import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";

import { CaretDown } from "../clients/ClientAppIcons";
import { Tick } from "./AppIcons";

// const publishingOptions = [
//   { title: 'Published', description: 'This job posting can be viewed by anyone who has the link.', current: true },
//   { title: 'Draft', description: 'This job posting will no longer be publicly accessible.', current: false },
// ]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function HeadlessFilterSelect({
  options,
  value,
  onChange,
  placeholder,
}: {
  options: { title: string; description?: string; value: string }[];
  value?: string;
  onChange: (value: string) => void;
  placeholder: string;
}) {
  const selectedOption = options.find((option) => option.value === value);
  return (
    <Listbox
      value={selectedOption}
      onChange={(selectedObject) => onChange(selectedObject.value)}
    >
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">
            Change published status
          </Listbox.Label>
          <div className="relative">
            <div className="inline-flex divide-x divide-indigo-700 rounded-md shadow-sm">
              <div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-indigo-600 px-3 py-2 text-white shadow-sm">
                <p className="text-sm font-semibold">
                  {selectedOption?.title || placeholder}
                </p>
              </div>
              <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-indigo-600 p-2 hover:bg-indigo-700">
                <span className="sr-only">Change published status</span>
                <CaretDown className="h-3 w-3 text-white" aria-hidden="true" />
              </Listbox.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-10 mt-2 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "cursor-default select-none p-4 text-sm",
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p
                            className={
                              selected ? "font-semibold" : "font-normal"
                            }
                          >
                            {option.title}
                          </p>
                        </div>
                        <p
                          className={classNames(
                            active ? "text-indigo-200" : "text-gray-500",
                            "mt-2",
                          )}
                        >
                          {option.description}
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
