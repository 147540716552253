import React, { useEffect, useState } from "react";

import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { alertError } from "../../actions/AlertActions";
import {
  clientTickets,
  getClientSRs,
  getTicketSRs,
  listSRs,
} from "../../api/Api";
import { convertToAscendingDescendingDict } from "../../utils/StateUtils";
import MaterialTable from "../common/MaterialTable";
import SRTicketFilter from "../common/SRTicketFilter";
import { JobTypesDict } from "../common/form/constants";

const fetchClientServiceRequests = (clientId, setData) => {
  getClientSRs(clientId)
    .then(setData)
    .catch((_) => alertError("Error loading Service Requests"));
};

const fetchTicketServiceRequests = (ticketId, setData) => {
  getTicketSRs(ticketId)
    .then(setData)
    .catch((_) => alertError("Error loading Service Requests"));
};

const fetchServiceRequests = (filters, setData) => {
  listSRs(filters.status ?? "ALL")
    .then(setData)
    .catch((_) => {
      alertError("Error loading Service Requests");
    });
};

const fetchClientTickets = (clientId, setData) => {
  clientTickets(clientId)
    .then(setData)
    .catch((_) => alertError("Error loading Tickets"));
};
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function ListServiceRequests({
  clientId,
  data,
  buildings,
  defaultBuilding,
  onClickCB,
}) {
  const classes = useStyles();
  const [serviceRequests, setServiceRequests] = useState(data ?? []);
  const [searchText, setSearchText] = useState("");
  const [selectedBuilding, setSelectedBuilding] = useState();
  const [selectedEstimateStatus, setSelectedEstimateStatus] = useState();
  const [selectedTicket, setSelectedTicket] = useState();
  const [tickets, setTickets] = useState([]);

  const buildingChangeCB = (buildingId, propertyName) => {
    setSelectedBuilding({ id: buildingId, name: propertyName });
  };
  const estimateChangeCB = (status) => {
    setSelectedEstimateStatus(status);
  };

  const ticketChangeCB = (ticketId) => {
    setSelectedTicket(ticketId);
  };

  useEffect(() => {
    if (selectedTicket)
      fetchTicketServiceRequests(selectedTicket.ticketId, setServiceRequests);
    else if (clientId) fetchClientServiceRequests(clientId, setServiceRequests);
    else if (!data) fetchServiceRequests({}, setServiceRequests);
  }, [clientId, data, selectedTicket]);

  useEffect(() => {
    if (clientId) fetchClientTickets(clientId, setTickets);
  }, [clientId]);

  const getFilteredData = () => {
    let data = [];
    let append = false;

    convertToAscendingDescendingDict(
      serviceRequests,
      "ticketId",
      "serviceRequestIndex",
      "desc"
    ).map((row) => {
      data.push({
        id: row.serviceRequestId,
        data: [
          {
            render:
              `${row.ticketId}-` +
              (!row.isDraft
                ? `SR${row.serviceRequestIndex}`
                : `I${row.serviceRequestIndex}`),

            sortBy: row.serviceRequestId,
          },
          row.description,
          JobTypesDict[row.jobType],
          row.bookingStatus,
          row.propertyShortName || "-",
          {
            render: row.preferredDateTime
              ? new Date(row.preferredDateTime).toLocaleString()
              : "-",
            sortBy: row.preferredDateTime
              ? new Date(row.preferredDateTime).getTime()
              : 0,
          },
        ],
      });
    });

    if (selectedBuilding) {
      if (selectedBuilding.id) {
        data = [];
        convertToAscendingDescendingDict(
          serviceRequests,
          "ticketId",
          "serviceRequestIndex",
          "desc"
        )
          .filter((sr) => sr.buildingId === parseInt(selectedBuilding.id))
          .map((row) => {
            data.push({
              id: row.serviceRequestId,
              data: [
                {
                  render:
                    `${row.ticketId}-` +
                    (!row.isDraft
                      ? `SR${row.serviceRequestIndex}`
                      : `I${row.serviceRequestIndex}`),

                  sortBy: row.serviceRequestId,
                },
                row.description,
                JobTypesDict[row.jobType],
                row.bookingStatus,
                row.propertyShortName || "-",
                {
                  render: row.preferredDateTime
                    ? new Date(row.preferredDateTime).toLocaleString()
                    : "-",
                  sortBy: row.preferredDateTime
                    ? new Date(row.preferredDateTime).getTime()
                    : 0,
                },
              ],
            });
          });
      }
    }
    return data;
  };
  return (
    <div
      className={classes.root + " max-w-6xl mx-auto w-full gap-2 flex-col p-2"}
    >
      <div className="flex items-center gap-2 cursor-pointer">
        <div className="flex-row w-full">
          <TextField
            id="search"
            className="w-full flex-1"
            label="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            variant="outlined"
          />
        </div>

        <SRTicketFilter
          buildings={buildings || []}
          tickets={tickets || []}
          defaultBuilding={defaultBuilding}
          selectedBuilding={selectedBuilding}
          selectedEstimateStatus={selectedEstimateStatus}
          buildingChangeCB={buildingChangeCB}
          estimateChangeCB={estimateChangeCB}
          ticketChangeCB={ticketChangeCB}
          display={["buildings"]}
        />
      </div>
      <MaterialTable
        data={getFilteredData()}
        head={[
          { id: "serviceRequestId", label: "Service Request Number" },
          { id: "description", label: "Description" },
          { id: "jobType", label: "Job Type" },
          { id: "bookingStatus", label: "Status" },
          { id: "propertyShortName", label: "Property Short Name" },
          { id: "preferredDate", label: "Preferred Date" },
        ]}
        // defaultOrderBy={"serviceRequestId"}
        // defaultOrder="desc"
        onClickCB={(id) => {
          const srData = serviceRequests.find(
            (sr) => sr.serviceRequestId === id
          );

          onClickCB(srData);
        }}
        searchText={searchText}
      />
    </div>
  );
}
