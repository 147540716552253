import { React, useState } from "react";

import UnitsAndResidents from "../../pages/building/components/UnitsAndResidents";
import BuildingInfo from "../clients/BuildingInfo";
import ListClientsBuildingPage from "../clients/ListClientsBuildingPage";
import { SearchIcon } from "../common/AppIcons";
import HeadlessFilterSelect from "../common/HeadlessFilterSelect";

export default function BuildingSummary({
  buildingId,
  buildingData,
  refreshCB,
}) {
  const [searchText, setSearchText] = useState("");
  const [filterClientsBy, setFilterClientsBy] = useState("");

  return (
    <div className="px-2 flex flex-col md:flex-row py-4 gap-5 items-start">
      <BuildingInfo
        selectedBuilding={buildingData.building}
        context={"profile"}
        refreshCB={() => {
          refreshCB({ type: "building" });
        }}
      />
      {/* Deprecating Clients in this Building */}
      <div className="flex flex-col w-full">
        <div>
          <div className="flex flex-row justify-between items-center py-4 w-full">
            <div>
              <span>Clients In This Building</span>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-800"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    // debouncedChangeHandler(e.target.value);
                  }}
                />
              </div>
              <div>
                <HeadlessFilterSelect
                  value={filterClientsBy}
                  onChange={(value) => {
                    setFilterClientsBy((currentFilter) =>
                      currentFilter === value ? "" : value
                    );
                  }}
                  options={[
                    { title: "Owner", value: "OWNER" },
                    { title: "Tenant", value: "TENANT" },
                    { title: "HOA", value: "HOA" },
                    { title: "PMC", value: "PMC" },
                  ]}
                  placeholder="Filter"
                />
              </div>
            </div>
          </div>
          {buildingData.clients
            .filter((client) => client.clientId)
            .filter((item) => {
              if (searchText !== "") {
                return (
                  item?.clientName
                    ?.toLowerCase()
                    ?.includes(searchText.toLowerCase()) ||
                  item?.clientType
                    ?.toLowerCase()
                    ?.includes(searchText.toLowerCase())
                );
              } else {
                return true;
              }
            })
            .map((client) => (
              <div className="flex flex-col py-2">
                <ListClientsBuildingPage
                  buildingData={buildingData}
                  client={client}
                  type={"BUILDING"}
                  tickets={buildingData.tickets}
                  refreshCB={() => {
                    refreshCB({ type: "building" });
                  }}
                />
              </div>
            ))}
        </div>
        <UnitsAndResidents
          buildingId={buildingId}
          buildingData={buildingData.building}
          refreshCB={() => {
            refreshCB({ type: "building" });
          }}
        />
      </div>
    </div>
  );
}
