import { atom } from "recoil";

export type GalleryItem = {
  communicationId: number;
  mediaUrls: string[];
};
export type GalleryData = {
  mode: "select" | "view";
  selected: GalleryItem[];
};

const initialState: GalleryData = {
  mode: "view",
  selected: [],
};

export const galleryAtom = atom<GalleryData>({
  key: "galleryAtom",
  default: initialState,
});
