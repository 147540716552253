// import ListContacts from "../clients/ListContacts";
import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";
import { navigate } from "raviger";

import { alertError } from "../../actions/AlertActions";
import { endConversation, getTerritory } from "../../api/Api";
import CreateContact from "../clients/CreateContact";
import CreateClient from "../clients/LegacyCreateClient";
import ListClients from "../clients/ListClients";
import { getTerritoryFromZIP } from "../crm/chat/zip";
import MaterialModal from "./MaterialModal";

const initialState = { link: "EXISTING" };

export default function SelectContact({
  show,
  setShow,
  onSuccess,
  data,
  onReportSpam,
}) {
  const [selected, setSelected] = useState({ ...initialState, show: false });

  const [territories, setTerritories] = useState([]);

  useEffect(() => {
    setSelected((current) => {
      return { ...current, show: show };
    });
    getTerritory().then((res) => {
      setTerritories(res);
    });
  }, [show]);
  return (
    <MaterialModal
      open={selected.show}
      setOpen={(_) =>
        setSelected((current) => {
          return { ...current, show: false };
        })
      }
      extended
    >
      <Button
        onClick={onReportSpam}
        style={{ color: "red" }}
        className="float-right p-4"
      >
        Report Spam and Delete
      </Button>
      <div className="flex">
        {selected && selected.link === "NEW" && (
          <CreateClient
            clientData={{
              contact: {
                phone: data.conversation
                  ? data.conversation.channelState.attributes.phoneOrEmail
                  : "",
                firstName: data.conversation
                  ? data.conversation.channelState.attributes.first_name
                  : "",
                lastName: data.conversation
                  ? data.conversation.channelState.attributes.last_name
                  : "",
              },
              building: {
                territory: data.conversation
                  ? data.conversation.channelState.attributes.zipcode
                    ? getTerritoryFromZIP(
                        parseInt(
                          data.conversation.channelState.attributes.zipcode
                        )
                      )
                    : territories[0].name
                  : "",
                address: {
                  zip: data.conversation.channelState.attributes.zipcode || "",
                },
              },
            }}
            onSuccess={onSuccess}
          />
        )}
        {selected && selected.link === "EXISTING" && !selected.clientId && (
          <ListClients
            newClient={(_) =>
              setSelected((selected) => {
                return { ...selected, link: "NEW" };
              })
            }
            onSelect={(clientId, type) => {
              setSelected((selected) => {
                return { ...selected, clientId: clientId };
              });
            }}
          />
        )}
        {selected && selected.link === "EXISTING" && selected.clientId && (
          <CreateContact
            baseData={{
              client: { clientId: selected.clientId },
              phone: data.conversation.channelState.attributes.phoneOrEmail,
              firstName: data.conversation.channelState.attributes.first_name,
              lastName: data.conversation.channelState.attributes.last_name,
            }}
            successCB={(contactId) => {
              setSelected((selected) => {
                return { ...selected, clientId: "", show: false };
              });
              navigate(`/clients/${selected.clientId}/messages/${contactId}`);

              endConversation(
                data.conversation.sid,
                contactId
                  ? contactId
                  : data.conversation.channelState.attributes.contactId
              ).catch((_) => alertError("Couldn't Leave Chat"));
            }}
          />
        )}
      </div>
    </MaterialModal>
  );
}
