import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CallIcon from "@mui/icons-material/Call";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import { TextField } from "@mui/material";

import {
  alertError,
  alertInfo,
  alertSuccess,
} from "../../actions/AlertActions";
import { startCall, startAssociateCall } from "../../actions/VoiceActions";
import { saveInternalNotes, sendMessage } from "../../api/Api";
import { deepUpdate } from "../../utils/StateUtils";
import { properStrings, renderPhone } from "../../utils/StringUtils";
import DropDown from "../common/DropDown";
import { NewMediaDragIn } from "../common/NewMediaDragIn";
import MaterialForm from "../common/form/MaterialForm";
import MessagesList from "./MessagesList";

// import { changeExtension } from "../common/PreviewLink";

// import EmailIcon from "@mui/icons-material/Email";
// import MessageIcon from "@mui/icons-material/Message";

const addEncodedFile = (setContent, file) =>
  function (upload) {
    setContent((content) => {
      return {
        ...content,
        attachments: [
          ...(content.attachments ?? []),
          {
            fileName: file.name,
            contentType: file.type,
            encodedFile: upload.target.result,
          },
        ],
      };
    });
  };

export default function MessagesView({
  title,
  subtext,
  phone,
  contactId,
  buildingId,
  contacts,
  tickets,
  messages,
  addAttachmentToSR,
  updateMessages,
  fetchNextPage,
  associateMessaging = false,
  showApp = false,
}) {
  const fileRef = useRef();
  const [channel, setChannel] = useState("SMS");
  const [content, setContent] = useState("");
  const [editNotesFlag, triggerEditNotes] = useState(false);
  const [mediaUrls, setMediaUrls] = useState([]);
  const [displayChannel, setDisplayChannel] = useState("SMS");

  const onChangeCB = (channel) => {
    setDisplayChannel(channel);
    channel === "APP" ? setChannel("INTERNAL") : setChannel(channel);
  };

  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  const onDropCB = (file) => {
    alertInfo("Uploading file...");

    let httpsURL = "https:" + file.url.split("http:")[1];

    console.log("httpsURL parsing done");

    toDataURL(httpsURL, function (dataUrl) {
      setMediaUrls((current) => {
        return [...current, { url: file.url, base: dataUrl }];
      });
      alertInfo("Upload Complete");
    });
  };

  const OnDeleteCB = (data) => {
    setMediaUrls(data);
  };

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);
    Array.from(acceptedFiles).forEach((file) => {
      // console.log("MIME:" + file.type);
      var reader = new FileReader();
      reader.onload = addEncodedFile(setContent, file);
      reader.readAsDataURL(file);
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (messages.content.length > 0) {
      const lastMessage = messages.content[0];
      if (lastMessage.channel === "EMAIL") {
        setChannel("EMAIL");
        setDisplayChannel("EMAIL");

        setContent((content) => {
          return {
            ...content,
            subject: `Re: ${lastMessage.subject}`,
          };
        });
      } else if (lastMessage.channel === "INTERNAL") {
        setChannel("INTERNAL");
        setDisplayChannel("INTERNAL");
      } else {
        setChannel("SMS");
        setDisplayChannel("SMS");
      }
    }
  }, [messages]);

  const handleSelectFile = (event) => {
    // console.log("Uploading to S3");
    var files = event.target.files;
    Array.from(files).forEach((file) => {
      // console.log("MIME:" + file.type);
      var reader = new FileReader();
      reader.onload = addEncodedFile(setContent, file);
      reader.readAsDataURL(file);
    });
  };

  async function parseMediaUrls(attachments, mediaUrls) {
    let data = attachments ? attachments : [];

    mediaUrls.forEach((mediaUrl) => {
      data.push({
        fileName: mediaUrl.url.split("/").pop(),
        contentType: mediaUrl.base.split("data:")[1].split(";")[0],
        encodedFile: mediaUrl.base,
      });
    });

    return data;
  }

  async function dispatchMessage(content, foreground) {
    let messageChannel = channel;
    if (channel === "SMS") {
      if (content.attachments || mediaUrls.length > 0) {
        messageChannel = "MMS";
      }
    }
    sendMessage({
      channel: messageChannel,
      contactId: content.contactId ?? contactId,
      content: content.text,
      notes: content.notes,
      subject: channel === "EMAIL" ? content.subject : null,
      attachments: await parseMediaUrls(content.attachments, mediaUrls),
    })
      .then((_) => {
        if (foreground) {
          updateMessages();
          alertSuccess("Sent Message");
          setContent({ subject: "", text: "", notes: "" });
          setMediaUrls([]);
        }
      })
      .catch((_) => alertError("Error Sending Message"));
  }
  return (
    <div
      className={
        associateMessaging
          ? "border flex flex-col md:w-6/12 max-h-lscreen align-middle"
          : "md:w-9/12 border flex flex-col"
      }
    >
      {/* <!-- Header --> */}
      {title && (
        <div className="py-2 px-3 bg-gray-200 flex flex-row justify-between items-center">
          <div className="flex items-center">
            <div>
              <AccountCircleIcon className="w-10 h-10 rounded-full" />
            </div>
            <div className="ml-4">
              <p className="text-grey-darkest">{title}</p>
              <p className="text-grey-darker text-xs mt-1">
                {subtext && renderPhone(subtext)}
              </p>
            </div>
          </div>

          <div className="flex gap-2 pr-4">
            <RefreshIcon
              onClick={(_) => updateMessages()}
              className="cursor-pointer"
            />
            {phone && (
              <CallIcon
                onClick={(_) => {
                  if (associateMessaging) {
                    startAssociateCall({
                      number: phone,
                    });
                  } else {
                    startCall({
                      number: phone,
                      // callerId:`${selectedContact.firstName} ${selectedContact.lastName}`
                    });
                  }
                }}
                className="cursor-pointer"
              />
            )}
          </div>
        </div>
      )}

      {/* <!-- Messages --> */}
      {/* <div className="flex-1 overflow-auto bg-white"> */}
      {
        <MessagesList
          messages={messages}
          contacts={contacts}
          tickets={tickets}
          refreshCB={updateMessages}
          fetchNextPage={fetchNextPage}
          addAttachmentToSR={addAttachmentToSR}
          title={title}
          draggableMedia
          associateMessaging={associateMessaging}
        />
      }
      {/* </div> */}

      {/* <!-- Input --> */}
      {contactId && (
        <div
          className="bg-grey-lighter px-4 py-4 flex gap-2 items-center bg-gray-200"
          // style={{ "background-color": "#EDF2F6" }}
        >
          {showApp ? (
            <DropDown
              options={[
                { channel: "SMS" },
                { channel: "EMAIL" },
                { channel: "APP" },
              ]}
              defaultValue={{ channel: displayChannel }}
              onChangeCB={onChangeCB}
            />
          ) : (
            <DropDown
              options={[{ channel: "SMS" }, { channel: "EMAIL" }]}
              onChangeCB={onChangeCB}
            />
          )}

          {/* <div
            className="cursor-pointer"
            onClick={(_) =>
              setChannel((current) => (current === "SMS" ? "EMAIL" : "SMS"))
            }
          >
            {channel === "SMS" ? <MessageIcon /> : <EmailIcon />}
          </div> */}
          <div
            className="flex-1 flex flex-col gap-2 mx-4"
            {...getRootProps()}
            onClick={(_) => {}}
          >
            {contacts && content.copyTo && (
              <MaterialForm
                className="p-1"
                data={content}
                renderArray={[
                  {
                    name: "copyTo",
                    inputType: "SELECT",
                    variant: "MULTISELECT",
                    options: Object.values(contacts).map((contact) => {
                      return {
                        label: properStrings([
                          contact.firstName,
                          contact.lastName,
                        ]),
                        value: contact.contactId,
                      };
                    }),
                    defaultValue: "ALL",
                  },
                ]}
                onChange={(update) => {
                  const { name, value } = update;
                  setContent((data) => deepUpdate(name, value, data));
                }}
              />
            )}
            {displayChannel === "EMAIL" && (
              <input
                className="w-full border rounded px-2 py-2"
                placeholder="Subject"
                value={content.subject}
                onChange={(e) =>
                  setContent({ ...content, subject: e.target.value })
                }
              />
            )}
            <NewMediaDragIn
              urls={mediaUrls}
              editMedia={true}
              emptyScreen={false}
              onDropCB={onDropCB}
              OnDeleteCB={OnDeleteCB}
            >
              <TextField
                variant="standard"
                className={
                  "w-full border rounded px-2 py-2 supplier-messages-textfield " +
                  (editNotesFlag ? "bg-yellow-200" : "bg-white")
                }
                multiline
                minRows={3}
                maxRows={8}
                placeholder={
                  editNotesFlag
                    ? "Type in Notes for Internal Reference"
                    : "New Message"
                }
                value={editNotesFlag ? content.notes : content.text}
                onChange={
                  editNotesFlag
                    ? (e) => setContent({ ...content, notes: e.target.value })
                    : (e) => setContent({ ...content, text: e.target.value })
                }
              />
            </NewMediaDragIn>
            {content.attachments?.map((attachment) => (
              <p>
                File: {attachment.fileName}
                <CloseIcon
                  className="cursor-pointer"
                  onClick={(e) =>
                    setContent({ ...content, attachments: undefined })
                  }
                />
              </p>
            ))}
            <div className="flex gap-2">
              <span
                className="text-sm text-gray-700"
                onClick={(_) => triggerEditNotes((current) => !current)}
              >
                {editNotesFlag
                  ? "Switch to Messaging"
                  : content.notes
                  ? "Edit Notes"
                  : "Add Notes"}
              </span>
              {content.copyTo ? (
                <span
                  className="text-sm text-gray-700 cursor-pointer"
                  onClick={(_) => {
                    setContent((content) => {
                      return { ...content, copyTo: undefined };
                    });
                  }}
                >
                  Remove Bcc
                </span>
              ) : (
                <span
                  className="text-sm text-gray-700 cursor-pointer"
                  onClick={(_) => {
                    setContent((content) => {
                      return { ...content, copyTo: [] };
                    });
                  }}
                >
                  Add Bcc
                </span>
              )}
            </div>
          </div>
          <div>
            <AttachFileIcon
              className="cursor-pointer"
              onClick={(_) => fileRef.current.click()}
            />
            <input
              type="file"
              className="hidden"
              onChange={handleSelectFile}
              ref={fileRef}
              multiple
            />
            <input className="hidden" {...getInputProps()} />
          </div>
          <div>
            <DynamicIcon
              icon={editNotesFlag ? SaveIcon : SendIcon}
              className="cursor-pointer send-message-icon"
              onClick={
                editNotesFlag
                  ? (_) =>
                      saveInternalNotes({
                        contactId: contactId,
                        notes: content.notes,
                        attachments: content.attachments,
                      })
                        .then((_) => {
                          updateMessages();
                          alertSuccess("Saved Notes");
                          setContent({ subject: "", text: "", notes: "" });
                        })
                        .catch((_) => alertError("Error Saving Notes"))
                  : (_) => {
                      alertInfo("Sending Message", undefined, {
                        loading: true,
                      });
                      dispatchMessage(content, true);
                      if (content.copyTo?.length > 0)
                        content.copyTo.forEach((copy) =>
                          dispatchMessage({
                            ...content,
                            contactId: Number(copy),
                          })
                        );
                    }
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}

const DynamicIcon = (props) => {
  const Icon = props.icon;
  return <Icon {...props} />;
};
