import React from "react";
import { useDrag } from "react-dnd";
export const DraggableItem = ({ item, children, dragCB }) => {
  const [{ opacity }, drag] = useDrag({
    item: { url: item, type: "IMAGE" },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        // let alertMessage = '';
        // const isDropAllowed = dropResult.allowedDropEffect === 'any' ||
        //     dropResult.allowedDropEffect === dropResult.dropEffect;
        // if (isDropAllowed) {
        //     const isCopyAction = dropResult.dropEffect === 'copy';
        //     const actionName = isCopyAction ? 'copied' : 'moved';
        //     alertMessage = `You ${actionName} ${item?.name} into ${dropResult.name}!`;
        // }
        // else {
        //     alertMessage = `You cannot ${dropResult.dropEffect} an item into the ${dropResult.name}`;
        // }
        if (dragCB) dragCB(item.url);
      }
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });
  return (
    <div ref={drag} style={{ opacity }}>
      {children}
    </div>
  );
};
