import React, { useEffect, useState } from "react";

import { getMedia } from "../clients/MessagesList";

export default function Carousel({ lightboxImages }) {
  const [images, setImages] = useState(lightboxImages);
  useEffect(() => {
    let photoIndex = images.photoIndex;
    let filtered = lightboxImages.images.filter((item, index) => {
      let splitted = item.split(".");
      let extension = splitted[splitted.length - 1];
      if (extension !== "pdf") {
        return true;
      } else {
        if (index < images.photoIndex) {
          photoIndex -= 1;
        }
      }
    });
    setImages((current) => {
      return { photoIndex: photoIndex, images: filtered };
    });
  }, [lightboxImages]);

  return (
    <div
      id="carouselExampleControls"
      className="carousel slide relative bg-opacity-0"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner relative w-full overflow-hidden flex flex-row bg-opacity-0">
        <button
          className=" carousel-control-prev flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline bg-gray-700 bg-opacity-50 hover:bg-opacity-80"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon inline-block bg-no-repeat"
            style={{ color: "#000" }}
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <div className="flex-grow">
          {images.images.map((imageSRC, index) => (
            <div
              className={
                "carousel-item relative float-left w-full " +
                (index === images.photoIndex ? "active" : "")
              }
              key={index}
            >
              {getMedia(imageSRC, "block w-full")}
            </div>
          ))}
        </div>
        <button
          className="carousel-control-next flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline bg-gray-700 bg-opacity-50 hover:bg-opacity-80"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon inline-block bg-no-repeat"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}
