const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export function getISODateString(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function getFormattedDate(date) {
  let d = new Date(date),
    month = "" + monthNames[d.getMonth()],
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (day.length < 2) day = "0" + day;

  return `${day} ${month} ${year}`;
}

export const dateEqual = (date1, date2) =>
  date1.getDate() === date2.getDate() &&
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth();

export const isAfter = (date, time) => new Date(date).getHours() > time;

export const getToday = () => {
  let now = new Date();
  // console.log("Now: " + now.toISOString())
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);
  // console.log("Today: " + now.toISOString())
  return now;
};

export const getDateStep30 = (date, disablePast) => {
  let dateObj = new Date(date);
  let now = disablePast
    ? new Date() > date
      ? new Date()
      : dateObj ?? new Date()
    : dateObj ?? new Date();
  // console.log("Received:" + (date?.toISOString() ?? "No Date Input") + "; Taken:" + now.toISOString())
  const currentMinutes = now.getMinutes();
  if (currentMinutes > 30) {
    now.setHours(now.getHours() + 1);
    now.setMinutes(0);
  } else if (currentMinutes !== 0) now.setMinutes(30);
  now.setSeconds(0);
  now.setMilliseconds(0);
  // console.log("Returning: " + now.toISOString())
  return now;
};

export const addDays = (dayCount, date = new Date()) => {
  let dateObj = new Date(date);
  dateObj.setDate(dateObj.getDate() + dayCount);
  return dateObj;
};

export const getTomorrow = () => {
  let tomorrow = getToday();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const getNextDay = (date) => {
  let nextDay = new Date(date);
  nextDay.setDate(nextDay.getDate() + 1);
  return nextDay;
};

export const getPreviousDay = (date) => {
  let prevDay = new Date(date);
  prevDay.setDate(prevDay.getDate() - 1);
  return prevDay;
};

export const getStartOfDay = (date) => {
  let start = new Date(date);
  start.setHours(0);
  start.setMinutes(0);
  return start;
};

export const timeAfterNHours = (date, n) => {
  let tomorrow = date;
  tomorrow.setHours(tomorrow.getHours() + n);
  return tomorrow;
};

export const previousRange = (from, to) => {
  let previousFrom = new Date(from);
  let previousTo = new Date(to);
  while (!dateEqual(previousTo, from)) {
    previousFrom.setDate(previousFrom.getDate() - 1);
    previousTo.setDate(previousTo.getDate() - 1);
  }
  previousFrom.setDate(previousFrom.getDate() - 1);
  previousTo.setDate(previousTo.getDate() - 1);
  return { from: previousFrom, to: previousTo };
};

export const nextRange = (from, to) => {
  let nextFrom = new Date(from);
  let nextTo = new Date(to);
  while (!dateEqual(nextFrom, to)) {
    nextFrom.setDate(nextFrom.getDate() + 1);
    nextTo.setDate(nextTo.getDate() + 1);
  }
  nextFrom.setDate(nextFrom.getDate() + 1);
  nextTo.setDate(nextTo.getDate() + 1);
  return { from: nextFrom, to: nextTo };
};

export function formatDate(baseDate) {
  const date = new Date(baseDate);
  const now = new Date();
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 86400;
  if (interval > 1) {
    const format = date.getDate() + " " + monthNames[date.getMonth()];
    const year = date.getFullYear();
    if (year < now.getFullYear()) return format + " " + year;
    else return format;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

export function formatDateLegacy(date) {
  date = new Date(date);
  let tempDate = date;
  let now = new Date();
  // now.setHours(0, 0, 0, 0);
  // tempDate.setHours(0, 0, 0, 0);
  const currentTime = now.getTime();
  if (currentTime === tempDate.getTime()) {
    var seconds = Math.floor((new Date() - date) / 1000);
    var divisors = [3600, 60, 1];
    var description = ["hrs", "min", "sec"];
    var result = "";
    var interval = Math.floor((seconds / 31536000) * 2592000 * 86400);
    for (let i = 0; i < divisors.length; i++) {
      interval = Math.floor(seconds / divisors[i]);
      if (interval > 1) {
        result = interval + " " + description[i];
        break;
      }
      seconds -= interval * divisors[i];
    }
    return result + " ago";
  } else if (currentTime - tempDate.getTime() === 86400000) return "Yesterday";
  else {
    const format = date.getDate() + " " + monthNames[date.getMonth()];
    const year = date.getFullYear;
    if (year < tempDate.getFullYear) return format + " " + year;
    else return format;
  }
}
