import { useEffect, useRef, useState } from "react";

import { deepFind, deepUpdate } from "../../../../utils/StateUtils";
import { properString } from "../../../../utils/StringUtils";
import { DeleteBin } from "../../../clients/ClientAppIcons";
import MaterialModal from "../../MaterialModal";
import { PreviewTiles } from "../../PreviewTiles";
import { make as MaterialForm } from "../MaterialForm.bs";

export default function Gallery({ name, data, options, onChange }) {
  const value = deepFind(name, data);
  const [showGallery, setShowGallery] = useState(false);

  if (options.length === 0) {
    return <span>No media in Gallery</span>;
  }

  return (
    <>
      {(value || []).length > 0 ? (
        <div className="flex flex-row flex-wrap">
          <PreviewTiles
            urls={value || []}
            OnDeleteCB={(url) => {
              onChange(
                deepUpdate(
                  name,
                  value.filter((v) => v !== url),
                  data
                )
              );
            }}
          />
        </div>
      ) : (
        <span
          className="my-2 p-2 rounded-md text-center font-bold uppercase text-gray-700 text-sm hover:text-blue-500 bg-blue-100 hover:bg-blue-200 "
          onClick={() => {
            setShowGallery(true);
          }}
        >
          Add Attachments
        </span>
      )}
      {showGallery && (
        <MaterialModal
          open={true}
          setOpen={() => {
            setShowGallery(false);
          }}
        >
          <SelectFromGalleryModal
            data={data}
            name={name}
            options={options}
            value={value || []}
            onChange={onChange}
          />
        </MaterialModal>
      )}
    </>
  );
}

const SelectFromGalleryModal = ({
  data,
  name, // path to selected media urls
  options, // list of gallery media urls
  value, // list of selected media urls
  onChange, // callback to update selected media urls
}) => {
  return (
    <PreviewTiles
      urls={options}
      selectMedia={value}
      onSelectCB={(url) => {
        console.log("DEBUG: onSelectCB", url, "|", value, "|", name, "|", data);
        onChange({
          name,
          value: value.includes(url)
            ? value.filter((v) => v !== url)
            : [...value, url],
        });
      }}
    ></PreviewTiles>
  );
};
