import React from "react";

import LoadingSpinner from "./LoadingSpinner";

interface LoadingOverlayProps {
  isLoading: boolean;
  message?: string;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  isLoading,
  message = "Loading...",
}) => {
  if (!isLoading) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
        <LoadingSpinner />
        <p className="mt-4 text-lg font-semibold">{message}</p>
      </div>
    </div>
  );
};

export default LoadingOverlay;
