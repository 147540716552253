import React, { useEffect, useState } from "react";
import ReactAudioPlayer from "react-audio-player";

import CallIcon from "@mui/icons-material/Call";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Avatar } from "@mui/material";
import { Link } from "raviger";

import { alertError } from "../../actions/AlertActions";
import { reassignComm } from "../../api/Api";
import { renderText } from "../../utils/RenderUtils";
import { deepUpdate } from "../../utils/StateUtils";
import { capitalizeFirst } from "../../utils/StringUtils";
import { properStrings2 } from "../../utils/StringUtils";
import { renderTime } from "../../utils/TimeUtils.bs";
import { DraggableItem } from "../common/DraggableItem";
import PopOverClick from "../common/PopOverClick";
import PopOverText from "../common/PopOverText";
import PreviewLink from "../common/PreviewLink";

export default function MessageBubble({
  message,
  associateMessaging,
  outgoing,
  contacts,
  tickets,
  refreshCB,
  draggableMedia,
  addAttachmentToSR,
  setChatData,
  setChatModalDisplay,
  setLightboxImages,
}) {
  const [select, setSelect] = useState({});
  const [emailForward, setEmailForward] = useState(false);
  const [content, setContent] = useState(message.content);
  const [index, setIndex] = useState();

  const [expand, setExpand] = useState(false);
  const [append, setAppend] = useState(false);

  useEffect(() => {
    const found = /On\sMon,\s|Tue,\s|Wed,\s|Thu,\s|Fri,\s|Sat,\s|Sun,\s/.exec(
      message.content
    );
    const found2 = /wrote:/.exec(message.content);
    let f;

    if (found && found2 && message.channel === "EMAIL") {
      setContent(() => {
        message.content.slice(0, found.index - 3);
      });

      var splitted = message?.content.split("\n");

      for (var i = 0; i < splitted.length; i++) {
        f = /On\sMon,\s|Tue,\s|Wed,\s|Thu,\s|Fri,\s|Sat,\s|Sun,\s/.exec(
          splitted[i]
        );
        if (f && found2) {
          setIndex(i);
          break;
        }
      }
    } else {
      // if (message.content?.includes("wrote:")) {
      // setEmailForward(true);
      setContent(message.content);
      // }
    }
  }, []);

  useEffect(() => {
    if (expand) {
      setContent(message.content);
      // setEmailForward(false);
    } else {
      const found = /On\sMon,\s|Tue,\s|Wed,\s|Thu,\s|Fri,\s|Sat,\s|Sun,\s/.exec(
        message.content
      );
      const found2 = /wrote:/.exec(message.content);

      if (found && found2 && message.channel === "EMAIL") {
        setContent(message.content.slice(0, found.index - 3));
      } else {
        if (message.content?.includes("wrote:")) {
          setEmailForward(true);
          setContent(message.content.split("wrote:")[0] + " wrote:");
        }
      }
    }
  }, [expand]);

  const onChange = (update) => {
    const { name, value } = update;
    setSelect((data) => deepUpdate(name, value, data));
  };

  const reassign = (commId, onSuccess) => {
    reassignComm({ communicationId: commId, ticketId: select.ticket })
      .then((_) => {
        refreshCB();
        onSuccess();
      })
      .catch((_) => alertError("Couldn't Reassign Message"));
  };

  return (
    <div className={"flex gap-2 mb-2" + (outgoing ? " justify-end" : "")}>
      <div
        className={
          outgoing
            ? "rounded py-2 px-3 bg-gray-200"
            : message.channel === "VOICEMAIL"
            ? "rounded py-2 px-3 bg-blue-200"
            : "rounded py-2 px-3 bg-blue-200"
        }
      >
        <div className="text-sm text-teal flex gap-2 justify-between">
          <p>
            {outgoing
              ? message.userFullName ?? ""
              : contacts
              ? contacts[message.contactId]?.lastName || ""
              : ""}{" "}
            {associateMessaging && !outgoing
              ? capitalizeFirst(message.fromActual?.split(" ")[1])
              : ""}
          </p>
        </div>
        <div className="text-sm text-teal flex gap-2 justify-between">
          <p>
            {message.emailCc ? "CC: " + properStrings2(message.emailCc) : ""}
          </p>
        </div>
        <div className="text-sm mt-1">
          {message.channel === "CALL" || message.channel === "VOICEMAIL" ? (
            <div className="flex flex-col gap-2">
              <div className="flex">
                <CallIcon /> Duration : {renderTime(message.duration)}
              </div>
              {message.recordingUrl && (
                <ReactAudioPlayer
                  src={message.recordingUrl}
                  autoPlay={false}
                  controls
                />
              )}
            </div>
          ) : (
            (
              (message.channel === "INTERNAL_NOTES"
                ? message.notes
                : content) || ""
            )
              .split("\n")
              .map((item, i) => {
                if (i != index - 1) {
                  return item.split("\n").map((item, renderIndex) => {
                    // let count = (item.match(/>/g) || []).length * 3;

                    let count = 0;
                    for (var i = 0; i < item.trim().length; i++) {
                      if (item[i] === ">") {
                        count += 1;
                      } else if (item[i] === " ") {
                        continue;
                      } else {
                        break;
                      }
                    }

                    // item = item.replace(/>/g, " ".repeat(count));

                    if (count) {
                      item = item.slice(count, item.length);

                      let pixels = `${count * 12}px`;

                      let found =
                        /On\sMon,\s|Tue,\s|Wed,\s|Thu,\s|Fri,\s|Sat,\s|Sun,\s/.exec(
                          item
                        );
                      if (found && message.channel === "EMAIL") {
                        return (
                          <>
                            <br />

                            <p
                              key={renderIndex}
                              className={"block break-all"}
                              style={{ "padding-left": pixels }}
                            >
                              {renderText(item)}
                            </p>
                          </>
                        );
                      }

                      return (
                        <p
                          key={renderIndex}
                          className={"block break-all "}
                          style={{ "padding-left": pixels }}
                        >
                          {renderText(item)}
                        </p>
                      );
                    } else {
                      // if (item.includes("wrote:")) {
                      if (!append) {
                        // return (
                        //   <div className="flex">
                        //     <div className="flex-row">
                        //       <p key={renderIndex} className="block break-all">
                        //         {renderText(item)}
                        //       </p>
                        //     </div>
                        //     <div className="flex-row ">
                        //       <MoreHorizIcon
                        //         className="bg-gray-400 hover:bg-gray-700 "
                        //         onClick={() => {
                        //           setExpand((current) => !current);
                        //         }}
                        //       />
                        //     </div>
                        //   </div>
                        // );

                        setAppend(true);
                      } else {
                        return (
                          <p key={renderIndex} className="block break-all">
                            {renderText(item)}
                          </p>
                        );
                      }
                      // }

                      return (
                        <p key={renderIndex} className="block break-all">
                          {renderText(item)}
                        </p>
                      );
                    }
                  });
                } else {
                  return (
                    <div className="flex">
                      <div className="flex-row ">
                        <p className="block break-all">{renderText(item)}</p>
                        <MoreHorizIcon
                          className="bg-gray-400 hover:bg-gray-700 "
                          onClick={() => {
                            setExpand((current) => !current);
                          }}
                        />
                      </div>
                    </div>
                  );
                }
              })
          )}
          {/* {emailForward && (
            <MoreHorizIcon
              onClick={() => {
                setExpand((current) => !current);
              }}
            />
          )} */}
        </div>
        <div className="flex flex-wrap">
          {message.attachmentUrls?.map((attachment, i) => (
            <div
              key={i}
              className="text-sm mt-1"
              onClick={(_) =>
                setLightboxImages({
                  images: message.attachmentUrls,
                  photoIndex: i,
                })
              }
            >
              {draggableMedia ? (
                <DraggableItem item={attachment}>
                  <PreviewLink url={attachment} />
                </DraggableItem>
              ) : (
                <PreviewLink url={attachment} />
              )}
            </div>
          ))}
        </div>

        <div className="text-right text-xs flex items-center gap-2 text-grey-dark mt-1">
          {message.notes && message.channel !== "INTERNAL_NOTES" && (
            <PopOverClick
              className="text-sm text-gray-700"
              renderPopOver={(_) => <textarea value={message.notes} />}
            >
              Notes
            </PopOverClick>
          )}
          {message.channel === "PUBLIC_CHAT" ? (
            <>
              <span className="text-blue-800">
                {message.channel.replace("_", " ") + " "}
              </span>
              <span
                className="text-blue-800"
                onClick={(_) => {
                  var date = new Date(message.createdAt).toLocaleTimeString(
                    [],
                    {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  );
                  setChatData({
                    title: message.userFullName + " " + date,
                    chats: message.chatMessages,
                  });
                  setChatModalDisplay(true);
                }}
              >
                <Link href="">View Chat</Link>
              </span>
            </>
          ) : (
            <>
              {message.channel === "INTERNAL"
                ? "APP "
                : message.channel.replace("_", " ") + " "}
            </>
          )}
          {new Date(message.createdAt).toLocaleTimeString([], {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </div>
      </div>
      <PopOverText
        text={
          message.userFullName
            ? `${message.userFullName}`
            : `${message.fromUsername}`
        }
      >
        {outgoing && (
          <Avatar src={message.userProfilePic} className="h-8 w-8">
            {((message.userFullName || message.fromUsername) ?? " ").slice(
              0,
              1
            )}
          </Avatar>
        )}
      </PopOverText>
    </div>
  );
}
