import React, { useState } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BuildIcon from "@mui/icons-material/Build";
import BusinessIcon from "@mui/icons-material/Business";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "raviger";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import { getTicketSRs } from "../../api/Api";
import { createSR, getTicket } from "../../api/Api";
import { deepUpdate } from "../../utils/StateUtils";
import { renderAddress } from "../../utils/StringUtils";
// import ServiceRequestDetail from "../service/ServiceRequestDetail";
// import MaterialModal from "../common/MaterialModal";
import NewServiceRequestPopup from "../common/NewServiceRequestPopup";
// import { Button, Typography } from "@mui/material";
// import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import { formUpdateSRTicket } from "../common/form/constants";

// import NewCreateTicket from "../pipelines/NewCreateTicket";

export default function CustomListItem({
  item,
  tab,
  setServiceRequest,
  setSelected,
}) {
  const [ticketSR, setTicketSR] = useState([]);
  const [showList, setShowList] = useState(false);
  const [forUpdate, setForUpdate] = useState();

  const { openTicket } = useAppActions();

  const onChangeUpdate = (update) => {
    const { name, value } = update;
    setForUpdate((data) => deepUpdate(name, value, data));
  };

  return (
    <>
      <div
        key={item.ticketId ?? item.contactId ?? item.buildingId}
        className="bg-white px-3 flex items-center hover:bg-gray-200 cursor-pointer"
        onClick={(_) => {
          item.ticketId && setServiceRequest({ confirmed: "false", ...item });
          setSelected(item);
          setShowList((current) => !current);
          if (item.ticketId)
            getTicketSRs(item.ticketId)
              .then((data) => {
                setTicketSR(data);
              })
              .catch((_) => {
                alertError("Error Loading Service Requests");
              });
        }}
      >
        <div>
          {tab === "Tickets" ? (
            <ConfirmationNumberIcon className="h-12 w-12 p-2" />
          ) : tab === "Locations" ? (
            <BusinessIcon className="h-12 w-12" />
          ) : (
            <AccountCircleIcon className="h-12 w-12 rounded-full" />
          )}
        </div>
        <div className="ml-2 flex-1 border-b border-grey-lighter py-4">
          <div className="flex items-bottom justify-between">
            <p className="text-grey-darkest">
              {tab === "Tickets"
                ? `#T${item.ticketId} | ${
                    item.title || item.description || "Unspecified"
                  }`
                : tab === "Locations"
                ? renderAddress(item.address)
                : item.position
                ? item.position === "TENANT"
                  ? `#${item.unitNumber} ${item.firstName} ${item.lastName}`
                  : `${item.firstName} ${item.lastName}`
                : `${item.firstName} ${item.lastName}`}
            </p>
          </div>
        </div>
      </div>
      {ticketSR &&
        showList &&
        ticketSR.map((SR, index) => (
          <div
            className="ml-8"
            onClick={(_) => {
              setForUpdate(
                ticketSR.find(
                  (sr) => sr.serviceRequestId === SR.serviceRequestId
                )
              );
            }}
          >
            <Link key={index} href="">
              <ListItem button>
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary={SR.description.slice(0, 50)} />
              </ListItem>
            </Link>
          </div>
        ))}

      {forUpdate && (
        <NewServiceRequestPopup
          data={forUpdate}
          renderArray={() => {
            return formUpdateSRTicket(forUpdate);
          }}
          backCB={() => {
            getTicket(forUpdate.ticketId).then((data) => {
              openTicket(data.ticketId);
              // setForUpdateTicket(data);
              setForUpdate();
            });
          }}
          onChangeCB={onChangeUpdate}
          clearCB={() => {
            setForUpdate();
          }}
          updateCB={(_) => {
            createSR(forUpdate)
              .then((_) => {
                // setForUpdate();
                alertSuccess("Successfully Updated Service Request");
              })
              .catch((_) => alertError("Error Updating Service Request"));
          }}
        />
      )}
    </>
  );
}
