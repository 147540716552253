import { React, useCallback, useEffect, useState } from "react";

import debounce from "lodash.debounce";
import { navigate } from "raviger";

import { getBuildings } from "../../api/Api";
import { properString, renderAddress } from "../../utils/StringUtils";
import { GoToPageIcon } from "../clients/ClientAppIcons";
// import { renderAddress } from "../../utils/StringUtils";
import { AddIconBlue } from "../common/AppIcons";
import DropDownTailwindBasic from "../common/DropDownTailwindBasic";
import HeadlessFilterSelect from "../common/HeadlessFilterSelect";
import MaterialTable from "../common/MaterialTable";
import { territories } from "../common/form/constants";

export default function NewBuildingList({}) {
  const [tableData, setTableData] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [type, setType] = useState("");
  const [territory, setTerritory] = useState("");

  const [totalElements, setTotalElements] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setTableData([]);
      getTableData();
    }, 300);
  }, [searchText]);

  useEffect(() => {
    getTableData();
  }, [page, size, type, territory]);

  const onSearch = (value) => {
    setSearchText(value);
  };

  const getTableHead = () => {
    return [
      { id: "address", label: "ADDRESS" },
      { id: "propertyShortName", label: "Property Short Name" },
      { id: "buildingType", label: "BUILDING TYPE" },
      { id: "territory", label: "TERRITORY" },
      { id: "primaryClient", label: "PRIMARY CLIENT" },
    ];
  };

  const getTableData = () => {
    // let baseURL = `/building/newApi/getAll?`;
    // if (type && territory) {
    //   baseURL = baseURL + `type=${type}&territory=${territory}`;
    // } else if (type) {
    //   baseURL = baseURL + `type=${type}`;
    // } else if (territory) {
    //   baseURL = baseURL + `territory=${territory}`;
    // }

    const params = { type, territory };

    getBuildings(params, searchText, page, size).then((data) => {
      setTotalElements(data.totalElements);
      setTableData(
        data.content.map((item) => {
          return {
            id: item.buildingId,
            data: [
              renderAddress(item),
              item.propertyShortName,
              item.type,
              properString(item.territory),
              {
                render: (
                  <>
                    {item.primaryClientId || item.primaryContactId ? (
                      <div
                        className="flex flex-row items-center text-newBlue-400"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (item.primaryClientType !== "CONTACT") {
                            navigate(
                              `/commercial/${item.primaryClientId}/summary`
                            );
                          } else {
                            // TODO: Looks like broken code;
                            navigate(
                              `/individual/${item.primaryClientId}/buildings`
                            );
                          }
                        }}
                      >
                        <div>{item.primaryClientName}</div>

                        <div className="px-2">
                          <GoToPageIcon />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ),
              },
              item.primaryClientName,
            ],
          };
        })
      );
    });
  };
  return (
    <div className="bg-white h-full w-full p-4 overflow-auto">
      <div className="flex flex-row justify-between items-center px-4 py-4">
        <div className="font-semibold text-lg">Buildings</div>
        <div className="flex flex-row items-center">
          <div>
            <button
              className="text-white bg-newBlue-400 rounded px-2 py-1.5"
              onClick={() => {
                navigate(`/clients/new`);
              }}
            >
              <div className="flex flex-row items-center">
                <div>
                  <AddIconBlue color={"#fff"} />
                </div>
                <div className="flex gap-1 px-2">
                  <span className="hidden md:inline">Add</span>
                  <span className="">New</span>
                  <span className="hidden md:inline">Building</span>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col border ">
        <div className="flex flex-col-reverse md:flex-row items-end gap-2 justify-between py-4 px-4">
          <div className="relative w-full md:w-3/12">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-newGray-300 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-800"
              placeholder="Search..."
              required
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div>
              <HeadlessFilterSelect
                onChange={(value) => {
                  setType(value);
                }}
                value={type}
                options={[
                  { title: "Building Type", value: "" },
                  { title: "SFH", value: "SFH" },
                  { title: "Apartment", value: "APARTMENT" },
                  { title: "HOA", value: "HOA" },
                ]}
              />
            </div>

            <div className="px-2">
              <HeadlessFilterSelect
                placeholder="Territory"
                value={territory}
                onChange={(value) => {
                  setTerritory(value);
                }}
                options={[
                  { label: "Territory", value: "" },
                  ...territories,
                ].map((item) => {
                  return { title: item.label, value: item.value };
                })}
              />
            </div>
          </div>
        </div>
        <div className="py-4">
          <div className="border-t ">
            <MaterialTable
              data={tableData}
              head={getTableHead()}
              headRenderColor="text-newGray-300"
              defaultOrderBy={"createdAt"}
              defaultOrder="desc"
              pageRows={size}
              onClickCB={(selection) => {
                navigate(`/building/${selection}/summary`);
              }}
              totalData={totalElements}
              currentPage={page}
              paginationCB={(value) => {
                setPage(value);
              }}
              rowChangeCB={(value) => {
                setSize(value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
