import { useEffect, useState } from "react";

import { alertError } from "../actions/AlertActions";
import {
  getBuilding,
  getBuildingUnits,
  getClient,
  getPipelines,
} from "../api/Api";
import { conditionalArrayEntry } from "../utils/ObjectUtills";
import { renderAddress } from "../utils/StringUtils";
import usePrevious from "./usePrevious";

export default function useTicketForm(data, setData) {
  const [pipelines, setPipelines] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const previousValue = usePrevious(data);
  const [contacts, setContacts] = useState([]);
  const [units, setUnits] = useState([]);

  useEffect(() => {
    if (previousValue && data.buildingId !== previousValue.buildingId) {
      if (data.buildingId)
        getBuilding(data.buildingId)
          .then((building) => {
            setContacts(building.contacts);
          })
          .catch((_err) => {
            alertError("Couldn't Fetch Select Building");
          });
      else setContacts([]);
    }
  }, [data.buildingId]);

  useEffect(() => {
    if (data.contactId && contacts.length > 0) {
      const dataContact = contacts.find(
        (contact) => data.contactId === contact.contactId
      );
      if (
        dataContact &&
        dataContact.unitNumber &&
        dataContact.unitNumber !== data.unitNumber
      ) {
        setData({ ...data, unitNumber: dataContact.unitNumber });
      }
    } else if (data.unitNumber) {
      setData((data) => ({ ...data }));
    }
  }, [data.contactId, contacts]);

  useEffect(() => {
    const stage = pipelines
      .find((pipeline) => pipeline.pipelineId === data.pipelineId)
      ?.stages.find((stage) => stage.stageId === data.stageId);
    // if (stage && stage.action === "SERVICE_REQUEST") {
    //   alertInfo("Please Create a Service Request before Closing the Ticket");
    // }
  }, [data.stageId]);

  useEffect(() => {
    data.clientId &&
      (previousValue == undefined ||
        previousValue?.clientId !== data.clientId) &&
      getClient(data.clientId).then((clientData) =>
        setBuildings(clientData.buildings)
      );

    getPipelines().then((pipelines) => setPipelines(pipelines));
  }, [buildings]);

  useEffect(() => {
    if (pipelines.length > 0 && data.pipelineId) {
      const pipelineStages = pipelines.find(
        (pipeline) => pipeline.pipelineId === data.pipelineId
      ).stages;
      if (pipelineStages.find((stage) => stage.stageId === data.stageId))
        return;
      const stageId = pipelineStages.find((stage) => stage.level === 1).stageId;
      setData((data) => ({ ...data, stageId }));
    }
  }, [pipelines, data.pipelineId]);

  useEffect(() => {
    // When buildingId changes, populate unitNumber options from API
    if (data.buildingId) {
      getBuildingUnits(data.buildingId)
        .then((res) => {
          setUnits(res.value);
        })
        .catch((_err) => {
          alertError("Couldn't Fetch Building Units");
        });
    } else {
      setUnits([]);
    }
  }, [data.buildingId]);

  const stageDropDown = () =>
    pipelines.length > 0 && data.pipelineId
      ? [
          {
            name: "stageId",
            inputType: "SELECT",
            defaultValue: "",
            options: pipelines
              .find((pipeline) => pipeline.pipelineId === data.pipelineId)
              ?.stages.map((stage) => ({
                value: stage.stageId,
                label: stage.stage,
              })),
          },
        ]
      : [];

  return [
    // ...(data?.ticketId
    //   ? []
    //   : [
    //       {
    //         name: "pipelineId",
    //         inputType: "SELECT",
    //         defaultValue: 1,
    //         options: pipelines.map((pipeline) => ({
    //           value: pipeline.pipelineId,
    //           label: pipeline.pipelineType,
    //         })),
    //       },
    //     ]),
    { name: "title" },
    {
      name: "priority",
      inputType: "SELECT",
      defaultValue: data.priority || "false",
      options: [
        { label: "High", value: "true" },
        { label: "Low", value: "false" },
      ],
    },
    ...conditionalArrayEntry(data?.clientId && !data?.ticketId, {
      name: "buildingId",
      inputType: "SELECT",
      options: buildings.map((building) => {
        return {
          label: renderAddress(building.address),
          value: building.buildingId,
        };
      }),
    }),
    {
      name: "unitNumber",
      defaultValue: data?.unitNumber
        ? data?.unitNumber
        : data?.alternateContact?.unitNumber
        ? data?.alternateContact?.unitNumber
        : "",
      variant: "AUTOCOMPLETE|FREESOLO",
      inputType: "SELECT",
      options: units.map((unit) => unit || "N/A"),
    },

    // { name: "description", inputType: "AREA", variant: "OUTLINED" },
    { name: "notes", inputType: "AREA", variant: "OUTLINED" },
    ...(data.confirmed === "false" && !(data.ticketId || data.serviceRequestId)
      ? [
          {
            name: "serviceItems",
            inputType: "CHILDFORM",
            variant: "jobType",
          },
        ]
      : []),
    ...stageDropDown(),
  ];
}
