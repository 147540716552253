import { useEffect, useState } from "react";



import { Tab } from "@headlessui/react";

import { getUnitById } from "../../../../api/Api";
import DropDown from "../../../../components/common/DropDown";
import LeaseHistory from "./LeaseHistory";
import RentLeaseTab from "./RentLease/RentLeaseTab";
import UnitDetailsTab from "./UnitDetailsTab";


type UnitDetailsScreenProps = {
  unitId: number;
  buildingName: string;
  buildingId: number;
  onBackClick: React.Dispatch<React.SetStateAction<string>>;
  leaseId?: number;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
  selectedTab: string;
  setLeaseId: React.Dispatch<React.SetStateAction<number>>;
};

export function UnitDetailsScreen({
  unitId,
  buildingName,
  buildingId,
  onBackClick,
  leaseId,
  selectedTab,
  setSelectedTab,
  setLeaseId,
}: UnitDetailsScreenProps) {
  const unitDetailsScreenTabs = [
    { label: "Unit Details", value: "unitDetails" },
    { label: "Lease Details", value: "leaseDetails" },
    { label: "Lease History", value: "leaseHistory" },
  ];

  const [unitNumber, setUnitNumber] = useState("");

  useEffect(() => {
    getUnitById(unitId).then((data) => {
      setLeaseId(data.currentLeaseId);
      setUnitNumber(data.unitNumber);
    });
  }, [unitId]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row p-2 w-max rounded bg-gray-200  text-xs font-normal text-gray-700 mb-4">
        <button
          className="hover:text-newBlue-400 focus:outline-none hover:underline mr-4"
          onClick={() => {
            onBackClick("unitListing");
            setSelectedTab("unitDetails");
          }}
        >
          {buildingName}
        </button>
        {`>`}
        <button
          className="hover:text-newBlue-400 focus:outline-none hover:underline mx-4"
          onClick={() => setSelectedTab("unitDetails")}
        >{`Unit #${unitNumber}`}</button>
        {`>  `}
        <div className="ml-4">{`${
          unitDetailsScreenTabs.find((item) => item.value === selectedTab)
            ?.label
        }`}</div>
      </div>
      <div className="text-2xl font-medium text-gray-900">
        {" "}
        {`Unit #${unitNumber}`}
      </div>

      <Tab.Group
        selectedIndex={unitDetailsScreenTabs.findIndex(
          (item) => item.value === selectedTab
        )}
        onChange={(index) => {
          if (index === 1) onBackClick("rentLeaseDetails");
          else setSelectedTab(unitDetailsScreenTabs[index].value);
        }}
      >
        <div className="flex flex-col md:flex-row  w-full  mt-6  ">
          <div className="w-32 md:block hidden">
            <div className=" border-gray-200 ">
              <Tab.List className=" flex flex-col " aria-label="Tabs">
                {unitDetailsScreenTabs.map((item) => (
                  <Tab
                    key={item.value}
                    className={"flex focus:outline-none w-32 border-r"}
                  >
                    {({ selected }) => (
                      <span
                        className={
                          "cursor-pointer border-transparent text-base hover:text-gray-700  whitespace-nowrap py-1 my-1 pl-2 font-medium " +
                          (selected
                            ? "text-newBlue-400 border-l-4 border-newBlue-400 "
                            : "text-newGray-800 ml-1")
                        }
                      >
                        {item.label}
                      </span>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>
          </div>
          <div className="flex md:hidden justify-center pb-4 z-10">
            <DropDown
              className="text-m p-2"
              aria-label="Tabs"
              options={unitDetailsScreenTabs.map((item) => ({
                channel: item.label, // Display Name
                value: item.value, // Value
              }))}
              onChangeCB={(index) => {
                const selectedOption = unitDetailsScreenTabs.find(
                  (item) => item.label === index
                );
                console.log(
                  "LOG: index",
                  index,
                  selectedOption.label,
                  selectedOption.value
                );
                if (selectedOption.label === "Lease Details")
                  onBackClick("rentLeaseDetails");
                else setSelectedTab(selectedOption.value);
              }}
              defaultValue={unitDetailsScreenTabs
                .map((item) => ({
                  channel: item.label, // Display Name
                  value: item.value, // Value
                }))
                .find((item) => item.value === selectedTab)}
            />
          </div>
          <div className="w-full">
            <Tab.Panels>
              <Tab.Panel>
                <UnitDetailsTab
                  unitId={unitId}
                  buildingId={buildingId}
                  onBackClick={onBackClick}
                />
              </Tab.Panel>
              <Tab.Panel>
                <RentLeaseTab
                  unitId={unitId}
                  leaseId={leaseId}
                  onBackClick={onBackClick}
                  buildingId={buildingId}
                  setLeaseId={setLeaseId}
                />
              </Tab.Panel>
              <Tab.Panel>
                <LeaseHistory
                  unitId={unitId}
                  setCurrentScreen={onBackClick}
                  setLeaseId={setLeaseId}
                />
              </Tab.Panel>
            </Tab.Panels>
          </div>
        </div>
      </Tab.Group>
    </div>
  );
}