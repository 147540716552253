import React, { useState } from "react";

import { CaretDown, CaretUp, HouseOutline } from "./ClientAppIcons";
import ListUnitMember from "./ListUnitMember";

const prependUnitNumber = (unitNumber) => {
  if (unitNumber === "") return "Building";
  // If the unit number is a number, prepend with `Unit #`
  else if (!isNaN(unitNumber)) return `Unit ${unitNumber}`;
  // Otherwise, return the unit number as-is
  else return unitNumber;
};

export default function RenderUnits({
  name,
  buildingId,
  unitContacts,
  buildingUnits,
  refreshCB,
}) {
  const [expanded, setExpanded] = useState(false);

  const overallCount = unitContacts.reduce(
    (acc, item) => {
      acc[item.position] += 1;
      return acc;
    }, // reducer
    {
      MANAGER: 0,
      PRIMARY_TENANT: 0,
      OWNER: 0,
      SUBTENANT: 0,
      ROOMMATE: 0,
      GUEST: 0,
      AUTHORIZED_OCCUPANT: 0,
      TENANT: 0,
    } // initial value
  );

  return (
    <>
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-col border w-full">
          <div
            className="flex flex-row justify-between items-center p-2"
            onClick={() => {
              setExpanded(true);
            }}
          >
            <div className="flex md:grid md:grid-rows-1 md:grid-cols-3 justify-between w-full items-center">
              <div className="flex items-center gap-2">
                <button className="bg-newGray-100 px-2 py-2 rounded">
                  <HouseOutline />
                </button>
                <div className="px-1 w-2/3">{prependUnitNumber(name)}</div>
              </div>

              <div className="hidden md:flex flex-row items-center gap-2">
                <span className="bg-newPink-100 text-newBlue-600 inline-flex items-center rounded px-2 py-1 text-sm font-medium estimateStatus">
                  Owners:{overallCount.OWNER}
                </span>
                <span className="bg-newOrange-100 text-newOrange-500 inline-flex items-center rounded px-2 py-1 text-sm font-medium estimateStatus">
                  Tenants:
                  {overallCount.TENANT +
                    overallCount.PRIMARY_TENANT +
                    overallCount.AUTHORIZED_OCCUPANT +
                    overallCount.GUEST +
                    overallCount.ROOMMATE +
                    overallCount.SUBTENANT}
                </span>
                {/* <span
                  className="inline-flex items-center rounded px-2 py-1 text-sm font-medium estimateStatus"
                  style={{
                    color: "#7B61FF",
                    backgroundColor: "#E4E2F7",
                  }}
                >
                  Managers:{overallCount.MANAGER}
                </span> */}
              </div>
              {/* Contacts Summary for Mobile View */}
              <div className="inline-flex md:hidden">
                <span
                  className="items-center rounded px-2 py-1 text-sm font-medium inline-flex md:hidden"
                  style={{
                    color: "#7B61FF",
                    backgroundColor: "#E4E2F7",
                  }}
                >
                  Contacts:
                  {overallCount.TENANT +
                    overallCount.MANAGER +
                    overallCount.PRIMARY_TENANT +
                    overallCount.AUTHORIZED_OCCUPANT +
                    overallCount.GUEST +
                    overallCount.ROOMMATE +
                    overallCount.SUBTENANT +
                    overallCount.OWNER}
                </span>
              </div>
            </div>

            <div
              className="p-2 rounded-md hover:bg-gray-200 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setExpanded((expanded) => !expanded);
              }}
            >
              {expanded ? <CaretUp /> : <CaretDown />}
            </div>
          </div>
          {expanded && (
            <div>
              {unitContacts.map((contact) => (
                <ListUnitMember
                  buildingId={buildingId}
                  buildingUnits={buildingUnits}
                  contact={contact}
                  refreshCB={refreshCB}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
