import React, { useCallback, useEffect, useState } from "react";
import ReactAudioPlayer from "react-audio-player";

import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import { CircularProgress, Paper } from "@mui/material";
import { Button, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import { navigate } from "raviger";

import { alertError } from "../actions/AlertActions";
import { useClientRef } from "../actions/ClientRefActions";
import {
  assignContactToBuildings,
  getForAllClients,
  getForAllFSOs,
  listClientContacts,
} from "../api/Api";
import CreateAssociate from "../components/associates/CreateAssociate";
import CreateAssociateContact from "../components/associates/CreateAssociateContact";
import ListAssociates from "../components/associates/ListAssociates";
import { CaretDown } from "../components/clients/ClientAppIcons";
import CreateContact from "../components/clients/CreateContact";
import CreateClient from "../components/clients/LegacyCreateClient";
import ListClients from "../components/clients/ListClients";
import ListProperties from "../components/clients/ListProperties";
import MessageComponent from "../components/clients/MessageComponent";
import {
  CallIconCRM,
  DashBoardIconCRM,
  DownArrowCRM,
  InternalIconCRM,
  LandingPageIconCRM,
  MMSIconCRM,
  MailIcon,
  MessageIconCRM,
  MissedCallCRM,
  PublicChatIconCRM,
  QuickReplyCRM,
  SearchIcon,
  UpArrowCRM,
  VoiceMailCRM,
  WebsiteBooking,
  WebsiteIconCRM,
  WebsiteRequest,
} from "../components/common/AppIcons";
import DropDown from "../components/common/DropDown";
import HeadlessDropdown from "../components/common/HeadlessDropdown";
import MaterialModal from "../components/common/MaterialModal";
import MaterialTable from "../components/common/MaterialTable";
import ExternalMessaging from "../components/crm/ExternalMessaging";
import { formatDate } from "../utils/DateUtils";
import { useTitle } from "../utils/RouterUtils";
import { previewText } from "../utils/StringUtils";

const PAGE_SIZE = 20;

const getNameAt = (nameString, index) => {
  const names = nameString.split(" ");
  if (names.length > index) {
    return names[index];
  }
  return "";
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper elevation={2} className="p-4 min-h-full">
          {children}
        </Paper>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const messageFilterOptions = [
  { label: "All Channels", value: null, icon: null },
  {
    label: "Email",
    value: "EMAIL",
    icon: MailIcon,
    bgClass: "bg-newBlue-800",
  },
  {
    label: "SMS",
    value: "SMS",
    icon: MessageIconCRM,
    bgClass: "bg-newViolet-100",
  },
  {
    label: "MMS",
    value: "MMS",
    icon: MMSIconCRM,
    bgClass: "bg-newOrange-400",
  },
  {
    label: "Voicemail",
    value: "VOICEMAIL",
    icon: VoiceMailCRM,
    bgClass: "bg-newPink-200",
  },
  {
    label: "Missed Call",
    value: "MISSEDCALL",
    icon: MissedCallCRM,
    bgClass: "bg-teal-100",
  },
  {
    label: "Call",
    value: "CALL",
    icon: CallIconCRM,
    bgClass: "bg-newGreen-200",
  },

  {
    label: "Website",
    value: "WEBSITE",
    icon: WebsiteIconCRM,
    bgClass: "bg-newYellow-600",
  },
  {
    label: "Landing Page",
    value: "LANDING_REQUEST",
    icon: LandingPageIconCRM,
    bgClass: "bg-newBlue-900",
  },
  {
    label: "Internal Notes",
    value: "INTERNAL_NOTES",
    icon: InternalIconCRM,
    bgClass: "bg-newRed-200",
  },
  {
    label: "Public Chat",
    value: "PUBLIC_CHAT",
    icon: PublicChatIconCRM,
    bgClass: "bg-newBlue-1000",
  },
  {
    label: "Dashboard",
    value: "DASHBOARD",
    icon: DashBoardIconCRM,
    bgClass: "bg-newRed-300",
  },
  {
    label: "Website Request",
    value: "WEBSITE_REQUEST",
    icon: WebsiteRequest,
    bgClass: "bg-newBlue-1100",
  },
  {
    label: "Website Booking",
    value: "WEBSITE_BOOKING",
    icon: WebsiteBooking,
    bgClass: "bg-newBlue-1200",
  },
].map((option) => {
  const Icon = option.icon;
  return {
    ...option,
    label: (
      <span className="flex items-center">
        {Icon ? <Icon className="w-5 h-5 mr-2" /> : <React.Fragment />}
        {option.label}
      </span>
    ),
  };
});

const getBackgroundClass = (message) => {
  // if (message.channel === "INTERNAL_NOTES") {
  //   return "bg-yellow-500";
  // } else
  if (message.isRead) {
    return "bg-gray-300";
  } else {
    return "bg-gray-100";
  }
};

const tabNames = [
  { label: "All Messages", value: "all" },
  { label: "Existing Clients", value: "existing" },
  { label: "New Leads", value: "new" },
  { label: "FSO Messages", value: "fso" },
];
export default function CRMHomePage() {
  const [value, setValue] = React.useState("all");
  const [inbox, setInbox] = useState([]);
  const [selected, setSelected] = useState();
  const [selectedFSO, setSelectedFSO] = useState();

  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [messageData, setMessageData] = useState({
    phone: " ",
    email: "",
  });
  const [linkBuildingModal, setLinkBuildingModal] = useState({
    display: false,
    clientId: null,
    data: [],
  });
  const [clientContact, setClientContact] = useState({});

  const [selectedProperty, setSelectedProperty] = useState([]);
  const [selectedContactId, setSelectedContactId] = useState();

  const [filterData, setFilterData] = useState([]);
  const [showMenu, setShowMenu] = useState(false);

  const [filter, setFilter] = useState({
    incoming: false,
    outgoing: false,
    page: 0,
    size: 10,
    select_value: { label: "All Channels", value: null },
  });

  const [refreshFlag, setRefreshFlag] = useState(false);

  const [quickReply, setQuickReply] = useState({
    display: false,
    clientId: null,
    contact: null,
    clientName: null,
    phone: null,
  });

  const { createClientWith } = useClientRef();

  useTitle("Omnichannel Inbox | SquadHub");

  const paginationCB = (new_page_no) => {
    setFilter({ ...filter, page: new_page_no });
  };

  const rowChangeCB = (new_size) => {
    setFilter({ ...filter, size: new_size });
  };

  const onSearch = (value) => {
    setSearchText(value);
  };

  useEffect(() => {
    let filteredData = inbox.content;

    if (filter.incoming && filter.outgoing) {
      setFilterData(inbox);
    } else {
      if (filter.incoming) {
        filteredData = filteredData.filter((item) => {
          return item.isOutgoing === false;
        });
      }

      if (filter.outgoing) {
        filteredData = filteredData.filter((item) => {
          return item.isOutgoing === true;
        });
      }
    }

    setFilterData(filteredData);
  }, [filter]);

  useEffect(() => {
    setFilterData(inbox.content);
  }, [inbox]);

  useEffect(() => {
    getData();
  }, [refreshFlag]);

  // Mark as `loading` when the value is null
  const setDataCallback = (value) => {
    if (value === null) {
      setLoading(true);
    } else {
      setInbox({ content: value.content, totalElements: value.totalElements });
      setLoading(false);
    }
  };

  const getData = () => {
    setDataCallback(null);

    let isOutGoingStatus;
    let unknownStatus;

    if (
      (filter.incoming && filter.outgoing) ||
      (filter.incoming === false && filter.outgoing === false)
    ) {
      isOutGoingStatus = null;
    } else if (filter.incoming) {
      isOutGoingStatus = false;
    } else {
      isOutGoingStatus = true;
    }

    if (value === "all") {
      unknownStatus = null;
    } else if (value === "existing" || value === "fso") {
      unknownStatus = false;
    } else if (value === "new") {
      unknownStatus = true;
    } else {
      unknownStatus = false;
    }

    let payload = {
      isOutgoing: isOutGoingStatus,
      channel: filter.select_value.value,
      search: searchText,
      pageRequest: {
        page: filter.page,
        size: filter.size,
      },
      unknown: unknownStatus,
    };

    if (value !== "fso") {
      getForAllClients(payload)
        .then(setDataCallback)
        .catch((_err) => {
          alertError("Error Loading Inbox");
        });
    } else {
      getForAllFSOs({ ...payload, unknown: undefined })
        .then(setDataCallback)
        .catch((_err) => {
          alertError("Error Loading Inbox");
        });
    }
  };

  useEffect(() => {
    getData();
  }, [value, filter, searchText]);

  return (
    <div className="w-full h-full">
      <div className="md:m-4 bg-white p-4 rounded-lg border-2">
        <div className="flex flex-col border-2 ">
          <div className="hidden md:flex md:flex-row w-full border-b">
            <div className="px-4">
              <div className=" border-gray-200">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabNames.map((item) => (
                    // TODO: Render & state needs be refactored as `<Link />`
                    <span
                      className={
                        "cursor-pointer border-transparent text-base hover:text-gray-700  whitespace-nowrap py-4 px-1 border-b-2 font-medium " +
                        (value === item.value
                          ? "text-newBlue-400 border-b-4 border-newBlue-400"
                          : "text-newGray-300")
                      }
                      onClick={() => {
                        setValue(item.value);
                      }}
                    >
                      {item.label}
                    </span>
                  ))}
                </nav>
              </div>
            </div>
          </div>

          <div className="flex md:hidden justify-center mt-4 pb-4 border-b z-10">
            <DropDown
              className="text-m p-2"
              aria-label="Tabs"
              options={tabNames.map((item) => ({
                channel: item.label, // Display Name
                value: item.value, // Value
              }))}
              onChangeCB={(index) => {
                const selectedOption = tabNames.find(
                  (item) => item.label === index
                );
                setValue(selectedOption.value);
              }}
              defaultValue={tabNames
                .map((item) => ({
                  channel: item.label, // Display Name
                  value: item.value, // Value
                }))
                .find((item) => item.value === value)}
            />
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center pt-6 px-4">
            <div className="flex flex-row items-center w-full">
              <p className="font-semibold text-newBlue-400 w-full m-2">
                <div className="relative md:w-52">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <SearchIcon />
                  </div>
                  <input
                    id="crm-search"
                    className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-800"
                    placeholder="Search..."
                    onChange={(e) => {
                      onSearch(e.target.value);
                    }}
                  />
                </div>
              </p>
            </div>
            <div className="flex flex-col md:flex-row items-center gap-4">
              {[
                {
                  label: "Incoming",
                  onChange: (e) => {
                    setFilter((current) => {
                      return { ...current, incoming: e.target.checked };
                    });
                  },
                },
                {
                  label: "Outgoing",
                  onChange: (e) => {
                    setFilter((current) => {
                      return { ...current, outgoing: e.target.checked };
                    });
                  },
                },
              ].map((item) => (
                <div className="items-center px-4">
                  <div className="flex flex-row items-center">
                    <div className="flex items-center">
                      <input
                        id={item.label}
                        aria-describedby={item.label}
                        name={item.label}
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded border-2"
                        onChange={item.onChange}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor={item.label}
                        className="font-medium text-gray-700 text-base leading-6"
                      >
                        {item.label}
                      </label>
                    </div>
                  </div>
                </div>
              ))}

              <div className="relative">
                <HeadlessDropdown
                  options={messageFilterOptions}
                  value={filter.select_value.value}
                  onChange={(value) => {
                    setFilter((current) => {
                      return {
                        ...current,
                        select_value: messageFilterOptions.find(
                          (item) => item.value === value
                        ),
                      };
                    });
                  }}
                >
                  <div className="flex flex-row w-32 items-center gap-2 text-gray-700 border-gray-300 border p-2 rounded-md">
                    <span className="text-base leading-6 font-medium">
                      {filter.select_value.label || "All Channels"}
                    </span>
                    <CaretDown className="h-3 w-3" />
                  </div>
                </HeadlessDropdown>
              </div>
            </div>
          </div>
          <div className="py-4">
            {loading ? (
              <div className="flex items-center h-full p-10 bg-white">
                <CircularProgress className="mx-auto" />
              </div>
            ) : (
              // Render Inbox as Material Table
              <MaterialTable
                currentPage={filter.page}
                rowChangeCB={rowChangeCB}
                paginationCB={paginationCB}
                pageRows={10}
                totalData={inbox.totalElements}
                data={filterData.map((row) => {
                  const isRead = row.isOutgoing || row.isRead;
                  const date = formatDate(row.createdAt);
                  const messageType = messageFilterOptions.find(
                    (item) => item.value === row.channel
                  );
                  const TypeIcon = messageType.icon;
                  return {
                    id: row.communicationId,
                    className: getBackgroundClass(row),
                    data: [
                      {
                        render: (
                          <div className="flex md:w-96 items-center">
                            <div
                              className={`flex w-4 h-4 md:w-9 md:h-9 flex-shrink-0 items-center justify-center rounded-full ${messageType.bgClass}`}
                            >
                              <TypeIcon className={`h-5 w-5 text-white`} />
                            </div>
                            <div className=" pl-2">
                              {row.channel === "VOICEMAIL" ||
                              row.channel === "CALL" ||
                              row.channel === "MISSEDCALL" ? (
                                <ReactAudioPlayer
                                  className="w-full"
                                  src={row.recordingUrl}
                                  autoPlay={false}
                                  controls
                                />
                              ) : (
                                <>
                                  <span
                                    className={`${
                                      isRead
                                        ? " font-medium text-gray-700"
                                        : " font-semibold"
                                    }`}
                                  >
                                    {row.channel === "EMAIL"
                                      ? row.subject
                                      : row.callerMetadata
                                      ? row.callerMetadata.name
                                      : "-"}
                                  </span>
                                  <p className="text-sm w-full  break-words text-gray-700 text-justify">
                                    {row.channel === "INTERNAL_NOTES"
                                      ? previewText(String(row.notes), 100)
                                      : row.content
                                      ? previewText(String(row.content), 100)
                                      : "----"}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                        ),
                        sortBy: row.content,
                      },
                      {
                        render: (
                          <div className="flex md:w-40">
                            {row.contactId ? (
                              <>
                                <div className="flex flex-row items-center">
                                  <div>
                                    <Avatar
                                      className="w-5 h-5 md:w-8 md:h-8"
                                      src={row.profilePicture}
                                    >
                                      {row.contactFirstName
                                        ? row.contactFirstName[0]
                                        : "U"}
                                    </Avatar>
                                  </div>
                                  <div>
                                    <div className="flex flex-col pl-1 md:pl-2 md:pr-1">
                                      <span
                                        className={`${
                                          isRead
                                            ? "font-medium text-black md:whitespace-nowrap"
                                            : "font-semibold"
                                        } py-1`}
                                      >
                                        {row.contactFirstName || "Unknown"}{" "}
                                        {row.contactLastName || ""}
                                      </span>
                                      <span className="text-sm text-newGray-300">
                                        {row.clientName}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              "-"
                            )}
                          </div>
                        ),
                        sortBy:
                          row.contactFirstName + " " + row.contactLastName,
                      },
                      {
                        render: (
                          <div className="md:px-2">
                            <span
                              className={
                                "inline-flex items-center rounded p-1 md:p-2 " +
                                (row.isOutgoing
                                  ? "bg-newOrange-300"
                                  : "bg-newBlue-700")
                              }
                            >
                              {row.isOutgoing ? (
                                <UpArrowCRM className="h-3 w-3 md:h-5 md:w-5" />
                              ) : (
                                <DownArrowCRM className="h-3 w-3 md:h-5 md:w-5" />
                              )}
                            </span>
                          </div>
                        ),
                        sortBy: row.fromActual,
                      },

                      {
                        render: (
                          <span className="text-black md:font-medium">
                            {row.fromActual}
                          </span>
                        ),
                        sortBy: row.fromActual,
                      },
                      {
                        render: (
                          <span className="text-black md:font-medium">
                            {row.toActual}
                          </span>
                        ),
                        sortBy: row.toActual,
                      },
                      {
                        render: (
                          <span className="text-xs text-black whitespace-nowrap font-medium">
                            {date}
                          </span>
                        ),
                        sortBy: new Date(row.createdAt).getTime(),
                      },
                      {
                        render: row.clientId ? (
                          <button
                            className="px-2 py-2  border border-newGray-700 rounded"
                            onClick={(e) => {
                              e.stopPropagation();
                              setQuickReply({
                                display: true,
                                contact: row.contactId,
                                clientId: row.clientId,
                                clientName: `${
                                  row.contactFirstName || "Unknown"
                                } ${row.contactLastName || ""}`,
                              });
                            }}
                          >
                            <div className="flex flex-row items-center">
                              <div>
                                <QuickReplyCRM className="h-5 w-5" />
                              </div>
                              <div className="px-2 whitespace-nowrap">
                                Quick Reply
                              </div>
                            </div>
                          </button>
                        ) : (
                          <></>
                        ),
                      },
                    ],
                  };
                })}
                head={[
                  { id: "conversation", label: "Conversation" },
                  ...(value === "fso"
                    ? [{ id: "associateName", label: "Associate" }]
                    : [{ id: "clientName", label: "Client Name" }]),
                  { id: "status", label: "Status" },
                  { id: "from", label: "From" },
                  { id: "to", label: "To" },
                  { id: "createdAt", label: "Date" },
                  { id: "", label: "" },
                ]}
                headRenderColor="text-newGray-300"
                defaultOrderBy={"createdAt"}
                defaultOrder="desc"
                onClickCB={(selection) => {
                  const selected = filterData.find(
                    (com) => com.communicationId === selection
                  );

                  if (value === "fso") {
                    navigate(
                      `/associates/${selected.associateId}/messages/${selected.contactId}`
                    );
                  } else {
                    if (selected.clientId) {
                      if (selected.contactId) {
                        navigate(
                          `/commercial/${selected.clientId}/messages/${selected.contactId}`
                        );
                      }
                    } else if (selected.contactId) {
                      if (selected.buildingId)
                        navigate(
                          `/building/${selected.buildingId}/messages/${selected.contactId}`
                        );
                      else
                        navigate(
                          `/individual/${selected.contactId}/messages/${selected.contactId}`
                        );
                    } else {
                      setSelectedFSO(
                        filterData.find(
                          (com) => com.communicationId === selection
                        )
                      );
                      setSelected(
                        filterData.find(
                          (com) => com.communicationId === selection
                        )
                      );
                    }
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
      {/* Message PopUp Modal */}
      <MaterialModal
        open={selected ? true : false}
        setOpen={(_) => setSelected(null)}
        extended
      >
        <div className="flex">
          <div>
            {!selected?.link && !selectedFSO?.link && (
              <div className="flex justify-between items-center bg-gray-400 rounded-lg gap-2 p-4">
                <span>External Contact</span>
                <button
                  className="p-2 rounded-md bg-gray-200 hover:bg-gray-300 text-gray-800"
                  onClick={(_) => {
                    createClientWith({
                      component: ExternalMessaging,
                      props: {
                        fromActual: selected.fromActual,
                        setMessageData: () => {},
                      },
                      prefill: {
                        // If fromActual is an email, prefill the email field
                        email: selected.fromActual.includes("@")
                          ? selected.fromActual
                          : "",
                        // If fromActual is a phone number, prefill the phone field
                        phone: selected.fromActual.includes("@")
                          ? ""
                          : selected.fromActual,
                        // If row.callerMetadata.name is not null, prefill the name field
                        firstName: getNameAt(
                          selected.callerMetadata?.name || "",
                          0
                        ),
                        // If row.callerMetadata.name is not null, prefill the name field
                        lastName: getNameAt(
                          selected.callerMetadata?.name || "",
                          1
                        ),
                      },
                    });
                    navigate("/clients/new");
                    // setSelected((selected) => {
                    //   return { ...selected, link: "EXISTING" };
                    // })
                  }}
                >
                  Link to Client
                </button>
                <button
                  className="p-2 rounded-md bg-gray-200 hover:bg-gray-300 text-gray-800"
                  onClick={(_) =>
                    setSelectedFSO((selected) => {
                      return {
                        ...selected,
                        link: "EXISTING",
                      };
                    })
                  }
                >
                  Link to FSO
                </button>
              </div>
            )}
            {selected && (
              <ExternalMessaging
                fromActual={selected.fromActual}
                setMessageData={setMessageData}
              />
            )}
          </div>
          {selectedFSO && selectedFSO.link === "NEW" && (
            <CreateAssociate
              editAssociate={
                selected.channel === "EMAIL" ||
                selected.channel === "WEBSITE_REQUEST" ||
                selected.channel === "LANDING_REQUEST"
                  ? {
                      email:
                        selected.fromActual.includes("<") &&
                        selected.fromActual.includes(">")
                          ? selected.fromActual.slice(
                              selected.fromActual.indexOf("<") + 1,
                              selected.fromActual.indexOf(">")
                            )
                          : selected.fromActual
                          ? selected.fromActual
                          : messageData.email
                          ? messageData.email
                          : "",

                      phone: messageData.phone,
                    }
                  : {
                      phone: selected.fromActual
                        ? selected.fromActual
                        : messageData.phone,
                      email: messageData.email,
                    }
              }
            />
          )}
          {selected && selected.link === "NEW" && (
            <CreateClient
              displayInCol={true}
              clientData={
                selected.channel === "EMAIL" ||
                selected.channel === "WEBSITE_REQUEST" ||
                selected.channel === "LANDING_REQUEST"
                  ? {
                      contact: {
                        email:
                          selected.fromActual.includes("<") &&
                          selected.fromActual.includes(">")
                            ? selected.fromActual.slice(
                                selected.fromActual.indexOf("<") + 1,
                                selected.fromActual.indexOf(">")
                              )
                            : selected.fromActual
                            ? selected.fromActual
                            : messageData.email
                            ? messageData.email
                            : "",

                        phone: messageData.phone,
                      },
                    }
                  : {
                      contact: {
                        phone: selected.fromActual
                          ? selected.fromActual
                          : messageData.phone,
                        email: messageData.email,
                      },
                    }
              }
            />
          )}
          {selectedFSO &&
            selectedFSO.link === "EXISTING" &&
            !selectedFSO.associateId && (
              <ListAssociates
                tab={"TPV"}
                newAssociate={(_) =>
                  setSelectedFSO((selected) => {
                    return { ...selected, link: "NEW" };
                  })
                }
                onSelect={(associateId) => {
                  setSelectedFSO((selected) => {
                    return {
                      ...selected,
                      associateId: associateId,
                    };
                  });
                }}
              />
            )}

          {selected && selected.link === "EXISTING" && !selected.clientId && (
            <ListClients
              newClient={(_) =>
                setSelected((selected) => {
                  return { ...selected, link: "NEW" };
                })
              }
              onSelect={(clientId, clientType) =>
                setSelected((selected) => {
                  return { ...selected, clientId: clientId, type: clientType };
                })
              }
            />
          )}
          {selectedFSO &&
            selectedFSO.link === "EXISTING" &&
            selectedFSO.associateId && (
              <CreateAssociateContact
                associateId={selectedFSO.associateId}
                baseData={
                  selectedFSO.channel === "EMAIL" ||
                  selectedFSO.channel === "WEBSITE_REQUEST" ||
                  selectedFSO.channel === "LANDING_REQUEST"
                    ? {
                        associate: { associateId: selectedFSO.associateId },
                        email:
                          selectedFSO.fromActual.includes("<") &&
                          selectedFSO.fromActual.includes(">")
                            ? selectedFSO.fromActual.slice(
                                selectedFSO.fromActual.indexOf("<") + 1,
                                selectedFSO.fromActual.indexOf(">")
                              )
                            : selectedFSO.fromActual
                            ? selectedFSO.fromActual
                            : messageData.email
                            ? messageData.email
                            : "",
                        phone: messageData.phone,
                      }
                    : {
                        associate: { associateId: selectedFSO.associateId },
                        phone: selectedFSO.fromActual
                          ? selectedFSO.fromActual
                          : messageData.phone,
                        email: messageData.email || "",
                      }
                }
                successCB={(newContactId) => {
                  navigate(
                    `/associates/${selectedFSO.associateId}/messages/${newContactId}`
                  );
                }}
              />
            )}
          {selected && selected.link === "EXISTING" && selected.clientId && (
            <CreateContact
              baseData={
                selected.channel === "EMAIL" ||
                selected.channel === "WEBSITE_REQUEST" ||
                selected.channel === "LANDING_REQUEST"
                  ? {
                      client: { clientId: selected.clientId },
                      email:
                        selected.fromActual.includes("<") &&
                        selected.fromActual.includes(">")
                          ? selected.fromActual.slice(
                              selected.fromActual.indexOf("<") + 1,
                              selected.fromActual.indexOf(">")
                            )
                          : selected.fromActual
                          ? selected.fromActual
                          : messageData.email
                          ? messageData.email
                          : "",
                      phone: messageData.phone,
                    }
                  : {
                      client: { clientId: selected.clientId },
                      phone: selected.fromActual
                        ? selected.fromActual
                        : messageData.phone,
                      email: messageData.email || "",
                    }
              }
              successCB={(newContactId) => {
                //open the client modal with the contact selected
                setSelectedContactId(newContactId);
                if (selected.type === "COMMERCIAL") {
                  listClientContacts(selected.clientId).then((contacts) => {
                    let contact = contacts.find((item) => {
                      if (item.contactId === newContactId) return true;
                    });

                    setClientContact(contact);
                  });

                  setLinkBuildingModal({
                    clientId: selected.clientId,
                    display: true,
                    data: [],
                  });
                } else {
                  setLinkBuildingModal({
                    clientId: selected.clientId,
                    display: false,
                    data: [],
                  });

                  if (selected.clientId) {
                    navigate(
                      `/commercial/${selected.clientId}/messages/${newContactId}`
                    );
                  } else {
                    navigate(`/individual/${newContactId}/messages`);
                  }
                }
              }}
            />
          )}
        </div>
      </MaterialModal>
      {/* Link Buildings Modal */}
      <MaterialModal
        open={linkBuildingModal.display}
        setOpen={(_) =>
          setLinkBuildingModal((_) => {
            return { clientId: null, display: false, data: [] };
          })
        }
        extended
      >
        <Typography variant="h6" className="mb-4">
          Would you like to link {clientContact?.firstName}{" "}
          {clientContact?.lastName} to a building?
        </Typography>
        <ListProperties
          clientId={linkBuildingModal.clientId}
          multiple={true}
          crmHome={true}
          multiSelectCB={(properties) => {
            setSelectedProperty(properties);
          }}
        />
        <div className="py-4 flex-row">
          <Button
            onClick={(_) => {
              assignContactToBuildings(
                selectedContactId,
                selectedProperty
              ).then(() => {
                if (selected.clientId) {
                  navigate(
                    `/commercial/${selected.clientId}/messages/${clientContact.contactId}`
                  );
                } else {
                  navigate(`/individual/${selected.contactId}/messages`);
                }
              });
            }}
            color="primary"
            className="float-right p-4"
          >
            Link
          </Button>
          <Button
            onClick={(_) => {
              if (selected.clientId) {
                navigate(
                  `/commercial/${selected.clientId}/messages/${clientContact.contactId}`
                );
              } else {
                navigate(`/individual/${selected.contactId}/messages`);
              }
            }}
            color="primary"
            className="float-right p-4"
          >
            Cancel
          </Button>
        </div>
      </MaterialModal>
      {/* Quick Reply Modal */}
      <MaterialModal
        containerClassName={"flex flex-col"}
        open={quickReply.display}
        setOpen={(_) =>
          setQuickReply({ display: false, contact: null, clientId: null })
        }
        extended
      >
        <div className="py-2">
          <div className="flex flex-row justify-between">
            <div>
              <Typography
                variant="h6"
                className="font-semibold cursor-pointer"
                onClick={() => {
                  if (quickReply.clientId) {
                    navigate(
                      `/commercial/${quickReply.clientId}/messages/${quickReply.contact}`
                    );
                  } else {
                    navigate(`/individual/${quickReply.contact}/messages`);
                  }
                }}
              >
                {quickReply.clientName}
              </Typography>
            </div>
            <div>
              {/* <CallIcon
                onClick={(_) => {
                  startCall({
                    number: quickReply.phone
                  });
                }}
                className="cursor-pointer"
              /> */}
            </div>
          </div>
        </div>
        {quickReply.contact && (
          <ExternalMessaging
            contactIds={[quickReply.contact || []]}
            refreshFlag={refreshFlag}
          />
        )}
        {quickReply.contact && (
          <MessageComponent
            contactId={quickReply.contact}
            clientId={quickReply.clientId}
            setRefreshFlag={setRefreshFlag}
          />
        )}
      </MaterialModal>
    </div>
  );
}
