import React from "react";

import CommentIcon from "@mui/icons-material/Comment";
import LaunchIcon from "@mui/icons-material/Launch";
import { Avatar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "raviger";

import { renderAddress } from "../../utils/StringUtils";
import PopOverText from "../common/PopOverText";
import { jobTypes } from "../common/form/constants";

const PopOverContent = ({ client, setForUpdate }) => {
  return (
    <div
      style={{ minWidth: "350px" }}
      className="flex w-full p-3 max-w-sm flex-col"
    >
      <div className="flex items-center w-full text-sm">
        <div className="text-gray-900 flex w-full items-center font-medium ">
          <Avatar className="w-8 h-8 text-base">
            <span className="text-base">{client.clientName}</span>
          </Avatar>

          <span className="px-2">{client.clientName}</span>

          <div className="flex ml-auto text-gray-500">
            {`${client.ticketId}-${
              !client.isDraft
                ? "SR" + client.serviceRequestIndex
                : "I" + client.serviceRequestIndex
            }`}
            {/* SR #{client.serviceRequestId} */}
          </div>
        </div>
      </div>
      <div className="ml-auto mt-3 flex gap-2">
        <span
          className="outline-none focus:outline-none"
          onClick={(_) =>
            setForUpdate({
              ...client,
              startTime: client.timelineStart,
              endTime: client.timelineEnd,
              associate: client.associateId,
            })
          }
        >
          <LaunchIcon className="text-lg text-gray-700" />
        </span>
        <Link
          href={`/clients/${client.clientId}/messages`}
          className="outline-none focus:outline-none"
        >
          <CommentIcon className="text-lg text-gray-700" />
        </Link>
      </div>

      <Typography className="my-2 text-gray-600" variant="caption">
        {new Date(client.timelineStart).toLocaleTimeString()} to{" "}
        {new Date(client.timelineEnd).toLocaleTimeString()}
      </Typography>
      <Typography variant="subtitle2">Job Location</Typography>
      <Typography className="my-2 text-gray-600" variant="caption">
        {renderAddress(client)}
      </Typography>
      <Typography variant="subtitle2">Job Description</Typography>
      <Typography className="my-2 text-gray-600" variant="caption">
        {client?.description}
      </Typography>
    </div>
  );
};

export default function ScheduleItem({
  territory,
  setForUpdate,
  current,
  backgroundFor,
  slotDuration,
}) {
  return (
    <td
      className={
        "text-sm relative group border-gray-500 cursor-pointer p-1 border-b "
        // (slotDuration % 2 === 0 ? "border-r" : "")
      }
      colSpan={slotDuration}
    >
      <PopOverText
        text={<PopOverContent client={current} setForUpdate={setForUpdate} />}
      >
        <div
          className={
            "w-full h-full flex flex-col px-2 py-1 rounded-md " +
            backgroundFor(current.appointmentStatus) +
            (territory !== current.territory ? " opacity-50" : "")
          }
          style={{ minWidth: "100px" }}
          onClick={(_) =>
            setForUpdate({
              ...current,
              startTime: current.timelineStart,
              endTime: current.timelineEnd,
              associate: current.associateId,
            })
          }
        >
          <div className="flex flex-row items-center gap-1">
            <span
              className=" text-normal text-black my-auto w-full"
              style={{ fontWeight: 500 }}
            >
              {current.clientName?.length > 19
                ? current.clientName?.slice(0, 17) + ".."
                : current.clientName}
            </span>
          </div>
          <div className="flex flex-row items-center justify-between gap-1">
            <span className="text-newGray-300 text-xs">
              {jobTypes.find((j) => j.value === current.jobType)?.label}
            </span>
            <span className="text-xs text-newGray-300 w-6/12">
              {`${current.ticketId}-${
                !current.isDraft
                  ? "SR" + current.serviceRequestIndex
                  : "I" + current.serviceRequestIndex
              }`}
              {/* {current.serviceRequestId} */}
            </span>
          </div>
        </div>
      </PopOverText>
    </td>
  );
}
