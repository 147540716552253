import React, { useEffect, useState } from "react";

import BusinessIcon from "@mui/icons-material/Business";
import { Button, Typography } from "@mui/material";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import {
  createBuilding,
  createLocation,
  getSupplier,
  getTerritory,
  listSupplierContacts,
} from "../../api/Api";
import states from "../../assets/states";
import { deepUpdate } from "../../utils/StateUtils";
import { properString } from "../../utils/StringUtils";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import { getTerritoryFromZIP } from "../crm/chat/zip";

//

export const primaryContact = (contacts) => {
  let primaryContactOptions = [];
  contacts.forEach((contact) => {
    primaryContactOptions.push({
      value: contact.contactId,
      label: `${contact.firstName} ${contact.lastName}`,
    });
  });

  return primaryContactOptions;
};

const formBuilding = (data, clientContacts, subAddressOptions, territories) => {
  return [
    {
      name: "primaryContactId",
      inputType: "SELECT",
      options: data.contacts
        ? primaryContact(data.contacts)
        : primaryContact(clientContacts),
      defaultValue: data.contacts
        ? primaryContact(data.contacts)[0]?.value
        : clientContacts
        ? data.primaryContactId
          ? data.primaryContactId
          : primaryContact(clientContacts)[0]?.value
        : primaryContact(clientContacts)[0]?.value,
    },
    {
      name: "address",
      inputType: "ADDRESS",
    },
    {
      name: "territory",
      inputType: "SELECT",
      options: territories,
    },
  ];
};

export default function CreateBuilding({
  baseData,
  onSubmitCB,
  successCB,
  onCancel,
}) {
  const [data, setData] = useState(baseData ?? {});
  const [clientContacts, setClientContacts] = useState([]);
  const [subAddressOptions, setSubAddressOptions] = useState([]);
  const [territories, setTerritories] = useState([]);

  useEffect(() => {
    getTerritory().then((res) => {
      let temp = [];
      res.map((item) => {
        temp.push({ value: item.name, label: properString(item.name) });
      });
      setTerritories(temp);
    });
  }, []);

  const onChange = (update) => {
    const { name, value } = update;

    if (name === "address.zip") {
      let territory = getTerritoryFromZIP(value);
      setData((data) => deepUpdate("territory", territory, data));
    }

    setData((data) => deepUpdate(name, value, data));
  };

  const fetchData = () => {
    if (baseData.client)
      getSupplier(baseData.supplier.supplierId)
        .then((clientData) => {
          setData({
            ...data,
            primaryContactId: clientData.primaryContactId,
            address: { state: "CA" },
          });
          return;
        })
        .catch((_) => alertError("Error Loading Supplier Locations"));
  };

  useEffect(
    (_) => {
      fetchData();

      const updateGeoCordinates = {
        geocodeLatitude: baseData?.geocodeLatitude,
        geocodeLongitude: baseData?.geocodeLongitude,
      };

      if (!baseData.contacts) {
        listSupplierContacts(baseData?.supplier.supplierId).then((contacts) => {
          setClientContacts(contacts);
        });
      }

      setData((data) => {
        return { ...data, ...updateGeoCordinates };
      });
    },
    [baseData]
  );
  useEffect(
    (_) => {
      console.log("onChange", data);
    },
    [data]
  );
  const onSubmit = (event) => {
    event.preventDefault();
    if (onSubmitCB) {
      console.log("Callback");
      return onSubmitCB(event);
    } else {
      createLocation(data)
        .then((newBuildingId) => {
          data.buildingId
            ? alertSuccess("Successfully Updated Location")
            : alertSuccess("Successfully Created Location");
          successCB && successCB(newBuildingId.value);
        })
        .catch((_) => {
          data.buildingId
            ? alertError("Error Updating Location")
            : alertError("Error Creating Location");
        });
    }
  };
  return (
    <div className="max-w-sm w-full">
      <MaterialForm
        data={data}
        renderArray={
          data.type !== "RESIDENTIAL"
            ? formBuilding(data, clientContacts, subAddressOptions, territories)
            : formBuilding(
                data,
                clientContacts,
                subAddressOptions,
                territories
              ).slice(1)
        }
        onSubmit={onSubmit}
        onChange={onChange}
      />

      <div className="float-right">
        <Button color="primary" className="float-right p-4" onClick={onSubmit}>
          Save
        </Button>
        <Button className="float-right p-4" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
