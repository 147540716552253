import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import MessageIcon from "@mui/icons-material/Message";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import { TextField } from "@mui/material";

import {
  alertError,
  alertInfo,
  alertSuccess,
} from "../../actions/AlertActions";
import {
  sendMessage, // saveInternalNotes,
  listClientContacts,
} from "../../api/Api";
import { useCopyTo } from "../../hooks/useCopyTo";
import { deepUpdate } from "../../utils/StateUtils";
import { properStrings } from "../../utils/StringUtils";
import MaterialForm from "../common/form/MaterialForm";

const addEncodedFile = (setContent, file) =>
  function (upload) {
    setContent((content) => {
      return {
        ...content,
        attachments: [
          ...(content.attachments ?? []),
          {
            fileName: file.name,
            contentType: file.type,
            encodedFile: upload.target.result,
          },
        ],
      };
    });
  };

export default function MessageComponent({
  contactId,
  clientId,
  setRefreshFlag,
}) {
  const fileRef = useRef();
  const [channel, setChannel] = useState("SMS");
  const [content, setContent] = useState("");
  const [editNotesFlag, triggerEditNotes] = useState(false);
  const clientType = clientId ? "COMMERCIAL" : "INDIVIDUAL";
  const copytoFields = useCopyTo(
    clientType === "INDIVIDUAL" ? contactId : clientId,
    clientType
  );

  const onDrop = useCallback((acceptedFiles) => {
    Array.from(acceptedFiles).forEach((file) => {
      // console.log("MIME:" + file.type);
      var reader = new FileReader();
      reader.onload = addEncodedFile(setContent, file);
      reader.readAsDataURL(file);
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSelectFile = (event) => {
    // console.log("Uploading to S3");
    var files = event.target.files;
    Array.from(files).forEach((file) => {
      // console.log("MIME:" + file.type);
      var reader = new FileReader();
      reader.onload = addEncodedFile(setContent, file);
      reader.readAsDataURL(file);
    });
  };
  const dispatchMessage = (content, foreground) =>
    sendMessage({
      channel: channel === "SMS" && content.attachments ? "MMS" : channel,
      contactId: content.contactId ?? contactId,
      content: content.text,
      notes: content.notes,
      subject: channel === "EMAIL" ? content.subject : null,
      attachments: content.attachments,
    })
      .then((_) => {
        if (foreground) {
          // updateMessages();
          setRefreshFlag((current) => !current);
          alertSuccess("Sent Message");
          setContent({ subject: "", text: "", notes: "" });
        }
      })
      .catch((_) => alertError("Error Sending Message"));

  return (
    <div className=" bottom-0 bg-white px-4 py-4 flex gap-2 items-center">
      <div
        className="cursor-pointer"
        onClick={(_) =>
          setChannel((current) => (current === "SMS" ? "EMAIL" : "SMS"))
        }
      >
        {channel === "SMS" ? <MessageIcon /> : <EmailIcon />}
      </div>
      <div
        className="flex-1 flex flex-col gap-2 mx-4"
        {...getRootProps()}
        onClick={(_) => {}}
      >
        {content.copyTo && (
          <MaterialForm
            className="p-1"
            data={content}
            renderArray={copytoFields}
            onChange={(update) => {
              const { name, value } = update;
              setContent((data) => deepUpdate(name, value, data));
            }}
          />
        )}
        {channel === "EMAIL" && (
          <input
            className="w-full border rounded px-2 py-2"
            placeholder="Subject"
            value={content.subject}
            onChange={(e) =>
              setContent({ ...content, subject: e.target.value })
            }
          />
        )}
        <TextField
          variant="standard"
          className={
            "w-full border rounded px-2 py-2 message-text-area " +
            (editNotesFlag ? "bg-yellow-200" : "bg-white")
          }
          multiline
          minRows={3}
          maxRows={8}
          placeholder={
            editNotesFlag
              ? "Type in Notes for Internal Reference"
              : "New Message"
          }
          value={editNotesFlag ? content.notes : content.text}
          onChange={
            editNotesFlag
              ? (e) => setContent({ ...content, notes: e.target.value })
              : (e) => setContent({ ...content, text: e.target.value })
          }
        />
        {content.attachments?.map((attachment) => (
          <p>
            File: {attachment.fileName}
            <CloseIcon
              className="cursor-pointer"
              onClick={(e) =>
                setContent({ ...content, attachments: undefined })
              }
            />
          </p>
        ))}
        <div className="flex gap-2">
          {/* <span
            className="text-sm text-gray-700"
            onClick={(_) => triggerEditNotes((current) => !current)}
          >
            {editNotesFlag
              ? "Switch to Messaging"
              : content.notes
              ? "Edit Notes"
              : "Add Notes"}
          </span> */}
          {content.copyTo ? (
            <span
              className="text-sm text-gray-700 cursor-pointer"
              onClick={(_) => {
                setContent((content) => {
                  return { ...content, copyTo: undefined };
                });
              }}
            >
              Remove Bcc
            </span>
          ) : (
            <span
              className="text-sm text-gray-700 cursor-pointer"
              onClick={(_) => {
                setContent((content) => {
                  return { ...content, copyTo: [] };
                });
              }}
            >
              Add Bcc
            </span>
          )}
        </div>
      </div>
      <div>
        <AttachFileIcon
          className="cursor-pointer"
          onClick={(_) => fileRef.current.click()}
        />
        <input
          type="file"
          className="hidden"
          onChange={handleSelectFile}
          ref={fileRef}
          multiple
        />
        <input className="hidden" {...getInputProps()} />
      </div>
      <div>
        <DynamicIcon
          icon={editNotesFlag ? SaveIcon : SendIcon}
          className="cursor-pointer send-icon"
          onClick={(_) => {
            alertInfo("Sending Message", undefined, { loading: true });
            dispatchMessage(content, true);
            if (content.copyTo?.length > 0)
              content.copyTo.forEach((copy) =>
                dispatchMessage({
                  ...content,
                  contactId: Number(copy),
                })
              );
          }}
        />
      </div>
    </div>
  );
}

const DynamicIcon = (props) => {
  const Icon = props.icon;
  return <Icon {...props} />;
};
