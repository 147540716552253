import { useEffect, useState } from "react";



import { Tab } from "@headlessui/react";

import { getLeaseById, getUnitById } from "../../../../../api/Api";
import DropDown from "../../../../../components/common/DropDown";
import { conditionalArrayEntry } from "../../../../../utils/ObjectUtills";
import DepositTab from "./DepositTab";
import DocumentsTab from "./DocumentsTab";
import LateFeesTab from "./LateFeesTab";
import RentLeaseTab from "./RentLeaseTab";
import TenantsTab from "./TenantsTab";


type UnitDetailsScreenProps = {
  unitId: number;
  buildingName: string;
  buildingId: number;
  onBackClick: React.Dispatch<React.SetStateAction<string>>;
  setNewLeaseId: React.Dispatch<React.SetStateAction<number>>;
  leaseIdParam?: number;
};

export function RentLeaseScreen({
  unitId,
  buildingName,
  buildingId,
  onBackClick,
  leaseIdParam,
  setNewLeaseId,
}: UnitDetailsScreenProps) {
  const unitDetailsScreenTabs = [
    { label: "Rent/Lease", value: "rentLease" },
    { label: "Tenants", value: "tenants" },
    { label: "Deposit", value: "deposit" },
    { label: "Late Fees", value: "lateFees" },
    { label: "Documents", value: "documents" },
    ...conditionalArrayEntry(
      leaseIdParam !== undefined && leaseIdParam !== null && leaseIdParam !== 0,
      { label: "Screening", value: "screening" }
    ),
  ];

  const [selectedTab, setSelectedTab] = useState("rentLease");

  const [leaseId, setLeaseId] = useState(0);

  const [unitNumber, setUnitNumber] = useState("");

  useEffect(() => {
    getUnitById(unitId).then((data) => {
      setUnitNumber(data.unitNumber);
    });
  }, [unitId]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row p-2 w-max rounded bg-gray-200  text-xs font-normal text-gray-700 mb-4">
        <button
          className="hover:text-newBlue-400 focus:outline-none hover:underline mr-4"
          onClick={() => {
            onBackClick("unitListing");
            setSelectedTab("unitDetails");
          }}
        >
          {buildingName}
        </button>
        {`>`}
        <button
          className="hover:text-newBlue-400 focus:outline-none hover:underline mx-4"
          onClick={() => {
            onBackClick("unitDetails");
            setSelectedTab("unitDetails");
          }}
        >{`Unit #${unitNumber}`}</button>
        {`>  `}
        <div className="ml-4">{`${
          unitDetailsScreenTabs.find((item) => item.value === selectedTab)
            ?.label
        }`}</div>
      </div>
      <div className="text-2xl font-medium text-gray-900">
        {leaseIdParam === undefined || leaseIdParam === null
          ? `Lease ${leaseId === 0 ? "" : `#${leaseId}`}`
          : `Lease #${leaseIdParam ? leaseIdParam : leaseId}`}
      </div>
      <Tab.Group
        selectedIndex={unitDetailsScreenTabs.findIndex(
          (item) => item.value === selectedTab
        )}
        onChange={(index) => {
          if (index === 5) onBackClick("tenantScreening");
          setSelectedTab(unitDetailsScreenTabs[index].value);
        }}
      >
        <div className="flex flex-col md:flex-row  w-full  mt-6">
          <div className="w-32  md:block hidden">
            <div className=" border-gray-200">
              <Tab.List className=" flex flex-col " aria-label="Tabs">
                {unitDetailsScreenTabs.map((item) => (
                  <Tab
                    key={item.value}
                    className={"flex focus:outline-none w-32 border-r "}
                  >
                    {({ selected }) => (
                      <span
                        className={
                          "cursor-pointer border-transparent text-base hover:text-gray-700  whitespace-nowrap py-1 my-1 pl-2 font-medium " +
                          (selected
                            ? "text-newBlue-400 border-l-4 border-newBlue-400 "
                            : "text-newGray-800 ml-1")
                        }
                      >
                        {item.label}
                      </span>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>
          </div>
          <div className="flex md:hidden justify-center pb-4 z-10">
            <DropDown
              className="text-m p-2"
              aria-label="Tabs"
              options={unitDetailsScreenTabs.map((item) => ({
                channel: item.label, // Display Name
                value: item.value, // Value
              }))}
              onChangeCB={(index) => {
                const selectedOption = unitDetailsScreenTabs.find(
                  (item) => item.label === index
                );
                console.log(
                  "LOG: index",
                  index,
                  selectedOption.label,
                  selectedOption.value
                );
                if (selectedOption.label === "Screening")
                  onBackClick("tenantScreening");
                else setSelectedTab(selectedOption.value);
              }}
              defaultValue={unitDetailsScreenTabs
                .map((item) => ({
                  channel: item.label, // Display Name
                  value: item.value, // Value
                }))
                .find((item) => item.value === selectedTab)}
            />
          </div>
          <Tab.Panels>
            <Tab.Panel>
              <RentLeaseTab
                unitId={unitId}
                leaseId={leaseIdParam ? leaseIdParam : leaseId}
                onBackClick={onBackClick}
                buildingId={buildingId}
                setLeaseId={setLeaseId}
                setNewLeaseId={setNewLeaseId}
              />
            </Tab.Panel>
            <Tab.Panel>
              <TenantsTab leaseId={leaseIdParam ? leaseIdParam : leaseId} />
            </Tab.Panel>
            <Tab.Panel>
              <DepositTab
                leaseId={leaseIdParam ? leaseIdParam : leaseId}
                onBackClick={onBackClick}
              />
            </Tab.Panel>
            <Tab.Panel>
              <LateFeesTab
                leaseId={leaseIdParam ? leaseIdParam : leaseId}
                onBackClick={onBackClick}
                buildingId={buildingId}
              />
            </Tab.Panel>
            <Tab.Panel>
              <DocumentsTab leaseId={leaseIdParam ? leaseIdParam : leaseId} />
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
}