import { useEffect, useRef, useState } from "react";

import { alertError } from "../../../actions/AlertActions";
import { getUnits } from "../../../api/Api";
import { BuildingIcon } from "../../../components/clients/ClientAppIcons";
import { SearchIcon } from "../../../components/common/AppIcons";
import { deepUpdate } from "../../../utils/StateUtils";
import { UnitData } from "./NewUnitScreen";

type CopyUnitProps = {
  buildingId: number;
  onChange: (value: React.SetStateAction<UnitData>) => void;
  onSuccess: () => void;
};

type UnitDetails = {
  marketRent: number;
  unitSize: number;
  beds: number;
  bath: string | number;
};
type Units = {
  unitNumber: number;
  unitDetails: UnitDetails;
};

export default function CopyUnitDetails({
  buildingId,
  onChange,
  onSuccess,
}: CopyUnitProps) {
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] = useState([]);
  const listInnerRef = useRef();
  const [currPage, setCurrPage] = useState(0); // storing current page number
  const [totalPages, setTotalPages] = useState(10); // storing list

  useEffect(() => {
    setCurrPage(0);
    setListData([]);
  }, [searchText]);

  useEffect(() => {
    getUnits(currPage, 5, buildingId, searchText, "")
      .then((data) => {
        setListData([...listData, ...data.content]);
        setTotalPages(data.totalPages);
      })
      .catch((_) =>
        alertError("Couldn't Connect to Server, Check your Connection")
      );
  }, [searchText, currPage]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (currPage !== totalPages) {
          setCurrPage(currPage + 1);
        }
        // This will be triggered after hitting the last element.
        // API call should be made here while implementing pagination.
      }
    }
  };

  const handleClickItem = (item: UnitDetails) => {
    onChange((data) => deepUpdate("bath", item.bath, data));
    onChange((data) => deepUpdate("marketRent", item.marketRent, data));
    onChange((data) => deepUpdate("beds", item.beds, data));
    onChange((data) => deepUpdate("unitSize", item.unitSize, data));
    onSuccess();
  };

  return (
    <div className="flex flex-col w-full">
      <div className="text-xl font-semibold text-black mb-5">
        Copy Unit Details
      </div>
      <p className="font-semibold text-newBlue-400 w-full">
        <div className="relative ">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <SearchIcon />
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-900 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-800"
            placeholder="Search for Unit Number"
            required
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>
      </p>
      <div
        className="flex flex-col mt-4 h-112 overflow-auto"
        onScroll={onScroll}
        ref={listInnerRef}
      >
        {listData.map((item: Units) => (
          <button
            className="flex flex-row p-4 border-b border-newGray-600"
            onClick={() => handleClickItem(item.unitDetails)}
          >
            <div className="flex justify-center items-center h-14 w-14 rounded bg-yellow-100">
              {" "}
              <BuildingIcon />
            </div>
            <div className="flex flex-col ml-3 w-full items-start">
              <div className="text-lg font-medium text-black min-w-max i bg-yellow-100 m-1 p-1 rounded">
                {item.unitNumber}
              </div>
              <div className="flex flex-row w-80">
                <div className="flex flex-col items-start w-2/3">
                  <div className=" text-md font-normal text-black">{`Market rent: $${item.unitDetails.marketRent}`}</div>
                  <div className=" text-md font-normal text-black">{`Unit Size: ${item.unitDetails.unitSize} sq.ft.`}</div>
                </div>
                <div className="flex flex-col items-start  w-1/3">
                  <div className=" text-md font-normal text-black">{`Bath: ${item.unitDetails.bath}`}</div>
                  <div className="text-md font-normal text-black">{`Beds: ${item.unitDetails.beds}`}</div>
                </div>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}
