import { navigate } from "raviger";



import MaterialForm from "../../components/common/form/MaterialForm";
import { conditionalArrayEntry } from "../../utils/ObjectUtills";
import { deepUpdate } from "../../utils/StateUtils";
import { lowerCase } from "../../utils/StringUtils";
import { SubscriberType, VendorStages } from "./CreateVendor";


type VendorDetailsData = {
  address: {
    city: string;
    geocodeLatitude: string;
    geocodeLongitude: string;
    melissaBaseMak: string;
    state: string;
    streetAddress1: string;
    zip: string;
  };
  name: string;
  primaryContactNumber: string;
  supportEmail: string;
  contractorLicenseNumber: string;
  contractorLicenseExpiry: string;
  territories: string;
};

type VendorDetailsProps = {
  vendorDetails: VendorDetailsData;
  type: string;
  setVendorDetails: React.Dispatch<
    React.SetStateAction<{
      address: {
        city: string;
        geocodeLatitude: string;
        geocodeLongitude: string;
        melissaBaseMak: string;
        state: string;
        streetAddress1: string;
        zip: string;
      };
      name: string;
      primaryContactNumber: string;
      supportEmail: string;
      contractorLicenseNumber: string;
      contractorLicenseExpiry: string;
      territories: string;
      licenseDetails: string;
      invoiceTerms: string;
    }>
  >;
  nextStage: () => void;
};

export default function VendorDetails({
  setVendorDetails,
  nextStage,
  vendorDetails,
  type,
}: VendorDetailsProps) {
  const onChange = (update: {
    name: string;
    subOptions?: string[];
    value?: string;
  }) => {
    let { name, value } = update;

    if (name === "supportEmail") {
      value = lowerCase(value);
    }

    setVendorDetails((data) => deepUpdate(name, value, data));
  };

  const emailSuffix = process.env.REACT_APP_EMAIL_SUFFIX;

  const validateEmail = (email: string) => {
    if (email === "") return true;
    const mailId = `${email}${emailSuffix}`;
    return String(mailId)
      .toLowerCase()
      .match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
  };

  const formContractorDetails = () => {
    return [
      { name: "name" },
      { name: "primaryContactNumber", inputType: "PHONE" },
      {
        name: "supportEmail",
        inputType: "WITH_OPTIONS",
        inputOptions: { suffix: emailSuffix },
      },
      ...conditionalArrayEntry(!validateEmail(vendorDetails.supportEmail), {
        inputType: "RAW",
        children: (
          <div className="text-left">
            <span className="text-red-500  text-xs font-normal">
              Please Enter a Valid Email Address
            </span>
          </div>
        ),
      }),
      { name: "contractorLicenseNumber" },
      { name: "contractorLicenseExpiry", inputType: "DATE" },
      {
        name: "estimateTerms",
        label: "Estimate Terms",
        inputType: "SELECT",
        options: [
          { label: "7 Days", value: "SEVEN" },
          { label: "10 Days", value: "TEN" },
          { label: "15 Days", value: "FIFTEEN" },
          { label: "30 Days", value: "THIRTY" },
          { label: "60 Days", value: "SIXTY" },
          { label: "90 Days", value: "NINETY" },
        ],
      },
      {
        name: "invoiceTerms",
        label: "Invoice Payment Terms",
        inputType: "SELECT",
        options: [
          { label: "On Receipt", value: "ZERO" },
          { label: "7 Days", value: "SEVEN" },
          { label: "10 Days", value: "TEN" },
          { label: "15 Days", value: "FIFTEEN" },
          { label: "30 Days", value: "THIRTY" },
          { label: "60 Days", value: "SIXTY" },
          { label: "90 Days", value: "NINETY" },
        ],
      },
    ];
  };

  const commonDetails = () => {
    return [
      {
        inputType: "RAW",
        children: (
          <div className="">
            <span className=" text-gray-700 text-sm font-normal">
              Invoice Terms And Conditions
            </span>
          </div>
        ),
      },
      {
        name: "invoiceTermsAndConditions",
        label: "Invoice Terms And Conditions",
        inputType: "AREA",
        variant: "OUTLINED",
      },
      {
        inputType: "RAW",
        children: (
          <div className="">
            <span className=" text-gray-700 text-sm font-normal">
              Estimate Terms And Conditions
            </span>
          </div>
        ),
      },
      {
        name: "estimateTermsAndConditions",
        label: "Estimate Terms And Conditions",
        inputType: "AREA",
        variant: "OUTLINED",
      },
      {
        name: "address",
        inputType: "ADDRESS",
        variant: "STANDARD",
      },
    ];
  };

  const formPMCDetails = () => {
    return [
      { name: "name", suffix: "test" },
      { name: "primaryContactNumber", inputType: "PHONE" },
      {
        name: "supportEmail",
        inputType: "WITH_OPTIONS",
        inputOptions: { suffix: emailSuffix },
      },
      ...conditionalArrayEntry(!validateEmail(vendorDetails.supportEmail), {
        inputType: "RAW",
        children: (
          <div className="text-left">
            <span className="text-red-500  text-xs font-normal">
              Please Enter a Valid Email Address
            </span>
          </div>
        ),
      }),
      { name: "licenseDes", suffix: "@gmail.com", editable: false },
      {
        name: "estimateTerms",
        label: "Estimate Terms",
        inputType: "SELECT",
        options: [
          { label: "7 Days", value: "SEVEN" },
          { label: "10 Days", value: "TEN" },
          { label: "15 Days", value: "FIFTEEN" },
          { label: "30 Days", value: "THIRTY" },
          { label: "60 Days", value: "SIXTY" },
          { label: "90 Days", value: "NINETY" },
        ],
      },
      {
        name: "invoiceTerms",
        label: "Invoice Payment Terms",
        inputType: "SELECT",
        options: [
          { label: "On Receipt", value: "ZERO" },
          { label: "7 Days", value: "SEVEN" },
          { label: "10 Days", value: "TEN" },
          { label: "15 Days", value: "FIFTEEN" },
          { label: "30 Days", value: "THIRTY" },
          { label: "60 Days", value: "SIXTY" },
          { label: "90 Days", value: "NINETY" },
        ],
      },
    ];
  };

  return (
    <div className="w-full ">
      <div className="flex flex-col md:flex-row">
        <MaterialForm
          data={vendorDetails}
          fieldClassName="my-2"
          renderArray={
            type === SubscriberType.contractor
              ? formContractorDetails()
              : formPMCDetails()
          }
          onSubmit={() => {}}
          onChange={onChange}
        />
        <MaterialForm
          data={vendorDetails}
          fieldClassName="my-2"
          renderArray={commonDetails()}
          onSubmit={() => {}}
          onChange={onChange}
        />
      </div>
      <div className="flex-auto flex flex-row-reverse my-4">
        <button
          className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
          bg-newBlue-400  w-80
          text-white border-newBlue-400
          border"
          onClick={() => nextStage()}
        >
          Continue
        </button>
        <button
          className="text-base flex justify-center items-center px-4 py-2 rounded font-medium cursor-pointer 
          bg-white
          text-newBlue-400 border-newBlue-400
          border duration-200 ease-in-out 
          transition"
          onClick={() => navigate("/settings")}
        >
          Go Back
        </button>
      </div>
    </div>
  );
}