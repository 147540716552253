export const DBConfig = {
  name: "USC",
  version: 1,
  objectStoresMeta: [
    {
      store: "client",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "form", keypath: "form", options: { unique: false } },
      ],
    },
  ],
};
