import dispatcher from "../dispatcher";

export function dispatchNotification(message) {
  dispatcher.dispatch({
    actionType: "NOTIFICATION",
    ...message,
  });
}
export function subscribeTopic(message) {
  dispatcher.dispatch({
    actionType: "SUBSCRIBE",
    ...message,
  });
}
export function unsubscribeTopic(message) {
  dispatcher.dispatch({
    actionType: "UNSUBSCRIBE",
    ...message,
  });
}
