import React from "react";

import Popover from "@mui/material/Popover";
import makeStyles from "@mui/styles/makeStyles";

export default function PopOverText({
  className,
  children,
  renderPopOver,
  bgColor,
}) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(1),
      backgroundColor: bgColor ? bgColor : "white",
    },
  }));

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <div
        className={className}
        onClick={handlePopoverOpen}
        onBlur={handlePopoverClose}
      >
        {children}
      </div>
      <Popover
        id="mouse-over-popover"
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
      >
        {renderPopOver(handlePopoverClose)}
      </Popover>
    </div>
  );
}
