import React, { useEffect, useState } from "react";

import { Snackbar } from "@mui/material";
import { AlertTitle } from "@mui/material";
import Alert from "@mui/material/Alert";

import dispatcher from "./dispatcher";

const snackbarInitialState = { open: false };

export default function AlertWrapper({ callWidget }) {
  const [alertQueue, setAlertQueue] = useState([]);

  // First alert in the queue or initial state
  const snackbar = alertQueue[0] || snackbarInitialState;

  const handleAlert = (action) => {
    if (action.actionType === "ALERT")
      if (action.options?.deferred) {
        // Wait for 500ms and show the alert only if no other alert is open
        setTimeout(() => {
          setAlertQueue((alertQueue) => [...alertQueue, action]);
        }, 500);
        // If the current alert is a loading alert, show the new alert immediately
      } else {
        setAlertQueue((alertQueue) => {
          if (alertQueue[0]?.options?.loading) {
            // Remove the loading alert and show the new alert
            const [_loadingAlert, ...rest] = alertQueue;
            return [...rest, action];
          }
          return [...alertQueue, action];
        });
      }
  };

  useEffect(() => {
    dispatcher.register(handleAlert);
    window.dispatcher = dispatcher;
  }, []);

  const renderAlertContent = (message, description) =>
    description ? (
      <>
        <AlertTitle>{message}</AlertTitle>
        {description}
      </>
    ) : (
      message
    );

  const onClose = () => {
    // Close Snackbar
    // Remove the next alert if it is deferred
    console.log("onClose", alertQueue);
    setAlertQueue((alertQueue) => {
      const updatedQueue = alertQueue.filter(
        (alert, index) => !(index === 0 || alert.options?.deferred)
      );
      console.log("updatedQueue", updatedQueue);
      return updatedQueue;
    });
  };
  return (
    <Snackbar
      anchorOrigin={
        snackbar?.options?.anchorOrigin ?? {
          vertical: "bottom",
          horizontal: "center",
        }
      }
      open={snackbar !== snackbarInitialState}
      autoHideDuration={snackbar.time ? snackbar.time : 6000}
      onClose={onClose}
      sx={
        callWidget
          ? { ...{ bottom: { xs: 300, sm: 90 } } }
          : { ...{ bottom: { xs: 60 } } }
      }
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={onClose}
        severity={snackbar?.variant ?? "success"}
      >
        {renderAlertContent(snackbar?.message, snackbar?.description)}
      </Alert>
    </Snackbar>
  );
}
