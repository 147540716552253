import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";

import PreviewLink from "../common/PreviewLink";
import { Tick } from "./AppIcons";
// import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
// import { getMedia } from "../suppliers/MessagesList";
// import { blueGrey } from "@mui/material/colors";
import Carousel from "./Carousel";
import MaterialModal from "./MaterialModal";

export const PreviewTiles = ({
  urls,
  editMedia,
  selectMedia,
  emptyScreen,
  children,
  onSelectCB,
  OnDeleteCB,
}) => {
  const [onHover, setonHover] = useState({});

  const [selectedImage, setSelectedImage] = useState("");

  return (
    <div className="w-full">
      {children}
      <div className="flex w-full items-center justify-start bg-grey-lighter">
        {urls?.length === 0 && emptyScreen ? (
          <></>
        ) : (
          urls?.map((item, index) => (
            <div
              className="flex flex-row static items-center justify-center relative px-1"
              onClick={() => {
                if (onSelectCB) onSelectCB(item);
                else setSelectedImage(item);
              }}
              onMouseEnter={() => {
                setonHover((current) => {
                  return { ...current, [index]: true };
                });
              }}
              onMouseLeave={() => {
                setonHover((current) => {
                  return { ...current, [index]: false };
                });
              }}
            >
              <PreviewLink url={item} tileView key={index} />
              {editMedia && onHover[index] && (
                <div className="absolute">
                  <DeleteIcon
                    className="text-blue-light"
                    onClick={(e) => {
                      e.stopPropagation();
                      OnDeleteCB(item);
                    }}
                  />
                </div>
              )}
              {selectMedia && selectMedia.includes(item) && (
                <div className="absolute">
                  <Tick
                    className="text-blue-light"
                    onClick={(e) => {
                      e.stopPropagation();
                      OnDeleteCB(item);
                    }}
                  />
                </div>
              )}
            </div>
          ))
        )}
      </div>

      {selectedImage && (
        <MaterialModal
          className="p-0"
          open={selectedImage}
          setOpen={() => {
            setSelectedImage();
          }}
        >
          <Carousel
            lightboxImages={{
              images: urls,
              photoIndex: urls.indexOf(selectedImage),
            }}
          />
        </MaterialModal>
      )}
    </div>
  );
};
