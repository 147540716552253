import dispatcher from "../dispatcher";

export function alertSuccess(message, description) {
  dispatcher.dispatch({
    actionType: "ALERT",
    open: true,
    variant: "success",
    message: message,
    description: description,
  });
}

export function alertSuccessWithCustomTime(message, description, time) {
  dispatcher.dispatch({
    actionType: "ALERT",
    open: true,
    variant: "success",
    message: message,
    description: description,
    time: time,
  });
}

export function alertError(message, description, options) {
  dispatcher.dispatch({
    actionType: "ALERT",
    open: true,
    variant: "error",
    message: message,
    description: description,
    options,
  });
}

export function alertInfo(message, description, options = {}) {
  dispatcher.dispatch({
    actionType: "ALERT",
    open: true,
    variant: "info",
    message: message,
    description: description,
    options,
  });
}

export function alertNotification(
  message,
  description,
  options = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
  }
) {
  dispatcher.dispatch({
    actionType: "ALERT",
    open: true,
    variant: "info",
    message: message,
    description: description,
    options,
  });
}
