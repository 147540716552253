import React from "react";

import { deepFind } from "../../../../utils/StateUtils";
// import MaterialUiPhoneNumber from "material-ui-phone-number-2";
import MaterialUiPhoneNumber from "../../phonePicker/index";

export default function Number({
  variant,
  data,
  name,
  label,
  onChange,
  optional,
  className,
}) {
  return (
    <MaterialUiPhoneNumber
      id="outlined-basic"
      label={label + (optional ? " (Optional)" : "")}
      variant={variant === "OUTLINED" ? "outlined" : "standard"}
      placeholder={label}
      name={name}
      defaultCountry={"us"}
      value={deepFind(name, data)}
      onChange={(value) => onChange({ name, value })}
      fullWidth
      className={className}
    />
  );
}
