import React, { useEffect, useState } from "react";

import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import ContactsIcon from "@mui/icons-material/Contacts";
import DescriptionIcon from "@mui/icons-material/Description";
import LocationIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import { CircularProgress, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { Link } from "raviger";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import {
  createSR,
  createTicket,
  getBuilding,
  getClient,
  getTicket,
  getTicketSRs,
} from "../../api/Api";
import { deepUpdate } from "../../utils/StateUtils";
import { properStrings, renderAddress } from "../../utils/StringUtils";
import { ClientIcon } from "../common/AppIcons";
// import NewServiceRequestPopup from "../common/NewServiceRequestPopup";
import Carousel from "../common/Carousel";
import MaterialModal from "../common/MaterialModal";
import PreviewLink from "../common/PreviewLink";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import { JobTypesDict, formUpdateSRTicket } from "../common/form/constants";
// import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
// import { getMedia } from "../clients/MessagesList";
import CreateSR from "../service/CreateSR";
import ServiceRequestDetail from "../service/ServiceRequestDetail";
import EstimateForm from "./EstimateForm";

const fetchServiceRequests = (ticketId, setServiceRequests) =>
  getTicketSRs(ticketId)
    .then(setServiceRequests)
    .catch((_) => alertError("Couldn't fetch SR Details"));

export default function TicketDetails({
  ticketId,
  setQuickMessage,
  setContactId,
  showEstimate,
  onSuccessCB,
  urlTicketId,
  refreshCB,
}) {
  const [ticket, setTicket] = useState();
  const [serviceRequests, setServiceRequests] = useState();
  const [action, setAction] = useState();
  const [changeBuilding, toggleChangeBuilding] = useState(false);
  const [changeContacts, toggleChangeContacts] = useState(false);
  const [contacts, setContacts] = useState([]);

  const [clientBuildings, setClientBuildings] = useState();

  const [lightboxImages, setLightboxImages] = useState();
  const [showModal, setShowModal] = useState(false);
  const [forUpdate, setForUpdate] = useState();

  const [estimateShow, setEstimateShow] = useState(showEstimate);

  // const [estimateShow, setEstimateShow] = useState(showEstimate);

  const fetchTicketDetail = (ticketId, setTicket) =>
    getTicket(ticketId)
      .then((data) => {
        setTicket(data);
        if (estimateShow) {
          if (parseInt(urlTicketId) === ticketId) {
            setShowModal(true);
            setEstimateShow(false);
          }
        }
      })
      .catch((_) => alertError("Couldn't fetch Ticket Details"));

  useEffect(() => {
    if (changeBuilding)
      getClient(ticket.clientId)
        .then((clientData) => setClientBuildings(clientData.buildings))
        .catch((_) => alertError("Error Loading Client Buildings"));
  }, [changeBuilding]);

  useEffect(() => {
    if (!showModal) {
      fetchTicketDetail(ticketId, setTicket);
    }
  }, [showModal]);

  useEffect(() => {
    if (ticket?.buildingId)
      getBuilding(ticket.buildingId)
        .then((building) => {
          setContacts(building.contacts);
        })
        .catch((err) => {
          alertError("Couldn't Fetch Select Building");
        });
    else setContacts([]);
  }, [ticket?.buildingId]);

  useEffect(() => {
    if (estimateShow) {
      fetchTicketDetail(ticketId, setTicket);
    }
    fetchServiceRequests(ticketId, setServiceRequests);
  }, [ticketId]);

  const onChangeUpdate = (update) => {
    const { name, value } = update;
    setForUpdate((data) => deepUpdate(name, value, data));
  };

  const renderServiceRequests = (serviceRequests) => {
    return (
      <div className="flow-root w-full">
        <ul className="">
          {serviceRequests.map((serviceRequest, renderIndex) => (
            <li
              key={renderIndex}
              className="mt-3"
              onClick={() => {
                setForUpdate(serviceRequest);
              }}
            >
              <div className="relative">
                <div
                  className={
                    "relative flex space-x-3 rounded  cursor-pointer " +
                    (serviceRequest.materials?.length > 0
                      ? "bg-newYellow-300 hover:bg-newYellow-100"
                      : serviceRequest.isEstimateSent === true &&
                        serviceRequest.isDraft === true &&
                        serviceRequest.isChangeOrder === false &&
                        serviceRequest.estimateStatus !== "SENT"
                      ? "bg-newGray-700 hover:bg-newGray-200"
                      : "bg-newGray-600 hover:bg-blue-300")
                  }
                >
                  <div>
                    <span
                      className={
                        "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                      }
                    >
                      <DescriptionIcon
                        className="h-5 w-5 text-gray-600"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                    <div>
                      <p
                        className={
                          "text-sm " +
                          (serviceRequest.isEstimateSent === true &&
                          serviceRequest.isDraft === true &&
                          serviceRequest.isChangeOrder === false &&
                          serviceRequest.estimateStatus !== "SENT"
                            ? "text-newGray-200 line-through"
                            : "text-gray-800")
                        }
                      >
                        {`${serviceRequest.ticketId}-` +
                          (!serviceRequest.isDraft
                            ? `SR${serviceRequest.serviceRequestIndex}`
                            : `I${serviceRequest.serviceRequestIndex}`)}
                      </p>
                    </div>
                    <div>
                      <p
                        className={
                          "text-sm " +
                          (serviceRequest.isEstimateSent === true &&
                          serviceRequest.isDraft === true &&
                          serviceRequest.isChangeOrder === false &&
                          serviceRequest.estimateStatus !== "SENT"
                            ? "text-newGray-200 line-through"
                            : "text-gray-800")
                        }
                      >
                        {JobTypesDict[serviceRequest.jobType]}
                      </p>
                    </div>
                    <div
                      className={
                        "text-right text-sm whitespace-nowrap px-2 " +
                        (serviceRequest.isEstimateSent === true &&
                        serviceRequest.isDraft === true &&
                        serviceRequest.isChangeOrder === false &&
                        serviceRequest.estimateStatus !== "SENT"
                          ? "text-newGray-200 line-through"
                          : "text-gray-800")
                      }
                    >
                      <time dateTime={serviceRequest.createdAt}>
                        {new Date(
                          serviceRequest.createdAt
                        ).toLocaleDateString()}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="flex flex-col max-w-sm w-full">
      {ticket ? (
        <>
          {(ticket.mediaUrls || ticket.ticketMedia) && (
            <>
              <Typography variant="h6">Media</Typography>
              <div className="flex gap-2">
                {[
                  ...new Set(
                    (ticket.mediaUrls || [])
                      .concat(ticket.ticketMedia)
                      .filter(
                        (d) =>
                          !(ticket?.blacklistedTicketMedia || []).includes(d)
                      )
                  ),
                ]?.map((attachment, i) => (
                  <div
                    key={i}
                    className="text-sm mt-1"
                    onClick={(_) =>
                      setLightboxImages({
                        images: (ticket.mediaUrls || []).concat(
                          ticket.ticketMedia
                        ),
                        photoIndex: i,
                      })
                    }
                  >
                    <PreviewLink url={attachment} tileView />
                  </div>
                ))}
              </div>
            </>
          )}
          <Link
            href={`/clients/${ticket.clientId}`}
            className="py-2 hover:text-blue-700"
          >
            <ClientIcon className="mx-1" />
            {ticket.clientName}
          </Link>
          <div>
            {changeBuilding ? (
              clientBuildings && (
                <MaterialForm
                  data={{}}
                  renderArray={[
                    {
                      name: "building",
                      label: "Building",
                      inputType: "SELECT",
                      options: clientBuildings.map((building) => {
                        return {
                          label: renderAddress(building.address),
                          value: building.buildingId,
                        };
                      }),
                    },
                  ]}
                  onSubmit={(_) => {}}
                  onChange={({ name, value }) => {
                    createTicket({ ...ticket, buildingId: value }).then(() => {
                      fetchTicketDetail(ticketId, setTicket);
                    });
                    toggleChangeBuilding((_) => false);
                    setClientBuildings((_) => undefined);
                  }}
                />
              )
            ) : ticket.buildingId ? (
              <Link
                href={`/clients/${ticket.clientId}/summary/${ticket.buildingId}`}
                className="py-1 hover:text-blue-700"
              >
                <LocationIcon className="mx-1" />
                {renderAddress(ticket)}
              </Link>
            ) : (
              <span className="py-1 hover:text-blue-700">
                <LocationIcon className="mx-1" />
                Location not specified
              </span>
            )}

            {!changeBuilding && (
              <Button
                onClick={(_) => {
                  toggleChangeBuilding((_) => true);
                }}
              >
                Change
              </Button>
            )}
          </div>
          {ticket.primaryContact && (
            <span className="py-1 hover:text-blue-700">
              <PersonIcon className="mx-1" />
              {properStrings([
                ticket.primaryContact?.firstName,
                ticket.primaryContact?.lastName,
              ])}
            </span>
          )}
          <div className="flex flex-row items-center">
            {changeContacts ? (
              <MaterialForm
                data={{}}
                renderArray={[
                  {
                    name: "contactId",
                    label: "Contact",
                    inputType: "SELECT",
                    options: contacts.map((contact) => {
                      return {
                        label: properStrings([
                          contact.firstName,
                          contact.lastName,
                        ]),
                        value: contact.contactId,
                      };
                    }),
                  },
                ]}
                onSubmit={(_) => {}}
                onChange={({ name, value }) => {
                  setContactId(value);

                  createTicket({ ...ticket, contactId: value }).then(() => {
                    fetchTicketDetail(ticketId, setTicket);
                  });

                  toggleChangeContacts((_) => false);
                }}
              />
            ) : (
              <span className="py-1 hover:text-blue-700">
                <ContactsIcon className="mx-1" />
                {ticket.alternateContact
                  ? properStrings([
                      ticket.alternateContact.firstName,
                      ticket.alternateContact.lastName,
                    ])
                  : "No Contacts Selected"}
              </span>
            )}
            <br />

            {!changeContacts && (
              <Button
                onClick={(_) => {
                  toggleChangeContacts((_) => true);
                }}
              >
                Change
              </Button>
            )}
          </div>
          <div className="flex justify-between">
            <Typography variant="h6" className="w-48">
              Service Requests
            </Typography>
            {action ? (
              <Button
                color="secondary"
                variant="text"
                onClick={(_) => {
                  setAction();
                }}
              >
                <div className="flex gap-1">
                  <span>Cancel</span>
                </div>
              </Button>
            ) : (
              <Button
                color="primary"
                variant="text"
                onClick={(_) => {
                  setAction("CREATE_SR");
                }}
              >
                <div className="flex gap-1 create-sr-button">
                  <AddCircleOutlineOutlined />
                  <span>Create</span>
                </div>
              </Button>
            )}
          </div>
          <div className="flex items-center justify-center py-2">
            {action && action === "CREATE_SR" ? (
              <CreateSR
                baseData={ticket}
                successCB={(_) => {
                  setAction();
                  fetchServiceRequests(ticketId, setServiceRequests);
                  fetchTicketDetail(ticketId, setTicket);
                }}
                onCancelCB={(_) => setAction()}
              />
            ) : (serviceRequests?.length || 0) === 0 ? (
              <span className="p-2 bg-gray-500 opacity-60 rounded-md text-black">
                No Service Requests to show
              </span>
            ) : (
              renderServiceRequests(serviceRequests)
            )}
          </div>
        </>
      ) : (
        <div className="p-2">
          <CircularProgress className="mx-auto" />
        </div>
      )}

      {lightboxImages && (
        <MaterialModal
          className="p-0"
          open={lightboxImages}
          setOpen={() => {
            setLightboxImages();
          }}
        >
          <Carousel lightboxImages={lightboxImages} />
        </MaterialModal>
      )}

      {/* {lightboxImages && (
        <AutoRotatingCarousel
          open={true}
          onClose={() => setLightboxImages()}
          autoplay={false}
          mobile={false}
          style={{ position: "absolute", backgroundColor: colors.blueGrey[50] }}
          classes={{ slide: "slide_parent" }}
          hideArrows={() => (lightboxImages.images.length > 0 ? false : true)}
        >
          {lightboxImages.images.map((imageSRC, index) => (
            <Slide
              media={getMedia(imageSRC)}
              classes={{ text: "text_parent" }}
              style={{
                backgroundColor: colors.blueGrey[50],
                height: "fit-content"
              }}
              mediaBackgroundStyle={{ backgroundColor: colors.blueGrey[50] }}
              title=""
              subtitle=""
            />
          ))}
        </AutoRotatingCarousel>
      )} */}
      <div className="flex flex-row">
        <Button
          color="primary"
          className="float-right p-4 quick-message-button"
          onClick={() => {
            setQuickMessage((current) => {
              if (current) {
                return null;
              } else {
                let contact = {
                  contacts: [ticket?.primaryContact],
                  clientId: ticket?.clientId,
                };
                if (ticket.alternateContact) {
                  if (
                    ticket.alternateContact.contactId !==
                    ticket?.primaryContact.contactId
                  ) {
                    contact.contacts.push(ticket.alternateContact);
                  }
                }

                return contact;
              }
            });
          }}
        >
          Quick message
        </Button>
        <Button
          color="primary"
          className="float-right p-4 create-update-estimate-button"
          onClick={() => setShowModal((current) => !current)}
        >
          {ticket?.estimateId ? "View/Update " : "Create "} Estimate
        </Button>
      </div>
      <MaterialModal
        open={showModal}
        setOpen={(_) => {
          setShowModal((current) => !current);
        }}
        label="estimate-modal"
        describedby="create-view-estimate"
        width={"80"}
      >
        <EstimateForm
          ticketData={ticket}
          setShowModal={setShowModal}
          onSuccessCB={onSuccessCB}
          refreshCB={refreshCB}
        />
      </MaterialModal>

      {forUpdate && (
        <MaterialModal
          open={forUpdate ? true : false}
          setOpen={(_) => {
            fetchServiceRequests(ticketId, setServiceRequests);
            setForUpdate();
          }}
          label="new-user-modal"
          describedby="create-new-user"
        >
          <div className="flex">
            <div>
              <Typography variant="h6">
                Request Details{" "}
                {forUpdate.serviceRequestId
                  ? `${forUpdate.ticketId}-` +
                    (!forUpdate.isDraft
                      ? `SR${forUpdate.serviceRequestIndex}`
                      : `I${forUpdate.serviceRequestIndex}`)
                  : ""}
              </Typography>
              <MaterialForm
                data={forUpdate}
                renderArray={formUpdateSRTicket(forUpdate)}
                onChange={onChangeUpdate}
              />
              <Button
                onClick={(_) => {
                  let data = forUpdate;
                  delete data["appointments"];
                  createSR(data)
                    .then((_) => {
                      // setForUpdate();
                      alertSuccess("Successfully Updated Service Request");
                    })
                    .catch((_) => {
                      alertError("Error Updating Service Request");
                    });
                }}
                color="primary"
                className="float-right p-4"
              >
                Update
              </Button>
            </div>
            <ServiceRequestDetail
              serviceRequestId={forUpdate.serviceRequestId}
              setForUpdate={setForUpdate}
            />
          </div>
        </MaterialModal>
      )}
    </div>
  );
}
