import React, { useState } from "react";

import PeopleOutlined from "@mui/icons-material/PeopleOutlined";
import { Button, Typography } from "@mui/material";
import { Link, navigate } from "raviger";

import {
  alertError,
  alertInfo,
  alertSuccess,
} from "../../actions/AlertActions";
import { createContact, createPMCManagers } from "../../api/Api";
import { conditionalObjectEntry } from "../../utils/ObjectUtills";
import { CallbackLink } from "../../utils/RouterUtils";
import { deepUpdate } from "../../utils/StateUtils";
import { PersonWithIcon } from "../common/AppIcons";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import { useContactFields } from "../common/form/constants";
import { ContactIcon } from "./ClientAppIcons";

export default function CreateContact({
  baseData,
  successCB,
  onCancelCB,
  renderFlexComponent,
}) {
  const [data, setData] = useState(baseData ?? {});
  const [conflictingContacts, setConflictingContacts] = useState();

  const onChange = (update) => {
    const { name, value } = update;
    setData((data) => deepUpdate(name, value, data));
  };

  const onSubmit = (event, existingContact) => {
    event.preventDefault();
    const { isClient, ...filteredObject } = data;
    const _ = isClient;
    let payload = {};
    if (existingContact) {
      const { firstName, lastName, email, phone, contactId } = existingContact;
      payload = {
        ...filteredObject,
        firstName,
        lastName,
        email,
        phone,
        contactId,
      };
    } else {
      payload = filteredObject;
    }
    const createContactPromise = data.managerOfClientId
      ? createPMCManagers(payload, data.managerOfClientId)
      : createContact(payload);
    createContactPromise
      .then((newContactId) => {
        data.contactId
          ? alertSuccess("Successfully Updated Contact")
          : alertSuccess("Successfully Created Contact");
        successCB && successCB(newContactId.value);
      })
      .catch((e) => {
        if (e.status === 409) {
          alertError("The email/phone number is already registered");
          setConflictingContacts(e.data);
        } else {
          const errorMessage =
            e.readableMessage ?? data.clientId
              ? "Error Updating Contact"
              : "Error Creating Contact";
          alertError(errorMessage);
        }
      });
  };

  const contactFormFields = useContactFields(data);

  if (conflictingContacts) {
    // Show a list of conflicting contacts
    // Link to the contact page
    // Styling strictly using TailwindCSS
    return (
      <div className="p-4 max-w-sm">
        <div className="flex gap-2">
          <span className="text-lg">Contacts Already Exist</span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="mt-2 text-md">
            The following contacts already exists:
          </span>
          <div className="mt-2 flex flex-col gap-2">
            {conflictingContacts.map((contact) => (
              // TODO: Make the Card a Link to the individual's profile &
              // add a button to proceed with linking the existing contact instead.
              <li
                key={contact.contactId}
                className="group relative flex gap-8 items-center justify-between rounded-xl p-4 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  onCancelCB();
                  alertInfo("Taking you to the individual's profile");
                  setTimeout(() => {
                    navigate(`/individual/${contact.contactId}/buildings`);
                  }, 200);
                }}
              >
                <div className="flex gap-4">
                  <div className="flex-shrink-0">
                    {contact.profilePicture ? (
                      <img
                        className="h-12 w-12 rounded-full"
                        src={contact.profilePicture}
                        alt=""
                      />
                    ) : (
                      <ContactIcon className="h-12 w-12 rounded-full border-2 border-gray-300 text-gray-700" />
                    )}
                  </div>
                  <div className="w-full text-sm leading-6">
                    <span className="font-semibold text-gray-900">
                      <span
                        className="absolute inset-0 rounded-xl"
                        aria-hidden="true"
                      ></span>
                      {`${contact.firstName} ${contact.lastName}`}
                    </span>
                    <div className="text-gray-500">
                      {contact.email}
                      {contact.phone && contact.email ? " | " : ""}
                      {contact.phone}
                    </div>
                  </div>
                </div>
                <a
                  href="#link"
                  className="relative flex items-center whitespace-nowrap rounded-full py-1 pl-4 pr-3 text-sm text-gray-500 transition hover:bg-gray-200 group-hover:border-gray-300"
                  onClick={(e) => {
                    e.stopPropagation();
                    onSubmit(e, contact);
                  }}
                >
                  <span className="font-semibold transition group-hover:text-gray-700">
                    Link
                  </span>
                  <svg
                    className="mt-px h-5 w-5 text-gray-400 transition group-hover:trangray-x-0.5 group-hover:text-gray-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </a>
              </li>
            ))}
            {/* Add a Note that clicking on the contact would open the individual's profile page
                and clicking on the link button would link the contact */}
            <div className="text-sm text-gray-500">
              Note: Use the <b>Link</b> button to link the existing contact.
              <br />
              Selecting the Contact would take you to the individual's profile.
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 max-w-sm">
      <div className="flex gap-2">
        <PeopleOutlined />
        <Typography variant="h5">
          {baseData.label ? `${baseData.label} Details` : "Contact Details"}
        </Typography>
      </div>
      <MaterialForm
        className="px-4 py-2 w-full"
        data={data}
        renderArray={contactFormFields}
        onSubmit={onSubmit}
        onChange={onChange}
      />

      <div className="flex flex-row-reverse items-center justify-between">
        <Button
          color="primary"
          className="float-right p-4 contact-save-button"
          onClick={onSubmit}
        >
          Save
        </Button>
        {renderFlexComponent && renderFlexComponent()}
      </div>
    </div>
  );
}
