import React, { useEffect, useState } from "react";

import { Avatar } from "@mui/material";

import { alertError } from "../../actions/AlertActions";
import {
  contactsUnderIndividualClient,
  getBuildingManagersNewApi,
  managerContacts,
  residentContacts,
  unitsWithResidents,
} from "../../api/Api";
import { conditionalArrayEntry } from "../../utils/ObjectUtills";
import { deepFilter } from "../../utils/StateUtils";
import { properString, renderAddress } from "../../utils/StringUtils";
import { BuildingIconMessages, ProfileIcon } from "./ClientAppIcons";

export default function MessagesFilter({
  buildings,
  buildingId,
  clientId,
  individualClientId,
  prefillContact,
  searchText,
  selectedContactCB,
  selectedContactId,
  onLoadCB,
  clientClass = "INDIVIDUAL",
}) {
  const [managerData, setManagerData] = useState([]);
  const [residentData, setResidentData] = useState([]);

  const isHOA = clientClass === "HOA";
  const filterSearch = deepFilter(searchText);

  useEffect(() => {
    const onLoad = async () => {
      try {
        // onLoadCB is used to preselect the first contact
        if (individualClientId) {
          contactsUnderIndividualClient(individualClientId).then((data) => {
            setResidentData(data);
            onLoadCB && onLoadCB(data);
          });
        } else if (clientId) {
          const promises = [
            managerContacts(clientId),
            residentContacts(clientId),
          ];

          Promise.all(promises)
            .then((data) => {
              const [managerData, residentData] = data;
              setManagerData(managerData);
              setResidentData(residentData);

              onLoadCB && onLoadCB([...managerData, ...residentData]);
            })
            .catch((e) => {
              alertError(
                `There was an error fetching contacts for this client`
              );
            });
        }
        if (buildingId) {
          const promises = [
            unitsWithResidents(buildingId),
            getBuildingManagersNewApi(buildingId),
          ];

          Promise.all(promises)
            .then((data) => {
              const [residentData, managerData] = data;
              const flattenedDict = Object.entries(residentData).reduce(
                (acc, [key, value]) => {
                  return [
                    ...acc,
                    ...value.map((v) => {
                      return { ...v, unitNumber: key };
                    }),
                  ];
                },
                []
              );
              setResidentData(flattenedDict);
              setManagerData(managerData);
              onLoadCB && onLoadCB([...flattenedDict, ...managerData]);
            })
            .catch((e) => {
              alertError(
                `There was an error fetching contacts for this building`
              );
            });
        }
      } catch (e) {
        alertError(
          `There was an error fetching contacts for this ${
            buildingId ? "building" : "client"
          }`
        );
      }
    };
    onLoad();
  }, [clientId]);

  const prefilledContactRow = prefillContact && (
    <ContactRow
      contact={prefillContact}
      selectedContact={selectedContactId}
      setSelectedContact={selectedContactCB}
    />
  );

  const clientManagerRows = managerData
    .filter(
      (contact, index, self) =>
        index ===
        self.findIndex(
          (c) => c.contactId === contact.contactId && filterSearch(contact)
        )
    )
    .map((contact) => (
      <ContactRow
        contact={contact}
        selectedContact={selectedContactId}
        setSelectedContact={selectedContactCB}
      />
    ));

  return (
    <div className="flex flex-col h-full my-4">
      {prefilledContactRow}
      {managerData && clientManagerRows.length > 0 && (
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <BuildingIconMessages />
            <span className="font-bold">
              {isHOA && "Board Members & "}Managers
            </span>
          </div>
          <div className="flex flex-col gap-2">{clientManagerRows}</div>
        </div>
      )}

      {buildings &&
        buildings.length > 0 &&
        buildings
          // filter out duplicate buildings using buildingId
          .filter(
            (building, index, self) =>
              index ===
              self.findIndex((b) => b.buildingId === building.buildingId)
          )
          .map((building) => {
            const managersRows = managerData
              // TODO: Revisit this filter; Might be broken
              // In Buildings Page; Manager Data is not expected to be show in this area
              // Even though "unintentially", This filter does that task as well
              // Since Managers will not have a buildingId Set
              // Along with it's original purpose of filtering by buildingId.
              .filter(
                (contact, _index, _self) =>
                  contact?.buildingId === building.buildingId &&
                  filterSearch({ contact, building })
              )
              .map((contact) => (
                <ContactRow
                  contact={contact}
                  selectedContact={selectedContactId}
                  setSelectedContact={selectedContactCB}
                />
              ));
            const residentRows = residentData
              .filter(
                (contact) =>
                  // In Buildings Page; Don't filter by buildingId because we only have data for one building
                  (buildingId
                    ? true
                    : contact?.buildingId === building.buildingId) &&
                  filterSearch(contact)
              )
              // TODO: This Filter may not be required once the Data Issue in the Backend Is fixed;
              // filter out duplicate contacts using contactId
              .filter(
                (contact, index, self) =>
                  index ===
                  self.findIndex((c) => c.contactId === contact.contactId)
              )
              .map((contact) => (
                <ContactRow
                  contact={contact}
                  selectedContact={selectedContactId}
                  setSelectedContact={selectedContactCB}
                />
              ));

            if (managersRows.length === 0 && residentRows.length === 0) {
              return null;
            }
            return (
              <div className="flex flex-col gap-2 mt-4">
                <div className="flex items-center gap-2">
                  <BuildingIconMessages />
                  <span className="font-bold">
                    {renderAddress(building.address)}
                  </span>
                </div>
                <div className="flex flex-col gap-2">
                  {managersRows}
                  {residentRows}
                </div>
              </div>
            );
          })}
    </div>
  );
}

// Styled with Tailwind CSS
const ContactRow = ({ contact, selectedContact, setSelectedContact }) => {
  const {
    contactId,
    firstName,
    lastName,
    email,
    phone,
    position,
    profilePicture,
  } = contact;
  return (
    <div
      className={`flex overflow-x-hidden items-center justify-between p-2 border-b border-gray-200 cursor-pointer ${
        selectedContact === contactId
          ? "bg-newBlue-500 text-gray-900"
          : "text-gray-700 hover:bg-gray-100"
      }`}
      onClick={() => setSelectedContact(contact)}
    >
      <div className="flex items-center gap-2">
        <Avatar
          src={profilePicture}
          variant="square"
          className="h-8 w-8 rounded"
        ></Avatar>
        <div className="flex flex-col">
          <span className="font-bold">{`${firstName} ${lastName}`}</span>
          <span className="text-xs">
            {properString(
              position === "MANAGER" && contact.client?.subCategory === "HOA"
                ? "BOARD_MEMBER"
                : position
            )}
            {contact.unitNumber &&
              (position === "OWNER" || position === "TENANT") &&
              ` - ${contact.unitNumber}`}
          </span>
          <span className="text-xs">{email}</span>
          <span className="text-xs">{phone}</span>
        </div>
      </div>
    </div>
  );
};
