// Generated by ReScript, PLEASE EDIT WITH CARE

import * as PhoneNumberGen from "./PhoneNumber.gen";

var make = PhoneNumberGen.make;

export {
  make ,
  
}
/* make Not a pure module */
