/* Untyped file generated from Address.res by genType. */
/* eslint-disable */

import {default as makeNotChecked} from './Address.jsx';

// In case of type error, check the type of 'make' in 'Address.re' and './Address.jsx'.
export const makeTypeChecked = makeNotChecked;

// Export 'make' early to allow circular import from the '.bs.js' file.
export const make = makeTypeChecked;
