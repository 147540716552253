import React from "react";

import TextField from "@mui/material/TextField";

import { deepFind } from "../../../../utils/StateUtils";

export default function Number({
  variant,
  data,
  name,
  label,
  onChange,
  optional,
  className,
}) {
  const escape = (text) => text.replace(" ", "").replace("-", "");
  const value = deepFind(name, data) || "";
  return (
    <TextField
      id="outlined-basic"
      className={className}
      label={label + (optional ? " (Optional)" : "")}
      variant={variant === "OUTLINED" ? "outlined" : "standard"}
      placeholder={label}
      name={name}
      value={value}
      onChange={(e) => {
        e.preventDefault();
        if (e.target.value === "+" || !isNaN(escape(e.target.value))) {
          if (variant === "DECIMAL5") {
            if (e.target.value % 0.5 === 0) onChange(e.target);
          } else {
            onChange(e.target);
          }
        }
      }}
      fullWidth
    />
  );
}
