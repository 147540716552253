import { useState } from "react";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import { buyPhoneNumber } from "../../api/Api";
import {
  InfoIcon,
  LinkIcon,
  MmsIcon,
  SmsIcon,
  VoiceIcon,
} from "../../components/common/AppIcons";
import { renderPhone } from "../../utils/StringUtils";

type ConfirmPhoneNumberProps = {
  phone: string;
  fee: string;
  onSuccess: () => void;
  onCancel: () => void;
};

export default function ConfirmPhoneNumber({
  fee,
  phone,
  onCancel,
  onSuccess,
}: ConfirmPhoneNumberProps) {
  const [friendlyName, setFriendlyName] = useState("");
  const handleBuy = () => {
    if (friendlyName === "") alertError("Friendly name required");
    else {
      buyPhoneNumber({
        phoneNumber: phone,
        friendlyName: friendlyName,
      })
        .then(() => {
          alertSuccess("Phone number purchased successfully");
          onSuccess();
          
        })
        .catch(() => {
          alertError("Error occurred");
        });
    }
  };

  return (
    <div className="flex flex-col p-4">
      <div className="text-newBlue-400 text-lg font-semibold mb-4">
        Review Phone Number
      </div>
      <div className="flex flex-row justify-between items-center">
        <div className="text-black text-2xl font-bold">
          {renderPhone(phone)}
        </div>
        <div className="flex flex-row items-end">
          <div className="text-black text-2xl font-bold">{fee}</div>
          <div className="text-newGray-800 text-sm font-normal mb-1 ml-2">
            monthly fee
          </div>
        </div>
      </div>

      <div className="flex flex-row items-start py-4">
        <div className="-mt-1">
          <InfoIcon />
        </div>
        <div className="flex flex-col">
          <div className="text-newGray-800 text-sm font-normal ml-2">
            {`You’ll be charged ${fee} immediately. Afterwards, you’ll be charged`}
          </div>
          <div className="text-newGray-800 text-sm font-normal ml-2">
            $1.15/month in addition to the usage you incur on the phone number
          </div>
          <div className="flex flex-row items-end">
            <a
              href="https://support.twilio.com/hc/en-us/articles/223182908-How-much-does-a-phone-number-cost-"
              className="text-newBlue-400 text-sm font-normal ml-2 underline mr-1"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
            <div className="mb-1">
              <LinkIcon className="h-3 w-3" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col border-t-2 border-b-2 border-newGray-700">
        <div className="text-black text-base font-semibold mt-4">
          Capabilities
        </div>
        <div className="flex flex-row my-4">
          <VoiceIcon className="h-8 w-8" />
          <div className="flex-flex-col ml-4">
            <div className="text-black text-base font-semibold">Voice</div>
            <div className="text-newGray-800 font-normal text-sm">
              Receive incoming calls and make outgoing calls
            </div>
          </div>
        </div>
        <div className="flex flex-row my-4">
          <SmsIcon className="h-8 w-8" />
          <div className="flex-flex-col ml-4">
            <div className="text-black text-base font-semibold">SMS</div>
            <div className="text-newGray-800 font-normal text-sm">
              Send and receive multi-media messages
            </div>
          </div>
        </div>
        <div className="flex flex-row my-4">
          <MmsIcon className="h-8 w-8" />
          <div className="flex-flex-col ml-4">
            <div className="text-black text-base font-semibold">MMS</div>
            <div className="text-newGray-800 font-normal text-sm">
              Send and receive multi-media messages
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col py-4">
        <div className="text-black text-base font-semibold">
          A2P 10DLC registration required for US messaging
        </div>
        <div className="text-newGray-800 font-normal text-sm">
          To send SMS/MMS messages to the US with this Local number, a
          registration process
        </div>
        <div className="text-newGray-800 font-normal text-sm">
          is required. This process can be performed after the number is
          purchased.
        </div>
        <div className="flex flex-row items-end">
          <a
            href="https://support.twilio.com/hc/en-us/articles/1260800720410-What-is-A2P-10DLC-"
            className="text-newBlue-400 text-sm font-normal underline mr-1"
            target="_blank"
            rel="noreferrer"
          >
            Learn more about A2P 10 DLC registration
          </a>
          <div className="mb-1">
            <LinkIcon className="h-3 w-3" />
          </div>
        </div>

        <div className="font-medium  text-base leading-8 h-6  mt-3">
          Friendly Name<span className="text-newRed-400">*</span>
        </div>

        <div className="w-full flex-1 mr-4 ">
          <div className="bg-white my-2 p-1 flex border border-newGray-700 rounded ">
            <input
              className="p-1 px-2 appearance-none outline-none w-full text-newGray-800"
              name="territory"
              type="text"
              value={friendlyName}
              onChange={(input) => setFriendlyName(input.target.value)}
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-row-reverse mt-4 w-full">
        <button
          className="text-base w-72 ml-4  flex justify-center items-center px-4 py-2 rounded font-medium  
          bg-newBlue-400 
          text-white border-newBlue-400
          border"
          onClick={handleBuy}
        >
          {`Buy ${renderPhone(phone)}`}
        </button>
        <button
          className="text-base flex justify-center items-center px-4 py-2 rounded font-medium cursor-pointer 
          bg-white
          text-newBlue-400 border-newBlue-400
          border duration-200 ease-in-out 
          transition"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
