type PageLayoutProps = {
  headerLeft: JSX.Element;
  headerRight: JSX.Element;
  content: JSX.Element;
};

export default function PageLayout({
  headerLeft,
  headerRight,
  content,
}: PageLayoutProps) {
  return (
    <div className="flex max-h-xscreen h-full w-full bg-white  mx-auto gap-2 flex-col p-4 rounded-md justify-start  overflow-auto">
      <div className="flex flex-col-reverse md:flex-row justify-between m-4">
        <div className="text-black font-medium text-base">{headerLeft}</div>
        <div className="text-black font-medium text-base">{headerRight}</div>
      </div>
      <div className="text-black font-medium text-base">{content}</div>
    </div>
  );
}
