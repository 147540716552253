import React, { useEffect, useState } from "react";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import makeStyles from "@mui/styles/makeStyles";
import { navigate } from "raviger";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import { resetPasswordRecovery, verifyPasswordRecovery } from "../../api/Api";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit">The Help Squad</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(18),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function RecoverPassword({ uuid, email }) {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  // Add these variables to your component to track the state
  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
  const handleMouseDownPassword1 = () => setShowPassword1(!showPassword1);

  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  const [validUuid, setValidUuid] = useState(true);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (password1 === "" || password2 === "") {
      alertError("Passwords cannot be empty");
    } else if (password1.length < 6 || password2.length < 6) {
      alertError("Passwords must be at least 6 characters long");
    } else if (password1 !== password2) {
      alertError("Passwords do not match");
    } else {
      resetPasswordRecovery({ uuid: uuid, email: email, password: password1 })
        .then((response) => {
          alertSuccess(response.comment);
          navigate("/");
        })
        .catch((error) => {
          alertError(error.message);
        });
    }
  };
  const classes = useStyles();

  useEffect(() => {
    verifyPasswordRecovery({ uuid: uuid, email: email })
      .then((response) => {})
      .catch((error) => {
        setValidUuid(false);
        alertError("Invalid Password Recovery Link");
      });
  }, []);

  return validUuid ? (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Recover Password
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            label="Password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type={showPassword1 ? "text" : "password"} // <-- This is where the magic happens
            value={password1}
            onChange={(event) => setPassword1(event.target.value)}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword1}
                  >
                    {showPassword1 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type={showPassword2 ? "text" : "password"} // <-- This is where the magic happens
            value={password2}
            onChange={(event) => setPassword2(event.target.value)}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword2}
                    onMouseDown={handleMouseDownPassword2}
                  >
                    {showPassword2 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Save Password
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  ) : (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Invalid Password Recovery Link. Please try again{" "}
          <Link href="/forgot-password">here</Link>
        </Typography>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
