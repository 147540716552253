import React, { useState } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";

export function getUrlExtension(url) {
  return url.split(/[#?]/)[0].split(".").pop().trim();
}

export function changeExtension(url, extn, target = "jpg") {
  return url.replace(new RegExp(`.${extn}$`), `.${target}`);
}

export function processUrl(url, target) {
  const extn = getUrlExtension(url);
  // console.log("Extension", extn);
  if (target !== undefined) {
    // console.log("Target Defined: " + target + " | From: " + extn, url);
    url = changeExtension(url, extn, target);
  } else {
    // console.log("Target Undefined");
    switch (extn) {
      // case "mov":
      //   url = changeExtension(url, "mov", mp4)
      //   break;
      case "heic":
        url = changeExtension(url, "heic", "jpg");
        break;
    }
  }
  return url;
}

export default function PreviewLink({ url, tileView }) {
  const [contentType, setContentType] = useState(
    getUrlExtension(url) === "pdf" ? "PDF" : "IMAGE"
  );
  let processedUrl = "";
  if (url) {
    processedUrl = processUrl(url);
  }

  return contentType === "PDF" ? (
    tileView ? (
      <a
        href={processedUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        <img
          className="h-16 w-16 border object-contain cursor-pointer"
          src={processUrl(url, "jpg")}
          onError={(_) => setContentType("VIDEO")}
          alt={processedUrl}
        />
      </a>
    ) : (
      <a
        href={processedUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        <img
          className="h-56 object-contain cursor-pointer"
          src={processUrl(url, "jpg")}
          onError={(_) => setContentType("VIDEO")}
          alt={processedUrl}
        />
      </a>
    )
  ) : contentType === "IMAGE" ? (
    tileView ? (
      <img
        className="h-16 w-16 border object-contain cursor-pointer"
        src={processedUrl}
        onError={(_) => setContentType("VIDEO")}
        alt={processedUrl}
      />
    ) : (
      <img
        className="h-56 object-contain cursor-pointer"
        src={processedUrl}
        onError={(_) => setContentType("VIDEO")}
        alt={processedUrl}
      />
    )
  ) : contentType === "VIDEO" ? (
    tileView ? (
      <div className="relative h-16 w-16 cursor-pointer">
        <img
          className="absolute h-16 w-16 object-contain"
          src={processUrl(processedUrl, "jpg")}
          onError={(_) => setContentType("OTHER")}
          alt={processedUrl}
        />
        <PlayCircleFilledIcon
          className="absolute text-gray-200 opacity-80"
          style={{
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div>
    ) : (
      <div className="cursor-pointer">
        <video width="400" controls poster={processUrl(processedUrl, "jpg")}>
          <source src={processUrl(processedUrl, "webm")} type="video/webm" />
          <source src={processUrl(processedUrl, "mp4")} type="video/mp4" />
          <source
            src={processUrl(processedUrl, "mov")}
            onError={(_) => setContentType("OTHER")}
            type="video/mov"
          />
        </video>
      </div>
    )
  ) : tileView ? (
    <a
      href={processedUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e) => e.stopPropagation()}
    >
      <AttachFileIcon className="h-16 w-16 border" />
    </a>
  ) : (
    <a
      href={processedUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e) => e.stopPropagation()}
    >
      <AttachFileIcon className="text-sm" />{" "}
      {processedUrl.replaceAll("%20", " ").split("/").slice(-1)}
    </a>
  );
}
