import { alertError } from "../actions/AlertActions";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// export const API_BASE_URL = "https://uscapi.helpsquad.us/api";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const ADMIN_TOKEN = "ADMIN_TOKEN";

const request = (options) => {
  const headers = new Headers({
    "Content-Type": "application/json",
  });
  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  // if(devEnv)
  if (process.env.NODE_ENV !== "production") {
    console.log("Request ", options);
  }

  return fetch(options.url, options).then((response) =>
    response
      .json()
      .then((json) => {
        if (!response.ok) {
          if (json.readableMessage) alertError(json.readableMessage);
          return Promise.reject({ data: json, status: response.status });
        }
        return json;
      })
      .catch((error) => {
        console.log("Error ", error);
        alertError("Something went wrong. Please try again!", undefined, {
          deferred: true,
        });
        return Promise.reject(error);
      })
  );
};

export function parameterize(obj) {
  const requestParams = Object.entries(obj).reduce((acc, [key, value]) => {
    if (value) {
      acc.push(`${key}=${value}`);
    }
    return acc;
  }, []);
  return requestParams.join("&");
}

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: API_BASE_URL + "/auth/me",
    method: "GET",
  });
}

export function updateLastOnline() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: API_BASE_URL + "/auth/updateLastOnline",
    method: "GET",
  });
}

export function adminResetPassword(data) {
  return request({
    url: API_BASE_URL + "/auth/resetPassword",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function login(data) {
  return request({
    url: API_BASE_URL + "/auth/signin",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function listUsers(vendorId) {
  let url = API_BASE_URL + "/admin/users";
  if (vendorId) url = API_BASE_URL + `/admin/users?vendorId=${vendorId}`;
  return request({
    url: url,
    method: "GET",
  });
}
export function getZipCodes(territoryId) {
  return request({
    url: API_BASE_URL + `/territory/${territoryId}/zipcode/`,
    method: "GET",
  });
}

export function createZipCode(territoryId, data) {
  return request({
    url: API_BASE_URL + `/territory/${territoryId}/zipcode/`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function deleteZipCode(territoryId, zipCode) {
  return request({
    url: API_BASE_URL + `/territory/${territoryId}/zipcode/${zipCode}`,
    method: "DELETE",
  });
}

export function getMyVendor() {
  return request({
    url: API_BASE_URL + `/user/myVendor`,
    method: "GET",
  });
}

export function initiateStripe() {
  return request({
    url: API_BASE_URL + `/stripe`,
    method: "POST",
  });
}

export function stripeInfo() {
  return request({
    url: API_BASE_URL + `/stripe/info`,
    method: "GET",
  });
}

export function stripeLink() {
  return request({
    url: API_BASE_URL + `/stripe/link`,
    method: "GET",
  });
}

export function stripeAccountSession() {
  return request({
    url: API_BASE_URL + `/stripe/account_session`,
    method: "GET",
  });
}

export function deleteAllZipCodes(territoryId) {
  return request({
    url: API_BASE_URL + `/territory/${territoryId}/zipcode/deleteAll`,
    method: "DELETE",
  });
}

export function bulkCreateZipCodes(territoryId, data) {
  return request({
    url: API_BASE_URL + `/territory/${territoryId}/zipcode/bulkCreate`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function createUser(data) {
  return request({
    url: API_BASE_URL + "/auth/signup",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function createAssociate(data) {
  return request({
    url: API_BASE_URL + "/associate/create",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function listAssociates(filter) {
  let url = API_BASE_URL + "/associate/all?territory=" + filter.territory;
  if (filter.type) {
    if (filter.type !== "ALL") {
      url =
        API_BASE_URL +
        `/associate/all?type=${filter.type}&territory=${filter.territory}`;
    }
  }
  return request({
    url: url,
    method: "GET",
  });
}

export function getAssociatesByTerritory(territory) {
  return request({
    url: API_BASE_URL + "/associate/getByTerritory/" + territory,
    method: "GET",
  });
}

export function getAllPipeLineStages(pipelineId) {
  return request({
    url: API_BASE_URL + `/pipeline/${pipelineId}/stage/all`,
    method: "GET",
  });
}

export function createPipeline(data) {
  return request({
    url: API_BASE_URL + `/pipeline/`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function createPipelineStage(pipelineId, data) {
  return request({
    url: API_BASE_URL + `/pipeline/${pipelineId}/stage/`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function updatePipelineStage(pipelineId, stageId, data) {
  return request({
    url: API_BASE_URL + `/pipeline/${pipelineId}/stage/${stageId}`,
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function updateVendor(vendorId, data) {
  return request({
    url: API_BASE_URL + `/vendors/${vendorId}`,
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function updatePipeline(pipelineId, data) {
  return request({
    url: API_BASE_URL + `/pipeline/${pipelineId}`,
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function deleteStage(pipelineId, stageId) {
  return request({
    url: API_BASE_URL + `/pipeline/${pipelineId}/stage/${stageId}`,
    method: "DELETE",
  });
}

export function deletePipeline(pipelineId) {
  return request({
    url: API_BASE_URL + `/pipeline/${pipelineId}`,
    method: "DELETE",
  });
}

export function findByTerritoryWithAppointmentCount(data) {
  return request({
    url:
      API_BASE_URL +
      "/associate/findByTerritoryWithAppointmentCount/" +
      data.territory,
    method: "POST",
    body: JSON.stringify({
      timelineStart: data.timelineStart,
      timelineEnd: data.timelineEnd,
    }),
  });
}

export function getAssociate(data) {
  return request({
    url: API_BASE_URL + "/associate/get/" + data.associateId,
    method: "GET",
  });
}

export function createClient(data) {
  return request({
    url: API_BASE_URL + "/client/create",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function listClients(filter) {
  return request({
    url: API_BASE_URL + `/client/${filter}`,
    method: "GET",
  });
}

export function getClient(clientId) {
  return request({
    url: API_BASE_URL + "/client/get/" + clientId,
    method: "GET",
  });
}

export function getBuilding(buildingId) {
  return request({
    url: API_BASE_URL + "/building/get/" + buildingId,
    method: "GET",
  });
}

export function getLocation(locationId) {
  return request({
    url: API_BASE_URL + "/location/" + locationId,
    method: "GET",
  });
}

export function createBuilding(building) {
  return request({
    url: API_BASE_URL + "/building/create",
    method: "POST",
    body: JSON.stringify(building),
  });
}

export function updateBuilding(buildingId, data) {
  return request({
    url: API_BASE_URL + `/building/${buildingId}`,
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function createLocation(location) {
  return request({
    url: API_BASE_URL + "/location/create",
    method: "POST",
    body: JSON.stringify(location),
  });
}

export function listClientContacts(clientId) {
  return request({
    url: API_BASE_URL + `/contact/clientId/${clientId}`,
    method: "GET",
  });
}

export function assignContactToBuilding(data) {
  return request({
    url: API_BASE_URL + `/contact/building`,
    method: "POST",
    body: JSON.stringify({ ...data }),
  });
}

export function assignContactToBuildings(contactId, buildingIds) {
  return request({
    url: API_BASE_URL + `/contact/building`,
    method: "POST",
    body: JSON.stringify({ contactId, buildingIds }),
  });
}

export function createContact(contact) {
  return request({
    url: API_BASE_URL + "/contact/create",
    method: "POST",
    body: JSON.stringify(contact),
  });
}

export function createSupplierContact(contact, supplierId) {
  return request({
    url: API_BASE_URL + `/suppliers/${supplierId}/contact`,
    method: "POST",
    body: JSON.stringify(contact),
  });
}

export function createLocationContact(contact, locationId) {
  return request({
    url: API_BASE_URL + `/location/${locationId}/contact`,
    method: "POST",
    body: JSON.stringify(contact),
  });
}

// Twilio Access Tokens
export function getCapabilityToken() {
  return request({
    url: API_BASE_URL + "/twilio/token",
    method: "GET",
  });
}

// Twilio Access Tokens
export function getWorkerToken() {
  return request({
    url: API_BASE_URL + "/twilio/getWorkerToken",
    method: "GET",
  });
}

export function getChatToken() {
  return request({
    url: API_BASE_URL + "/twilio/getWebChatTokenCSR",
    method: "GET",
  });
}

export async function joinConversation(conversationSid) {
  return request({
    url: API_BASE_URL + "/twilio/joinWebChatCSR",
    method: "POST",
    body: JSON.stringify(conversationSid),
  });
}

export function endConversation(conversationSid, contactId) {
  return request({
    url: API_BASE_URL + "/twilio/deleteConversation?contactId=" + contactId,
    method: "POST",
    body: JSON.stringify(conversationSid),
  });
}

export function endSpamConversation(conversationSid) {
  return request({
    url: API_BASE_URL + "/twilio/deleteConversation?spam=true",
    method: "POST",
    body: JSON.stringify(conversationSid),
  });
}

export function sendMessage(message) {
  return request({
    url: API_BASE_URL + "/twilio/sendMessage",
    method: "POST",
    body: JSON.stringify(message),
  });
}
// TODO: Remove all Usage and Purge
export function getTicketMessages(ticketId) {
  return request({
    url: API_BASE_URL + "/communication/getByTicketId/" + ticketId,
    method: "GET",
  });
}
export function getMessagesForTickets(data) {
  return request({
    url: API_BASE_URL + "/communication/getByTicketIds",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getMessagesForExternal(data) {
  return request({
    url: API_BASE_URL + "/communication/getForUnknown",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getContactMessages(data) {
  return request({
    url: API_BASE_URL + "/communication/getForContacts",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function saveInternalNotes(data) {
  return request({
    url: API_BASE_URL + "/communication/addInternalNotes",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getInboxAll() {
  return request({
    url: API_BASE_URL + "/communication/getInboxAll",
    method: "GET",
  });
}

export function getInboxAllIncoming() {
  return request({
    url: API_BASE_URL + "/communication/getInboxAllIncoming",
    method: "GET",
  });
}

export function getInbox() {
  return request({
    url: API_BASE_URL + "/communication/getInbox",
    method: "GET",
  });
}

export function getInboxUnknown() {
  return request({
    url: API_BASE_URL + "/communication/getInboxUnknown",
    method: "GET",
  });
}
export function getInboxFSO() {
  return request({
    url: API_BASE_URL + "/communication/getInboxFSO",
    method: "GET",
  });
}

export function identifyCaller(phoneNumber) {
  return request({
    url: API_BASE_URL + "/contact/getFromPhone/" + JSON.stringify(phoneNumber),
    method: "GET",
  });
}

export function getSR(serviceRequestId) {
  return request({
    url: API_BASE_URL + "/sr/get/" + serviceRequestId,
    method: "GET",
  });
}

export function createSR(serviceRequest) {
  return request({
    url: API_BASE_URL + "/sr/create",
    method: "POST",
    body: JSON.stringify(serviceRequest),
  });
}

export function listSRs(status) {
  return request({
    url: API_BASE_URL + "/sr/getAll/" + status,
    method: "GET",
  });
}

export function getClientSRs(clientId) {
  return request({
    url: API_BASE_URL + "/sr/getByClientId/" + clientId,
    method: "GET",
  });
}

export function getPipelines() {
  return request({
    url: API_BASE_URL + "/pipeline/all",
    method: "GET",
  });
}

export function getVendors(pageable, type, search) {
  const URL =
    type === "ALL" || type === undefined
      ? `/vendors${
          search === "" || search === undefined ? "" : `?search=${search}`
        }`
      : `/vendors?type=${type}${
          search === "" || search === undefined ? "" : `&search=${search}`
        }`;
  return request({
    url: API_BASE_URL + URL,
    method: "POST",
    body: JSON.stringify(pageable),
  });
}

export function getPhoneNumbers(page, size, phone) {
  const URL = phone
    ? `/phonenumbers?page=${page}&size=${size}&phone=${phone}`
    : `/phonenumbers?page=${page}&size=${size}`;
  return request({
    url: API_BASE_URL + URL,
    method: "GET",
  });
}

export function getAvailablePhoneNumbers(params) {
  return request({
    url: API_BASE_URL + `/phonenumbers/availableToBuy${params ?? ""}`,
    method: "GET",
  });
}
export function getVendorById(vendorId) {
  return request({
    url: API_BASE_URL + `/vendors/${vendorId}`,
    method: "GET",
  });
}

export function getPipelineTickets(pipelineId, territory, ownerId) {
  return request({
    url:
      API_BASE_URL +
      "/ticket/newApi/pipeline/" +
      pipelineId +
      (territory ? "/territory/" + territory : "") +
      (ownerId !== -2 ? `?ownerId=${ownerId}` : ""),
    method: "GET",
  });
}

export function getTodo() {
  return request({
    url: API_BASE_URL + "/ticket/todo",
    method: "GET",
  });
}

export function markTodo(ticketId, toDo) {
  return request({
    url: API_BASE_URL + "/ticket/" + ticketId + "/updateToDo",
    method: "POST",
    body: JSON.stringify(toDo),
  });
}

export function createTicket(newTicket) {
  return request({
    url: API_BASE_URL + "/ticket/create",
    method: "POST",
    body: JSON.stringify(newTicket),
  });
}

export function buyPhoneNumber(data) {
  return request({
    url: API_BASE_URL + "/phonenumbers/buy",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function updateTicket(ticket) {
  return request({
    url: API_BASE_URL + `/ticket/newApi/${ticket.ticketId}/update`,
    method: "POST",
    body: JSON.stringify(ticket),
  });
}

export function moveTicket(moveRequest) {
  return request({
    url: API_BASE_URL + "/ticket/move",
    method: "POST",
    body: JSON.stringify(moveRequest),
  });
}

export function clientTickets(clientId) {
  return request({
    url: API_BASE_URL + "/ticket/client/" + clientId,
    method: "GET",
  });
}

export function getUnscheduledByTerritory(territory) {
  return request({
    url: API_BASE_URL + "/sr/getUnscheduledByTerritory/" + territory,
    method: "GET",
  });
}

export function scheduleSR(scheduleRequest) {
  return request({
    url: API_BASE_URL + "/sr/schedule",
    method: "POST",
    body: JSON.stringify(scheduleRequest),
  });
}

export function scheduledSRs(dateRange) {
  return request({
    url: API_BASE_URL + "/sr/upcomingAssigned",
    method: "POST",
    body: JSON.stringify(dateRange),
  });
}

export function getNotifications(pageable, isRead) {
  const notificationUrl =
    isRead === undefined
      ? `/notification/getAll`
      : `/notification/getAll?isRead=${isRead}`;
  return request({
    url: API_BASE_URL + notificationUrl,
    method: "POST",
    body: JSON.stringify(pageable),
  });
}

export function markNotificationAsRead(notificationId) {
  return request({
    url: API_BASE_URL + "/notification/markAsRead",
    method: "POST",
    body: Number(notificationId),
  });
}

export function addNotes(notes) {
  return request({
    url: API_BASE_URL + "/communication/addNotes",
    method: "POST",
    body: JSON.stringify(notes),
  });
}

export function reassignComm(data) {
  return request({
    url: API_BASE_URL + "/communication/assignToTicket",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getAssociateHistory(associateId, params) {
  return request({
    url:
      API_BASE_URL +
      "/sr/getByAssociateId/" +
      associateId +
      "?" +
      (params ?? ""),
    method: "GET",
  });
}

export function getAssociateMessages(associateId, page) {
  return request({
    url: API_BASE_URL + "/communication/getInternalMessages/" + associateId,
    method: "POST",
    body: JSON.stringify(page),
  });
}

export function sendInternalMessage(data) {
  return request({
    url: API_BASE_URL + "/communication/sendInternalMessage",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function fileUpload(data) {
  return request({
    url: API_BASE_URL + "/sr/uploadCSR",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function adminFileUpload(data) {
  return request({
    url: API_BASE_URL + "/admin/uploadFile",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function createAdmin(data) {
  return request({
    url: API_BASE_URL + "/auth/signup",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function createUnit(data) {
  return request({
    url: API_BASE_URL + "/units",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function updateUnit(unitNumber, data) {
  return request({
    url: API_BASE_URL + `/units/${unitNumber}`,
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function getUnits(page, size, buildingId, searchText, filterText) {
  return request({
    url:
      API_BASE_URL +
      `/units?buildingId=${buildingId}&page=${page}&size=${size}&search=${searchText}${filterText}`,
    method: "GET",
  });
}

export function getLeases(page, size, unitId) {
  return request({
    url: API_BASE_URL + `/leases?unitId=${unitId}&page=${page}&size=${size}`,
    method: "GET",
  });
}

export function getInvoices(
  page,
  size,
  ticketId,
  searchText,
  clientId,
  contactId,
  buildingId,
  status
) {
  return request({
    url:
      API_BASE_URL +
      `/invoice?ticketId=${ticketId}&page=${page}&size=${size}&search=${searchText}&clientId=${clientId}&contactId=${contactId}&buildingId=${buildingId}&status=${status}`,
    method: "GET",
  });
}

export function getPayments(invoiceId, page, size) {
  return request({
    url:
      API_BASE_URL +
      `/invoice/${invoiceId}/payments?&page=${page}&size=${size}`,
    method: "GET",
  });
}

export function getInvoiceById(invoiceId) {
  return request({
    url: API_BASE_URL + `/invoice/${invoiceId}`,
    method: "GET",
  });
}

export function sendReminder(invoiceId) {
  return request({
    url: API_BASE_URL + `/invoice/${invoiceId}/reminder`,
    method: "POST",
  });
}
export function updateSr(srId, data) {
  return request({
    url: API_BASE_URL + `/sr/${srId}`,
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function updateMaterial(materialId, data) {
  return request({
    url: API_BASE_URL + `/materials/${materialId}`,
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function removeSR(invoiceId, srId) {
  return request({
    url: API_BASE_URL + `/invoice/${invoiceId}/sr/${srId}`,
    method: "DELETE",
  });
}

export function addSR(invoiceId, srId) {
  return request({
    url: API_BASE_URL + `/invoice/${invoiceId}/sr/${srId}`,
    method: "POST",
  });
}

export function sendInvoice(invoiceId, data) {
  return request({
    url: API_BASE_URL + `/invoice/${invoiceId}/send`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getUnitById(unitId) {
  return request({
    url: API_BASE_URL + `/units/${unitId}`,
    method: "GET",
  });
}

export function createLease(data) {
  return request({
    url: API_BASE_URL + "/leases",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function updateLease(leaseId, data) {
  return request({
    url: API_BASE_URL + `/leases/${leaseId}`,
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function getAllTenants(leaseId) {
  return request({
    url: API_BASE_URL + `/leases/${leaseId}/tenants`,
    method: "GET",
  });
}

export function removeTenant(leaseId, contactId) {
  return request({
    url: API_BASE_URL + `/leases/${leaseId}/tenants/${contactId}`,
    method: "DELETE",
  });
}

export function addTenantUnderLease(leasedId, data) {
  return request({
    url: API_BASE_URL + `/leases/${leasedId}/tenants`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getLeaseById(leaseId) {
  return request({
    url: API_BASE_URL + `/leases/${leaseId}`,
    method: "GET",
  });
}

export function createProspect(data) {
  return request({
    url: API_BASE_URL + `/prospects`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getAllProspects(page, size, ticketId) {
  return request({
    url:
      API_BASE_URL +
      `/prospects?page=${page}&size=${size}&ticketId=${ticketId}`,
    method: "GET",
  });
}

export function getProspectById(prospectId) {
  return request({
    url: API_BASE_URL + `/prospects/${prospectId}`,
    method: "GET",
  });
}

export function updateProspect(prospectId, data) {
  return request({
    url: API_BASE_URL + `/prospects/${prospectId}`,
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function createTerritory(data, vendorId) {
  return request({
    url: API_BASE_URL + `/vendors/${vendorId}/createTerritoryWithZipcodes`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function createVendor(data) {
  return request({
    url: API_BASE_URL + "/vendors/create",
    method: "POST",
    body: JSON.stringify(data),
  });
}
export function mergeBuildings(data) {
  return request({
    url: API_BASE_URL + "/admin/mergeBuildings",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function mergeClients(data) {
  return request({
    url: API_BASE_URL + "/admin/mergeClients",
    method: "POST",
    body: JSON.stringify(data),
  });
}

// {
//   "buildingId": 0,
//   "contactId": 0
// }
export function unlinkContact(data) {
  return request({
    url: API_BASE_URL + "/building/removeContact",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getTicketSRs(ticketId) {
  return request({
    url: API_BASE_URL + "/sr/getByTicketId/" + ticketId,
    method: "GET",
  });
}

export function getTicket(ticketId) {
  return request({
    url: API_BASE_URL + "/ticket/newApi/" + ticketId,
    method: "GET",
  });
}

export function pauseRecording(data) {
  return request({
    url: API_BASE_URL + "/twilio/pauseRecording",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function resumeRecording(data) {
  return request({
    url: API_BASE_URL + "/twilio/resumeRecording",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getSupervisors(territory) {
  return request({
    url: API_BASE_URL + "/user/getAll?role=SUPERVISOR&territory=" + territory,
    method: "GET",
  });
}

export function getOwners(territory) {
  return request({
    url: API_BASE_URL + "/user/getAll?role=CSR&territory=" + territory,
    method: "GET",
  });
}

export function getCSRs() {
  return request({
    url: API_BASE_URL + "/user/getAll?role=CSR",
    method: "GET",
  });
}

export function assignOwner(data) {
  return request({
    url: API_BASE_URL + "/ticket/assignOwner",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function updateConversation(data) {
  return request({
    url: API_BASE_URL + "/twilio/addAttributesToConversation",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function createEstimate(data) {
  return request({
    url: API_BASE_URL + "/estimate/create",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function updateEstimate(estimateId, data) {
  return request({
    url: API_BASE_URL + `/estimate/${estimateId}`,
    method: "PATCH",
    body: JSON.stringify(data),
  });
}
export function getAppointmentsForAssociate(data) {
  return request({
    url: API_BASE_URL + `/sr/getAppointmentsForAssociate/${data.associateId}`,
    method: "POST",
    body: JSON.stringify({
      timelineStart: data.timelineStart,
      timelineEnd: data.timelineEnd,
    }),
  });
}
export function findFromPhoneOrEmail(data) {
  return request({
    url: API_BASE_URL + "/contact/findFromPhoneOrEmail",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function linkToClient(data) {
  return request({
    url: API_BASE_URL + "/contact/linkToClient",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function downloadEstimate(id) {
  return request({
    url: API_BASE_URL + `/estimate/${id}/download`,
    method: "GET",
  });
}
export function getEstimate(id) {
  return request({
    url: API_BASE_URL + "/estimate/getById/" + id,
    method: "GET",
  });
}

export function getVendorA2P() {
  return request({
    url: API_BASE_URL + "/admin/vendorA2P",
    method: "GET",
  });
}

export function updateVendorA2P(data) {
  return request({
    url: API_BASE_URL + "/admin/vendorA2P",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getEstimateInvoices(estimateId) {
  return request({
    url: API_BASE_URL + "/estimate/" + estimateId + "/invoice",
    method: "GET",
  });
}

export function getContactsForTicketId(id) {
  return request({
    url: API_BASE_URL + "/contact/ticketId/" + id,
    method: "GET",
  });
}
export function snoozeTicket(ticketId) {
  return request({
    url: API_BASE_URL + `/ticket/${ticketId}/snooze`,
    method: "POST",
    body: JSON.stringify({}),
  });
}

export function createResidentialForExisting(data) {
  return request({
    url: API_BASE_URL + `/client/createResidentialForExisting`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function sendEstimatePdf(data) {
  return request({
    url: API_BASE_URL + `/twilio/sendEstimatePdf`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function connectToQuickBooks(data) {
  return request({
    url: API_BASE_URL + `/quickbooks/connectToQuickbooks`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function assignToContractor(ticketId, subScriberId) {
  return request({
    url:
      API_BASE_URL + `/ticket/${ticketId}/assignToContractor/${subScriberId}`,
    method: "POST",
  });
}

export function createInvoiceForEstimate(estimateId, qbCustomer) {
  let URL = qbCustomer
    ? API_BASE_URL +
      `/quickbooks/qbo/createInvoiceForEstimate/${estimateId}?qbCustomerId=${qbCustomer}`
    : API_BASE_URL + `/quickbooks/qbo/createInvoiceForEstimate/${estimateId}`;
  return request({
    url: URL,
    method: "POST",
    // body: JSON.stringify({})
  });
}

export function createInvoice(data) {
  return request({
    url: API_BASE_URL + `/invoice/create`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function payInvoice(invoiceId, data) {
  return request({
    url: API_BASE_URL + `/invoice/${invoiceId}/pay`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function makePayment(invoiceId, contactId) {
  return request({
    url: API_BASE_URL + `/invoice/${invoiceId}/checkout?contactId=${contactId}`,
    method: "GET",
  });
}

export function getPayment(sessionId) {
  return request({
    url: API_BASE_URL + `/stripe/session_status?sessionId=${sessionId}`,
    method: "GET",
  });
}

export function updateInvoice(invoiceId, data) {
  return request({
    url: API_BASE_URL + `/invoice/${invoiceId}`,
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function getBillingEmails(ticketId) {
  return request({
    url: API_BASE_URL + `/ticket/${ticketId}/getBillingEmails`,
    method: "GET",
  });
}

export function qbGetCustomers(searchText, page = 1, pageSize = 10) {
  let url = API_BASE_URL + `/quickbooks/qbo/getCustomers`;
  if (searchText) {
    url = url + `?searchText=${searchText}`;
  }
  return request({
    url: url,
    method: "POST",
    body: JSON.stringify({
      page: page,
      size: pageSize,
    }),
  });
}

export function editUser(data) {
  return request({
    url: API_BASE_URL + `/admin/editUser`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function disableUser(userId) {
  return request({
    url: API_BASE_URL + `/admin/disableUser/${userId}`,
    method: "POST",
  });
}

// /api/client/47/switchToIndividual POST
export function UNSAFE_switchToIndividual(clientId) {
  return request({
    url: API_BASE_URL + `/client/${clientId}/switchToIndividual`,
    method: "POST",
  });
}

/*/api/buildingContact/{buildingContactId} PATCH
[
    {
        "op": "replace",
        "path": "/position",
        "value": "TENANT"
    }
]*/
export function updateBuildingContact(buildingContactId, data) {
  return request({
    url: API_BASE_URL + `/buildingContact/${buildingContactId}`,
    method: "PATCH",
    body: data,
  });
}

export function linkClient(data) {
  return request({
    url: API_BASE_URL + "/contact/linkToClient",
    method: "POST",
    body: JSON.stringify({
      contactId: data.contactId,
      clientId: data.clientId,
    }),
  });
}

export function unLinkClientContacts(contactId) {
  return request({
    url: API_BASE_URL + `/contact/${contactId}/unlinkFromClient`,
    method: "POST",
    body: {},
  });
}

export function getThirdPartyVendors(id) {
  let url = API_BASE_URL + `/thirdPartyCompanies/`;
  id && (url = API_BASE_URL + `/thirdPartyCompanies/${id}`);

  return request({
    url: url,
    method: "GET",
  });
}

export function createTPV(data) {
  return request({
    url: API_BASE_URL + `/thirdPartyCompanies/create`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function updateStatusCSR(data) {
  return request({
    url: API_BASE_URL + `/sr/updateStatusCSR`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function UNSAFE_getMelissaAddress(params) {
  return request({
    url: API_BASE_URL + `/address/getMelissaAddresses?${parameterize(params)}`,
    method: "GET",
  });
}

export function getBuildingUnits(buildingId) {
  return request({
    url: API_BASE_URL + `/building/${buildingId}/units`,
    method: "GET",
  });
}

export function removeBuilding(buildingId, clientId) {
  return request({
    url: API_BASE_URL + `/client/${clientId}/unlinkBuilding/${buildingId}`,
    method: "POST",
  });
}

export function removeTPVContact(associateId, contactId) {
  return request({
    url: API_BASE_URL + `/associate/${associateId}/contact/${contactId}`,
    method: "DELETE",
  });
}

export function removeLocationContact(locationId, contactId) {
  return request({
    url: API_BASE_URL + `/location/${locationId}/contact/${contactId}`,
    method: "DELETE",
  });
}

export function removeSupplierContact(supplierId, contactId) {
  return request({
    url: API_BASE_URL + `/suppliers/${supplierId}/contact/${contactId}`,
    method: "DELETE",
  });
}

export function removeLocation(locationId) {
  return request({
    url: API_BASE_URL + `/location/${locationId}`,
    method: "DELETE",
  });
}

export const associateContactUpdate = (data) => {
  return request({
    url: API_BASE_URL + `/contact/create`,
    method: "POST",
    body: JSON.stringify(data),
  });
};

export function listSupplier() {
  return request({
    url: API_BASE_URL + `/suppliers`,
    method: "GET",
  });
}

export function getSupplier(supplierId) {
  return request({
    url: API_BASE_URL + `/suppliers/${supplierId}`,
    method: "GET",
  });
}

export function createSupplier(data) {
  return request({
    url: API_BASE_URL + `/suppliers/create`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function listSupplierContacts(supplierId) {
  return request({
    url: API_BASE_URL + `/contact/supplierId/${supplierId}`,
    method: "GET",
  });
}

export function listSupplierAddress(supplierId) {
  return request({
    url: API_BASE_URL + `/location/supplier/${supplierId}`,
    method: "GET",
  });
}

export function createMaterials(data) {
  return request({
    url: API_BASE_URL + `/materials/create`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function estimateAccept(estimateId, data) {
  return request({
    url: API_BASE_URL + `/estimate/${estimateId}/accept`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getTerritory() {
  return request({
    url: API_BASE_URL + `/territory/`,
    method: "GET",
  });
}

export function editAddTerritory(data) {
  return request({
    url: API_BASE_URL + `/territory/`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getContactsBySr(serviceRequestId) {
  return request({
    url: API_BASE_URL + `/building/getContactsBySr/${serviceRequestId}`,
    method: "GET",
  });
}

export function getForAllClients(data) {
  return request({
    url: API_BASE_URL + `/communication/getForAllClients`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function updateTenant(leaseId, prospectId) {
  return request({
    url: API_BASE_URL + `/leases/${leaseId}/prospects/${prospectId}`,
    method: "POST",
  });
}

export function setCurrentLease(unitId, leaseId) {
  return request({
    url: API_BASE_URL + `/units/${unitId}/setCurrentLease/${leaseId}`,
    method: "POST",
  });
}

export function endLease(unitId) {
  return request({
    url: API_BASE_URL + `/units/${unitId}/unsetCurrentLease`,
    method: "POST",
  });
}

export function getForAllFSOs(data) {
  return request({
    url: API_BASE_URL + `/communication/getForAllFSOs`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function setListingId(leaseId) {
  return request({
    url: API_BASE_URL + `/leases/${leaseId}/listing`,
    method: "POST",
  });
}

export function twilioPhoneNumber() {
  return request({
    url: API_BASE_URL + `/marketingCampaign`,
    method: "GET",
  });
}

export function getTwilioStatus() {
  return request({
    url: API_BASE_URL + `/twilio/workerStatuses`,
    method: "GET",
  });
}

export function createUpdateTwilioPhoneNumber(data) {
  return request({
    url: API_BASE_URL + `/marketingCampaign`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getFromPhone(number) {
  return request({
    url: API_BASE_URL + `/marketingCampaign/getFromPhone/${number}`,
    method: "GET",
  });
}

export function newClientGet(clientId) {
  return request({
    url: API_BASE_URL + `/client/newApi/${clientId}`,
    method: "GET",
  });
}

export function getManagersPMC(clientId) {
  return request({
    url: API_BASE_URL + `/client/newApi/${clientId}/managers`,
    method: "GET",
  });
}

export function getBuildingsUnderCommercialClient(clientId) {
  return request({
    url:
      API_BASE_URL +
      `/client/newApi/${clientId}/buildingsUnderCommercialClient`,
    method: "GET",
  });
}

export function getBuildingManagers(clientId, buildingId) {
  return request({
    url:
      API_BASE_URL +
      `/client/newApi/${clientId}/building/${buildingId}/managers`,
    method: "GET",
  });
}

export function getBuildingResidentsOwners(buildingId, value) {
  return request({
    url: API_BASE_URL + `/building/newApi/${buildingId}/residents`,
    method: "POST",
    data: value ? { value } : {},
  });
}

export function getUnitsUnderHOA(clientId) {
  return request({
    url: API_BASE_URL + `/client/newApi/${clientId}/unitsUnderHoa`,
    method: "GET",
  });
}

export function createTicketNew(data) {
  return request({
    url: API_BASE_URL + `/ticket/newApi/create`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getTicketsCommercial(
  clientId,
  search,
  page = 0,
  size = 10,
  filterBuildingId,
  position
) {
  return request({
    url: API_BASE_URL + `/ticket/newApi/commercialClient/${clientId}`,
    method: "POST",
    body: JSON.stringify({
      search,
      position,
      buildingId: filterBuildingId,
      pageable: {
        page,
        size: size,
      },
    }),
  });
}

export function getTicketsIndividual(
  contactId,
  search,
  page = 0,
  size = 10,
  filterBuildingId,
  position
) {
  return request({
    url: API_BASE_URL + `/ticket/newApi/individualClient/${contactId}`,
    method: "POST",
    body: JSON.stringify({
      search,
      position,
      buildingId: filterBuildingId,
      pageable: {
        page,
        size: size,
      },
    }),
  });
}

export function getBuildingsUnderIndividualClient(contactId) {
  return request({
    url:
      API_BASE_URL +
      `/contact/newApi/${contactId}/buildingsUnderIndividualClient`,
    method: "GET",
  });
}

export function getContactDetails(contactId) {
  return request({
    url: API_BASE_URL + `/contact/get/${contactId}/`,
    method: "GET",
  });
}

export function getCommericalClients(
  clientClass = "ALL",
  search = "",
  page = 0,
  size = 10
) {
  // /api/client/newApi/getAllCommercial?clientClass=HOA/PMC
  return request({
    url:
      API_BASE_URL +
      `/client/newApi/getAllCommercial` +
      (clientClass !== "ALL" ? `?clientClass=${clientClass}` : ""),
    method: "POST",
    body: JSON.stringify({
      search,
      pageable: {
        page,
        size: size,
      },
    }),
  });
}

// Get all clients with addresses
export function searchIndividualClients(search = "", page = 0, size = 10) {
  return request({
    url: API_BASE_URL + `/contact/newApi/getAllIndividualClientsWithBuildings`,
    method: "POST",
    body: JSON.stringify({
      search,
      pageable: {
        page,
        size: size,
      },
    }),
  });
}

// Get all clients with addresses
export function getIndividualClients(search = "", page = 0, size = 10) {
  return request({
    url: API_BASE_URL + `/contact/newApi/getAllIndividualClients`,
    method: "POST",
    body: JSON.stringify({
      search,
      pageable: {
        page,
        size: size,
      },
    }),
  });
}

export function getBuildings(params, search = "", page = 0, size = 10) {
  return request({
    url: API_BASE_URL + `/building/newApi/getAll?` + parameterize(params),
    method: "POST",
    body: JSON.stringify({
      search,
      pageable: {
        page,
        size: size,
      },
    }),
  });
}

export function createIndividualClient(data) {
  return request({
    url: API_BASE_URL + `/contact/newApi/createIndividualClient`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function createUnderIndividualClient(data, contactId) {
  return request({
    url:
      API_BASE_URL +
      `/building/newApi/createUnderIndividualClient/${contactId}`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function createCommercialClient(data) {
  return request({
    url: API_BASE_URL + `/client/newApi/create`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function createPMCManagers(data, clientId) {
  return request({
    url: API_BASE_URL + `/client/newApi/${clientId}/createManager`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

// /api/client/newApi/{clientId}/fireManager/{contactId}
export function fireManager(clientId, contactId) {
  return request({
    url: API_BASE_URL + `/client/newApi/${clientId}/fireManager/${contactId}`,
    method: "POST",
  });
}

export function createUnderPMC(data, clientId) {
  return request({
    url: API_BASE_URL + `/building/newApi/createUnderPMC/${clientId}`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function createUnderHOA(data, clientId) {
  return request({
    url: API_BASE_URL + `/building/newApi/createUnderHOA/${clientId}`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function createUnitsUnderHoaBuilding(data, buildingId) {
  return request({
    url: API_BASE_URL + `/building/newApi/${buildingId}/createResidents`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function unitsWithResidents(buildingId) {
  return request({
    url: API_BASE_URL + `/building/newApi/${buildingId}/unitsWithResidents`,
    method: "GET",
  });
}

export function getBuildingManagersNewApi(buildingId) {
  return request({
    url: API_BASE_URL + `/building/newApi/${buildingId}/managers`,
    method: "GET",
  });
}

export function getBuildingClients(buildingId) {
  return request({
    url: API_BASE_URL + `/building/newApi/${buildingId}/allClients`,
    method: "GET",
  });
}

export function managerContacts(clientId) {
  return request({
    url: API_BASE_URL + `/client/newApi/${clientId}/managerContacts`,
    method: "GET",
  });
}

export function residentContacts(clientId) {
  return request({
    url: API_BASE_URL + `/client/newApi/${clientId}/residentContacts`,
    method: "GET",
  });
}

export function UNSAFE__removeContact(buildingId, data) {
  return request({
    url: API_BASE_URL + `/building/${buildingId}/removeContact`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function updateCommercialClient(clientId, data) {
  return request({
    url: API_BASE_URL + `/client/newApi/${clientId}/update`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function updateBuildingNewApi(buildingId, data) {
  return request({
    url: API_BASE_URL + `/building/newApi/${buildingId}/update`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function deleteUnitUnderManager(buildingId, data) {
  return request({
    url: API_BASE_URL + `/building/newApi/${buildingId}/unitUnderManager`,
    method: "DELETE",
    body: JSON.stringify(data),
  });
}

export function addUnitUnderManager(buildingId, data) {
  return request({
    url: API_BASE_URL + `/building/newApi/${buildingId}/unitUnderManager`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function contactsUnderIndividualClient(clientId) {
  return request({
    url:
      API_BASE_URL +
      `/contact/newApi/${clientId}/contactsUnderIndividualClient`,
    method: "GET",
  });
}

export function getClientsForContact(contactId) {
  return request({
    url: API_BASE_URL + `/contact/newApi/${contactId}/allClients`,
    method: "GET",
  });
}

export function markAsPrimary(buildingId, data) {
  return request({
    url: API_BASE_URL + `/building/newApi/${buildingId}/markPrimaryClient`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function newBuildingGet(buildingId) {
  return request({
    url: API_BASE_URL + `/building/newApi/get/${buildingId}`,
    method: "GET",
  });
}

export const getTicketsForBuilding = (
  buildingId,
  search,
  page = 0,
  size = 10,
  position,
  filters = {},
  params = {}
) => {
  return request({
    url:
      API_BASE_URL + `/ticket/building/${buildingId}?` + parameterize(params),
    method: "POST",
    method: "POST",
    body: JSON.stringify({
      ...filters,
      search,
      position,
      pageable: {
        page,
        size: size,
      },
    }),
  });
};

export function resetPassword(email) {
  return request({
    url: API_BASE_URL + "/user/forgotPassword",
    method: "POST",
    body: JSON.stringify({ value: email }),
  });
}

export function verifyPasswordRecovery(data) {
  return request({
    url: API_BASE_URL + "/user/verifyPasswordRecovery",
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function resetPasswordRecovery(data) {
  return request({
    url: API_BASE_URL + "/user/resetPasswordRecovery",
    method: "POST",
    body: JSON.stringify(data),
  });
}
