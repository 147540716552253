// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ChildFormGen from "./ChildForm.gen";

var make = ChildFormGen.make;

export {
  make ,
  
}
/* make Not a pure module */
