import React, { useEffect, useState } from "react";

import { getPhoneNumbers } from "../../api/Api";
import { renderPhone } from "../../utils/StateUtils";

// import { previewText } from "../../utils/StringUtils";

export default function MarketingCampaign({ stateChangeCB, classes }) {
  const [phoneNumbersData, setPhoneNumbersData] = useState([]);

  const [selected, setSelected] = useState({
    phoneNumber: "",
    phoneNumberId: "",
  });

  const [hoverState, setHoverState] = useState(false);

  useEffect(() => {
    stateChangeCB(selected);
  }, [selected]);

  useEffect(() => {
    getPhoneNumbers(0, 100).then((data) => {
      setPhoneNumbersData(data.content);

      localStorage.setItem(
        "marketingCampaignState",
        JSON.stringify({
          friendlyName: data.content[0].friendlyName,
          phoneNumber: data.content[0].phone,
          phoneNumberId: data.content[0].id,
        })
      );
      setSelected({
        friendlyName: data.content[0].friendlyName,
        phoneNumber: data.content[0].phone,
        phoneNumberId: data.content[0].id,
      });
    });
  }, []);

  return (
    <>
      <div className="relative inline-block text-left w-full">
        <div>
          <button
            className={
              "border border-newGray-200 w-full text-gray-700 font-semibold py-2 px-4 rounded items-center whitespace-nowrap bg-white "
            }
            onClick={() => {
              setHoverState((current) => !current);
            }}
          >
            <div className="flex justify-between items-center">
              <div className="flex flex-row items-center">
                <div>{selected?.friendlyName}</div>
                <div className="px-2">
                  <span
                    className="inline-flex items-center rounded px-2 py-0.5 text-xs font-medium estimateStatus"
                    style={{
                      color: "rgba(18, 37, 157, 1)",
                      backgroundColor: "rgba(229, 231, 244, 1)",
                    }}
                  >
                    {selected.phoneNumber !== undefined
                      ? renderPhone(selected.phoneNumber)
                      : null}
                  </span>
                </div>
              </div>
              <div>
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </button>
        </div>
        <div
          className={
            "overflow-y-auto absolute  right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none " +
            (hoverState ? "" : "hidden")
          }
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabindex="-1"
        >
          <div className="py-1" role="none">
            {phoneNumbersData.map((item, index) => (
              <a
                className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-300"
                role="menuitem"
                tabindex="-1"
                id="menu-item-0"
                onClick={() => {
                  setHoverState(false);
                  setSelected({
                    friendlyName: item.friendlyName,
                    phoneNumber: item.phone,
                    phoneNumberId: item.id,
                  });

                  localStorage.setItem(
                    "marketingCampaignState",
                    JSON.stringify({
                      friendlyName: item.friendlyName,
                      phoneNumber: item.phone,
                      phoneNumberId: item.id,
                    })
                  );
                }}
              >
                <div className="flex flex-row justify-between items-center">
                  <div>{renderPhone(item.phone)}</div>
                  <div className="items-center">
                    <input
                      id="comments"
                      aria-describedby="comments-description"
                      name="comments"
                      type="radio"
                      className="h-4 w-4 rounded-full border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setHoverState(false);
                          setSelected({
                            friendlyName: item.friendlyName,
                            phoneNumber: item.phone,
                            phoneNumberId: item.id,
                          });
                        }
                      }}
                      checked={selected.phoneNumber === item.phone}
                    />
                  </div>
                </div>
                <span
                  className="inline-flex items-center rounded px-2 py-0.5 text-xs font-medium estimateStatus"
                  style={{
                    color: "rgba(18, 37, 157, 1)",
                    backgroundColor: "rgba(229, 231, 244, 1)",
                  }}
                >
                  {item.friendlyName}
                </span>
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
