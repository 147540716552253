import React, { useEffect, useState } from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Autocomplete } from "@mui/material";

import { alertError } from "../../../../actions/AlertActions";
import { deepFind } from "../../../../utils/StateUtils";

const getOptionLabel = (option) => {
  return option.label ? option.label : option;
};

export default function Dropdown({
  variant,
  data,
  name,
  label,
  onChange,
  options,
  defaultValue,
  optional,
  noSelect,
  relatedCallback,
  className,
  editable = true,
}) {
  const nullableValue = deepFind(name, data);
  // Ensuring that the value can be null, such that the state is always controlled
  const value = defaultValue ? nullableValue || defaultValue : nullableValue;

  const searchValue = deepFind(`${name}Search`, data);

  useEffect(() => {
    value === defaultValue && onChange({ name, value });
  }, []);

  return variant?.includes("MULTISELECT") ? (
    <Autocomplete
      fullWidth
      multiple
      disabled={!editable}
      className={className}
      variant={variant?.includes("OUTLINED") ? "outlined" : "standard"}
      selectOnFocus
      freeSolo={variant?.includes("FREESOLO")}
      id="tags-standard"
      options={options}
      groupBy={(option) => option.groupBy || ""}
      getOptionLabel={(option) =>
        options?.find((o) => o.value === option)?.label || ""
      }
      isOptionEqualToValue={(option, value) => {
        // console.log("Getting Option Selected for ", value, " from ", option);
        return option.value === value;
      }}
      name={name}
      value={value}
      onChange={(_event, newValue) => {
        // newValue = ["value1", "value2", {label: "value3label", value: "value3"}]
        // This is a hack; We need to store only option.value, But onChange adds the entire option to the value.
        const updatedValue = newValue.map((v) => {
          const option = options[0].value;
          if (typeof v === typeof option) {
            return v;
          }
          return v.value;
        });
        // console.log("Updating Dropdown from ", value, " to ", updatedValue);
        onChange({ name, value: updatedValue });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant?.includes("OUTLINED") ? "outlined" : "standard"}
          label={label + (optional ? " (Optional)" : "")}
          placeholder={label + (optional ? " (Optional)" : "")}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <div className="flex flex-col w-full">
            <span className="block">{option.label}</span>
            {option.description && <p>{option.description}</p>}
            <div className="flex flex-row justify-between overflow-x-hidden gap-1">
              {option.tags &&
                option.tags.map((tag) => (
                  <span
                    key={tag}
                    className="rounded-full my-2 text-xs bg-gray-200 px-2 py-1"
                  >
                    {tag}
                  </span>
                ))}
            </div>
          </div>
        </li>
      )}
      filterOptions={(options, params) => {
        const filterText = params.inputValue.toLowerCase();
        const filtered = options
          .filter((option) => {
            const tags = option.tags ?? [];
            return (
              option.label.toLowerCase().includes(filterText) ||
              tags.some((tag) => tag.toLowerCase().includes(filterText))
            );
          })
          .map((option) => {
            const tags = option.tags ?? [];
            return {
              ...option,
              tags: tags.sort((a, b) =>
                a.toLowerCase().includes(filterText) ? -1 : 1
              ),
            };
          });

        return filtered;
      }}
    />
  ) : variant?.includes("SELECT_WITH_TAG") ? (
    <Autocomplete
      fullWidth
      disabled={!editable}
      className={className}
      selectOnFocus
      variant={variant?.includes("OUTLINED") ? "outlined" : "standard"}
      freeSolo={variant?.includes("FREESOLO")}
      id="tags-standard"
      options={options}
      groupBy={(option) => option.groupBy || ""}
      getOptionLabel={(option) =>
        options?.find((o) => o.value === option)?.label || ""
      }
      isOptionEqualToValue={(option, value) => {
        // console.log("Getting Option Selected for ", value, " from ", option);
        return option.value === value;
      }}
      name={name}
      value={value}
      onChange={(_event, newValue) => {
        if (newValue) onChange({ name, value: newValue.value });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label + (optional ? " (Optional)" : "")}
          placeholder={label + (optional ? " (Optional)" : "")}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            <div className="flex flex-col w-full">
              <span className="block">{option.label}</span>
              {option.description && <p>{option.description}</p>}
              <div className="flex flex-row justify-between overflow-x-hidden gap-1">
                {option.tags &&
                  option.tags.map((tag) => (
                    <span
                      key={tag}
                      className="rounded-full my-2 text-xs bg-gray-200 px-2 py-1"
                    >
                      {tag}
                    </span>
                  ))}
              </div>
            </div>
          </li>
        );
      }}
      filterOptions={(options, params) => {
        const filterText = params.inputValue.toLowerCase();
        const filtered = options
          .filter((option) => {
            const tags = option.tags ?? [];
            return (
              option.label.toLowerCase().includes(filterText) ||
              tags.some((tag) => tag.toLowerCase().includes(filterText))
            );
          })
          .map((option) => {
            const tags = option.tags ?? [];
            return {
              ...option,
              tags: tags.sort((a, b) =>
                a.toLowerCase().includes(filterText) ? -1 : 1
              ),
            };
          });

        return filtered;
      }}
    />
  ) : variant?.includes("AUTOCOMPLETE") ? (
    <>
      <Autocomplete
        className={className}
        options={options}
        disabled={!editable}
        variant={variant?.includes("OUTLINED") ? "outlined" : "standard"}
        groupBy={(option) => option.groupBy || ""}
        filterOptions={(options, params) => {
          if (variant?.includes("API")) return options;
          const filter = params.inputValue.toLowerCase();
          return options.filter((option) => {
            return getOptionLabel(option).toLowerCase().includes(filter);
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant?.includes("OUTLINED") ? "outlined" : "standard"}
            label={label + (optional ? " (Optional)" : "")}
            onChange={(e) => {
              onChange({ name: `${name}Search`, value: e.target.value });
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.key || props.key}>
              <span>{getOptionLabel(option)}</span>
            </li>
          );
        }}
        noOptionsText={
          searchValue?.length > 5
            ? "No Options found."
            : "Start typing to Search"
        }
        value={value}
        onChange={(e, newValue) => {
          onChange({
            name,
            value: newValue?.value ? newValue.value : newValue,
          });
          console.log("Checking Related Callback for ", name);
          if (relatedCallback) {
            console.log("Invoking Related Callback for ", newValue);
            relatedCallback(newValue, onChange);
          }
        }}
        isOptionEqualToValue={(option, value) => {
          console.log("Checking Equality");
          return option.key === value.key;
        }}
      />
      {/* Debugging Sibling */}
      {/* {value === null ? "null" : JSON.stringify(value)} */}
    </>
  ) : (
    <FormControl
      variant={variant?.includes("OUTLINED") ? "outlined" : "standard"}
      fullWidth
    >
      <InputLabel id="select-outlined-label">
        {label + (optional ? " (Optional)" : "")}
      </InputLabel>
      <Select
        variant={variant?.includes("OUTLINED") ? "outlined" : "standard"}
        id={name}
        value={value || ""}
        name={name}
        disabled={!editable}
        className={className}
        onChange={(e) => {
          const newValue = e.target.value;
          onChange(e.target);
          if (relatedCallback) {
            console.log("Invoking Related Callback for ", newValue);
            const selectedOption = options.find(
              (option) => option.value === newValue
            );
            relatedCallback(selectedOption, onChange);
          }
        }}
      >
        {!noSelect && (
          <MenuItem value="">
            <em>Select</em>
          </MenuItem>
        )}
        {options.map((option, _index) => (
          <MenuItem
            key={option.key ?? option.value}
            disabled={option.disabled ? true : false}
            value={
              option.label.includes(":")
                ? option.label.split(":")[0] + ":" + option.value
                : option.value
            }
          >
            {option.label.includes(":") ? (
              <>
                <span
                  className="inline-flex items-center rounded px-2 py-0.5 text-xs font-medium"
                  style={{
                    color: "#23AF2A",
                    backgroundColor: "#D4EAD7",
                    // opacity: "0.15"
                  }}
                >
                  {option.label.split(":")[0]}
                </span>
                &nbsp;{option.label.split(":")[1]}
              </>
            ) : (
              <span>{option.label}</span>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
