import React, { useEffect } from "react";
import { deepFind } from "../../../../utils/StateUtils";
import { TextField } from "@mui/material";
export default function Text({
  variant,
  data,
  name,
  label,
  onChange,
  optional,
  defaultValue,
}) {
  const value = deepFind(name, data) || defaultValue || "";
  useEffect(() => {
    value === defaultValue && onChange({ name, value });
  }, []);
  return (
    <TextField
      id="outlined-basic"
      label={label + (optional ? " (Optional)" : "")}
      variant={variant === "OUTLINED" ? "outlined" : "standard"}
      placeholder={label}
      name={name}
      type="time"
      value={value}
      onChange={(e) => {
        e.preventDefault();
        onChange(e.target);
      }}
      InputLabelProps={{
        shrink: true,
      }}
      step={30}
      fullWidth
    />
  );
}
