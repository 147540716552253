import React, { useState, useEffect } from "react";

export default function LoadingScreen() {
  const [time, setTime] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((time) => (time + 1) % 4);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-80 h-80 overflow-hidden">
      <div className="w-full h-full flex flex-row items-center justify-center text-center text-2xl font-bold opacity-50">
        <svg
          className="animate-spin -ml-1 mr-3 mt-1 h-8 w-8 text-blue-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-60"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-100"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <div className="text-blue-600 tracking-wide">Loading </div>
      </div>
    </div>
  );
}
