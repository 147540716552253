import { useEffect, useState } from "react";

import { Tab } from "@headlessui/react";
import { Link, navigate } from "raviger";

import { alertError } from "../../actions/AlertActions";
import {
  getBuilding,
  getBuildingClients,
  getTicketsForBuilding,
  newBuildingGet,
  getMyVendor,
} from "../../api/Api";
import BuildingSummary from "../../components/building/BuildingSummary";
import {
  ClientBuildingIcon,
  TicketBagIcon,
} from "../../components/clients/ClientAppIcons";
import NewListTickets from "../../components/clients/NewListTickets";
import NewMessagesScreen from "../../components/clients/NewMessagesScreen";
import RenderAddressComponent from "../../components/clients/RenderAddressComponent";
import { LeftArrowWithTailIcon } from "../../components/common/AppIcons";
import DropDown from "../../components/common/DropDown";
import { SuspenseBoundary } from "../../components/common/core/SuspenseBoundary";
import { buildingTypes } from "../../components/common/form/constants";
import MapComponent from "../../components/scheduler/MapComponent";
import { useTitle } from "../../utils/RouterUtils";
import { properString } from "../../utils/StringUtils";
import ProfileDetails from "./components/ProfileDetails";
import UnitsTab from "./components/Unitstab";

const BuildingHomeTabs = [
  { label: "Summary", value: "summary" },
  { label: "Units", value: "units" },
  { label: "Tickets", value: "tickets" },
  { label: "Messages", value: "messages" },
  { label: "Profile", value: "profile" },
];

const fetchTickets = (buildingId, callback) => {
  getTicketsForBuilding(buildingId, "", 0, 10, "")
    .then(({ content, totalElements }) => {
      callback({
        tickets: content,
        totalTickets: totalElements,
      });
    })
    .catch((_) => {
      alertError(
        "Sorry, we are unable to fetch tickets for this building at this time."
      );
    });
};

const fetchBuilding = (buildingId, callback) => {
  newBuildingGet(buildingId)
    .then((data) => {
      callback({ building: data });
    })
    .catch((_) => {
      alertError(
        "Sorry, we are unable to fetch the building details at this time."
      );
    });
};

const fetchBuildingClients = (buildingId, callback) => {
  getBuildingClients(buildingId)
    .then((data) => {
      callback({ clients: data });
    })
    .catch((_) => {
      alertError(
        "Sorry, we are unable to fetch the building clients at this time."
      );
    });
};

const initialState = {};
const dataFetchReducer = (props, state, setState, action) => {
  const updateBuildingCB = (data) => {
    setState((state) => ({ ...state, ...data }));
  };
  switch (action.type) {
    case "init":
      setState(initialState);
      fetchBuilding(props.buildingId, updateBuildingCB);
      fetchBuildingClients(props.buildingId, updateBuildingCB);
      fetchTickets(props.buildingId, updateBuildingCB);
      break;
    case "building":
      fetchBuilding(props.buildingId, updateBuildingCB);
      break;
    case "clients":
      fetchBuildingClients(props.buildingId, updateBuildingCB);
      break;
    case "tickets":
      fetchTickets(props.buildingId, updateBuildingCB);
      break;
    default:
      throw new Error();
  }
};

const getActiveTabs = (vendorType) => {
  if (vendorType === "CONTRACTOR") {
    return BuildingHomeTabs.filter(
      (item) => item.label !== "Units" && item.label !== "Profile"
    );
  } else return BuildingHomeTabs;
};

export default function BuildingHomePage({
  buildingId,
  tab,
  selectedId,
  unitTab,
  leaseId,
  leaseTab,
}) {
  const [data, setData] = useState(initialState);
  const [vendorType, setVendorType] = useState("");

  useTitle("Building Profile | SquadHub");

  const remoteReduceClient = (action) =>
    dataFetchReducer({ buildingId }, data, setData, action);

  useEffect(() => {
    remoteReduceClient({ type: "init" });
  }, [buildingId]);

  useEffect(() => {
    getMyVendor().then((data) => {
      setVendorType(data.type);
    });
  }, []);

  const buildingType = (
    buildingTypes.find(
      (item) => item.value === data.building?.buildingType
    ) || { label: "Unspecified" }
  ).label;

  const buildingHomeTabs = getActiveTabs(vendorType);

  return (
    <SuspenseBoundary waitFor={data.building && data.building.address}>
      <div className="bg-white h-xscreen w-full p-4 overflow-auto">
        <div className="flex flex-col-reverse md:flex-row justify-between">
          <div className="flex flex-col">
            <div className="flex items-start justify-start">
              <Link
                href="/buildings"
                className="flex flex-row flex-shrink items-center rounded-md px-2 py-2 bg-blue-100 hover:bg-blue-200"
              >
                <div>
                  <LeftArrowWithTailIcon className="h-3 w-3" />
                </div>
                <span className="px-1 text-sm text-newBlue-400">
                  All Buildings
                </span>
              </Link>
            </div>
            <div className="text-black text-md py-4">
              {data.building?.hoa && (
                <span className="text-sm text-gray-500">
                  {data.building?.hoa.clientName}
                </span>
              )}
              <RenderAddressComponent
                address={{
                  ...data.building?.address,
                  propertyShortName: data.building?.propertyShortName,
                }}
              />
              {/* {renderAddress(buildingData.address)} */}
            </div>

            <div className="flex flex-row items-center py-2 gap-4">
              <div className="flex flex-row items-center">
                <div>
                  <ClientBuildingIcon />
                </div>
                <div className="px-2 text-sm">Type: {buildingType}</div>
              </div>

              <div className="flex flex-row items-center">
                <div>
                  <TicketBagIcon />
                </div>
                <div className="px-2 text-sm">
                  No.of Tickets: {data.totalTickets}
                </div>
              </div>
            </div>
          </div>
          <div className="h-48 w-full md:w-1/2 md:pr-16 pb-2 ">
            <MapComponent
              data={[
                {
                  lat: data.building?.address.geocodeLatitude,
                  lng: data.building?.address.geocodeLongitude,
                },
              ]}
              onClick={() => {}}
              center={{
                lat: 34.0522,
                lng: -118.2437,
              }}
            />
          </div>
        </div>
        <SuspenseBoundary
          name="Building Home"
          waitFor={data.building && data.clients}
        >
          <Tab.Group
            selectedIndex={buildingHomeTabs.findIndex(
              (item) => item.value === tab
            )}
            onChange={(index) => {
              navigate(
                `/building/${buildingId}/${buildingHomeTabs[index].value}`
              );
            }}
          >
            <div className="hidden md:flex md:flex-row w-full border-b">
              <div className="p-4">
                <div className=" border-gray-200">
                  <Tab.List className="-mb-px flex space-x-8" aria-label="Tabs">
                    {buildingHomeTabs.map((item) => (
                      <Tab key={item.value} className={"focus:outline-none"}>
                        {({ selected }) => (
                          <span
                            className={
                              "cursor-pointer border-transparent text-base hover:text-gray-700  whitespace-nowrap py-4 px-1 border-b-2 font-medium " +
                              (selected
                                ? "text-newBlue-400 border-b-4 border-newBlue-400"
                                : "text-newGray-800")
                            }
                          >
                            {item.label}
                          </span>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>
              </div>
            </div>

            <div className="flex md:hidden justify-center mt-4 pb-4 border-b z-10">
              <DropDown
                className="text-m p-2"
                aria-label="Tabs"
                options={buildingHomeTabs.map((item) => ({
                  channel: item.label, // Display Name
                  value: item.value, // Value
                }))}
                onChangeCB={(index) => {
                  const selectedOption = buildingHomeTabs.find(
                    (item) => item.label === index
                  );
                  navigate(`/building/${buildingId}/${selectedOption.value}`);
                }}
                defaultValue={buildingHomeTabs
                  .map((item) => ({
                    channel: item.label, // Display Name
                    value: item.value, // Value
                  }))
                  .find((item) => item.value === tab)}
              />
            </div>
            <Tab.Panels>
              <Tab.Panel>
                <BuildingSummary
                  buildingId={buildingId}
                  buildingData={data}
                  refreshCB={remoteReduceClient}
                />
              </Tab.Panel>
              {vendorType === "PMC" && (
                <Tab.Panel>
                  <UnitsTab
                    buildingId={buildingId}
                    buildingName={data.building?.address?.streetAddress1}
                    unitId={selectedId}
                    unitTab={unitTab}
                    leaseIdParam={leaseId}
                    leaseTab={leaseTab}
                  />
                </Tab.Panel>
              )}
              <Tab.Panel>
                <NewListTickets id={buildingId} type={"BUILDING"} />
              </Tab.Panel>
              <Tab.Panel>
                <NewMessagesScreen
                  buildingId={buildingId}
                  type={"BUILDING"}
                  buildings={[data.building]}
                  tickets={data.tickets}
                  selectedContactId={selectedId}
                />
              </Tab.Panel>
              {vendorType === "PMC" && (
                <Tab.Panel>
                  <ProfileDetails buildingId={buildingId} />
                </Tab.Panel>
              )}
            </Tab.Panels>
          </Tab.Group>
        </SuspenseBoundary>
      </div>
    </SuspenseBoundary>
  );
}
