import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { navigate, usePath } from "raviger";
import { useRecoilState } from "recoil";

import { alertError } from "../../actions/AlertActions";
import { getContactMessages, getMessagesForTickets } from "../../api/Api";
import { galleryAtom } from "../../store/GalleryState";
import { makePageable } from "../../utils/PageUtils";
import { useMediaQuery } from "../../utils/useMediaQuery";
import CollapsibleSelector from "../common/core/CollapsibleSelector";
import MessagesSideFilter from "./MessagesSideFilter";
import NewMessagesView from "./NewMessagesView";

const handleFetchMessages = (apiFetch, data, setMessages, next) => {
  apiFetch(data)
    .then((data) => {
      if (next) {
        setMessages((messages) => {
          return { ...data, content: [...messages.content, ...data.content] };
        });
      } else {
        setMessages(data);
      }
    })
    .catch((_) => alertError("Error Fetching Messages"));
};

export default function NewMessagesScreen({
  prefillContact,
  clientId,
  buildingId,
  buildings,
  selectedContactId,
  tickets,
  type = "COMMERCIAL",
  clientClass = "INDIVIDUAL",
}) {
  const [selectedContact, setSelectedContact] = useState();
  const [messages, setMessages] = useState({ content: [] });
  const [selectedTicket, setSelectedTicket] = useState();

  const path = usePath();
  const basePath = path.split("/").slice(0, 3).join("/");

  const [tab, setTab] = useState("CONTACTS");

  const [galleryState, setGalleryState] = useRecoilState(galleryAtom);

  const isMobile = useMediaQuery("(max-width: 640px)");

  useEffect(() => {
    if (selectedContact?.contactId) fetchMessages();
  }, [selectedContact]);

  useEffect(() => {
    if (selectedTicket?.ticketId) fetchMessages();
  }, [selectedTicket]);

  const selectedContactCB = (value) => {
    navigate(`${basePath}/messages/${value.contactId}`);
    setSelectedContact(value);
  };

  const fetchMessages = (next = false) => {
    const currentPage = messages.pageable?.pageNumber ?? -1;

    if (tab === "CONTACTS") {
      selectedContact &&
        selectedContact.contactId &&
        handleFetchMessages(
          getContactMessages,
          makePageable(
            { contactIds: [selectedContact.contactId] },
            currentPage,
            next
          ),
          setMessages,
          next
        );
    } else if (tab === "TICKETS") {
      selectedTicket &&
        selectedTicket.ticketId &&
        handleFetchMessages(
          getMessagesForTickets,
          makePageable(
            { ticketIds: [selectedTicket.ticketId] },
            currentPage,
            next
          ),
          setMessages,
          next
        );
    }
  };

  // Refresh Messages when saving Tickets
  useEffect(() => {
    // When a ticket is saved, the galleryState.selected is set to [].
    if (galleryState.selected.length === 0) {
      fetchMessages();
    }
  }, [galleryState]);

  return (
    <div className="px-2 flex flex-col md:flex-row py-4 gap-5 items-start">
      <DndProvider backend={HTML5Backend}>
        <CollapsibleSelector
          collapseWhen={isMobile && (selectedContact || selectedTicket)}
          onExpand={() => {
            setSelectedContact();
            setSelectedTicket();
            navigate(`${basePath}/messages`);
          }}
        >
          <MessagesSideFilter
            clientId={clientId}
            buildingId={buildingId}
            buildings={buildings}
            selectedContactCB={selectedContactCB}
            selectedTicketCB={(ticket) => {
              setSelectedTicket(ticket);
            }}
            prefillContact={prefillContact}
            selectedContactId={Number(selectedContactId)}
            tab={tab}
            type={type}
            clientClass={clientClass}
            tabCB={(tab) => {
              setTab(tab);
            }}
            onLoadCB={(contacts) => {
              if (tab === "CONTACTS") {
                if (selectedContactId) {
                  const selectedContact = contacts.find(
                    (contact) => contact.contactId === Number(selectedContactId)
                  );
                  if (selectedContact) {
                    selectedContactCB(selectedContact);
                    return;
                  }
                }
                const firstContact = contacts[0];
                if (firstContact && !isMobile) {
                  selectedContactCB(firstContact);
                }
              }
            }}
          />
        </CollapsibleSelector>
        {(selectedContact || selectedTicket || !isMobile) && (
          <NewMessagesView
            tickets={tickets}
            buildings={buildings}
            type={type}
            selectedContact={selectedContact}
            clientId={clientId}
            title={
              tab === "TICKETS"
                ? selectedTicket?.title
                : `${selectedContact?.firstName} ${selectedContact?.lastName}`
            }
            subtext={
              tab === "TICKETS"
                ? selectedTicket?.phone || null
                : selectedContact?.phone || null
            }
            phone={
              tab === "TICKETS" ? selectedTicket?.phone : selectedContact?.phone
            }
            messages={messages}
            contactId={
              tab === "TICKETS"
                ? selectedTicket?.contactId
                : selectedContact?.contactId
            }
            updateMessages={() => fetchMessages()}
            fetchNextPage={(_) => fetchMessages(true)}
          />
        )}
      </DndProvider>
    </div>
  );
}
