import React from "react";
import * as Sentry from "@sentry/react";

const renderFallback = (name) => (
  <div className="w-full h-full flex items-center justify-center">
    <span>The {name} component couldn't not be loaded due to an error</span>
  </div>
);

const ErrorWrap = ({ name, children }) => {
  return (
    <Sentry.ErrorBoundary fallback={renderFallback(name)}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorWrap;
