import { useEffect, useState } from "react";

import { set } from "date-fns";
import { usePath } from "raviger";
import { useRecoilState } from "recoil";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import { createTicketNew, getMyVendor } from "../../api/Api";
import { useExtendedTicket } from "../../hooks/useExtendedTicket";
import { galleryAtom } from "../../store/GalleryState";
import { outlineFields } from "../../utils/FormUtils";
import { conditionalObjectEntry } from "../../utils/ObjectUtills";
import { deepFind, deepUpdate } from "../../utils/StateUtils";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";

const initialState = {
  clientCandidate: null,
};

const onSubmit = (data, callback, subscriberType, isExternal) => {
  const [clientType, id] = data.clientCandidate.key.split(".");
  const { contactId, ...rest } = data;
  const ticketPayload = {
    ...rest,
    ...conditionalObjectEntry(clientType === "client", {
      createdUnderClientId: id,
    }),
    ...conditionalObjectEntry(clientType === "contact", {
      createdUnderContactId: id,
    }),
    ...conditionalObjectEntry(subscriberType === "PMC", {
      type: isExternal ? "EXTERNAL" : "INTERNAL",
    }),
    buildingId: data.buildingCandidate?.buildingId,
    serviceRequests:
      data.serviceItems?.map((item) => ({
        ...item,
        buildingId: data.building,
      })) || [],
  };
  createTicketNew(ticketPayload)
    .then((data) => {
      alertSuccess(`Ticket #${data.value} Created Successfully!`);
      callback();
    })
    .catch((error) => {
      console.log(error);
      alertError("Couldn't create the Ticket");
    });
};

export default function ExtendedTicketForm({
  className = "m-2 px-4 py-6 bg-white rounded-md w-80 max-h-xscreen overflow-y-auto",
  onClose,
}) {
  const [data, setData] = useState(initialState);
  const [galleryState, setGalleryState] = useRecoilState(galleryAtom);
  const [myVendor, setMyVendor] = useState({});
  const [toggle, setToggle] = useState(false);
  const { renderFields, dirtyState } = useExtendedTicket(
    data,
    setData,
    toggle,
    onClose
  );

  const [external, setExternal] = useState(false);
  const path = usePath();

  // Effect to monitor mounting and unmounting of the component
  useEffect(() => {
    console.log("DEBUG: ExtendedTicketForm Mounted");
    return () => {
      console.log("DEBUG: ExtendedTicketForm Unmounted");
    };
  }, []);

  useEffect(() => {
    getMyVendor().then((data) => {
      setMyVendor(data);
    });
  }, []);

  useEffect(() => {
    if (path.match(/\/commercial\/\d+/)) {
      console.log("DEBUG: Path Matched");
      const id = path.split("/")[2];
      const currentValue = deepFind("clientCandidate", data);
      if (!currentValue) {
        console.log(
          "DEBUG: Client Candidate not set; Setting it to ClientId ",
          id
        );
        setData((data) => deepUpdate("clientId", id, data));
      } else {
        console.log("DEBUG: Client Candidate already set");
      }
    } else if (path.match(/\/individual\/\d+/)) {
      console.log("DEBUG: Path Matched");
      const id = path.split("/")[2];
      const currentValue = deepFind("clientCandidate", data);
      if (!currentValue) {
        console.log(
          "DEBUG: Client Candidate not set; Setting it to ContactId ",
          id
        );
        setData((data) => deepUpdate("contactId", id, data));
      } else {
        console.log("DEBUG: Client Candidate already set");
      }
    }
  }, [path]);

  const onChange = (update) => {
    console.log("onChange Received: ", update);
    const { name, value } = update;
    setData((data) => deepUpdate(name, value, data));
  };

  const handleSubmit = () => {
    const [clientType, _id] = data.clientCandidate?.key?.split(".");
    // Validation; If it is a Commercial Client, ie. ClientType === "client"
    // then the primaryContactId must be set
    if (clientType === "client" && !data.primaryContactId) {
      alertError("Please select a Primary Contact");
      return;
    }

    if (!data.buildingCandidate) {
      alertError("Please select a Building");
      return;
    }

    const callback = () => {
      setGalleryState((state) => ({
        ...state,
        selected: [],
      }));
      onClose();
      setData(initialState);
    };
    onSubmit(
      {
        ...data,
        communicationIds: galleryState.selected.map(
          (item) => item.communicationId
        ),
      },
      callback,
      myVendor.type,
      toggle
    );
  };

  return (
    <div className={className}>
      <h1 className="pb-2 pl-1">Create Ticket</h1>
      {myVendor.type === "PMC" && (
        <div className="flex flex-col mb-4">
          <div className="text-xs text-gray-700 font-normal mb-2">
            Note: Please mark the Ticket as External if it will be assigned to a
            Contractor
          </div>
          <div className="flex flex-row items-center ">
            <button
              className={
                "md:w-12 md:h-6 w-8 h-4 flex items-center  border border-gray-400 rounded-full p-1 cursor-pointer focus:outline-none  " +
                (toggle ? "bg-newBlue-700" : "bg-white")
              }
              onClick={() => {
                setToggle(!toggle);
              }}
            >
              <div
                className={
                  "bg-newBlue-400 md:w-5 md:h-5 h-5 w-5 rounded-full shadow-md transform duration-300 ease-in-out" +
                  (toggle ? " transform translate-x-5" : null)
                }
              ></div>
            </button>
            <div className="ml-2 text-base fond-normal text-gray-700">
              External
            </div>
          </div>
        </div>
      )}
      <MaterialForm
        className="flex flex-col gap-2"
        data={data}
        renderArray={outlineFields(renderFields)}
        onChange={onChange}
        onSubmit={handleSubmit}
      />
      {!dirtyState && (
        <div className="flex justify-between mt-6 mb-2">
          <span
            className="text-gray-700 hover:text-gray-900 antialiased font-bold py-1 px-4 rounded cursor-pointer"
            onClick={() => setData(initialState)}
          >
            Clear
          </span>
          <button
            className="bg-indigo-700 hover:bg-indigo-900 text-white font-bold py-1 px-4 rounded"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
}
