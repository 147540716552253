import {
  UNSAFE__removeContact,
  UNSAFE_getMelissaAddress,
  updateBuildingContact,
} from "./Api";

interface RemoveContactRequest {
  contactId: number;
  unitNumber?: number;
}

export function removeContact(buildingId: number, data: RemoveContactRequest) {
  return UNSAFE__removeContact(buildingId, data);
}

interface AutofillReq {
  address: string;
  state: string;
}
export function getAddressAutofill(address: string, state: string) {
  return UNSAFE_getMelissaAddress({ address, state } as AutofillReq);
}

export function updateResidentPosition(
  buildingContactId: number,
  position: string
) {
  const data = JSON.stringify([
    {
      op: "replace",
      path: "/position",
      value: position,
    },
  ]);
  return updateBuildingContact(buildingContactId, data);
}

export function updateResidentUnit(
  buildingContactId: number,
  unitNumber: string
) {
  const data = JSON.stringify([
    {
      op: "replace",
      path: "/unitNumber",
      value: unitNumber,
    },
  ]);
  return updateBuildingContact(buildingContactId, data);
}
