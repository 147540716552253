import { useEffect, useState } from "react";



import { alertError, alertSuccess } from "../../actions/AlertActions";
import { getVendorById, updateVendor } from "../../api/Api";
import MaterialForm from "../../components/common/form/MaterialForm";
import { deepUpdate } from "../../utils/StateUtils";
import { lowerCase } from "../../utils/StringUtils";


type EditVendorProps = {
  vendorId: number;
  setEdit: React.Dispatch<
    React.SetStateAction<{
      display: boolean;
      vendorId: number;
    }>
  >;
  onSuccess: () => void;
};

type Address = {
  addressId: number;
  city: string;
  melissaBaseMak: string;
  state: string;
  streetAddress1: string;
  streetAddress2: string;
  territory: string;
  zip: number;
};

type VendorDetailsData = {
  address: Address;
  contractorLicenseExpiry: string;
  contractorLicenseNumber: string;
  iconUrl: string;
  id: number;
  logoUrl: string;
  name: string;
  primaryContactNumber: string;
  supportEmail: string;
  territory: string;
  type: string;
  licenseDetails: string;
  invoiceTerms: string;
  estimateTerms: string;
  invoiceTermsAndConditions: string;
  estimateTermsAndConditions: string;
};

export default function EditVendor({
  vendorId,
  setEdit,
  onSuccess,
}: EditVendorProps) {
  const [vendorDetails, setVendorDetails] = useState<VendorDetailsData>({
    name: "",
    contractorLicenseExpiry: "",
    contractorLicenseNumber: "",
    territory: "",
    iconUrl: "",
    id: 0,
    logoUrl: "",
    primaryContactNumber: "",
    supportEmail: "",
    address: {
      addressId: 0,
      city: "",
      melissaBaseMak: "",
      state: "",
      streetAddress1: "",
      streetAddress2: "",
      territory: "",
      zip: 0,
    },
    type: "",
    licenseDetails: "",
    invoiceTerms: "",
    estimateTerms: "",
    invoiceTermsAndConditions: "",
    estimateTermsAndConditions: "",
  });
  useEffect(() => {
    getVendorById(vendorId).then((data) => {
      setVendorDetails(data as VendorDetailsData);
    });
  }, [vendorId]);

  const onChange = (update: {
    name: string;
    subOptions?: string[];
    value?: string;
  }) => {
    let { name, value } = update;

    if (name === "supportEmail") {
      value = lowerCase(value);
    }

    setVendorDetails((data) => deepUpdate(name, value, data));
  };

  const imageDetails = () => {
    return [
      { name: "iconUrl", inputType: "IMAGE", label: "Icon" },
      { name: "logoUrl", inputType: "IMAGE", label: "Logo" },
    ];
  };

  const formContractorDetails = () => {
    return [
      { name: "name" },
      { name: "primaryContactNumber", inputType: "PHONE" },
      { name: "supportEmail", inputType: "EMAIL" },
      { name: "contractorLicenseNumber" },
      { name: "contractorLicenseExpiry", inputType: "DATE" },
      {
        name: "estimateTerms",
        label: "Estimate Terms",
        inputType: "SELECT",
        options: [
          { label: "7 Days", value: "SEVEN" },
          { label: "10 Days", value: "TEN" },
          { label: "15 Days", value: "FIFTEEN" },
          { label: "30 Days", value: "THIRTY" },
          { label: "60 Days", value: "SIXTY" },
          { label: "90 Days", value: "NINETY" },
        ],
      },
      {
        name: "invoiceTerms",
        label: "Invoice Payments Terms",
        inputType: "SELECT",
        options: [
          { label: "7 Days", value: "SEVEN" },
          { label: "10 Days", value: "TEN" },
          { label: "15 Days", value: "FIFTEEN" },
          { label: "30 Days", value: "THIRTY" },
          { label: "60 Days", value: "SIXTY" },
          { label: "90 Days", value: "NINETY" },
        ],
      },
    ];
  };

  const commonDetails = () => {
    return [
      {
        inputType: "RAW",
        children: (
          <div className="">
            <span className=" text-gray-700 text-sm font-normal">
              Invoice Terms And Conditions
            </span>
          </div>
        ),
      },
      {
        name: "invoiceTermsAndConditions",
        label: "Invoice Terms And Conditions",
        inputType: "AREA",
        variant: "OUTLINED",
      },
      {
        inputType: "RAW",
        children: (
          <div className="">
            <span className=" text-gray-700 text-sm font-normal">
              Estimate Terms And Conditions
            </span>
          </div>
        ),
      },
      {
        name: "estimateTermsAndConditions",
        label: "Estimate Terms And Conditions",
        inputType: "AREA",
        variant: "OUTLINED",
      },
      {
        name: "address",
        inputType: "ADDRESS",
        variant: "STANDARD",
      },
    ];
  };

  const formPMCDetails = () => {
    return [
      { name: "name" },
      { name: "primaryContactNumber", inputType: "PHONE" },
      { name: "supportEmail", inputType: "EMAIL" },
      { name: "licenseDetails" },
      {
        name: "estimateTerms",
        label: "Estimate Terms",
        inputType: "SELECT",
        options: [
          { label: "7 Days", value: "SEVEN" },
          { label: "10 Days", value: "TEN" },
          { label: "15 Days", value: "FIFTEEN" },
          { label: "30 Days", value: "THIRTY" },
          { label: "60 Days", value: "SIXTY" },
          { label: "90 Days", value: "NINETY" },
        ],
      },
      {
        name: "invoiceTerms",
        label: "Invoice Payment Terms",
        inputType: "SELECT",
        options: [
          { label: "On Receipt", value: "ZERO" },
          { label: "7 Days", value: "SEVEN" },
          { label: "10 Days", value: "TEN" },
          { label: "15 Days", value: "FIFTEEN" },
          { label: "30 Days", value: "THIRTY" },
          { label: "60 Days", value: "SIXTY" },
          { label: "90 Days", value: "NINETY" },
        ],
      },
    ];
  };

  return (
    <div className="">
      <MaterialForm
        data={vendorDetails}
        className="flex flex-row w-full justify-evenly"
        renderArray={imageDetails()}
        onSubmit={() => {}}
        onChange={onChange}
      />
      <div className="flex flex-row w-full">
        <MaterialForm
          data={vendorDetails}
          renderArray={
            vendorDetails.type === "PMC"
              ? formPMCDetails()
              : formContractorDetails()
          }
          onSubmit={() => {}}
          onChange={onChange}
        />
        <MaterialForm
          data={vendorDetails}
          renderArray={commonDetails()}
          onSubmit={() => {}}
          onChange={onChange}
        />
      </div>
      <div className="flex-auto flex flex-row-reverse mt-4">
        <button
          className="text-base  ml-2  flex justify-center px-4 py-2 rounded font-medium  
      bg-newBlue-400  w-80
      text-white border-newBlue-400
      border"
          onClick={() => {
            updateVendor(vendorId, [
              {
                op: "replace",
                path: "/name",
                value: vendorDetails.name,
              },
              {
                op: "replace",
                path: "/address",
                value: vendorDetails.address,
              },
              {
                op: "replace",
                path: "/contractorLicenseExpiry",
                value: vendorDetails.contractorLicenseExpiry,
              },
              {
                op: "replace",
                path: "/contractorLicenseNumber",
                value: vendorDetails.contractorLicenseNumber,
              },
              {
                op: "replace",
                path: "/iconUrl",
                value: vendorDetails.iconUrl,
              },
              {
                op: "replace",
                path: "/logoUrl",
                value: vendorDetails.logoUrl,
              },
              {
                op: "replace",
                path: "/primaryContactNumber",
                value: vendorDetails.primaryContactNumber,
              },
              {
                op: "replace",
                path: "/supportEmail",
                value: vendorDetails.supportEmail,
              },
              {
                op: "replace",
                path: "/licenseDetails",
                value: vendorDetails.licenseDetails,
              },
              {
                op: "replace",
                path: "/invoiceTerms",
                value: vendorDetails.invoiceTerms,
              },
              {
                op: "replace",
                path: "/estimateTerms",
                value: vendorDetails.estimateTerms,
              },
              {
                op: "replace",
                path: "/invoiceTermsAndConditions",
                value: vendorDetails.invoiceTermsAndConditions,
              },
              {
                op: "replace",
                path: "/estimateTermsAndConditions",
                value: vendorDetails.estimateTermsAndConditions,
              },
            ])
              .then(() => {
                alertSuccess("Vendor updated successfully");
                setEdit({ display: false, vendorId: 0 });
                onSuccess();
              })
              .catch(() => {
                alertError("Error while updating vendor");
              });
          }}
        >
          Update Vendor
        </button>
        <button
          className="text-base flex justify-center px-4 py-2 rounded font-medium cursor-pointer 
      bg-white
      text-newBlue-400 border-newBlue-400
      border duration-200 ease-in-out 
      transition"
          onClick={() => setEdit({ display: false, vendorId: 0 })}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}