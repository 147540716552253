import { useEffect, useState } from "react";

import { alertError } from "../../../actions/AlertActions";
import { getBuildingUnits } from "../../../api/Api";
import { getAddressAutofill } from "../../../api/TypedAPI";
import { getDateStep30 } from "../../../utils/DateUtils";
import { conditionalArrayEntry } from "../../../utils/ObjectUtills";
import { properStrings, renderAddress } from "../../../utils/StringUtils";
import { skillFilter } from "../../associates/SkillFilter.bs";
import { getSubCategories } from "../../service/CreateSR";

export const JobTypesDict = {
  HANDYMAN: "Handyman",
  PLUMBER: "Plumber",
  ELECTRICIAN: "Electrician",
  INSPECTION: "Inspection",
  SITE_VISIT: "Site Visit",
  SPECIALIST: "Specialist",
};

export const clientSubCategories = [
  { label: "Property Management", value: "PROPERTY_MANAGEMENT" },
  { label: "HOA", value: "HOA" },
  { label: "Office", value: "OFFICE" },
  { label: "Realtor", value: "REALTOR" },
  { label: "Landlord", value: "LANDLORD" },
];

export const jobTypes = [
  { label: "Handyman", value: "HANDYMAN" },
  { label: "Plumber", value: "PLUMBER" },
  { label: "Electrician", value: "ELECTRICIAN" },
  { label: "Inspection", value: "INSPECTION" },
  { label: "Site Visit", value: "SITE_VISIT" },
  { label: "Specialist", value: "SPECIALIST" },
];

export const buildingTypes = [
  { label: "Home Owner Association", value: "HOA" },
  { label: "Single Family Home", value: "SFH" },
  { label: "Apartment", value: "APARTMENT" },
  { label: "Office", value: "OFFICE" },
];

export const materialMethods = [
  { label: "In Stock", value: "IN_STOCK" },
  { label: "Pick Up", value: "PICK_UP" },
  { label: "Job Site", value: "JOB_SITE" },
];

export const materialMethodMapping = {
  IN_STOCK: "In Stock",
  PICK_UP: "Pick Up",
  JOB_SITE: "Job Site",
};

export const associateTypes = [
  { label: "Squad Associate", value: "SQUAD_ASSOCIATE", sortBy: 1 },
  { label: "Supervisor", value: "SUPERVISOR", sortBy: 3 },
  { label: "Third Party Vendor", value: "THIRD_PARTY_VENDOR", sortBy: 4 },
];

export const extendedSkills = [
  { label: "Asphalt", value: "ASPHALT" },
  { label: "Blacksmith", value: "BLACKSMITH" },
  { label: "Cabinet", value: "CABINET" },
  { label: "Concrete walkway", value: "CONCRETE_WALKWAY" },
  { label: "Countertop", value: "COUNTERTOP" },
  { label: "Deck", value: "DECK" },
  { label: "Door supplier", value: "DOOR_SUPPLIER" },
  { label: "Electrician", value: "ELECTRICIAN" },
  { label: "Handyman", value: "HANDYMAN" },
  { label: "Hot mop", value: "HOT_MOP" },
  { label: "Locksmith", value: "LOCKSMITH" },
  { label: "Mold remediation", value: "MOLD_REMEDIATION" },
  { label: "Plumber", value: "PLUMBER" },
  { label: "Plumbing", value: "PLUMBING" },
  { label: "Reglazing", value: "REGLAZING" },
  { label: "Roofing", value: "ROOFING" },
  { label: "Termites", value: "TERMITES" },
  { label: "Tiling", value: "TILING" },
  { label: "Waterproof", value: "WATERPROOF" },
  { label: "Window installer", value: "WINDOW_INSTALLER" },
  { label: "Window supplier", value: "WINDOW_SUPPLIER" },
];

export const handymanSubCategory = [
  {
    label: "Appliance Installation",
    value: "APPLIANCE_INSTALLATION",
  },
  {
    label: "Blacksmith",
    value: "BLACKSMITH",
  },
  {
    label: "Carpentry",
    value: "CARPENTRY",
  },
  {
    label: "Carpet",
    value: "CARPET",
  },
  {
    label: "Cleaning",
    value: "CLEANING",
  },
  {
    label: "Concrete",
    value: "CONCRETE",
  },
  {
    label: "Deck",
    value: "DECK",
  },
  {
    label: "Drywall",
    value: "DRYWALL",
  },
  {
    label: "Electrician",
    value: "ELECTRIC",
  },
  {
    label: "Flooring",
    value: "FLOORING",
  },
  {
    label: "Handyman",
    value: "HANDYMAN",
  },
  {
    label: "HVAC",
    value: "HVAC",
  },
  {
    label: "Landscaping",
    value: "LANDSCAPING",
  },
  {
    label: "Locksmith",
    value: "LOCKSMITH",
  },
  {
    label: "Paint",
    value: "PAINT",
  },
  {
    label: "Plumbing",
    value: "PLUMBING",
  },
  {
    label: "Remediation ",
    value: "REMEDIATION ",
  },
  {
    label: "Remodeling",
    value: "REMODELING",
  },
  {
    label: "Roofing",
    value: "ROOFING",
  },
  {
    label: "StuccoPlastering",
    value: "STUCCOPLASTERING",
  },
  {
    label: "Tiling",
    value: "TILING",
  },
  {
    label: "Waterproofing",
    value: "WATERPROOFING",
  },
  {
    label: "Window Installer",
    value: "WINDOW INSTALLER",
  },
];

export const plumberSubCategory = [{ label: "Plumbing", value: "PLUMBING" }];
export const electricianSubCategory = [
  { label: "Electrician", value: "ELECTRICIAN" },
];

export const specialistSubCategory = [
  {
    label: "Appliance",
    value: "APPLIANCE",
  },
  {
    label: "Blacksmith",
    value: "BLACKSMITH",
  },
  {
    label: "Carpentry",
    value: "CARPENTRY",
  },
  {
    label: "Carpet",
    value: "CARPET",
  },
  {
    label: "Cleaning",
    value: "CLEANING",
  },
  {
    label: "Concrete",
    value: "CONCRETE",
  },
  {
    label: "Deck",
    value: "DECK",
  },
  {
    label: "Drywall",
    value: "DRYWALL",
  },
  {
    label: "Electrician",
    value: "ELECTRIC",
  },
  {
    label: "Flooring",
    value: "FLOORING",
  },
  {
    label: "Handyman",
    value: "HANDYMAN",
  },
  {
    label: "Hotmop",
    value: "HOTMOP",
  },
  {
    label: "HVAC",
    value: "HVAC",
  },
  {
    label: "Landscaping",
    value: "LANDSCAPING",
  },
  {
    label: "Locksmith",
    value: "LOCKSMITH",
  },
  {
    label: "Paint",
    value: "PAINT",
  },
  {
    label: "Plumbing",
    value: "PLUMBING",
  },
  {
    label: "Remediation ",
    value: "REMEDIATION ",
  },
  {
    label: "Remodeling",
    value: "REMODELING",
  },
  {
    label: "Glazing",
    value: "GLAZING",
  },
  {
    label: "Roofing",
    value: "ROOFING",
  },
  {
    label: "Termites",
    value: "TERMITES",
  },
  {
    label: "Stucco Plastering",
    value: "STUCCOPLASTERING",
  },
  {
    label: "Tiling",
    value: "TILING",
  },
  {
    label: "Waterproofing",
    value: "WATERPROOFING",
  },
  {
    label: "Window Installer",
    value: "WINDOW INSTALLER",
  },
];

export const supplierSubCategory = [
  {
    label: "Appliance",
    value: "APPLIANCE",
  },
  {
    label: "Cabinets",
    value: "CABINETS",
  },
  {
    label: "Carpet",
    value: "CARPET",
  },
  {
    label: "Cleaning",
    value: "CLEANING",
  },
  {
    label: "Countertops",
    value: "COUNTERTOPS",
  },
  {
    label: "Doors",
    value: "DOORS",
  },
  {
    label: "Electrician",
    value: "ELECTRIC",
  },
  {
    label: "Flooring",
    value: "FLOORING",
  },
  {
    label: "HVAC",
    value: "HVAC",
  },
  {
    label: "Paint",
    value: "PAINT",
  },
  {
    label: "Plumbing ",
    value: "PLUMBING ",
  },
  {
    label: "Remodeling",
    value: "REMODELING",
  },
  {
    label: "Tiles",
    value: "TILES",
  },
  {
    label: "Windows",
    value: "WINDOWS",
  },
  {
    label: "General Hardware",
    value: "GENERAL_HARDWARE",
  },
];

export const consolidatedSubCategory = [
  ...specialistSubCategory,
  ...supplierSubCategory,
  ...handymanSubCategory,
  ...electricianSubCategory,
  ...plumberSubCategory,
];

const confirmedSwitch = {
  name: "confirmed",
  inputType: "SELECT",
  defaultValue: "false",
  options: [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ],
};

export const formTicketOrSR = (data, buildings, contacts) => [
  confirmedSwitch,
  ...(buildings
    ? [
        {
          name: "building",
          inputType: "SELECT",
          options: buildings.map((building) => {
            return {
              label: renderAddress(building.address),
              value: building.buildingId,
            };
          }),
        },
      ]
    : []),
  ...((data?.confirmed || "false") === "true"
    ? formServiceRequest(data, contacts)
    : formSRTicket),
];
export const formSR = (data, buildings, contacts, skills, skillData) => {
  return [
    // ...(buildings
    //   ? [
    //       {
    //         name: "buildingId",
    //         inputType: "SELECT",
    //         options: buildings.map((building) => {
    //           return {
    //             label: renderAddress(building.address),
    //             value: building.buildingId,
    //           };
    //         }),
    //       },
    //     ]
    //   : []),
    ...formServiceRequest(data, contacts, skills, skillData),
  ];
};

export const formServiceRequest = (data, contacts, skills, skillData) => [
  {
    name: "jobType",
    inputType: "SELECT",
    options: skills || jobTypes,
  },
  ...((data.jobType !== "INSPECTION" && data.jobType !== "SITE_VISIT") ?? ""
    ? [
        {
          name: "subCategory",
          inputType: "SELECT",
          // variant: "MULTISELECT",
          defaultValue: data.jobType === "SITE_VISIT" ? null : [],
          options: getSubCategories(skillData, data.jobType) ?? [],
        },
      ]
    : []),
  {
    name: "preferredDateTime",
    inputType: "DATETIME",
    variant: "STEP30",
    defaultValue: getDateStep30(new Date()),
  },
  ...(contacts
    ? [
        {
          name: "contactId",
          inputType: "SELECT",
          defaultValue: data.alternateContact
            ? data.alternateContact.contactId || ""
            : "",
          options: contacts.map((contact) => {
            return {
              label: properStrings([
                contact.firstName || "",
                contact.lastName || "",
              ]),
              value: contact.contactId
                ? contact.contactId.toString()
                : "" + "-" + contact.unitNumber
                ? contact.unitNumber.toString()
                : "",
            };
          }),
        },
      ]
    : []),
  { name: "duration", inputType: "NUMBER", variant: "DECIMAL5" },
  // { name: "title" },
  { name: "description", inputType: "AREA", variant: "OUTLINED" },
  // { name: "notes", inputType: "AREA", variant: "OUTLINED" },

  { name: "unitNumber" },
  ...conditionalArrayEntry(data.serviceRequestId !== undefined, {
    name: "bookingStatus",
    inputType: "SELECT",
    defaultValue: "CREATED",
    options: ticketStatus,
  }),
];

export const ticketSymbolCount = {
  UNDER_1K: 1,
  BETWEEN_1K_5K: 2,
  BETWEEN_5K_10K: 3,
  BETWEEN_10K_25K: 4,
  ABOVE_25K: 5,
};

// export const ticketValues = [
//   { label: "Under $1K", value: "UNDER_1K", avg: 500, symbol: 1 },
//   { label: "$1-$5K", value: "BETWEEN_1K_5K", avg: 3000, symbol: 2 },
//   { label: "$5-$10K", value: "BETWEEN_5K_10K", avg: 7500, symbol: 3 },
//   { label: "$10-$25K", value: "BETWEEN_10K_25K", avg: 17500, symbol: 4 },
//   { label: "Above $25K", value: "ABOVE_25K", avg: 25000, symbol: 5 },
// ];

// Deprecated, To be Removed
export const formSRTicket = [
  {
    name: "pipelineId",
    inputType: "HIDDEN",
    defaultValue: "1",
  },
  // { name: "title" },
  { name: "description", inputType: "AREA", variant: "OUTLINED" },
];

export const linkManagerForm = (
  managerOptions,
  hiredByOptions,
  unitOptions
) => {
  return [
    {
      name: "manager",
      inputType: "SELECT",
      options: managerOptions,
    },
    ...conditionalArrayEntry(unitOptions.length > 0, {
      name: "unitNumber",
      inputType: "SELECT",
      options: unitOptions,
    }),

    {
      name: "hiredBy",
      inputType: "SELECT",
      options: hiredByOptions,
    },
  ];
};

export const addNewManagerForm = (hiredByOptions = [], unitOptions = []) => {
  return [
    { name: "firstName" },
    { name: "lastName" },
    ...conditionalArrayEntry(hiredByOptions.length > 0, {
      name: "hiredBy",
      inputType: "SELECT",
      options: hiredByOptions,
    }),
    ...conditionalArrayEntry(unitOptions.length > 0, {
      name: "unitNumber",
      inputType: "SELECT",
      options: unitOptions,
    }),

    { name: "phone", inputType: "PHONE" },
    { name: "email", inputType: "EMAIL" },
  ];
};

export const formTicket = (data) => {
  return [
    ...(data?.ticketId
      ? []
      : [
          {
            name: "pipelineId",
            inputType: "SELECT",
            options: [],
          },
        ]),
    { name: "title" },
    ...(data?.clientId
      ? [
          {
            name: "buildingId",
            inputType: "SELECT",
            options: [],
          },
        ]
      : []),
  ];
};

export const ticketStatus = [
  { label: "Created", value: "CREATED" },
  { label: "Materials Required", value: "MATERIALS_REQUIRED" },
  { label: "Scheduled", value: "SCHEDULED" },
  { label: "Revist Required", value: "REVISIT_REQUIRED" },
  { label: "Remedial", value: "REMEDIAL" },
  { label: "Cancelled", value: "CANCELLED" },
  { label: "Completed", value: "COMPLETED" },
];

export const ticketStatusObj = {
  CREATED: "CREATED",
  MATERIALS_REQUIRED: "MATERIALS REQUIRED",
  SCHEDULED: "SCHEDULED",
  REVISIT_REQUIRED: "REVIST REQUIRED",
  CANCELLED: "CANCELLED",
  COMPLETED: "COMPLETED",
};

export const formUpdateSRTicket = (data, skills, skillData) => {
  return [
    {
      name: "pipelineId",
      inputType: "HIDDEN",
      defaultValue: "1",
    },
    {
      name: "jobType",
      inputType: "SELECT",
      options: skills || jobTypes,
    },
    ...((data.jobType !== "INSPECTION" && data.jobType !== "SITE_VISIT") ?? ""
      ? [
          {
            name: "subCategory",
            inputType: "SELECT",
            // variant: "MULTISELECT",
            defaultValue: [],
            options: getSubCategories(skillData, data.jobType),
          },
        ]
      : []),
    { name: "preferredDateTime", inputType: "DATETIME", variant: "STEP30" },
    { name: "duration", inputType: "NUMBER" },
    // { name: "title" },
    { name: "description", inputType: "AREA", variant: "OUTLINED" },
    // { name: "notes", inputType: "AREA", variant: "OUTLINED" },
    { name: "unitNumber" },
    {
      name: "bookingStatus",
      inputType: "SELECT",
      defaultValue: "CREATED",
      options: ticketStatus,
    },
  ];
};

export const territories = [
  { label: "Los Angeles", value: "LOS_ANGELES" },
  { label: "San Diego", value: "SAN_DIEGO" },
  { label: "San Francisco", value: "SAN_FRANCISCO" },
  { label: "Orange County", value: "ORANGE_COUNTY" },
];

export const territorySelect = {
  name: "territory",
  inputType: "SELECT",
  options: territories,
};

export const formAssociateContact = (data) => [
  { name: "profilePicture", inputType: "IMAGE" },
  { name: "firstName" },
  { name: "lastName" },
  { name: "phone", inputType: "PHONE" },
  { name: "email", inputType: "EMAIL" },
];

// data: Used to populate the unitOptions
// force: Used to force the unitOptions to be populated, regardless of position
export const useContactFields = (data, force = false) => {
  const [unitOptions, setUnitOptions] = useState([]);
  useEffect(() => {
    data.streetAddress1
      ? getAddressAutofill(data.streetAddress1, "CA")
          .then((addresses) => {
            // Find the Entries where Address.Address1 = data.streetAddress1
            // Check if any of the entries have a non-empty string as Address.SubBuilding
            // If so, set the unitOptions to the array
            // If not, set the unitOptions to an empty array

            const unitOptionsString =
              addresses
                .filter(
                  (entry) => entry.Address.Address1 === data.streetAddress1
                )
                .find((entry) => entry.Address.SubBuilding.length > 0)?.Address
                .SubBuilding || "";

            if (unitOptionsString.length > 0) {
              setUnitOptions(unitOptionsString.split(","));
            } else {
              setUnitOptions([]);
            }
          })
          .catch((_err) => {
            alertError(
              "Sorry, we're having trouble finding your address. Please try again later."
            );
          })
      : // PS: Populating unitOptions for a buildingId is not being used anywhere yet
        data.buildingId &&
        getBuildingUnits(data.buildingId)
          .then((units) => {
            setUnitOptions(units.value);
          })
          .catch((_err) => {
            alertError(
              "Sorry, we're having trouble finding the units for this building.",
              "Please ensure that the building is Melissa Verified"
            );
          });
  }, [data.streetAddress1, data.buildingId]);
  return [
    ...formAssociateContact(data),
    ...conditionalArrayEntry(data.buildingId && data.position !== "MANAGER", {
      name: "position",
      optional: true,
      inputType: "SELECT",
      defaultValue: "OWNER",
      options: [
        { label: "Owner", value: "OWNER" },
        { label: "Tenant", value: "TENANT" },
      ],
    }),
    ...conditionalArrayEntry(
      data?.position === "TENANT" || data?.position === "OWNER" || force,
      {
        name: "unitNumber",
        inputType: unitOptions.length > 0 ? "SELECT" : "TEXT",
        options: unitOptions.map((unit) => ({ label: unit, value: unit })),
      }
    ),
  ];
};

export const formContact = (data, buildingId = null) => [
  ...formAssociateContact(data),
  ...conditionalArrayEntry(buildingId, {
    name: "position",
    optional: true,
    inputType: "SELECT",
    options: [
      { label: "Owner", value: "OWNER" },
      { label: "Tenant", value: "TENANT" },
    ],
  }),
  ...conditionalArrayEntry(
    data?.position === "TENANT" || data?.position === "OWNER",
    { name: "unitNumber" }
  ),
];

export const scheduleForm = (associates, jobType) => [
  {
    name: "associate",
    inputType: "SELECT",
    options: associates
      ?.filter((associate) => skillFilter(associate.skill, jobType))
      ?.map((associate) => {
        return {
          label: `${associate.firstName} ${associate.lastName}`,
          value: associate.associateId,
        };
      }),
  },
  {
    name: "startTime",
    inputType: "DATETIME",
    variant: "STEP30",
  },
  {
    name: "endTime",
    inputType: "DATETIME",
    variant: "STEP30",
  },
];
