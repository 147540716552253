import { useEffect, useReducer, useState } from "react";

import { navigate } from "raviger";
import { useRecoilState } from "recoil";

import { alertError, alertInfo } from "../actions/AlertActions";
// import { alertError } from "../actions/AlertActions";
import {
  getBuildingClients,
  getBuildingManagers,
  getBuildingResidentsOwners,
  getBuildingUnits,
  getBuildings,
  getBuildingsUnderCommercialClient,
  getBuildingsUnderIndividualClient,
  getCommericalClients,
  getContactDetails,
  getIndividualClients,
  newClientGet,
} from "../api/Api";
import { PreviewTiles } from "../components/common/PreviewTiles";
import { galleryAtom } from "../store/GalleryState";
import { conditionalArrayEntry } from "../utils/ObjectUtills";
import { deepUpdate } from "../utils/StateUtils";
import { renderAddress } from "../utils/StringUtils";
import { useMediaQuery } from "../utils/useMediaQuery";
import { useFormServiceRequest } from "./useFormServiceRequest";

const redirectToClientMessages = (clientCandidate, primaryContactId) => {
  const [clientType, clientId] = clientCandidate.split(".");
  const urlClientClass = clientType === "contact" ? "individual" : "commercial";
  const messageContactId =
    clientType === "contact" ? clientId : primaryContactId;
  navigate(`/${urlClientClass}/${clientId}/messages/${messageContactId}`);
};

const triggerGallerySelection = (data, callback) => {
  console.log("Checking ", data);
  if (!data.clientCandidate || !data.clientCandidate.key) {
    alertError("Please select a client first");
    return;
  }
  if (data.clientCandidate.groupBy !== "INDIVIDUAL") {
    if (!data.primaryContactId) {
      alertError("Please select a Contact for the Client");
      return;
    }
  }
  redirectToClientMessages(data.clientCandidate.key, data.primaryContactId);
  callback();
};

const getGalleryMedia = (gallery) =>
  gallery.selected.reduce((acc, { media }) => {
    if (media) {
      return [...acc, ...media];
    } else {
      return acc;
    }
  }, []);

// formState manages collapsed state & childForm focus
const formStateReducer = (state, action) => {
  switch (action.type) {
    case "setCollapsed":
      return { ...state, collapsed: action.payload };
    case "toggleCollapsed":
      return { ...state, collapsed: !state.collapsed };
    case "setFocus":
      return { ...state, focus: action.payload };
    default:
      return state;
  }
};

const initialFormState = {
  collapsed: false,
  focus: null,
};

// TODO: Remove the need to pass setData here
export function useExtendedTicket(data = {}, setData, isExternal, onClose) {
  const [clients, setClients] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [units, setUnits] = useState([]);
  const [gallery, setGallery] = useRecoilState(galleryAtom);
  const [state, dispatch] = useReducer(formStateReducer, initialFormState);

  const isCommercialClient =
    data.clientCandidate && data.clientCandidate.groupBy !== "INDIVIDUAL";

  const galleryMedia = getGalleryMedia(gallery);

  // Flag form dirty if Child Form is focused
  const dirtyState = state.focus !== initialFormState.focus;

  const useFields = (data) =>
    useFormServiceRequest({
      data,
      buildings,
      setData: (data) => {
        console.log("Setting Data", data);
      },
      childServiceRequest: true,
      mediaUrls: galleryMedia,
    });

  // Populate clients; Fetch preselected client if clientId or contactId is set
  useEffect(() => {
    console.log("DEBUG: Searching for Clients", data);
    // When a clientId is set, fetch the client and set it as clientCandidate
    if (
      data.clientId &&
      !clients.find(
        (client) => Number(client.clientId) === Number(data.clientId)
      )
    ) {
      newClientGet(data.clientId).then((client) => {
        const name = `${client.clientName}`;
        const clientCandidate = {
          ...client,
          clientClass: "COMMERCIAL",
          label: name,
          name,
          value: `client.${client.clientId}`,
        };
        setClients([clientCandidate]);
      });
    }
    // When a contactId is set, fetch the contact and set it as clientCandidate
    else if (
      data.contactId &&
      !clients.find(
        (client) => Number(client.contactId) === Number(data.contactId)
      )
    ) {
      getContactDetails(data.contactId).then((contact) => {
        const name = `${contact.firstName} ${contact.lastName}`;
        const clientCandidate = {
          ...contact,
          clientClass: "INDIVIDUAL",
          name,
          value: `contact.${contact.contactId}`,
        };
        setClients([clientCandidate]);
      });
    }
    // When buildingCandidate is set, fetch Clients for the selected building
    else if (data.buildingCandidate) {
      getBuildingClients(data.buildingCandidate.buildingId)
        .then((data) => {
          const clients = data.map((client) => {
            const name = `${client.clientName}`;
            if (client.contactId)
              return {
                ...client,
                name,
                clientClass: "INDIVIDUAL",
                value: `contact.${client.contactId}`,
              };
            else return { ...client, name, value: `client.${client.clientId}` };
          });
          setClients(clients);
        })
        .catch((_err) => {
          alertError("Could not get clients for building");
        });
    }
    // When clientId or contactId is not set, fetch all clients
    else {
      getIndividualClients(data.clientCandidateSearch).then((data) => {
        const individualClients = data.content.map((contact) => {
          const name = `${contact.firstName} ${contact.lastName}`;
          return {
            ...contact,
            clientClass: "INDIVIDUAL",
            name,
            value: `contact.${contact.contactId}`,
          };
        });
        setClients((clients) => [
          ...clients.filter((client) => client.clientClass !== "INDIVIDUAL"),
          ...individualClients,
        ]);
      });
      getCommericalClients("ALL", data.clientCandidateSearch).then((data) => {
        const commercialClients = data.content.map((client) => {
          console.log("Commercial Client", client);
          const name = `${client.clientName}`;
          return { ...client, name, value: `client.${client.clientId}` };
        });
        setClients((clients) => [
          ...clients.filter((client) => client.clientClass === "INDIVIDUAL"),
          ...commercialClients,
        ]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data.clientCandidateSearch,
    data.clientCandidate,
    data.buildingCandidate,
    data.clientId,
    data.contactId,
  ]);

  // Set clientCandidate if clientId or contactId is set
  useEffect(() => {
    console.log("DEBUG: data.clientId or data.contactId or clients changed");
    if (data.clientId || data.contactId) {
      const clientCandidate = clients.find(
        (client) =>
          (data.clientId &&
            Number(client.clientId) === Number(data.clientId)) ||
          (data.contactId &&
            Number(client.contactId) === Number(data.contactId))
      );
      if (clientCandidate) {
        const clientCandidateOption = {
          groupBy: clientCandidate.clientClass,
          key: clientCandidate.value,
          label: clientCandidate.name,
        };
        setData((data) => {
          const updatedClientCandidate = deepUpdate(
            "clientCandidate",
            clientCandidateOption,
            data
          );

          const resetSelectedId = deepUpdate(
            data.clientId ? "clientId" : "contactId",
            null,
            updatedClientCandidate
          );

          return resetSelectedId;
        });
      }
    }
  }, [data.clientId, data.contactId, clients]);

  // Populate buildings when clientCandidate changes
  useEffect(() => {
    if (data.clientCandidate) {
      const [clientType, clientId] = data.clientCandidate.key.split(".");
      if (clientType === "contact") {
        getBuildingsUnderIndividualClient(clientId)
          .then((data) => {
            setBuildings(data);
          })
          .catch((_err) => {
            alertError("Could not get buildings for individual client");
          });
      } else {
        getBuildingsUnderCommercialClient(clientId)
          .then((data) => {
            setBuildings(data);
          })
          .catch((_err) => {
            alertError(
              "Could not get buildings for commercial client " + clientId
            );
          });
      }
    } else {
      getBuildings({}, data.buildingCandidateSearch)
        .then(({ content }) => {
          setBuildings(
            content.map((building) => ({
              ...building,
              address: {
                streetAddress1: building.streetAddress1,
                streetAddress2: building.streetAddress2,
                city: building.city,
                state: building.state,
                zip: building.zip,
              },
            }))
          );
        })
        .catch((_err) => {
          alertError("Could not get buildings");
        });
    }
  }, [data.clientCandidate, data.buildingCandidateSearch]);

  // Populate contacts when building changes
  useEffect(() => {
    if (data.buildingCandidate) {
      setContacts([]);

      getBuildingResidentsOwners(
        data.buildingCandidate.buildingId,
        data.buildingCandidate.buildingId
      )
        .then((data) => {
          setContacts((contacts) => [...contacts, ...data]);
        })
        .catch((_err) => {
          alertError("Could not get Residents/Owners for building");
        });

      if (data.clientCandidate && isCommercialClient) {
        const [_clientType, clientId] = data.clientCandidate.key.split(".");
        getBuildingManagers(clientId, data.buildingCandidate.buildingId)
          .then((data) => {
            const managersData = data.map((manager) => ({
              ...manager,
              position: "MANAGER",
            }));
            setContacts((contacts) => [...contacts, ...managersData]);
          })
          .catch((_err) => {
            alertError("Could not get Managers for building");
          });
      }
      getBuildingUnits(data.buildingCandidate.buildingId)
        .then((res) => {
          setUnits(res.value);
        })
        .catch((_err) => {
          alertError("Could not get units for building");
        });
    } else {
      setUnits([]);
      setContacts([]);
    }
  }, [data.buildingCandidate, data.clientCandidate]);

  // // Populate units when building or contact changes
  // useEffect(() => {
  //   if (data.building) {
  //     getBuildingUnits(data.building)
  //       .then((data) => {
  //         setUnits(data);
  //       })
  //       .catch((_err) => {
  //         alertError("Could not get units for building");
  //       });
  //   }
  // }, [data.building]);

  const contactOptions = contacts
    // If unitNumber is set, filter out contacts that belong to other units
    .filter((contact) => {
      if (data.unitNumber && data.unitNumber !== "N/A") {
        return contact.unitNumber === data.unitNumber || !contact.unitNumber;
      } else {
        return true;
      }
    })
    .sort((a, _b) => {
      // Show managers first
      return a.position === "MANAGER" ? -1 : 1;
    })
    .map((contact) => {
      const baseLabel = `${contact.firstName} ${contact.lastName}`;
      let unitNumber = "";
      if (contact.unitNumber) {
        unitNumber = ` - ${contact.unitNumber}`;
      }
      return {
        label: `${baseLabel}${unitNumber}`,
        value: contact.contactId,
        groupBy: contact.position,
        unitNumber: contact.unitNumber,
      };
    });

  const renderFields = [
    { name: "title" },
    {
      name: "buildingCandidate",
      label: "Building",
      inputType: "SELECT",
      variant: "AUTOCOMPLETE|OUTLINED",
      defaultValue: data.buildingCandidate,
      options: buildings.map((building) => ({
        label: renderAddress(building.address),
        buildingId: building.buildingId,
        key: `building.${building.buildingId}.${building.address.streetAddress2}`,
      })),
      relatedCallback: (buildingId, _onChangeCallback) => {
        if (!buildingId) {
          _onChangeCallback({ name: "unitNumber", value: null });
          _onChangeCallback({ name: "primaryContactCandidate", value: null });
          _onChangeCallback({ name: "primaryContactId", value: null });
          _onChangeCallback({ name: "alternateContactId", value: null });
        }
      },
    },
    {
      name: "unitNumber",
      inputType: "SELECT",
      variant: "AUTOCOMPLETE|FREESOLO",
      collapsible: true,
      defaultValue: data?.unitNumber
        ? data?.unitNumber
        : data?.alternateContact?.unitNumber
        ? data?.alternateContact?.unitNumber
        : "",
      options: units.map((unit) => unit || "N/A"),
    },
    {
      name: "clientCandidate",
      label: "Client",
      inputType: "SELECT",
      variant: "AUTOCOMPLETE|OUTLINED",
      defaultValue: data.clientCandidate,
      options: clients.map((client) => {
        return {
          label: client.name,
          key: client.value,
          groupBy: client.clientClass,
          unitNumber: client.unitNumber,
        };
      }),
      relatedCallback: (clientCandidate, onChangeCallback) => {
        if (clientCandidate?.unitNumber) {
          onChangeCallback({
            name: "unitNumber",
            value: clientCandidate.unitNumber,
          });
        }
      },
    },
    ...conditionalArrayEntry(!data.clientCandidate, {
      inputType: "RAW",
      children: (
        <div className="text-right">
          <span
            className="text-blue-500 cursor-pointer hover:text-blue-700 text-sm p-1 text-right"
            onClick={() => navigate("/clients/new")}
          >
            Create New Client
          </span>
        </div>
      ),
    }),
    ...conditionalArrayEntry(isCommercialClient, {
      name: "primaryContactCandidate",
      label: "Primary Contact",
      collapsible: true,
      inputType: "SELECT",
      variant: "AUTOCOMPLETE|OUTLINED",
      defaultValue: data.primaryContactCandidate,
      options: contactOptions.map((contact) => {
        return {
          label: contact.label,
          key: contact.value,
          groupBy: contact.groupBy,
          unitNumber: contact.unitNumber,
        };
      }),
      relatedCallback: (primaryContactCandidate, onChangeCallback) => {
        if (primaryContactCandidate) {
          onChangeCallback({
            name: "primaryContactId",
            value: primaryContactCandidate.key,
          });
          if (primaryContactCandidate.unitNumber) {
            onChangeCallback({
              name: "unitNumber",
              value: primaryContactCandidate.unitNumber,
            });
          }
        }
      },
    }),
    ...conditionalArrayEntry(!!contacts, {
      name: "alternateContactId",
      collapsible: true,
      inputType: "SELECT",
      defaultValue: data.contact,
      options: contactOptions.filter(
        (contact) => contact.value !== data.primaryContactId
      ),
    }),
    ...conditionalArrayEntry(!isExternal, {
      name: "ticketGallery",
      inputType: "RAW",
      children: (
        <CommsGallery
          data={data}
          gallery={gallery}
          setGallery={setGallery}
          onClose={onClose}
        />
      ),
    }),
    ...conditionalArrayEntry(!isExternal, {
      name: "serviceItems",
      inputType: "CHILDFORM",
      variant: "subCategory|materials",
      renderFields: useFields,
      relatedCallback: (action) => {
        switch (action.type) {
          case "disclosure":
            const { expanded } = action;
            if (expanded === false) {
              dispatch({ type: "setCollapsed", payload: false });
            } else {
              dispatch({ type: "toggleCollapsed" });
            }
            break;
          case "focus":
            const { name } = action;
            dispatch({ type: "setFocus", payload: name });
            break;
          default:
            break;
        }
      },
    }),
    { name: "notes", inputType: "AREA", variant: "OUTLINED" },
    {
      name: "priority",
      inputType: "SELECT",
      defaultValue: data.priority || "false",
      options: [
        { label: "High", value: "true" },
        { label: "Low", value: "false" },
      ],
    },
  ].filter(
    // Filter out collapsed fields when in collapsed mode
    (field) => !state.collapsed || !field.collapsible
  );

  return { renderFields, dirtyState };
}

// TODO: Make sure that `data` is required in this component.
const CommsGallery = ({ data, gallery, setGallery, onClose }) => {
  const isMobile = useMediaQuery("(max-width: 640px)");
  const galleryMedia = getGalleryMedia(gallery);
  return (
    <div className="flex flex-col">
      {gallery.mode === "select" ? (
        <div className="flex flex-row">
          <span className="text-gray-700 py-2 px-4 rounded">
            Tag Messages and Photos
          </span>
          <button
            className="hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded"
            type="button"
            onClick={() => {
              setGallery((gallery) => ({ ...gallery, mode: "preview" }));
            }}
          >
            Done
          </button>
        </div>
      ) : (
        <>
          {galleryMedia.length > 0 ? (
            <PreviewTiles
              urls={galleryMedia}
              onDelete={(url) => {
                const newSelected = gallery.selected.filter(
                  (item) => item !== url
                );
                setGallery((gallery) => ({
                  ...gallery,
                  selected: newSelected,
                }));
              }}
            />
          ) : (
            <></>
          )}
          {/* Button to switch Gallery to SELECT mdoe */}
          <button
            className="mt-2 p-2 font-bold rounded-md text-center uppercase text-gray-700 text-sm hover:text-blue-500 bg-blue-100 hover:bg-blue-200 "
            type="button"
            onClick={() => {
              triggerGallerySelection(data, () => {
                setGallery((gallery) => ({
                  ...gallery,
                  mode: "select",
                }));
              });
              if (isMobile) {
                onClose();
                alertInfo(
                  "Please select the messages and then reopen the Ticket Creation Form and click Done to proceed"
                );
              }
            }}
          >
            Tag Attachments
          </button>
        </>
      )}
    </div>
  );
};
