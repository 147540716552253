import { useEffect, useState } from "react";

import { alertError, alertInfo } from "../../../../../actions/AlertActions";
import { fileUpload, getLeaseById, updateLease } from "../../../../../api/Api";
import {
  AddIconBlue,
  UploadIcon,
} from "../../../../../components/common/AppIcons";
import MaterialModal from "../../../../../components/common/MaterialModal";
import MaterialForm from "../../../../../components/common/form/MaterialForm";
import { uploadEncodedFile } from "../../helper";

type DocumentsTabProps = {
  leaseId: number;
};

type Document = {
  name: string;
  url?: string;
};

export default function DocumentsTab({ leaseId }: DocumentsTabProps) {
  const [newDocument, setNewDocument] = useState({ display: false, name: "" });
  const [documents, setDocuments] = useState<Document[]>([]);
  const [leaseTerms, setLeaseTerms] = useState("");
  useEffect(() => {
    if (leaseId !== null && leaseId !== 0)
      getLeaseById(leaseId).then((data) => {
        setLeaseTerms(data.leaseTerms);
        setDocuments(data.documents ? (data.documents as Document[]) : []);
      });
  }, [leaseId]);

  const onChange = () => (update: { name: string; value: string }) => {
    const { value } = update;
    setNewDocument({ display: true, name: value });
  };

  const uploadFile = (data: any, index: number) =>
    fileUpload(data)
      .then((res) => {
        console.log("Success!", res.value);
        documents[index].url = res.value;
        setDocuments(documents);

        updateLease(leaseId, [
          {
            op: "replace",
            path: "/documents",
            value: documents,
          },
        ]).then(() => {
          getLeaseById(leaseId).then((data) => {
            setDocuments(data.documents ?? []);
          });
        });
        alertInfo("File Uploaded");
      })
      .catch((failure) => {
        console.log("Failure", failure);
      });

  const handleFileUpload = (
    event: { target: { files: any } },
    index: number
  ) => {
    if (leaseId === null) {
      alertError("Please add lease");
      return;
    }
    let files = event.target.files;
    alertInfo("Uploading File");
    Array.from(files).forEach((file) => {
      console.log("MIME:" + file.type);
      let reader = new FileReader();
      reader.onload = uploadEncodedFile(
        (data) => uploadFile(data, index),
        file
      );
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="w-full p-4">
      <div className="w-full flex flex-col items-start self-center ">
        <div className="text-lg font-medium text-black">Lease Details</div>
        <div className="flex flex-row mt-4 my-1">
          <div className="text-base text-newGray-800 font-normal mr-1">
            {`Lease term:`}
          </div>
          <div className="text-base text-black font-semibold">{leaseTerms}</div>
        </div>
        <div className="flex flex-row my-1 mb-4">
          <div className="text-base text-newGray-800 font-normal mr-1">
            {`Lease Status:`}
          </div>
          <div className="text-base text-black font-semibold">---</div>
        </div>
        <div className="text-lg font-medium text-black mt-4">
          Add Leasing Documents
        </div>
        <div className="w-full">
          {documents?.map((item, index) => {
            return (
              <div className="flex flex-col md:flex-row justify-between w-full md:w-144 rounded border px-4 py-2 my-2 border-newGray-700 gap-4">
                <div className="text-base font-medium text-black">
                  {item?.name}
                </div>
                {item.url === null ? (
                  <label className="flex  cursor-pointer bg-newBlue-500 items-center justify-center rounded border border-dashed border-newBlue-400 transition-all px-2 py-1">
                    <UploadIcon />
                    <div className="space-1 text-center">
                      <div className=" text-xs text-newBlue-400 font-medium  ">
                        Upload
                      </div>
                    </div>
                    <input
                      id="upload_image"
                      type="file"
                      className="hidden"
                      onChange={(event) => handleFileUpload(event, index)}
                    />
                  </label>
                ) : (
                  <div className="flex flex-row justify-between">
                    <div className=" px-2 py-1 bg-green-200 text-green-700 text-xs font-medium rounded">
                      <a href={item.url} target="_blank" rel="noreferrer">
                        View
                      </a>
                    </div>
                    <label className="flex  cursor-pointer bg-yellow-200 items-center justify-center rounded transition-all px-2 py-1 ml-2">
                      <div className="space-1 text-center">
                        <div className=" text-xs text-yellow-800 font-medium  ">
                          Upload revision
                        </div>
                      </div>
                      <input
                        id="upload_image"
                        type="file"
                        className="hidden"
                        onChange={(event) => handleFileUpload(event, index)}
                      />
                    </label>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <button
          className="border w-max h-8 rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400 my-4 self-center md:self-start"
          onClick={() => setNewDocument({ display: true, name: "" })}
        >
          <div className="flex flex-row items-center ">
            <div>
              <AddIconBlue className="h-4 w-4" />
            </div>
            <div className="px-2 flex">
              <div className="font-medium">Create New Document</div>
            </div>
          </div>
        </button>
      </div>
      <MaterialModal
        open={newDocument.display}
        setOpen={() => setNewDocument({ display: false, name: "" })}
      >
        <div className="flex flex-col items-end w-80">
          <MaterialForm
            className="w-80"
            data={newDocument}
            renderArray={[
              {
                name: "name",
                variant: "OUTLINED",
              },
            ]}
            onChange={(update: { name: string; value: string }) => {
              const { value } = update;
              setNewDocument({ display: true, name: value });
            }}
          />
          <button
            className="text-base mr-2  flex justify-center items-center px-4 py-2 rounded font-medium  
        bg-newBlue-400  w-40
        text-white border-newBlue-400
        border mt-4"
            onClick={() => {
              if (leaseId === null) {
                alertError("Please add lease");
                return;
              }
              updateLease(leaseId, [
                {
                  op: "replace",
                  path: "/documents",
                  value: [...documents, { name: newDocument.name, url: null }],
                },
              ]).then(() => {
                getLeaseById(leaseId).then((data) => {
                  setDocuments(data.documents ?? []);
                });
                setNewDocument({ display: false, name: "" });
              });
            }}
          >
            Save
          </button>
        </div>
      </MaterialModal>
    </div>
  );
}
