// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "rescript/lib/es6/array.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Text$UscReact from "./components/Text.bs.js";
import * as Number$UscReact from "./components/Number.bs.js";
import * as Switch$UscReact from "./components/Switch.bs.js";
import * as Address$UscReact from "./components/Address.bs.js";
import * as Gallery$UscReact from "./components/Gallery.bs.js";
import * as Dropdown$UscReact from "./components/Dropdown.bs.js";
import * as ChildForm$UscReact from "./components/ChildForm.bs.js";
import * as FormInput$UscReact from "./FormInput.bs.js";
import * as DatePicker$UscReact from "./components/DatePicker.bs.js";
import * as FileUpload$UscReact from "./components/FileUpload.bs.js";
import * as TimePicker$UscReact from "./components/TimePicker.bs.js";
import * as PhoneNumber$UscReact from "./components/PhoneNumber.bs.js";
import * as DateTimePicker$UscReact from "./components/DateTimePicker.bs.js";

function MaterialForm(Props) {
  var className = Props.className;
  var fieldClassName = Props.fieldClassName;
  var editable = Props.editable;
  var data = Props.data;
  var renderArray = Props.renderArray;
  var onSubmit = Props.onSubmit;
  var onChange = Props.onChange;
  var noSelect = Props.noSelect;
  var subForm = Props.subForm;
  var match = React.useState(function () {
        
      });
  var setFocusChild = match[1];
  var focusChild = match[0];
  var focusCB = function (name) {
    return Curry._1(setFocusChild, (function (param) {
                  if (name !== undefined) {
                    return name;
                  }
                  
                }));
  };
  var fields = $$Array.mapi((function (param, input) {
          var inputClass = FormInput$UscReact.className(input);
          var fieldClass = inputClass !== undefined ? inputClass : fieldClassName;
          if (focusChild !== undefined) {
            if (focusChild === FormInput$UscReact.name(input)) {
              return React.createElement(ChildForm$UscReact.make, {
                          editable: editable,
                          data: data,
                          variant: FormInput$UscReact.variant(input),
                          name: FormInput$UscReact.name(input),
                          label: FormInput$UscReact.label(input),
                          className: fieldClass,
                          optional: FormInput$UscReact.optional(input),
                          setFocusChild: focusCB,
                          focusChild: focusChild,
                          renderFields: FormInput$UscReact.renderFields(input),
                          relatedCallback: FormInput$UscReact.relatedCallback(input),
                          onChange: onChange,
                          key: FormInput$UscReact.name(input)
                        });
            } else {
              return React.createElement("div", {
                          key: FormInput$UscReact.name(input),
                          className: "hidden"
                        });
            }
          }
          var match = FormInput$UscReact.inputType(input);
          switch (match) {
            case "ADDRESS" :
                return React.createElement(Address$UscReact.make, {
                            editable: editable,
                            data: data,
                            variant: FormInput$UscReact.variant(input),
                            name: FormInput$UscReact.name(input),
                            defaultValue: FormInput$UscReact.defaultValue(input),
                            optional: FormInput$UscReact.optional(input),
                            options: FormInput$UscReact.options(input),
                            label: FormInput$UscReact.label(input),
                            className: fieldClass,
                            onChange: onChange,
                            noSelect: noSelect,
                            key: FormInput$UscReact.name(input)
                          });
            case "CHILDFORM" :
                return React.createElement(ChildForm$UscReact.make, {
                            editable: editable,
                            data: data,
                            variant: FormInput$UscReact.variant(input),
                            name: FormInput$UscReact.name(input),
                            label: FormInput$UscReact.label(input),
                            className: fieldClass,
                            optional: FormInput$UscReact.optional(input),
                            setFocusChild: focusCB,
                            focusChild: focusChild,
                            renderFields: FormInput$UscReact.renderFields(input),
                            relatedCallback: FormInput$UscReact.relatedCallback(input),
                            onChange: onChange,
                            key: FormInput$UscReact.name(input)
                          });
            case "DATE" :
                return React.createElement(DatePicker$UscReact.make, {
                            editable: editable,
                            data: data,
                            variant: FormInput$UscReact.variant(input),
                            name: FormInput$UscReact.name(input),
                            label: FormInput$UscReact.label(input),
                            className: fieldClass,
                            optional: FormInput$UscReact.optional(input),
                            defaultValue: FormInput$UscReact.defaultValue(input),
                            onChange: onChange,
                            key: FormInput$UscReact.name(input)
                          });
            case "DATETIME" :
                return React.createElement(DateTimePicker$UscReact.make, {
                            editable: editable,
                            data: data,
                            variant: FormInput$UscReact.variant(input),
                            name: FormInput$UscReact.name(input),
                            label: FormInput$UscReact.label(input),
                            className: fieldClass,
                            optional: FormInput$UscReact.optional(input),
                            defaultValue: FormInput$UscReact.defaultValue(input),
                            onChange: onChange,
                            key: FormInput$UscReact.name(input)
                          });
            case "GALLERY" :
                return React.createElement(Gallery$UscReact.make, {
                            editable: editable,
                            options: FormInput$UscReact.options(input),
                            data: data,
                            variant: FormInput$UscReact.variant(input),
                            name: FormInput$UscReact.name(input),
                            label: FormInput$UscReact.label(input),
                            className: fieldClass,
                            optional: FormInput$UscReact.optional(input),
                            onChange: onChange,
                            key: FormInput$UscReact.name(input)
                          });
            case "IMAGE" :
                return React.createElement(FileUpload$UscReact.make, {
                            editable: editable,
                            data: data,
                            name: FormInput$UscReact.name(input),
                            label: FormInput$UscReact.label(input),
                            className: fieldClass,
                            optional: FormInput$UscReact.optional(input),
                            onChange: onChange,
                            key: FormInput$UscReact.name(input)
                          });
            case "NUMBER" :
                return React.createElement(Number$UscReact.make, {
                            editable: editable,
                            data: data,
                            variant: FormInput$UscReact.variant(input),
                            name: FormInput$UscReact.name(input),
                            label: FormInput$UscReact.label(input),
                            className: fieldClass,
                            optional: FormInput$UscReact.optional(input),
                            onChange: onChange,
                            key: FormInput$UscReact.name(input)
                          });
            case "PHONE" :
                return React.createElement(PhoneNumber$UscReact.make, {
                            editable: editable,
                            data: data,
                            name: FormInput$UscReact.name(input),
                            variant: FormInput$UscReact.variant(input),
                            label: FormInput$UscReact.label(input),
                            className: fieldClass,
                            optional: FormInput$UscReact.optional(input),
                            onChange: onChange,
                            key: FormInput$UscReact.name(input)
                          });
            case "RAW" :
                var children = FormInput$UscReact.children(input);
                if (children !== undefined) {
                  return React.createElement("div", {
                              key: FormInput$UscReact.name(input),
                              className: "flex flex-col gap-1"
                            }, Caml_option.valFromOption(children));
                } else {
                  return React.createElement("div", {
                              key: FormInput$UscReact.name(input),
                              className: "flex flex-col gap-1"
                            });
                }
            case "SELECT" :
                return React.createElement(Dropdown$UscReact.make, {
                            editable: editable,
                            data: data,
                            variant: FormInput$UscReact.variant(input),
                            name: FormInput$UscReact.name(input),
                            defaultValue: FormInput$UscReact.defaultValue(input),
                            optional: FormInput$UscReact.optional(input),
                            options: FormInput$UscReact.options(input),
                            label: FormInput$UscReact.label(input),
                            className: fieldClass,
                            onChange: onChange,
                            noSelect: noSelect,
                            relatedCallback: FormInput$UscReact.relatedCallback(input),
                            key: FormInput$UscReact.name(input)
                          });
            case "CHECKBOX" :
            case "SWITCH" :
                break;
            case "TIME" :
                return React.createElement(TimePicker$UscReact.make, {
                            editable: editable,
                            data: data,
                            variant: FormInput$UscReact.variant(input),
                            name: FormInput$UscReact.name(input),
                            label: FormInput$UscReact.label(input),
                            className: fieldClass,
                            optional: FormInput$UscReact.optional(input),
                            defaultValue: FormInput$UscReact.defaultValue(input),
                            onChange: onChange,
                            key: FormInput$UscReact.name(input)
                          });
            default:
              return React.createElement(Text$UscReact.make, {
                          editable: editable,
                          data: data,
                          variant: FormInput$UscReact.variant(input),
                          name: FormInput$UscReact.name(input),
                          label: FormInput$UscReact.label(input),
                          className: fieldClass,
                          inputType: FormInput$UscReact.inputType(input),
                          defaultValue: FormInput$UscReact.defaultValue(input),
                          optional: FormInput$UscReact.optional(input),
                          onChange: onChange,
                          inputOptions: FormInput$UscReact.inputOptions(input),
                          key: FormInput$UscReact.name(input)
                        });
          }
          return React.createElement(Switch$UscReact.make, {
                      editable: editable,
                      data: data,
                      name: FormInput$UscReact.name(input),
                      label: FormInput$UscReact.label(input),
                      className: fieldClass,
                      optional: FormInput$UscReact.optional(input),
                      onChange: onChange,
                      inputType: FormInput$UscReact.inputType(input),
                      key: FormInput$UscReact.name(input)
                    });
        }), renderArray);
  var containerClassName = className !== undefined ? className : "px-4 py-2 w-full flex flex-col gap-1 material-form-parent";
  if (subForm !== undefined) {
    return React.createElement("div", {
                className: containerClassName
              }, fields);
  } else {
    return React.createElement("form", {
                className: containerClassName,
                onSubmit: onSubmit
              }, fields);
  }
}

var make = MaterialForm;

export {
  make ,
  
}
/* react Not a pure module */
