import { useState } from "react";

import { alertError, alertSuccess } from "../../../../../actions/AlertActions";
import { addTenantUnderLease } from "../../../../../api/Api";
import MaterialForm from "../../../../../components/common/form/MaterialForm";
import { deepUpdate } from "../../../../../utils/StateUtils";

type NewTenantProps = {
  leaseId: number;
  onCloseTenant: () => void;
  onSave: () => void;
};

export default function NewTenantForm({
  leaseId,
  onCloseTenant,
  onSave,
}: NewTenantProps) {
  const [tenantDetails, setTenantDetails] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    tenantClass: "",
  });

  const onChange = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setTenantDetails((data) => deepUpdate(name, value, data));
  };

  const onSaveNewTenant = () => {
    if (leaseId === null) {
      alertError("Please add lease");
      return;
    }
    addTenantUnderLease(leaseId, tenantDetails).then(() => {
      alertSuccess("Tenant added successfully");
      onSave();
      onCloseTenant();
    });
  };

  const tenantOptions = [
    { value: "PRIMARY_TENANT", label: "Primary Tenant" },
    { value: "SUBTENANT", label: "Sub Tenant" },
    { value: "ROOMMATE", label: "Roommate" },
    { value: "GUEST", label: "Guest" },
    { value: "AUTHORIZED_OCCUPANT", label: "Authorized Occupant" },
  ];

  return (
    <div>
      <div className="text-lg font-medium text-black">Create a New Tenant</div>
      <div>
        <MaterialForm
          className="w-144 grid grid-flow-row-dense grid-rows-1 grid-cols-2 mt-3 "
          data={tenantDetails}
          onChange={onChange}
          fieldClassName="px-2 my-2"
          renderArray={[
            { name: "firstName", variant: "OUTLINED" },
            { name: "lastName", variant: "OUTLINED" },
            { name: "phone", variant: "OUTLINED", inputType: "PHONE" },
            { name: "email", variant: "OUTLINED", inputType: "EMAIL" },
            {
              name: "tenantClass",
              variant: "OUTLINED",
              inputType: "SELECT",
              options: tenantOptions,
              label: "Select tenant Class",
            },
          ]}
        />
      </div>
      <div className="flex flex-row mt-4 mr-4 ">
        <button
          className="text-base mr-2  flex justify-center items-center px-4 py-2 rounded font-medium  
        bg-newBlue-400  w-40
        text-white border-newBlue-400
        border"
          onClick={() => {
            onSaveNewTenant();
          }}
        >
          Save Tenant
        </button>
        <button
          className="text-base flex justify-center items-center px-4 py-2 rounded  font-medium cursor-pointer 
        bg-gray-300
        text-gray-800 duration-200 ease-in-out 
        transition mr-4"
          onClick={onCloseTenant}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
