import React from "react";

import { renderAddress } from "../../utils/StringUtils";
import { PenIcon } from "../common/AppIcons";
import { materialMethodMapping } from "../common/form/constants";

export default function MaterialTile({
  material,
  data,
  materialSelectCB,
  editCB,
}) {
  return (
    <div className="w-full border rounded px-6 py-4 border-newGray-700">
      <div className="flex flex-row items-center justify-between gap-5 flex-wrap">
        <div className="flex flex-row items-center gap-2">
          <span className="text-newBlue-400 font-semibold">
            {material.name}
          </span>
          <span
            className={
              "text-sm ml-1 px-2 py-1 rounded text-newBlue-600 " +
              (material.method === "PICKUP"
                ? "bg-newRed-100"
                : "bg-newGreen-100")
            }
          >
            {materialMethodMapping[material.method]}
          </span>
          <span className="text-sm ml-1 px-2 py-1 rounded bg-newBlue-700 text-newBlue-600">
            {material.isDelivered ? "Delivered" : "Not Delivered"}
          </span>
        </div>
      </div>
      <div className="mt-4 flex flex-row items-end justify-between flex-wrap gap-4">
        <div className="flex flex-col gap-1 font-semibold">
          <div className="flex flex-row items-center gap-x-2 flex-wrap">
            <span className="text-newGray-800 font-normal	text-sm">
              SKU Number :
            </span>
            <span className="text-newBlue-600 font-normal ">
              {material.skuNumber}
            </span>
          </div>
          <div className="flex flex-row items-center gap-x-2 flex-wrap">
            <span className="text-newGray-800 font-normal	text-sm">
              Supplier :
            </span>
            <span className="text-newBlue-600 font-normal ">
              {material.supplier?.name}
            </span>
          </div>
          <div className="flex flex-row items-center gap-x-2 flex-wrap">
            <span className="text-newGray-800 font-normal	text-sm">
              Supplier Branch :
            </span>
            <span className="text-newBlue-600 font-normal ">
              {renderAddress(material.supplierBranch?.address)}
            </span>
          </div>
        </div>
        <div>
          <button
            className="text-newBlue-600 bg-newGray-900 hover:bg-gray-100 rounded text-sm px-2 py-1 flex flex-row items-center gap-1 font-normal"
            onClick={() => {
              editCB({
                display: true,
                data: {
                  materialId: material.materialId,
                  method: material.method,
                  name: material.name,
                  description: material.description,
                  skuNumber: material.skuNumber,
                  supplierId: material.supplier?.supplierId,
                  supplierBranchId: material.supplierBranch?.buildingId,
                  orderDate: material.orderDate,
                  deliveryDate: material.deliveryDate,
                  orderNumber: material.orderNumber,
                  trackingUrl: material.trackingUrl,
                  serviceRequestId: data.serviceRequestId,
                  isDelivered: material.isDelivered ? "true" : "false",
                },
                edit: true,
              });
            }}
          >
            <PenIcon fill="black" fillOpacity="0.54" />
            Update
          </button>
        </div>
      </div>
    </div>
  );
}
