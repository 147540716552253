import dispatcher from "../dispatcher";
//
export function openChat() {
  dispatcher.dispatch({
    actionType: "CHAT",
    open: true,
  });
}

export function closeChat() {
  dispatcher.dispatch({
    actionType: "CLOSE_CHAT",
    open: true,
  });
}

export function messageRecieved() {
  dispatcher.dispatch({
    actionType: "MESSAGE_RECIEVE",
    open: true,
  });
}

export function unReadMessage(conversationId) {
  dispatcher.dispatch({
    actionType: "UNREAD",
    open: true,
    conversationId: conversationId,
  });
}
