import React from "react";

const phonePad = [
  { numeric: "1", label: "-" },
  { numeric: "2", label: "A B C" },
  { numeric: "3", label: "D E F" },
  { numeric: "4", label: "G H I" },
  { numeric: "5", label: "J K L" },
  { numeric: "6", label: "M N O" },
  { numeric: "7", label: "P Q R S" },
  { numeric: "8", label: "T U V" },
  { numeric: "9", label: "W X Y Z" },
  { numeric: "*", label: " " },
  { numeric: "0", label: " " },
  { numeric: "#", label: " " },
];

export default function Dialpad({ twilioDevice }) {
  // Handle numeric buttons
  const sendDigit = (digit) => {
    twilioDevice.activeConnection().sendDigits(digit);
  };

  return (
    <div className="p-4 grid grid-cols-3 bg-black gap-2">
      {phonePad.map((button, index) => {
        return (
          <button
            key={index}
            className="px-4 py-2 rounded-full w-full flex flex-col items-center justify-center hover:bg-gray-300 rounded-md bg-newGray-1000"
            onClick={() => sendDigit(button.numeric)}
          >
            <span className="text-xl  text-white">{button.numeric}</span>
            {/* <span className="text-sm">{button.label ?? ""}</span> */}
          </button>
        );
      })}
    </div>
  );
}
