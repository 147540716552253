import { useSetRecoilState } from "recoil";

import { ClientRefItem, clientRefAtom } from "../store/ClientRefState";

export function useClientRef() {
  const setClientReference = useSetRecoilState(clientRefAtom);
  return {
    createClientWith: ({ component, props, prefill }: ClientRefItem) =>
      setClientReference({
        component,
        props,
        prefill,
      }),
    clearClientRef: () => setClientReference(null),
  };
}
