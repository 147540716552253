// Public Router using Raviger with routes to Signin and ForgotPassword
import React from "react";

import { useRoutes } from "raviger";

import RecoverPassword from "../components/auth/RecoverPassword";
import ResetPassword from "../components/auth/ResetPassword";
import SignIn from "../components/auth/SignIn";

let routes = {
  "/": () => <SignIn />,
  "/forgot-password": () => <ResetPassword />,
  "/recover-password/:uuid/:email/endofurl": (pathParams: {
    uuid: string;
    email: string;
  }) => <RecoverPassword uuid={pathParams.uuid} email={pathParams.email} />,
};

export default function PublicRouter() {
  return useRoutes(routes);
}
