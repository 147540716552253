import { useEffect, useState } from "react";

import { alertError, alertSuccess } from "../../../actions/AlertActions";
import {
  addUnitUnderManager,
  deleteUnitUnderManager,
  getBuildingsUnderCommercialClient,
} from "../../../api/Api";
import { getAddressAutofill } from "../../../api/TypedAPI";
import {
  DeleteBin,
  HomeIcon,
} from "../../../components/clients/ClientAppIcons";
import RenderAddressComponent from "../../../components/clients/RenderAddressComponent";

const fetchBuildings = (clientId, callback) => {
  getBuildingsUnderCommercialClient(clientId).then((buildings) =>
    callback({ buildings })
  );
};

const linkManagerToBuilding = (
  buildingId,
  contactId,
  clientId,
  unitNumber,
  callback
) => {
  addUnitUnderManager(buildingId, {
    contactId,
    clientId,
    unitNumber,
  }).then((_data) => {
    callback();
  });
};
export default function ManagerAccess({ clientId, manager, callback }) {
  const [buildings, setBuildings] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);

  const [building, setBuilding] = useState({});

  useEffect(() => {
    if (clientId) {
      fetchBuildings(clientId, ({ buildings }) => {
        setBuildings(buildings);
      });
    }
  }, [clientId]);

  useEffect(() => {
    if (building.address) {
      getAddressAutofill(building.address.streetAddress1, "CA")
        .then((addresses) => {
          // Find the Entries where Address.Address1 = data.streetAddress1
          // Check if any of the entries have a non-empty string as Address.SubBuilding
          // If so, set the unitOptions to the array
          // If not, set the unitOptions to an empty array

          const unitOptionsString =
            addresses
              .filter(
                (entry) =>
                  entry.Address.Address1 === building.address.streetAddress1
              )
              .find((entry) => entry.Address.SubBuilding.length > 0)?.Address
              .SubBuilding || "";

          if (unitOptionsString.length > 0) {
            setUnitOptions(unitOptionsString.split(","));
          } else {
            setUnitOptions([]);
          }
        })
        .catch((_err) => {
          alertError(
            "Sorry, we're having trouble finding your address. Please try again later."
          );
        });
    } else {
      setUnitOptions([]);
    }
  }, [building]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-center"></div>
      <div className="flex">
        {!building.address ? (
          <SelectBuilding
            buildings={buildings?.filter(
              (building) =>
                !manager.buildings.find(
                  (managerBuilding) =>
                    managerBuilding.buildingId === building.buildingId
                )
            )}
            onSelectCallback={setBuilding}
          />
        ) : (
          <SelectUnit
            units={unitOptions}
            onCancel={() => setBuilding({})}
            onSelectCallback={(unit) => {
              linkManagerToBuilding(
                building.buildingId,
                manager.contactId,
                clientId,
                unit,
                () => {
                  setBuilding({});
                  alertSuccess("Successfully linked manager to building");
                  callback();
                }
              );
            }}
          />
        )}
        <div className="flex flex-col w-128 items-center border border-gray-300 rounded p-4 h-112 justify-start">
          <div className="font-semibold text-lg pb-2">
            Manager Access Control
          </div>
          {manager?.buildings?.length ? (
            manager?.buildings?.map((building, index) => (
              <div className="p-4 flex flex-row justify-between items-center border border-gray-300 w-full">
                <div className="flex flex-row items-center">
                  <div className="bg-newBlue-500 p-2 rounded">
                    <HomeIcon />
                  </div>
                  {building.unitNumber && (
                    <div className="px-2">
                      <span className="bg-gray-300 text-gray-600 rounded px-2 py-1">
                        {building.unitNumber}
                      </span>
                    </div>
                  )}
                  <div className="px-2">
                    <span className="text-newBlue-600">{`${building.address.streetAddress1} ${building.address.streetAddress1}, ${building.address.city}`}</span>
                  </div>
                </div>
                {/* TODO: Add a button when required, to add additional unit-level access.  */}
                <div
                  className="flex flex-row items-center cursor-pointer"
                  onClick={() => {
                    deleteUnitUnderManager(building.buildingId, {
                      contactId: manager.contactId,
                      unitNumber: building.unitNumber,
                    })
                      .then((_data) => {
                        alertSuccess("Unit Removed");
                        callback();
                      })
                      .catch((_err) => {
                        alertError(
                          "Sorry, we couldn't remove the unit. Please try again later."
                        );
                      });
                  }}
                >
                  <DeleteBin />
                </div>
              </div>
            ))
          ) : (
            <div className="flex flex-row justify-center items-center">
              <div className="bg-newBlue-500 p-2 rounded">
                <HomeIcon />
              </div>
              <div className="px-2">
                <span className="text-newBlue-600">No Units</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const SelectBuilding = ({ buildings, onSelectCallback }) => (
  <div className="flex flex-col h-112 overflow-y-auto pr-2">
    {/* Link a New Building from the List */}
    <h3 className="font-semibold text-lg pb-2">Link a New Building</h3>
    <div className="flex flex-col w-80">
      {buildings.map((building, _index) => (
        <div className="flex flex-row justify-between items-center border border-gray-300 w-full p-4">
          <div className="flex flex-row items-center">
            <div className="bg-newBlue-500 p-2 rounded">
              <HomeIcon />
            </div>
            <div className="px-2">
              <RenderAddressComponent address={building.address} />
            </div>
          </div>
          <div className="flex flex-row items-center">
            <button
              className="bg-newBlue-500 text-gray-800 rounded px-2 py-1"
              onClick={() => {
                onSelectCallback(building);
              }}
            >
              Link
            </button>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const SelectUnit = ({ units, onSelectCallback, onCancel }) => {
  const unitOptions = units.map((unit) => ({
    value: unit,
    label: unit,
  }));

  const allOptions = [{ value: null, label: "Building" }, ...unitOptions];

  return (
    <div className="flex flex-col h-112 overflow-y-auto pr-2">
      {/* Link a New Building from the List */}
      <div className="flex flex-row justify-between items-center">
        <h3 className="font-semibold text-lg pb-2">Select a Unit</h3>
        <button
          className="text-blue-800 p-2 mx-2"
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col w-80">
        {allOptions.map((unit, _index) => (
          <div className="flex flex-row justify-between items-center border border-gray-300 w-full p-4">
            <div className="flex flex-row items-center">
              <div className="bg-newBlue-500 p-2 rounded">
                <HomeIcon />
              </div>
              <div className="px-2">
                <span className="bg-gray-300 text-gray-600 rounded px-2 py-1">
                  {unit.label}
                </span>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <button
                className="bg-newBlue-500 text-gray-800 rounded px-2 py-1"
                onClick={() => {
                  onSelectCallback(unit.value);
                }}
              >
                Link
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
