import React, { useEffect, useRef, useState } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import CachedIcon from "@mui/icons-material/Cached";
import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import DialpadIcon from "@mui/icons-material/Dialpad";
import DoneIcon from "@mui/icons-material/Done";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import PauseIcon from "@mui/icons-material/Pause";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import ReplayIcon from "@mui/icons-material/Replay";
import RingVolumeIcon from "@mui/icons-material/RingVolume";
import RouterIcon from "@mui/icons-material/Router";
import NoSignalIcon from "@mui/icons-material/SignalCellularConnectedNoInternet0Bar";
import SMSIcon from "@mui/icons-material/Textsms";
import { Button } from "@mui/material";
import { Popover } from "@mui/material";
import { Typography } from "@mui/material";
import { Avatar } from "@mui/material";
import { navigate, usePath } from "raviger";
import { Device } from "twilio-client";

import {
  alertError,
  alertInfo,
  alertSuccess,
} from "../../actions/AlertActions";
import { openChat } from "../../actions/ChatActions";
import {
  subscribeTopic,
  unsubscribeTopic,
} from "../../actions/NotificationActions";
import { callEnded } from "../../actions/VoiceActions";
import {
  addNotes,
  getCapabilityToken,
  getChatToken,
  identifyCaller,
  pauseRecording,
  resumeRecording,
  sendMessage,
  getPhoneNumbers,
} from "../../api/Api";
import dispatcher from "../../dispatcher";
import { deepUpdate } from "../../utils/StateUtils";
import { renderPhone } from "../../utils/StateUtils";
import { previewText } from "../../utils/StringUtils";
import { ProfileIcon } from "../common/AppIcons";
import { CRMExpandChat, LeftArrowWithTailIcon } from "../common/AppIcons";
import MaterialModal from "../common/MaterialModal";
import PopOverClick from "../common/PopOverClick";
import MaterialForm from "../common/form/MaterialForm";
import MaterialUiPhoneNumber from "../common/phonePicker/index";
import Dialpad from "./Dialpad";
import MarketingCampaign from "./MarketingCampaign";
import TaskRouterUI from "./TaskRouterUI";
import { useCheckMobileScreen } from "./chat/utils/useMatchMedia";

const baseState = {
  muted: false,
  log: "Connected",
  onPhone: false,
  caller: null,
  phoneNumber: "",
  isValidNumber: true,
  profilePicture: null,
};

const addEncodedFile = (setState, file) =>
  function (upload) {
    setState((state) => {
      return {
        ...state,
        attachments: [
          ...(state.attachments ?? []),
          {
            fileName: file.name,
            contentType: file.type,
            encodedFile: upload.target.result,
          },
        ],
      };
    });
  };

const startChat = async (setTwilioChatToken) => {
  getChatToken().then(setTwilioChatToken).catch(console.log);
};

const TEXT_TEMPLATE = `Hello! 
Thanks for reaching out to Help Squad.
We need your full name/address/email address, a list of items along with photos so that we can provide a rough estimate.

Thanks. `;

export default function CRMControls({ setCallWidgetCB }) {
  const isDesktopResolution = !useCheckMobileScreen();

  const [device, setDevice] = useState(new Device());
  const [muted, setMuted] = useState(false);
  const [recording, setRecording] = useState(true);
  const [showDialpad, toggleDialpad] = useState(false);
  const [activeConnection, setActiveConnection] = useState();
  const currentPath = usePath();
  const fileRef = useRef();
  const [state, setState] = useState({
    ...baseState,
    log: "Connecting",
    // TODO: Add activeUser.firstName at the end of the TextContent
    textContent: TEXT_TEMPLATE,
  });
  const [twilioChatToken, setTwilioChatToken] = useState();
  const [messageReceived, setMessageReceived] = useState(false);
  const [openChatBox, setOpenChatBox] = useState(false);
  const [associateCall, setAssociateCall] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  // const [marketingCampaignDetails, setMarketingCampaignDetails] = useState({});

  const [phoneNumberData, setPhoneNumberData] = useState([]);

  const [mobileCallPopup, setMobileCallPopUp] = useState(false);

  const [overflow, setOverflow] = useState(false);

  useEffect((_) => {
    startChat(setTwilioChatToken);

    dispatcher.register(handleAction);
    window.dispatcher = dispatcher;
  }, []);

  useEffect(() => {
    if (twilioChatToken) {
      subscribeTopic({
        topic: "/notifications/public_chat",
      });
      subscribeTopic({
        topic: "/notifications/conversation_joined",
      });
      return () => {
        unsubscribeTopic({
          topic: "/notifications/public_chat",
        });
        unsubscribeTopic({
          topic: "/notifications/conversation_joined",
        });
      };
    }
  }, [twilioChatToken]);

  const handleAction = (action) => {
    switch (action.actionType) {
      case "NOTIFICATION":
        if (action.topic.includes("/public_chat"))
          if (!openChatBox) setMessageReceived(true);
        break;
      case "MESSAGE_RECIEVE":
        if (!openChatBox) {
          setMessageReceived(true);
        }
        break;
      case "CLOSE_CHAT":
        setOpenChatBox(false);
        setMessageReceived(false);

        break;
      case "CHAT":
        setOpenChatBox(true);
        setMessageReceived(false);
        break;
      case "VOICE":
        handleToggleCall(action.callerInfo);
        break;
      case "ASSOCIATE_VOICE":
        setAssociateCall(true);
        handleToggleCall(action.callerInfo);
        break;
      case "INCOMING_TASK":
        if (action.callerInfo.direction === "inbound") {
          getPhoneNumbers(0, 100, action.callerInfo.called).then((data) => {
            setPhoneNumberData(data.content);
          });
        }
        setState((state) => {
          return {
            ...state,
            callerInfo: action.callerInfo,
            phoneNumber: action.callerInfo.caller,
            profilePicture: action.callerInfo.profilePicture,
          };
        });
        break;
      case "TASK_TIMEOUT":
      case "TASK_CANCELLED":
        rejectCall();
        break;
      default:
        break;
    }
  };

  // useEffect(() => {
  //   getFromPhone("+14324324534").then((data) => {
  //     console.log("fsdafsadfsadfsdfsfsdafsd", data);
  //   });
  // }, []);

  useEffect(() => {
    if (state.log === "Incoming Call" || state.log === "Calling") {
      setOverflow(true);
      setCallWidgetCB(true);
    } else {
      setOverflow(false);
      setCallWidgetCB(false);
    }
  }, [state.log]);

  const dispatchMessage = () =>
    sendMessage({
      channel: state.attachments ? "MMS" : "SMS",
      toActual: state.phoneNumber.replace(/[^0-9/+]/g, ""),
      content: state.textContent,
      attachments: state.attachments,
    })
      .then((_) => {
        //   updateMessages();
        alertSuccess("Message Sent");
        setState({
          ...state,
          modal: false,
          onPhone: false,
          textContent: TEXT_TEMPLATE,
          notes: "",
          phoneNumber: "",
          log: "Call Ended",
          caller: null,
        });
      })
      .catch((_) => alertError("Error Sending Message"));

  const connect = (_) => {
    setState({ ...state, log: "Connecting" });
    getCapabilityToken()
      .then((data) => {
        device.setup(data.value);
      })
      .catch((err) => {
        setState({ ...state, log: "Could not fetch token, see console.log" });
      });
  };

  const rejectCall = () => {
    try {
      if (device.activeConnection() !== undefined)
        device.activeConnection().reject();
      setState(baseState);
    } catch (e) {
      console.log("Error Rejecting Task Cancel", e);
    }
  };
  useEffect(() => {
    state.caller === null &&
      (state.log === "Calling" || state.log === "Incoming Call") &&
      state.phoneNumber &&
      !state.caller &&
      identifyCaller(state.phoneNumber.replace(/[^0-9/+]/g, ""))
        .then((callerId) => {
          setState((state) => {
            return {
              ...state,
              caller: `${callerId.contactFirstName} ${callerId.contactLastName}`,
              profilePicture: callerId.profilePicture,
              navigatePath: callerId.associateId
                ? `/associates/${callerId.associateId}/messages`
                : callerId.supplierId
                ? `suppliers/${callerId.supplierId}/messages`
                : callerId.clientId
                ? `/commercial/${callerId.clientId}/messages/${callerId.contactId}`
                : `/individual/${callerId.contactId}/messages/${callerId.contactId}`,
            };
          });
        })
        .catch((_err) => {
          setState((state) => {
            return {
              ...state,
              caller: "New Client",
              navigatePath: `/clients/new/RESIDENTIAL/${state.phoneNumber}`,
            };
          });
          alertInfo("New Client");
        });

    if (
      (state.log === "Calling" || state.log === "Incoming Call") &&
      state.navigatePath
    ) {
      if (
        !currentPath.includes(state.navigatePath) &&
        associateCall === false
      ) {
        navigate(state.navigatePath);
      }
    }

    state.caller === null &&
      (state.log === "Calling" || state.log === "Incoming Call") &&
      setRecording(true);
    if (state.log === "Unable to Connect") connect();
  }, [state]);

  useEffect(() => {
    if (activeConnection) {
      console.log("Updated Connection");
    }
  }, [activeConnection]);

  useEffect(() => {
    console.log("Mount Controls");
    // Fetch Twilio capability token from server
    connect();

    // Configure event handlers for Twilio device
    device.on("disconnect", function (e) {
      console.log("Call Disconnected", e);
      callEnded();
      setState((state) => {
        if (state.notes) {
          addNotes({ twilioSID: e.parameters.CallSid, notes: state.notes })
            .then((_) => {
              alertInfo("Call Ended. Saved Notes");
            })
            .catch((_) => {
              alertError("Call Ended. Couldn't Save Notes");
            });
        }
        return {
          ...state,
          onPhone: false,
          notes: "",
          phoneNumber: "",
          log: "Call Ended",
          caller: null,
        };
      });
    });
    device.on("cancel", function (e) {
      console.log("Call Cancelled", e);
      setState((state) => {
        return {
          ...state,
          onPhone: false,
          notes: "",
          phoneNumber: "",
          log: "Call Ended",
          caller: null,
        };
      });
    });

    device.on("ready", function () {
      setState((state) => {
        return { ...state, log: "Connected", caller: undefined };
      });
    });

    device.on("error", function (error) {
      setState({ ...state, log: "Unable to Connect" });
      console.log("Twilio Voice; Connection Lost; ", JSON.stringify(error));
    });

    device.on("connect", function (connection) {
      console.log("Device Connected", connection);
      setState((state) => {
        return { ...state, callSid: connection.parameters["CallSid"] };
      });
      console.log("Call Connected");
    });

    device.on("incoming", function (connection) {
      console.log("Incoming Call", connection);
      setState((state) => {
        return {
          ...state,
          log: "Incoming Call",
          phoneNumber: undefined,
          caller: null,
        };
      });
      setActiveConnection(connection);
    });

    dispatcher.register(handleAction);
    window.dispatcher = dispatcher;

    // Testing static Capability Token
    // device.setup("eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJBQzhkZTkyZTY4Mzc2Mjg0MTkzMzI5Zjg0YjEzNzFhMTMwIiwiZXhwIjoxNTk5Mzg3NDgzLCJzY29wZSI6InNjb3BlOmNsaWVudDppbmNvbWluZz9jbGllbnROYW1lPXJhamFwcGFuIHNjb3BlOmNsaWVudDpvdXRnb2luZz9hcHBTaWQ9QVBmNjQ5NTVkNGJkODcwOTJmZTFhY2YzZGVhYWU2N2NmYSJ9.Gz47g1-jT7BQ7eXgKXe_GB0OWtdfh9UqU0A8UVHiuv4");
  }, []);

  // Handle number input
  const handleChangeNumber = (value) => {
    setState({
      ...state,
      phoneNumber: value,
      isValidNumber: /^\+([0-9]|#|\*)+$/.test(value.replace(/[-()\s]/g, "")),
    });
  };

  // Handle muting
  const handleToggleMute = () => {
    var updatedMuted = !muted;
    setMuted(updatedMuted);
    device.activeConnection().mute(updatedMuted);
  };

  // Handle muting
  const handleToggleRecording = () => {
    recording
      ? pauseRecording({ value: state.callSid })
          .then((_) => setRecording(false))
          .catch((_) => alertError("Couldn't Pause Recording"))
      : resumeRecording({ value: state.callSid })
          .then((_) => setRecording(true))
          .catch((_) => alertError("Couldn't Resume Recording"));
  };

  // Make an outbound call with the current number,
  // or hang up the current call
  const handleToggleCall = (callInfo) => {
    const phoneNumber = callInfo?.number ?? state.phoneNumber;
    const callerId = callInfo?.callerId ?? null;
    const profilePicture = callInfo?.profilePicture ?? null;

    if (!state.onPhone) {
      // make outbound call with current number

      setActiveConnection(
        device.connect({
          number: phoneNumber.replace(/[^0-9/+]/g, ""),
          phoneNumberId: Number(
            JSON.parse(localStorage.getItem("marketingCampaignState"))
              ?.phoneNumberId
          ),
        })
      );

      if (callInfo) {
        getPhoneNumbers(0, 100, callInfo.called || callInfo.number).then(
          (data) => {
            setPhoneNumberData(data.content);
          }
        );
      }
      setState({
        ...state,
        phoneNumber: phoneNumber,
        caller: callerId,
        log: "Calling",
        muted: false,
        onPhone: true,

        profilePicture: profilePicture,
      });
    } else if (!callInfo) {
      // hang up call in progress
      device.disconnectAll();
    }
  };

  const renderControls = () => {
    switch (state.log) {
      case "Incoming Call":
        return (
          <div className="w-full flex flex-col gap-2 items-center text-white">
            <div className="flex flex-row justify-between gap-2 items-center">
              <div>
                <div className="">
                  <button className="flex flex-row bg-newGray-1000 text-white font-semibold py-1 px-2 rounded items-center cursor-default">
                    <Avatar
                      src={state.profilePicture}
                      variant="square"
                      className="h-8 w-8 rounded mx-1"
                    ></Avatar>
                    <div className="flex-col">
                      {state.caller
                        ? previewText(state.caller, 20)
                        : "Identifying"}
                      <div className="py-0.5">
                        <span
                          className="inline-flex items-center rounded px-0.5 py-0.5 text-xs font-medium estimateStatus"
                          style={{
                            color: "rgba(18, 37, 157, 1)",
                            backgroundColor: "rgba(229, 231, 244, 1)",
                          }}
                        >
                          {state.phoneNumber}
                        </span>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              <span>
                <PhoneForwardedIcon />
              </span>
              <div>
                {phoneNumberData.length === 1 && (
                  <div className="">
                    <button className="flex flex-col bg-newGray-1000 text-white font-semibold py-1 px-2 rounded items-center cursor-default">
                      {phoneNumberData[0].friendlyName &&
                        previewText(phoneNumberData[0].friendlyName, 20)}
                      <div className="py-0.5">
                        <span
                          className="inline-flex items-center rounded px-0.5 py-0.5 text-xs font-medium estimateStatus"
                          style={{
                            color: "rgba(18, 37, 157, 1)",
                            backgroundColor: "rgba(229, 231, 244, 1)",
                          }}
                        >
                          {renderPhone(phoneNumberData[0].phone)}
                        </span>
                      </div>
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="flex felx-row gap-4">
              <div>
                <button
                  className="px-2 py-2 rounded mx-auto bg-green-600 items-center text-xs md:text-base flex gap-2 text-white"
                  onClick={(_) => {
                    activeConnection.accept();
                    setState((state) => {
                      return {
                        ...state,
                        onPhone: true,
                        log: "Calling",
                        isIncoming: true,
                      };
                    });
                  }}
                >
                  {
                    <p className="flex gap-1 items-center justify-center">
                      <CallIcon /> <span>Accept Call</span>
                    </p>
                  }
                </button>
              </div>
              <div>
                <button
                  className="py-2 px-2 rounded mx-auto bg-red-600 items-center text-xs md:text-base flex gap-2 text-white"
                  onClick={rejectCall}
                >
                  {
                    <p className="flex gap-1 items-center justify-center">
                      <CallEndIcon /> <span>Reject Call</span>
                    </p>
                  }
                </button>
              </div>
            </div>
          </div>
        );
      case "Connecting...":
        return (
          <p>
            <RouterIcon /> Connecting{" "}
          </p>
        );
      case "Calling":
        return (
          <div className="text-left flex gap-2">
            <div className="flex gap-2 items-center text-white justify-between flex-col ">
              <div className="flex flex-col gap-2 items-center">
                <div className="flex flex-row ">
                  <p> On Call </p>
                </div>

                <div
                  className={
                    (state.isIncoming
                      ? "flex flex-row-reverse "
                      : "flex flex-row ") +
                    ` justify-between gap-2 items-center`
                  }
                >
                  <div>
                    {phoneNumberData.length === 1 && (
                      <div className="">
                        <button className="flex flex-col bg-newGray-1000 text-white font-semibold py-1 px-2 rounded items-center cursor-default">
                          {phoneNumberData[0].friendlyName &&
                            previewText(phoneNumberData[0].friendlyName, 20)}
                          <div className="py-0.5">
                            <span
                              className="inline-flex items-center rounded px-0.5 py-0.5 text-xs font-medium estimateStatus"
                              style={{
                                color: "rgba(18, 37, 157, 1)",
                                backgroundColor: "rgba(229, 231, 244, 1)",
                              }}
                            >
                              {renderPhone(phoneNumberData[0].phone)}
                            </span>
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                  <span>
                    <PhoneForwardedIcon />
                  </span>

                  <div>
                    <div className="">
                      <button className="flex flex-row bg-newGray-1000 text-white font-semibold py-1 px-2 rounded items-center cursor-default">
                        <Avatar
                          src={state.profilePicture}
                          variant="square"
                          className="h-8 w-8 rounded mx-1"
                        ></Avatar>
                        <div className="flex-col">
                          {state.caller
                            ? previewText(state.caller, 20)
                            : "Identifying"}
                          <div className="py-0.5">
                            <span
                              className="inline-flex items-center rounded px-0.5 py-0.5 text-xs font-medium estimateStatus"
                              style={{
                                color: "rgba(18, 37, 157, 1)",
                                backgroundColor: "rgba(229, 231, 244, 1)",
                              }}
                            >
                              {state.phoneNumber}
                            </span>
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-2">
                <div>
                  <button
                    className="px-2 py-2 mx-auto bg-red-600 rounded-lg items-center whitespace-nowrap text-xs md:text-base flex gap-2 text-white"
                    onClick={(_) => {
                      device.disconnectAll();
                    }}
                    disabled={!state.isValidNumber}
                  >
                    <p className="flex items-center justify-center gap-0.5">
                      <CallEndIcon />
                      End Call
                    </p>
                  </button>
                </div>
                <div>
                  <button
                    className="py-2 px-2 bg-red-600 rounded-lg items-center   text-xs md:text-base flex gap-2 text-white"
                    onClick={(_) => {
                      toggleDialpad((showDialpad) => !showDialpad);
                    }}
                  >
                    <Popover
                      open={showDialpad}
                      onClose={(_) => toggleDialpad(false)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                    >
                      <Dialpad twilioDevice={device} />
                    </Popover>
                    <p className="mx-auto">
                      <DialpadIcon />
                    </p>
                  </button>
                </div>
                <div>
                  <button
                    className={
                      `py-2 px-2 rounded-lg items-center text-xs md:text-base flex gap-2 text-white` +
                      (muted ? " bg-red-600" : " bg-green-600")
                    }
                    onClick={(_) => {
                      handleToggleMute();
                    }}
                  >
                    {muted ? (
                      <p className="mx-auto">
                        <MicOffIcon />
                      </p>
                    ) : (
                      <p className="mx-auto">
                        <MicIcon />
                      </p>
                    )}
                  </button>
                </div>
                <div>
                  <button
                    className="py-2 px-2 bg-green-600 rounded-lg items-center flex gap-2 text-white  text-xs"
                    onClick={(_) => {
                      handleToggleRecording();
                    }}
                  >
                    {recording ? (
                      <p className="has-tooltip mx-auto">
                        <span className="tooltip rounded shadow-lg px-1 bg-gray-100 text-gray-700 -mt-8 delay-500  text-xs">
                          Pause Recording
                        </span>
                        <PauseIcon /> Pause
                      </p>
                    ) : (
                      <p className="has-tooltip mx-auto">
                        <span className="tooltip rounded shadow-lg px-1 bg-gray-100 text-gray-700 -mt-8 delay-500  text-xs">
                          Resume Recording
                        </span>
                        <FiberManualRecordIcon /> Resume
                      </p>
                    )}
                  </button>
                </div>
                <div>
                  <PopOverClick
                    className="bg-green-600 text-white cursor-pointer rounded-lg px-2 py-2  text-xs h-10"
                    renderPopOver={(_) => (
                      <textarea
                        value={state.notes}
                        onChange={(e) =>
                          setState({ ...state, notes: e.target.value })
                        }
                      />
                    )}
                  >
                    Notes
                  </PopOverClick>
                </div>
              </div>
            </div>
          </div>
        );
      case "Unable to Connect":
        return (
          <div className="text-left flex">
            <div className="flex gap-2 p-2 w-full justify-between">
              <p>
                <NoSignalIcon /> Unable to Connect{" "}
              </p>
            </div>
            <button
              className="px-2 bg-green-600 rounded-lg items-center w-56 flex gap-2 text-white"
              onClick={(_) => connect()}
              disabled={!state.isValidNumber}
            >
              <p className="mx-auto">
                <ReplayIcon /> Retry{" "}
              </p>
            </button>
          </div>
        );
      default:
        return (
          <div className="flex gap-2 items-center">
            <div
              className="flex gap-2 p-1 px-2 rounded-lg items-center"
              onClick={() => {
                setMobileCallPopUp((current) => !current);
              }}
            >
              <CRMExpandChat />
            </div>
            <ChatIcon
              className={
                "cursor-pointer text-white " +
                (messageReceived && !openChatBox
                  ? "animate-bounce"
                  : "animate-none")
              }
              onClick={(_) => {
                openChat();
              }}
            />
          </div>
        );
    }
  };

  const iconControls = () => {
    switch (state.log) {
      case "Unable to Connect":
        return <CloseIcon style={{ color: "red" }} />;
      case "Calling":
        return <CallIcon />;
      case "Connecting":
        return <CachedIcon style={{ color: "yellow" }} />;
      case "Connected":
        return <DoneIcon style={{ color: "green" }} />;
      case "Incoming Call":
        return <RingVolumeIcon style={{ color: "green" }} />;
      default:
        return "";
    }
  };

  const handleSelectFile = (event) => {
    // console.log("Uploading to S3");
    var files = event.target.files;
    Array.from(files).forEach((file) => {
      // console.log("MIME:" + file.type);
      var reader = new FileReader();
      reader.onload = addEncodedFile(setState, file);
      reader.readAsDataURL(file);
    });
  };

  const onChange = (update) => {
    const { name, value } = update;
    setState((data) => deepUpdate(name, value, data));
  };

  return (
    <div
      className={
        "fixed flex  items-center justify-between w-full py-2 px-2 bottom-0 left-0 " +
        (overflow ? "overflow-x-auto" : "")
      }
      style={{ backgroundColor: "#040055" }}
    >
      <div
        className={
          "text-white flex gap-1 items-center px-2 z-10 " +
          (state.log === "Incoming Call" || state.log === "Calling"
            ? " hidden"
            : "")
        }
      >
        <TaskRouterUI isDesktopResolution={isDesktopResolution} />
      </div>
      {renderControls()}

      {/* TODO: Move Modal out into a separate component */}
      <MaterialModal
        containerClassName="max-w-md w-full"
        className="pb-12"
        open={state.modal ?? false}
        setOpen={(_) =>
          setState({ ...state, textContent: state.textContent, modal: false })
        }
      >
        <div className="flex flex-row py-2">
          <button
            className="bg-gray-200 px-2"
            onClick={() => {
              setMobileCallPopUp(true);
              setState({
                ...state,
                textContent: state.textContent,
                modal: false,
              });
            }}
          >
            <div className="flex flex-row items-center">
              <div>
                <LeftArrowWithTailIcon />
              </div>
              <div className="px-1 text-sm" style={{ color: "#6B6C7B" }}>
                Go back
              </div>
            </div>
          </button>
        </div>
        <MaterialForm
          className="w-full flex flex-col "
          data={state}
          onChange={onChange}
          renderArray={[
            { name: "phoneNumber", inputType: "PHONE" },
            { name: "textContent", inputType: "AREA", variant: "OUTLINED" },
          ]}
        />
        <div className="flex flex-wrap gap-2 py-2">
          {state.attachments?.map((attachment) => (
            <p className="rounded-full bg-gray-300 flex p-2">
              {attachment.fileName}
              <CloseIcon
                className="cursor-pointer"
                onClick={(e) => setState({ ...state, attachments: undefined })}
              />
            </p>
          ))}
        </div>
        <div>
          <span
            className="flex items-center"
            onClick={(_) => fileRef.current.click()}
          >
            <AttachFileIcon className="cursor-pointer" /> Add Attachment
          </span>
          <input
            type="file"
            className="hidden"
            onChange={handleSelectFile}
            ref={fileRef}
            multiple
          />
        </div>
        <Button
          color="primary"
          className="float-right"
          onClick={dispatchMessage}
        >
          Send
        </Button>
        <Button
          style={{ color: "red" }}
          // color="danger"
          className="float-right"
          onClick={(_) => {
            setState({
              ...state,
              phoneNumber: "",
              textContent: "",
            });
          }}
        >
          Clear
        </Button>
      </MaterialModal>
      <MaterialModal
        containerClassName="max-w-md w-full"
        open={mobileCallPopup}
        setOpen={(_) => setMobileCallPopUp(false)}
      >
        <div className="pb-4">
          <Typography variant="h5">Quick Call</Typography>
        </div>
        <div className="py-4">
          <MarketingCampaign
            classes={"bg-gray-200"}
            stateChangeCB={(selected) => {}}
          />
        </div>

        {!state.onPhone && (
          <>
            <MaterialUiPhoneNumber
              id="outlined-basic"
              placeholder="Enter Phone Number"
              defaultCountry={"us"}
              value={state.phoneNumber}
              onChange={handleChangeNumber}
              fullWidth
            />
            <div className="flex flex-row justify-end px-4 py-4">
              <div className="px-2">
                <button
                  onClick={(_) => {
                    setMobileCallPopUp(false);
                    handleToggleCall();
                  }}
                  disabled={!state.isValidNumber}
                >
                  <CallIcon />
                </button>
              </div>
              <div className="px-2">
                <button
                  onClick={(_) => {
                    setMobileCallPopUp(false);

                    setState({
                      ...state,
                      textContent: state.textContent ?? TEXT_TEMPLATE,
                      modal: true,
                    });
                  }}
                  disabled={!state.isValidNumber}
                >
                  <SMSIcon />
                </button>
              </div>
            </div>
          </>
        )}
      </MaterialModal>
    </div>
  );
}
