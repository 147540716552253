import { atom } from "recoil";

export type ConfirmationDialogProps = {
  open: boolean;
  title: string;
  description: string;
  onConfirm: () => void;
  onClose?: () => void;
};

export type GlobalComponentState = {
  ticketId: number | null;
  invoiceId: number |  null;
  srId: number | null;
  contactId: number | null;
  confirmationDialog?: ConfirmationDialogProps;
  ticketCallback?: () => void;
  createSimpleTicket?: boolean;
  contactData?: any;
  contactSuccessCB?: () => void;
  managerAccessData?: any;
  selectFromChoices?: {
    title: string;
    description: string;
    choices: { label: string; value?: any; callback?: () => void }[];
    callback: (value: any) => void;
  };
  quickMessage?: {
    contacts: any[];
    contactId?: number;
    clientId: number;
  };
};

const initialState: GlobalComponentState = {
  ticketId: null,
  invoiceId: null,
  srId: null,
  contactId: null,


  confirmationDialog: {
    open: false,
    title: "",
    description: "",
    onConfirm: () => {},
  },
};

const globalComponentStateAtom = atom<GlobalComponentState>({
  key: "globalComponentState",
  default: initialState,
});

export { globalComponentStateAtom };
