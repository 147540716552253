import { useEffect } from "react";

import { Link, navigate } from "raviger";

import { alertInfo } from "../actions/AlertActions";

export function ValidLink({
  href,
  className,
  children,
}: {
  href: string;
  className?: string;
  children: React.ReactNode;
}) {
  if (href.includes("undefined") || href.includes("null")) {
    return (
      <Link
        href="#"
        className={className}
        onClick={() => {
          alertInfo("This feature is currently unavailable");
        }}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <Link href={href} className={className}>
        {children}
      </Link>
    );
  }
}

export function CallbackLink({
  href,
  className,
  callback,
  children,
}: {
  href?: string;
  className?: string;
  callback: () => void;
  children: React.ReactNode;
}) {

  const LinkComponent = (props: any) => {
    return props.href ? 
      <a href={props.href} className={props.className} onClick={props.onClick}>
        {props.children}
      </a> :
      <button className={props.className} onClick={props.onClick}>
        {props.children}
      </button>
  }
  return (
    <LinkComponent
      href={href}
      className={className}
      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        callback();
        navigate(href);
      }}
    >
      {children}
    </LinkComponent>
  );
}

export function useTitle(title: string) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title;
    return () => {
      document.title = prevTitle;
    };
  }, [title]);
}
