const DEFAULT_PAGE_SIZE = 20;

export const makePageable = (
  data,
  currentPage,
  next = false,
  size = DEFAULT_PAGE_SIZE
) => {
  return {
    ...data,
    pageable: next
      ? { page: currentPage + 1, size: size }
      : { page: 0, size: size },
  };
};
