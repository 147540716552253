import { useEffect, useState } from "react";

import { Avatar } from "@mui/material";
import { Link } from "raviger";

import { alertError, alertSuccess } from "../../../actions/AlertActions";
import { useAppActions } from "../../../actions/AppActions";
import { fireManager } from "../../../api/Api";
import {
  HomeIcon,
  MessageIcon,
  ThreeDotsIcon,
} from "../../../components/clients/ClientAppIcons";
import HeadlessDropdown from "../../../components/common/HeadlessDropdown";
import { filterUnique } from "../../../utils/ModelUtils";
import { ValidLink } from "../../../utils/RouterUtils";
import { renderAddressUnitNumber } from "../../../utils/StringUtils";

export const ClientManagerItem = ({ manager, refreshCB, isHOA }) => {
  const { editContact, editManagerAccess, withConfirmation, quickMessage } =
    useAppActions();
  const [seeAllToggle, setSeeAllToggle] = useState(false);
  const [sliceList, setSliceList] = useState([]);

  const buildingList = manager.buildings;

  useEffect(() => {
    //
    if (buildingList.length > 1) setSeeAllToggle(true);

    setSliceList(buildingList.slice(0, 1));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manager]);

  const onSeeAllClick = () => {
    setSeeAllToggle(false);
    setSliceList(buildingList);
  };
  const onHideClick = () => {
    setSeeAllToggle(true);
    setSliceList(buildingList.slice(0, 1));
  };

  const MenuButton = ({ className }) => {
    return (
      <div className={className}>
        <HeadlessDropdown
          value={"Options"}
          options={["Add/Remove Units", "Edit", "Delete/Fire"]}
          onChange={(value) => {
            if (value === "Add/Remove Units") {
              editManagerAccess({
                manager: manager,
                clientId: manager.managerOfClientId,
                callback: () => {
                  refreshCB();
                },
              });
            } else if (value === "Edit") {
              editContact(
                {
                  contactId: manager.contactId,
                  firstName: manager.firstName,
                  lastName: manager.lastName,
                  email: manager.email,
                  phone: manager.phone,
                  profilePicture: manager.profilePicture,
                },
                (_) => {
                  refreshCB();
                }
              );
            } else if (value === "Delete/Fire") {
              withConfirmation({
                title: "Fire Manager",
                description:
                  "Are you sure you want to fire this manager? This action cannot be undone.",
                onConfirm: () => {
                  fireManager(manager.managerOfClientId, manager.contactId)
                    .then((_res) => {
                      alertSuccess(
                        "The manager has been removed from the Client"
                      );
                      refreshCB();
                    })
                    .catch((_err) => {
                      alertError(
                        "Sorry, we couldn't remove the manager from the Client"
                      );
                    });
                },
              });
            }
          }}
        >
          <div className="px-2 py-2 bg-gray-300 hover:bg-gray-400 rounded">
            <ThreeDotsIcon />
          </div>
        </HeadlessDropdown>
      </div>
    );
  };

  return (
    <div className={"flex flex-row items-center"}>
      <div>
        <div
          className={
            "flex flex-col md:flex-row items-center justify-between px-4 py-2"
          }
        >
          <div className="flex flex-col md:flex-row items-start gap-2">
            <div className="flex flex-row items-center gap-2">
              <Avatar
                src={manager.profilePicture}
                variant="square"
                className="h-8 w-8 rounded"
              ></Avatar>
              <div className="font-medium">{`${manager.firstName} ${manager.lastName}`}</div>

              {/* `/commercial/${manager.managerOfClientId}/messages/${manager.contactId}` */}
              <button
                onClick={() => {
                  quickMessage({
                    contacts: [manager],
                    contactId: manager.contactId,
                    clientId: manager.managerOfClientId,
                  });
                }}
                className="px-1 py-1"
              >
                <MessageIcon className="h-8 w-8 bg-white rounded-md hover:bg-indigo-800 hover:text-white" />
              </button>
            </div>

            {!isHOA && (
              <div className="items-center">
                <span className=" bg-newBlue-500 inline-flex px-2 py-2 text-sm font-medium rounded">
                  <div className="flex flex-row items-center gap-2">
                    <div>
                      <HomeIcon />
                    </div>
                    <div className="text-newBlue-400 flex flex-wrap gap-1">
                      Managed
                      <span className="whitespace-nowrap">
                        {" "}
                        Properties:{" "}
                        {manager.buildings
                          ? manager.buildings.filter(filterUnique("buildingId"))
                              .length
                          : 0}
                      </span>
                    </div>
                  </div>
                </span>
              </div>
            )}
            {seeAllToggle && (
              <button
                className="text-newBlue-400 text-xs underline italic"
                onClick={onSeeAllClick}
              >
                Show All
              </button>
            )}
            {buildingList.length > 1 && !seeAllToggle && (
              <button
                className="text-newBlue-400 text-xs underline italic"
                onClick={onHideClick}
              >
                Hide
              </button>
            )}
          </div>

          <div className="rounded flex flex-col max-h-32 overflow-y-auto">
            {sliceList?.map((building) => (
              <div className="p-2  rounded bg-newGray-1100 flex flex-row justify-between items-center">
                <div className="flex flex-row items-center">
                  <div className="bg-newBlue-500 p-2 rounded">
                    <HomeIcon />
                  </div>
                  <div className="px-2">
                    <span className="text-newBlue-600">
                      {renderAddressUnitNumber(building.address)}
                    </span>
                  </div>
                </div>
                {/* ATTENTION: THIS MIGHT BE WHAT YOU'RE LOOKING FOR; TENDANCY TO BREAK THINGS */}
                {building.hiredBy ? (
                  <Link
                    href={`/individual/${building.hiredBy.contactId}/buildings`}
                    className="flex flex-row items-center bg-newBlue-500 text-newBlue-400 px-2 py-1"
                  >
                    Hired By: {building.hiredBy.firstName}{" "}
                    {building.hiredBy.lastName}
                  </Link>
                ) : (
                  building.hoa &&
                  building.hoa.clientId !== manager.managerOfClientId && (
                    <Link
                      href={`/commercial/${building.hoa.clientId}/summary`}
                      className="flex flex-row items-center bg-newBlue-500 text-newBlue-400 px-2 py-1"
                    >
                      Hired By: {building.hoa.clientName}
                    </Link>
                  )
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <MenuButton className="px-2" />
    </div>
  );
};
