import React, { useState } from "react";

// import { CheckIcon, CrossIcon2, EditIcon } from "./AppIcons";
import { Tick } from "../common/AppIcons";
import { capitalizeFirstLetter } from "../common/ProgressSteps";

export default function TaskTicker({
  tasks,
  last_finished,
  change_appointment_status,
  editPrevilage,
  className,
}) {
  const [hover_on, set_hover_on] = useState();

  const task_finished = (task) => {
    let flag = false;

    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i] === task) {
        return flag ? false : true;
      }
      if (tasks[i] === last_finished) flag = true;
    }
    return tasks.slice(0, tasks.indexOf(last_finished) + 1)?.includes(task);
  };

  const show_accept_appointment_button = () => {
    return editPrevilage && last_finished === "ASSIGNED";
  };
  return (
    <div
      className={
        "md:mx-auto flex flex-col md:flex-row items-start md:items-center px-4 pb-8 " +
        className
      }
    >
      {tasks.map((task, task_index) => {
        const editable =
          editPrevilage &&
          ((task === "ENROUTE" && !task_finished(task)) ||
            (task === "STARTED" && last_finished !== "FINISHED") ||
            (task === "FINISHED" && last_finished !== "FINISHED") ||
            (task === "PICKED_UP" &&
              !["DELIVERED", "FINISHED", "STARTED"].includes(last_finished)) ||
            task === "DELIVERED");

        if (
          (!show_accept_appointment_button() && last_finished !== "REJECTED") ||
          task === "ASSIGNED"
        )
          return (
            <div
              key={task_index}
              className="flex flex-col md:flex-row items-center"
            >
              {task_index !== 0 && (
                <div
                  className={
                    "h-12 md:h-px md:w-10 lg:w-16 " +
                    (task_finished(task)
                      ? "bg-green-600 w-1"
                      : "bg-gray-300 w-px")
                  }
                >
                  &nbsp;
                </div>
              )}
              <div
                className={
                  "p-2 relative flex flex-row md:flex-col gap-2 items-center rounded-full duration-200 justify-center " +
                  (task_finished(task)
                    ? "bg-green-600 text-white h-8 md:h-10 w-8 md:w-10"
                    : "border border-gray-500") +
                  " " +
                  (editable ? "hover:opacity-60 cursor-pointer" : "")
                }
                onClick={() => {
                  if (editable) change_appointment_status(task);
                }}
              >
                {task_finished(task) ? (
                  <Tick />
                ) : (
                  // <CheckIcon
                  //   style={{ width: "16px" }}
                  //   className="m-px md:m-1 h-4 w-4 text-white"
                  // />
                  <div className="h-3 md:h-4 w-3 md:w-4 rounded-full bg-gray-500" />
                )}
                <div
                  className={
                    "absolute transform translate-x-10 md:translate-x-0 md:translate-y-9 flex flex-row md:flex-col gap-x-3 items-center ml-7 md:ml-0"
                  }
                >
                  <span
                    className={
                      "text-sm whitespace-pre " +
                      (task_finished(task) ? "text-gray-700" : "text-gray-600")
                    }
                  >
                    {capitalizeFirstLetter(task)}
                  </span>
                  {editable && (
                    <button className="text-indigo-500 flex flex-row items-center gap-1 text-sm">
                      {/* <EditIcon style={{ height: "12px" }} /> Update */}
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
      })}

      {last_finished === "REJECTED" && (
        <div className="flex flex-row items-center">
          <div className={"h-px w-10 md:w-16 lg:w-20 xl:w-24 bg-gray-400"} />

          <div
            className={
              "p-2 relative flex flex-col justify-center items-center rounded-full duration-200 bg-red-500 text-white h-10 w-10"
            }
          >
            {/* <CrossIcon2
              style={{ width: "16px" }}
              className="h-4 w-4 text-white"
            /> */}

            <span
              className={
                "absolute transform translate-y-9 text-sm text-gray-700"
              }
            >
              Rejected
            </span>
          </div>
        </div>
      )}

      {show_accept_appointment_button() && (
        <div className="flex flex-row items-center">
          <div className={"h-px w-10 md:w-16 lg:w-20 xl:w-24 bg-gray-300"} />
          <div className="flex flex-row items-center w-48">
            <button
              onMouseEnter={() => set_hover_on("ACCEPTED")}
              onMouseLeave={() => set_hover_on()}
              onClick={() => {
                change_appointment_status("ACCEPTED");
              }}
              className={
                "bg-green-500 border border-green-500 rounded-l-lg py-2 font-semibold text-white hover:opacity-80 transform-all duration-300 overflow-hidden w-24 " +
                (hover_on === "ACCEPTED" ? "w-48 " : " ") +
                (hover_on === "REJECTED" ? "w-0 " : "")
              }
            >
              Accept
            </button>
            <button
              onMouseEnter={() => set_hover_on("REJECTED")}
              onMouseLeave={() => set_hover_on()}
              onClick={() => {
                change_appointment_status("REJECTED");
              }}
              className={
                "overflow-hidden text-red-500 border-r border-t border-b border-red-500 rounded-r-lg py-2 font-semibold text-white hover:opacity-70 duration-300 w-24 " +
                (hover_on === "REJECTED" ? "w-48 " : " ") +
                (hover_on === "ACCEPTED" ? "w-0 " : "")
              }
            >
              Reject
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
