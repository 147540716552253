import React, { useEffect, useState } from "react";

import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import { Button, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { navigate } from "raviger";

import { alertError } from "../../actions/AlertActions";
import { createTPV, getThirdPartyVendors } from "../../api/Api";
import states from "../../assets/states";
import { deepUpdate } from "../../utils/StateUtils";
import { renderAddressWithoutCity } from "../../utils/StringUtils";
import MaterialModal from "../common/MaterialModal";
import MaterialTable from "../common/MaterialTable";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";

const formTPV = (data) => [
  { name: "companyName" },
  {
    name: "address",
    inputType: "ADDRESS",
    variant: "STANDARD",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid \${theme.palette.divider}`,
  },
}));

export default function ListVendors() {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);

  const [newTPV, setNew] = useState(false);
  const [forUpdate, setForUpdate] = useState();

  const fetchThirdPartyVendors = () => {
    getThirdPartyVendors()
      .then((data) => {
        setVendors(data);
        setLoading(false);
      })
      .catch((error) => {
        alertError("Error getting Associates");
        setLoading(false);
      });
  };

  const onChange = (update) => {
    let { name, value } = update;

    setForUpdate((data) => deepUpdate(name, value, data));
  };

  const handleSubmit = () => {
    createTPV(forUpdate)
      .then(() => {
        fetchThirdPartyVendors();
        setForUpdate();
      })
      .catch((error) => {
        alertError("Error creating third party vendor");
      });
  };

  useEffect(() => {
    fetchThirdPartyVendors();
  }, []);

  return (
    <>
      <div
        className={
          classes.root +
          " max-w-6xl mx-auto w-full gap-2 flex-col p-4 rounded-md"
        }
      >
        <div className="flex flex-row items-center justify-between">
          <Typography className="p-2 text-gray-800" variant="h4">
            Third Party Companies
          </Typography>
          <div onClick={(_) => navigate("/associates")}>
            <Button
              style={{
                "text-transform": "none",
              }}
              color="black"
            >
              {" "}
              Associates
            </Button>
          </div>
        </div>
        <div className="flex items-center gap-2 cursor-pointer">
          <TextField
            id="search"
            className="flex-1"
            label="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            variant="outlined"
          />
          <div
            onClick={(_) => {
              setNew(true);
              setForUpdate({});
            }}
          >
            <AddCircleOutlineOutlined /> New Third Party Company
          </div>
        </div>
        <MaterialTable
          loading={loading}
          data={vendors?.map((vendor) => {
            return {
              id: vendor.id,
              data: [
                vendor.companyName,
                renderAddressWithoutCity(vendor.address),
                vendor.address.city,
              ],
            };
          })}
          head={[
            { id: "companyName", label: "Company Name" },
            { id: "address", label: "Address" },
            { id: "city", label: "City" },
          ]}
          defaultOrderBy={"firstName"}
          onClickCB={
            (vendorId) => {
              console.log("vendor detected", vendorId);
              getThirdPartyVendors(vendorId).then((data) => {
                setForUpdate(data);
              });
            }
            // navigate("/associates/" + associate + "/details")
          }
          searchText={searchText}
        />
      </div>
      {forUpdate && (
        <MaterialModal
          open={forUpdate ? true : false}
          setOpen={(_) => {
            setNew(false);
            setForUpdate();
          }}
          label="new-user-modal"
          describedby="create-new-user"
        >
          <Typography className="p-2 text-gray-800" variant="h6">
            {newTPV ? "New Third Party Company" : forUpdate.companyName}
          </Typography>
          <MaterialForm
            data={forUpdate}
            renderArray={formTPV(forUpdate)}
            onSubmit={handleSubmit}
            onChange={onChange}
          />
          <Button
            onClick={handleSubmit}
            color="primary"
            className="float-right p-4"
          >
            {newTPV ? "Create" : "Save"}
          </Button>
        </MaterialModal>
      )}
    </>
  );
}
