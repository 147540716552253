import { React } from "react";

import { Tooltip } from "@mui/material";

import { PersonWithIcon } from "../../common/AppIcons";
import { HOAIcon, PMCIcon } from "../ClientAppIcons";

export default function CommercialTypeSelection({ onChange, onNext }) {
  return (
    <div className="flex flex-col gap-4 items-center py-4">
      <div>
        <span className="font-medium">
          Choose an Option that best describes this Client
        </span>
      </div>
      <div className="flex flex-col sm:flex-row gap-4 py-4">
        {[
          {
            value: "PROPERTY_MANAGEMENT",
            label: "Property Management",
            tooltip: "Property Management Company",
            icon: PMCIcon,
          },
          {
            value: "HOA",
            label: "Home Owner Association",
            tooltip: "Home Owner Association",
            icon: HOAIcon,
          },
          {
            value: "BUILDING_OWNER",
            label: "Landlord",
            tooltip: "Building Owner",
            icon: PersonWithIcon,
          },
        ].map((option) => (
          <Tooltip enterDelay={100} title={option.tooltip}>
            <div
              className="p-10 bg-gray-200 rounded cursor-pointer hover:bg-gray-300"
              onClick={() => {
                onChange(option.value);
                onNext();
              }}
            >
              <div className="flex flex-col items-center gap-4 cursor-pointer w-24">
                <div>
                  <option.icon className="h-10 w-10" />
                </div>
                <span className="whitespace-pre-wrap text-center">
                  {option.label}
                </span>
              </div>
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
}
