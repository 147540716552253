import { useEffect, useState } from "react";

import { alertError } from "../actions/AlertActions";
import { getBuilding, getMyVendor } from "../api/Api";
import {
  // handymanSubCategory,
  jobTypes,
  ticketStatus,
} from "../components/common/form/constants";
import { getSubCategories } from "../components/service/CreateSR";
import { getDateStep30 } from "../utils/DateUtils";
import { conditionalArrayEntry } from "../utils/ObjectUtills";
import { properStrings } from "../utils/StringUtils";
import { properString } from "../utils/StringUtils";
import { useMaterials } from "./useMaterials";
import usePrevious from "./usePrevious";

const defaultDate = getDateStep30(new Date());

export function useFormServiceRequest({
  data,
  buildings,
  setData,
  childServiceRequest,
  mediaUrls = [],
}) {
  const [contacts, setContacts] = useState([]);
  const previousValue = usePrevious(data);
  const useFields = (data) => useMaterials(data);
  const [skillData, setSkillData] = useState([]);

  useEffect(() => {
    if (previousValue && data.jobType !== previousValue.jobType) {
      if (data.jobType !== "HANDYMAN" && data.subCategory === "") {
        setData((data) => ({ ...data, subCategory: "" }));
      }
    }
  }, [data.jobType]);

  useEffect(() => {
    if (previousValue && data.buildingId !== previousValue.building) {
      if (data.buildingId)
        getBuilding(data.buildingId)
          .then((building) => {
            setContacts(building.contacts);
          })
          .catch((err) => {
            alertError("Couldn't Fetch Select Building");
          });
      else setContacts([]);
    }
  }, [data.buildingId]);

  useEffect(() => {
    const contact = contacts.find((c) => c.contactId === data.contactId);
    if (!contact) {
      setData((data) => ({ ...data, contactId: undefined }));
    }
  }, [contacts]);

  useEffect(() => {
    getMyVendor()
      .then((data) => {
        setSkillData(data.categories);
      })
      .catch(() => alertError("Error while fetching vendor"));
  }, []);

  useEffect(() => {
    if (data.contactId && contacts.length > 0) {
      const dataContact = contacts.find(
        (contact) => data.contactId === contact.contactId
      );
      if (
        dataContact &&
        dataContact.unitNumber &&
        dataContact.unitNumber !== data.unitNumber
      ) {
        // setData({ ...data, unitNumber: dataContact.unitNumber });
      }
    }
  }, [data.contactId, contacts]);

  const getSkills = () => {
    return skillData.map((item) => {
      return { label: properString(item.skill), value: item.skill };
    });
  };

  return [
    // ...conditionalArrayEntry(buildings && !childServiceRequest, {
    //   name: "buildingId",
    //   inputType: "SELECT",
    //   options: buildings.map((building) => {
    //     return {
    //       label: renderAddress(building.address),
    //       value: building.buildingId,
    //     };
    //   }),
    // }),

    {
      name: "jobType",
      inputType: "SELECT",
      options: getSkills().length ? getSkills() : jobTypes,
    },
    ...conditionalArrayEntry(
      data.jobType !== "INSPECTION" && data.jobType !== "SITE_VISIT",
      {
        name: "subCategory",
        inputType: "SELECT",
        options: getSubCategories(skillData, data.jobType) ?? [],
      }
    ),
    {
      name: "preferredDateTime",
      inputType: "DATETIME",
      variant: "STEP30",
      defaultValue: defaultDate,
    },
    ...conditionalArrayEntry(data.buildingId && contacts.length > 0, {
      name: "contactId",
      inputType: "SELECT",
      options: contacts.map((contact) => {
        return {
          label: properStrings([
            contact.firstName,
            contact.lastName,
            contact.unitNumber ?? "",
          ]),
          value: contact.contactId,
        };
      }),
    }),
    { name: "duration", inputType: "NUMBER", variant: "DECIMAL5" },
    { name: "description", inputType: "AREA", variant: "OUTLINED" },
    {
      name: "priority",
      inputType: "SELECT",
      defaultValue: "false",
      options: [
        { label: "High Priority", value: "true" },
        { label: "Normal", value: "false" },
      ],
    },
    {
      name: "materials",
      inputType: "CHILDFORM",
      variant: "name",
      renderFields: useFields,
    },
    {
      name: "mediaUrls",
      inputType: "GALLERY",
      options: mediaUrls,
    },
    {
      name: "bookingStatus",
      inputType: "SELECT",
      defaultValue: "CREATED",
      options: ticketStatus,
    },
  ];
}
