import React, { useEffect } from "react";

import TextField from "@mui/material/TextField";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import { getDateStep30 } from "../../../../utils/DateUtils";
import { deepFind } from "../../../../utils/StateUtils";

export default function DateTime({
  variant,
  data,
  name,
  label,
  onChange,
  optional,
  defaultValue,
}) {
  const value =
    (deepFind(name, data) ||
      getDateStep30(
        defaultValue,
        variant?.includes("DISABLEPAST") ? true : false
      )) ??
    getDateStep30(new Date(), variant?.includes("DISABLEPAST") ? true : false);
  // console.log("find: " + deepFind(name, data) + "; default:" + getDateStep30(defaultValue, variant?.includes("DISABLEPAST") ? true : false) + "; current:" + getDateStep30(new Date(), variant?.includes("DISABLEPAST") ? true : false) )
  useEffect(() => {
    value === defaultValue && onChange({ name, value });
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <DateTimePicker
        label={label + (optional ? " (Optional)" : "")}
        value={value}
        onChange={(value) => onChange({ name, value })}
        renderInput={(params) => <TextField {...params} />}
      /> */}
      <MobileDateTimePicker
        id="outlined-basic"
        label={label + (optional ? " (Optional)" : "")}
        variant="standard"
        // variant={variant?.includes("OUTLINED") ? "outlined" : "standard"}
        placeholder={label}
        name={name}
        disablePast={variant?.includes("DISABLEPAST") ? true : false}
        minutesStep={variant?.includes("STEP30") ? 30 : 1}
        value={value}
        maxTime={dayjs().set("hour", 20)}
        minTime={dayjs().set("hour", 5)}
        onChange={(value) => onChange({ name, value })}
        InputLabelProps={{
          shrink: true,
        }}
        renderInput={(params) => <TextField variant="standard" {...params} />}
        fullWidth
      />
    </LocalizationProvider>
  );
}
