import { useState, useEffect, useRef } from "react";

import {
  format,
  subMonths, // addMonths,
  // subYears,
  // addYears,
  // isEqual,
  getDaysInMonth,
  getDay,
} from "date-fns";
import moment from "moment";
import useOnClickOutside from "use-onclickoutside";

import { LeftArrow, RightArrow } from "../common/AppIcons";

type DatepickerType = "date" | "month" | "year";

export default function ArrowedDatePicker({
  mode,
  date,
  onDateChange,
  arrowCB,
}) {
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setShowDatepicker(false);
  });

  const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [dayCount, setDayCount] = useState([]);
  const [blankDays, setBlankDays] = useState([]);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [datepickerHeaderDate, setDatepickerHeaderDate] = useState(
    new Date(date)
  );
  const [selectedDate, setSelectedDate] = useState(new Date(date));
  const [type, setType] = useState("date");

  useEffect(() => {
    setSelectedDate(new Date(date));
    setDatepickerHeaderDate(new Date(date));
  }, [date]);

  const decrement = (modeType) => {
    switch (modeType ? modeType : type) {
      case "date":
        {
          let newDate = moment(selectedDate).subtract(1, "days");
          onDateChange(newDate);
          arrowCB(newDate, mode);
        }
        break;
      case "month":
        {
          let newDate = moment(selectedDate).subtract(1, "months");

          onDateChange(new Date(newDate.format("YYYY-MM-DD HH:mm:ss")));
          setDatepickerHeaderDate(
            new Date(newDate.format("YYYY-MM-DD HH:mm:ss"))
          );

          arrowCB(newDate, mode);
        }
        // setDatepickerHeaderDate((prev) => subYears(prev, 1));
        break;
      case "year":
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
      default:
        break;
    }
  };

  const increment = (modeType) => {
    switch (modeType ? modeType : type) {
      case "date":
        {
          let newDate = moment(selectedDate).add(1, "days");
          onDateChange(newDate);
          arrowCB(newDate, mode);
        }
        break;
      case "month":
        {
          let newDate = moment(selectedDate).add(1, "months");

          onDateChange(new Date(newDate.format("YYYY-MM-DD HH:mm:ss")));
          setDatepickerHeaderDate(
            new Date(newDate.format("YYYY-MM-DD HH:mm:ss"))
          );

          arrowCB(newDate, mode);
        }
        break;
      case "year":
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
      default:
        break;
    }
  };

  const isToday = (date) => {
    let formulatedDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      date
    );

    return moment(formulatedDate).isSame(selectedDate, "day");
  };

  const setDateValue = (date) => () => {
    let fullDate =
      datepickerHeaderDate.getFullYear() +
      "-" +
      (datepickerHeaderDate.getMonth() + 1) +
      "-" +
      date;
    if (onDateChange) {
      onDateChange(moment(fullDate, "YYYY-MM-DD"));
    }

    onDateChange(
      new Date(
        datepickerHeaderDate.getFullYear(),
        datepickerHeaderDate.getMonth(),
        date
      )
    );
    setShowDatepicker(false);
  };

  const getDayCount = (date) => {
    let daysInMonth = getDaysInMonth(date);

    // find where to start calendar day of week
    let dayOfWeek = getDay(new Date(date.getFullYear(), date.getMonth(), 1));
    let blankdaysArray = [];
    for (let i = 1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    let daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    setBlankDays(blankdaysArray);
    setDayCount(daysArray);
  };

  const isSelectedMonth = (month) => {
    return moment(
      new Date(selectedDate.getFullYear(), month, selectedDate.getDate())
    ).isSame(selectedDate, "month");
  };

  const setMonthValue = (month) => () => {
    setDatepickerHeaderDate(
      new Date(
        datepickerHeaderDate.getFullYear(),
        month,
        datepickerHeaderDate.getDate()
      )
    );
    setType("date");
  };

  const toggleDatepicker = () => setShowDatepicker((prev) => !prev);

  const showMonthPicker = () => setType("month");

  const showYearPicker = () => setType("date");

  useEffect(() => {
    getDayCount(datepickerHeaderDate);
  }, [datepickerHeaderDate]);

  return (
    <div className="antialiased sans-serif" ref={ref}>
      <div>
        <div className="container mx-auto ">
          <div className="">
            <div className="relative w-full flex items-center">
              <div
                tabIndex={0}
                className="flex flex-row items-center border border-newGray-200 focus:border-blue-500 rounded shadow-sm px-1 py-1"
              >
                <div
                  className="cursor-pointer px-1 py-1"
                  onClick={() => {
                    decrement();
                  }}
                >
                  <LeftArrow />
                </div>
                <input type="hidden" name="date" />
                <input
                  type="text"
                  readOnly
                  className="cursor-pointer w-24 leading-none rounded text-black font-normal text-center border-none outline-none"
                  placeholder="Select date"
                  value={moment(selectedDate).format("DD MMMM")}
                  onClick={toggleDatepicker}
                />
                <div
                  className="cursor-pointer px-1 py-1"
                  onClick={() => {
                    increment();
                  }}
                >
                  <RightArrow />
                </div>
              </div>
              {showDatepicker && (
                <div
                  className="bg-white mt-12 rounded-lg shadow p-4 absolute top-0 left-0 z-20"
                  style={{ width: "17rem" }}
                >
                  <div className="flex justify-between items-center mb-2">
                    <div>
                      <button
                        type="button"
                        className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                        onClick={() => {
                          decrement("month");
                        }}
                      >
                        <svg
                          className="h-6 w-6 text-gray-500 inline-flex"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 19l-7-7 7-7"
                          />
                        </svg>
                      </button>
                    </div>
                    {type === "date" && (
                      <div
                        onClick={showMonthPicker}
                        className="flex-grow p-1 text-lg font-bold text-gray-800 cursor-pointer hover:bg-gray-200 rounded-lg"
                      >
                        <p className="text-center">
                          {format(datepickerHeaderDate, "MMMM")}
                        </p>
                      </div>
                    )}
                    <div
                      onClick={showYearPicker}
                      className="flex-grow p-1 text-lg font-bold text-gray-800 cursor-pointer hover:bg-gray-200 rounded-lg"
                    >
                      <p className="text-center">
                        {format(datepickerHeaderDate, "yyyy")}
                      </p>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                        onClick={() => {
                          increment("month");
                        }}
                      >
                        <svg
                          className="h-6 w-6 text-gray-500 inline-flex"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {type === "date" && (
                    <>
                      <div className="flex flex-wrap mb-3 -mx-1">
                        {DAYS.map((day, i) => (
                          <div
                            key={i}
                            style={{ width: "14.26%" }}
                            className="px-1"
                          >
                            <div className="text-gray-800 font-medium text-center text-xs">
                              {day}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="flex flex-wrap -mx-1">
                        {blankDays.map((_, i) => (
                          <div
                            key={i}
                            style={{ width: "14.26%" }}
                            className="text-center border p-1 border-transparent text-sm"
                          ></div>
                        ))}
                        {dayCount.map((d, i) => (
                          <div
                            key={i}
                            style={{ width: "14.26%" }}
                            className="px-1 mb-1"
                          >
                            <div
                              onClick={setDateValue(d)}
                              className={`cursor-pointer text-center text-sm leading-none rounded-full leading-loose transition ease-in-out duration-100 ${
                                isToday(d)
                                  ? "bg-blue-500 text-white"
                                  : "text-gray-700 hover:bg-blue-200"
                              }`}
                            >
                              {d}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {type === "month" && (
                    <div className="flex flex-wrap -mx-1">
                      {Array(12)
                        .fill(null)
                        .map((_, i) => (
                          <div
                            key={i}
                            onClick={setMonthValue(i)}
                            style={{ width: "25%" }}
                          >
                            <div
                              className={`cursor-pointer p-5 font-semibold text-center text-sm rounded-lg hover:bg-gray-200 ${
                                isSelectedMonth(i)
                                  ? "bg-blue-500 text-white"
                                  : "text-gray-700 hover:bg-blue-200"
                              }`}
                            >
                              {format(
                                new Date(
                                  datepickerHeaderDate.getFullYear(),
                                  i,
                                  datepickerHeaderDate.getDate()
                                ),
                                "MMM"
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  )}{" "}
                  {/* {type === "year" && (
                      <Datepicker
                        datepickerHeaderDate={datepickerHeaderDate}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        closeDatepicker={() => setShowDatepicker(false)}
                      />
                    )} */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
