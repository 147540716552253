import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

type StripeCheckoutProps = {
  accountId: string;
  clientSecret: string;
};

export default function StripeCheckout({
  accountId,
  clientSecret,
}: StripeCheckoutProps) {
  const options = {
    clientSecret: clientSecret,
  };
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? "",
    {
      stripeAccount: accountId,
    }
  );

  return (
    <div className="md:w-1200 p-4">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}
