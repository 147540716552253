import { useEffect, useState } from "react";

import moment from "moment";

import { getPayments } from "../../api/Api";
import { properString } from "../../utils/StringUtils";
import MaterialModal from "../common/MaterialModal";
import MaterialTable from "../common/MaterialTable";
import StripePaymentDetails from "./StripePaymentDetails";

type PaymentsListProps = {
  invoiceId: number;
};

type Payment = {
  id: number;
  invoiceId: number;
  paymentIntentId: string;
  status: string;
  paymentStatus: string;
  createdAt: string;
  contact: {
    contactId: number;
    firstName: string;
    lastName: string;
  };
  customerEmail: string;
  paymentChannel: string;
  amount: number;
  createdBy: {
    id: number;
    firstName: string;
    lastName: string;
  };
};

export default function PaymentsList({ invoiceId }: PaymentsListProps) {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [totalElements, setTotalElements] = useState(0);

  useEffect(() => {
    getPayments(invoiceId, page, size).then((data) => {
      setPayments(data.content as Payment[]);
      setTotalElements(data.totalElements);
    });
  }, [invoiceId, page, size]);

  const getTableHead = () => {
    return [
      { id: "id", label: "Id" },
      { id: "createdAt", label: "Created At" },
      { id: "status", label: "Status" },
      { id: "paymentStatus", label: "Payment Status" },
      { id: "customer", label: "Customer" },
      { id: "customerEmail", label: "Customer Email" },
      { id: "paymentChannel", label: "Payment Channel" },
      { id: "amount", label: "Amount" },
      { id: "paidBy", label: "Paid By" },
    ];
  };
  const [showPayment, setShowPayment] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  const getTableData = () => {
    let data = [];

    data = payments?.map((item: Payment) => {
      return {
        id: item.id,
        data: [
          item.id,
          moment(item.createdAt).format("MM/DD/yyyy hh:mm:ss A"),
          properString(item.status),
          {
            render: (
              <>
                <button
                  className={`flex w-max p-2 items-center rounded ${
                    item.paymentStatus === "PAID"
                      ? "bg-green-300"
                      : "bg-red-300"
                  }`}
                >
                  <span className="text-sm font-medium">
                    {properString(item.paymentStatus)}
                  </span>
                </button>
              </>
            ),
          },
          `${item.contact.firstName} ${item.contact.lastName}`,
          item.customerEmail,
          properString(item.paymentChannel),
          `$${item.amount.toFixed(2)}`,
          item.paymentChannel === "SELF"
            ? `${item.contact.firstName} ${item.contact.lastName}`
            : `${item.createdBy.firstName} ${item.createdBy.lastName}`,
        ],
      };
    });
    return data;
  };

  return (
    <>
      <div className="flex flex-row justify  items-center mb-10">
        <span className="text-xl font-bold">Payments</span>
      </div>
      <MaterialTable
        data={getTableData()}
        head={getTableHead()}
        headRenderColor="text-newGray-300"
        defaultOrder="desc"
        pageRows={size}
        onClickCB={(selection: number) => {
          const selected = payments.find((item) => item.id === selection);
          setSelectedItem(selected?.paymentIntentId ?? "");
          setShowPayment(true);
        }}
        totalData={totalElements}
        currentPage={page}
        paginationCB={(value: number) => {
          setPage(value);
        }}
        rowChangeCB={(value: number) => {
          setSize(value);
        }}
        loading={undefined}
        defaultOrderBy={undefined}
        multiSelect={undefined}
        searchText={undefined}
        setSelected={undefined}
      />
      <MaterialModal
        open={showPayment}
        setOpen={(_) => setShowPayment(false)}
        extended
      >
        <StripePaymentDetails
          paymentIntentId={selectedItem}
          onClose={() => {
            setShowPayment(false);
          }}
        />
      </MaterialModal>
    </>
  );
}
