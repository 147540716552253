import React, { useRef } from "react";
import Draggable from "react-draggable";



import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Modal } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";
import useOnClickOutside from "use-onclickoutside";



import { ModalDragIcon } from "./AppIcons";


const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme: any) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  blurredBackdrop: {
    backdropFilter: "blur(5px)",
  },
}));

export default function MaterialModal({
  className,
  containerClassName,
  children,
  open,
  setOpen,
  extended,
  label,
  describedBy,
  width,
}: {
  className?: string;
  containerClassName?: string;
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  extended?: boolean;
  label?: string;
  describedBy?: string;
  width?: number | string;
}) {
  const ref = useRef(null);

  const [draggableStatus, setDraggableStatus] = React.useState(true);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle());

  const getModalWidth = () => {
    if (typeof window !== "undefined" && window.innerWidth >= 768) {
      return width ? (typeof width === "number" ? `${width}%` : width) : "auto";
    }
    return "90%";
  };

  const isSmallScreen =
    typeof window !== "undefined" && window.innerWidth < 768;

  return (
    <Draggable disabled={draggableStatus}>
      <Modal
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: isSmallScreen
                ? "rgba(0, 0, 0, 0.5)"
                : "transparent",
            },
            className: isSmallScreen ? classes.blurredBackdrop : "",
          },
        }}
        style={{
          backgroundColor: "transparent",
        }}
        id="modal"
        open={open ? true : false}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby={label}
        aria-describedby={describedBy}
      >
        <>
          <div
            style={{
              ...modalStyle,
              maxHeight: "90vh",
              width: getModalWidth(),
              maxWidth: "90%",
            }}
            className={`${classes.paper} rounded-md p-1.5 h-auto ${
              containerClassName ?? "overflow-y-auto"
            }`}
          >
            <IconButton
              onClick={() => setOpen(false)}
              className="absolute top-0 right-0 m-0.5"
            >
              <HighlightOffIcon className="text-3xl md:text-2xl" />
            </IconButton>
            <div
              onMouseEnter={() => {
                setDraggableStatus(false);
              }}
              onMouseLeave={() => {
                setDraggableStatus(true);
              }}
              className=" cursor-move  hover:border-b-2 hover:bg-newGray-900 p-4 items-center flex justify-center"
              style={{
                height: "20px",
              }}
            >
              <ModalDragIcon />
            </div>
            <div
              className={`${
                className ? className : "px-4 py-2 md:px-8 md:py-4"
              }`}
            >
              {open && children}
            </div>
          </div>
        </>
      </Modal>
    </Draggable>
  );
}