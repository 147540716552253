import React from "react";

// import moment from "moment";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import { materialMethodMapping } from "../common/form/constants";

export default function ListMaterials({ material, serviceRequestId, editCB }) {
  return (
    <div className="flex w-full px-2">
      <ul className="w-full">
        <li
          key={"renderIndex"}
          className="mt-3 px-2"
          onClick={() => {
            editCB({
              display: true,
              data: {
                materialId: material.materialId,
                method: material.method,
                name: material.name,
                description: material.description,
                skuNumber: material.skuNumber,
                supplierId: material.supplier?.supplierId,
                supplierBranchId: material.supplierBranch?.buildingId,
                orderDate: material.orderDate,
                deliveryDate: material.deliveryDate,
                orderNumber: material.orderNumber,
                trackingUrl: material.trackingUrl,
                serviceRequestId: serviceRequestId,
                isDelivered: material.isDelivered ? "true" : "false",
              },
              edit: true,
            });
          }}
        >
          <div className="relative">
            <div className="relative flex space-x-3 rounded bg-newYellow-100 hover:bg-newYellow-200 cursor-pointer">
              <div>
                <span
                  className={
                    "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                  }
                >
                  <ShoppingCartIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                <div>
                  <p className="text-sm text-gray-800"></p>
                </div>
                <div>
                  <p className="text-sm text-gray-800 align-middle">
                    {material.name}
                  </p>
                </div>
                <div className="px-2">
                  <p className="text-sm text-gray-800">
                    {materialMethodMapping[material.method]}
                  </p>
                </div>
                <div
                  className={
                    "text-right text-sm whitespace-nowrap  text-gray-800 " +
                    (material.method !== "IN_STOCK"
                      ? material.isDelivered
                        ? "px-4"
                        : "px-2"
                      : "px-4 invisible")
                  }
                >
                  {true && (material.isDelivered ? "Delivered" : "Undelivered")}
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
