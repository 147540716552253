import React from "react";

import { Avatar } from "@mui/material";
import { Client as ConversationsClient } from "@twilio/conversations";
import { navigate } from "raviger";

import { alertError } from "../../../actions/AlertActions";
import { messageRecieved } from "../../../actions/ChatActions";
import {
  endConversation,
  endSpamConversation,
  joinConversation,
} from "../../../api/Api";
import audio from "../../../assets/not.wav";
import softAudio from "../../../assets/soft.wav";
import dispatcher from "../../../dispatcher";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SelectContact from "../../common/SelectContact";
import Conversation from "./Conversation";
import ImagePopup from "./ImagePopup";
import UnreadMessage from "./UnreadMessage";

class ConversationsApp extends React.Component {
  constructor(props) {
    super(props);

    const name = this.props.identity ?? "";
    const loggedIn = name !== "";
    this.token = this.props.token;
    this.setUserName = this.props.setUserName;
    this.setSelectedConversationContactId =
      this.props.setSelectedConversationContactId;

    this.setSelectedConversationId = this.props.setSelectedConversationId;
    this.setConversation = this.props.setConversation;
    this.state = {
      props: this.props,
      name,
      loggedIn,
      token: null,
      statusString: null,
      conversationsReady: false,
      conversations: [],
      selectedConversationSid: this.props.selectedConversationId,
      newMessage: "",
      conversationToDelete: undefined,
      boundConversations: new Set(),
      messages: [],
      loadingState: "initializing",
      image_popup: { popup: false, index: undefined },
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedConversationId !== this.props.selectedConversationId
    ) {
      this.setUserName(null);
      this.setState({
        ...this.state,
        selectedConversationSid: this.props.selectedConversationId,
      });
    }
  }

  messageAdded = (message, targetConversation) => {
    // if (targetConversation.sid === this.state.selectedConversationSid) {
    // console.log("message state ");
    let conversationMessages = this.state.messages;

    conversationMessages.map((conversationObject) => {
      if (conversationObject.conversationId === targetConversation.sid) {
        conversationObject.messages.push(message);
      }
    });

    this.setState({ messages: conversationMessages });
    // }
  };

  loadMessagesFor = (thisConversation) => {
    console.log("Fetching Messages");
    // if (state.conversationProxy === thisConversation) {
    thisConversation
      .getMessages()
      .then((messagePaginator) => {
        console.log("Received Messages");
        console.log("this conversation ", thisConversation);
        console.log("message paginator", messagePaginator);

        let conversationMessages = this.state.messages;

        conversationMessages.map((conversationObject) => {
          if (conversationObject.conversationId === thisConversation.sid) {
            messagePaginator.items.map((message) => {
              conversationObject.messages.push(message);
            });
          }
        });

        this.setState((state) => ({
          ...state,
          messages: conversationMessages,
          loadingState: "ready",
        }));
        // }
      })
      .catch((err) => {
        console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
        this.setState((state) => ({ ...state, loadingState: "failed" }));
      });
    // }
  };

  addToBoundConversations = (conversation) => {
    this.setState((prevState) => {
      let boundConversations = new Set(prevState.boundConversations);
      boundConversations.add(conversation);
      return { boundConversations };
    });
  };

  componentDidMount = () => {
    if (this.state.loggedIn) {
      // console.log("User is Logged In");
      this.getToken();
      this.setState({ statusString: "Fetching credentials…" });
    }
  };

  static getDerivedStateFromProps(newProps, oldState) {
    // console.log("getDerivedStateFromProps", newProps);
    if (
      newProps.clearFlag === oldState.props.clearFlag &&
      oldState.conversations.length > 0
    ) {
      let conversationToClose = oldState.conversations.find(
        (it) => it.sid === oldState.selectedConversationSid
      );
      conversationToClose && conversationToClose.setAllMessagesRead();
    }

    return {
      ...oldState,
      props: newProps,
      // selectedConversationSid: newProps.selectedConversationSid
      //   ? newProps.selectedConversationSid
      //   : newProps.clearFlag === oldState.props.clearFlag
      //   ? oldState.selectedConversationSid
      //   : null
    };
  }
  logIn = (name) => {
    console.log("Logging In ..");
    if (name !== "") {
      localStorage.setItem("name", name);
      this.setState({ name, loggedIn: true }, this.getToken);
    }
  };

  logOut = (event) => {
    console.log("Logging Out");
    if (event) {
      event.preventDefault();
    }

    this.setState({
      name: "",
      loggedIn: false,
      token: "",
      conversationsReady: false,
      messages: [],
      newMessage: "",
      conversations: [],
    });

    localStorage.removeItem("name");
    this.conversationsClient.shutdown();
  };

  getToken = async () => {
    // console.log("Setting Token", this.token);
    this.setState({ token: this.token }, this.initConversations);
  };

  handleAction = (action) => {
    switch (action.actionType) {
      case "UNREAD":
        let conversationId = action.conversationId;
        this.initConversations();
        break;

      default:
    }
  };

  initConversations = async () => {
    dispatcher.register(this.handleAction);
    window.dispatcher = dispatcher;

    // console.log("Initializing Conversations");
    window.conversationsClient = ConversationsClient;
    this.conversationsClient = await ConversationsClient.create(
      this.state.token
    );
    this.setState({ statusString: "Connecting to Twilio…" });

    this.conversationsClient.on("connectionStateChanged", (state) => {
      if (state === "connecting")
        this.setState({
          statusString: "Connecting to Twilio…",
          status: "default",
        });
      if (state === "connected") {
        this.setState({
          statusString: "You are connected.",
          status: "success",
        });
        this.conversationsClient
          .getSubscribedConversations()
          .then(({ items, hasNextPage, hasPrevPage, nextPage, prevPage }) => {
            // console.log("Conversations", items);

            items.map((conversation) => {
              // conversation.setAllMessagesRead();

              this.setState({
                messages: [
                  ...this.state.messages,
                  {
                    conversationId: conversation.sid,
                    messages: [],
                  },
                ],
              });
            });

            this.setState((state) => {
              return {
                ...state,
                conversations: items,
              };
            });
          })
          .catch(console.error);
      }
      if (state === "disconnecting")
        this.setState({
          statusString: "Disconnecting from Twilio…",
          conversationsReady: false,
          status: "default",
        });
      if (state === "disconnected")
        this.setState({
          statusString: "Disconnected.",
          conversationsReady: false,
          status: "warning",
        });
      if (state === "denied")
        this.setState({
          statusString: "Failed to connect.",
          conversationsReady: false,
          status: "error",
        });
    });

    this.conversationsClient.on("conversationJoined", (conversation) => {
      console.log("Joined new Conversation", conversation);
      conversation.setAllMessagesRead();
      this.loadMessagesFor(conversation);
      conversation.on("messageAdded", (m) => {
        if (m.author !== this.state.name) {
          conversation.getUnreadMessagesCount().then((data) => {});

          messageRecieved();
          if (document.hidden) {
            var ChirpChirp = new Audio(audio);
            ChirpChirp.play();
          } else {
            var softAudio_ = new Audio(softAudio);
            softAudio_.play();
          }
        }

        this.messageAdded(m, conversation);
      });

      let conversationFilter = [...this.state.conversations];

      let found = conversationFilter.filter((item) => {
        if (item.sid === conversation.sid) {
          return item;
        }
      });

      this.setState({
        conversations:
          found.length > 0
            ? [...this.state.conversations]
            : [...this.state.conversations, conversation],
        selectedConversationSid: conversation.sid,
      });

      if (this.props.selectedConversationId) {
        this.setSelectedConversationId(this.props.selectedConversationId);
      } else {
        this.setSelectedConversationId(conversation.sid);
      }
    });
    this.conversationsClient.on("conversationLeft", (thisConversation) => {
      console.log("Left from Conversation", thisConversation);
      this.setState({
        conversations: [
          ...this.state.conversations.filter((it) => it !== thisConversation),
        ],
      });
    });
  };

  render() {
    const { conversations, status, messages } = this.state;

    const selectedConversationSid = this.props.selectedConversationId;

    const selectedConversation = conversations.find(
      (it) => it.sid === selectedConversationSid
    );

    const selectedConversationMessages = messages.find(
      (conversationMessages) =>
        conversationMessages.conversationId === selectedConversationSid
    );

    // console.log("Rendering Conversations", conversations);
    let conversationContent;

    if (selectedConversation) {
      this.setConversation(selectedConversation);
      this.setUserName(
        `- ${selectedConversation.channelState.attributes.first_name} ${selectedConversation.channelState.attributes.last_name}`
      );
      this.setSelectedConversationContactId(
        selectedConversation.channelState.attributes.contactId
      );
      conversationContent = (
        <Conversation
          boundConversations={this.state.boundConversations}
          addToBoundConversations={this.addToBoundConversations}
          messages={
            selectedConversationMessages
              ? selectedConversationMessages.messages
              : []
          }
          conversationProxy={selectedConversation}
          myIdentity={this.state.name}
          setMessageReceived={this.props.setMessageReceived}
          loadingState={this.state.loadingState}
          open_image_popup={(json) => {
            this.setState({
              image_popup: { ...this.state.image_popup, ...json },
            });
          }}
        />
      );
      // } else if (status !== "success") {
      //   conversationContent = "Loading your conversation!";
    } else {
      // console.log(
      //   `Conversations: ${conversations.length} | NewChats: ${this.state.props.newChats.length}`
      // );
      conversationContent = (
        <div className="w-80 h-80">
          {[...conversations, ...this.state.props.newChats].length === 0 ? (
            <div className="w-full h-full flex flex-row items-center justify-center text-center text-medium  opacity-50">
              <div className="text-newGray-300 tracking-wide">
                You have no active conversations
              </div>
            </div>
          ) : (
            <div className="flow-root mt-6">
              <ul className="-my-5 divide-y divide-gray-200 px-3">
                {this.state.props.newChats.map((newChat, renderIndex) => (
                  <li key={renderIndex} className="py-4">
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <Avatar
                          src={newChat.profilePic || ""}
                          className="h-6 w-6 mr-2"
                          onClick={(_) => {
                            if (newChat.contactId)
                              navigate(`/clients/${newChat.clientId}`);
                          }}
                        >
                          {newChat.user_name[0] ||
                            newChat.channelState.attributes.name[0]}
                        </Avatar>
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900 truncate">
                          {newChat.user_name}
                        </p>
                      </div>
                      <div>
                        <button
                          onClick={async (_) => {
                            console.log("debug change");
                            this.setState({
                              messages: [
                                ...this.state.messages,
                                {
                                  conversationId: newChat.conversationSID,
                                  messages: [],
                                },
                              ],
                            });
                            await joinConversation(newChat.conversationSID)
                              .then((_) => {
                                if (newChat.contactId)
                                  navigate(`/clients/${newChat.clientId}`);
                              })
                              .catch((_) => alertError("Couldn't Join Chat"));
                          }}
                          className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                        >
                          Join
                        </button>
                      </div>
                    </div>
                  </li>
                ))}

                {conversations.map((conversation, renderIndex) => (
                  <li
                    key={renderIndex}
                    onClick={(_) => {
                      this.setState((state) => ({
                        ...state,
                        selectedConversationSid: conversation.sid,
                      }));

                      this.setSelectedConversationId(conversation.sid);
                      // conversation.setAllMessagesRead();
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <Avatar
                          src={
                            conversation.channelState.attributes.profilePic ||
                            ""
                          }
                          className="h-6 w-6 mr-2"
                          onClick={(_) => {
                            navigate(
                              `/clients/${conversation.channelState.attributes.clientId}`
                            );
                          }}
                        >
                          {conversation.channelState.attributes.user_name[0]}
                        </Avatar>
                      </div>
                      <div className="flex flex-1 min-w-0 items-center">
                        <p className="text-sm font-medium text-gray-900 truncate">
                          {`${conversation.channelState.attributes.first_name} ${conversation.channelState.attributes.last_name}`}
                        </p>
                        <UnreadMessage
                          conversation={conversation}
                          appState={this.state}
                        />
                      </div>
                      <div>
                        <button
                          className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.setState((state) => ({
                              ...state,
                              conversationToDelete: conversation.sid,
                              conversationPhoneOrEmail:
                                conversation.channelState.attributes
                                  .phoneOrEmail,
                              conversationContactId:
                                conversation.channelState.attributes.contactId,
                              conversation: conversation,
                            }));

                            !conversation.channelState.attributes.contactId
                              ? this.setState((state) => ({
                                  ...state,
                                  conversationContactId:
                                    conversation.channelState.attributes
                                      .contactId,
                                }))
                              : endConversation(
                                  conversation.sid,
                                  conversation.channelState.attributes.contactId
                                ).catch((_) =>
                                  alertError("Couldn't Leave Chat")
                                );
                          }}
                        >
                          {conversation.participants.size === 1
                            ? "Close"
                            : "Leave"}
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      );
    }

    if (this.state.loggedIn) {
      return (
        <React.Fragment>
          <ImagePopup
            view={this.state.image_popup.popup}
            onChange={(val) => {
              this.setState({ image_popup: val });
            }}
            set_view={() => {
              this.setState({
                image_popup: { ...this.state.image_popup, popup: false },
              });
            }}
            image_popup={this.state.image_popup}
          />
          <div
            className={
              "h-80 " +
              (selectedConversation ? "overflow-auto" : "overflow-x-hidden ")
            }
          >
            {conversationContent}
          </div>
          {!this.state.conversationContactId && (
            <SelectContact
              data={{
                conversationPhoneOrEmail: this.state.conversationPhoneOrEmail,
                conversation: this.state.conversation,
              }}
              show={this.state.conversationToDelete !== undefined}
              setShow={(_) =>
                this.setState((state) => ({
                  ...state,
                  conversationToDelete: undefined,
                  conversationContactId: undefined,
                }))
              }
              onSuccess={({ contactId }) => {
                this.setState((state) => ({
                  ...state,
                  conversationToDelete: this.state.conversationToDelete,
                  conversationContactId: contactId,
                }));
                endConversation(this.state.conversationToDelete, contactId);
              }}
              onReportSpam={({ contactId }) => {
                this.setState((state) => ({
                  ...state,
                  conversationToDelete: this.state.conversationToDelete,
                  conversationContactId: contactId,
                }));
                endSpamConversation(this.state.conversationToDelete).then(
                  () => {
                    this.setState((state) => ({
                      ...state,
                      conversationToDelete: undefined,
                    }));
                  }
                );
              }}
            />
          )}
        </React.Fragment>
      );
    }

    return <div>Loading</div>;
  }
}

export default ConversationsApp;
