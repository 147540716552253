import React, { useEffect, useState } from "react";

import { alertError, alertSuccess } from "../../../actions/AlertActions";
import { createPipelineStage, updatePipelineStage } from "../../../api/Api";

export type CreateStageProps = {
  pipelineId?: number;
  onSubmit: () => void;
  isEdit: boolean;
  final?: boolean;
  stageId?: number;
  stage?: string;
};

export default function CreateStage({
  pipelineId,
  onSubmit,
  final,
  isEdit,
  stageId,
  stage,
}: CreateStageProps) {
  useEffect(() => {}, []);

  const [stageName, setStageName] = useState(stage);
  const [finalStage, setFinalStage] = useState(final);

  return (
    <>
      <div className=" flex flex-col p-4  w-full rounded">
        <div className="mb-6 text-gray-900 text-xl font-medium">
          {isEdit ? `Edit Stage` : `Create New Stage`}
        </div>
        <div className="mb-6">
          <input
            className="appearance-none border border-newGray-300 rounded w-96 py-2 px-3 text-newGray-800 leading-tight focus:outline-none focus:shadow-outline"
            id="stage"
            type="text"
            placeholder="Enter stage name"
            onChange={(input) => setStageName(input.target.value)}
            value={stageName}
          />
        </div>
        <div className="flex items-center mb-6">
          <input
            id="link-checkbox"
            type="checkbox"
            value=""
            defaultChecked={finalStage}
            onChange={() => setFinalStage(!finalStage)}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label className="ml-2 text-sm font-medium text-gray-800 dark:text-gray-800">
            Mark this as Final Stage
          </label>
        </div>

        <div className="flex flex-row">
          <button
            className="w-full rounded bg-newBlue-400 text-white text-md font-normal py-2 px-6"
            onClick={() => {
              isEdit
                ? updatePipelineStage(pipelineId, stageId, [
                    {
                      op: "replace",
                      path: "/stage",
                      value: stageName,
                    },
                    {
                      op: "replace",
                      path: "/final",
                      value: finalStage,
                    },
                  ])
                    .then(() => {
                      onSubmit();
                      alertSuccess("Successfully updated stage");
                    })
                    .catch((_) => alertError("Error updating stage"))
                : createPipelineStage(pipelineId, {
                    stage: stageName,
                    final: finalStage,
                  })
                    .then(() => {
                      onSubmit();
                      alertSuccess("Successfully created stage");
                    })
                    .catch((_) => alertError("Error creating stage"));
            }}
          >
            {isEdit ? `Update Stage` : `Create Stage`}
          </button>
          <button
            className="flex w-20 rounded border border-gray-900 py-2 px-6 ml-4 justify-center items-center"
            onClick={onSubmit}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
