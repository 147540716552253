import { useEffect, useState } from "react";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import { createSR, getAssociateHistory, getSR, getTicket } from "../../api/Api";
import MaterialTable from "../../components/common/MaterialTable";
import NewServiceRequestPopup from "../../components/common/NewServiceRequestPopup";
import {
  JobTypesDict,
  formUpdateSRTicket,
} from "../../components/common/form/constants";
import { deepUpdate } from "../../utils/StateUtils";
import { AssociateHistoryData } from "./Service";

type AssociateAppointmentsProps = {
  associateId: number;
};

export default function AssociateAppointments({
  associateId,
}: AssociateAppointmentsProps) {
  const [forUpdate, setForUpdate] = useState();
  const [history, setHistory] = useState<AssociateHistoryData[]>();
  console.log({ history });
  const { openTicket } = useAppActions();

  useEffect(() => {
    getAssociateHistory(associateId)
      .then((data) => setHistory(data as AssociateHistoryData[]))
      .catch((_) => alertError("Couldn't get Job History"));
  }, [associateId]);

  const onChangeUpdate = (update: { name: string; value: any }) => {
    const { name, value } = update;
    setForUpdate((data) => deepUpdate(name, value, data));
  };

  return (
    <div className="mt-4">
      <MaterialTable
        data={
          history?.map((row) => {
            return {
              id: row.serviceRequestId,
              data: [
                `${row.ticketId}-` +
                  (!row.isDraft
                    ? `SR${row.serviceRequestIndex}`
                    : `I${row.serviceRequestIndex}`),
                row.clientName,
                row.description,
                JobTypesDict[row.jobType],
                row.bookingStatus,
                row.propertyShortName || "-",
                {
                  render: row.timelineStart
                    ? new Date(row.timelineStart).toLocaleString()
                    : "-",
                  sortBy: row.timelineStart
                    ? new Date(row.timelineStart).getTime()
                    : 0,
                },
              ],
            };
          }) || []
        }
        head={[
          { id: "serviceRequestId", label: "Service Request Number" },
          { id: "clientName", label: "Client Name" },
          { id: "description", label: "Description" },
          { id: "jobType", label: "Job Type" },
          { id: "bookingStatus", label: "Status" },
          { id: "propertyShortName", label: "Property Short Name" },
          { id: "timeSlot", label: "Time Slot" },
        ]}
        defaultOrderBy={"timeSlot"}
        defaultOrder="desc"
        onClickCB={(SRId: number) => {
          getSR(SRId).then((data) => {
            delete data["appointments"];
            setForUpdate(data);
          });
        }}
        searchText={""}
        loading={undefined}
        currentPage={undefined}
        totalData={undefined}
        multiSelect={undefined}
        setSelected={undefined}
        paginationCB={undefined}
        rowChangeCB={undefined}
      />

      {forUpdate && (
        <NewServiceRequestPopup
          data={forUpdate}
          renderArray={() => {
            return formUpdateSRTicket(forUpdate);
          }}
          backCB={() => {
            getTicket(forUpdate.ticketId).then((data) => {
              openTicket(data.ticketId);
              setForUpdate();
            });
          }}
          onChangeCB={onChangeUpdate}
          clearCB={() => {
            setForUpdate();
          }}
          updateCB={() => {
            createSR(forUpdate)
              .then((_) => {
                alertSuccess("Successfully Updated Service Request");
              })
              .catch((_) => alertError("Error Updating Service Request"));
          }}
        />
      )}
    </div>
  );
}
