import { useEffect, useRef, useState } from "react";

import { deepFind } from "../../../../utils/StateUtils";
import { properString, singularText } from "../../../../utils/StringUtils";
import { DeleteBin } from "../../../clients/ClientAppIcons";
import Disclosure from "../../headless/Disclosure";
import { make as MaterialForm } from "../MaterialForm.bs";

export default function ChildForm({
  variant,
  data,
  name,
  label,
  onChange,
  setFocusChild,
  focusChild,
  renderFields,
  relatedCallback,
}) {
  const [selectedChild, setSelectedChild] = useState(0);
  const [nestedFocusChild, setNestedFocusChild] = useState(null);

  const childRef = useRef(null);

  const nameWithinContext = name.split(".").pop();

  const onFocus = (selector) => {
    if (relatedCallback) {
      relatedCallback({
        type: "disclosure",
        expanded: false,
      });
      relatedCallback({
        type: "focus",
        name: selector,
      });
    }
    if (selector) {
      setFocusChild(selector);
    } else {
      setFocusChild(null);
    }
  };

  useEffect(() => {
    if (selectedChild && childRef.current) {
      childRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedChild]);

  const value = focusChild
    ? deepFind(`${nameWithinContext}[${selectedChild}]`, data) || {}
    : deepFind(nameWithinContext, data) || [];

  const fields = renderFields && renderFields(value || {});

  const FocusForm = focusChild ? (
    <MaterialForm
      className="flex flex-col rounded-md m-2"
      data={value || {}}
      renderArray={fields.map((field) => {
        const returnCandidate = {
          ...field,
          name: `${field.name}`,
        };
        if (field.inputType === "CHILDFORM") {
          return {
            ...returnCandidate,
            relatedCallback: (action) => {
              // When the child form is expanded, update the state to reflect that
              if (action.type === "focus") {
                setNestedFocusChild(action.name);
              }
            },
          };
        }

        return returnCandidate;
      })}
      onChange={(event) => {
        console.log("ChildForm onChange", event);
        onChange({
          name: `${nameWithinContext}[${selectedChild}].${event.name}`,
          value: event.value,
        });
      }}
      subForm
    />
  ) : (
    <div></div>
  );

  return (
    <div className={"-mx-2 border-gray-500 "}>
      {focusChild && focusChild === name ? (
        <div>
          <span className="p-2 text-gray-700 text-base">
            {`${singularText(label)} ${selectedChild + 1}`}
          </span>
          {FocusForm}
          {!nestedFocusChild && (
            <div
              className="flex flex-row justify-end items-center p-2"
              ref={childRef}
            >
              <button
                type="button"
                className="p-2 rounded-md childServiceRequest text-right font-bold text-gray-800 text-sm bg-blue-100 hover:bg-blue-200"
                onClick={() => {
                  console.log("Removing Child");
                  onFocus(null);
                  setSelectedChild(0);
                }}
              >
                {
                  // If data[nameWithinContext] is not defined, then a fresh child is previewed
                  data[nameWithinContext] ? "Save" : "Cancel"
                }
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col">
          <button
            type="button"
            className="m-2 p-2 rounded-md text-center font-bold uppercase text-blue-700 text-sm hover:text-blue-500 bg-blue-100 hover:bg-blue-200 "
            onClick={() => {
              onFocus(name);
              setSelectedChild(value?.length ?? 0);
            }}
          >
            <span className="text-gray-700">+ Add {label}</span>
          </button>
          {value?.length > 0 ? (
            <>
              <Disclosure
                onToggle={() => {
                  relatedCallback({
                    type: "disclosure",
                  });
                }}
                collapsedChildren={
                  <span className={`mx-2 text-gray-700 text-base`}>
                    {`${value?.length} ${
                      value?.length === 1 ? singularText(label) : label
                    }`}
                  </span>
                }
              >
                {value?.map((item, index) => (
                  <div
                    key={index}
                    className="flex rounded-lg px-2 py-1 m-2 bg-gray-300 hover:bg-gray-200 cursor-pointer justify-between items-center"
                    onClick={() => {
                      onFocus(name);
                      setSelectedChild(index);
                    }}
                  >
                    <ChildItemLabel
                      variant={variant}
                      item={item}
                      index={index}
                    />
                    {/* Delete Button */}
                    <span
                      className="text-gray-500 text-sm rounded-full p-2 hover:bg-gray-400 cursor-pointer"
                      onClick={(event) => {
                        event.stopPropagation();
                        const updatedChildren = value.filter(
                          (_, i) => i !== index
                        );
                        onChange({
                          name: nameWithinContext,
                          value: updatedChildren,
                        });
                        if (updatedChildren.length === 0) {
                          relatedCallback({
                            type: "disclosure",
                            expanded: false,
                          });
                        }
                      }}
                    >
                      <DeleteBin className="w-4 h-4 text-red-600" />
                    </span>
                  </div>
                ))}
              </Disclosure>
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
}

const ChildItemLabel = ({ variant, item, index }) => {
  // Variant is a string split by `|` that has different keys. eg: `name|title|description`
  // The first variant that exists as a key in the item will be used as the label
  // Any further variants will be used as a badges
  const variants = variant.split("|");
  // [selector, badge1, unrelated, badge2]
  const label = variants.find((variant) => item[variant]);
  // first instance where item.variant exists = selector
  const badges = variants.filter(
    (variant) => variant !== label && item[variant]
  );
  // [badge1, badge2]

  // index + 1 will be the serial number of the item, and wille be prepended to the label
  return (
    <div className="flex gap-2">
      <span className="text-gray-700 text-base">
        {index + 1}. {properString(item[label])}
      </span>
      {badges.map((badge) => (
        <ItemBadge key={badge} selector={badge} item={item} />
      ))}
    </div>
  );
};

const ItemBadge = ({ selector, item }) => {
  // If the attribute is a string, it will be used as the badge
  // Else if it is an array, with length > 1, The Badge would say `attr.length ${attr}`
  const attr = item[selector];
  if (typeof attr === "string") {
    return (
      <span className="text-gray-700 text-sm rounded-full p-1 px-2 bg-gray-200">
        {/* string badge */}
        {attr}
      </span>
    );
  } else if (Array.isArray(attr)) {
    if (attr.length >= 1) {
      return (
        <span className="text-gray-700 text-sm rounded-full p-1 px-2 bg-gray-200">
          {/* array badge */}
          {attr.length} {properString(selector)}
        </span>
      );
    } else {
      return <></>;
    }
  }
  return (
    <span className="text-gray-500 text-sm rounded-full p-1 bg-gray-200">
      Generic; {Array.isArray(attr) ? "Array" : typeof attr}
    </span>
  );
};
