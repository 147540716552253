// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DropdownGen from "./Dropdown.gen";

var make = DropdownGen.make;

export {
  make ,
  
}
/* make Not a pure module */
