import { useRecoilValue, useSetRecoilState } from "recoil";

import { globalComponentStateAtom } from "../store/GlobalComponent";
import { alertInfo } from "./AlertActions";

export const useAppActions = () => {
  // Disclaimer: Using the recoil value here, causes infinite re-renders
  const setGlobalState = useSetRecoilState(globalComponentStateAtom);

  return {
    openTicket: (ticketId: number,  callback?: () => void) => {
      setGlobalState((state) => {
        return {
          ...state,
          ticketId,
          ticketCallback: callback,
        };
      });
    },
    openInvoice: (invoiceId: number,  callback?: () => void) => {
      setGlobalState((state) => {
        return {
          ...state,
          invoiceId,
          ticketCallback: callback,
        };
      });
    },
    createSimpleTicket: () => {
      setGlobalState((state) => {
        return {
          ...state,
          createSimpleTicket: true,
        };
      });
    },
    withConfirmation: ({
      title,
      description,
      onConfirm,
      onClose,
    }: {
      title: string;
      description: string;
      onConfirm: () => void;
      onClose?: () => void;
    }) => {
      setGlobalState((state) => {
        return {
          ...state,
          confirmationDialog: {
            open: true,
            title,
            description,
            onConfirm,
            onClose
          },
        };
      });
    },
    editManagerAccess: ({
      clientId,
      manager,
      callback,
    }: {
      clientId: number;
      manager: any;
      callback: () => void;
    }) => {
      setGlobalState((state) => {
        return {
          ...state,
          managerAccessData: {
            clientId,
            manager,
            callback: () => {
              console.log("Manager access callback");
              callback();
            },
          },
        };
      });
    },
    refreshManagerAccessData: ({ managers }: { managers: any[] }) => {
      // Given the disclaimer above, we use the setGlobalState function
      // from the beginning to access the latest state
      setGlobalState((globalComponentState) => {
        if (globalComponentState.managerAccessData?.manager) {
          console.log("Refreshing manager access data");
          const updatedManagerObj = managers.find(
            (manager) =>
              manager.contactId ===
              globalComponentState.managerAccessData.manager.contactId
          );
          return {
            ...globalComponentState,
            // Ensure that managerAccessData is not undefined
            // Since closing the modal can also trigger this effect
            managerAccessData: globalComponentState.managerAccessData && {
              ...globalComponentState.managerAccessData,
              manager: updatedManagerObj,
            },
          };
        }
        return globalComponentState;
      });
    },

    createManager: ({
      label = "Manager",
      clientId,
      buildingId,
      callback,
    }: {
      label: string;
      clientId: number;
      buildingId?: number;
      callback: () => void;
    }) => {
      setGlobalState((state) => {
        return {
          ...state,
          contactData: {
            managerOfClientId: clientId,
            buildingId,
            position: "MANAGER",
            label,
          },
          contactSuccessCB: callback,
        };
      });
    },

    editContact: (
      contactData: {
        contactId: number;
        firstName: string;
        lastName: string;
        position: "OWNER" | "TENANT" | "MANAGER";
        email: string;
        phone: string;
        profilePicture: string;
      },
      callback: () => void
    ) => {
      setGlobalState((state) => {
        return {
          ...state,
          contactData,
          contactSuccessCB: callback,
        };
      });
    },
    selectFromChoices: ({
      title,
      description,
      choices,
      callback,
    }: {
      title: string;
      description: string;
      choices: { label: string; value?: any; callback?: () => void }[];
      callback: (value: any) => void;
    }) => {
      setGlobalState((state) => {
        return {
          ...state,
          selectFromChoices: {
            title,
            description,
            choices,
            callback,
          },
        };
      });
    },
    closeChoiceSelector: () => {
      setGlobalState((state) => {
        return {
          ...state,
          selectFromChoices: undefined,
        };
      });
    },
    quickMessage: ({
      contacts,
      clientId,
      contactId,
    }: {
      contacts: any[];
      clientId: number;
      contactId?: number;
    }) => {
      setGlobalState((state) => {
        return {
          ...state,
          quickMessage: {
            contacts,
            clientId,
            contactId,
          },
        };
      });
    },
  };
};
