// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateTimePickerGen from "./DateTimePicker.gen";

var make = DateTimePickerGen.make;

export {
  make ,
  
}
/* make Not a pure module */
