import MaterialForm from "../../components/common/form/MaterialForm";
import { deepUpdate } from "../../utils/StateUtils";

type AdminDetails = {
  firstName: string;
  lastName: string;
  phone: string;
  emailAddress: string;
  password: string;
};

type AdminSetupProps = {
  adminDetails: AdminDetails;
  setAdminDetails: React.Dispatch<
    React.SetStateAction<{
      firstName: string;
      lastName: string;
      phone: string;
      emailAddress: string;
      password: string;
    }>
  >;
};
export default function AdminSetup({
  adminDetails,
  setAdminDetails,
}: AdminSetupProps) {
  const onChange = (update: {
    name: string;
    subOptions?: string[];
    value?: string;
  }) => {
    let { name, value } = update;

    setAdminDetails((data) => deepUpdate(name, value, data));
  };

  const formAdminDetails = () => {
    return [
      { name: "firstName" },
      { name: "lastName" },
      { name: "phone", inputType: "PHONE" },
      { name: "emailAddress", inputType: "EMAIL" },
      { name: "password" },
    ];
  };
  return (
    <div className="mt-20 md:mx-20">
      <div>
        <MaterialForm
          data={adminDetails}
          renderArray={formAdminDetails()}
          onSubmit={() => {}}
          onChange={onChange}
          fieldClassName="mt-4"
        />
      </div>
    </div>
  );
}
