import { CommercialClientIcon, IndividualClientIcon } from "../ClientAppIcons";

export default function ClientTypeSelection({ onChange, onNext }) {
  return (
    <div className="flex flex-col gap-4 items-center">
      <div>
        <span className="font-medium">Select Type of Client</span>
      </div>
      <div className="flex flex-col  sm:flex-row gap-4 py-4">
        <div
          className="p-12 bg-newGray-1400 cursor-pointer"
          onClick={() => {
            onChange("INDIVIDUAL");
            onNext();
          }}
        >
          <div className="flex flex-col items-center gap-4 cursor-pointer">
            <div>
              <IndividualClientIcon className={"h-12 w-12"} />
            </div>
            <div className="flex flex-col">
              <span>Individual Resident</span>
              <span>(Owner or Tenant)</span>
            </div>
          </div>
        </div>
        <div
          className="p-12 bg-newGray-1400 cursor-pointer"
          onClick={() => {
            onChange("COMMERCIAL");
            onNext();
          }}
        >
          <div className="flex flex-col items-center gap-4 cursor-pointer">
            <div>
              <CommercialClientIcon className={"h-12 w-12"} />
            </div>
            <div>
              <span>Commercial Client</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
