import React, { useState } from "react";

import NewUnitScreen from "./NewUnitScreen";
import UnitListing from "./UnitListing";
import { TenantScreening } from "./tenantScreening/TenantScreening";
import { RentLeaseScreen } from "./unitDetails/RentLease/RentLeaseScreen";
import { UnitDetailsScreen } from "./unitDetails/UnitDetailsScreen";

type UnitTabProps = {
  buildingId: number;
  buildingName: string;
  unitId?: number;
  unitTab?: string;
  leaseIdParam?: number;
  leaseTab?: string;
};

export default function UnitsTab({
  buildingId,
  buildingName,
  unitTab,
  unitId,
  leaseTab,
  leaseIdParam,
}: UnitTabProps) {
  const [currentTab, setCurrentTab] = useState(
    leaseTab === "screening" ? "tenantScreening" : "unitListing"
  );

  const [leaseId, setLeaseId] = useState(0);
  const [selectedUnit, setSelectedUnit] = useState(0);

  const [selectedTab, setSelectedTab] = useState("unitDetails");

  return (
    <div className="p-4">
      {currentTab === "unitListing" && (
        <UnitListing
          onTabChange={setCurrentTab}
          setSelectedUnit={setSelectedUnit}
          buildingId={buildingId}
        />
      )}
      {currentTab === "newUnit" && (
        <NewUnitScreen
          onBackClick={() => setCurrentTab("unitListing")}
          buildingId={buildingId}
        />
      )}
      {currentTab === "unitDetails" && (
        <UnitDetailsScreen
          unitId={selectedUnit === 0 ? unitId ?? 0 : selectedUnit}
          buildingName={buildingName}
          buildingId={buildingId}
          onBackClick={setCurrentTab}
          leaseId={leaseIdParam ? leaseIdParam : leaseId}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          setLeaseId={setLeaseId}
        />
      )}
      {currentTab === "rentLeaseDetails" && (
        <RentLeaseScreen
          unitId={selectedUnit === 0 ? unitId ?? 0 : selectedUnit}
          buildingName={buildingName}
          buildingId={buildingId}
          onBackClick={setCurrentTab}
          setNewLeaseId={setLeaseId}
          leaseIdParam={leaseId === 0 ? leaseIdParam : leaseId}
        />
      )}
      {currentTab === "tenantScreening" && (
        <TenantScreening
          unitId={selectedUnit === 0 ? unitId ?? 0 : selectedUnit}
          setCurrentTab={setCurrentTab}
          buildingName={buildingName}
          leaseId={leaseId === 0 ? leaseIdParam : leaseId}
        />
      )}
    </div>
  );
}
