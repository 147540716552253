import { useState } from "react";

import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectComponentsProvider,
  ConnectPaymentDetails,
} from "@stripe/react-connect-js";

import { stripeAccountSession } from "../../api/Api";

type StripePaymentDetailsProps = {
  paymentIntentId: string;
  onClose: () => void;
};

export default function StripePaymentDetails({
  paymentIntentId,
  onClose,
}: StripePaymentDetailsProps) {
  const [stripeConnectInstance] = useState(() => {
    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? "",
      fetchClientSecret: () =>
        stripeAccountSession().then((data) => data.value),
      appearance: {
        overlays: "dialog",
        variables: {
          colorPrimary: "#625afa",
        },
      },
    });
  });

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <ConnectPaymentDetails payment={paymentIntentId} onClose={onClose} />
    </ConnectComponentsProvider>
  );
}
