import { useState } from "react";

import { Typography } from "@mui/material";

import { properStrings } from "../../utils/StringUtils";
import MessageComponent from "../clients/MessageComponent";
import MaterialForm from "../common/form/MaterialForm";
import ExternalMessaging from "./ExternalMessaging";

export default function QuickMessage({ contactId, contacts = [], clientId }) {
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [contact, setContact] = useState(contactId);
  return (
    <>
      {!contact && (
        <Typography variant="h6" className="font-semibold">
          Whom Would You Like To Message ?
        </Typography>
      )}
      {contact && (
        <ExternalMessaging
          contactIds={[contact || []]}
          refreshFlag={refreshFlag}
        />
      )}
      <div className="w-full">
        <MaterialForm
          data={{}}
          renderArray={[
            {
              name: "contact",
              label: "Contacts",
              inputType: "SELECT",
              options: contacts
                ?.filter((contact) => contact)
                .map((contact) => {
                  return {
                    label: properStrings([contact.firstName, contact.lastName]),
                    value: contact.contactId,
                  };
                }),
              defaultValue: contact,
            },
          ]}
          onSubmit={(_) => {}}
          onChange={({ value }) => {
            setContact(value);
          }}
        />
      </div>

      {contact && (
        <MessageComponent
          contactId={contact}
          clientId={clientId}
          setRefreshFlag={setRefreshFlag}
        />
      )}
    </>
  );
}
