import { useEffect, useState } from "react";

import { Button } from "@mui/material";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import {
  createSupplier,
  getSupplier,
  listSupplierContacts,
  removeLocation,
  removeSupplierContact,
} from "../../api/Api";
import { DetailsIcon } from "../../components/clients/ClientAppIcons";
import {
  AddIconBlue,
  DeleteIcon,
  SearchIcon,
} from "../../components/common/AppIcons";
import HeadlessDropdown from "../../components/common/HeadlessDropdown";
import MaterialModal from "../../components/common/MaterialModal";
import MaterialForm from "../../components/common/form/MaterialForm";
import { supplierSubCategory } from "../../components/common/form/constants";
import { getTerritoryFromZIP } from "../../components/crm/chat/zip";
import CreateBuilding from "../../components/suppliers/CreateBuilding";
import CreateContact from "../../components/suppliers/CreateContact";
import { deepUpdate, lowerCase } from "../../utils/StateUtils";
import { properString, renderPhone } from "../../utils/StringUtils";
import { SupplierContactsData, SupplierDetailsData } from "./Service";

type SupplierDetailsProps = {
  supplierId: number;
};

export default function SupplierDetails({ supplierId }: SupplierDetailsProps) {
  const [data, setData] = useState<SupplierDetailsData>();

  const [edit, setEdit] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [searchLocationText, setSearchLocationText] = useState("");
  const [searchContactText, setSearchContactText] = useState("");
  const [selectedContact, setSelectedContact] =
    useState<SupplierContactsData>();

  const [contacts, setContacts] = useState<SupplierContactsData[]>();

  useEffect(() => {
    getSupplier(supplierId)
      .then((data) => {
        setData(data as SupplierDetailsData);
      })
      .catch((_) => alertError("Error Loading Supplier"));
    listSupplierContacts(supplierId)
      .then((contacts) => {
        setContacts(contacts as SupplierContactsData[]);
      })

      .catch((_) => alertError("Error Loading Contacts"));
  }, [supplierId]);

  const { withConfirmation } = useAppActions();

  const formCommercial = (contacts: SupplierContactsData[]) => {
    let basic = [
      {
        name: "commercialClient.billingAddress",
        inputType: "ADDRESS",
        variant: "STANDARD",
      },

      {
        name: "typeOfSupplies",
        inputType: "SELECT",
        variant: "MULTISELECT",
        defaultValue: [],
        options: supplierSubCategory,
      },
    ];

    return [{ name: "name" }, ...basic];
  };

  const onChange = (update: {
    name: string;
    subOptions?: string[];
    value?: string;
  }) => {
    let { name, value } = update;

    if (name === "contact.email") {
      value = lowerCase(value);
    }

    if (name === "building.address.zip") {
      let territory = getTerritoryFromZIP(value);
      setData((data) => deepUpdate("building.territory", territory, data));
    }

    setData((data) => deepUpdate(name, value, data));
  };

  const onSubmit = () => {
    createSupplier({
      ...data,
      name: data?.name,
    })
      .then(() => {
        if (data?.supplierId) {
          alertSuccess("Successfully updated Supplier");
        }
        setEdit(false);
      })
      .catch((e) => {
        const errorMessage =
          e.readableMessage ??
          (data?.supplierId
            ? "Error Updating Supplier"
            : "Error Creating Supplier");
        alertError(errorMessage);
      });
  };

  const filteredContactList = contacts?.filter(
    (item) =>
      item.firstName?.toLowerCase().includes(searchContactText.toLowerCase()) ||
      item.lastName?.toLowerCase().includes(searchContactText.toLowerCase())
  );

  const filteredLocationList = data?.buildings?.filter(
    (item) =>
      item.address?.streetAddress1
        ?.toLowerCase()
        .includes(searchLocationText.toLowerCase()) ||
      item.address?.city
        ?.toLowerCase()
        .includes(searchLocationText.toLowerCase())
  );

  const suppliesList = data?.typeOfSupplies?.map((item) =>
    properString(item.toLowerCase())
  );
  const supplierInfoList = [
    {
      label: "Primary Contact",
      value: data?.name ?? "---",
    },

    {
      label: "Street Address 1",
      value: data?.commercialClient?.billingAddress.streetAddress1 ?? "---",
    },
    {
      label: "City",
      value: data?.commercialClient?.billingAddress.city ?? "---",
    },
    {
      label: "State",
      value: data?.commercialClient?.billingAddress.state ?? "---",
    },
    {
      label: "Zip",
      value: data?.commercialClient?.billingAddress.zip ?? "---",
    },
    {
      label: "Types of Supplies",
      value: suppliesList ? `${suppliesList.join(", ")}` : "---",
    },
  ];
  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex flex-col border border-newGray-1500 w-80 rounded self-start mt-4 ml-4">
        {edit ? (
          <div>
            <MaterialForm
              data={data}
              renderArray={formCommercial(contacts ?? [])}
              onSubmit={onSubmit}
              onChange={onChange}
            />
            <div className="flex flex-row-reverse w-full">
              <Button onClick={() => setEdit(false)} className="p-4">
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                color="primary"
                className="p-4 create-associate"
              >
                Save
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-row justify-between items-center p-4 border-b border-newGray-1500">
              <div className="font-semibold text-sm">Supplier Details</div>
              <div
                className="text-newBlue-400 font-semibold text-sm cursor-pointer"
                onClick={() => setEdit(true)}
              >
                Edit
              </div>
            </div>
            {supplierInfoList.map((item) => (
              <div className="mt-2 border-b p-1 px-4 border-newGray-1500">
                <div className="font-semibold text-sm">{item.label}</div>
                <div className="text-newGray-800 text-sm">{item.value}</div>
              </div>
            ))}
          </>
        )}
      </div>
      <div className="flex flex-col  w-full">
        <div className="mt-4 ml-4">
          <div className="flex flex-col md:flex-row gap-2 justify-between items-start">
            <div className="flex flex-row gap-2 justify-between w-full items-center">
              <div className="font-semibold text-sm ">Supplier Locations</div>
              <button
                className="border rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400"
                onClick={() => {
                  setOpenLocationModal(true);
                }}
              >
                <div className="flex flex-row items-center">
                  <div>
                    <AddIconBlue className="h-4 w-4" />
                  </div>
                  <div className="px-2 flex gap-1">
                    <span className="inline">Add</span>
                    <span className="hidden md:inline">
                      New Supplier Location
                    </span>
                  </div>
                </div>
              </button>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-row gap-2 items-center ">
                <div>
                  <div className="flex flex-row items-center px-2 rounded bg-newGray-1400 ">
                    <div>
                      <SearchIcon />
                    </div>
                    <input
                      id="buildings_search"
                      className="focus:outline-none p-2 text-gray-700 bg-newGray-1400"
                      placeholder="Search..."
                      value={searchLocationText}
                      onChange={(e) => {
                        setSearchLocationText(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            {filteredLocationList?.map((item) => (
              <div className="flex flex-row border rounded mt-2 p-5  border-newGray-1500 justify-between items-center mb-2 ">
                <div className="font-semibold text-sm ">{`${item.address?.streetAddress1}, ${item.address?.city} ${item.address?.zip}`}</div>

                <button
                  className="border rounded text-sm px-2 py-1 bg-newGray-700 text-newGray-800"
                  onClick={() => {
                    withConfirmation({
                      title: "Remove Location",
                      description: `Are you sure you want to remove ${item.address?.streetAddress1}, ${item.address?.city} ${item.address?.zip} 
                        This action cannot be undone.`,
                      onConfirm: () =>
                        removeLocation(item.buildingId)
                          .then((_res) => {
                            alertSuccess("Location removed successfully");
                            getSupplier(supplierId).then((data) => {
                              setData(data as SupplierDetailsData);
                            });
                          })
                          .catch((_err) => {
                            alertError("Failed to remove location");
                          }),
                    });
                  }}
                >
                  <div className="flex flex-row items-center">
                    <div>
                      <DeleteIcon className="h-4 w-4" />
                    </div>
                    <div className="px-2">Remove</div>
                  </div>
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-10 ml-4">
          <div className="flex flex-col md:flex-row gap-2 justify-between items-start">
            <div className="flex flex-row gap-2 justify-between w-full items-center">
              <div className="font-semibold text-sm ">Contacts</div>

              <button
                className="border rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400"
                onClick={() => {
                  setOpenContactModal(true);
                }}
              >
                <div className="flex flex-row items-center">
                  <div>
                    <AddIconBlue className="h-4 w-4" />
                  </div>
                  <div className="flex gap-1">
                    <span>Add</span>
                    <span className="hidden md:inline"> New Contact</span>
                  </div>
                </div>
              </button>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-row gap-2 items-center ">
                <div>
                  <div className="flex flex-row items-center px-2 rounded bg-newGray-1400 ">
                    <div>
                      <SearchIcon />
                    </div>
                    <input
                      id="buildings_search"
                      className="focus:outline-none p-2 text-gray-700 bg-newGray-1400"
                      placeholder="Search..."
                      value={searchContactText}
                      onChange={(e) => setSearchContactText(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            {filteredContactList?.map((item) => (
              <div className="flex flex-row border rounded mt-2 p-5  border-newGray-1500 justify-between items-center mb-2 ">
                <div className="flex flex-col md:flex-row flex-1 gap-2 justify-between">
                  <div className="font-semibold text-sm w-2/7">{`${item.firstName} ${item.lastName}`}</div>
                  <div className="w-1/7">
                    {item.primary && (
                      <div className="border rounded text-sm font-semibold py-1 bg-newOrange-400 text-newOrange-500 w-20">
                        Primary
                      </div>
                    )}
                  </div>

                  <div className="text-sm">{renderPhone(item.phone)}</div>
                  <div className="text-sm break-words">{item.email}</div>
                </div>

                <div className="flex pl-4 w-1/7 justify-end">
                  <HeadlessDropdown
                    value={"Options"}
                    options={["Edit", "Remove"]}
                    onChange={(value) => {
                      if (value === "Edit") {
                        setSelectedContact(item);
                        setOpenContactModal(true);
                      }
                      if (value === "Remove") {
                        withConfirmation({
                          title: "Remove Contact",
                          description: `Are you sure you want to remove ${item.firstName} ${item.lastName}  
                        This action cannot be undone.`,
                          onConfirm: () =>
                            removeSupplierContact(supplierId, item.contactId)
                              .then((_res) => {
                                alertSuccess("Contact removed successfully");
                                listSupplierContacts(supplierId).then(
                                  (contacts) => {
                                    setContacts(
                                      contacts as SupplierContactsData[]
                                    );
                                  }
                                );
                              })
                              .catch((_err) => {
                                alertError("Failed to remove Contact");
                              }),
                        });
                      }
                    }}
                  >
                    <div className="px-2 py-2 bg-gray-300 hover:bg-gray-400 rounded">
                      <DetailsIcon />
                    </div>
                  </HeadlessDropdown>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <MaterialModal open={openLocationModal} setOpen={setOpenLocationModal}>
        <CreateBuilding
          baseData={{ supplier: data }}
          successCB={() => {
            setOpenLocationModal(false);
            getSupplier(supplierId).then((data) => {
              setData(data as SupplierDetailsData);
            });
          }}
          onSubmitCB={undefined}
          onCancel={() => setOpenLocationModal(false)}
        />
      </MaterialModal>
      <MaterialModal open={openContactModal} setOpen={setOpenContactModal}>
        <CreateContact
          baseData={selectedContact}
          successCB={() => {
            setOpenContactModal(false);
            setSelectedContact({});
            listSupplierContacts(supplierId).then((contacts) => {
              setContacts(contacts as SupplierContactsData[]);
            });
          }}
          onSubmitCB={undefined}
          renderFlexComponent={undefined}
          locationId={undefined}
          supplierId={supplierId}
        />
      </MaterialModal>
    </div>
  );
}
