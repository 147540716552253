export default [
  {
    label: "Alabama",
    Abbrev: "Ala.",
    value: "AL",
  },
  {
    label: "Alaska",
    Abbrev: "Alaska",
    value: "AK",
  },
  {
    label: "Arizona",
    Abbrev: "Ariz.",
    value: "AZ",
  },
  {
    label: "Arkansas",
    Abbrev: "Ark.",
    value: "AR",
  },
  {
    label: "California",
    Abbrev: "Calif.",
    value: "CA",
  },
  {
    label: "Colorado",
    Abbrev: "Colo.",
    value: "CO",
  },
  {
    label: "Connecticut",
    Abbrev: "Conn.",
    value: "CT",
  },
  {
    label: "Delaware",
    Abbrev: "Del.",
    value: "DE",
  },
  {
    label: "District of Columbia",
    Abbrev: "D.C.",
    value: "DC",
  },
  {
    label: "Florida",
    Abbrev: "Fla.",
    value: "FL",
  },
  {
    label: "Georgia",
    Abbrev: "Ga.",
    value: "GA",
  },
  {
    label: "Hawaii",
    Abbrev: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    Abbrev: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    Abbrev: "Ill.",
    value: "IL",
  },
  {
    label: "Indiana",
    Abbrev: "Ind.",
    value: "IN",
  },
  {
    label: "Iowa",
    Abbrev: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    Abbrev: "Kans.",
    value: "KS",
  },
  {
    label: "Kentucky",
    Abbrev: "Ky.",
    value: "KY",
  },
  {
    label: "Louisiana",
    Abbrev: "La.",
    value: "LA",
  },
  {
    label: "Maine",
    Abbrev: "Maine",
    value: "ME",
  },
  {
    label: "Maryland",
    Abbrev: "Md.",
    value: "MD",
  },
  {
    label: "Massachusetts",
    Abbrev: "Mass.",
    value: "MA",
  },
  {
    label: "Michigan",
    Abbrev: "Mich.",
    value: "MI",
  },
  {
    label: "Minnesota",
    Abbrev: "Minn.",
    value: "MN",
  },
  {
    label: "Mississippi",
    Abbrev: "Miss.",
    value: "MS",
  },
  {
    label: "Missouri",
    Abbrev: "Mo.",
    value: "MO",
  },
  {
    label: "Montana",
    Abbrev: "Mont.",
    value: "MT",
  },
  {
    label: "Nebraska",
    Abbrev: "Nebr.",
    value: "NE",
  },
  {
    label: "Nevada",
    Abbrev: "Nev.",
    value: "NV",
  },
  {
    label: "New Hampshire",
    Abbrev: "N.H.",
    value: "NH",
  },
  {
    label: "New Jersey",
    Abbrev: "N.J.",
    value: "NJ",
  },
  {
    label: "New Mexico",
    Abbrev: "N.M.",
    value: "NM",
  },
  {
    label: "New York",
    Abbrev: "N.Y.",
    value: "NY",
  },
  {
    label: "North Carolina",
    Abbrev: "N.C.",
    value: "NC",
  },
  {
    label: "North Dakota",
    Abbrev: "N.D.",
    value: "ND",
  },
  {
    label: "Ohio",
    Abbrev: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    Abbrev: "Okla.",
    value: "OK",
  },
  {
    label: "Oregon",
    Abbrev: "Ore.",
    value: "OR",
  },
  {
    label: "Pennsylvania",
    Abbrev: "Pa.",
    value: "PA",
  },
  {
    label: "Rhode Island",
    Abbrev: "R.I.",
    value: "RI",
  },
  {
    label: "South Carolina",
    Abbrev: "S.C.",
    value: "SC",
  },
  {
    label: "South Dakota",
    Abbrev: "S.D.",
    value: "SD",
  },
  {
    label: "Tennessee",
    Abbrev: "Tenn.",
    value: "TN",
  },
  {
    label: "Texas",
    Abbrev: "Tex.",
    value: "TX",
  },
  {
    label: "Utah",
    Abbrev: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    Abbrev: "Vt.",
    value: "VT",
  },
  {
    label: "Virginia",
    Abbrev: "Va.",
    value: "VA",
  },
  {
    label: "Washington",
    Abbrev: "Wash.",
    value: "WA",
  },
  {
    label: "West Virginia",
    Abbrev: "W.Va.",
    value: "WV",
  },
  {
    label: "Wisconsin",
    Abbrev: "Wis.",
    value: "WI",
  },
  {
    label: "Wyoming",
    Abbrev: "Wyo.",
    value: "WY",
  },
].map((state) => {
  return {
    ...state,
    label: state.value,
  };
});
