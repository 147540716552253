import React, { useEffect, useState } from "react";

import BusinessIcon from "@mui/icons-material/Business";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LaunchIcon from "@mui/icons-material/Launch";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { Button, Typography } from "@mui/material";
import { navigate } from "raviger";

import {
  alertError,
  alertInfo,
  alertSuccess,
} from "../../actions/AlertActions";
import {
  createClient,
  createSR,
  createTicket,
  getTerritory,
} from "../../api/Api";
import states from "../../assets/states";
import { deepUpdate } from "../../utils/StateUtils";
import { lowerCase, properString } from "../../utils/StringUtils";
import { useIndexedDB } from "../../utils/indexed-db/indexed-hooks";
import MaterialModal from "../common/MaterialModal";
import MaterialTable from "../common/MaterialTable";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import { formTicketOrSR } from "../common/form/constants";
import { primaryContact } from "./CreateBuilding";

const formInitial = [
  {
    name: "type",
    inputType: "SELECT",
    options: [
      { label: "Commercial Client", value: "COMMERCIAL" },
      { label: "Residential Client", value: "RESIDENTIAL" },
    ],
  },
];

const formCommercial = (contacts, subAddressOptions) => {
  let basic = [
    {
      name: "commercialClient.subCategory",
      inputType: "SELECT",
      options: [
        { label: "Property Management", value: "PROPERY_MANAGEMENT" },
        { label: "HOA", value: "HOA" },
        { label: "Office", value: "OFFICE" },
        { label: "Realtor", value: "REALTOR" },
        { label: "Landlord", value: "LANDLORD" },
      ],
    },
    {
      name: "commercialClient.billingAddress",
      inputType: "ADDRESS",
    },
  ];
  if (contacts) {
    return [
      ...formInitial,
      { name: "clientName" },
      {
        name: "primaryContactId",
        inputType: "SELECT",
        options: primaryContact(contacts),
        defaultValue: primaryContact(contacts)[0]?.value,
      },
      ...basic,
    ];
  } else {
    return [...formInitial, { name: "clientName" }, ...basic];
  }
};

const formBuilding = (subAddressOptions, territories) => {
  return [
    { name: "building.propertyShortName" },
    {
      name: "building.buildingType",
      inputType: "SELECT",
      options: [
        { label: "SFH", value: "SFH" },
        { label: "High Rise Apartment", value: "HIGH_RISE" },
        { label: "Apartment", value: "APARTMENT" },
        { label: "Condo", value: "CONDO" },
        { label: "Office", value: "OFFICE" },
      ],
    },
    { name: "building.accessCode" },
    {
      name: "building.territory",
      inputType: "SELECT",
      options: territories,
    },

    {
      name: "building.address",
      inputType: "ADDRESS",
      variant: "STANDARD",
    },
  ];
};

const formContact = [
  { name: "contact.firstName" },
  { name: "contact.lastName" },
  { name: "contact.phone", inputType: "PHONE" },
  { name: "contact.email", inputType: "EMAIL" },
  {
    name: "contact.position",
    optional: true,
    inputType: "SELECT",
    options: [
      { label: "Manager", value: "MANAGER" },
      { label: "Owner", value: "OWNER" },
    ],
  },
];

export default function CreateClient({
  containerClass,
  clientData,
  onSubmitCB,
  setShow,
  successCB,
  onSuccess,
  contacts,
  displayInCol = false,
}) {
  const { add, getAll, deleteRecord } = useIndexedDB("client");
  const [data, setData] = useState(clientData ?? {});
  const [recoverDraft, setRecoverDraft] = useState(false);
  const [recovered, setRecovered] = useState([]);
  const [serviceRequest, setServiceRequest] = useState({});
  const [formCommercialAutofill, setFormRender] = useState(formInitial);
  const [subAddressOptions, setSubAddressOptions] = useState({
    building: [],
    commercial: [],
  });

  const [territories, setTerritories] = useState([]);

  useEffect(() => {
    getTerritory().then((res) => {
      let temp = [];
      res.map((item) => {
        temp.push({ value: item.name, label: properString(item.name) });
      });
      setTerritories(temp);
    });
  }, []);

  useEffect(() => {
    setFormRender(
      data.type === "COMMERCIAL"
        ? contacts
          ? formCommercial(contacts, subAddressOptions)
          : formCommercial("", subAddressOptions)
        : formInitial
    );
  }, [data.type, contacts, subAddressOptions]);

  useEffect(() => {
    setData(clientData ?? {});
  }, [clientData]);
  const getDrafts = () =>
    getAll().then((draft) => {
      setRecovered(draft);
    });
  useEffect(() => {
    getDrafts();
    return () => {
      setData((data) => {
        const toSave = JSON.stringify(data);
        data.clientId === undefined &&
          toSave.length > 50 &&
          add({ form: toSave }).then(
            (event) => {
              alertInfo("Saved Client Details " + JSON.stringify(data).length);
            },
            (error) => {
              alertError(
                error.readableMessage ?? "Couldn't Save Client Details"
              );
            }
          );
        return {};
      });
    };
  }, []);

  const onCreate = (clientId, buildingId) => {
    navigate(`/clients/${clientId}/summary/${buildingId}`);
  };

  const createServiceRequest = (clientId, buildingId) => {
    alertInfo("Saving Service Request");
    serviceRequest.confirmed === "true"
      ? createSR({
          ...serviceRequest,
          bookingStatus: serviceRequest.bookingStatus ?? "CREATED",
          building: { buildingId },
        })
          .then(() => onCreate(clientId, buildingId))
          .catch(() => alertError("Error Saving Service Request"))
      : createTicket({
          ...serviceRequest,
          clientId: clientId,
          pipelineId: 1,
        })
          .then((_) => onCreate(clientId, buildingId))
          .catch((_) => alertError("Error Creating Ticket"));
  };

  const recoverClient = (index) => {
    // console.log(recovered[index]);
    setData({ ...JSON.parse(recovered[index].form), recovered: true });
    deleteRecord(recovered[index].id);
    setRecoverDraft(false);
  };

  const onChange = (update) => {
    if (update.name === "building.address.streetAddress1") {
      setSubAddressOptions((current) => {
        return {
          ...current,
          building: update.subOptions || [],
        };
      });
    }
    if (update.name === "commercialClient.billingAddress.streetAddress1") {
      setSubAddressOptions((current) => {
        return {
          ...current,
          commercial: update.subOptions || [],
        };
      });
    }

    let { name, value } = update;

    if (name === "contact.email") {
      value = lowerCase(value);
    }

    setData((data) => deepUpdate(name, value, data));
  };
  const onChangeSR = (update) => {
    const { name, value } = update;
    if (name === "jobType" && value === "HANDYMAN" && data.subCategory !== "") {
      setServiceRequest((data) => deepUpdate("subCategory", "", data));
    }
    setServiceRequest((data) => deepUpdate(name, value, data));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (onSubmitCB) {
      console.log("Callback");
      return onSubmitCB(event);
    } else {
      createClient({
        ...data,
        clientName:
          data.type === "COMMERCIAL"
            ? data.clientName
            : `${data.contact?.firstName || ""} ${
                data.contact?.lastName || ""
              }`.trim() ?? data.clientName,
        buildings: [
          {
            ...data.building,
            contacts: [{ contact: data.contact }],
          },
        ],
      })
        .then((response) => {
          const { clientId, buildingIds, contactId } = response;
          const buildingId =
            (buildingIds && buildingIds.length > 0 && buildingIds[0]) || "";
          if (data.clientId) {
            alertSuccess("Successfully updated Client");
            successCB && successCB();
          } else {
            if (onSuccess)
              onSuccess({
                contactId: contactId,
                clientId: clientId,
              });
            setData({ ...data, clientId: clientId });
            alertSuccess("Successfully created Client");
            if (serviceRequest.jobType || serviceRequest.description)
              createServiceRequest(clientId, buildingId);
            else onCreate(clientId, buildingId);
          }
        })
        .catch((e) => {
          const errorMessage =
            e.readableMessage ??
            (data.clientId ? "Error Updating Client" : "Error Creating Client");
          alertError(errorMessage);
        });
    }
  };

  const contactForm = !data.clientId && data.type && (
    <div className="p-4 max-w-xs w-full">
      {data.type === "COMMERCIAL" && (
        <div className="flex gap-2">
          <BusinessIcon />
          <Typography variant="h5">Building Contacts</Typography>
        </div>
      )}
      <MaterialForm
        data={data}
        renderArray={formContact}
        onSubmit={onSubmit}
        onChange={onChange}
      />
    </div>
  );
  return (
    <div
      className={containerClass ?? "max-w-6xl flex flex-col mx-auto bg-white"}
    >
      {data.clientId === undefined &&
        recovered.length > 0 &&
        data.recovered !== true && (
          <div
            className="mx-auto p-1 mt-4 bg-gray-400 cursor-pointer text-sm rounded-lg text-white"
            onClick={(_) => setRecoverDraft(true)}
          >
            <LaunchIcon /> You have {recovered.length} unsaved clients. Click
            here to recover and save them.
          </div>
        )}
      <div
        className={
          "flex flex-col " + (displayInCol ? "lg:flex-col" : "lg:flex-row")
        }
      >
        <div className="p-4 max-w-xs w-full">
          <div className="flex gap-2">
            <PeopleAltIcon />
            <Typography variant="h5">Client Billing Address</Typography>
          </div>
          <MaterialForm
            data={data}
            renderArray={formCommercialAutofill}
            onSubmit={onSubmit}
            onChange={onChange}
          />
          {data.type === "RESIDENTIAL" && contactForm}
        </div>

        {!data.clientId && data.type && (
          <div className="p-4 max-w-xs w-full">
            <div className="flex gap-2">
              <BusinessIcon />
              <Typography variant="h5">
                {data.type === "RESIDENTIAL" && "Residential "}Building
              </Typography>
            </div>
            <MaterialForm
              data={data}
              renderArray={
                data.type === "COMMERCIAL"
                  ? formBuilding(subAddressOptions, territories)
                  : formBuilding(subAddressOptions, territories).slice(1)
              }
              onSubmit={onSubmit}
              onChange={onChange}
            />
          </div>
        )}

        {data.type === "COMMERCIAL" && contactForm}
        {!data.clientId &&
          data.type &&
          !clientData?.contact?.email &&
          !clientData?.contact?.phone && (
            <div className="p-4 max-w-xs w-full">
              <div className="flex gap-2">
                <Typography variant="h5">Service Request</Typography>
              </div>
              <MaterialForm
                data={serviceRequest}
                renderArray={formTicketOrSR(
                  serviceRequest,
                  data.building?.contacts || []
                )}
                onSubmit={(_) => {}}
                onChange={onChangeSR}
              />
            </div>
          )}
      </div>

      {data.type && clientData !== data && (
        <div className="w-full">
          <Button
            color="primary"
            className="float-left lg:float-right p-4 client-save-button"
            onClick={onSubmit}
          >
            Save
          </Button>
          <Button
            className="float-left lg:float-right p-4"
            onClick={(_) => setData(clientData ?? {})}
          >
            Cancel
          </Button>
        </div>
      )}
      {recoverDraft && (
        <MaterialModal
          open={recoverDraft}
          setOpen={(_) => setRecoverDraft(false)}
          label="new-user-modal"
          describedby="create-new-user"
        >
          <MaterialTable
            data={
              recovered?.map((draft, index) => {
                if (draft.form) {
                  const recoveredData = JSON.parse(draft.form);
                  return {
                    id: index,
                    data: [
                      `${index}`,
                      `${
                        recoveredData.clientName ??
                        `${recoveredData.contact?.firstName ?? "-"} ${
                          recoveredData.contact?.lastName ?? "-"
                        }`
                      }`,
                      <button
                        className="flex items-center hover:bg-blue-400 bg-dark text-white p-2 rounded-lg"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteRecord(draft.id).then(() => getDrafts());
                          setRecoverDraft(false);
                        }}
                      >
                        <DeleteForeverIcon /> Remove
                      </button>,
                    ],
                  };
                } else
                  return {
                    id: index,
                    data: [
                      `${index}`,
                      `Corrupt Data`,
                      <button
                        className="flex items-center hover:bg-blue-400 bg-dark text-white p-2 rounded-lg"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteRecord(draft.id).then(() => getDrafts());
                          setRecoverDraft(false);
                        }}
                      >
                        <DeleteForeverIcon /> Remove
                      </button>,
                    ],
                  };
              }) || []
            }
            head={[
              { id: "id", label: "ID" },
              { id: "clientName", label: "Client Name" },
              { id: "deleteIcon", label: "" },
            ]}
            defaultOrderBy={"id"}
            onClickCB={recoverClient}
            searchText={""}
            pageRows={5}
          />
        </MaterialModal>
      )}
    </div>
  );
}
