import { LeftArrowWithTailIcon } from "../AppIcons";

const DefaultCollapseView = ({ onClick }: { onClick: () => void }) =>
    <div
        className="p-2 flex flex-row items-center hover:bg-gray-200 rounded-md flex-grow-0 flex-shrink cursor-pointer"
        onClick={onClick}
    >
        <div>
            <LeftArrowWithTailIcon className="h-3 w-3" />
        </div>
        <div className="px-1 text-sm text-newBlue-400"> Back to List </div>
    </div>

export default function CollapsibleSelector({
    collapseWhen,
    collapseView = DefaultCollapseView,
    onExpand,
    children,
}: {
    collapseWhen: boolean;
    collapseView?: ({ onClick }: { onClick: () => void }) => JSX.Element;
    onExpand: () => void;
    children: JSX.Element;
}) {

    const CollapseViewComponent = collapseView;

    if (collapseWhen) {
        return <CollapseViewComponent onClick={onExpand} />;
    }
    return children;
}