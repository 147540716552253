import React, { useEffect } from "react";

import { TextField } from "@mui/material";

import PopOverClick from "../common/PopOverClick";

import { renderAddress } from "../../utils/StringUtils";
import { Autocomplete } from "@mui/material";

export default function SRTicketFilter({
  buildings,
  tickets,
  defaultBuilding,
  display,
  buildingChangeCB,
  ticketChangeCB,
  estimateChangeCB,
}) {
  useEffect(() => {
    if (defaultBuilding) {
      let building = buildings.find((building) => {
        if (building.buildingId === defaultBuilding) {
          return building;
        }
      });
      if (building)
        buildingChangeCB(
          building.buildingId,
          building.address.streetAddress1 +
            ", " +
            (building.address.streetAddress2
              ? building.address.streetAddress2
              : "") +
            ", " +
            (building.address.city ? building.address.city : "")
        );
    }
  }, []);

  const handleBuildingClose = (buildingId, propertyName) => {
    buildingChangeCB(buildingId, propertyName);
    // setSelectedBuilding({ id: buildingId, name: propertyName });
  };

  const handleEstimateClose = (status) => {
    estimateChangeCB(status);
  };

  return (
    <PopOverClick
      className="text-sm text-gray-700"
      renderPopOver={(_closePopOver) => (
        <div className="flex flex-col gap-2">
          <Autocomplete
            id="combo-box-demo"
            options={buildings}
            getOptionLabel={(building) =>
              renderAddress({
                streetAddress1: building.address.streetAddress1,
                streetAddress2: building.address.streetAddress2,
                city: building.address.city,
              })
            }
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Building" variant="outlined" />
            )}
            onChange={(_, building) => {
              if (building) {
                handleBuildingClose(
                  building.buildingId,
                  renderAddress({
                    streetAddress1: building.address.streetAddress1,
                    streetAddress2: building.address.streetAddress2,
                    city: building.address.city,
                  })
                );
              } else {
                handleBuildingClose(undefined, "");
              }
            }}
          />

          {tickets && (
            <div className="flex items-center">
              <Autocomplete
                id="combo-box-demo"
                options={tickets}
                getOptionLabel={(ticket) =>
                  "#T" +
                  ticket.ticketId +
                  " | " +
                  (ticket.title ?? ticket.description.substring(0, 20))
                }
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Ticket" variant="outlined" />
                )}
                onChange={(_, ticket) => {
                  ticketChangeCB(ticket);
                }}
              />
            </div>
          )}
          {display.includes("estimate_status") && (
            <div className="flex items-center">
              <Autocomplete
                id="combo-box-demo"
                options={[
                  { value: "ACCEPTED", display: "Accepted" },
                  { value: "NULL", display: "No Estimate" },
                  { value: "UNSENT", display: "Unsent" },
                  { value: "SENT", display: "Sent" },
                  {
                    value: "PARTIALLY_ACCEPTED",
                    display: "Partially Accepted",
                  },
                  { value: "REJECTED", display: "Rejected" },
                ]}
                getOptionLabel={(option) => option.display}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Estimate Status"
                    variant="outlined"
                  />
                )}
                onChange={(_, value) => {
                  if (value) {
                    handleEstimateClose(value.value);
                  } else {
                    handleEstimateClose(undefined);
                  }
                }}
              />
            </div>
          )}
        </div>
      )}
    >
      {/* Tailwind Styles for Material UI Button */}
      <div className="uppercase text-base text-gray-700 font-bold antialiased">
        Filter
      </div>
    </PopOverClick>
  );
}
