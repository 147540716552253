import React from "react";

const Dashboard = () => {
  return (
    <iframe
      title="Metabase"
      src="https://analytics.squadhub.pro/public/dashboard/3b4a691a-fdd4-40e1-aad1-a90f3bd459c5"
      frameborder="0"
      className="w-full h-screen"
      allowtransparency
    ></iframe>
  );
};

export default Dashboard;
