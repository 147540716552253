import React, { useState, useEffect } from "react";
function UnreadMessage({ conversation, appState }) {
  const [show, setShow] = useState(false);
  const [unReadCount, setUnReadCount] = useState();
  useEffect(() => {
    // conversation.setAllMessagesRead();
    conversation
      .getUnreadMessagesCount()
      .then((count) => {
        if (count > 0) {
          setShow(true);
          setUnReadCount(count);
        }
      })
      .catch((error) => {
        console.log("issue", error);
      });
  }, [conversation, appState]);
  return show ? (
    <p className="text-sm font-medium text-red-900 truncate mx-2">
      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
        {unReadCount}
      </span>
    </p>
  ) : (
    <></>
  );
}

export default UnreadMessage;
