import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import ConversationsMessages from "./ConversationsMessages";
import PropTypes from "prop-types";
import audio from "../../../assets/not.wav";
import softAudio from "../../../assets/soft.wav";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import { messageRecieved } from "../../../actions/ChatActions";

import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";

import MenuItem from "@mui/material/MenuItem";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SMSIcon from "@mui/icons-material/Textsms";

function Conversation(props) {
  const [state, setState] = useState({
    newMessage: "",
    conversationProxy: props.conversationProxy,
    myIdentity: props.myIdentity,
    messages: props.messages || [],
    loadingState: props.loadingState,
    setMessageReceived: props.setMessageReceived,
    participantLeft: false,
  });

  useEffect(() => {
    setState({
      ...state,
      participantLeft:
        props.conversationProxy.participants.size === 1 ? true : false,
    });
  }, [props.conversationProxy.participants.size]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const open = Boolean(anchorEl);

  const canned_messages = [
    {
      "Thank you for connecting with Help Squad Do you have any other questions?":
        "Thank you for connecting with Help Squad Do you have any other questions?",
    },
    {
      "Need Full name/Address/email address":
        "Need Full name/Address/email address",
    },
    {
      "Would you mind sharing some photos?":
        "Would you mind sharing some photos?",
    },
    {
      "Sure we would be more than happy to help you with that :)":
        "Sure we would be more than happy to help you with that :)",
    },
    {
      "Here is the price list for Los Angeles and Orange County":
        "Here is the price list for Los Angeles and Orange County",
    },
    {
      "Price List": `
    Here is the price list for Los Angeles and Orange County.

    Handyman
      Basic Handyman Services
      $85/h min Two hours plus material (we provide receipts)
      ***Larger scope of service we provide free estimate\n

    Plumbing
      $95 for basic service like replacing toilet tank flapper or stop valve
      $150-$180 for regular drain snaking
      $350 plus for heavy duty snake specially for mainline.
      ***Additional plumbing services like changing shower valve etc. We provide free estimate

    Electric
      Basic service is $150/h
      Additional service like installing new panel/recessed lights/running new wires we provide free estimate
      *Any job requiring City Permits will be an additional fee.\n`,
    },
  ];

  const handleClick = (event) => {
    setMenuOpen((state) => !state);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setMenuOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (state.conversationProxy) {
      state.conversationProxy.setAllMessagesRead();
      // loadMessagesFor(state.conversationProxy);
      if (!props.boundConversations.has(state.conversationProxy)) {
        let newConversation = state.conversationProxy;
        newConversation.on("participantLeft", (m) => {
          setState((state) => ({
            ...state,
            participantLeft: true,
          }));
          console.log("participant left");
        });
        newConversation.on("messageAdded", (m) => {
          if (m.author !== props.myIdentity) {
            messageRecieved();
            if (document.hidden) {
              var ChirpChirp = new Audio(audio);
              ChirpChirp.play();
            } else {
              var softAudio_ = new Audio(softAudio);
              softAudio_.play();
            }
          }
          messageAdded(m, newConversation);
        });
        props.addToBoundConversations(newConversation);
      }
    }
    return () => {
      console.log("unmounting");
    };
  }, []);

  useEffect(() => {
    let logic =
      state.loadingState === "initializing" ||
      state.conversationProxy !== props.conversationProxy;
    if (logic) {
      setState((state) => ({
        ...state,
        loadingState: "ready",
        conversationProxy: props.conversationProxy,
        messages: props.messages,
      }));
    }
  }, [props]);

  const messageAdded = (message, targetConversation) => {
    if (targetConversation === state.conversationProxy) {
      setState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, message],
      }));
    }
  };

  const onMessageChanged = (event) => {
    const newMessage = event.target.value;
    setState((state) => ({ ...state, newMessage: newMessage }));
  };

  const sendMessage = (event) => {
    event.preventDefault();
    console.log("Sending Message");
    const message = state.newMessage.replace(/ /g, "") ? state.newMessage : "";
    setState((state) => ({ ...state, newMessage: "" }));
    message.length > 0 && state.conversationProxy.sendMessage(message);
  };

  const onDrop = (acceptedFiles) => {
    console.log("Dropped files", acceptedFiles);
    state.conversationProxy.sendMessage({
      contentType: acceptedFiles[0].type,
      media: acceptedFiles[0],
    });
  };

  const handleSelectFile = (event) => {
    var files = event.target.files;
    Array.from(files).forEach((file) => {
      state.conversationProxy.sendMessage({
        contentType: file.type,
        media: file,
      });
    });
  };

  return (
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps()}
          onClick={() => {}}
          className="h-full"
          id="OpenChannel"
        >
          {isDragActive && (
            <div className="w-full h-full bg-blue-500 z-30 absolute flex justify-center items-center flex-col">
              <span className="text-white text-4xl">Cloud Upload</span>
              <h3 className="text-white">Release to Upload</h3>
            </div>
          )}
          <div
            className={"h-full flex flex-col"}
            style={{
              filter: `blur(${isDragActive ? 4 : 0}px)`,
            }}
          >
            <input id="files" {...getInputProps()} />
            <div className={"flex-1 overflow-auto bg-white"}>
              <ConversationsMessages
                identity={props.myIdentity}
                messages={props.messages}
                open_image_popup={props.open_image_popup}
              />
            </div>
            {state.participantLeft && (
              <div className="w-full flex flex-col gap-2 items-center justify-center mb-2 ">
                <div className="mt-3 mb-5 m-auto px-1 py-px bg-gray-100 text-black-400 text-xs rounded tracking-wide">
                  The client has left the chat
                </div>
              </div>
            )}
            <div className="">
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                style={{ zIndex: 140 }}
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
              >
                {canned_messages.map((messageObject, index) => (
                  <MenuItem
                    key={index}
                    onClick={(_) => {
                      handleClose();

                      state.conversationProxy.sendMessage(
                        messageObject[Object.keys(messageObject)[0]]
                      );
                    }}
                  >
                    {Object.keys(messageObject)[0]}
                  </MenuItem>
                ))}
              </Menu>
              <form>
                <div className="w-full flex flex-row items-center">
                  <input
                    style={{
                      flexBasis: "100%",
                    }}
                    placeholder={"Type your message here..."}
                    type={"text"}
                    name={"message"}
                    className="w-80 inline-block placeholder-gray-800"
                    autoComplete={"off"}
                    disabled={
                      state.loadingState !== "ready" || state.participantLeft
                    }
                    onChange={onMessageChanged}
                    value={state.newMessage}
                  />

                  <label htmlFor="file_input">
                    <AttachFileIcon className="cursor-pointer" />
                  </label>
                  <Button
                    id="fade-button"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : "false"}
                    onClick={handleClick}
                  >
                    <SMSIcon />
                  </Button>

                  <input
                    id="file_input"
                    type="file"
                    placeholder={<AttachFileIcon className="cursor-pointer" />}
                    disabled={state.participantLeft}
                    className="hidden"
                    onChange={handleSelectFile}
                    multiple
                  />

                  <button onClick={sendMessage} className=""></button>
                </div>
                {/* <Button
                  id="fade-button"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : "false"}
                  onClick={handleClick}
                >
                  Replies
                  <ArrowDropDownIcon />
                </Button> */}
              </form>
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
}

Conversation.propTypes = {
  myIdentity: PropTypes.string.isRequired,
};

export default Conversation;
