import React from "react";

import { AddIconBlue } from "./AppIcons";

export default function NoDataComponent({
  icon,
  buttonDisplay = true,
  lines = [],
  buttonText,
  buttonAction,
}) {
  return (
    <div className="p-4 rounded-lg border border-newGray-500 flex flex-col items-center text-newGray-800 text-sm">
      <div className="py-2">{icon}</div>
      {lines.map((line, index) => (
        <div>{line}</div>
      ))}
      {buttonDisplay && (
        <div className="py-4">
          <button
            className="text-newBlue-400 bg-newBlue-500 px-2 py-1 rounded text-sm"
            onClick={() => {
              buttonAction();
            }}
          >
            <div className="flex flex-row items-center">
              <div className="px-1">
                <AddIconBlue className="h-4 w-4" />
              </div>
              <div>{buttonText}</div>
            </div>
          </button>
        </div>
      )}
    </div>
  );
}
