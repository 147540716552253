import { useEffect, useState } from "react";

import { getMyVendor, initiateStripe, stripeInfo } from "../../api/Api";
import StripeTabs from "./StripeTabs";

export type VendorDetailsData = {
  contractorLicenseExpiry: string;
  contractorLicenseNumber: string;
  iconUrl: string;
  id: number;
  logoUrl: string;
  name: string;
  primaryContactNumber: string;
  supportEmail: string;
  territory: string;
  type: string;
  licenseDetails: string;
  estimateTerms: string;
  invoiceTerms: string;
  stripeInfoId: number | null;
  estimateTermsAndConditions: string;
  invoiceTermsAndConditions: string;
};

export type StripeData = {
  isAccountChargesEnabled: boolean;
  isAccountDetailsSubmitted: boolean;
};

export default function StripePayments() {
  const [vendor, setVendor] = useState<VendorDetailsData>();
  const [stripeData, setStripeData] = useState<StripeData>();
  useEffect(() => {
    getMyVendor().then((data: VendorDetailsData) => {
      setVendor(data);

      if (data.stripeInfoId !== null) {
        stripeInfo().then((data: StripeData) => setStripeData(data));
      }
    });
  }, []);

  return (
    <div className="flex  flex-col justify-between items-center pt-6 px-4 h-fit mb-10">
      {vendor?.stripeInfoId === null && (
        <div className="flex flex-col justify-between w-max border border-newGray-400 bg-newGray-100 rounded  p-4 px-10">
          <span className="text-sm font-medium ">
            Would you like to create a Stripe Account to receive payments?
          </span>
          <button
            className="w-max self-center text-white bg-newBlue-400 border border-white rounded px-2 mt-4"
            onClick={() =>
              initiateStripe().then((data) => {
                console.log({ data });
                getMyVendor().then((data: VendorDetailsData) =>
                  setVendor(data)
                );
              })
            }
          >
            Yes
          </button>
        </div>
      )}
      {vendor?.stripeInfoId !== null && (
        <StripeTabs vendor={vendor} stripeData={stripeData} />
      )}
    </div>
  );
}
