import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import {
  deleteStage,
  getAllPipeLineStages,
  updatePipelineStage,
} from "../../api/Api";
import { DetailsIcon } from "../../components/clients/ClientAppIcons";
import { AddBox, DeleteIcon } from "../../components/common/AppIcons";
import HeadlessDropdown from "../../components/common/HeadlessDropdown";
import MaterialModal from "../../components/common/MaterialModal";
import { SuspenseBoundary } from "../../components/common/core/SuspenseBoundary";
import { properString } from "../../utils/StringUtils";
import CreateStage from "./components/CreateStage";
import SetStageRule from "./components/SetStageRule";
import { Stage } from "./service";

type PipeLineTabProps = {
  pipeLineId: number;
  pipeLineName: string;
};

type CreateStageType = {
  pipelineId?: number;
  display: boolean;
  isEdit: boolean;
  finalStage?: boolean;
  stageId?: number;
  stageName?: string;
};

export default function PipelineTab({
  pipeLineId,
  pipeLineName,
}: PipeLineTabProps) {
  const [stageData, setStageData] = useState<Stage[]>();
  const [changeRule, setChangeRule] = useState({
    display: false,
  });
  const [createStage, setCreateStage] = useState<CreateStageType>({
    display: false,
    isEdit: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [selectedStage, setSelectedStage] = useState<Stage>();
  useEffect(() => {
    getAllPipeLineStages(pipeLineId)
      .then((data) => {
        setStageData(data);
      })
      .catch((_) => alertError("Error Loading pipelines"));
  }, [pipeLineId]);

  const { withConfirmation } = useAppActions();

  return (
    <div className="flex m-4">
      <SuspenseBoundary
        name="Supplier Details"
        waitFor={stageData}
        fallback={undefined}
        fallbackClassName={undefined}
      >
        <>
          <div className="flex">
            <DragDropContext
              onDragEnd={(result) => {
                const { draggableId, source, destination } = result;
                if (!destination) return;

                setIsLoading(true);
                updatePipelineStage(source.droppableId, draggableId, [
                  {
                    op: "replace",
                    path: "/level",
                    value: destination?.index,
                  },
                ])
                  .then(() => {
                    getAllPipeLineStages(pipeLineId)
                      .then((data) => {
                        setStageData(data);
                        setIsLoading(false);
                      })
                      .catch((_) => setIsLoading(false));
                    alertSuccess("Successfully updated stage");
                  })
                  .catch((_) => setIsLoading(false));
              }}
            >
              <Droppable droppableId={`${pipeLineId}`} direction="horizontal">
                {(provided) => (
                  <div
                    className="flex flex-row"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {stageData?.map((item) => {
                      return (
                        <Draggable
                          key={`${item.stageId}`}
                          draggableId={`${item.stageId}`}
                          index={item.level}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="bg-gray-200 p-4 mr-4 w-96 rounded"
                            >
                              {isLoading ? (
                                <div className="flex w-full h-12 animate-pulse "></div>
                              ) : (
                                <>
                                  <div className="flex flex-row justify-between items-center">
                                    <div>{item.stage}</div>
                                    <div>
                                      <HeadlessDropdown
                                        value={"Options"}
                                        options={[
                                          "Edit",
                                          "Delete",
                                          "Add/Edit Rule",
                                        ]}
                                        onChange={(value) => {
                                          if (value === "Edit") {
                                            setCreateStage({
                                              pipelineId: pipeLineId,
                                              display: true,
                                              isEdit: true,
                                              finalStage: selectedStage?.final,
                                              stageId: selectedStage?.stageId,
                                              stageName: selectedStage?.stage,
                                            });
                                          }
                                          if (value === "Delete") {
                                            withConfirmation({
                                              title: "Remove Stage",
                                              description: `Are you sure you want to delete ${selectedStage?.stage} 
                    This action cannot be undone.`,
                                              onConfirm: () =>
                                                deleteStage(
                                                  pipeLineId,
                                                  selectedStage?.stageId
                                                )
                                                  .then((_res) => {
                                                    alertSuccess(
                                                      `${selectedStage?.stage} deleted successfully`
                                                    );
                                                    getAllPipeLineStages(
                                                      pipeLineId
                                                    ).then((data) => {
                                                      setStageData(data);
                                                    });
                                                  })
                                                  .catch((_err) => {
                                                    alertError(
                                                      `Failed to delete ${selectedStage?.stage}`
                                                    );
                                                  }),
                                            });
                                          } else if (
                                            value === "Add/Edit Rule"
                                          ) {
                                            setChangeRule({
                                              display: true,
                                            });
                                          }
                                        }}
                                      >
                                        <div
                                          className="px-2 py-2 bg-gray-300 hover:bg-gray-400 rounded"
                                          onClick={() => setSelectedStage(item)}
                                        >
                                          <DetailsIcon />
                                        </div>
                                      </HeadlessDropdown>
                                    </div>
                                  </div>
                                  <div>
                                    {item?.automationSelectors?.map(
                                      (automation) => {
                                        return (
                                          <div className="flex flex-row justify-between items-center bg-white p-2  my-2 w-full border rounded border-newBlue-400">
                                            <div className=" text-newBlue-400 font-medium ">{`${properString(
                                              automation
                                            )} Automation`}</div>
                                            <button
                                              className="flex border border-newGray-800 rounded p-2 items-center justify-center"
                                              onClick={() => {
                                                const remainingAutomation =
                                                  item?.automationSelectors?.filter(
                                                    (item) =>
                                                      item !== automation
                                                  );
                                                withConfirmation({
                                                  title: "Remove Automation",
                                                  description: `Are you sure you want to delete ${properString(
                                                    automation
                                                  )} 
                                  This action cannot be undone.`,
                                                  onConfirm: () =>
                                                    updatePipelineStage(
                                                      pipeLineId,
                                                      item.stageId,
                                                      [
                                                        {
                                                          op: "replace",
                                                          path: "/automationSelectors",
                                                          value:
                                                            remainingAutomation,
                                                        },
                                                      ]
                                                    )
                                                      .then(() => {
                                                        alertSuccess(
                                                          "Successfully updated rule"
                                                        );
                                                        getAllPipeLineStages(
                                                          pipeLineId
                                                        ).then((data) => {
                                                          setStageData(data);
                                                        });
                                                      })
                                                      .catch((_) =>
                                                        alertError(
                                                          "Error updating rule"
                                                        )
                                                      ),
                                                });
                                              }}
                                            >
                                              <DeleteIcon />
                                            </button>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                  <div>
                                    {item?.triggerSelectors?.map((trigger) => {
                                      return (
                                        <div className="flex flex-row justify-between items-center bg-white p-2  my-2 w-full border rounded border-newBlue-400">
                                          <div className=" text-newBlue-400 font-medium ">{`Triggers ${properString(
                                            trigger
                                          )}`}</div>
                                          <button
                                            className="flex border border-newGray-800 rounded p-2 items-center justify-center"
                                            onClick={() => {
                                              const remainingTriggers =
                                                item?.triggerSelectors?.filter(
                                                  (item) => item !== trigger
                                                );
                                              withConfirmation({
                                                title: "Remove Trigger",
                                                description: `Are you sure you want to delete ${properString(
                                                  trigger
                                                )} 
                                This action cannot be undone.`,
                                                onConfirm: () =>
                                                  updatePipelineStage(
                                                    pipeLineId,
                                                    item.stageId,
                                                    [
                                                      {
                                                        op: "replace",
                                                        path: "/triggerSelectors",
                                                        value:
                                                          remainingTriggers,
                                                      },
                                                    ]
                                                  )
                                                    .then(() => {
                                                      alertSuccess(
                                                        "Successfully updated rule"
                                                      );
                                                      getAllPipeLineStages(
                                                        pipeLineId
                                                      ).then((data) => {
                                                        setStageData(data);
                                                      });
                                                    })
                                                    .catch((_) =>
                                                      alertError(
                                                        "Error updating rule"
                                                      )
                                                    ),
                                              });
                                            }}
                                          >
                                            <DeleteIcon />
                                          </button>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <div>
            <button
              onClick={() =>
                setCreateStage({
                  pipelineId: pipeLineId,
                  display: true,
                  isEdit: false,
                })
              }
              className="focus:outline-none"
            >
              <AddBox className="h-10 w-10" />
            </button>
          </div>
        </>
      </SuspenseBoundary>

      {changeRule.display && (
        <MaterialModal
          open={changeRule.display}
          setOpen={(_) =>
            setChangeRule((current) => {
              return {
                ...current,
                display: false,
              };
            })
          }
          label="new-user-modal"
          describedBy="create-new-user"
        >
          <SetStageRule
            stageName={selectedStage?.stage}
            stageId={selectedStage?.stageId}
            pipelineId={pipeLineId}
            pipelineName={pipeLineName}
            automation={selectedStage?.automationSelectors}
            triggers={selectedStage?.triggerSelectors}
            isFinal={selectedStage?.final}
            onSubmit={() => {
              setChangeRule((current) => {
                return {
                  ...current,
                  display: false,
                };
              });
              getAllPipeLineStages(pipeLineId).then((data) => {
                setStageData(data);
              });
            }}
          />
        </MaterialModal>
      )}
      {createStage.display && (
        <MaterialModal
          open={createStage.display}
          setOpen={(_) =>
            setCreateStage((current) => {
              return {
                ...current,
                display: false,
              };
            })
          }
          label="new-user-modal"
          describedBy="create-new-user"
        >
          <CreateStage
            pipelineId={createStage.pipelineId}
            final={createStage.finalStage}
            isEdit={createStage.isEdit}
            stageId={createStage.stageId}
            stage={createStage.stageName}
            onSubmit={() => {
              setCreateStage({ display: false, isEdit: false });
              getAllPipeLineStages(pipeLineId).then((data) => {
                setStageData(data);
              });
            }}
          />
        </MaterialModal>
      )}
    </div>
  );
}
