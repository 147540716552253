import { Disclosure as HeadlessDisclosure } from "@headlessui/react";
import { Transition } from "@tailwindui/react";
import { useEffect, useState } from "react";

import usePrevious from "../../../hooks/usePrevious";
import { ChevronUpIcon } from "../AppIcons";

export default function Disclosure({
  collapsedChildren,
  onToggle,
  children,
}: {
  collapsedChildren: React.ReactNode;
  onToggle: (expand: boolean) => void;
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);
  return (
    <div className="mx-2">
      <div
        className="flex justify-between items-center cursor-pointer bg-blue-200 hover:bg-blue-300 rounded-md p-2"
        onClick={() => {
          setOpen((open) => !open);
          onToggle && onToggle(!open);
        }}
      >
        {collapsedChildren}
        <ChevronUpIcon
          className={`${
            open ? "" : "rotate-180 transform"
          } h-5 w-5 text-purple-500`}
        />
      </div>

      <Transition
        show={open}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        {children}
      </Transition>
    </div>
  );
}

export function DisclosureListener({
  open,
  onToggle,
  children,
}: {
  open: boolean;
  onToggle: (open: boolean) => void;
  children: React.ReactNode;
}) {
  const previousOpen = usePrevious(open);
  useEffect(() => {
    if (previousOpen !== undefined && previousOpen !== open) {
      onToggle(open);
    }
  }, [open, previousOpen, onToggle]);
  return <>{children}</>;
}
