import React, { useState } from "react";

import { LeftArrowIcon } from "../../../components/common/AppIcons";
import UnitAmenities from "./UnitAmenities";
import UnitGeneralInfo from "./UnitGeneralInfo";
import UploadUnitPhoto from "./UploadUnitPhoto";

type NewUnitScreenProps = {
  onBackClick: () => void;
  buildingId: number;
};

export type UnitData = {
  unitNumber: string;
  marketRent: number;
  unitSize: number;
  beds: number;
  bath: number;
  description: string;
  overridePetPolicy: boolean;
  overrideFeePolicy: boolean;
};

export default function NewUnitScreen({
  onBackClick,
  buildingId,
}: NewUnitScreenProps) {
  const [currentScreen, setCurrentScreen] = useState<
    "generalInfo" | "uploadPhoto" | "unitAmenities"
  >("generalInfo");
  const [unitId, setUnitId] = useState(0);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [unitData, setUnitData] = useState<UnitData>({
    unitNumber: "",
    marketRent: 0,
    unitSize: 0,
    beds: 0,
    bath: 0,
    description: "",
    overridePetPolicy: false,
    overrideFeePolicy: false,
  });

  return (
    <div className="flex flex-col">
      <button
        className="flex items-center focus:outline-none justify-self-start w-max"
        onClick={onBackClick}
      >
        <LeftArrowIcon className="h-5 w-5 text-black" />
        <div className="text-xl font-semibold text-black">
          Create Unit Profile
        </div>
      </button>
      {currentScreen === "generalInfo" && (
        <UnitGeneralInfo
          unitId={unitId}
          onBackClick={onBackClick}
          buildingId={buildingId}
          setCurrentScreen={setCurrentScreen}
          setUnitId={setUnitId}
          unitData={unitData}
          setUnitData={setUnitData}
        />
      )}
      {currentScreen === "uploadPhoto" && (
        <UploadUnitPhoto
          unitId={unitId}
          setCurrentScreen={setCurrentScreen}
          imageUrls={imageUrls}
          setImageUrls={setImageUrls}
          buildingId={buildingId}
        />
      )}
      {currentScreen === "unitAmenities" && (
        <UnitAmenities
          onBackClick={onBackClick}
          unitId={unitId}
          setCurrentScreen={setCurrentScreen}
          buildingId={buildingId}
        />
      )}
    </div>
  );
}
