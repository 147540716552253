import { navigate } from "raviger";
import { CloseIcon, CrossIcon, PenIcon, WrenchCrossIcon } from "../../components/common/AppIcons";
import { properString } from "../../utils/StringUtils";

type ComplianceStatusModalProps = {
    profileStatus: string;
    brandStatus: string;
    campaignStatus: string;
    onClose: () => void;
    onEdit: () => void;
}

export default function ComplianceStatusModal({
    brandStatus, campaignStatus, profileStatus, onClose, onEdit,
}: ComplianceStatusModalProps) {

    const getStatus = (status?: string) => {
        switch (status) {
            case "IN_REVIEW":
                return "border-yellow-700 text-yellow-700 bg-yellow-700 bg-opacity-10";
            case "FAILED":
                return "border-red-700 text-red-700 bg-red-700 bg-opacity-10";
            case "TWILIO_REJECTED":
                return "border-red-700 text-red-700 bg-red-700 bg-opacity-10";
            case "APPROVED":
                return "border-green-700 text-green-700 bg-green-700 bg-opacity-20";
            case "SUBMITTED":
                return "border-gray-700 text-gray-700 bg-gray-700 bg-opacity-20";
            case "REGISTERED":
                return "border-blue-700 text-blue-800 bg-blue-700 bg-opacity-20";
            default:
                return "border-gray-900 text-gray-900 bg-gray-300"
        }
    }

    return (
        <div className="flex flex-col p-4 md:w-96">
            <div className="flex flex-row justify-between">
                <div className="text-black text-base font-semibold ">Your Twilio A2P compliance status</div>
            </div>
            <div className=" flex flex-col my-4">
                <div className="flex flex-row justify-between items-center my-4">
                    <div className="text-base font-normal text-black">
                        Customer Profile Status:
                    </div>
                    <div className={`flex w-32 items-center py-2 justify-center border rounded ${getStatus(profileStatus)}`}>
                        {properString(profileStatus)}</div>
                </div>

                <div className="flex flex-row justify-between items-center my-4">
                    <div className="text-base font-normal text-black">
                        Brand Status:
                    </div>
                    <div className={`flex w-32 items-center py-2 justify-center border rounded ${getStatus(brandStatus)}`}>{brandStatus ? properString(brandStatus) : "Not Submitted"}</div>
                </div>

                <div className="flex flex-row justify-between items-center my-4">
                    <div className="text-base font-normal text-black">
                        Campaign Status:
                    </div>
                    <div className={`flex w-32 items-center py-2 justify-center border rounded ${getStatus(campaignStatus)}`}>{campaignStatus ? properString(campaignStatus) : "Not Submitted"}</div>
                </div>

            </div>
            <button className="flex flex-row items-center" onClick={onEdit}>
                <div ><PenIcon className=" h-4 w-4 text-newBlue-400 mr-1" /></div>
                <div className="text-newBlue-400 text-base font-normal">Edit Compliance Application</div>
            </button>
        </div>
    )
}