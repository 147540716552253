/* Untyped file generated from ChildForm.res by genType. */
/* eslint-disable */

import {default as makeNotChecked} from './ChildForm.jsx';

import * as React from 'react';

// In case of type error, check the type of 'make' in 'ChildForm.re' and './ChildForm.jsx'.
export const makeTypeChecked = makeNotChecked;

// Export 'make' early to allow circular import from the '.bs.js' file.
export const make = function ChildForm(Arg1) {
  const $props = {editable:Arg1.editable, data:Arg1.data, variant:Arg1.variant, name:Arg1.name, label:Arg1.label, className:Arg1.className, optional:Arg1.optional, setFocusChild:function (Arg11) {
      const result1 = Arg1.setFocusChild((Arg11 == null ? undefined : Arg11));
      return result1
    }, focusChild:Arg1.focusChild, renderFields:Arg1.renderFields, relatedCallback:Arg1.relatedCallback, onChange:Arg1.onChange};
  const result = React.createElement(makeTypeChecked, $props);
  return result
};
