import React, { useEffect, useRef, useState } from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { Button, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Switch from "@mui/material/Switch";
import moment from "moment";
import "moment-timezone";
import { Link } from "raviger";

import { updateStatusCSR } from "../../api/Api";
import { DraggableItem } from "../common/DraggableItem";
import MaterialModal from "../common/MaterialModal";
import PreviewLink from "../common/PreviewLink";
import ProgressSteps from "../common/ProgressSteps";
import TimeTile from "../common/TimeTile";

const getAssociate = (appointment) => {
  let ids = [];
  appointment.map((item, index) => {
    ids.push(item.associateId);
  });
  return ids;
};

const appointmentType = {
  PICKUP_AND_SERVICE: "Pickup and Service",
  PICKUP: "Pickup",
  SERVICE: "Service",
};

export const getAppointmentStatus = (currentStatus, appointmentType) => {
  let found = false;
  let value_list = { ENROUTE: 1, STARTED: 2, FINISHED: 3 };

  if (appointmentType === "PICKUP_AND_SERVICE") {
    value_list = { ENROUTE: 1, PICKED_UP: 2, STARTED: 3, FINISHED: 4 };
  }

  const value = value_list[currentStatus];

  let return_status = [];

  let status = [
    { label: "STARTED", value: "STARTED" },
    { label: "FINISHED", value: "FINISHED" },
  ];

  if (appointmentType === "PICKUP_AND_SERVICE") {
    status = [
      { label: "PICKED UP", value: "PICKED_UP" },
      { label: "STARTED", value: "STARTED" },
      { label: "FINISHED", value: "FINISHED" },
    ];
  } else if (appointmentType === "PICKUP") {
    status = [
      { label: "PICKED UP", value: "STARTED" },
      { label: "DELIVERED", value: "FINISHED" },
    ];
  }

  if (currentStatus) {
    if (currentStatus !== "STARTED" && currentStatus !== "PICKED_UP") {
      status = [{ label: "ENROUTE", value: "ENROUTE" }, ...status];
    }
  }

  if (!value) {
    return status;
  } else {
    status.map((item, index) => {
      if (index + 2 > value) {
        return_status.push(item);
      }
    });

    return return_status;
  }
};

export const getAppointmentSteps = (appointmentType) => {
  if (appointmentType === "PICKUP_AND_SERVICE") {
    return [
      "ASSIGNED",
      "ACCEPTED",
      "ENROUTE",
      "PICKED_UP",
      "STARTED",
      "FINISHED",
    ];
  } else if (appointmentType === "PICKUP") {
    return ["ASSIGNED", "ACCEPTED", "ENROUTE", "PICKED_UP", "DELIVERED"];
  } else {
    return ["ASSIGNED", "ACCEPTED", "ENROUTE", "STARTED", "FINISHED"];
  }
};

export const getCurrentStep = (appointmentType, status) => {
  if (appointmentType === "PICKUP") {
    if (status === "STARTED") {
      return "PICKED_UP";
    } else if (status === "FINISHED") {
      return "DELIVERED";
    }
  }
  return status ? status : "ASSIGNED";
};

export default function RenderAppointments({
  appointment,
  setLightboxImages,
  setEditAppointment,
  addAttachmentToSR,
  serviceRequest,
  setImagePopUp,
  getServiceRequestData,
}) {
  const fileRef = useRef();

  const [modal, setModal] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const [state, setState] = useState({
    appointmentId: appointment.appointmentId,
    status: appointment.status,
    attachments: attachments,
    notes: "",
    revisitRequired: false,
  });

  const [timeline, setTimeline] = useState({
    timelineStart: "",
    timelineEnd: "",
  });

  useEffect(() => {
    if (serviceRequest && appointment) {
      let timezone = serviceRequest.timezone;
      let currentOffset = moment().utcOffset();
      let requiredTimezoneOffset = moment().tz(timezone)?.utcOffset();

      setTimeline({
        timelineStart: moment(appointment?.timelineStart?.split("Z")[0]).add(
          requiredTimezoneOffset,
          "minutes"
        ),
        timelineEnd: moment(appointment?.timelineEnd?.split("Z")[0]).add(
          requiredTimezoneOffset,
          "minutes"
        ),
      });
    }
  }, [serviceRequest, appointment]);

  useEffect(() => {
    if (appointment.status !== state.status) {
      setModal(true);
    }
  }, [state.status]);

  const addEncodedFile = (setState, file) =>
    function (upload) {
      setState((attachments) => {
        return {
          ...state,
          attachments: [
            ...state.attachments,
            {
              fileName: file.name,
              contentType: file.type,
              encodedFile: upload.target.result,
            },
          ],
        };
      });
    };

  const handleSelectFile = (event) => {
    // console.log("Uploading to S3");
    var files = event.target.files;
    Array.from(files).forEach((file) => {
      // console.log("MIME:" + file.type);
      var reader = new FileReader();
      reader.onload = addEncodedFile(setState, file);
      reader.readAsDataURL(file);
    });
  };

  const renderAppointmentStatus = (status) => {
    if (
      status === "PICKED_UP" &&
      appointment.appointmentType === "PICKUP_AND_SERVICE"
    ) {
      return "PICKED UP";
    }

    if (appointment.appointmentType === "PICKUP") {
      if (status === "STARTED") {
        return "PICKED UP";
      } else if (status === "FINISHED") {
        return "DELIVERED";
      }
    }

    return status;
  };

  return (
    <>
      <div className="flex flex-col justify-center">
        <ProgressSteps
          steps={getAppointmentSteps(appointment.appointmentType)}
          currentStep={getCurrentStep(
            appointment.appointmentType,
            appointment.status
          )}
          appointmentType={appointment.appointmentType}
        />
        <div className="flex gap-2 items-center py-2 w-full justify-center">
          <CheckCircleOutlineIcon className="h-8 w-8" />
          <Button
            className="bg-gray-200 hover:bg-gray-400"
            variant="text"
            onClick={(_) => {
              if (appointment.status !== "FINISHED") {
                setModal(true);
              }
            }}
          >
            {renderAppointmentStatus(appointment.status)}
          </Button>

          {/* <span>{appointment.status}</span> */}
          <AccessTimeIcon className="h-8 w-8" />
          <TimeTile datetime={timeline.timelineStart} />
          <span>-</span>
          <TimeTile datetime={timeline.timelineEnd} />
          <span> </span>
          <PermIdentityIcon />
          {appointment.associates.map((associate_) => (
            <Link
              href={`/associates/${associate_.associateId}/messages`}
              className="py-2 hover:text-blue-700"
            >
              <span>{`${associate_.firstName ?? ""}`}</span>
              <br />
              <span>{`${associate_.lastName ?? ""}`}</span>
              <br />
              <span className="inline-flex sm:whitespace-pre items-center rounded px-2 py-0.5 text-xs font-medium bg-orange-400 text-white">
                {appointmentType[appointment.appointmentType]}
              </span>
            </Link>
          ))}

          <Button
            onClick={(_) => {
              let requiredTimezoneOffset = moment()
                .tz(serviceRequest.timezone)
                ?.utcOffset();

              setEditAppointment({
                appointmentType: appointment.appointmentType,
                appointmentId: appointment.appointmentId,
                shouldNotify: appointment.shouldNotify,
                serviceRequestId: serviceRequest.serviceRequestId,
                associateId: getAssociate(appointment.associates),
                startTime: moment(
                  appointment?.timelineStart?.split("Z")[0]
                ).add(requiredTimezoneOffset, "minutes"),
                endTime: moment(appointment?.timelineEnd?.split("Z")[0]).add(
                  requiredTimezoneOffset,
                  "minutes"
                ),
                timezone: serviceRequest.timezone,
                isRemedial: appointment.isRemedial,
                description: appointment.description,
              });
            }}
          >
            Edit Appointment
          </Button>
        </div>
        <div className="grid grid-cols-2">
          <div className="flex flex-col p-2 px-4">
            <Typography variant="h6">Before</Typography>
            {!appointment.beforeNotes && !appointment.beforeUrls && (
              <span className="p-2 my-2 bg-blue-200 rounded-lg text-gray-700">
                Yet to be updated
              </span>
            )}
            {appointment.beforeNotes && (
              <span className="p-2 my-2 bg-blue-200 rounded-lg text-gray-700">
                Associate Notes : {appointment.beforeNotes}
              </span>
            )}
            <div className="flex flex-wrap">
              {appointment.beforeUrls?.map((attachment, i) => (
                <div
                  key={i}
                  className="text-sm mt-1"
                  onClick={(_) => {
                    setImagePopUp({ display: true, photoIndex: i });
                    setLightboxImages(appointment.beforeUrls);
                  }}
                >
                  <DraggableItem item={attachment} dragCB={addAttachmentToSR}>
                    <PreviewLink url={attachment} />
                  </DraggableItem>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col p-2 px-4">
            <Typography variant="h6">After</Typography>
            {!appointment.afterNotes && !appointment.afterUrls && (
              <span className="p-2 my-2 bg-blue-200 rounded-lg text-gray-700">
                Yet to be updated
              </span>
            )}
            {appointment.afterNotes && (
              <span className="p-2 my-2 bg-blue-200 rounded-lg text-gray-700">
                Associate Notes : {appointment.afterNotes}
              </span>
            )}
            <div className="flex flex-wrap">
              {appointment.afterUrls?.map((attachment, i) => (
                <div
                  key={i}
                  className="text-sm mt-1"
                  onClick={(_) => {
                    setImagePopUp({ display: true, photoIndex: i });

                    setLightboxImages(appointment.afterUrls);
                  }}
                >
                  <DraggableItem item={attachment} dragCB={addAttachmentToSR}>
                    <PreviewLink url={attachment} tileView />
                  </DraggableItem>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {setModal && (
        <MaterialModal
          open={modal}
          setOpen={(_) => setModal(false)}
          label="new-user-modal"
          describedby="create-new-user"
        >
          <div className="flex items-center">
            <div className="flex flex-col">
              {(state.status === "STARTED" || state.status === "FINISHED") && (
                <>
                  <div className="">
                    <Button
                      style={{ color: "black", textTransform: "none" }}
                      onClick={(_) => {}}
                      variant="contained"
                      component="label"
                    >
                      Add Image
                      <input
                        type="file"
                        hidden
                        onChange={handleSelectFile}
                        ref={fileRef}
                        multiple
                      />
                    </Button>
                    {state.attachments?.map((attachment) => (
                      <p>
                        File: {attachment.fileName}
                        <CloseIcon
                          className="cursor-pointer"
                          onClick={(e) => {
                            let fileIndex = state.attachments.map(
                              (item, index) => {
                                if (item.fileName === attachment.fileName) {
                                  return index;
                                }
                              }
                            );
                            let revisedAttachment = state.attachments.splice(
                              fileIndex,
                              1
                            );
                            setAttachments(revisedAttachment);
                          }}
                        />
                      </p>
                    ))}
                  </div>
                  <div className="py-2">
                    <textarea
                      className="bg-gray-100 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"
                      id="inline-full-name"
                      placeholder="Add Notes"
                      type="text"
                      value={state.notes}
                      onChange={(e) =>
                        setState({ ...state, notes: e.target.value })
                      }
                      rows={3}
                    />
                  </div>
                </>
              )}
              {state.status === "FINISHED" && (
                <div className="">
                  Revisit Required:-{" "}
                  <Switch
                    style={{
                      color: state.revisitRequired ? "primary" : "#000",
                    }}
                    onChange={(e) => {
                      setState((current) => ({
                        ...current,
                        revisitRequired: e.target.checked,
                      }));
                    }}
                  />
                </div>
              )}
              <div className="py-3">
                <div className=" flex flex-row items-center">
                  <div>
                    <Autocomplete
                      id="combo-box-demo"
                      options={getAppointmentStatus(
                        state.status,
                        appointment.appointmentType
                      )}
                      getOptionLabel={(option) => option.label}
                      style={{ width: 250 }}
                      disableClearable
                      defaultValue={getAppointmentStatus(
                        "",
                        appointment.appointmentType
                      ).find((item) => {
                        if (item.label === state.status) {
                          return item.label[0];
                        }
                      })}
                      onChange={(event, newValue) => {
                        setState((state) => {
                          return {
                            ...state,
                            status: newValue.value,
                            attachments: [],
                            notes: "",
                          };
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Appointment Status"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <div className="">
                    <Button
                      style={{ color: "black", textTransform: "none" }}
                      onClick={(_) => {
                        let data = {
                          ...state,
                          status: state.status.toUpperCase(),
                        };
                        updateStatusCSR(data).then(() => {
                          setModal(false);
                          getServiceRequestData();
                        });
                      }}
                    >
                      Update Status
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MaterialModal>
      )}
    </>
  );
}
