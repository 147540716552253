import React, { useState } from "react";
import { useEffect } from "react";

import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import { TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { navigate } from "raviger";

import { alertError } from "../../actions/AlertActions";
import { listSupplier } from "../../api/Api";
import MaterialTable from "../common/MaterialTable";
import { extendedSkills } from "../common/form/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid \${theme.palette.divider}`,
  },
}));

const skillFormatted = (skill) => {
  let formatted = extendedSkills.find((item) => {
    if (item.value === skill) {
      return item;
    }
  });
  if (formatted) {
    return formatted.label;
  } else {
    return "";
  }
};

const types = {
  SQUAD_CELL: "Squad Cell",
  CONTRACTOR: "Contractor",
  SUPERVISOR: "Supervisor",
  THIRD_PARTY_VENDOR: "Third Party Vendor",
};

export default function ListSupplier() {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({ type: "OTHERS", territory: "ALL" });

  const init = () => {
    listSupplier()
      .then((data) => {
        setSuppliers(data);
        setLoading(false);
      })
      .catch((_err) => {
        alertError(
          "Sorry, we couldn't populate suppliers. Please try again later."
        );
      });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    init();
  }, [filter]);

  const handleChange = (event, newValue) => {
    setFilter((current) => {
      return { ...current, type: newValue };
    });
  };

  const gatherData = () => {
    return suppliers?.map((supplier) => {
      return {
        id: supplier.supplierId,
        data: [
          supplier.name,
          `${supplier.streetAddress1 || ""}, ${supplier.streetAddress2 || ""}`,
          supplier.city,
        ],
      };
    });
  };

  const gatherHead = () => {
    return [
      { id: "name", label: "Name" },
      { id: "address", label: "Billing Address" },
      { id: "city", label: "City" },
    ];
  };
  return (
    <div className="max-w-6xl mx-auto bg-white rounded-md p-4">
      <div className="flex flex-row items-center justify-between gap-3">
        <Typography className="p-2 text-gray-800" variant="h4">
          Suppliers
        </Typography>
      </div>
      <div className={classes.root}>
        <div
          className={
            classes.root +
            " max-w-6xl mx-auto w-full gap-2 flex-col p-4 rounded-md"
          }
        >
          <div className="flex items-center gap-2 cursor-pointer">
            <TextField
              id="search"
              className="flex-1"
              label="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              variant="outlined"
            />
            <div onClick={(_) => navigate("/suppliers/new")}>
              <AddCircleOutlineOutlined /> New Supplier
            </div>
          </div>
          <MaterialTable
            loading={loading}
            data={gatherData()}
            head={gatherHead()}
            defaultOrderBy={"firstName"}
            onClickCB={(supplier) =>
              navigate("/suppliers/" + supplier + "/details")
            }
            searchText={searchText}
          />
        </div>
      </div>
    </div>
  );
}
