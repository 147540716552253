// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AddressGen from "./Address.gen";

var make = AddressGen.make;

export {
  make ,
  
}
/* make Not a pure module */
