import React from "react";

import { Button, Typography } from "@mui/material";

import { getUrlExtension, processUrl } from "../common/PreviewLink";

export default function SRGallery({
  imgList,
  mediaUrls,
  srSelectedImages,
  onCheckCB,
  onSelectCB,
  onSubmitCB,
}) {
  return (
    <>
      <section className="overflow-hidden text-gray-700 ">
        <Typography variant="h6">Select Media</Typography>
        <br />
        <div className="container px-5 py-2 mx-auto lg:pt-12">
          <div className="flex flex-wrap w-full sm:grid sm:grid-cols-4 gap-2">
            {imgList.map((item, _index) => (
              <div className="container p-1 flex flex-col items-end gap-0.5">
                {getUrlExtension(item) === "pdf" && (
                  <a
                    href={processUrl(item)}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img
                      className="block object-fit object-center w-full  rounded-lg"
                      src={processUrl(item, "jpg")}
                      alt={processUrl(item)}
                    />
                  </a>
                )}

                {getUrlExtension(item) !== "pdf" && (
                  <img
                    onClick={() => {
                      onSelectCB(item);
                    }}
                    src={item}
                    className="block object-fit object-center w-full rounded-lg"
                    alt={"Gallery"}
                  />
                )}

                <input
                  type="checkbox"
                  checked={
                    mediaUrls?.includes(item) ||
                    srSelectedImages?.includes(item)
                      ? true
                      : false
                  }
                  className="checkbox rounded-full"
                  id="check2"
                  onChange={(e) => {
                    onCheckCB(e.target.checked, item);
                  }}
                  style={{
                    // position: "absolute",
                    // bottom: "-5px",
                    // right: "0px",
                    width: "30px",
                    height: "16px",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      <Button
        onClick={(_) => {
          onSubmitCB();
        }}
        color="primary"
        className="float-right p-4 material-add-btn"
      >
        Add
      </Button>
    </>
  );
}
