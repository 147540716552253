// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FileUploadGen from "./FileUpload.gen";

var make = FileUploadGen.make;

export {
  make ,
  
}
/* make Not a pure module */
