// Generated by ReScript, PLEASE EDIT WITH CARE


function skillFilter(associate, job) {
  switch (associate) {
    case "ELECTRICIAN" :
        if (job === "ELECTRICIAN") {
          return true;
        } else {
          return false;
        }
    case "HANDYMAN" :
        if (job === "HANDYMAN") {
          return true;
        } else {
          return false;
        }
    case "PLUMBER" :
        if (job === "PLUMBER") {
          return true;
        } else {
          return false;
        }
    case "SPECIALIST" :
        if (job === "SPECIALIST") {
          return true;
        } else {
          return false;
        }
    default:
      switch (job) {
        case "ELECTRICAL" :
        case "HANDYMAN" :
        case "PLUMBING" :
        case "SPECIALIST" :
            return false;
        default:
          return true;
      }
  }
}

export {
  skillFilter ,
  
}
/* No side effect */
