const SF = [
  94503, 94572, 94130, 94930, 94621, 94954, 94960, 94118, 94937, 94590, 94592,
  94133, 94610, 94660, 94502, 94108, 94804, 94929, 94801, 94965, 94605, 94924,
  94103, 94131, 94132, 94964, 94116, 94107, 94102, 94704, 94559, 94109, 94104,
  94158, 94972, 94952, 94710, 94941, 94971, 94946, 94973, 94702, 94720, 94122,
  95918, 94925, 94609, 94940, 94591, 94806, 94530, 90266, 94603, 94602, 94117,
  94115, 94111, 94939, 94124, 94708, 94956, 94110, 94904, 94127, 94903, 94945,
  94608, 94612, 94618, 94601, 94957, 94613, 94121, 94920, 94607, 94564, 94933,
  94589, 94703, 94850, 94950, 94709, 95476, 94707, 94805, 94901, 94949, 94112,
  94525, 94129, 94134, 94114, 94938, 94963, 94105, 94611, 94705, 94803, 94501,
  94123, 94947, 94569, 94547, 94606, 94619, 94970, 94706,
];

const LA = [
  90004, 90005, 90006, 90012, 90013, 90014, 90015, 90017, 90019, 90020, 90021,
  90024, 90025, 90026, 90027, 90028, 90029, 90031, 90032, 90034, 90035, 90036,
  90038, 90039, 90041, 90042, 90045, 90046, 90048, 90049, 90056, 90057, 90064,
  90065, 90066, 90067, 90068, 90069, 90071, 90077, 90094, 90095, 90210, 90211,
  90212, 90230, 90232, 90261, 90272, 90291, 90292, 90293, 90301, 90302, 90303,
  90304, 90401, 90402, 90403, 90404, 90405, 91001, 91006, 91007, 91011, 91024,
  91030, 91101, 91103, 91104, 91105, 91106, 91107, 91108, 91201, 91202, 91203,
  91204, 91205, 91206, 91207, 91208, 91210, 91316, 91401, 91403, 91411, 91423,
  91436, 91501, 91502, 91504, 91505, 91506, 91601, 91602, 91604, 91606, 91607,
  91706, 91731, 91732, 91733, 91754, 91770, 91775, 91776, 91780, 91801, 91803,
];

const OC = [
  92840, 92637, 92677, 92625, 92694, 92663, 90680, 92655, 92624, 92780, 92692,
  92845, 92843, 92782, 92620, 92841, 92653, 92612, 92808, 92606, 92861, 92647,
  92627, 92691, 92648, 92899, 92603, 92869, 92807, 92844, 92708, 92802, 92657,
  92704, 92617, 92626, 92673, 92701, 92618, 92705, 92865, 92660, 92604, 92649,
  92683, 92703, 92675, 92646, 92805, 92662, 92656, 92868, 92661, 92630, 92799,
  92602, 92614, 92706, 92867, 92672, 92707, 92629, 92651, 92866, 92697,
];

const SD = [
  92126, 92101, 92037, 92111, 92121, 92110, 92136, 92102, 92107, 92147, 92132,
  92026, 92029, 92115, 92116, 92109, 92106, 92071, 92021, 91942, 92114, 92127,
  92105, 92130, 92020, 92120, 92113, 92067, 92182, 92054, 92084, 92081, 91941,
  92075, 92124, 92187, 92010, 92083, 92011, 92056, 92104, 92025, 92145, 92119,
  92131, 92014, 92007, 92123, 92108, 92009, 92140, 91977, 92003, 92069, 92019,
  92103, 92134, 92161, 92122, 92091, 92078, 92117, 92024, 92008, 92064, 92129,
  92040, 91945, 92128,
];

export const getTerritoryFromZIP = (zipCode) => {
  zipCode = parseInt(zipCode);

  if (SF.includes(zipCode)) {
    return "SAN_FRANCISCO";
  } else if (LA.includes(zipCode)) {
    return "LOS_ANGELES";
  } else if (OC.includes(zipCode)) {
    return "ORANGE_COUNTY";
  } else if (SD.includes(zipCode)) {
    return "SAN_DIEGO";
  } else {
    return "";
  }
};
