import React, { useEffect, useState } from "react";

import BusinessIcon from "@mui/icons-material/Business";
import { Button, Typography } from "@mui/material";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import {
  createBuilding,
  getClient,
  getTerritory,
  listClientContacts,
} from "../../api/Api";
import states from "../../assets/states";
import { deepUpdate } from "../../utils/StateUtils";
import { properString } from "../../utils/StringUtils";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";

//

export const primaryContact = (contacts) => {
  let primaryContactOptions = [];
  contacts.forEach((contact) => {
    primaryContactOptions.push({
      value: contact.contactId,
      label: `${contact.firstName} ${contact.lastName}`,
    });
  });

  return primaryContactOptions;
};

export default function CreateBuilding({ baseData, onSubmitCB, successCB }) {
  const [data, setData] = useState(baseData ?? {});
  const [clientContacts, setClientContacts] = useState([]);
  const [subAddressOptions, setSubAddressOptions] = useState([]);
  const [territories, setTerritories] = useState([]);

  const onChange = (update) => {
    const { name, value } = update;
    if (update.name === "address.streetAddress1") {
      if (update.subOptions[0]) setSubAddressOptions(update.subOptions);
    }

    setData((data) => deepUpdate(name, value, data));
  };

  const formBuilding = (data, clientContacts) => {
    return [
      { name: "propertyShortName" },
      { name: "coverImage", inputType: "IMAGE" },
      {
        name: "buildingType",
        inputType: "SELECT",
        options: [
          { label: "SFH", value: "SFH" },
          { label: "High Rise Apartment", value: "HIGH_RISE" },
          { label: "Apartment", value: "APARTMENT" },
          { label: "Condo", value: "CONDO" },
          { label: "Office", value: "OFFICE" },
        ],
      },
      {
        name: "territory",
        inputType: "SELECT",
        options: territories,
      },
      {
        name: "primaryContactId",
        inputType: "SELECT",
        options: data.contacts
          ? primaryContact(data.contacts)
          : primaryContact(clientContacts),
        defaultValue: data.contacts
          ? primaryContact(data.contacts)[0]?.value
          : clientContacts
          ? data.primaryContactId
            ? data.primaryContactId
            : primaryContact(clientContacts)[0]?.value
          : primaryContact(clientContacts)[0]?.value,
      },
      { name: "accessCode" },
      {
        name: "address",
        inputType: "ADDRESS",
        variant: "STANDARD",
      },
    ];
  };

  const fetchData = () => {
    if (baseData.client)
      getClient(baseData.client.clientId)
        .then((clientData) => {
          setData({
            ...data,
            primaryContactId: clientData.primaryContactId,
            address: { state: "CA" },
          });
          return;
        })
        .catch((_) => alertError("Error Loading Client Buildings"));
  };

  useEffect(
    (_) => {
      fetchData();

      getTerritory().then((res) => {
        let temp = [];
        res.map((item) => {
          temp.push({ value: item.name, label: properString(item.name) });
        });
        setTerritories(temp);
      });

      const updateGeoCordinates = {
        geocodeLatitude: baseData?.geocodeLatitude,
        geocodeLongitude: baseData?.geocodeLongitude,
      };

      if (!baseData.contacts) {
        listClientContacts(baseData?.client.clientId).then((contacts) => {
          setClientContacts(contacts);
        });
      }

      setData((data) => {
        return { ...data, ...updateGeoCordinates };
      });
    },
    [baseData]
  );
  useEffect(
    (_) => {
      console.log("onChange", data);
    },
    [data]
  );
  const onSubmit = (event) => {
    event.preventDefault();
    if (onSubmitCB) {
      console.log("Callback");
      return onSubmitCB(event);
    } else {
      createBuilding(data)
        .then((newBuildingId) => {
          data.buildingId
            ? alertSuccess("Successfully Updated Building")
            : alertSuccess("Successfully Created Building");
          successCB && successCB(newBuildingId.value);
        })
        .catch((err) => {
          data.buildingId
            ? alertError("Error Updating Building")
            : alertError("Error Creating Building");
        });
    }
  };
  return (
    <div className="p-4 max-w-sm w-full">
      <div className="flex gap-2">
        <BusinessIcon />
        <Typography variant="h5">Building</Typography>
      </div>
      <MaterialForm
        data={data}
        renderArray={
          data.type !== "RESIDENTIAL"
            ? formBuilding(data, clientContacts)
            : formBuilding(data, clientContacts).slice(1)
        }
        onSubmit={onSubmit}
        onChange={onChange}
      />

      <div className="float-right">
        <Button color="primary" className="float-right p-4" onClick={onSubmit}>
          Save
        </Button>
        <Button className="float-right p-4">Cancel</Button>
      </div>
    </div>
  );
}
