import { LoadingIcon } from "../AppIcons";
import ErrorWrap from "../ErrorWrap";

const baseClass = "flex flex-col items-center justify-center";
const DefaultFallback = ({ className = "h-64" }) => (
  <div className={`${baseClass} ${className}`}>
    <LoadingIcon />
  </div>
);

// Refactor `children` as a function so that it renders conditionally
export const SuspenseBoundary = ({
  children,
  fallback,
  fallbackClassName,
  waitFor,
  name,
}) => {
  const Fallback = () =>
    fallback ?? <DefaultFallback className={fallbackClassName} />;

  const renderWhen =
    waitFor !== undefined &&
    waitFor !== null &&
    waitFor !== false &&
    waitFor !== "";
  return renderWhen ? (
    <ErrorWrap name={name}>{children}</ErrorWrap>
  ) : (
    <Fallback />
  );
};
