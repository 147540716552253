// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "rescript/lib/es6/string.js";
import * as Js_math from "rescript/lib/es6/js_math.js";

function padTime(time) {
  return ("0" + String(time)).slice(-2);
}

function renderTime(sec) {
  var hours = Js_math.floor(sec / (60.0 * 60.0));
  var minutes = Js_math.floor(sec % (60.0 * 60.0) / 60.0);
  var seconds = Js_math.ceil(sec % 60.0);
  return $$String.concat(":", hours !== 0 ? ({
                  hd: String(hours),
                  tl: {
                    hd: padTime(minutes),
                    tl: {
                      hd: padTime(seconds),
                      tl: /* [] */0
                    }
                  }
                }) : ({
                  hd: padTime(minutes),
                  tl: {
                    hd: padTime(seconds),
                    tl: /* [] */0
                  }
                }));
}

export {
  padTime ,
  renderTime ,
  
}
/* No side effect */
