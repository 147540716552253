import { useState } from "react";

import { alertSuccess } from "../../../../../actions/AlertActions";
import { createProspect } from "../../../../../api/Api";
import MaterialForm from "../../../../../components/common/form/MaterialForm";
import { deepUpdate } from "../../../../../utils/StateUtils";

type NewProspectProps = {
  ticketId: number;
  onCloseProspect: () => void;
  onSave: () => void;
};

export default function NewProspect({
  ticketId,
  onCloseProspect,
  onSave,
}: NewProspectProps) {
  const [prospectDetails, setProspectDetails] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  });

  const onChange = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setProspectDetails((data) => deepUpdate(name, value, data));
  };

  const onSaveNewProspect = () => {
    createProspect({ ...prospectDetails, ticketId: ticketId }).then(() => {
      alertSuccess("Prospect created successfully");
      onSave();
      onCloseProspect();
    });
  };

  return (
    <div>
      <div className="text-lg font-medium text-black">
        Create a New Prospect
      </div>
      <div>
        <MaterialForm
          className="w-144 grid grid-flow-row-dense grid-rows-1 grid-cols-2 mt-3 "
          data={prospectDetails}
          onChange={onChange}
          fieldClassName="px-2 my-2"
          renderArray={[
            { name: "firstName", variant: "OUTLINED" },
            { name: "lastName", variant: "OUTLINED" },
            { name: "phone", variant: "OUTLINED", inputType: "PHONE" },
            { name: "email", variant: "OUTLINED", inputType: "EMAIL" },
          ]}
        />
      </div>
      <div className="flex flex-row mt-4 mr-4 ">
        <button
          className="text-base mr-2  flex justify-center items-center px-4 py-2 rounded font-medium  
        bg-newBlue-400  w-40
        text-white border-newBlue-400
        border"
          onClick={() => {
            onSaveNewProspect();
          }}
        >
          Save Prospect
        </button>
        <button
          className="text-base flex justify-center items-center px-4 py-2 rounded  font-medium cursor-pointer 
        bg-gray-300
        text-gray-800 duration-200 ease-in-out 
        transition mr-4"
          onClick={onCloseProspect}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
