import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import {
  alertError,
  alertInfo,
  alertSuccess,
} from "../../actions/AlertActions";
import { listAssociates, sendMessage } from "../../api/Api";
import DropDown from "../common/DropDown";
import { NewMediaDragIn } from "../common/NewMediaDragIn";
import { addEncodedFile } from "./MessagesView";

export default function MessageForwardModal({
  messageData,
  setForwardModal,
  lastChannel,
}) {
  const fileRef = useRef();

  const [associates, setAssociates] = useState([]);
  const [selectedAssociate, setSelectedAssociate] = useState();
  const [channel, setChannel] = useState("SMS");
  const [content, setContent] = useState("");
  const [mediaUrls, setMediaUrls] = useState([]);
  const [convertedMediaUrls, setConvertedMediaUrls] = useState([]);

  useEffect(() => {
    listAssociates({ territory: "ALL" }).then((data) => {
      let formatted = [];

      data.map((item) => {
        formatted.push({
          label: `${item.firstName} ${item.lastName}`,
          value: item.primaryContactId
            ? item.primaryContactId
            : item.contacts[0]?.contactId,
          usesApp: item.usesApp,
        });
      });

      setAssociates(formatted);
    });
  }, []);

  useEffect(() => {
    let convertedMediaURL = [];
    mediaUrls.map((item, index) => {
      let httpsURL = "https:" + item.split("http:")[1];
      toDataURL(httpsURL, function (dataUrl) {
        convertedMediaURL.push({ url: item, base: dataUrl });
      });
    });

    setConvertedMediaUrls(convertedMediaURL);
  }, [mediaUrls]);

  useEffect(() => {
    // setContent({
    //   contactId: selectedAssociate?.value,
    //   attachments: [],
    //   text: messageData.content
    // });
    let value_list = [];
    selectedAssociate?.map((item) => {
      value_list.push(item.value);
    });

    setContent({
      contactIds: value_list,
      attachments: [],
      text: messageData.content,
    });

    setMediaUrls(messageData.attachmentUrls || []);
  }, [messageData, selectedAssociate]);

  const onChangeCB = (channel) => {
    setChannel(channel);
  };

  const onDropCB = (file) => {
    alertInfo("Uploading file...");

    let httpsURL = "https:" + file.url.split("http:")[1];

    console.log("httpsURL parsing done");
  };
  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);
    Array.from(acceptedFiles).forEach((file) => {
      // console.log("MIME:" + file.type);
      var reader = new FileReader();
      reader.onload = addEncodedFile(setContent, file);
      reader.readAsDataURL(file);
    });
  }, []);

  const OnDeleteCB = (data) => {
    setMediaUrls(data);
  };

  async function parseMediaUrls(attachments, mediaUrls) {
    let data = attachments ? attachments : [];

    mediaUrls.forEach((mediaUrl) => {
      data.push({
        fileName: mediaUrl.url.split("/").pop(),
        contentType: mediaUrl.base.split("data:")[1].split(";")[0],
        encodedFile: mediaUrl.base,
      });
    });

    return data;
  }

  async function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  async function dispatchMessage(content, foreground) {
    let messageChannel = channel;
    if (channel === "SMS") {
      if (content.attachments || mediaUrls.length > 0) {
        messageChannel = "MMS";
      }
    }

    sendMessage({
      channel: messageChannel,
      contactIds: content.contactIds,
      content: content.text,
      subject: channel === "EMAIL" ? content.subject : null,
      attachments: await parseMediaUrls(
        content.attachments,
        convertedMediaUrls
      ),
    })
      .then((_) => {
        if (foreground) {
          alertSuccess("Sent Message");
          setContent({ subject: "", text: "", notes: "" });
          setMediaUrls([]);
        }

        setForwardModal(false);
      })
      .catch((_) => alertError("Error Sending Message"));
  }

  const handleSelectFile = (event) => {
    // console.log("Uploading to S3");
    var files = event.target.files;
    Array.from(files).forEach((file) => {
      // console.log("MIME:" + file.type);
      var reader = new FileReader();
      reader.onload = addEncodedFile(setContent, file);
      reader.readAsDataURL(file);
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div className="">
        <div className="flex flex-row items-center">
          <div>
            <p className="text-xl ...">Forward To:-</p>
          </div>
          <div className="px-2">
            <Autocomplete
              multiple
              id="combo-box-demo"
              options={associates}
              getOptionLabel={(option) => `${option.label}`}
              style={{ width: 300 }}
              onChange={(event, newValue) => {
                setSelectedAssociate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Associates" variant="outlined" />
              )}
            />
          </div>
        </div>

        <div className="flex flex-col py-4">
          <div className="bg-grey-lighter px-4 py-4 flex gap-2 items-center bg-gray-200">
            {selectedAssociate?.usesApp}
            <DropDown
              options={
                selectedAssociate?.usesApp
                  ? [
                      { channel: "SMS" },
                      { channel: "EMAIL" },
                      { channel: "APP" },
                    ]
                  : [{ channel: "SMS" }, { channel: "EMAIL" }]
              }
              onChangeCB={onChangeCB}
              defaultValue={
                lastChannel === "MMS"
                  ? { channel: "SMS" }
                  : { channel: lastChannel }
              }
            />
            <div
              className="flex-1 flex flex-col gap-2 mx-4"
              {...getRootProps()}
              onClick={(_) => {}}
            >
              {channel === "EMAIL" && (
                <input
                  className="w-full border rounded px-2 py-2"
                  placeholder="Subject"
                  value={content.subject}
                  onChange={(e) =>
                    setContent({ ...content, subject: e.target.value })
                  }
                />
              )}

              <NewMediaDragIn
                urls={mediaUrls}
                editMedia={true}
                emptyScreen={false}
                onDropCB={onDropCB}
                OnDeleteCB={OnDeleteCB}
              >
                <TextField
                  variant="standard"
                  className={"w-full border rounded px-2 py-2 " + "bg-white"}
                  multiline
                  minRows={3}
                  maxRows={8}
                  placeholder={"New Message"}
                  value={content.text}
                  onChange={(e) =>
                    setContent({ ...content, text: e.target.value })
                  }
                />
              </NewMediaDragIn>
              {content.attachments?.map((attachment) => (
                <p>
                  File: {attachment.fileName}
                  <CloseIcon
                    className="cursor-pointer"
                    onClick={(e) =>
                      setContent({ ...content, attachments: undefined })
                    }
                  />
                </p>
              ))}
            </div>
            <div>
              <AttachFileIcon
                className="cursor-pointer"
                onClick={(_) => fileRef.current.click()}
              />
              <input
                type="file"
                className="hidden"
                onChange={handleSelectFile}
                ref={fileRef}
                multiple
              />
              <input className="hidden" {...getInputProps()} />
            </div>
            <div>
              <DynamicIcon
                icon={SendIcon}
                className="cursor-pointer"
                onClick={(_) => {
                  alertInfo("Sending Message", undefined, { loading: true });
                  dispatchMessage(content, true);
                  if (content.copyTo?.length > 0)
                    content.copyTo.forEach((copy) =>
                      dispatchMessage({
                        ...content,
                        contactId: Number(copy),
                      })
                    );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const DynamicIcon = (props) => {
  const Icon = props.icon;
  return <Icon {...props} />;
};
