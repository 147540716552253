import React, { useEffect, useState } from "react";

import PersonIcon from "@mui/icons-material/Person";
import { Button, Typography } from "@mui/material";

import { getOwners, getSupervisors } from "../../api/Api";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";

export default function SelectOwner({
  currentOwner,
  territory,
  ticketId,
  supervisorStage,
  changeOwnerCB,
}) {
  const [selectUserForm, setSelectUserForm] = useState([]);
  const [owner, setOwner] = useState(currentOwner);

  const [supervisors, setSupervisors] = useState([]);

  const formSupplier = [
    {
      name: "ownerId",
      inputType: "SELECT",
      options: supervisors,
    },
  ];

  useEffect(() => {
    if (supervisorStage) {
      getSupervisors(territory).then((data) => {
        let option = [];
        data.forEach((item) => {
          option.push({
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          });
        });

        setSupervisors(option);
        setSelectUserForm(data);
      });
    } else {
      getOwners(territory).then((data) => {
        let option = [];
        data.forEach((item) => {
          option.push({
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          });
        });

        setSupervisors(option);
        setSelectUserForm(data);
      });
    }
  }, []);

  return (
    <>
      <div className="p-4 max-w-sm w-full">
        <div className="flex gap-2 items-center">
          <PersonIcon />
          <Typography variant="h5">Assign Owner</Typography>
        </div>
        <div className="flex justify-center py-2">
          <MaterialForm
            data={{
              ownerId: owner,
            }}
            renderArray={formSupplier}
            onChange={(update) => {
              setOwner(update.value);
            }}
          />
        </div>

        <div className="float-right">
          <Button
            color="primary"
            className="float-right p-2"
            onClick={(_) => {
              changeOwnerCB({ ticketId: ticketId, owner: owner });
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
}
