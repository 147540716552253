import { navigate } from "raviger";

import BuildingDiv from "../../../components/clients/BuildingDiv";
import ClientBillingAddressInfo from "../../../components/clients/ClientBillingAddressInfo";
import ClientManagers from "../components/ClientManagers";

const filterBuildingsForPMCManager = (buildings = [], pmcManager) => ({
  ...pmcManager,
  buildings: pmcManager.buildings.filter((building) =>
    buildings.some((b) => b.buildingId === building.buildingId)
  ),
});

const getApplicableManagers = (clientData) => {
  // clientData.pmcManagers is a list of all managers for the PMC
  // clientData.client.managedBy.managers is a list of managers for this client
  return clientData.client.managedBy.managers == null
    ? []
    : clientData.client.managedBy.managers.map((manager) => {
        let applicableManager = clientData.pmcManagers.find(
          (pmcManager) => pmcManager.contactId === manager.contactId
        );
        return applicableManager
          ? filterBuildingsForPMCManager(
              clientData.buildings,
              applicableManager
            )
          : manager;
      });
};

export default function ClientSummaryTab({ clientData, refreshCB }) {
  return (
    <div className="px-2 flex flex-col md:flex-row py-4 gap-5 items-start">
      <ClientBillingAddressInfo
        clientClass={clientData.client.clientClass}
        clientData={clientData.client}
        refreshCB={() => {
          refreshCB("client");
        }}
      />

      <div className="flex flex-col w-full">
        <BuildingDiv
          buildings={clientData.buildings}
          clientData={clientData.client}
          onSelectCB={(building) => {
            navigate(
              `/commercial/${clientData.client.clientId}/buildings/${building.buildingId}`
            );
          }}
          refreshCB={() => {
            refreshCB("buildings");
          }}
        />
        <ClientManagers
          clientId={clientData.client.clientId}
          managers={clientData.managers}
          clientClass={clientData.client.subCategory}
          refreshCB={() => {
            refreshCB("managers");
          }}
        />
        {clientData.pmcManagers && (
          <ClientManagers
            clientId={clientData.client.managedBy.clientId}
            clientName={clientData.client.managedBy.clientName}
            managers={getApplicableManagers(clientData)}
            refreshCB={() => {
              refreshCB("managers");
            }}
          />
        )}
      </div>
    </div>
  );
}
