import { useEffect, useState } from "react";

import {
  getCommericalClients,
  newClientGet,
  getMyVendor,
} from "../../../api/Api";
import { outlineFields } from "../../../utils/FormUtils";
import { conditionalArrayEntry } from "../../../utils/ObjectUtills";
import { make as MaterialForm } from "../../common/form/MaterialForm.bs";

export const clientClassOptions = [
  { label: "Self-Managed", value: "HOA_OWNER" },
  { label: "Third Party Management", value: "PMC" },
];

// Duplicated as clientSubcategories in constants.js
export const clientSubCategoryOptions = (clientClass) => [
  ...conditionalArrayEntry(clientClass !== "PMC", {
    label: "Home Owner Association",
    value: "HOA",
  }),
  ...conditionalArrayEntry(
    clientClass !== "PMC",

    { label: "Landlord", value: "BUILDING_OWNER" }
  ),
  ...conditionalArrayEntry(clientClass !== "HOA_OWNER", [
    { label: "Property Management", value: "PROPERTY_MANAGEMENT" },
  ]),
  ...conditionalArrayEntry(clientClass !== "HOA_OWNER", [
    { label: "Contractor", value: "CONTRACTOR" },
  ]),
];

const useClientFields = (data, onChange) => {
  const [commercialClients, setCommercialClients] = useState([]);
  const [vendorType, setVendorType] = useState("");

  useEffect(() => {
    if (
      data?.managedById &&
      !commercialClients.find(
        (client) => Number(client.clientId) === Number(data?.managedById)
      )
    ) {
      newClientGet(data.managedById).then((client) => {
        const name = `${client.clientName}`;
        const managedByCandidate = {
          ...client,
          label: name,
        };
        setCommercialClients([managedByCandidate]);
      });
    } else if (data?.managedByCandidateSearch) {
      getCommericalClients("PMC", data.managedByCandidateSearch).then(
        (data) => {
          const commercialClients = data.content.map((client) => {
            const name = `${client.clientName}`;
            return {
              ...client,
              label: name,
            };
          });
          setCommercialClients((clients) =>
            clients
              .filter((client) => client.clientClass === "INDIVIDUAL")
              .concat(commercialClients)
          );
        }
      );
    }
  }, [data?.managedById, data?.managedByCandidateSearch]);

  useEffect(() => {
    if (data?.managedById) {
      const managedByCandidate = commercialClients.find(
        (client) =>
          data.managedById &&
          Number(client.clientId) === Number(data.managedById)
      );
      if (managedByCandidate) {
        // Set ManagedByCandidate and reset managedById
        onChange({ name: "managedByCandidate", value: managedByCandidate });
        onChange({ name: "managedById", value: null });
      }
    }
  }, [data?.managedById, commercialClients]);

  useEffect(() => {
    getMyVendor().then((data) => {
      setVendorType(data.type);
    });
  }, []);

  const subCategoryOptions = clientSubCategoryOptions(data?.clientClass);

  return [
    {
      name: "profilePic",
      inputType: "IMAGE",
    },
    {
      name: "clientName",
      label: "Entity Name",
    },
    ...conditionalArrayEntry(data?.clientId && !data?.isEditingEntityType, {
      name: "isEditingEntityType",
      inputType: "CHECKBOX",
      label: "I have to make changes to this entity’s type",
    }),
    ...conditionalArrayEntry(data?.clientId && data?.isEditingEntityType, {
      name: "clientClass",
      inputType: "SELECT",
      label: "Who Manages this Entity?",
      options: clientClassOptions,
    }),
    ...conditionalArrayEntry(data?.clientId && data?.isEditingEntityType, {
      name: "subCategory",
      label: "Commercial Client Type",
      defaultValue: subCategoryOptions[0].value,
      inputType: "SELECT",
      options: subCategoryOptions,
    }),
    { name: "billingAddress", inputType: "ADDRESS" },
    ...conditionalArrayEntry(
      data?.clientClass === "HOA_OWNER" &&
        vendorType !== "PMC" &&
        !data?.managedByCandidate,
      {
        name: "isManaged",
        inputType: "CHECKBOX",
        label: "Does this Entity have a Property Management Company?",
      }
    ),
    ...conditionalArrayEntry(
      data?.clientClass === "HOA_OWNER" &&
        vendorType !== "PMC" &&
        (data?.isManaged || data?.managedByCandidate),
      {
        name: "managedByCandidate",
        label: "Managed By",
        inputType: "SELECT",
        variant: "AUTOCOMPLETE|OUTLINED",
        options: commercialClients,
      }
    ),
  ];
};

export default function ClientFormFields({ data, onChange }) {
  const commercialClientFields = useClientFields(data, onChange);
  return (
    <div className="flex flex-col gap-4 items-center mt-12 p-4 bg-white h-full w-72 sm:w-full">
      <MaterialForm
        className="w-72 sm:w-96 flex flex-col gap-3"
        data={data}
        onChange={onChange}
        onSubmit={() => {}}
        renderArray={outlineFields(commercialClientFields)}
      />
    </div>
  );
}
