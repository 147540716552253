import React, { useEffect, useState } from "react";

import BusinessIcon from "@mui/icons-material/Business";
import { Button, Typography } from "@mui/material";

import { alertSuccess, alertError } from "../../actions/AlertActions";
import { createTicket, createTicketNew, getPipelines } from "../../api/Api";
import { useExtendedTicket } from "../../hooks/useExtendedTicket";
import { conditionalObjectEntry } from "../../utils/ObjectUtills";
import { deepUpdate } from "../../utils/StateUtils";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import { formTicket } from "../common/form/constants";
import TicketDetails from "./TicketDetail";

export default function CreateSimpleTicket(props = { successCB: () => {} }) {
  const { baseData, onSubmitCB, successCB } = props;
  const [data, setData] = useState(baseData ?? {});
  const [formTicketState, setFormTicket] = useState(formTicket(baseData ?? {}));

  const { renderFields } = useExtendedTicket(data, setData);

  const clientField = renderFields.find(
    (field) => field.name === "clientCandidate"
  );

  useEffect(() => {
    getPipelines().then((pipelines) =>
      setFormTicket((form) =>
        form.map((input) =>
          input.name === "pipelineId"
            ? {
                ...input,
                options: pipelines.map((pipeline) => {
                  return {
                    label: pipeline.pipelineType,
                    value: pipeline.pipelineId,
                  };
                }),
              }
            : input
        )
      )
    );
  }, []);

  const onChange = (update) => {
    const { name, value } = update;
    setData((data) => deepUpdate(name, value, data));
  };
  const onSubmit = (event) => {
    event.preventDefault();
    const [clientType, id] = data.clientCandidate.key.split(".");
    const ticketPayload = {
      ...data,
      ...conditionalObjectEntry(clientType === "client", {
        createdUnderClientId: id,
      }),
      ...conditionalObjectEntry(clientType === "contact", {
        createdUnderContactId: id,
      }),
      pipelineId: data.pipelineId ?? data.pipeline,
    };
    if (onSubmitCB) {
      console.log("Callback");
      return onSubmitCB(event);
    } else {
      createTicketNew(ticketPayload)
        .then((newBuildingId) => {
          data.ticketId
            ? alertSuccess("Successfully Updated Ticket")
            : alertSuccess("Successfully Created Ticket");
          successCB && successCB();
        })
        .catch((_) => {
          data.ticketId
            ? alertError("Error Updating Ticket")
            : alertError("Error Creating Ticket");
        });
    }
  };
  return (
    <div className="flex items-center">
      <div className="p-4 max-w-sm w-full">
        <div className="flex gap-2">
          <BusinessIcon />
          <Typography variant="h5">Ticket</Typography>
        </div>
        <MaterialForm
          data={data}
          renderArray={[...formTicketState, clientField]}
          onSubmit={onSubmit}
          onChange={onChange}
        />

        <div className="float-right">
          <Button
            color="primary"
            className="float-right p-4"
            onClick={onSubmit}
          >
            Save
          </Button>
        </div>
      </div>
      {data.ticketId && <TicketDetails ticketId={data.ticketId} />}
    </div>
  );
}
