import { useEffect, useState } from "react";

import { Tab } from "@headlessui/react";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import { deletePipeline, getPipelines } from "../../api/Api";
import { DetailsIcon } from "../../components/clients/ClientAppIcons";
import { AddBox } from "../../components/common/AppIcons";
import HeadlessDropdown from "../../components/common/HeadlessDropdown";
import MaterialModal from "../../components/common/MaterialModal";
import PipelineTab from "./PipelineTab";
import CreatePipeline from "./components/CreatePipeline";
import { PipeLineData } from "./service";

type MenuButtonProps = {
  className: string;
};

export default function SalesScreen() {
  const [pipeLines, setPipelines] = useState<PipeLineData[]>([]);

  const [createPipeline, setCreatePipeline] = useState({
    display: false,
    isEdit: false,
  });

  useEffect(() => {
    getPipelines()
      .then((data) => {
        setPipelines(data as PipeLineData[]);
      })
      .catch((_) => alertError("Error Loading pipelines"));
  }, []);
  const { withConfirmation } = useAppActions();

  const MenuButton = ({ className }: MenuButtonProps) => {
    return (
      <div className={className}>
        <HeadlessDropdown
          value={"Options"}
          options={["Edit", "Delete"]}
          onChange={(value) => {
            if (value === "Edit") {
              setCreatePipeline({
                display: true,
                isEdit: true,
              });
            } else if (value === "Delete") {
              withConfirmation({
                title: "Remove Pipeline",
                description: `Are you sure you want to delete ${
                  pipeLines[selectedTab]
                    ? pipeLines[selectedTab].pipelineType
                    : ""
                }
                    This action cannot be undone.`,
                onConfirm: () =>
                  deletePipeline(
                    pipeLines[selectedTab]
                      ? pipeLines[selectedTab].pipelineId
                      : 0
                  )
                    .then((_res) => {
                      alertSuccess(
                        `${
                          pipeLines[selectedTab]
                            ? pipeLines[selectedTab].pipelineType
                            : ""
                        } deleted successfully`
                      );
                      getPipelines().then((data) => {
                        setPipelines(data as PipeLineData[]);
                      });
                    })
                    .catch((_err) => {
                      alertError(
                        `Failed to delete ${
                          pipeLines[selectedTab]
                            ? pipeLines[selectedTab].pipelineType
                            : ""
                        }`
                      );
                    }),
              });
            }
          }}
        >
          <div className="flex items-center justify-center px-2 bg-white hover:bg-gray-400 rounded">
            <DetailsIcon />
          </div>
        </HeadlessDropdown>
      </div>
    );
  };

  const [selectedTab, setSelectedTab] = useState(0);

  console.log({ selectedTab });
  return (
    <div className="flex max-h-xscreen h-full w-full bg-white  mx-auto gap-2 flex-col p-4 rounded-md justify-start  overflow-auto">
      <div className="mb-4">
        <div className="text-gray-900 text-2xl font-medium mt-6 mb-1">
          Setup Your Ticket Pipelines
        </div>
        <div className="text-base text-gray-800">
          This is where your Pipelines are managed.
        </div>
      </div>

      <div>
        <Tab.Group
          selectedIndex={selectedTab}
          onChange={(index) => {
            setSelectedTab(index);
          }}
        >
          <div className="flex flex-row w-full border-b">
            <div className="p-4 pb-0">
              <div className="">
                <Tab.List className=" flex space-x-8 items-center">
                  {pipeLines?.map((item, _index) => {
                    return (
                      <Tab
                        key={item.pipelineId}
                        className={"focus:outline-none"}
                      >
                        {({ selected }) => (
                          <div className="flex flex-row items-center p-1 pb-0">
                            <div
                              className={
                                "text-base hover:text-gray-700  py-4 px-1  font-medium " +
                                (selected
                                  ? "text-newBlue-400 border-b-4 border-newBlue-400"
                                  : "text-newGray-800")
                              }
                            >
                              {item.pipelineType}
                            </div>
                            {selected && <MenuButton className="" />}
                          </div>
                        )}
                      </Tab>
                    );
                  })}
                  <button
                    onClick={() =>
                      setCreatePipeline({ display: true, isEdit: false })
                    }
                    className="focus:outline-none"
                  >
                    <AddBox className="h-8 w-8" />
                  </button>
                </Tab.List>
              </div>
            </div>
          </div>
          {/* Ensuring that the components are not rendered when a client switch happens */}
          {/* Otherwise internal effects will trigger redirects */}

          <Tab.Panels>
            {pipeLines?.map((item) => (
              <Tab.Panel key={item.pipelineId}>
                <PipelineTab
                  pipeLineId={item.pipelineId}
                  pipeLineName={item.pipelineType}
                />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
      {createPipeline.display && (
        <MaterialModal
          open={createPipeline.display}
          setOpen={(_) =>
            setCreatePipeline((current) => {
              return {
                ...current,
                display: false,
              };
            })
          }
          label="new-user-modal"
          describedBy="create-new-user"
        >
          <CreatePipeline
            isEdit={createPipeline.isEdit}
            pipelineId={
              pipeLines[selectedTab]
                ? pipeLines[selectedTab].pipelineId
                : undefined
            }
            pipelineType={
              pipeLines[selectedTab]
                ? pipeLines[selectedTab].pipelineType
                : undefined
            }
            onSubmit={() => {
              setCreatePipeline({ display: false, isEdit: false });
              getPipelines().then((data) => {
                setPipelines(data as PipeLineData[]);
              });
            }}
          />
        </MaterialModal>
      )}
    </div>
  );
}
