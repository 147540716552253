// import HomeIcon from "@mui/icons-material/Home";
import React, { useEffect } from "react";

import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Avatar } from "@mui/material";
import { Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import MaterialModal from "../common/MaterialModal";
import CreateAssociateContact from "./CreateAssociateContact";

// import { createResidentialForExisting } from "../../api/Api";
// import { alertError } from "../../actions/AlertActions";
// import { navigate } from "raviger";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function AssociateContactsAccordion({
  data,
  associateId,
  primaryContact,
  buildingId,
  syncCB,
  withLink,
  clientType,
  unLinkCB,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(data[0]?.contactId);
  const [openModal, setOpenModal] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    console.log("re render");
  }, [data]);

  return (
    <div className="max-w-md w-full">
      {data?.map((contact) => (
        <Accordion
          key={`${contact.contactId}`}
          expanded={expanded === contact.contactId}
          onChange={handleChange(contact.contactId)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="flex items-center"
          >
            <Avatar src={contact.profilePicture} className="h-6 w-6 mr-2">
              {(contact.firstName && contact.firstName[0]) ??
                (contact.lastName && contact.lastName[0]) ??
                ""}
            </Avatar>
            <Typography className={classes.heading}>
              {contact.firstName}
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {contact.lastName}
            </Typography>
            {contact.contactId === primaryContact && (
              <div className="px-4">
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-300 text-blue-800">
                  Primary
                </span>
              </div>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <CreateAssociateContact
              associateId={associateId}
              baseData={contact}
              successCB={syncCB}
              renderFlexComponent={(_) => (
                <div className="flex flex-row">
                  {unLinkCB && (
                    <Button
                      onClick={(_) => {
                        unLinkCB(contact.contactId, buildingId);
                      }}
                      className="flex gap-1"
                    >
                      <DeleteIcon />
                      <span>Unlink</span>
                    </Button>
                  )}
                </div>
              )}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      {withLink && (
        <div className="p-2 cursor-pointer" onClick={(_) => setOpenModal(true)}>
          <AddCircleOutlineOutlined /> New Contact
        </div>
      )}
      <MaterialModal
        open={openModal}
        setOpen={setOpenModal}
        label="new-user-modal"
        describedby="create-new-user"
      >
        <CreateAssociateContact
          associateId={associateId}
          buildingId={buildingId}
          successCB={(_) => {
            syncCB();
            setOpenModal(false);
          }}
        />
      </MaterialModal>
    </div>
  );
}
