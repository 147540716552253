import React, { useEffect, useState } from "react";

import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import { Button, Typography } from "@mui/material";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import {
  createSR,
  getBuilding,
  getBuildingManagersNewApi,
  getClient,
  getMyVendor,
} from "../../api/Api";
import { deepUpdate } from "../../utils/StateUtils";
import { properString } from "../../utils/StringUtils";
import SRGallery from "../clients/SRGallery";
import MaterialModal from "../common/MaterialModal";
import { PreviewTiles } from "../common/PreviewTiles";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";
// import { formServiceRequest /*, formSR*/ } from "../common/form/constants";
import { formSR } from "../common/form/constants";

export const getSubCategories = (skillData, skill) => {
  const selectedSkill = skillData.find((item) => item.skill === skill);
  return selectedSkill?.subCategories?.map((item) => {
    return { label: properString(item), value: item };
  });
};

export default function CreateSR({
  baseData,
  onSubmitCB,
  successCB,
  onCancelCB,
}) {
  const [data, setData] = useState(baseData ?? {});
  const [clientBuildings, setClientBuildings] = useState([]);
  const [buildingContacts, setBuildingContacts] = useState([]);

  const [galleyModal, setGalleryModal] = useState(false);
  const [tempImages, setTempImages] = useState([]);
  const [skillData, setSkillData] = useState([]);

  useEffect(() => {
    data.clientId &&
      getClient(data.clientId).then((client) => {
        setClientBuildings(client.buildings);
      });

    getMyVendor()
      .then((data) => {
        setSkillData(data.categories);
      })
      .catch(() => alertError("Error while fetching vendor"));
  }, []);

  const getSkills = () => {
    return skillData.map((item) => {
      return { label: properString(item.skill), value: item.skill };
    });
  };

  useEffect(() => {
    getBuilding(data.buildingId).then((building) => {
      setBuildingContacts((data) => [...data, ...building.contacts]);
    });

    getBuildingManagersNewApi(data.buildingId)
      .then((managerData) => {
        setBuildingContacts((data) => {
          return [...data, ...managerData];
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [data.buildingId]);

  const onChange = (update) => {
    const { name, value } = update;

    setData((data) => deepUpdate(name, value, data));

    if (
      name === "jobType" &&
      (value === "SITE_VISIT" || value === "INSPECTION")
    ) {
      setData((data) => deepUpdate("subCategory", null, data));
    }
  };

  const onSubmit = (event) => {
    setData((data) => {
      return deepUpdate("mediaUrls", tempImages, data);
    });
    event.preventDefault();
    if (onSubmitCB) {
      console.log("Callback");
      return onSubmitCB(event);
    } else {
      createSR(data)
        .then((newSR) => {
          data.serviceRequestId
            ? alertSuccess("Successfully Updated SR")
            : alertSuccess("Successfully Created SR");
          successCB && successCB();
        })
        .catch((_) => {
          data.buildingId
            ? alertError("Error Updating SR")
            : alertError("Error Creating SR");
        });
    }
  };
  return (
    <>
      <div className="p-4 max-w-sm w-full">
        <div className="flex gap-2">
          <BusinessIcon />
          <Typography variant="h5">Service Request</Typography>
        </div>
        <MaterialForm
          data={data}
          renderArray={formSR(
            data,
            clientBuildings,
            buildingContacts,
            getSkills(),
            skillData
          )}
          onSubmit={onSubmit}
          onChange={onChange}
        />
        <Button
          color="primary"
          variant="text"
          onClick={(_) => {
            setGalleryModal(true);
          }}
        >
          <div className="flex gap-1 sr-material-create">
            <AddCircleOutlineOutlined />
            <span>Select SR Media</span>
          </div>
        </Button>

        <PreviewTiles
          urls={tempImages}
          editMedia={true}
          OnDeleteCB={(url) => {
            setTempImages((tempImages) => {
              return tempImages.filter((item) => item !== url);
            });
            setData((data) => {
              return deepUpdate(
                "mediaUrls",
                data.mediaUrls?.filter((u) => u !== url),
                data
              );
            });
          }}
        />

        <div className="float-right">
          <Button
            color="primary"
            className="float-right p-4 sr-save-button"
            onClick={onSubmit}
          >
            Save
          </Button>
          <Button onClick={onCancelCB} className="float-right p-4">
            Cancel
          </Button>
        </div>
      </div>
      <MaterialModal
        open={galleyModal}
        setOpen={(_) => {
          setGalleryModal(false);
        }}
        label="new-user-modal"
        describedby="create-new-user"
      >
        <SRGallery
          imgList={[
            ...new Set(
              (baseData?.mediaUrls || [])
                .concat(baseData.ticketMedia)
                .filter(
                  (d) => !(baseData?.blacklistedTicketMedia || []).includes(d)
                )
            ),
          ]}
          onCheckCB={(checked, item) => {
            let status = tempImages.includes(item);
            status
              ? setTempImages(tempImages.filter((i) => i !== item))
              : setTempImages([...tempImages, item]);
            // if (checked) {
            //   setData((data) => {
            //     return deepUpdate(
            //       "mediaUrls",
            //       [...(data.mediaUrls || []), item],
            //       data
            //     );
            //   });
            // } else {
            //   setData((data) => {
            //     return deepUpdate(
            //       "mediaUrls",
            //       data.mediaUrls?.filter((u) => u !== item),
            //       data
            //     );
            //   });
            // }
          }}
          onSelectCB={(item) => {}}
          onSubmitCB={() => {
            setData((data) => {
              return deepUpdate("mediaUrls", tempImages, data);
            });
            setGalleryModal(false);
          }}
          mediaUrls={tempImages}
          srSelectedImages={tempImages}
        />
      </MaterialModal>
    </>
  );
}
