import { fileUpload } from "../../../api/Api";


export const amenitiesForm = () =>{

    return [
        {
          name: "AIR_CONDITIONING",
          inputType: "CHECKBOX",
          label: "Air Conditioning",
        },
        {
          name: "BALCONY/PATIO",
          inputType: "CHECKBOX",
          label: "Balcony/Patio",
        },
        {
          name: "CABLE/SATELLITE_TV",
          inputType: "CHECKBOX",
          label: "Cable/Satellite TV",
        },
        {
          name: "CARPETED_FLOORS",
          inputType: "CHECKBOX",
          label: "Carpeted Floors",
        },
        {
          name: "CEILING_FAN",
          inputType: "CHECKBOX",
          label: "Ceiling Fan",
        },
        {
          name: "DISHWASHER",
          inputType: "CHECKBOX",
          label: "Dishwasher",
        },
        {
          name: "ELECTRIC_RANGE",
          inputType: "CHECKBOX",
          label: "Electric Range",
        },
        {
          name: "FIREPLACE",
          inputType: "CHECKBOX",
          label: "Fireplace",
        },
        {
          name: "FURNISHED",
          inputType: "CHECKBOX",
          label: "Furnished",
        },
        {
          name: "GARAGE",
          inputType: "CHECKBOX",
          label: "Garage",
        },
        {
          name: "GARBAGE_DISPOSAL",
          inputType: "CHECKBOX",
          label: "Garbage Disposal",
        },
        {
          name: "GAS_RANGE",
          inputType: "CHECKBOX",
          label: "Gas Range",
        },
        {
          name: "HARDWOOD_FLOORS",
          inputType: "CHECKBOX",
          label: "Hardwoood Floors",
        },
        {
          name: "HIGH-SPEED_INTERNET",
          inputType: "CHECKBOX",
          label: "High-Speed Internet",
        },
        {
          name: "INTRUSION_ALARM",
          inputType: "CHECKBOX",
          label: "Intrusion Alarm",
        },
        {
          name: "MICROWAVE",
          inputType: "CHECKBOX",
          label: "Microwave",
        },
        {
          name: "PETS_ALLOWED",
          inputType: "CHECKBOX",
          label: "Pets Allowed",
        },
        {
          name: "REFRIGERATOR",
          inputType: "CHECKBOX",
          label: "Refrigerator",
        },
        {
          name: "UTILITIES_PAID",
          inputType: "CHECKBOX",
          label: "Utilities Paid",
        },
        {
          name: "VAULTED_CEILINGS",
          inputType: "CHECKBOX",
          label: "Vaulted Ceilings",
        },
        {
          name: "WALK-IN_CLOSETS",
          inputType: "CHECKBOX",
          label: "Walk-in Closets",
        },
        {
          name: "WASHER/DRYER_HK-UP",
          inputType: "CHECKBOX",
          label: "Washer/Dryer Hk-up",
        },
        {
          name: "WASHER/DRYER_IN_UNIT",
          inputType: "CHECKBOX",
          label: "Washer/Dryer In Unit",
        },
        {
          name: "WATERFRONT_PROPERTY",
          inputType: "CHECKBOX",
          label: "Waterfront Property",
        },
        {
          name: "WHEELCHAIR_ACCESS",
          inputType: "CHECKBOX",
          label: "Wheelchair Access",
        },
      ]
}


export const uploadEncodedFile = (
    callback: (arg0: {
      fileName: any;
      contentType: any;
      encodedFile: any;
    }) => void,
    file: { name: any; type: any }
  ) => {
    return function (upload: { target: { result: any } }) {
      callback({
        fileName: file.name,
        contentType: file.type,
        encodedFile: upload.target.result,
      });
    };
  };

  
