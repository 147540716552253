import React, { useState } from "react";
import { useDrop } from "react-dnd";

import DeleteIcon from "@mui/icons-material/Delete";

import PreviewLink from "../common/PreviewLink";
// import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
// import { getMedia } from "../suppliers/MessagesList";
// import { blueGrey } from "@mui/material/colors";
import Carousel from "./Carousel";
import MaterialModal from "./MaterialModal";

export const NewMediaDragIn = ({
  urls,
  editMedia, // edit mode
  emptyScreen,
  children,
  onDropCB, //call back for drop
  OnDeleteCB, //call back for delete
}) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "IMAGE",
    drop: (file) => {
      //on drop call back function new implementation

      onDropCB(file);
      return { name: "Media" };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [onHover, setonHover] = useState({});

  const [selectedImage, setSelectedImage] = useState("");

  const isActive = canDrop && isOver;
  let backgroundColor = "#222";
  if (isActive) {
    backgroundColor = "darkgreen";
  } else if (canDrop) {
    backgroundColor = "darkkhaki";
  }
  return (
    <div className="w-full" ref={drop}>
      {children}
      <div className="flex w-full items-center justify-start bg-grey-lighter">
        {urls.length === 0 && emptyScreen ? (
          <label className="w-64 flex flex-col items-center px-2 py-4 bg-white text-blue tracking-wide cursor-pointer">
            <svg
              className="w-8 h-8"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
            </svg>
            <span className="mt-2 text-base leading-normal">
              {isActive ? "Release to drop" : "Drag an attachment here"}
            </span>
            <input type="file" className="hidden" />
          </label>
        ) : (
          urls.map((item, index) => {
            return (
              <div
                className="flex flex-row static items-center justify-center relative px-1"
                onMouseEnter={() => {
                  setonHover((current) => {
                    return { ...current, [index]: true };
                  });
                }}
                onMouseLeave={() => {
                  setonHover((current) => {
                    return { ...current, [index]: false };
                  });
                }}
                onClick={() => {
                  setSelectedImage(item.url ?? item);
                }}
              >
                {/* Items can be {url} or a simple url string right now. Therefore the ?? */}
                <PreviewLink url={item.url ?? item} tileView key={index} />
                {editMedia && onHover[index] && (
                  <div className="absolute">
                    <DeleteIcon
                      className="text-blue-light"
                      onClick={(e) => {
                        e.stopPropagation();
                        let deleteIndex = index;
                        let filtered = urls.filter(
                          (item, index) => index !== deleteIndex
                        );

                        OnDeleteCB(filtered);
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>

      {selectedImage && (
        <MaterialModal
          className="p-0"
          open={selectedImage}
          setOpen={() => {
            setSelectedImage();
          }}
        >
          <Carousel
            lightboxImages={{ images: [selectedImage], photoIndex: 0 }}
          />
        </MaterialModal>
      )}
    </div>
  );
};
