// Generated by ReScript, PLEASE EDIT WITH CARE

import * as TextGen from "./Text.gen";

var make = TextGen.make;

export {
  make ,
  
}
/* make Not a pure module */
