import React, { useState } from "react";

import { alertError } from "../../actions/AlertActions";
// import { properString } from "../../utils/StringUtils";
import { updateCommercialClient } from "../../api/Api";
import { renderClientCategory } from "../../pages/client/ClientHomePage";
import { conditionalObjectEntry } from "../../utils/ObjectUtills";
import { deepUpdate } from "../../utils/StateUtils";
import { properString } from "../../utils/StringUtils";
import { BuildingIcon } from "./ClientAppIcons";
import RenderAddressComponent from "./RenderAddressComponent";
import ClientFormFields from "./forms/ClientFormFields";

export default function ClientBillingAddressInfo({ clientData, refreshCB }) {
  const [editState, setEditState] = useState();

  const onChange = (update) => {
    let { name, value } = update;
    setEditState((clientData) => deepUpdate(name, value, clientData));
  };

  return (
    <div className="flex flex-col border border-newGray-1500 w-full md:w-112 rounded-lg">
      {editState ? (
        <>
          <ClientFormFields data={editState} onChange={onChange} />

          <div className="flex flex-row items-center py-4 justify-end">
            <div className="">
              <button
                className="px-2 py-1 rounded bg-newGray-1400"
                onClick={() => {
                  setEditState();
                }}
              >
                Cancel
              </button>
            </div>

            <div className="px-2">
              <button
                className="rounded px-2 py-1 bg-newBlue-500 text-newBlue-400"
                onClick={() => {
                  const payload = {
                    profilePic: editState.profilePic,
                    clientName: editState.clientName,
                    subCategory: editState.subCategory,
                    clientClass: editState.clientClass,
                    managedById: editState.managedByCandidate
                      ? editState.managedByCandidate.clientId
                      : null,
                    ...conditionalObjectEntry(
                      editState.billingAddress.streetAddress1,
                      {
                        billingAddress: editState.billingAddress,
                      }
                    ),
                  };
                  updateCommercialClient(clientData.clientId, payload)
                    .then((_res) => {
                      setEditState();
                      refreshCB();
                    })
                    .catch((_err) => {
                      console.log(_err);
                      alertError(
                        "Please Check Your Inputs, We Were Unable To Save Your Changes"
                      );
                    });
                }}
              >
                Save
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-row justify-between items-center p-6 border-b border-newGray-1500">
            <div className="font-semibold text-sm whitespace-nowrap">
              Client Billing Address
            </div>
            <div
              className="text-newBlue-400 font-semibold text-sm cursor-pointer px-4"
              onClick={() => {
                setEditState({
                  ...clientData,
                  managedById: clientData.managedBy?.clientId,
                });
              }}
            >
              Edit
            </div>
          </div>
          {clientData?.profilePic ? (
            <img
              src={clientData?.profilePic}
              className="w-full h-40 object-cover border border-gray-300"
              alt="client"
            />
          ) : (
            <div className="flex flex-row items-center px-4 py-4">
              <div className="bg-newGray-1300 px-4 py-4 rounded">
                <BuildingIcon color={"#fff"} />
              </div>
              <div>
                <span className="text-newGray-200 text-sm px-2">
                  No Image Available
                </span>
              </div>
            </div>
          )}

          {[
            // {
            //   label: "Client Type",
            //   value: clientData?.clientType ? clientData?.clientType : "----",
            // },
            {
              label: "Client Name",
              value: clientData?.clientName ? clientData?.clientName : "----",
            },

            {
              label: "Commercial Client Type",
              value: clientData?.subCategory
                ? renderClientCategory(clientData?.subCategory)
                : "----",
            },
          ].map((item, _index) => (
            <div
              key={item.label}
              className="mt-2 border-b p-1 px-4 border-newGray-1500"
            >
              <div className="font-semibold text-sm">{item.label}</div>
              {item.label === "Address" ? (
                <RenderAddressComponent
                  address={item.value}
                  className="text-newGray-800"
                />
              ) : (
                <div className="text-newGray-800 text-sm">{item.value}</div>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
}
