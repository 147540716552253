import { useEffect, useState } from "react";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import {
  getLocation,
  getSupplier,
  removeLocation,
  removeLocationContact,
} from "../../api/Api";
import { DetailsIcon } from "../../components/clients/ClientAppIcons";
import {
  AddIconBlue,
  DeleteIcon,
  SearchIcon,
  ClientBuildingIcon,
} from "../../components/common/AppIcons";
import HeadlessDropdown from "../../components/common/HeadlessDropdown";
import MaterialModal from "../../components/common/MaterialModal";
import CreateBuilding from "../../components/suppliers/CreateBuilding";
import CreateContact from "../../components/suppliers/CreateContact";
import { properString, renderPhone } from "../../utils/StringUtils";
import {
  Buildings,
  SupplierContactsData,
  SupplierDetailsData,
} from "./Service";

type SupplierLocationProps = {
  supplierId: number;
};

type LocationDetails = {
  label: string;
  value: string;
};

export default function SupplierLocations({
  supplierId,
}: SupplierLocationProps) {
  const [data, setData] = useState<SupplierDetailsData>();
  useEffect(() => {
    getSupplier(supplierId)
      .then((data) => {
        setData(data as SupplierDetailsData);
      })
      .catch((_) => alertError("Error Loading Supplier"));
  }, [supplierId]);
  const [selectedBuilding, SetSelectedBuilding] = useState<Buildings>(
    data?.buildings ? data?.buildings[0] : {}
  );

  useEffect(() => {
    SetSelectedBuilding(data?.buildings ? data?.buildings[0] : {});
  }, [data]);

  const [primaryContact, setPrimaryContact] = useState<SupplierContactsData>();

  const [searchLocationText, setSearchLocationText] = useState("");
  const [searchContactText, setSearchContactText] = useState("");
  const [edit, setEdit] = useState(false);
  const [selectedContact, setSelectedContact] =
    useState<SupplierContactsData>();

  const [selectedLocationDetails, SetSelectedLocationDetails] = useState<
    LocationDetails[]
  >([]);

  const { withConfirmation } = useAppActions();
  const [fetchedBuilding, setFetchedBuilding] = useState<Buildings>();
  const [openContactModal, setOpenContactModal] = useState(false);

  const filteredLocationList = data?.buildings?.filter(
    (item) =>
      item.address?.streetAddress1
        ?.toLowerCase()
        .includes(searchLocationText.toLowerCase()) ||
      item.address?.city
        ?.toLowerCase()
        .includes(searchLocationText.toLowerCase())
  );

  const filteredContactList = fetchedBuilding?.contacts?.filter(
    (item) =>
      item.firstName?.toLowerCase().includes(searchContactText.toLowerCase()) ||
      item.lastName?.toLowerCase().includes(searchContactText.toLowerCase())
  );

  useEffect(() => {
    SetSelectedLocationDetails([
      {
        label: "Primary Contact",
        value: primaryContact
          ? `${primaryContact.firstName} ${primaryContact.lastName}`
          : "---",
      },
      {
        label: "Address",
        value: selectedBuilding?.address?.streetAddress1 ?? "---",
      },
      {
        label: "City",
        value: selectedBuilding?.address?.city ?? "---",
      },
      {
        label: "State",
        value: selectedBuilding?.address?.state ?? "---",
      },
      {
        label: "Territory",
        value: properString(fetchedBuilding?.territory) ?? "---",
      },
    ]);
  }, [selectedBuilding, fetchedBuilding, primaryContact]);

  useEffect(() => {
    if (selectedBuilding?.buildingId)
      getLocation(selectedBuilding?.buildingId)
        .then((data) => {
          setFetchedBuilding(data as Buildings);
          setPrimaryContact(
            data?.contacts?.filter(
              (item: SupplierContactsData) =>
                item.contactId === data.primaryContactId
            )[0]
          );
        })
        .catch((_) => alertError("Error Loading Building"));
  }, [selectedBuilding]);

  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex flex-col border border-newGray-1500 w-80 rounded self-start mt-4 ml-4">
        <div className="flex flex-col mt-4 items-center ">
          <div className="flex flex-row items-center px-2 mx-2 rounded bg-newGray-1400 ">
            <div>
              <SearchIcon />
            </div>
            <input
              id="buildings_search"
              className="focus:outline-none p-2 text-gray-700 bg-newGray-1400"
              placeholder="Search..."
              value={searchLocationText}
              onChange={(e) => {
                setSearchLocationText(e.target.value);
              }}
            />
          </div>
          <div className="p-2 ">
            {filteredLocationList?.map((building) => (
              <div
                className={
                  "flex flex-row items-center rounded py-4 p-2 font-semibold hover:bg-newBlue-500 hover:text-newBlue-400" +
                  (selectedBuilding?.buildingId === building?.buildingId
                    ? "bg-newBlue-500 text-newBlue-400"
                    : "")
                }
                onClick={() => {
                  SetSelectedBuilding(building);
                }}
              >
                <div className="mr-2">
                  <ClientBuildingIcon className="h-6 w-6" />
                </div>
                <div>
                  {`${building.address?.streetAddress1}, ${building.address?.city}, ${building.address?.zip}`}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full">
        {edit ? (
          <div className="w-96">
            <CreateBuilding
              baseData={{ ...fetchedBuilding, type: data?.type }}
              onSubmitCB={undefined}
              successCB={() => setEdit(false)}
              onCancel={() => setEdit(false)}
            />
          </div>
        ) : (
          <div className="flex flex-col  border border-newGray-1500  rounded self-start mt-4 ml-4">
            <div className="flex flex-row justify-between items-center p-4 border-b border-newGray-1500">
              <div className="font-semibold text-sm">
                Supplier Location Details
              </div>
              <div className="flex flex-row items-center">
                <div
                  className="text-newBlue-400 font-semibold text-sm cursor-pointer mr-8"
                  onClick={() => {
                    setEdit(true);
                  }}
                >
                  Edit
                </div>
                <button
                  className="border rounded text-sm px-2 py-1 bg-newGray-700 text-newGray-800"
                  onClick={() => {
                    withConfirmation({
                      title: "Remove Location",
                      description: `Are you sure you want to remove ${selectedBuilding.address?.streetAddress1}, ${selectedBuilding.address?.city} ${selectedBuilding.address?.zip} 
                        This action cannot be undone.`,
                      onConfirm: () =>
                        removeLocation(selectedBuilding?.buildingId)
                          .then((_res) => {
                            alertSuccess("Location removed successfully");
                            getSupplier(supplierId).then((data) => {
                              setData(data as SupplierDetailsData);
                            });
                          })
                          .catch((_err) => {
                            alertError("Failed to remove location");
                          }),
                    });
                  }}
                >
                  <div className="flex flex-row items-center">
                    <div>
                      <DeleteIcon className="h-4 w-4" />
                    </div>
                    <div className="px-2">Remove Location</div>
                  </div>
                </button>
              </div>
            </div>
            <div className="grid grid-flow-row-dense grid-rows-2 grid-cols-2 md:grid-cols-3">
              {selectedLocationDetails.map((item) => (
                <div className="px-4 py-2 min-w-fit gap-4">
                  <div className="font-semibold text-sm ">{item.label}</div>
                  <div className="text-newGray-800 text-sm">{item.value}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="mt-10 ml-4">
          <div className="flex flex-row w-full  justify-between items-center">
            <div className="font-semibold text-sm ml-4">Contacts</div>
            <div className="flex flex-row">
              <div className="flex flex-row gap-2 items-center ">
                <div>
                  <div className="flex flex-row items-center px-2 rounded bg-newGray-1400 ">
                    <div>
                      <SearchIcon />
                    </div>
                    <input
                      id="buildings_search"
                      className="focus:outline-none p-2 text-gray-700 bg-newGray-1400"
                      placeholder="Search..."
                      value={searchContactText}
                      onChange={(e) => {
                        setSearchContactText(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <button
                  className="border rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400"
                  onClick={() => {
                    setOpenContactModal(true);
                  }}
                >
                  <div className="flex flex-row items-center">
                    <div>
                      <AddIconBlue className="h-4 w-4" />
                    </div>
                    <div className="px-2">Add New Contact</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="mt-4">
            {filteredContactList?.map((item) => (
              <div className="flex flex-row border rounded mt-2 p-5  border-newGray-1500 justify-between items-center mb-2 ">
                <div className="font-semibold text-sm w-2/7">{`${item.firstName} ${item.lastName}`}</div>
                <div className="w-1/7">
                  {item.contactId === primaryContact?.contactId && (
                    <div className="border rounded text-sm font-semibold px-2 py-1 bg-newOrange-400 text-newOrange-500 w-20">
                      Primary
                    </div>
                  )}
                </div>

                <div className="px-4 w-1/7">{renderPhone(item.phone)}</div>
                <div className="px-4 w-2/7 break-words">{item.email}</div>
                <div className="flex pl-4 w-1/7 justify-end">
                  <HeadlessDropdown
                    value={"Options"}
                    options={["Edit", "Remove"]}
                    onChange={(value) => {
                      if (value === "Edit") {
                        setSelectedContact(item);
                        setOpenContactModal(true);
                      }
                      if (value === "Remove") {
                        withConfirmation({
                          title: "Remove Contact",
                          description: `Are you sure you want to remove ${item.firstName} ${item.lastName}  
                        This action cannot be undone.`,
                          onConfirm: () =>
                            removeLocationContact(
                              selectedBuilding.buildingId,
                              item.contactId
                            )
                              .then((_res) => {
                                alertSuccess("Contact removed successfully");
                                getLocation(selectedBuilding?.buildingId).then(
                                  (data) => {
                                    setFetchedBuilding(data as Buildings);
                                    setPrimaryContact(
                                      data?.contacts?.filter(
                                        (item: SupplierContactsData) =>
                                          item.contactId ===
                                          data.primaryContactId
                                      )[0]
                                    );
                                  }
                                );
                              })
                              .catch((_err) => {
                                alertError("Failed to remove Contact");
                              }),
                        });
                      }
                    }}
                  >
                    <div className="px-2 py-2 bg-gray-300 hover:bg-gray-400 rounded">
                      <DetailsIcon />
                    </div>
                  </HeadlessDropdown>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <MaterialModal
        open={openContactModal}
        setOpen={setOpenContactModal}
        label="new-contact-modal"
        describedBy="create-new-contact"
      >
        <CreateContact
          baseData={selectedContact}
          successCB={() => {
            setOpenContactModal(false);
            setSelectedContact({});
            getLocation(selectedBuilding?.buildingId).then((data) => {
              setFetchedBuilding(data as Buildings);
              setPrimaryContact(
                data?.contacts?.filter(
                  (item: SupplierContactsData) =>
                    item.contactId === data.primaryContactId
                )[0]
              );
            });
          }}
          onSubmitCB={undefined}
          renderFlexComponent={undefined}
          locationId={selectedBuilding?.buildingId}
          supplierId={undefined}
        />
      </MaterialModal>
    </div>
  );
}
