import React, { useEffect, useState } from "react";

import { alertError, alertSuccess } from "../../../actions/AlertActions";
import { updatePipelineStage } from "../../../api/Api";
import MaterialForm from "../../../components/common/form/MaterialForm";
import { deepUpdate } from "../../../utils/StateUtils";

type SetStageRuleProps = {
  pipelineId: number;
  pipelineName: string;
  stageId?: number;
  stageName?: string;
  triggers?: string[];
  automation?: string[];
  isFinal?: boolean;
  onSubmit: () => void;
};

export default function SetStageRule({
  pipelineId,
  pipelineName,
  stageId,
  stageName,
  triggers,
  automation,
  isFinal,
  onSubmit,
}: SetStageRuleProps) {
  useEffect(() => {}, []);

  const [defaultAutomation] = useState(automation ?? []);
  const [defaultTriggers] = useState(triggers ?? []);

  const [selectedAutomation, setSelectedAutomation] = useState({
    Automation: defaultAutomation,
  });
  const [selectedTriggers, setSelectedTriggers] = useState({
    Triggers: defaultTriggers,
  });

  const automationSelectors = [
    { value: "ESTIMATE_SEND", label: "Estimate Send" },
    { value: "ESTIMATE_ACCEPTANCE", label: "Estimate Acceptance" },
    { value: "ESTIMATE_REJECTION", label: "Estimate Rejection" },
    { value: "INVOICE_CREATION", label: "Invoice Creation" },
  ];
  const triggerSelectors = [
    { value: "ACCEPT_ESTIMATE", label: "Accept Estimate" },
    { value: "MARK_SRS_COMPLETE", label: "Mark SRs as Completed" },
    { value: "ASSIGN_TO_SUPERVISOR", label: "Assign to Supervisor" },
  ];

  return (
    <>
      <div className=" flex flex-col p-2  w-full rounded">
        <div className="text-sm text-gray-800 mb-2">{`${pipelineName} > ${stageName}`}</div>
        <div className="mb-2">
          <span className="text-gray-900 text-xl font-medium">
            Setup Rule for
            <span className="text-newBlue-400 text-xl font-medium ">
              {` ${stageName}`}
            </span>
          </span>
        </div>
        <div className="text-sm text-gray-800 mb-4">
          {`Stage Type: ${isFinal ? "final" : "intermediate"}`}
        </div>

        <div className="flex flex-col w-128 bg-newGray-400  border rounded p-4 mb-4 ">
          <div className="text-gray-900 text-lg font-medium mb-4">
            Set Automation
          </div>
          <div className="flex flex-row justify-start items-end">
            <div className="pb-4">When </div>
            <div className="w-1/2">
              <MaterialForm
                data={selectedAutomation}
                renderArray={[
                  {
                    name: "Automation",
                    label: "these happen",
                    inputType: "SELECT",
                    variant: "MULTISELECT",
                    defaultValue: defaultAutomation,
                    value: selectedAutomation,
                    options: automationSelectors,
                  },
                ]}
                onChange={(update: { name: any; value: any }) => {
                  let { name, value } = update;

                  if (value !== undefined) {
                    setSelectedAutomation((data) =>
                      deepUpdate(name, value, data)
                    );
                  }
                }}
              />
            </div>
            <div className="pb-4">
              move to{" "}
              <span className="text-newBlue-400 font-medium">
                {`${stageName}`}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col bg-newGray-400 border rounded p-4 mb-4 w-160">
          <div className="text-gray-900 text-lg font-medium mb-4">
            Set Manual Triggers
          </div>
          <div className="flex flex-row items-end">
            <div className="pb-4">
              When moving to{" "}
              <span className="text-newBlue-400 font-medium">{` ${stageName}`}</span>{" "}
              do
            </div>
            <div className="w-1/2">
              <MaterialForm
                data={selectedTriggers}
                renderArray={[
                  {
                    name: "Triggers",
                    label: "these",
                    inputType: "SELECT",
                    variant: "MULTISELECT",
                    defaultValue: defaultTriggers,
                    value: selectedTriggers,
                    options: triggerSelectors,
                  },
                ]}
                onChange={(update: { name: any; value: any }) => {
                  let { name, value } = update;

                  if (value !== undefined) {
                    setSelectedTriggers((data) =>
                      deepUpdate(name, value, data)
                    );
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row">
          <button
            className="rounded bg-newBlue-400 text-white text-md font-normal py-2 px-6"
            onClick={() =>
              updatePipelineStage(pipelineId, stageId, [
                {
                  op: "replace",
                  path: "/automationSelectors",
                  value: selectedAutomation.Automation,
                },
                {
                  op: "replace",
                  path: "/triggerSelectors",
                  value: selectedTriggers.Triggers,
                },
              ])
                .then(() => {
                  onSubmit();
                  alertSuccess("Successfully updated rule");
                })
                .catch((_) => alertError("Error updating rule"))
            }
          >
            Save Rule
          </button>
          <button
            className="rounded border border-gray-900 py-2 px-6 ml-4"
            onClick={onSubmit}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
