import { navigate } from "raviger";

import RenderAddressComponent from "../clients/RenderAddressComponent";
import googleImage from "../common/google_map_link.png";

interface GeocodedAddress {
  geocodeLatitude: number;
  geocodeLongitude: number;
}

interface LegacyAddress {
  geocodeLatitude: null | undefined;
  geocodeLongitude: null | undefined;
}

type Address = GeocodedAddress | LegacyAddress;

interface AddressLinkProps {
  address: Address;
  buildingId: number;
}

export default function AddressLink({ address, buildingId }: AddressLinkProps) {
  return (
    <>
      {address?.geocodeLatitude && address?.geocodeLongitude && (
        <a
          href={
            "https://www.google.com/maps/search/?api=1&query=" +
            address?.geocodeLatitude +
            "," +
            address?.geocodeLongitude
          }
          target="_blank"
          rel="noreferrer noopener"
          className="px-3 rounded flex flex-row items-center text-sm    "
        >
          {" "}
          <img src={googleImage} className="h-5 w-5" alt="Google Maps" />
        </a>
      )}

      <div
        className="text-newGray-800 cursor-pointer"
        onClick={() => {
          navigate(`/building/${buildingId}`);
        }}
      >
        <RenderAddressComponent address={address} />
      </div>
    </>
  );
}
