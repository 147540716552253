import React, { useEffect, useState } from "react";

import { TextareaAutosize } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import { deepFind } from "../../../../utils/StateUtils";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
};

const styles = {
  Input: {
    "&.Mui-disabled": {
      opacity: 0.7,
      borderBottom: "1px solid",
      borderColor: "black",
    },
  },
  Label: {
    "&.Mui-disabled": {
      color: "black",
      opacity: 0.6,
    },
  },
};

export default function Text({
  variant,
  data,
  name,
  label,
  onChange,
  defaultValue,
  optional,
  inputType,
  className,
  editable = true,
  inputOptions,
}) {
  const value = deepFind(name, data) || defaultValue || "";

  const [touched, setTouched] = useState(false);

  useEffect(() => {
    value === defaultValue && onChange({ name, value });
  }, []);

  return inputType === "EMAIL" ? (
    <TextField
      id="outlined-basic"
      className={className}
      label={label + (optional ? " (Optional)" : "")}
      variant={variant === "OUTLINED" ? "outlined" : "standard"}
      placeholder={label}
      name={name}
      value={value || ""}
      FormHelperTextProps={{
        className: "text-red-500",
      }}
      onChange={(e) => {
        e.preventDefault();
        let status = validateEmail(e.target.value);
        if (!status) {
          setTouched(true);
        } else {
          setTouched(false);
        }
        onChange(e.target);
      }}
      helperText={touched && "Please Enter a Valid Email Address"}
      fullWidth
    />
  ) : // <ValidatorForm onSubmit={(_) => {}} onError={(_) => {}}>
  //   <TextValidator
  //     label={label + (optional ? " (Optional)" : "")}
  //     placeholder={label}
  //     onChange={(e) => {
  //       e.preventDefault();
  //       onChange(e.target);
  //     }}
  //     variant="standard"
  //     name={name}
  //     value={value}
  //     validators={["isEmail"]}
  //     errorMessages={["Please Enter a Valid Email Address"]}
  //     fullWidth
  //   />
  // </ValidatorForm>
  inputType === "AREA" ? (
    <TextareaAutosize
      minRows={1}
      aria-label={label + (optional ? " (Optional)" : "")}
      className={`${className} border border-newGray-500 my-1 px-2 py-1 rounded-md `}
      label={label + (optional ? " (Optional)" : "")}
      variant={variant === "OUTLINED" ? "outlined" : "standard"}
      placeholder={label}
      name={name}
      value={value || ""}
      onChange={(e) => {
        e.preventDefault();
        onChange(e.target);
      }}
    />
  ) : inputType === "HIDDEN" ? (
    <input
      type="hidden"
      className="hidden"
      label={label + (optional ? " (Optional)" : "")}
      variant={variant === "OUTLINED" ? "outlined" : "standard"}
      placeholder={label}
      name={name}
      value={value || ""}
      onChange={(e) => {
        e.preventDefault();
        onChange(e.target);
      }}
    />
  ) : inputType === "WITH_OPTIONS" ? (
    <TextField
      className={className}
      sx={variant === "OUTLINED" ? {} : styles}
      id="outlined-basic"
      disabled={!editable}
      label={label + (optional ? " (Optional)" : "")}
      variant={variant === "OUTLINED" ? "outlined" : "standard"}
      placeholder={label}
      name={name}
      value={value || ""}
      onChange={(e) => {
        e.preventDefault();
        onChange(e.target);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{inputOptions.suffix}</InputAdornment>
        ),
      }}
      fullWidth
    />
  ) : (
    <TextField
      className={className}
      sx={variant === "OUTLINED" ? {} : styles}
      id="outlined-basic"
      disabled={!editable}
      label={label + (optional ? " (Optional)" : "")}
      variant={variant === "OUTLINED" ? "outlined" : "standard"}
      placeholder={label}
      name={name}
      value={value || ""}
      onChange={(e) => {
        e.preventDefault();
        onChange(e.target);
      }}
      fullWidth
    />
  );
}
