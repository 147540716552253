import { Avatar } from "@mui/material";
import { Link } from "raviger";

import { markAsPrimary } from "../../api/Api";
import { ProfileIcon } from "../common/AppIcons";
import { CaretRight } from "./ClientAppIcons";

export default function ListClientsBuildingPage({
  client,
  buildingData,
  refreshCB,
}) {
  const primaryAction = () => {
    markAsPrimary(
      window.location.pathname.split("/").slice(1)[1],
      client.clientId
        ? {
            clientId: client.clientId,
          }
        : {
            contactId: client.contactId,
          }
    ).then(() => {
      refreshCB();
    });
  };

  return (
    <div className="w-full border border-newGray-1400 flex flex-row justify-between bg-newGray-1100 px-2 py-4 rounded items-center">
      <div className="flex flex-row items-center">
        <div className="px-1">
          <button>
            <Avatar
              src={client.profilePicture}
              variant="square"
              className="h-8 w-8 rounded"
            ></Avatar>
          </button>
        </div>
        <div className="px-1 text-sm">
          <span>{`${client.clientName}`}</span>
        </div>
      </div>

      <div className="flex flex-row items-center">
        {client.position === "OWNER" && (
          <div className="">
            <span className="bg-newPink-100 text-newBlue-600 inline-flex items-center rounded px-2 py-1 text-sm font-medium estimateStatus">
              Owner
            </span>
          </div>
        )}

        {client.position === "TENANT" && (
          <div className="">
            <span className="bg-newOrange-600 text-newOrange-500 inline-flex items-center rounded px-2 py-1 text-sm font-medium estimateStatus">
              Tenant
            </span>
          </div>
        )}

        <div className="px-2">
          <span className="bg-newBlue-500 text-newBlue-400 inline-flex items-center rounded px-2 py-1 text-sm font-medium estimateStatus">
            Tickets: {client.numberOfTickets}
          </span>
        </div>

        {client.clientId &&
          (client.contactId || client.clientId) !==
            buildingData.building.primaryClientId && (
            <div className="px-4 bg-newGray-1400 rounded py-1 cursor-pointer">
              <button
                className="bg-newGray-1400 text-newGray-1200 cursor-pointer"
                onClick={() => {
                  primaryAction(client);
                }}
              >
                Mark as Primary
              </button>
            </div>
          )}

        {(client.contactId === buildingData.building.primaryClientId ||
          client.clientId === buildingData.building.primaryClientId) && (
          <div className="px-2  bg-green-200 rounded py-1">
            <span className="bg-green-200 text-green-600">Primary</span>
          </div>
        )}
      </div>
      <div>
        <Link
          className="px-4 bg-newGray-1100 text-newGray-1200"
          href={
            client.contactId
              ? `/individual/${client.contactId}/buildings`
              : `/commercial/${client.clientId}/buildings`
          }
        >
          {/* Desktop Text */}
          <span className="hidden md:inline">Open Profile</span>
          {/* Mobile Icon */}
          <span className="md:hidden">
            <CaretRight />
          </span>
        </Link>
      </div>
    </div>
  );
}
