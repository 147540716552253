import { useEffect, useState } from "react";

import { Avatar } from "@mui/material";
import { navigate } from "raviger";

import { useAppActions } from "../../../actions/AppActions";
import {
  HomeIcon,
  MessageIcon,
  NoManagerIcon,
  ThreeDotsIcon,
} from "../../../components/clients/ClientAppIcons";
import { SearchIcon } from "../../../components/common/AppIcons";
import HeadlessDropdown from "../../../components/common/HeadlessDropdown";
import NoDataComponent from "../../../components/common/NoDataComponent";
import { filterUnique } from "../../../utils/ModelUtils";
import { deepFilter } from "../../../utils/StateUtils";

export default function BuildingManagers({
  clientId,
  selectedBuilding,
  managers,
  refreshCB,
  managerTitle = "Managers",
}) {
  const [searchText, setSearchText] = useState("");

  const { createManager } = useAppActions();

  const searchManagers = deepFilter(searchText);

  const filteredManagers = managers?.filter(searchManagers) || [];

  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-col md:flex-row justify-between md:items-center py-4">
        <div>
          <span className="font-semibold">{managerTitle}</span>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div className="flex flex-row items-center px-2 rounded bg-newGray-1400 ">
            <div>
              <SearchIcon />
            </div>
            <input
              id="building_managers_search"
              className="focus:outline-none p-2 text-gray-700 bg-newGray-1400 w-52"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          {/* <div>
            <button
              className="border rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400"
              onClick={() => {}}
            >
              <div className="flex flex-row items-center">
                <div>
                  <AddIconBlue />
                </div>
                <div className="px-2">Add Manager</div>
              </div>
            </button>
          </div> */}
        </div>
      </div>

      {(!filteredManagers || filteredManagers.length === 0) && (
        <NoDataComponent
          icon={<NoManagerIcon />}
          lines={[
            "Oops! Couldn’t find any Managers!",
            "Add a new manager by clicking the button below",
          ]}
          buttonText={"Add Manager"}
          buttonDisplay={true}
          buttonAction={() => {
            createManager({
              clientId: clientId,
              buildingId: selectedBuilding.buildingId,
              callback: () => {
                refreshCB && refreshCB();
              },
            });
          }}
        />
      )}
      <ClientManagerList
        clientId={clientId}
        managers={filteredManagers}
        buildingId={selectedBuilding.buildingId}
        refreshCB={refreshCB}
      />
    </div>
  );
}

const ClientManagerList = ({ clientId, buildingId, managers, refreshCB }) => {
  const {
    editContact,
    editManagerAccess,
    refreshManagerAccessData,
    quickMessage,
  } = useAppActions();

  // TODO: This is a hack to update the manager object in the global state
  // when the manager object is updated in the managers array.
  useEffect(() => {
    console.log("Refreshing manager access data from BuildingManagers");
    refreshManagerAccessData({ managers });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managers]);

  return (
    <div className="flex flex-col">
      {managers &&
        managers
          ?.filter((manager) => {
            return manager.buildings.some(
              (building) => building.buildingId === buildingId
            );
          })
          ?.filter((manager, index, self) => {
            return (
              index === self.findIndex((m) => m.contactId === manager.contactId)
            );
          })
          .map((manager) => (
            <div className="p-2 border rounded-lg border-newGray-1500 flex flex-col ">
              <div className={"flex flex-row items-center justify-between"}>
                <div
                  className={
                    "flex flex-col gap-3 md:flex-row items-center justify-between"
                  }
                >
                  <div className="flex flex-row items-center gap-2">
                    <Avatar
                      src={manager.profilePicture}
                      variant="square"
                      className="h-8 w-8 rounded"
                    ></Avatar>

                    <div className="font-medium">{`${manager.firstName} ${manager.lastName}`}</div>
                    <button
                      className="px-1 py-1"
                      onClick={(e) => {
                        e.stopPropagation();
                        // navigate(`/commercial/${clientId}/messages/${manager.contactId}`);
                        quickMessage({
                          contactId: manager.contactId,
                          contacts: [manager],
                          clientId: clientId,
                        });
                      }}
                    >
                      {" "}
                      <MessageIcon className="h-8 w-8 bg-white rounded-md hover:bg-indigo-800 hover:text-white" />
                    </button>
                  </div>
                  <div className="items-center">
                    <span className=" bg-newBlue-500 inline-flex px-2 py-2 text-sm font-medium rounded">
                      <div className="flex flex-row items-center gap-2">
                        <div>
                          <HomeIcon />
                        </div>
                        <div className="text-newBlue-400 flex flex-wrap gap-1">
                          Managed
                          <span className="whitespace-nowrap">
                            {" "}
                            Properties:{" "}
                            {manager.buildings
                              ? manager.buildings?.filter(
                                  filterUnique("buildingId")
                                ).length
                              : 0}
                          </span>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <div className="px-2">
                    <HeadlessDropdown
                      value={"Options"}
                      options={["Add/Remove Units", "Edit"]}
                      onChange={(value) => {
                        if (value === "Add/Remove Units") {
                          editManagerAccess({
                            manager: manager,
                            clientId: manager.managerOfClientId,
                            callback: () => {
                              refreshCB();
                            },
                          });
                        } else if (value === "Edit") {
                          editContact(
                            {
                              contactId: manager.contactId,
                              firstName: manager.firstName,
                              lastName: manager.lastName,
                              email: manager.email,
                              phone: manager.phone,
                              profilePicture: manager.profilePicture,
                            },
                            () => {
                              refreshCB && refreshCB();
                            }
                          );
                        }
                      }}
                    >
                      <div className="px-2 py-2 bg-gray-300 hover:bg-gray-400 rounded">
                        <ThreeDotsIcon />
                      </div>
                    </HeadlessDropdown>
                  </div>
                </div>
              </div>
            </div>
          ))}
    </div>
  );
};
