import React from "react";
import Popup from "../../common/Popup";
import { LeftArrowIcon } from "../../common/AppIcons";

export default function ImagePopup({
  view,
  onChange,
  image_popup,
  container_className,
  image_className,
}) {
  const change_image = ({ next, previous }) => {
    let array = Object.keys(image_popup);
    let next_index = null;
    for (let i = 0; i < array.length; i++) {
      if (Number(array[i]) || Number(array[i]) === 0) {
        if (
          next &&
          Number(array[i]) > image_popup.index &&
          (!next_index || next_index > Number(array[i]))
        )
          next_index = Number(array[i]);

        if (
          previous &&
          Number(array[i]) < image_popup.index &&
          (!next_index || next_index < Number(array[i]))
        )
          next_index = Number(array[i]);
      }
    }

    return next_index;
  };

  return (
    <Popup
      view={view}
      set_view={() => {
        onChange({ ...image_popup, popup: false });
      }}
      className={
        "bg-white border shadow rounded-md max-h-screen overflow-hidden " +
        container_className
      }
    >
      <div className="w-full flex flex-row-reverse absolute top-0">
        <div
          onClick={() => {
            onChange({ ...image_popup, popup: false });
          }}
          className={"relative top-3 right-10 flex justify-end delay-200"}
        >
          <span className="px-1 rounded bg-white hover:bg-gray-100 cursor-pointer border shadow text-xl text-red-500 hover:text-red-700">
            &#10005;
          </span>
        </div>
      </div>
      <div className="flex flex-row items-center ">
        <div
          onClick={() => {
            if (change_image({ previous: true }) !== null)
              onChange({
                ...image_popup,
                index: change_image({ previous: true }),
              });
          }}
          className={
            "h-screen flex items-center justify-center px-10 " +
            (change_image({ previous: true }) !== null
              ? "text-gray-900 delay-200 duration-200 hover:bg-gray-400 hover:bg-opacity-10"
              : "text-gray-700")
          }
        >
          <LeftArrowIcon />
        </div>
        <div
          onClick={() => onChange({ ...image_popup, popup: false })}
          className="flex items-center justify-center h-screen"
        >
          <img
            onClick={(e) => e.stopPropagation()}
            alt=""
            src={image_popup[image_popup.index]}
            className={
              "object-scale-down max-w-4xl max-h-screen " + image_className
            }
          />
        </div>
        <div
          onClick={() => {
            if (change_image({ next: true }) !== null)
              onChange({ ...image_popup, index: change_image({ next: true }) });
          }}
          className={
            "h-screen flex items-center justify-center px-10 " +
            (change_image({ next: true }) !== null
              ? "text-gray-900 delay-200 duration-200 hover:bg-gray-400 hover:bg-opacity-10"
              : "text-gray-600")
          }
        >
          <LeftArrowIcon className="transform rotate-180" />
        </div>
      </div>
    </Popup>
  );
}
