/* Untyped file generated from PhoneNumber.res by genType. */
/* eslint-disable */

import {default as makeNotChecked} from './PhoneNumber.jsx';

// In case of type error, check the type of 'make' in 'PhoneNumber.re' and './PhoneNumber.jsx'.
export const makeTypeChecked = makeNotChecked;

// Export 'make' early to allow circular import from the '.bs.js' file.
export const make = makeTypeChecked;
