import { useEffect, useState } from "react";

import { alertError } from "../actions/AlertActions";
import { listSupplier, listSupplierAddress } from "../api/Api";
import { materialMethods } from "../components/common/form/constants";
import { conditionalArrayEntry } from "../utils/ObjectUtills";

export function useMaterials(data) {
  console.log("Resolving useMaterials with ", data);
  const [suppliers, setSuppliers] = useState([]);
  const [supplierAddress, setSupplierAddress] = useState([]);
  useEffect(() => {
    listSupplier().then((data) =>
      setSuppliers(
        data.map((supplier) => ({
          label: supplier.name,
          value: supplier.supplierId,
          tags: supplier.typeOfSupplies || [],
        }))
      )
    );
  }, []);

  useEffect(() => {
    console.log("Data", data);
    if (data.supplierId)
      listSupplierAddress(data.supplierId)
        .then((data) =>
          setSupplierAddress(
            data.map((supplierAdd) => ({
              label: `${supplierAdd.streetAddress1} ${supplierAdd.streetAddress2}, ${supplierAdd.city}`,
              value: supplierAdd.buildingId,
            }))
          )
        )
        .catch((_err) => {
          alertError(
            "Sorry, we couldn't populate suppliers. Please try again later."
          );
        });
  }, [data]);

  return [
    {
      name: "method",
      inputType: "SELECT",
      options: materialMethods,
    },
    {
      name: "name",
      label: "Material Name",
    },
    { name: "description", inputType: "AREA", variant: "OUTLINED" },
    { name: "skuNumber", label: "SKU Number" },
    {
      name: "supplierId",
      inputType: "SELECT",
      variant: "SELECT_WITH_TAG",
      options: suppliers,
    },
    {
      name: "supplierBranchId",
      inputType: "SELECT",
      options: supplierAddress,
    },
    ...(data.method !== "IN_STOCK"
      ? [
          // {
          //   name: "deliveryDate",
          //   inputType: "DATE",
          //   variant: "STEP30"
          // },
          { name: "orderNumber" },
          {
            name: "orderDate",
            inputType: "DATE",
            variant: "STEP30",
            // defaultValue: getDateStep30(new Date())
          },
          { name: "trackingUrl" },

          ...conditionalArrayEntry(data.materialsId, {
            name: "isDelivered",
            inputType: "SELECT",

            options: [
              { label: "Yes", value: "true" },
              { label: "No", value: "false" },
            ],
          }),
        ]
      : []),
  ];
}
