import { atom } from "recoil";

// The ClientRefState has a Component & the props required to render it
// Which would be rendered in the ClientRef Page

export type ClientRefItem = {
  component: React.FC;
  props: any;
  prefill?: {
    name?: string;
    phone?: string;
    email?: string;
  };
};

export type ClientRefData = ClientRefItem | null;

export const clientRefAtom = atom<ClientRefData>({
  key: "clientRefAtom",
  default: null,
});
