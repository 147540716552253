import React, { useEffect, useState } from "react";

import {
  Badge, // Button, // FormControl,
  InputBase, // MenuItem,
  // Select,
  // TextField,
  // Typography
} from "@mui/material";
import { Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";
import "moment-timezone";
import { useRecoilState } from "recoil";

import { alertError } from "../../actions/AlertActions";
import {
  subscribeTopic,
  unsubscribeTopic,
} from "../../actions/NotificationActions";
import {
  // createSR,
  findByTerritoryWithAppointmentCount, // getTicket,
  getTerritory, // getAssociatesByTerritory,
  getUnscheduledByTerritory,
  scheduledSRs,
} from "../../api/Api";
import dispatcher from "../../dispatcher";
import {
  addDays,
  dateEqual,
  getDateStep30,
  getNextDay,
  getStartOfDay,
  getToday,
  timeAfterNHours,
} from "../../utils/DateUtils";
import { deepUpdate } from "../../utils/StateUtils";
import { convertToAscendingDescendingDict } from "../../utils/StateUtils";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { properString, renderAddress } from "../../utils/StringUtils";
import RenderAddressComponent from "../clients/RenderAddressComponent";
import { EnlargeIcon, RefreshIcon, SearchIcon } from "../common/AppIcons";
// import { schedule } from "../../api/Actions";
import MaterialTable from "../common/MaterialTable";
import NewServiceRequestPopup from "../common/NewServiceRequestPopup";
// import ServiceRequestDetail from "../service/ServiceRequestDetail";
// import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import {
  JobTypesDict,
  consolidatedSubCategory, // formServiceRequest,
  // scheduleForm,
  // territories,
  ticketStatusObj,
} from "../common/form/constants";
// import NewServiceRequestPopup from "../common/NewServiceRequestPopup";
import ArrowedDatePicker from "./ArrowedDatePicker";
import AssociateTab from "./AssociateTab";
import CustomDatePicker from "./DatePicker";
import MapComponent from "./MapComponent";
import MultiScheduleItem from "./MultiScheduleItem";
import ScheduleItem from "./ScheduleItem";

//test coment

const wrappedText = (text, lines = 1, linebreak = 60) => {
  // Split text into lines. Wrap by space, with 1 line always less than `linebreak` chars.
  const wrappedLines = text?.split(" ").reduce(
    (acc, word) => {
      const last = acc[acc.length - 1];
      if (last.length + word.length < linebreak) {
        acc[acc.length - 1] = last + " " + word;
      } else {
        acc.push(word);
      }
      return acc;
    }, // Start with an empty line.
    [""]
  );
  return (
    <div className="flex flex-col">
      {wrappedLines?.slice(0, lines).map((line, i) => (
        <div key={i}>
          {line}
          {i === lines - 1 && wrappedLines.length > lines ? "..." : ""}
        </div>
      ))}
    </div>
  );
};
const emptyCell = (i) => (
  <td
    key={i}
    className={
      "p-2 z-20 border-newGray-500" +
      (i % 2 !== 0 ? " border-r border-b" : " border-l border-b")
    }
  ></td>
);

const emptyRow = (size) =>
  Array.apply(null, { length: size }).map((_, i) => emptyCell(i));

const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};
const backgroundFor = (status) => {
  // Comments to avoid classname purge; bg-status-assigned bg-status-accepted bg-status-rejected bg-status-enroute bg-status-started bg-status-finished

  switch (status) {
    case "ASSIGNED":
      return `bg-status-assigned`;
    case "ACCEPTED":
      return `bg-status-accepted`;
    case "ENROUTE":
      return `bg-status-enroute`;
    case "STARTED":
      return `bg-status-started`;
    case "FINISHED":
      return `bg-status-finished`;
    case "REJECTED":
      return `bg-status-rejected text-white`;
    default:
      return "bg-white";
  }
};
const getDateRange = (firstDate, lastDate) => {
  if (firstDate instanceof Date && lastDate instanceof Date) {
    // console.log(firstDate.getTime() + " > " + lastDate.getTime())
    if (firstDate.getTime() > lastDate.getTime()) return [];
    let pivotDate = firstDate;
    let returnable = [new Date(pivotDate)];
    while (!dateEqual(pivotDate, lastDate)) {
      pivotDate.setDate(pivotDate.getDate() + 1);
      returnable = [...returnable, new Date(pivotDate)];
    }
    return returnable;
  }
  return [];
};
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: "relative",
    border: "1px solid #ced4da",
    borderTop: "none",
    borderBottom: "none",
    fontSize: 13,
    padding: "9px ",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: 6,
    fontSize: 8,
    minWidth: 4,
    width: 15,
    height: 15,
    color: "white",
    backgroundColor: theme.palette.success.main,
    // padding: '1px',
  },
}))(Badge);

const getJobType = (jobType, subCategory) => {
  // If subcategory is not specified, return the proper jobType
  // console.log("getJobType", jobType, subCategory);
  const properJobType = JobTypesDict[jobType];
  if (!subCategory) return properJobType;
  const resolvedSubcategory = consolidatedSubCategory.find((item) => {
    if (item.value === subCategory) {
      return item;
    }
  });
  return resolvedSubcategory ? resolvedSubcategory.label : properJobType;
};

export default function GanttChart({ from }) {
  const [associates, setAssociates] = useState([]);
  const [unassignedTickets, setUnassignedTickets] = useState();
  const [loading, setLoading] = useState(true);
  const [newSchedule, setNewSchedule] = useState();
  const [forUpdate, setForUpdate] = useState();
  const [searchText, setSearchText] = useState("");
  const [assSearchText, setAssSearchText] = useState("");

  const [data, setData] = useState();
  const [notification, setNotification] = useState(false);

  const [fulltable, setFullTable] = useState(false);
  const [fullGantt, setFullGantt] = useState(false);
  const [ganttParams, setGanttParams] = useState({
    showMap: false,
    from: from ? formatDate(from) : formatDate(getToday()),
    to: from ? formatDate(addDays(7, from)) : formatDate(addDays(7)),
    territory: "",
    tab: "Gantt",
  });
  const [dateRange, setDateRange] = useState(
    getDateRange(new Date(ganttParams.from), new Date(ganttParams.to))
  );

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [refresh, setRefresh] = useState(true);

  const [territories, setTerritories] = useState([]);

  useEffect(() => {
    getTerritory().then((data) => {
      setTerritories(data);
      setGanttParams({ ...ganttParams, territory: data[0].name });
    });
  }, []);

  const onChangeParams = (update) => {
    const { name, value } = update;
    setGanttParams((data) => deepUpdate(name, value, data));
  };

  const fromDateChange = (date) => {
    if (formatDate(date) > ganttParams.to) {
      alertError("Please select a valid range");
      return;
    }
    setGanttParams((current) => ({ ...current, from: formatDate(date) }));
  };
  const toDateChange = (date) => {
    if (formatDate(date) < ganttParams.from) {
      alertError("Please select a valid range");
      return;
    }
    setGanttParams((current) => ({ ...current, to: formatDate(date) }));
  };
  const arrowCB = (date, mode) => {
    if (mode === "from") {
      if (formatDate(date) > ganttParams.to) {
        alertError("Please select a valid range");
        return;
      }
      setGanttParams((current) => ({ ...current, from: formatDate(date) }));
    } else {
      if (formatDate(date) < ganttParams.from) {
        alertError("Please select a valid range");
        return;
      }
      setGanttParams((current) => ({ ...current, to: formatDate(date) }));
    }
  };

  const onMonthChange = (month, year) => {
    const date = new Date(`${year}/${month + 1}/01`);

    const firstDate = moment(date).startOf("month").format("YYYY-MM-DD");
    const lastDate = moment(date).endOf("month").format("YYYY-MM-DD");

    setGanttParams((current) => ({
      ...current,
      from: firstDate,
      to: lastDate,
    }));
  };

  const handleAction = (action) => {
    action.actionType === "NOTIFICATION" &&
      action.topic.includes("/notifications/scheduler") &&
      setNotification((current) => !current);
  };
  useEffect(() => {
    dispatcher.register(handleAction);
    window.dispatcher = dispatcher;
    subscribeTopic({
      topic: "/notifications/scheduler",
    });

    return () => {
      unsubscribeTopic({
        topic: "/notifications/scheduler",
      });
    };
  }, []);

  useEffect(() => {
    initGetData();
  }, [notification]);

  const onChange = (update) => {
    const { name, value } = update;

    if (name === "startTime") {
      setNewSchedule((data) => {
        const intermediate = deepUpdate(name, value, data);
        return deepUpdate(
          "endTime",
          timeAfterNHours(new Date(value), data.serviceRequest.duration ?? 2),
          intermediate
        );
      });
    } else setNewSchedule((data) => deepUpdate(name, value, data));
  };

  const onChangeUpdate = (update) => {
    const { name, value } = update;
    if (name === "startTime") {
      setForUpdate((data) => {
        const intermediate = deepUpdate(name, value, data);
        return deepUpdate(
          "endTime",
          timeAfterNHours(new Date(value), data.duration ?? 2),
          intermediate
        );
      });
    } else setForUpdate((data) => deepUpdate(name, value, data));
  };

  const calculateSlots = (start, end) => {
    const startMinutes = new Date(Date.parse(start)).getMinutes();
    let startHours = new Date(Date.parse(start)).getHours();
    startHours += startMinutes / 60;
    let endHours = new Date(Date.parse(end)).getHours();
    const endMinutes = new Date(Date.parse(end)).getMinutes();
    endHours += endMinutes / 60;
    const slotDuration =
      endHours - startHours === 0 ? 1 : (endHours - startHours) * 2;
    const slotStart = (startHours - 7) * 2;
    // console.log(`${slotStart} to ${slotDuration} for ${start} to ${end}`);
    return { slotDuration, slotStart };
  };

  const getTableData = () => {
    let data = unassignedTickets || [];

    if (ganttParams.territory !== "ALL") {
      data = data.filter((ticket) => {
        return ticket.territory === ganttParams.territory;
      });
    }

    data = convertToAscendingDescendingDict(
      data,
      "ticketId",
      "serviceRequestIndex",
      "desc"
    ).map((row) => {
      const contactName = `${row.contactFirstName} ${row.contactLastName}`;
      return {
        id: row.serviceRequestId,
        data: [
          `${row.ticketId}-` +
            (!row.isDraft
              ? `SR${row.serviceRequestIndex}`
              : `I${row.serviceRequestIndex}`),
          {
            render: (
              <div className="text-gray-900 flex w-full items-center">
                <Avatar
                  src={row.contactProfilePic || row.alternateContactProfilePic}
                  className="h-8 w-8"
                >
                  {(row.contactFirstName && row.contactFirstName[0]) ??
                    (row.contactLastName && row.contactLastName[0]) ??
                    ""}
                </Avatar>
                <div className="flex flex-col ml-2">
                  <span className="px-2">{contactName}</span>
                  {contactName !== row.clientName && (
                    <span className="px-2 font-semibold">
                      {" "}
                      {row.clientName}
                    </span>
                  )}
                </div>
              </div>
            ),
            sortBy: `${row.contactFirstName} ${row.contactLastName}`,
          },
          getJobType(row.jobType, row.subCategory),
          {
            // row.description wrapped into 3 lines and cut off with ellipsis
            render: wrappedText(row.description, 3),
            sortBy: row.description,
          },
          {
            render: <RenderAddressComponent address={row} />,
            sortBy: row.streetAddress1,
          },
          {
            render: row.preferredDateTime
              ? new Date(row.preferredDateTime).toLocaleString()
              : "-",
          },
          ticketStatusObj[row.bookingStatus],
        ],
      };
    });

    return data;
  };

  const initGetData = () => {
    if (!newSchedule && !forUpdate && ganttParams.territory) {
      setDateRange(
        getDateRange(new Date(ganttParams.from), new Date(ganttParams.to))
      );
      scheduledSRs({
        timelineStart: getStartOfDay(ganttParams.from),
        timelineEnd: getNextDay(ganttParams.to),
      })
        .then((scheduledSR) => {
          let temp = [];
          let timezone = territories.filter((item) => {
            if (ganttParams.territory === item.name) {
              return true;
            }
          });
          // let currentOffset = moment().utcOffset();

          let requiredTimezoneOffset = moment()
            .tz(timezone[0]?.timezone)
            ?.utcOffset();

          scheduledSR?.forEach((item, index) => {
            let tempItem = {
              ...item,
              timelineStart: moment(item.timelineStart.split("Z")[0]).add(
                requiredTimezoneOffset,
                "minutes"
              ),
              timelineEnd: moment(item.timelineEnd.split("Z")[0]).add(
                requiredTimezoneOffset,
                "minutes"
              ),
            };

            temp.push(tempItem);
          });

          setData(temp);
        })
        .catch((error) => {
          alertError("Couldn't get Appointments");
        });

      findByTerritoryWithAppointmentCount({
        territory: ganttParams.territory,
        timelineStart: getStartOfDay(ganttParams.from),
        timelineEnd: getNextDay(ganttParams.to),
      })
        .then((data) => {
          let sorted = data.sort(
            (first, second) =>
              parseInt(first.appointmentCount) -
              parseInt(second.appointmentCount)
          );

          setAssociates(sorted.reverse());
          setLoading(false);
        })
        .catch((error) => alertError("Couldn't get Associates"));

      getUnscheduled(ganttParams.territory);
    }
  };

  const getUnscheduled = () => {
    getUnscheduledByTerritory(ganttParams.territory)
      .then((tickets) => setUnassignedTickets(tickets))
      .catch((error) => alertError("Couldn't get Unassigned Tickets"));
  };

  const useStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 120,
    },
    dateAndTime: {
      fontSize: 12,
      padding: "10px 0px",
    },
  }));
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (territories.length > 0) {
      initGetData();
    }
  }, [
    territories,
    newSchedule,
    forUpdate,
    ganttParams.from,
    ganttParams.to,
    ganttParams.territory,
  ]);

  const newRenderTicket = (data) => {
    let initArray = emptyRow(26);
    let modifiable = initArray;
    let overlapping = [];

    let sortedData = data.sort(
      (a, b) => new Date(a.timelineStart) - new Date(b.timelineStart)
    );

    data = sortedData;

    if (data) {
      data.map((item) => (item["status"] = true));

      for (let index = 0; index < data.length; index++) {
        let current = data[index];

        if (current.status) {
          let { slotStart, slotDuration } = calculateSlots(
            current.timelineStart,
            current.timelineEnd
          );

          let normalizedSlotStart = slotStart - (26 - initArray.length);

          let overlapEnd = current.timelineEnd;

          overlapping.push(current);

          while (overlapEnd) {
            for (let i = index + 1; i < data.length; i++) {
              if (data[i].status) {
                if (overlapEnd && data[i]?.timelineStart) {
                  if (overlapEnd > data[i]?.timelineStart) {
                    overlapping.push(data[i]);
                    data[i]["status"] = false;
                  }
                }
              }
            }
            overlapEnd = false;
          }

          if (overlapping.length > 1) {
            let { slotStart, slotDuration } = calculateSlots(
              overlapping[0].timelineStart,
              overlapping[overlapping.length - 1].timelineEnd
            );

            normalizedSlotStart = slotStart - (26 - initArray.length);

            modifiable.splice(
              normalizedSlotStart,
              slotDuration,
              <MultiScheduleItem
                territory={ganttParams.territory}
                slotDuration={slotDuration}
                current={overlapping}
                setForUpdate={setForUpdate}
                backgroundFor={backgroundFor}
              />
            );

            overlapping = [];
          } else {
            // if (current.status) {
            overlapping = [];

            modifiable.splice(
              normalizedSlotStart,
              slotDuration,
              <ScheduleItem
                territory={ganttParams.territory}
                slotDuration={slotDuration}
                current={current}
                setForUpdate={setForUpdate}
                backgroundFor={backgroundFor}
              />
            );
            // }
          }
        }
      }
    }

    return modifiable;
  };

  // const renderTicket = (data) =>
  //   data
  //     .sort((a, b) => new Date(a.timelineStart) - new Date(b.timelineStart))
  //     .reduce((acc, current) => {
  //       const { slotStart, slotDuration } = calculateSlots(
  //         current.timelineStart,
  //         current.timelineEnd
  //       );
  //       const normalizedSlotStart = slotStart - (26 - acc.length);
  //       // console.log(
  //       //   "slotStart" +
  //       //     slotStart +
  //       //     " acc.length " +
  //       //     acc.length +
  //       //     " current.timelineStart " +
  //       //     new Date(current.timelineStart).toLocaleDateString() +
  //       //     " " +
  //       //     new Date(current.timelineStart).toLocaleTimeString() +
  //       //     " current.timelineEnd " +
  //       //     new Date(current.timelineEnd).toLocaleDateString() +
  //       //     " " +
  //       //     new Date(current.timelineEnd).toLocaleTimeString()
  //       // );
  //       // console.log(
  //       //   "Reducing from " +
  //       //     normalizedSlotStart +
  //       //     " to " +
  //       //     slotDuration +
  //       //     " for " +
  //       //     current.clientName
  //       // );
  //       let modifiable = acc;
  //       modifiable.splice(
  //         normalizedSlotStart,
  //         slotDuration,
  //         <ScheduleItem
  //           territory={ganttParams.territory}
  //           slotDuration={slotDuration}
  //           current={current}
  //           setForUpdate={setForUpdate}
  //           backgroundFor={backgroundFor}
  //         />
  //       );
  //       return modifiable;
  //     }, emptyRow(26));

  const associateFilter = () => {
    let data = associates;
    if (assSearchText && data) {
      data = data.filter((item) => {
        return (item.firstName + "" + item.lastName)
          .toLowerCase()
          .includes(assSearchText.toLowerCase());
      });
    }
    return data;
  };

  return (
    <div className="w-full flex flex-col h-lscreen bg-white">
      {!fulltable && (
        <div className="w-full flex flex-row items-center justify-between flex-wrap gap-4 py-2">
          <div className="flex  flex-row items-center  justify-center md:justify-start flex-wrap gap-4 md:gap-16 px-1">
            <div className="flex items-center">
              <div className="bg-newGray-100 p-0.5 flex flex-row items-center rounded">
                {[
                  {
                    label: "Map",
                  },
                  {
                    label: "Gantt",
                  },
                ].map((switch_element, switch_element_index) => (
                  <div
                    key={switch_element_index}
                    className={
                      "py-1 px-8 rounded font-normal text-sm " +
                      (ganttParams.tab === switch_element.label
                        ? "bg-white text-newBlue-100"
                        : "text-gray-400 bg-newGray-100")
                    }
                    onClick={() => {
                      let showMap = false;
                      if (switch_element.label === "Map") {
                        showMap = true;
                      }
                      setGanttParams((ganttParams) => {
                        return {
                          ...ganttParams,
                          showMap: showMap,
                          tab: switch_element.label,
                        };
                      });
                    }}
                  >
                    {switch_element.label}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center gap-4 md:gap-16">
              {/* <div className="border px-2 py-1 rounded"> */}
              <CustomDatePicker
                date={ganttParams.from}
                onMonthChange={onMonthChange}
              />
              {/* </div> */}
              <div className="flex flex-row items-center gap-2">
                <div className="flex flex-row gap-3 items-center">
                  <span className="text-gray-500 hidden md:inline">From</span>
                  <ArrowedDatePicker
                    mode={"from"}
                    date={ganttParams.from}
                    onDateChange={fromDateChange}
                    arrowCB={arrowCB}
                  />
                </div>
                <div className="flex flex-row gap-2 items-center">
                  <span className=" text-gray-500">To</span>
                  <ArrowedDatePicker
                    mode={"to"}
                    date={ganttParams.to}
                    onDateChange={toDateChange}
                    arrowCB={arrowCB}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-auto flex flex-row items-center justify-center md:justify-start gap-3 px-1">
            <select
              id="countries"
              className="bg-gray-50 border border-newGray-200 text-black text-sm rounded py-1 px-1"
              onChange={(e) => {
                onChangeParams({ name: "territory", value: e.target.value });
              }}
              defaultValue={ganttParams.territory}
            >
              <option value="ALL" selected={ganttParams.territory === "ALL"}>
                ALL
              </option>
              {territories.map((options) => (
                <option
                  value={options.name}
                  selected={ganttParams.territory === options.name}
                >
                  {properString(options.name)}
                </option>
              ))}
            </select>
            <div
              className="border p-1 rounded border-newGray-200 items-center cursor-pointer"
              onClick={() => {
                initGetData();
              }}
            >
              <RefreshIcon />
            </div>
            <div className="border p-1 rounded border-newGray-200 cursor-pointer">
              <div
                onClick={(_) => {
                  setFullGantt((current) => !current);
                }}
              >
                <EnlargeIcon />
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={
          !fulltable
            ? "grid grid-cols-1 grid-rows-6 w-full h-full  bg-white text-xs"
            : "w-full h-full bg-white text-xs"
        }
      >
        {!fulltable && (
          <div
            className={`w-full ${
              fullGantt ? "row-span-6" : "row-span-3 md:row-span-4"
            } flex`}
          >
            {ganttParams.showMap && (
              <MapComponent
                data={data
                  .filter((sr) => sr.geocodeLatitude && sr.geocodeLongitude)
                  .map((sr) => {
                    return {
                      label: sr.clientName,
                      lat: sr.geocodeLatitude,
                      lng: sr.geocodeLongitude,
                    };
                  })}
              />
            )}
            <div className="relative overflow-auto  w-full">
              <table className=" table-auto text-left">
                <thead className="bg-white">
                  <tr className="px-2 ">
                    <th
                      className="px-2 bg-white sticky left-0 border-newGray-500 border-r"
                      colSpan="6"
                      rowSpan="2"
                    >
                      <div className="flex flex-row items-center px-2 rounded bg-gray-100">
                        <div
                          onClick={() => {
                            document.getElementById("search_input").focus();
                          }}
                        >
                          <SearchIcon />
                        </div>
                        <input
                          id="search_input"
                          className="focus:outline-none p-2 text-gray-700 bg-gray-100"
                          placeholder="Search..."
                          value={assSearchText}
                          onChange={(e) => setAssSearchText(e.target.value)}
                        />
                      </div>
                    </th>
                    {dateRange.map((dateObj) => {
                      return (
                        <th
                          key={dateObj.toLocaleDateString()}
                          colSpan="26"
                          className="text-center border-t border-l bg-newGray-400 px-2 py-4 text-newBlue-200 border-newGray-500"
                        >
                          {dateObj.toLocaleDateString("en-US", {
                            dateStyle: "full",
                          })}
                        </th>
                      );
                    })}
                  </tr>
                  <tr className="px-2 bg-white">
                    {dateRange.map((date) => {
                      return [
                        "07:00",
                        "08:00",
                        "09:00",
                        "10:00",
                        "11:00",
                        "12:00",
                        "13:00",
                        "14:00",
                        "15:00",
                        "16:00",
                        "17:00",
                        "18:00",
                        "19:00",
                      ].map((time) => (
                        <th
                          key={time}
                          className="px-6 border-r border-newGray-500 py-3 font-normal"
                          colSpan="2"
                        >
                          {time}
                        </th>
                      ));
                    })}
                  </tr>
                </thead>
                <tbody
                  style={{ height: "200px" }}
                  className="bg-grey-light w-full "
                >
                  {associateFilter()?.map((associate) => (
                    <tr className="relative h-12">
                      <AssociateTab
                        associate={associate}
                        currentJob={
                          data?.filter(
                            (apmt) =>
                              apmt.associateId === associate.associateId &&
                              dateEqual(
                                new Date(),
                                new Date(Date.parse(apmt.timelineStart))
                              )
                          ) || []
                        }
                      />
                      {dateRange.map((colDate) => {
                        return (
                          <>
                            {newRenderTicket(
                              data?.filter(
                                (apmt) =>
                                  apmt.associateId === associate.associateId &&
                                  dateEqual(
                                    colDate,
                                    new Date(Date.parse(apmt.timelineStart))
                                  )
                              ) || []
                            )}
                          </>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {!fullGantt && (
          <div className="w-full h-full row-span-3 md:row-span-2 flex flex-col py-2">
            <div className="w-full flex flex-col lg:flex-row items-center justify-between flex-wrap gap-4 py-2 bg-newGray-400">
              <div className="flex flex-row items-center flex-wrap gap-4 lg:gap-14 px-1 lg:w-5/12 justify-center lg:justify-start">
                <div className="p-0.5 flex flex-row items-center rounded">
                  <span className="text-newBlue-300 text-xs whitespace-nowrap">
                    Unassigned Service Requests
                  </span>
                </div>
                <div className="flex flex-row items-center px-2 rounded bg-gray-100 border border-newGray-200">
                  <div
                    onClick={() => {
                      document.getElementById("search_input").focus();
                    }}
                  >
                    <SearchIcon />
                  </div>
                  <input
                    id="search_input"
                    className="focus:outline-none rounded text-gray-700 bg-gray-10 md:w-56 px-4 py-1 outline-none transition-all duration-700 ease-in-out focus:shadow-outline hover:w-64 hover:w-64 "
                    placeholder="Search for job appointment"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </div>
              <div className="hidden lg:flex flex-row items-center gap-2 md:gap-1">
                <div className="px-2">
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-normal bg-status-assigned">
                    {" "}
                    Assigned{" "}
                  </span>
                </div>
                <div className="px-2">
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-normal bg-status-rejected">
                    {" "}
                    Rejected{" "}
                  </span>
                </div>
                <div className="px-2">
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-normal bg-status-accepted">
                    {" "}
                    Accepted{" "}
                  </span>
                </div>
                <div className="px-2">
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-normal bg-status-enroute">
                    {" "}
                    En Route{" "}
                  </span>
                </div>
                <div className="px-2">
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-normal bg-status-started">
                    {" "}
                    Started{" "}
                  </span>
                </div>
                <div className="px-2">
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-normal bg-status-finished">
                    {" "}
                    Finished{" "}
                  </span>
                </div>
              </div>

              <div className="flex flex-row items-center gap-3 px-1">
                <select
                  id="countries"
                  className="bg-gray-50 border border-newGray-200 text-black text-sm rounded py-1 px-1"
                  onChange={(e) => {
                    onChangeParams({
                      name: "territory",
                      value: e.target.value,
                    });
                  }}
                  defaultValue={territories[0]?.name}
                >
                  <option
                    value="ALL"
                    selected={ganttParams.territory === "ALL"}
                  >
                    ALL
                  </option>
                  {territories.map((options) => (
                    <option
                      value={options.name}
                      selected={ganttParams.territory === options.name}
                    >
                      {properString(options.name)}
                    </option>
                  ))}
                </select>

                <div
                  className="border p-1 rounded border-newGray-200 items-center cursor-pointer"
                  onClick={() => {
                    getUnscheduled();
                  }}
                >
                  <RefreshIcon />
                </div>
                <div className="border p-1 rounded border-newGray-200 cursor-pointer">
                  <div
                    onClick={(_) => {
                      setFullTable((current) => !current);
                    }}
                  >
                    <EnlargeIcon />
                  </div>
                </div>
              </div>
            </div>

            <div className="h-full row-span-5 whitespace-nowrap overflow-auto">
              <MaterialTable
                data={getTableData()}
                head={[
                  {
                    id: "serviceRequestId",
                    label: "SR #",
                  },
                  { id: "client", label: "Client" },
                  { id: "jobType", label: "Job Type" },
                  { id: "description", label: "Description" },
                  { id: "shortAddress", label: "Address" },
                  { id: "preferredDate", label: "Preferred Date" },
                  { id: "bookingStatus", label: "Status" },
                ]}
                // defaultOrderBy={"preferredDate"}
                // defaultOrder="desc"
                onClickCB={(SRId) =>
                  setNewSchedule((_) => {
                    const serviceRequest = unassignedTickets.find(
                      (job) => job.serviceRequestId === SRId
                    );
                    const startTime =
                      serviceRequest.preferredDateTime ?? new Date();
                    const endTime = timeAfterNHours(
                      new Date(startTime),
                      serviceRequest.duration ?? 2
                    );
                    return {
                      serviceRequestId: SRId,
                      startTime: getDateStep30(startTime, true),
                      endTime: getDateStep30(endTime, true),
                      serviceRequest: serviceRequest,
                      associateId: [],
                    };
                  })
                }
                searchText={searchText}
                pageRows={!fulltable ? 5 : 20}
              />
            </div>
          </div>
        )}

        {newSchedule && (
          <NewServiceRequestPopup
            data={newSchedule.serviceRequest}
            clearCB={() => {
              setNewSchedule();
            }}
          />
        )}

        {forUpdate && (
          <NewServiceRequestPopup
            data={forUpdate}
            clearCB={() => {
              setForUpdate();
            }}
          />
        )}
      </div>
    </div>
  );
}
