import React, { useEffect, useState } from "react";

import { useMediaQuery } from "../../utils/useMediaQuery";

export default function DropDown({
  options,
  onChangeCB,
  defaultValue,
  className,
}) {
  const [selected, setSelected] = useState(options[0]);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 640px)");

  useEffect(() => {
    setSelected(defaultValue ? defaultValue : options[0]);
  }, [defaultValue]);

  return (
    <div className="p-1">
      <div
        className="group inline-block relative"
        onMouseEnter={() => !isMobile && setIsOpen(true)}
        onMouseLeave={() => !isMobile && setIsOpen(false)}
      >
        <button
          className={`bg-gray-300 text-gray-700 font-semibold py-1.5 px-4 rounded inline-flex items-center ${className}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className={`mr-1 text-sm ${className}`}>
            {selected?.channel}
          </span>
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </button>
        <ul
          className={`absolute ${
            isOpen ? "block" : "hidden"
          } text-gray-700 cursor-pointer  z-10 ${className}`}
        >
          {options.map((option, _index) => (
            <li
              key={option.channel}
              className=""
              onClick={(_) => {
                onChangeCB(option.channel);
                setSelected(option);
                setIsOpen(false);
              }}
            >
              <span
                className={`rounded-t bg-gray-200 hover:bg-gray-400 py-1 px-4 block whitespace-no-wrap ${className}`}
                id={option.channel}
              >
                {option.channel}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
