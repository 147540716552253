import { useEffect, useState } from "react";

import { CallbackLink } from "../../../utils/RouterUtils";
import { TimesCircleIcon } from "../../clients/ClientAppIcons";
import { PenIcon } from "../../common/AppIcons";
import MaterialForm from "../../common/form/MaterialForm";

interface ReadOnlyAttribute {
  id: string;
  label: string;
  value: string | React.ReactNode;
  href?: string;
  onClick?: () => void;
}

interface EditableAttributeBase extends ReadOnlyAttribute {
  transform: (value: any) => string;
  onChange: (value: any) => void;
}

// Editable Attribute with options populated
interface EditableAttributePrepopulated extends EditableAttributeBase {
  options: any[];
}

// Editable Attribute with options to be populated
interface EditableAttribute extends EditableAttributeBase {
  getOptions: () => Promise<any[]>;
}

type DetailAttribute =
  | ReadOnlyAttribute
  | EditableAttribute
  | EditableAttributePrepopulated;

const computeGridCols = (cols: number) => {
  switch (cols) {
    case 1:
      return "grid-cols-1";
    case 2:
      return "grid-cols-2";
    case 3:
      return "grid-cols-3";
    default:
      return "grid-cols-2";
  }
};

export default function DetailsSection({
  attributes,
  cols = 2,
  refreshCB,
  isExternal,
  contactorName,
  editContractorName,
}: {
  attributes: DetailAttribute[];
  cols?: number;
  refreshCB: (patchObj: any) => void;
  isExternal: boolean;
  contactorName?: string;
  editContractorName: () => void;
}) {
  const [updateField, setUpdateField] = useState<any>(null);

  useEffect(() => {
    // Each attribute has an identifier and an API to populate options.
    // Also, another Update API to update the value of the attribute.
    if (
      updateField !== null &&
      !updateField.options &&
      updateField.getOptions
    ) {
      updateField.getOptions().then((options: any) => {
        setUpdateField((current: any) => ({
          ...current,
          options,
        }));
      });
    }
  }, [updateField]);

  const gridCols = computeGridCols(cols);

  return (
    <div className={`grid ${gridCols} gap-4 p-1 px-4`}>
      {attributes.map((item) => {
        const attributeType =
          // Check if `item` has `options` key
          item.hasOwnProperty("options")
            ? "EDITABLE_PREPOPULATED"
            : // Check if `item` has `getOptions` key
            item.hasOwnProperty("getOptions")
            ? "EDITABLE"
            : "READ_ONLY";
        if (updateField?.id === item.id) {
          return (
            <div className="flex items-center">
              <MaterialForm
                data={{}}
                renderArray={[
                  {
                    name: "value",
                    label: updateField.label,
                    inputType: "SELECT",
                    options: updateField.transform(updateField.options ?? []),
                  },
                ]}
                onSubmit={(_) => {}}
                onChange={({ value }: { value: string }) => {
                  refreshCB({ [updateField.id]: value });
                  setUpdateField(null);
                }}
              />
              <span onClick={() => setUpdateField(null)}>
                <TimesCircleIcon className="h-4 w-4 ml-2 cursor-pointer" />
              </span>
            </div>
          );
        }

        return (
          <div className="py-4">
            <div className="font-semibold text-sm">{item.label}</div>
            <div className="flex flex-row items-center">
              <CallbackLink
                className="text-newGray-800"
                href={item.href}
                callback={() => {
                  if (item.onClick) {
                    item.onClick();
                  }
                }}
              >
                {item.value}
              </CallbackLink>
              {(attributeType === "EDITABLE" ||
                attributeType === "EDITABLE_PREPOPULATED") && (
                <div
                  className="px-1 cursor-pointer"
                  onClick={() => setUpdateField(item)}
                >
                  <PenIcon className="h-4 w-4" />
                </div>
              )}
            </div>
          </div>
        );
      })}
      {isExternal && (
        <div className="py-4">
          <div className="font-semibold text-sm">Assigned Contractor</div>
          <div className="flex flex-row items-center">
            {contactorName ? contactorName : "----"}

            {!contactorName && <div
              className="px-1 cursor-pointer"
              onClick={() => editContractorName()}
            >
              <PenIcon className="h-4 w-4" />
            </div>}
          </div>
        </div>
      )}
    </div>
  );
}
