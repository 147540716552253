import React, { useEffect, useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import defaultStyle from "./defaultStyle";

import { getCSRs } from "../../api/Api";

export default function ReactMention({ value, onChangeCB, mentionIdChangeCB }) {
  const [users, setUsers] = useState([]);
  const [idMapping, setIdMapping] = useState({});

  useEffect(() => {
    getCSRs().then((res) => {
      let users = [];
      res.map((item) => {
        setIdMapping((current) => {
          return {
            ...current,
            [item.id]: item.firstName + " " + item.lastName,
          };
        });
        users.push({
          id: `${item.id}`,
          display: item.firstName + " " + item.lastName,
        });
      });

      setUsers(users);
    });
  }, []);

  const onChange = (ev, newValue) => {
    onChangeCB(newValue);
  };

  const onBlur = () => (ev, clickedOnSuggestion) => {
    if (!clickedOnSuggestion) {
      console.log("finished editing");
    }
  };

  const onAdd = (args) => {
    console.log("added a new mention", args);
  };

  const onRemove = (args) => {
    console.log("remove a new mention", args);
  };

  return (
    <MentionsInput
      className="placeholder-orange-700"
      value={value}
      onChange={(e) => {
        const text = e.target.value;
        // Regex Replace {{id}} with getName(id)
        const regex = /\{\{([^}]+)\}\}/g;
        console.log("text", text);
        const match = text.match(regex);
        let mentions = [];
        let plainText = text;
        if (match) {
          match.forEach((mention) => {
            const id = mention.replace(/\{|\}/g, "");
            mentions.push(Number(id));
            const name = users.find((user) => user.id === id).display;
            plainText = plainText.replace(mention, name);
          });
        }
        onChangeCB(text, mentions, plainText);
      }}
      onBlur={onBlur}
      style={defaultStyle}
      placeholder="Type in Notes for Internal Reference"
      markup="{{__id__}}"
      displayTransform={(id) => `@${idMapping[id]}`}
    >
      <Mention
        markup="{{__id__}}"
        displayTransform={(id) => `@${idMapping[id]}`}
        trigger="@"
        data={(search) =>
          users
            .filter((user) =>
              user.display.toLowerCase().includes(search.toLowerCase())
            )
            .slice(0, 10)
        }
        onAdd={onAdd}
      />
    </MentionsInput>
  );
}
