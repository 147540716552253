import React, { useEffect, useState } from "react";
import ReactAudioPlayer from "react-audio-player";

import CallIcon from "@mui/icons-material/Call";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Avatar } from "@mui/material";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import parse from "html-react-parser";
import { Link } from "raviger";
import { useRecoilState } from "recoil";

import { alertError, alertInfo } from "../../actions/AlertActions";
import { getTicketsForBuilding, reassignComm } from "../../api/Api";
import { galleryAtom } from "../../store/GalleryState";
import { renderText } from "../../utils/RenderUtils";
import { deepUpdate } from "../../utils/StateUtils";
import { capitalizeFirst, renderAddress } from "../../utils/StringUtils";
import { properStrings2 } from "../../utils/StringUtils";
import { renderTime } from "../../utils/TimeUtils.bs";
import { ForwardIcon } from "../common/AppIcons";
import { DraggableItem } from "../common/DraggableItem";
import MaterialModal from "../common/MaterialModal";
import PopOverClick from "../common/PopOverClick";
import PopOverText from "../common/PopOverText";
import PreviewLink from "../common/PreviewLink";
import AssociatesMessageForwardModal from "./AssociatesMessageForwardModal";
import MessageForwardModal from "./MessageForwardModal";

const allHtmlTags = [
  "html",
  "head",
  "body",
  "div",
  "span",
  "section",
  "p",
  "br",
  "img",
  "a",
  "ul",
  "li",
  "ol",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
];
const tableTags = ["table", "tr", "td", "th", "tbody", "thead", "tfoot"];

const parseWithoutStyling = (htmlToParse) => {
  return parse(htmlToParse, {
    replace: (domNode) => {
      const { attribs, name } = domNode;
      if (!attribs) return;
      if (!allHtmlTags.includes(name) && !tableTags.includes(name))
        return <></>;
      if (name === "style") return <></>;
    },
  });
};

export default function MessageBubble({
  message,
  associateMessaging,
  outgoing,
  contacts,
  // Buildings to Allow BuildingTickets
  buildings,
  tickets,
  refreshCB,
  draggableMedia,
  ticketId,
  setChatData,
  setChatModalDisplay,
  setLightboxImages,
  lastChannel,
}) {
  const [select, setSelect] = useState({});
  const [emailForward, setEmailForward] = useState(false);
  const [content, setContent] = useState(message.content);
  const [index, setIndex] = useState();

  const [expand, setExpand] = useState(false);
  const [append, setAppend] = useState(false);
  const [forwardModal, setForwardModal] = useState(false);
  const [checkboxStatus, setCheckboxStatus] = useState({});
  const [associateForward, setAssociateForward] = useState(false);

  const [buildingTickets, setBuildingTickets] = useState();

  const [gallery, setGallery] = useRecoilState(galleryAtom);

  const checkBoxForMedia = gallery.mode === "select";

  useEffect(() => {
    if (buildingTickets)
      getTicketsForBuilding(buildingTickets.buildingId)
        .then((res) => {
          setBuildingTickets((current) => ({
            ...current,
            tickets: res.content,
          }));
        })
        .catch((_) => alertError("Couldn't Fetch Tickets"));
  }, [buildingTickets]);

  useEffect(() => {
    if (ticketId === message.ticketId) {
      setCheckboxStatus({ [message.communicationId]: true });
    }
  }, [ticketId]);

  useEffect(() => {
    const found = /On\sMon,\s|Tue,\s|Wed,\s|Thu,\s|Fri,\s|Sat,\s|Sun,\s/.exec(
      message.content
    );
    const found2 = /wrote:/.exec(message.content);
    let f;

    if (found && found2 && message.channel === "EMAIL") {
      setContent(() => {
        message.content.slice(0, found.index - 3);
      });

      var splitted = message?.content.split("\n");

      for (var i = 0; i < splitted.length; i++) {
        f = /On\sMon,\s|Tue,\s|Wed,\s|Thu,\s|Fri,\s|Sat,\s|Sun,\s/.exec(
          splitted[i]
        );
        if (f && found2) {
          setIndex(i);
          break;
        }
      }
    } else {
      setContent(message.content);
    }
  }, []);

  useEffect(() => {
    console.log("");
  }, [message]);

  useEffect(() => {
    if (expand) {
      setContent(message.content);
      // setEmailForward(false);
    } else {
      const found = /On\sMon,\s|Tue,\s|Wed,\s|Thu,\s|Fri,\s|Sat,\s|Sun,\s/.exec(
        message.content
      );
      const found2 = /wrote:/.exec(message.content);

      if (found && found2 && message.channel === "EMAIL") {
        setContent(message.content.slice(0, found.index - 3));
      } else {
        if (message.content?.includes("wrote:")) {
          setEmailForward(true);
          setContent(message.content.split("wrote:")[0] + " wrote:");
        }
      }
    }
  }, [expand]);

  const onChange = (update) => {
    const { name, value } = update;
    setSelect((data) => deepUpdate(name, value, data));
  };

  const reassign = (payload, onSuccess) => {
    reassignComm(payload)
      .then((_) => {
        refreshCB();
        onSuccess();
      })
      .catch((_) => alertError("Couldn't Reassign Message"));
  };

  const getMember = (id) => {
    let mentionMember = message.mentions?.find(
      (mention) => mention.id === parseInt(id)
    );
    if (!mentionMember) {
      return "";
    }

    return `${mentionMember.firstName} ${mentionMember.lastName}`;
  };

  return (
    <>
      <div className={"flex gap-2 mb-2" + (outgoing ? " justify-end" : "")}>
        <div
          className={
            message.channel === "INTERNAL_NOTES"
              ? "rounded py-2 px-3 bg-yellow-400"
              : outgoing
              ? "rounded py-2 px-3 bg-gray-200"
              : message.channel === "VOICEMAIL"
              ? "rounded py-2 px-3 bg-blue-200"
              : "rounded py-2 px-3 bg-blue-200"
          }
        >
          <div className="flex flex-row items-center">
            {checkBoxForMedia && (
              <input
                type="checkbox"
                className=" rounded-full"
                checked={
                  gallery.selected.find(
                    (item) => item.communicationId === message.communicationId
                  ) !== undefined
                }
                onChange={(e) => {
                  setGallery((current) => ({
                    ...current,
                    selected: e.target.checked
                      ? [
                          ...current.selected,
                          {
                            communicationId: message.communicationId,
                            media: message.attachmentUrls,
                          },
                        ]
                      : current.selected.filter(
                          (item) =>
                            item.communicationId !== message.communicationId
                        ),
                  }));
                }}
              />
            )}
            <div
              className={
                "text-sm text-teal gap-2 min-w-max " +
                (checkBoxForMedia ? "px-4" : "")
              }
            >
              <p>
                {outgoing
                  ? message.userFullName ?? ""
                  : contacts
                  ? contacts[message.contactId]?.lastName || ""
                  : ""}{" "}
                {associateMessaging && !outgoing
                  ? capitalizeFirst(message.fromActual?.split(" ")[1])
                  : ""}
              </p>
            </div>
            <div className="text-sm text-teal flex flex-end justify-end w-full">
              {message.marketingCampaignName &&
                associateMessaging === false && (
                  <div className="flex flex-row items-center">
                    <div className="text-newGray-800 font-semibold">
                      {message.marketingCampaignName}
                    </div>
                  </div>
                )}

              {message.channel !== "INTERNAL_NOTES" &&
                message.channel !== "CALL" &&
                message.channel !== "VOICEMAIL" && (
                  <div
                    className="cursor-pointer h-5 w-5 flex items-center justify-center rounded-full bg-gray-300 hover:bg-gray-400"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (associateMessaging) {
                        alertInfo("Please Select Client to Forward to");
                        setAssociateForward(true);
                      } else {
                        alertInfo("Please Select Associate to Forward to");
                        setForwardModal((current) => !current);
                      }
                    }}
                  >
                    <ForwardIcon className="h-3 w-3 text-gray-800" />
                  </div>
                )}

              {/* {message.channel !== "INTERNAL" && tickets && (
                <>
                  <PopOverClick
                    className="text-sm text-gray-700"
                    renderPopOver={(closePopOver) => (
                      <div className="w-56">
                        <div className="px-4 pt-6 pb-2">
                          <Autocomplete
                            id="combo-box-demo"
                            options={tickets}
                            getOptionLabel={(option) =>
                              "#T" + option.ticketId + "-" + option.title
                            }
                            onChange={(event, newValue) => {
                              onChange({
                                value: newValue.ticketId,
                                name: "ticket"
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Assign Ticket"
                                variant="outlined"
                              />
                            )}
                          />
                        </div>

                        <Button
                          color="primary"
                          className="float-right p-4"
                          onClick={(_) =>
                            reassign(message.communicationId, closePopOver)
                          }
                        >
                          Save
                        </Button>
                      </div>
                    )}
                  >
                    {`#T${message.ticketId ?? "Unassigned"}`}
                  </PopOverClick>
                </>
              )} */}
            </div>
          </div>
          <div className="text-sm  text-teal flex gap-2 justify-between">
            <p>
              {message.emailCc ? "CC: " + properStrings2(message.emailCc) : ""}
            </p>
          </div>
          <div className="text-sm mt-1">
            {message.channel === "CALL" || message.channel === "VOICEMAIL" ? (
              <div className="flex flex-col gap-2">
                <div className="flex">
                  <CallIcon /> Duration : {renderTime(message.duration)}
                </div>
                {message.recordingUrl && (
                  <ReactAudioPlayer
                    src={message.recordingUrl}
                    autoPlay={false}
                    controls
                  />
                )}
              </div>
            ) : (
              (() => {
                const renderContent =
                  (message.channel === "INTERNAL_NOTES"
                    ? message.notes
                    : message.htmlContent
                    ? parseWithoutStyling(message.htmlContent)
                    : content) || "";
                if (typeof renderContent === "string") {
                  return renderContent.split("\n").map((item, i) => {
                    if (i !== index - 1) {
                      return item.split("\n").map((item, renderIndex) => {
                        let count = 0;
                        for (var i = 0; i < item.trim().length; i++) {
                          if (item[i] === ">") {
                            count += 1;
                          } else if (item[i] === " ") {
                            continue;
                          } else {
                            break;
                          }
                        }
                        if (count) {
                          item = item.slice(count, item.length);
                          let pixels = `${count * 12}px`;
                          let found =
                            /On\sMon,\s|Tue,\s|Wed,\s|Thu,\s|Fri,\s|Sat,\s|Sun,\s/.exec(
                              item
                            );
                          if (found && message.channel === "EMAIL") {
                            return (
                              <>
                                <br />

                                <p
                                  key={renderIndex}
                                  className={"block break-all"}
                                  style={{ "padding-left": pixels }}
                                >
                                  {renderText(item)}
                                </p>
                              </>
                            );
                          }

                          return (
                            <p
                              key={renderIndex}
                              className={"block break-all "}
                              style={{ "padding-left": pixels }}
                            >
                              {renderText(item)}
                            </p>
                          );
                        } else {
                          if (!append) {
                            setAppend(true);
                          } else {
                            let splitted = item.split("{{");
                            var mapReturn = splitted.map((item) => {
                              let insideSplit = item.split("}}");
                              if (insideSplit.length > 1) {
                                insideSplit[0] = getMember(insideSplit[0]);
                              }

                              return insideSplit.join(" ");
                            });

                            return (
                              <p key={renderIndex} className="block break-all">
                                {renderText(mapReturn.join(" "))}
                              </p>
                            );
                          }

                          return (
                            <p key={renderIndex} className="block break-all">
                              {renderText(item)}
                            </p>
                          );
                        }
                      });
                    } else {
                      return (
                        <div className="flex">
                          <div className="flex-row ">
                            <p className="block break-all">
                              {renderText(item)}
                            </p>
                            <MoreHorizIcon
                              className="bg-gray-400 hover:bg-gray-700 "
                              onClick={() => {
                                setExpand((current) => !current);
                              }}
                            />
                          </div>
                        </div>
                      );
                    }
                  });
                } else {
                  const unescapedContent = message.htmlContent
                    .replaceAll('\\"', '"')
                    .replaceAll("\\n", "\n")
                    .replaceAll("\\r", "\r")
                    .replaceAll("\\t", "\t");

                  return <div>{parseWithoutStyling(unescapedContent)}</div>;
                }
              })()
            )}
            <span className="bg-red-100 text-red-700">
              {message.deliveryErrorMessage}
            </span>
          </div>
          <div className="flex flex-wrap">
            {message.attachmentUrls?.map((attachment, i) => (
              <div
                key={i}
                className="text-sm mt-1"
                onClick={(_) =>
                  setLightboxImages({
                    images: message.attachmentUrls,
                    photoIndex: i,
                  })
                }
              >
                {draggableMedia ? (
                  <DraggableItem item={attachment}>
                    <PreviewLink url={attachment} />
                  </DraggableItem>
                ) : (
                  <PreviewLink url={attachment} />
                )}
              </div>
            ))}
          </div>
          <div className="flex flex-row justify-between items-center">
            <div className="text-right text-xs flex items-center gap-2 text-grey-dark">
              {message.notes && message.channel !== "INTERNAL_NOTES" && (
                <PopOverClick
                  className="text-sm text-gray-700"
                  renderPopOver={(_) => <textarea value={message.notes} />}
                >
                  Notes
                </PopOverClick>
              )}
              {message.channel === "PUBLIC_CHAT" ? (
                <>
                  <span className="text-blue-800">
                    {message.channel.replace("_", " ") + " "}
                  </span>
                  <span
                    className="text-blue-800"
                    onClick={(_) => {
                      var date = new Date(message.createdAt).toLocaleTimeString(
                        [],
                        {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      );
                      setChatData({
                        title: message.userFullName + " " + date,
                        chats: message.chatMessages,
                      });
                      setChatModalDisplay(true);
                    }}
                  >
                    <Link href="">View Chat</Link>
                  </span>
                </>
              ) : (
                <>
                  {message.channel === "INTERNAL"
                    ? "APP "
                    : message.channel.replace("_", " ") + " "}
                </>
              )}
              {new Date(message.createdAt).toLocaleTimeString([], {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
            <div className="px-4 cursor-pointer">
              {message.channel !== "INTERNAL" && tickets && (
                <>
                  <PopOverClick
                    className="text-sm text-gray-700"
                    renderPopOver={(closePopOver) => (
                      <div className="w-56">
                        <div className="px-4 pt-6">
                          <Autocomplete
                            id="combo-box-demo"
                            options={buildings}
                            getOptionLabel={(option) =>
                              renderAddress(option.address)
                            }
                            onChange={(event, newValue) => {
                              setBuildingTickets({
                                buildingId: newValue.buildingId,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Filter by Building (Optional)"
                                variant="outlined"
                              />
                            )}
                          />
                          <Autocomplete
                            id="combo-box-demo"
                            options={buildingTickets?.tickets || tickets}
                            getOptionLabel={(option) =>
                              "#T" + option.ticketId + "-" + option.title
                            }
                            onChange={(event, newValue) => {
                              onChange({
                                value: newValue.ticketId,
                                name: "ticket",
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Assign Ticket"
                                variant="outlined"
                              />
                            )}
                          />
                        </div>

                        <div className="flex flex-row-reverse justify-between px-4 pb-4">
                          <Button
                            color="primary"
                            className="float-right p-4"
                            onClick={(_) =>
                              reassign(
                                {
                                  communicationId: message.communicationId,
                                  ticketId: select.ticket,
                                },
                                closePopOver
                              )
                            }
                          >
                            Save
                          </Button>

                          {message.ticketId && (
                            <Button
                              color="primary"
                              className="float-right p-4"
                              onClick={(_) =>
                                reassign(
                                  {
                                    communicationId: message.communicationId,
                                    ticketId: null,
                                  },
                                  closePopOver
                                )
                              }
                            >
                              Unlink
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                  >
                    {`#T${message.ticketId ?? "Unassigned"}`}
                  </PopOverClick>
                </>
              )}
            </div>
          </div>
        </div>
        <PopOverText
          text={
            message.userFullName
              ? `${message.userFullName}`
              : `${message.fromUsername}`
          }
        >
          {outgoing && (
            <Avatar src={message.userProfilePic} className="h-8 w-8">
              {((message.userFullName || message.fromUsername) ?? " ").slice(
                0,
                1
              )}
            </Avatar>
          )}
        </PopOverText>
      </div>
      {forwardModal && (
        <MaterialModal
          open={forwardModal}
          setOpen={(_) => setForwardModal(false)}
          label="new-user-modal"
          describedby="create-new-user"
        >
          <MessageForwardModal
            messageData={message}
            setForwardModal={setForwardModal}
            lastChannel={lastChannel}
          />
        </MaterialModal>
      )}

      {associateForward && (
        <MaterialModal
          open={associateForward}
          setOpen={(_) => setAssociateForward(false)}
          label="new-user-modal"
          describedby="create-new-user"
        >
          <AssociatesMessageForwardModal
            associateId={window.location.pathname.split("/")[2]}
            messageData={message}
            setForwardModal={setAssociateForward}
            lastChannel={lastChannel}
          />
        </MaterialModal>
      )}
    </>
  );
}
