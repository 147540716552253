import BasicMessageBubble from "./BasicMessageBubble";
import React from "react";

export default function BasicConversationApp({ messages }) {
  return (
    <div id="messages" className="px-1">
      <ul>
        {messages.map((m) => {
          return <BasicMessageBubble key={m.index} m={m} />;
        })}
      </ul>
    </div>
  );
}
