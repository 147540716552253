import { useRecoilState } from "recoil";

import ManagerAccess from "../../../pages/client/components/ManagerAccess";
import { globalComponentStateAtom } from "../../../store/GlobalComponent";
import CreateContact from "../../clients/CreateContact";
import QuickMessage from "../../crm/QuickMessage";
import CreateSimpleTicket from "../../pipelines/CreateSimpleTicket";
import InvoiceForm from "../../pipelines/InvoiceForm";
import NewCreateTicket from "../../pipelines/NewCreateTicket";
import MaterialModal from "../MaterialModal";
import ConfirmationDialog from "./ConfirmationDialog";

export default function GlobalComponents() {
  const [globalComponentState, setGlobalComponentState] = useRecoilState(
    globalComponentStateAtom
  );

  return (
    <>
      <MaterialModal
        open={globalComponentState.ticketId !== null}
        setOpen={() =>
          setGlobalComponentState((state) => ({ ...state, ticketId: null }))
        }
        containerClassName="md:w-11/12 lg:w-8/12 overflow-y-auto"
      >
        <NewCreateTicket
          baseData={{ ticketId: globalComponentState.ticketId }}
          onSuccessCB={() => {
            globalComponentState.ticketCallback &&
              globalComponentState.ticketCallback();
            setGlobalComponentState((state) => ({
              ...state,
              ticketId: null,
              onSuccessCB: undefined,
            }));
          }}
        />
      </MaterialModal>

      <MaterialModal
        open={globalComponentState.contactData}
        setOpen={() =>
          setGlobalComponentState((state) => ({
            ...state,
            contactData: undefined,
          }))
        }
      >
        {/* TODO: Type Safety for CreateContact */}
        <CreateContact
          baseData={globalComponentState.contactData}
          successCB={(_) => {
            setGlobalComponentState((state) => ({
              ...state,
              contactData: undefined,
              contactSuccessCB: undefined,
            }));
            globalComponentState.contactSuccessCB &&
              globalComponentState.contactSuccessCB();
          }}
          onCancelCB={() =>
            setGlobalComponentState((state) => ({
              ...state,
              contactData: undefined,
              contactSuccessCB: undefined,
            }))
          }
        />
      </MaterialModal>
      {globalComponentState.confirmationDialog && (
        <ConfirmationDialog
          open={globalComponentState.confirmationDialog.open}
          onClose={() => {
            if (globalComponentState?.confirmationDialog?.onClose !== undefined)
              globalComponentState?.confirmationDialog?.onClose();
            setGlobalComponentState((state) => ({
              ...state,
              confirmationDialog: {
                open: false,
                title: "",
                description: "",
                onConfirm: () => {},
              },
            }));
          }}
          title={globalComponentState.confirmationDialog.title}
          description={globalComponentState.confirmationDialog.description}
          onConfirm={globalComponentState.confirmationDialog.onConfirm}
        />
      )}
      <MaterialModal
        open={globalComponentState.managerAccessData !== undefined}
        setOpen={() => {
          setGlobalComponentState((state) => ({
            ...state,
            managerAccessData: undefined,
          }));
        }}
      >
        {globalComponentState.managerAccessData && (
          <ManagerAccess
            clientId={globalComponentState.managerAccessData.clientId}
            manager={globalComponentState.managerAccessData.manager}
            callback={globalComponentState.managerAccessData.callback}
          />
        )}
      </MaterialModal>
      <MaterialModal
        open={globalComponentState.selectFromChoices !== undefined}
        setOpen={() =>
          setGlobalComponentState((state) => ({
            ...state,
            selectFromChoices: undefined,
          }))
        }
      >
        {globalComponentState.selectFromChoices && (
          <div className="p-4 max-w-sm">
            <div className="flex flex-col">
              <div className="text-xl font-semibold">
                {globalComponentState.selectFromChoices.title}
              </div>
              <div className="text-gray-500">
                {globalComponentState.selectFromChoices.description
                  .split("\n")
                  .map((line) => (
                    <p>{line}</p>
                  ))}
              </div>
            </div>
            <div className="flex flex-wrap gap-2 mt-4 items-center justify-center">
              {globalComponentState.selectFromChoices.choices.map(
                (choice: {
                  label: string;
                  value?: any;
                  callback?: () => void;
                }) => (
                  <button
                    className="border rounded text-sm px-2 py-1 h-10 w-24 mr-10 bg-newBlue-500 text-newBlue-400"
                    onClick={() => {
                      if (choice.callback) {
                        choice.callback();
                      } else {
                        globalComponentState.selectFromChoices.callback(
                          choice.value
                        );
                      }
                    }}
                  >
                    {choice.label}
                  </button>
                )
              )}
            </div>
          </div>
        )}
      </MaterialModal>
      <MaterialModal
        open={globalComponentState.invoiceId !== null}
        setOpen={(_) => {
          setGlobalComponentState((state) => ({ ...state, invoiceId: null }));
        }}
        label="estimate-modal"
        describedBy="create-view-estimate"
        width={90}
        className="px-1 py-1"
      >
        <InvoiceForm invoiceId={globalComponentState.invoiceId ?? 0} />
      </MaterialModal>
      <MaterialModal
        open={globalComponentState.createSimpleTicket}
        setOpen={() =>
          setGlobalComponentState((state) => ({
            ...state,
            createSimpleTicket: false,
          }))
        }
      >
        <CreateSimpleTicket
          successCB={() => {
            setGlobalComponentState((state) => ({
              ...state,
              createSimpleTicket: false,
            }));
          }}
        />
      </MaterialModal>
      <MaterialModal
        open={globalComponentState.quickMessage !== undefined}
        setOpen={() =>
          setGlobalComponentState((state) => ({
            ...state,
            quickMessage: undefined,
          }))
        }
        extended
        containerClassName="md:w-4/12 overflow-y-auto"
      >
        <QuickMessage
          clientId={globalComponentState.quickMessage?.clientId}
          contacts={globalComponentState.quickMessage?.contacts}
          contactId={globalComponentState.quickMessage?.contactId}
        />
      </MaterialModal>
    </>
  );
}
