import React from "react";
import { Transition } from "@tailwindui/react";

export default function Popup({
  view,
  set_view,
  className = "",
  className_inner = "",
  children,
}) {
  return (
    <Transition
      show={view}
      onClick={() => {
        set_view();
      }}
      style={{ width: "100%" }}
      className={
        "fixed left-0 top-0 overflow-auto bg-gray-300 bg-opacity-50 z-50 min-h-screen w-screen flex items-center justify-center" +
        className
      }
      enter="transform transition-all duration-200"
      enterFrom="opacity-0 scale-90"
      enterTo="opacity-100 scale-100"
      leave="transform transition-all duration-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-90"
    >
      <div
        className={
          "flex justify-center items-center justify-center m-auto flex flex-col " +
          className_inner
        }
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </Transition>
  );
}
