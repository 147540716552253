import React, { useEffect, useState } from "react";
import { useRef } from "react";

import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import { Button, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import makeStyles from "@mui/styles/makeStyles";
import { navigate, useQueryParams } from "raviger";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import { listClients, mergeClients } from "../../api/Api";
import activeUserStore from "../../store/ActiveUserStore";
import MaterialModal from "../common/MaterialModal";
import MaterialTable from "../common/MaterialTable";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));
const SelectClients = ({ data, selected, onComplete }) => {
  const [mergeTo, setMergeTo] = useState(null);
  return (
    <div className="px-4 pt-6 pb-2">
      <Autocomplete
        id="combo-box-demo"
        options={data}
        getOptionLabel={(option) => option.clientName}
        style={{ width: 300 }}
        onChange={(event, newValue) => {
          setMergeTo(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Merge To" variant="outlined" />
        )}
      />
      <div className="flex w-full mt-4 justify-end">
        <Button
          color="primary"
          disabled={!mergeTo}
          className="p-4 focus:outline-none"
          onClick={(_) => {
            mergeClients({
              primaryClientId: mergeTo.clientId,
              clientIds: selected,
            })
              .then((_) => {
                alertSuccess("Successfully Merged Clients");
                onComplete();
              })
              .catch((err) => {
                err.message
                  ? alertError(err.message)
                  : alertError("Error Merging Clients");
                onComplete();
              });
          }}
        >
          Merge
        </Button>
      </div>
    </div>
  );
};
export default function ListClients({ newClient, onSelect }) {
  const isAdmin = activeUserStore.isAdmin();
  const classes = useStyles();
  const [{ search }] = useQueryParams();
  const [filter, setFilter] = useState("ALL");
  const [searchText, setSearchText] = useState(search ?? "");
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const clientSearch = useRef(null);

  // State for Merge Clients
  const [multiSelect, setMultiSelect] = useState(false);
  const [selected, setSelected] = useState([]); // clientIds
  const [mergeModalOpen, setMergeModalOpen] = useState(false);

  useEffect(() => {
    var ids = [];
    selected.forEach((client) => {
      let id = client.split("|")[0];
      ids.push(id);
    });
    var distinct = [...new Set(ids)];
    if (distinct.length !== selected.length) {
      alertError("Error! Cannot merge duplicate clients");
    }
  }, [selected]);

  const handleChange = (event, newValue) => {
    setFilter(newValue);
  };

  const onMerge = () => {
    setMergeModalOpen(true);
  };

  const onCompleteMerge = () => {
    fetchData(filter);
    setSelected([]);
    setMultiSelect(false);
    setMergeModalOpen(false);
  };

  const fetchData = (filter) => {
    listClients(filter)
      .then((data) => {
        setClients(data);
        setLoading(false);
      })
      .catch((_) => {
        alertError("Error Getting Clients");
        setLoading(false);
      });
  };

  useEffect(() => {
    clientSearch.current.focus();
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchData(filter);
  }, [filter]);

  return (
    <div className="max-w-6xl mx-auto bg-white rounded-md p-4">
      <Typography className="p-2 pb-4 text-gray-800" variant="h4">
        Clients
      </Typography>
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={filter}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs + " bg-white"}
        >
          {[
            { label: "All Clients", value: "ALL" },
            { label: "Commercial Clients", value: "COMMERCIAL" },
            { label: "Residential Clients", value: "RESIDENTIAL" },
          ].map((tab) => (
            <Tab
              label={tab.label}
              key={tab.value}
              className="hover:bg-gray-200"
              value={tab.value}
            />
          ))}
        </Tabs>
        <div
          className={
            classes.root + " max-w-6xl mx-auto w-full gap-2 flex-col p-2"
          }
        >
          <div className="flex items-center gap-2 cursor-pointer">
            <TextField
              id="search"
              className="flex-1"
              label="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              variant="outlined"
              inputRef={clientSearch}
            />
            <div
              onClick={(_) =>
                newClient ? newClient() : navigate("/clients/new")
              }
            >
              <AddCircleOutlineOutlined /> New Client
            </div>

            {isAdmin && (
              <button
                disabled={multiSelect && selected.length <= 1}
                className="focus:outline-none"
                onClick={(_) => setMultiSelect(true)}
              >
                <MergeTypeIcon />
                Merge
              </button>
            )}
          </div>
          {isAdmin && multiSelect && (
            <div className="flex w-full gap-4 justify-end">
              <button
                className=" focus:outline-none"
                onClick={(_) => {
                  setMultiSelect(false);
                  setSelected([]);
                }}
              >
                Cancel
              </button>
              <button
                disabled={multiSelect && selected.length <= 1}
                className={`focus:outline-none ${
                  multiSelect && selected.length > 1 ? " text-indigo-700" : ""
                }`}
                onClick={onMerge}
              >
                <MergeTypeIcon />
                Merge
              </button>
            </div>
          )}
          <MaterialTable
            loading={loading}
            data={clients?.map((client) => {
              return {
                id: `${client.clientId}|${client.buildingId}`,
                data: [
                  client.clientName,
                  {
                    render:
                      client.type === "COMMERCIAL"
                        ? "Commercial"
                        : "Residential",
                    sortBy: "",
                  },
                  `${client.streetAddress1 ?? " - "}, ${
                    client.streetAddress2 ?? " - "
                  }`,
                  client.city,
                  client.phones,
                  client.emails,
                ],
              };
            })}
            head={[
              { id: "clientName", label: "Client Name" },
              { id: "type", label: "Type" },
              { id: "address", label: "Address" },
              { id: "city", label: "City" },
            ]}
            defaultOrderBy={"clientName"}
            onClickCB={(idString) => {
              const [clientId, buildingId] = idString.split("|");

              const client = clients.find((item) => {
                return item.clientId === Number(clientId);
              });

              onSelect
                ? onSelect(clientId, client.type)
                : navigate(`/commercial/${clientId}/summary`);
              //navigate(
              //     `/commercial/${clientId}/summary/${buildingId ?? ""}`
              //   );
            }}
            searchText={searchText}
            selected={selected}
            setSelected={setSelected}
            multiSelect={multiSelect}
          />

          <MaterialModal open={mergeModalOpen} setOpen={setMergeModalOpen}>
            <span> Choose Address for the Merged Building</span>
            {mergeModalOpen && (
              <SelectClients
                data={clients.filter((client) =>
                  selected
                    .map(
                      (clientIdBuildingId) => clientIdBuildingId.split("|")[0]
                    )
                    .includes(`${client.clientId}`)
                )}
                onComplete={onCompleteMerge}
                selected={selected.map(
                  (clientIdBuildingId) => clientIdBuildingId.split("|")[0]
                )}
              />
            )}
          </MaterialModal>
        </div>
      </div>
    </div>
  );
}
