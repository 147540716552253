import { useEffect, useState } from "react";

import { alertSuccess } from "../../../../../actions/AlertActions";
import {
  getAllProspects,
  getLeaseById,
  getUnitById,
  updateTenant,
} from "../../../../../api/Api";
import MaterialTable from "../../../../../components/common/MaterialTable";
import { properString } from "../../../../../utils/StringUtils";

type ScreeningListingProps = {
  unitId: number;
  leaseId?: number;
  setCurrentApplicationTab: React.Dispatch<React.SetStateAction<string>>;
  setCurrentProspect: React.Dispatch<React.SetStateAction<number>>;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
};

type ProspectData = {
  id: number;
  firstName: string;
  lastName: string;
  screeningStatus: string;
  type: string;
  incomeDetails: {
    employmentDetails: {
      monthlyIncome: number;
    }[];
  };
  miscellaneous: {
    pets: {
      name: string;
    }[];
  };
};

export function ScreeningListing({
  setCurrentApplicationTab,
  setCurrentProspect,
  unitId,
  leaseId,
  setCurrentTab,
}: ScreeningListingProps) {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const [tableData, setTableData] = useState([]);
  const [primaryTenantId, setPrimaryTenantId] = useState<string | null>();
  const [listingId, setListingId] = useState(0);

  useEffect(() => {
    if (leaseId !== undefined && leaseId !== null && leaseId !== 0) {
      getLeaseById(leaseId).then((lease) => {
        setPrimaryTenantId(lease.primaryTenantId);
        if (lease.listingId !== null) {
          setListingId(lease.listingId);
          getAllProspects(page, size, lease.listingId).then((data) => {
            setTableData(data.content);
            setTotalElements(data.totalElements);
          });
        }
      });
    } else
      getUnitById(unitId).then((data) => {
        if (data.currentLeaseId !== null) {
          getLeaseById(data.currentLeaseId).then((lease) => {
            setPrimaryTenantId(lease.primaryTenantId);
            if (lease.listingId !== null) {
              getAllProspects(page, size, lease.listingId).then((data) => {
                setTableData(data.content);
                setTotalElements(data.totalElements);
              });
            }
          });
        }
      });
  }, [unitId, page, size, leaseId]);

  const getTableHead = () => {
    return [
      { id: "rank", label: "Serial No." },
      { id: "name", label: "Name" },
      { id: "type", label: "Type" },
      { id: "salary", label: "Salary" },
      { id: "pets", label: "Pets" },
      { id: "screened", label: "Screened" },
      { id: "setAsTenant", label: "" },
    ];
  };

  const getTableData = () => {
    let data = [];
    data = tableData?.map((item: ProspectData, index) => {
      return {
        id: item.id,
        rowStyle: item.type === "TENANT" ? "bg-green-200" : "",
        data: [
          index + 1,
          {
            render: (
              <div className="">{`${item.firstName} ${item.lastName}`}</div>
            ),
          },
          {
            render: (
              <div className="flex flex-row p-1 px-2 items-center bg-newBlue-500 rounded-full w-max text-sm font-normal text-newBlue-400">
                {item.type ? properString(item.type ?? "") : "Applicant"}
              </div>
            ),
          },
          `$${item?.incomeDetails?.employmentDetails[0]?.monthlyIncome ?? 0}`,
          `${item.miscellaneous?.pets?.length ? "Yes" : "No"}`,
          {
            render: (
              <div
                className={`flex flex-row p-1 px-2 items-center rounded-full w-max text-sm font-normal 
              ${
                item.screeningStatus === "SCREENED"
                  ? " bg-green-300 text-green-700"
                  : "bg-red-200 text-red-600"
              }`}
              >
                {properString(item.screeningStatus ?? "")}
              </div>
            ),
          },
          {
            render: (
              <>
                {primaryTenantId === null && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      updateTenant(leaseId, item.id).then(() => {
                        getAllProspects(page, size, listingId).then((data) => {
                          setTableData(data.content);
                          setTotalElements(data.totalElements);
                        });
                        getLeaseById(leaseId).then((lease) => {
                          setPrimaryTenantId(lease.primaryTenantId);
                          setCurrentTab("rentLeaseDetails");
                        });
                        alertSuccess("Successfully set tenant");
                      });
                    }}
                    className={`flex flex-row p-1 px-2 items-center border border-blue-800 rounded w-max text-sm font-normal  bg-blue-200 text-blue-800
             `}
                  >
                    Proceed to Creating Lease
                  </button>
                )}
              </>
            ),
          },
        ],
      };
    });
    return data;
  };

  return (
    <div className="w-full">
      {tableData.length > 0 && (
        <div className="mt-4">
          <MaterialTable
            data={getTableData()}
            head={getTableHead()}
            headRenderColor="text-newGray-300"
            defaultOrder="desc"
            pageRows={size}
            onClickCB={(selection: number) => {
              setCurrentProspect(selection);
              setCurrentApplicationTab("screeningDetails");
            }}
            totalData={totalElements}
            currentPage={page}
            paginationCB={(value: number) => {
              setPage(value);
            }}
            rowChangeCB={(value: number) => {
              setSize(value);
            }}
            loading={undefined}
            defaultOrderBy={undefined}
            multiSelect={undefined}
            searchText={undefined}
            setSelected={undefined}
          />
        </div>
      )}
    </div>
  );
}
