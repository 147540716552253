import MessageBubble from "./MessageBubble";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

function ConversationsMessages(props) {
  const [state, setState] = useState({
    messages: props.messages || [],
    identity: props.identity,
  });

  useEffect(() => {
    let logic =
      state.loadingState === "initializing" ||
      state.conversationProxy !== props.conversationProxy;
    if (logic) {
      setState((state) => ({
        ...state,
        loadingState: "loading messages",
        conversationProxy: props.conversationProxy,
      }));
    }
  }, [props]);

  return (
    <div id="messages" className="px-1">
      <ul>
        {props.messages.map((m, index) => {
          console.log("Message: ", m);
          if (m.author === props.identity)
            return (
              <MessageBubble
                key={m.state.sid}
                direction="outgoing"
                message={m}
                open_image_popup={props.open_image_popup}
                index={index}
              />
            );
          else
            return (
              <MessageBubble
                key={m.state.sid}
                direction="incoming"
                message={m}
                open_image_popup={props.open_image_popup}
                index={index}
              />
            );
        })}
      </ul>
    </div>
  );
}

ConversationsMessages.propTypes = {
  identity: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ConversationsMessages;
