import React, { useEffect, useState } from "react";

import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import { Button, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import { getClient, mergeBuildings } from "../../api/Api";
import activeUserStore from "../../store/ActiveUserStore";
import { renderAddress } from "../../utils/StringUtils";
import MaterialModal from "../common/MaterialModal";
import MaterialTable from "../common/MaterialTable";
import CreateBuilding from "./CreateBuilding";

const SelectAddress = ({ data, selected, onComplete }) => {
  const [text, setText] = useState(null);
  const mergeBuildingData = () => {
    const payLoad = {
      addressId: text?.address?.addressId,
      buildingIds: selected,
    };
    mergeBuildings(payLoad)
      .then((_) => {
        alertSuccess("Successfully Merged Buildings");
        onComplete();
      })
      .catch((_) => {
        alertError("Error Merging Building");
        onComplete();
      });
  };
  return (
    <div className="px-4 pt-6 pb-2">
      <Autocomplete
        id="combo-box-demo"
        options={data}
        getOptionLabel={(option) =>
          `${
            option.propertyShortName ? option.propertyShortName + " | " : ""
          }${renderAddress(option.address)}`
        }
        style={{ width: 300 }}
        onChange={(event, newValue) => {
          setText(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Merge To" variant="outlined" />
        )}
      />
      <div className="flex w-full mt-4 justify-end">
        <Button
          color="primary"
          disabled={!text}
          className="p-4 focus:outline-none"
          onClick={mergeBuildingData}
        >
          Merge
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function ListProperties({
  data,
  clientId,
  selectProperty,
  multiple,
  multiSelectCB,
  crmHome = false,
}) {
  const isAdmin = activeUserStore.isAdmin();
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState([]);
  const [multiSelect, setMultiSelect] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [buildingData, setBuildingData] = useState(data);
  const [mergeModalOpen, setMergeModalOpen] = useState(false);

  const fetchData = () => {
    getClient(clientId)
      .then((clientData) => setBuildingData(clientData.buildings))
      .catch((_) => alertError("Error Loading Client Buildings"));
  };
  useEffect(() => {
    !data && fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [openModal]);

  useEffect(() => {
    if (multiSelectCB) {
      multiSelectCB(selected);
    }
  }, [selected]);

  const onMerge = () => {
    setMergeModalOpen(true);
  };
  const onCompleteMerge = () => {
    fetchData();
    setSelected([]);
    setMultiSelect(false);
    setMergeModalOpen(false);
  };
  return (
    <div
      className={classes.root + " max-w-6xl mx-auto w-full gap-2 flex-col p-2"}
    >
      <div className="flex items-center gap-2 cursor-pointer">
        <TextField
          id="search"
          className="flex-1"
          label="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          variant="outlined"
        />
        <div onClick={(_) => setOpenModal(true)}>
          <AddCircleOutlineOutlined /> New Property
        </div>

        {isAdmin && !crmHome && (
          <button
            disabled={multiSelect && selected.length <= 1}
            className="focus:outline-none"
            onClick={(_) => setMultiSelect(true)}
          >
            <MergeTypeIcon />
            Merge
          </button>
        )}
      </div>
      {isAdmin && multiSelect && (
        <div className="flex w-full gap-4 justify-end">
          <button
            className=" focus:outline-none"
            onClick={(_) => {
              setMultiSelect(false);
              setSelected([]);
            }}
          >
            Cancel
          </button>
          <button
            disabled={multiSelect && selected.length <= 1}
            className={`focus:outline-none ${
              multiSelect && selected.length > 1 ? " text-indigo-700" : ""
            }`}
            onClick={onMerge}
          >
            <MergeTypeIcon />
            Merge
          </button>
        </div>
      )}
      <MaterialTable
        data={
          buildingData?.map((row) => {
            return {
              id: row.buildingId,
              data: [
                row.propertyShortName,
                renderAddress(row.address),
                row.address.city,
              ],
            };
          }) || []
        }
        selected={selected}
        setSelected={setSelected}
        multiSelect={multiSelect || multiple}
        head={[
          { id: "propertyShortName", label: "Property Short Name" },
          { id: "address", label: "Address" },
          { id: "city", label: "City" },
        ]}
        loading={buildingData ? false : true}
        defaultOrderBy={"clientName"}
        onClickCB={(buildingId) => {
          selectProperty(buildingId);
        }}
        searchText={searchText}
      />
      <MaterialModal
        open={openModal}
        setOpen={setOpenModal}
        label="new-user-modal"
        describedby="create-new-user"
      >
        <CreateBuilding
          baseData={{ client: { clientId } }}
          successCB={(data) => {
            if (!crmHome) selectProperty(data);
            setOpenModal(false);
          }}
        />
      </MaterialModal>
      <MaterialModal open={mergeModalOpen} setOpen={setMergeModalOpen}>
        <span> Choose Address for the Merged Building</span>
        {mergeModalOpen && (
          <SelectAddress
            data={buildingData.filter((building) =>
              selected.includes(building.buildingId)
            )}
            onComplete={onCompleteMerge}
            selected={selected}
          />
        )}
      </MaterialModal>
    </div>
  );
}
