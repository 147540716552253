import moment from "moment";

import { alertError } from "../../actions/AlertActions";

export const COL_INDEX = {
  PRODUCT: 0,
  DESCRIPTION: 1,
  QTY: 2,
  RATE: 3,
  AMOUNT: 4,
  "ACCEPTED BY": 5,
  "ACCEPTED ON": 6,
  TAX: 7,
  ID: 8,
  ACCEPTED_STATUS: 9,
  REJECTED_STATUS: 10,
  CHANGE_REQUEST_STATUS: 11,
  UNSENT_STATUS: 12,
  SENT_STATUS: 13,
  MATERIALS: 14,
  INVOICED: 15,
};

export const MATERIAL_COL_INDEX = {
  0: "name",
  1: "description",
  2: "quantity",
  3: "rate",
  4: "amount",
  5: "materialId",
};

export const getNumericValue = (value) => {
  //check for decimals

  if (value) {
    return value.replace("$", "");
  }
  return 0;
};

// export const checkData = (lines) => {
//   let status = false;

//   lines.map((line) => {
//     console.log("checkData", line[COL_INDEX.QTY], line[COL_INDEX.RATE]);

//     if (line[COL_INDEX.QTY] === null || line[COL_INDEX.RATE] === null) {
//       status = true;
//     } else {
//       if (line[COL_INDEX.QTY] * line[COL_INDEX.RATE] === 0) {
//         status = false;
//       }
//     }

//     line[COL_INDEX.MATERIALS].map((material) => {
//       if (material.quantity === null || material.rate === null) {
//         status = true;
//       } else {
//         if (material.quantity * material.rate === 0) {
//           status = false;
//         }
//       }
//     });
//   });

//   return status;
// };

export const checkData = (lines) => {
  let status = false;
  lines.forEach((line) => {
    if (status !== true) {
      if (line.quantity === null || line.rate === null) {
        status = true;
      } else {
        if (line.title === "Inspection" || line.title === "Site Visit") {
          if (line.quantity === 0 || line.rate === 0) {
            status = false;
          }
        } else {
          if (line.quantity === 0 || line.rate === 0) {
            status = true;
          }
        }
      }

      line.materials?.map((material) => {
        if (material.quantity === null || material.rate === null) {
          status = true;
        }
      });
    }
  });

  return status;
};

export const checkEstimateAcceptData = (data) => {
  if (data.acceptedContactId && data.channel && data.date) {
    return true;
  } else {
    return false;
  }
};

export const objectifyLines = (lines) => {
  return lines.map((line) => {
    return {
      id: line[COL_INDEX.ID],
      title: line[COL_INDEX.PRODUCT],
      quantity: line[COL_INDEX.QTY],
      amount: line[COL_INDEX.AMOUNT],
      rate: line[COL_INDEX.RATE],
      tax: line[COL_INDEX.TAX] || 0,
      description: line[COL_INDEX.DESCRIPTION],
      acceptedName: line[COL_INDEX["ACCEPTED BY"]],
      acceptedDate: line[COL_INDEX["ACCEPTED ON"]]
        ? moment(line[COL_INDEX["ACCEPTED ON"]], "DD/mm/yyyy").format(
            "yyyy-mm-DD"
          )
        : null,
      materials: line[COL_INDEX.MATERIALS]?.map((material) => ({
        ...material,
        amount: material.rate * material.quantity,
      })),
    };
  });
};

export const convertToPayload = (estimateData, ticketId, total) => {
  let lines = estimateData.lines;
  let subTotal = total;

  const payload = {
    ...estimateData,
    lines: lines,
    total: subTotal,
    subTotal: subTotal,
    ticketId: ticketId,
  };
  return payload;
};

export const convertToEstimateData = (payload, ticketData) => {
  let estimateData = {
    ...payload,
    lines: payload.lines.map((line) => {
      return [
        line.title,
        line.description,
        line.quantity,
        line.rate,
        line.amount,
        line.acceptedName,
        line.acceptedDate
          ? moment(line.acceptedDate, "yyyy-mm-DD").format("DD/mm/yyyy")
          : "",
        line.tax || 0,

        line.id,
        //Accepted
        line.isDraft === false,
        //rejected
        line.isEstimateSent === true &&
          line.isDraft === true &&
          line.isChangeOrder === false,
        //change request
        // true,
        line.isEstimateSent === true &&
          line.isDraft === true &&
          line.isChangeOrder === true,
        //unsent
        line.isEstimateSent === false && line.isDraft === true,
        //sent
        payload.estimateStatus === "SENT",
        //materials
        line.materials,
        line.invoiceId !== null,
      ];
    }),
    customer: ticketData.clientName,
  };
  return estimateData;
};

export const checkPayloadValidity = (payload) => {
  if (!payload.estimateDate) {
    alertError("Estimate Date Not Entered");
    return false;
  }
  if (!payload.expirationDate) {
    alertError("Expiration Date Not Entered");

    return false;
  }

  return true;
};
