import { useEffect, useState } from "react";

import { alertError } from "../actions/AlertActions";
import {
  contactsUnderIndividualClient,
  getBuildingManagersNewApi,
  managerContacts,
  residentContacts,
  unitsWithResidents,
} from "../api/Api";

export const useCopyTo = (clientId, type) => {
  const [copyToOptions, setCopyToOptions] = useState([]);

  useEffect(() => {
    setCopyToOptions([]);
    if (type === "INDIVIDUAL") {
      contactsUnderIndividualClient(clientId)
        .then((data) => {
          setCopyToOptions(
            data
              .reduce((unique, contact) => {
                if (!unique.some((item) => item.value === contact.contactId)) {
                  unique.push({
                    label: `${contact.firstName} ${contact.lastName}`,
                    value: contact.contactId,
                  });
                }
                return unique;
              }, [])
              .sort((a, b) => a.label.localeCompare(b.label))
          );
        })
        .catch((err) => {
          alertError("Error Fetching Contacts");
        });
    } else if (clientId) {
      managerContacts(clientId)
        .then((data) => {
          setCopyToOptions(
            data
              .reduce((unique, contact) => {
                if (!unique.some((item) => item.value === contact.contactId)) {
                  unique.push({
                    label: `${contact.firstName} ${contact.lastName}`,
                    value: contact.contactId,
                  });
                }
                return unique;
              }, [])
              .sort((a, b) => a.label.localeCompare(b.label))
          );
        })
        .catch((err) => {
          alertError("Error Fetching Managers");
        });

      if (type === "COMMERCIAL") {
        residentContacts(clientId)
          .then((data) => {
            setCopyToOptions((copyToOptions) => {
              return [
                ...copyToOptions,
                ...data
                  .reduce((unique, contact) => {
                    if (
                      !unique.some((item) => item.value === contact.contactId)
                    ) {
                      unique.push({
                        label: `${contact.firstName} ${contact.lastName}`,
                        value: contact.contactId,
                      });
                    }
                    return unique;
                  }, [])
                  .sort((a, b) => a.label.localeCompare(b.label)),
              ];
            });
          })
          .catch((err) => {
            alertError("Error Fetching Residents");
          });
      }
    }
  }, [clientId]);

  return [
    {
      name: "copyTo",
      inputType: "SELECT",
      variant: "MULTISELECT",
      options: copyToOptions,
      defaultValue: "ALL",
    },
  ];
};
