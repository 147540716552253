import React, { useEffect, useState } from "react";

import { usePath } from "raviger";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import {
  createUnderHOA,
  createUnderIndividualClient,
  createUnderPMC,
} from "../../api/Api";
import { deepUpdate } from "../../utils/StateUtils";
import { renderAddressUnitNumber } from "../../utils/StringUtils";
import { AddIconBlue, SearchIcon } from "../common/AppIcons";
import MaterialModal from "../common/MaterialModal";
import { BuildingIcon } from "./ClientAppIcons";
import RenderAddressComponent from "./RenderAddressComponent";
import BuildingFormFields from "./forms/BuildingFormFields";

export default function ListBuildings({
  clientData,
  buildings,
  selectedBuilding,
  buildingChangeCB,
  refreshCB,
}) {
  const [data, setData] = React.useState();

  const [buildingData, setBuildingData] = useState(buildings);

  const [searchText, setSearchText] = React.useState("");

  useEffect(() => {
    setBuildingData(buildings);
  }, [buildings]);

  useEffect(() => {
    setBuildingData(
      buildings.filter((building) => {
        return renderAddressUnitNumber(building.address)
          .toLowerCase()
          .includes(searchText.toLowerCase());
      })
    );
  }, [searchText]);

  console.log({ clientData });

  const path = usePath();
  return (
    <div
      className={
        "flex flex-col border border-newGray-1500 p-4 rounded w-full md:w-80"
      }
    >
      {data && (
        <MaterialModal
          open={true}
          setOpen={() => {
            setData(null);
          }}
        >
          <BuildingFormFields
            data={data}
            onChange={({ name, value }) => {
              setData((data) => deepUpdate(name, value, data));
            }}
            clientClass={clientData?.clientVariant.toUpperCase()}
          />
          <div className="flex flex-row justify-end">
            <button
              className="border rounded text-sm px-2 py-1 h-10 w-24 mr-10 bg-newBlue-500 text-newBlue-400"
              onClick={() => {
                let createApi = createUnderIndividualClient;
                if (clientData?.clientClass === "HOA_OWNER") {
                  createApi = createUnderHOA;
                } else if (clientData?.clientClass === "PMC") {
                  createApi = createUnderPMC;
                }

                createApi(
                  {
                    ...data,
                    contacts: [],
                  },
                  clientData?.clientId
                )
                  .then((_res) => {
                    alertSuccess("Building details saved successfully");
                    setData(null);
                    refreshCB();
                  })
                  .catch((err) => {
                    alertError(
                      err.readableMessage ?? "Error creating building"
                    );
                  });
              }}
            >
              Save
            </button>
          </div>
        </MaterialModal>
      )}
      <div className="flex justify-center">
        <span className="text-newGray-300">Buildings</span>
      </div>
      <div className="py-2">
        <div className="flex flex-row items-center px-2 rounded bg-newGray-100">
          <div
            onClick={() => {
              document.getElementById("search_input").focus();
            }}
          >
            <SearchIcon />
          </div>
          <input
            id="search_input"
            className="focus:outline-none p-2 text-gray-700 bg-newGray-100"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>
      </div>

      <button
        className="border rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400"
        onClick={() => {
          setData({
            clientId:
              selectedBuilding?.clientId || clientData.clientId
                ? clientData?.clientId
                : clientData?.contactId,
          });
        }}
      >
        <div className="flex flex-row items-center">
          <div>
            <AddIconBlue className="h-4 w-4" />
          </div>
          <div className="flex gap-1 px-2">
            <span className="hidden md:inline">Add</span>
            <span className="">New</span>
            <span className="hidden md:inline">Building</span>
          </div>
        </div>
      </button>
      <div className="p-2 ">
        {buildingData.map((building, index) => (
          <div
            className={
              "items-center rounded flex flex-row py-4 p-2 hover:bg-newBlue-500 hover:text-newBlue-400 " +
              (selectedBuilding?.buildingId === building?.buildingId
                ? "bg-newBlue-500 text-newBlue-400"
                : "")
            }
            onClick={() => {
              buildingChangeCB(building);
            }}
          >
            <div className="flex flex-col">
              <BuildingIcon />
              <span className="text-xs font-bold italic text-newGray-300">
                {building.buildingType?.slice(0, 3)}
              </span>
            </div>
            <div className="px-2">
              <RenderAddressComponent
                address={building.address}
                unitNumber={building.unitNumbers.join(" | ")}
                position={building.positions}
              />
              {building.positions &&
                clientData.clientVariant === "individual" && (
                  <div className="flex flex-row">
                    {building.positions.map((item) => {
                      return (
                        item && (
                          <div className="border px-0.5 mr-1 text-xs border-gray-400 rounded">
                            {item.charAt(0) + item.slice(1).toLowerCase()}
                          </div>
                        )
                      );
                    })}
                  </div>
                )}
              {/* {renderAddressUnitNumber(building.address)} */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
