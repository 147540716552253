/* Untyped file generated from Dropdown.res by genType. */
/* eslint-disable */

import {default as makeNotChecked} from './Dropdown.jsx';

// In case of type error, check the type of 'make' in 'Dropdown.re' and './Dropdown.jsx'.
export const makeTypeChecked = makeNotChecked;

// Export 'make' early to allow circular import from the '.bs.js' file.
export const make = makeTypeChecked;
