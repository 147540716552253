// import React from "react";
// import { make as MaterialFormRE } from "./MaterialForm.bs";

// export default function MaterialForm(props) {
//     return (<MaterialFormRE {...props}/>)
// }

import { make as MaterialForm } from "./MaterialForm.bs";

export default MaterialForm;
