// Generated by ReScript, PLEASE EDIT WITH CARE

import * as NumberGen from "./Number.gen";

var make = NumberGen.make;

export {
  make ,
  
}
/* make Not a pure module */
