import React, { useEffect } from "react";

import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { deepFind } from "../../../../utils/StateUtils";

export default function Text({
  variant,
  data,
  name,
  label,
  onChange,
  optional,
  defaultValue,
  className,
  editable = true,
}) {
  const value = deepFind(name, data);
  useEffect(() => {
    value === defaultValue && onChange({ name, value });
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="date-picker-inline"
        label={label}
        disabled={!editable}
        disablePast={variant?.includes("DISABLEPAST") ? true : false}
        value={value}
        onChange={(newValue) => onChange({ name: name, value: newValue })}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        renderInput={(params) => <TextField variant="standard" {...params} />}
        className={className}
      />
    </LocalizationProvider>
  );
}
