/* Untyped file generated from DateTimePicker.res by genType. */
/* eslint-disable */

import {default as makeNotChecked} from './DateTimePicker.jsx';

// In case of type error, check the type of 'make' in 'DateTimePicker.re' and './DateTimePicker.jsx'.
export const makeTypeChecked = makeNotChecked;

// Export 'make' early to allow circular import from the '.bs.js' file.
export const make = makeTypeChecked;
