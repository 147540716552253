import { useEffect, useState } from "react";

import { alertError } from "../../../actions/AlertActions";
import { newBuildingGet, updateBuilding, updateUnit } from "../../../api/Api";
import MaterialForm from "../../../components/common/form/MaterialForm";
import { deepUpdate } from "../../../utils/StateUtils";
import { amenitiesForm } from "./helper";

type UnitAmenitiesProps = {
  unitId: number;
  buildingId: number;
  onBackClick: () => void;
  setCurrentScreen: React.Dispatch<
    React.SetStateAction<"generalInfo" | "uploadPhoto" | "unitAmenities">
  >;
};

export default function UnitAmenities({
  onBackClick,
  unitId,
  buildingId,
  setCurrentScreen,
}: UnitAmenitiesProps) {
  const [amenities, setAmenities] = useState({
    AIR_CONDITIONING: false,
    "BALCONY/PATIO": false,
    "CABLE/SATELLITE_TV": false,
    CARPETED_FLOORS: false,
    CEILING_FAN: false,
    DISHWASHER: false,
    ELECTRIC_RANGE: false,
    FIREPLACE: false,
    FURNISHED: false,
    GARAGE: false,
    GARBAGE_DISPOSAL: false,
    GAS_RANGE: false,
    HARDWOOD_FLOORS: false,
    "HIGH-SPEED_INTERNET": false,
    INTRUSION_ALARM: false,
    MICROWAVE: false,
    PETS_ALLOWED: false,
    REFRIGERATOR: false,
    UTILITIES_PAID: false,
    VAULTED_CEILINGS: false,
    "WALK-IN_CLOSETS": false,
    "WASHER/DRYER_HK-UP": false,
    "WASHER/DRYER_IN_UNIT": false,
    WATERFRONT_PROPERTY: false,
    WHEELCHAIR_ACCESS: false,
  });

  const [propertyAmenities, setPropertyAmenities] = useState({
    BIKE_PATHS: false,
    BOATING: false,
    CLUBHOUSE: false,
    CONTROLLED_ACCESS: false,
    COURTYARD: false,
    FITNESS_CENTER: false,
    GOLF_COURSE: false,
    LAUNDRY_FACILITIES: false,
    "PLAYGROUND/PARK": false,
    POOL: false,
    PUBLIC_TRANSPORTATION: false,
    RESERVED_PARKING: false,
    "SPA/JACUZZI": false,
    STORAGE: false,
    TENNIS_COURT: false,
  });

  useEffect(() => {
    newBuildingGet(buildingId).then((data) => {
      if (data.profile !== null) {
        if (data.profile?.amenities !== null)
          setPropertyAmenities(data.profile.amenities);
        if (data.profile?.unitAmenities !== null)
          setAmenities(data.profile.unitAmenities);
      }
    });
  }, [buildingId]);

  const [propertySelectAll, setPropertySelectAll] = useState({
    allSelected: false,
  });

  const [selectAll, setSelectAll] = useState({
    allSelected: false,
  });

  const onChange = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setAmenities((data) => deepUpdate(name, value, data));
  };

  const onChangeAmenities = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setPropertyAmenities((data) => deepUpdate(name, value, data));
  };
  const onChangeSelectAll = (update: { name: string; value: boolean }) => {
    const { name, value } = update;

    setSelectAll((data) => deepUpdate(name, value, data));

    setAmenities({
      AIR_CONDITIONING: value,
      "BALCONY/PATIO": value,
      "CABLE/SATELLITE_TV": value,
      CARPETED_FLOORS: value,
      CEILING_FAN: value,
      DISHWASHER: value,
      ELECTRIC_RANGE: value,
      FIREPLACE: value,
      FURNISHED: value,
      GARAGE: value,
      GARBAGE_DISPOSAL: value,
      GAS_RANGE: value,
      HARDWOOD_FLOORS: value,
      "HIGH-SPEED_INTERNET": value,
      INTRUSION_ALARM: value,
      MICROWAVE: value,
      PETS_ALLOWED: value,
      REFRIGERATOR: value,
      UTILITIES_PAID: value,
      VAULTED_CEILINGS: value,
      "WALK-IN_CLOSETS": value,
      "WASHER/DRYER_HK-UP": value,
      "WASHER/DRYER_IN_UNIT": value,
      WATERFRONT_PROPERTY: value,
      WHEELCHAIR_ACCESS: value,
    });
  };

  const onChangePropertySelectAll = (update: {
    name: string;
    value: boolean;
  }) => {
    const { name, value } = update;

    setPropertySelectAll((data) => deepUpdate(name, value, data));

    setPropertyAmenities({
      BIKE_PATHS: value,
      BOATING: value,
      CLUBHOUSE: value,
      CONTROLLED_ACCESS: value,
      COURTYARD: value,
      FITNESS_CENTER: value,
      GOLF_COURSE: value,
      LAUNDRY_FACILITIES: value,
      "PLAYGROUND/PARK": value,
      POOL: value,
      PUBLIC_TRANSPORTATION: value,
      RESERVED_PARKING: value,
      "SPA/JACUZZI": value,
      STORAGE: value,
      TENNIS_COURT: value,
    });
  };

  return (
    <div className="flex flex-col items-center mt-4 md:w-3/4">
      <div className="text-2xl font-semibold text-black">Unit Amenities</div>

      <div className="">
        <MaterialForm
          className="w-full grid grid-flow-row-dense grid-rows-1 grid-cols-1 md:grid-cols-3 mt-3"
          data={selectAll}
          onChange={onChangeSelectAll}
          fieldClassName="mr-2 p-4"
          renderArray={[
            {
              name: "allSelected",
              inputType: "CHECKBOX",
              label: "Select All",
            },
          ]}
        />
        <MaterialForm
          className="w-full grid grid-flow-row-dense grid-rows-1 grid-cols-1 md:grid-cols-3 mt-3"
          data={amenities}
          onChange={onChange}
          fieldClassName="mx-2"
          renderArray={amenitiesForm()}
        />
      </div>
      <div className="text-xl font-semibold text-black mt-8">
        Property Amenities
      </div>
      <div className="">
        <MaterialForm
          className="w-full grid grid-flow-row-dense grid-rows-1 grid-cols-1 md:grid-cols-3 mt-3"
          data={propertySelectAll}
          onChange={onChangePropertySelectAll}
          fieldClassName="mr-2 p-4"
          renderArray={[
            {
              name: "allSelected",
              inputType: "CHECKBOX",
              label: "Select All",
            },
          ]}
        />

        <div className="mt-4">
          <MaterialForm
            className="w-full grid grid-flow-row-dense grid-rows-1 grid-cols-1 md:grid-cols-3 mt-3"
            data={propertyAmenities}
            onChange={onChangeAmenities}
            fieldClassName="mx-2"
            renderArray={[
              {
                name: "BIKE_PATHS",
                inputType: "CHECKBOX",
                label: "Bike Paths",
              },
              {
                name: "BOATING",
                inputType: "CHECKBOX",
                label: "Boating",
              },
              {
                name: "CLUBHOUSE",
                inputType: "CHECKBOX",
                label: "Clubhouse",
              },
              {
                name: "CONTROLLED_ACCESS",
                inputType: "CHECKBOX",
                label: "Controlled Access",
              },
              {
                name: "COURTYARD",
                inputType: "CHECKBOX",
                label: "Courtyard",
              },
              {
                name: "FITNESS_CENTER",
                inputType: "CHECKBOX",
                label: "Fitness Center",
              },
              {
                name: "GOLF_COURSE",
                inputType: "CHECKBOX",
                label: "Golf Course",
              },
              {
                name: "LAUNDRY_FACILITIES",
                inputType: "CHECKBOX",
                label: "Laundry Facilities",
              },
              {
                name: "PLAYGROUND/PARK",
                inputType: "CHECKBOX",
                label: "Playground/Park",
              },
              {
                name: "POOL",
                inputType: "CHECKBOX",
                label: "Pool",
              },
              {
                name: "PUBLIC_TRANSPORTATION",
                inputType: "CHECKBOX",
                label: "Public Transportation",
              },
              {
                name: "RESERVED_PARKING",
                inputType: "CHECKBOX",
                label: "Reserved Parking",
              },
              {
                name: "SPA/JACUZZI",
                inputType: "CHECKBOX",
                label: "Spa/Jacuzzi",
              },
              {
                name: "STORAGE",
                inputType: "CHECKBOX",
                label: "Storage",
              },
              {
                name: "TENNIS_COURT",
                inputType: "CHECKBOX",
                label: "Tennis Court",
              },
            ]}
          />
        </div>
      </div>
      <div className="flex flex-row md:self-end mt-4 md:mr-4 ">
        <button
          className="text-base flex justify-center items-center px-4 py-2 rounded  font-medium cursor-pointer 
            bg-gray-300
            text-gray-800 duration-200 ease-in-out 
            transition"
          onClick={() => setCurrentScreen("uploadPhoto")}
        >
          Go Back
        </button>
        <button
          className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
            bg-newBlue-400  w-40
            text-white border-newBlue-400
            border"
          onClick={() => {
            updateUnit(unitId, [
              {
                op: "replace",
                path: "/unitDetails/amenities",
                value: amenities,
              },
            ])
              .then(() => {
                updateBuilding(buildingId, [
                  {
                    op: "replace",
                    path: "/profile/amenities",
                    value: propertyAmenities,
                  },
                ])
                  .then(() => {
                    onBackClick();
                  })
                  .catch(() =>
                    alertError("Error while saving PropertyAmenities")
                  );
              })
              .catch(() => alertError("Error while saving Amenities"));
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
}
