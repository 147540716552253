import React, { useEffect, useState } from "react";

import PointIcon from "@mui/icons-material/Room";
import GoogleMapReact from "google-map-react";

import PopOverText from "../common/PopOverText";

const defaultProps = {
  center: {
    lat: 34.0522,
    lng: -118.2437,
  },
  zoom: 11,
};

interface MarkerProps {
  lat: number;
  lng: number;
  label?: string;
}

interface PopOverMarkerProps extends MarkerProps {
  label: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
const PopOverMarker = ({ label, lat, lng }: PopOverMarkerProps) => (
  <PopOverText text={`Client: ${label}`}>
    <PointIcon className="h-8 w-8 absolute bottom-0 -ml-4 text-green-700" />
  </PopOverText>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
const DefaultMarker = ({ lat, lng }: MarkerProps) => (
  <PointIcon className="h-8 w-8 absolute bottom-0 -ml-4 text-green-700" />
);

interface MapPoint {
  lat: number;
  lng: number;
  label?: string;
}

interface MapDataPoint {
  lat: string;
  lng: string;
  label?: string;
}

const degreesToRadians = (degrees: number) => {
  return (degrees * Math.PI) / 180;
};

const radiansToDegrees = (radians: number) => {
  return (radians * 180) / Math.PI;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _mathematicalCalculateCenter = (data: MapDataPoint[]) => {
  if (data.length === 0) {
    return defaultProps.center as MapPoint;
  }

  if (data.length === 1) {
    const firstPoint = data[0];
    return {
      lat: Number(firstPoint.lat),
      lng: Number(firstPoint.lng),
    };
  }

  let x = 0;
  let y = 0;
  let z = 0;

  const dataPointsArray = data.map((point) => [
    Number(point.lat),
    Number(point.lng),
  ]);

  data.forEach((point) => {
    const latitude = degreesToRadians(Number(point.lat));
    const longitude = degreesToRadians(Number(point.lng));

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  });

  const total = data.length;

  x = x / total;
  y = y / total;
  z = z / total;

  const centralLongitude = Math.atan2(y, x);
  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLatitude = Math.atan2(z, centralSquareRoot);

  const returnPointRadians = {
    lat: centralLatitude,
    lng: centralLongitude,
  };

  const returnPoint = {
    lat: radiansToDegrees(returnPointRadians.lat),
    lng: radiansToDegrees(returnPointRadians.lng),
  };

  return returnPoint;
};

const calculateCenter = (data: MapDataPoint[]) => {
  if (data.length === 0) {
    return defaultProps.center as MapPoint;
  }

  if (data.length === 1) {
    const firstPoint = data[0];
    return {
      lat: Number(firstPoint.lat),
      lng: Number(firstPoint.lng),
    };
  }

  const maxLat = Math.max(...data.map((point) => Number(point.lat)));
  const minLat = Math.min(...data.map((point) => Number(point.lat)));

  const maxLng = Math.max(...data.map((point) => Number(point.lng)));
  const minLng = Math.min(...data.map((point) => Number(point.lng)));

  const centerLat = (maxLat + minLat) / 2;
  const centerLng = (maxLng + minLng) / 2;

  return {
    lat: centerLat,
    lng: centerLng,
  };
};

// Calculate zoom level based on the data points
// This is a rough approximation, built using copilot, but it may be working well enough
const calculateZoomLevel = (data: MapDataPoint[]) => {
  if (data.length === 0) {
    return defaultProps.zoom;
  }

  if (data.length === 1) {
    return 15;
  }

  const dataPointsArray = data.map((point) => [
    Number(point.lat),
    Number(point.lng),
  ]);

  const maxLat = Math.max(...dataPointsArray.map((point) => point[0]));
  const minLat = Math.min(...dataPointsArray.map((point) => point[0]));

  const maxLng = Math.max(...dataPointsArray.map((point) => point[1]));
  const minLng = Math.min(...dataPointsArray.map((point) => point[1]));

  const latDiff = maxLat - minLat;
  const lngDiff = maxLng - minLng;

  const maxDiff = Math.max(latDiff, lngDiff);

  const zoomLevel = Math.round(Math.log(360 / maxDiff) / Math.LN2);

  return zoomLevel;
};

export default function MapComponent({
  data = [],
  center,
  onClick,
}: {
  data: MapDataPoint[];
  center?: MapDataPoint;
  onClick?: (e: any) => void;
}) {
  // Find the ideal center point that will show all the points
  const [centerPoint, setCenter] = useState<MapPoint>(calculateCenter(data));

  useEffect(() => {
    setCenter((_center) => {
      const updatedCenter = calculateCenter(data);
      return updatedCenter;
    });
  }, [data]);

  return (
    <div
      className="flex flex-shrink-0"
      style={{ height: "100%", width: "100%" }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyClTu9Ezl7dx86h571Bxht0aYPwEp_t3kg",
        }}
        center={centerPoint ?? defaultProps.center}
        zoom={calculateZoomLevel(data) - 1}
        onClick={onClick}
        yesIWantToUseGoogleMapApiInternals
      >
        {data?.map((point) =>
          point.label ? (
            <PopOverMarker
              label={point.label}
              lat={point.lat}
              lng={point.lng}
            />
          ) : (
            <DefaultMarker
              key={`${point.lat}${point.lng}`}
              lat={point.lat}
              lng={point.lng}
            />
          )
        ) || <></>}
      </GoogleMapReact>
    </div>
  );
}
