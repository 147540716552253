import React from "react";

import moment from "moment";
import { navigate } from "raviger";

export default function TimeTile({ datetime }) {
  const dateObj = new Date(datetime);
  return (
    <div
      className="flex flex-col justify-center items-center bg-gray-200 p-2"
      onClick={() => {
        navigate(`/scheduler/${datetime}`);
      }}
    >
      <span className="">{moment(dateObj).format("MM/DD/YYYY hh:mm A")}</span>
    </div>
  );
}
