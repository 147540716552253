import React from "react";

import { alertError } from "../../actions/AlertActions";
import { estimateAccept } from "../../api/Api";
import MaterialModal from "../common/MaterialModal";
import EstimateForm from "./EstimateForm";
import { objectifyLines } from "./EstimateFormUtils";

// TODO: This component makes no sense; acceptEstimateCB should be a function defined in EstimateForm
// but it seems to be slightly different for some reason
export default function MarkAsAcceptedModal({ visibleVar, clearCB, data }) {
  return (
    <MaterialModal
      open={visibleVar}
      setOpen={(_) => clearCB()}
      extended
      width={"80"}
    >
      <EstimateForm
        ticketData={data}
        setShowModal={() => {
          clearCB();
        }}
        kanbanScreen={visibleVar ? true : false}
        acceptEstimateCB={(estimateId, selectedLines, client_data) => {
          let lines = objectifyLines(selectedLines);
          let ids = [];
          lines.map((item) => {
            ids.push(item.id);
          });
          estimateAccept(estimateId, {
            acceptIds: ids,
            acceptDate: client_data.date,
            acceptedContactId: client_data.acceptedContactId,
            acceptedChannel: client_data.channel,
          })
            .then(() => {
              clearCB();
            })
            .catch((e) => {
              if (e.readableMessage) {
                alertError(e.readableMessage);
              } else {
                alertError("Estimate Not Accepted");
              }
            });
        }}
        onSuccessCB={() => {
          console.log("Success CB");
        }}
        refreshCB={() => {
          console.log("Refresh CB");
        }}
      />
    </MaterialModal>
  );
}
