import React, { useEffect, useState } from "react";

import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";

import { alertError } from "../../../../actions/AlertActions";
import { getAddressAutofill } from "../../../../api/TypedAPI";
import states from "../../../../assets/states";
import { outlineFields } from "../../../../utils/FormUtils";
// import { conditionalArrayEntry } from "../../../../utils/ObjectUtills";
import { conditionalObjectEntry } from "../../../../utils/ObjectUtills";
import { deepFind } from "../../../../utils/StateUtils";
import { make as MaterialForm } from "../MaterialForm.bs";

export default function Address({
  data,
  name,
  label,
  onChange,
  defaultValue,
  variant,
  optional,
  className,
}) {
  const [addressOptions, setAddressOptions] = useState([]);
  const [subOptions, setSubOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState();
  const [formUpdate, setFormUpdate] = useState(false);

  const value = deepFind(name, data);

  // Ensuring that the value can be null, such that the state is always controlled
  useEffect(() => {
    value === defaultValue && onChange({ name, value });
  }, []);

  useEffect(() => {
    if (search) {
      const timeout = setTimeout(() => {
        getAddressAutofill(search.text, search.state)
          .then((options) => {
            setLoading(false);
            setAddressOptions(options);
          })
          .catch((_err) => {
            setLoading(false);
            alertError("Couldn't Find Addresses");
          });
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [search]);

  const updateAutofillSearch = (searchText, name, data) => {
    if (searchText.split(" ").length >= 2) {
      const stateKey = `${name}.state`;
      const state = deepFind(stateKey, data);
      setLoading(true);
      setSearch({ text: searchText, state });
    }
  };

  const updateAddress = (name, value) => {
    const updatedAddress = {
      streetAddress1: value.Address.Address1,
      city: value.Address.Locality ?? value.Address.SubAdministrativeArea,
      state: value.Address.AdministrativeArea,
      zip: value.Address.PostalCodePrimary.split("-")[0],
      geocodeLatitude: value.Address.Latitude,
      geocodeLongitude: value.Address.Longitude,
      melissaBaseMak: value.Address?.BaseMAK
        ? value.Address?.BaseMAK
        : value.Address?.MAK,
      ...conditionalObjectEntry(value.Address?.BaseMAK, {
        units: {
          units: value.Address?.SubBuilding?.split(",") || [],
          mak: value.Address?.MAK?.split(",") || [],
        },
      }),
    };
    onChange({
      name,
      value: updatedAddress,
    });
    setSubOptions(value.Address.SubBuilding?.split(",") || []);
    if (value.Address.SubBuilding.length !== 0) setFormUpdate(true);
  };

  const clearAddress = (name) => {
    onChange({ name, value: {} });
    setSubOptions([]);
    setFormUpdate(false);
  };

  const streetAddress2Field = [
    {
      name: name + ".streetAddress2",
      label: "Unit Number",
      inputType: "SELECT",
      variant: "AUTOCOMPLETE",
      options: subOptions,
    },
  ];

  const addressFields = [
    {
      name: name + ".city",
      editable: false,
    },
    {
      name: name + ".state",
      inputType: "SELECT",
      options: states,
      defaultValue: "CA",
    },
    {
      name: name + ".zip",
      editable: false,
    },
  ];

  return (
    <div>
      <Autocomplete
        className={className}
        fullWidth
        freeSolo
        subForm
        variant={variant?.includes("OUTLINED") ? "outlined" : "standard"}
        options={addressOptions}
        loading={loading}
        filterOptions={(options, _params) => {
          return options;
        }}
        name={name}
        getOptionLabel={(option) =>
          (option?.Address &&
            option.Address.Address1 +
              ", " +
              (option.Address.Locality ||
                option.Address.SubAdministrativeArea) +
              ", " +
              option.Address.AdministrativeArea +
              " " +
              option.Address.PostalCodePrimary +
              (option.Address.SubBuilding ? " Apt" : "")) ||
          option ||
          ""
        }
        value={value && value.streetAddress1 ? value.streetAddress1 : null}
        onChange={(_event, newValue, reason) => {
          if (reason === "clear") {
            clearAddress(name);
          } else {
            newValue && newValue.Address && updateAddress(name, newValue);
          }
        }}
        isOptionEqualToValue={(option, value) => {
          console.log(option, value, option.Address.Address1 === value);
          return option.Address.Address1 === value;
        }}
        renderInput={(params) => {
          return (
            <TextField
              autoComplete="off"
              {...params}
              variant={variant?.includes("OUTLINED") ? "outlined" : "standard"}
              label={label + (optional ? " (Optional)" : "")}
              placeholder={label + (optional ? " (Optional)" : "")}
              name={name}
              onChange={(event) => {
                updateAutofillSearch(event.target.value, name, data);
              }}
            />
          );
        }}
      />
      {(variant?.includes("OUTLINED")
        ? outlineFields(
            formUpdate
              ? [...streetAddress2Field, ...addressFields]
              : [...addressFields]
          )
        : formUpdate
        ? [...streetAddress2Field, ...addressFields]
        : [...addressFields]
      ).map((field) => {
        return (
          <MaterialForm
            className="flex flex-col gap-4 mt-4 w-full"
            data={data}
            editable={field.editable ?? true}
            onChange={onChange}
            renderArray={[field]}
          />
        );
      })}
    </div>
  );
}
