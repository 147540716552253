import React, { useEffect, useState } from "react";

import { navigate } from "raviger";

import {
  alertError,
  alertInfo,
  alertSuccess,
} from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import { getVendorA2P, updateVendorA2P } from "../../api/Api";
import { LinkIcon } from "../../components/common/AppIcons";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import MaterialModal from "../../components/common/MaterialModal";
import MaterialForm from "../../components/common/form/MaterialForm";
import { deepUpdate } from "../../utils/StateUtils";
import ComplianceStatusModal from "./ComplianceStatusModal";
import { ComplainceData } from "./ListPhoneNumbers";

export default function A2PComplianceForm() {
  const [customerprofile, setCustomerProfile] = useState({
    businessName: "",
    businessType: "",
    idType: "",
    businessIndustry: "",
    businessRegistrationNumber: "",
    businessEmail: "",
    websiteUrl: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    businessTitle: "",
    jobPosition: "",
    isSoleProprietor: null as boolean | null,
    customerProfileSid: null as string | null,
  });
  const { withConfirmation } = useAppActions();
  const [complianceModal, setComplianceModal] = useState(false);
  const [complainceData, setComplainceData] = useState<ComplainceData>();
  const [showTaxIdPopup, setShowTaxIdPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getVendorA2P().then((data) => {
      setCustomerProfile({
        businessName: data.businessName ?? "",
        businessType: data.businessType ?? "",
        idType: data.idType ?? "",
        businessIndustry: data.businessIndustry ?? "",
        businessRegistrationNumber: data.businessRegistrationNumber ?? "",
        businessEmail: data.businessEmail ?? "",
        websiteUrl: data.websiteUrl ?? "",
        firstName: data.firstName ?? "",
        lastName: data.lastName ?? "",
        email: data.email ?? "",
        phoneNumber: data.phoneNumber ?? "",
        businessTitle: data.businessTitle ?? "",
        jobPosition: data.jobPosition ?? "",
        isSoleProprietor: data.isSoleProprietor,
        customerProfileSid: data.customerProfileSid ?? null,
      });

      if (data.isSoleProprietor === null) {
        setShowTaxIdPopup(true);
      }
    });
  }, []);

  const onChange = (update: {
    name: string;
    subOptions?: string[];
    value?: string;
  }) => {
    let { name, value } = update;

    setCustomerProfile((data) => deepUpdate(name, value, data));
  };

  const businessNameForm = () => {
    return [{ name: "businessName", label: "Legal Business Name" }];
  };

  const onSubmit = () => {
    setIsLoading(true);
    updateVendorA2P(customerprofile)
      .then(() => {
        return getVendorA2P();
      })
      .then((data) => {
        setComplianceModal(true);
        setComplainceData(data);
      })
      .catch(() => alertError("Something went wrong"))
      .finally(() => setIsLoading(false));
  };

  const handleTaxIdResponse = (hasTaxId: boolean) => {
    setCustomerProfile((prev) => ({ ...prev, isSoleProprietor: !hasTaxId }));
    setShowTaxIdPopup(false);
  };

  const renderForm = () => {
    if (customerprofile.isSoleProprietor === null) {
      return null; // Don't render the form until we know if it's a sole proprietor
    }

    if (customerprofile.isSoleProprietor) {
      return (
        <>
          <MaterialForm
            data={customerprofile}
            renderArray={[
              { name: "businessName", label: "Business Name" },
              { name: "firstName", label: "First Name" },
              { name: "lastName", label: "Last Name" },
              {
                name: "businessEmail",
                label: "Business Email",
                inputType: "EMAIL",
              },
              {
                name: "phoneNumber",
                label: "Business Phone",
                inputType: "PHONE",
              },
            ]}
            onSubmit={() => {}}
            onChange={onChange}
            fieldClassName="my-4"
          />
        </>
      );
    }

    return (
      <>
        <MaterialForm
          data={customerprofile}
          renderArray={businessNameForm()}
          onSubmit={() => {}}
          onChange={onChange}
          fieldClassName="my-4"
        />
        <div className="text-newGray-800 text-sm font-normal">
          If you are registering a US entity, Please enter the exact legal
          business name as registered with the EIN, which can be found on the
          CP575 EIN confirmation letter
        </div>
        <div className="flex flex-row items-end">
          <a
            href="https://www.twilio.com/docs/sms/a2p-10dlc/onboarding#1-create-a-twilio-business-profile-in-trust-hub"
            className="text-newBlue-400 text-sm font-normal underline mr-1"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
          <div className="mb-1">
            <LinkIcon className="h-3 w-3" />
          </div>
        </div>
        <div className=" border-b border-newGray-500 pb-4 mt-4">
          <MaterialForm
            data={customerprofile}
            renderArray={businessForm()}
            onSubmit={() => {}}
            onChange={onChange}
            fieldClassName=""
          />
          <div className="text-newGray-800 text-sm font-normal">
            The provided website should be reflective of the registered brand
            and accessible
          </div>
        </div>
        <div className="mt-4">
          <div className="text-black text-xl font-semibold">
            Point of Contact
          </div>
          <div className="">
            <MaterialForm
              data={customerprofile}
              renderArray={contactForm()}
              onSubmit={() => {}}
              onChange={onChange}
              fieldClassName=""
            />
          </div>
        </div>
      </>
    );
  };

  const businessForm = () => {
    return [
      {
        name: "businessType",

        inputType: "SELECT",
        options: [
          { label: "Partnership", value: "Partnership" },
          { label: "Corporation", value: "Corporation" },
          { label: "Co-operative", value: "Co-operative" },
          {
            label: "Limited Liability Corporation",
            value: "Limited Liability Corporation",
          },
          { label: "Non-profit Corporation", value: "Non-profit Corporation" },
        ],
      },
      {
        name: "idType",
        label: "Business registration ID Type",
        inputType: "SELECT",
        options: [
          { label: "USA- Employer Identification Number (EIN)", value: "EIN" },
          { label: "VAT Number", value: "VAT" },
        ],
      },
      {
        name: "businessIndustry",
        inputType: "SELECT",
        options: [
          { label: "Automotive", value: "AUTOMOTIVE" },
          { label: "Agriculture", value: "AGRICULTURE" },
          { label: "Banking", value: "BANKING" },
          { label: "Construction", value: "CONSTRUCTION" },
          { label: "Consumer", value: "CONSUMER" },
          { label: "Education", value: "EDUCATION" },
          { label: "Engineering", value: "ENGINEERING" },
          { label: "Energy", value: "ENERGY" },
          { label: "Oil and gas", value: "OIL_AND_GAS" },
          {
            label: "Fast moving consumer goods",
            value: "FAST_MOVING_CONSUMER_GOODS",
          },
          { label: "Financial", value: "FINANCIAL" },
          { label: "Fintech", value: "FINTECH" },
          { label: "Food & Beverage", value: "FOOD_AND_BEVERAGES" },
          { label: "Government", value: "GOVERNMENT" },
          { label: "Healthcare", value: "HELTHCARE" },
          { label: "Hospitality", value: "HOSPITALITY" },
          { label: "Insurance", value: "INSURANCE" },
          { label: "Legal", value: "LEGAL" },
          { label: "Manufacturing", value: "MANUFACTURING" },
          { label: "Media", value: "MEDIA" },
          { label: "Online", value: "ONLINE" },
          { label: "Professional Services", value: "PROFESSIONAL_SERVICES" },
          { label: "Raw Materials", value: "RAW_MATERIALS" },
          { label: "Real Estate", value: "REAL_ESTATE" },
          { label: "Religion", value: "RELIGION" },
          { label: "Retail", value: "RETAIL" },
          { label: "Jewelry", value: "JEWELRY" },
          { label: "Technology", value: "TECHNOLOGY" },
          { label: "Telecommunication", value: "TELECOMMUNICATIONS" },
          { label: "Transportation", value: "TRANSPORTATION" },
          { label: "Travel", value: "TRAVEL" },
          { label: "Electronics", value: "ELECTRONICS" },
        ],
      },
      { name: "businessRegistrationNumber", inputType: "NUMBER" },
      { name: "businessEmail", inputType: "EMAIL" },
      { name: "websiteUrl" },
    ];
  };

  const contactForm = () => {
    return [
      { name: "firstName" },
      { name: "lastName" },
      { name: "email", inputType: "EMAIL" },
      { name: "phoneNumber", inputType: "PHONE" },
      { name: "businessTitle" },
      {
        name: "jobPosition",
        inputType: "SELECT",
        options: [
          { label: "Director", value: "Director" },
          { label: "GM", value: "GM" },
          { label: "VP", value: "VP" },
          { label: "CEO", value: "CEO" },
          { label: "CPO", value: "CPO" },
          { label: "General Conunsel", value: "General Counsel" },
          { label: "Other", value: "Other" },
        ],
      },
    ];
  };

  const renderSubmitButton = () => {
    const isSubmitDisabled = customerprofile.customerProfileSid !== null;
    const buttonText = isSubmitDisabled ? "Resubmit" : "Submit";
    const tooltipText = isSubmitDisabled
      ? "A customer profile already exists for this vendor. Please contact an administrator for further changes."
      : "";

    return (
      <button
        className={`flex justify-center items-center h-8 w-full mt-4 text-base px-4 py-2 rounded font-medium ${
          isSubmitDisabled
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-newBlue-400 hover:bg-newBlue-500"
        } text-white`}
        onClick={() => {
          withConfirmation({
            title: "Submit A2P Compliance Form",
            description:
              "Are you sure you want to submit the A2P Compliance Form?",
            onConfirm: onSubmit,
          });
        }}
        disabled={isSubmitDisabled}
        title={tooltipText}
      >
        {buttonText}
      </button>
    );
  };

  return (
    <div className="flex flex-col items-center m-4 pb-10 bg-white p-4 rounded-lg border-2">
      <LoadingOverlay
        isLoading={isLoading}
        message="Submitting. Please wait..."
      />
      {!showTaxIdPopup && (
        <div className="w-full md:w-144">
          <div className="text-black text-xl font-semibold">
            Create Customer profile
          </div>
          {renderForm()}
          {renderSubmitButton()}
        </div>
      )}
      {showTaxIdPopup && (
        <MaterialModal
          open={showTaxIdPopup}
          setOpen={(_) => {}}
          label="Tax ID Question"
          describedBy="tax_id_question_modal"
        >
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-4">
              Does your business have a Tax ID?
            </h2>
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-200 rounded"
                onClick={() => handleTaxIdResponse(false)}
              >
                No
              </button>
              <button
                className="px-4 py-2 bg-newBlue-400 text-white rounded"
                onClick={() => handleTaxIdResponse(true)}
              >
                Yes
              </button>
            </div>
          </div>
        </MaterialModal>
      )}
      {complianceModal && (
        <MaterialModal
          open={complianceModal}
          setOpen={(_) => setComplianceModal(false)}
          label="compliance"
          describedBy="compliance_modal"
        >
          <ComplianceStatusModal
            brandStatus={complainceData?.brandStatus ?? ""}
            campaignStatus={complainceData?.campaignStatus ?? ""}
            profileStatus={complainceData?.customerProfileStatus ?? ""}
            onClose={() => {
              setComplianceModal(false);
              navigate("/settings");
            }}
            onEdit={() => setComplianceModal(false)}
          />
        </MaterialModal>
      )}
    </div>
  );
}
