import dispatcher from "../dispatcher";

export function startCall(callerInfo) {
  dispatcher.dispatch({
    actionType: "VOICE",
    callerInfo,
  });
}

export function startAssociateCall(callerInfo) {
  dispatcher.dispatch({
    actionType: "ASSOCIATE_VOICE",
    callerInfo,
  });
}

export function callConnected() {
  dispatcher.dispatch({
    actionType: "CALL_CONNECT",
  });
}

export function callEnded() {
  dispatcher.dispatch({
    actionType: "CALL_END",
  });
}

export function incomingTask(callerInfo) {
  dispatcher.dispatch({
    actionType: "INCOMING_TASK",
    callerInfo,
  });
}

export function taskTimeout(callerInfo) {
  dispatcher.dispatch({
    actionType: "TASK_TIMEOUT",
    callerInfo,
  });
}

export function taskCancelled(callerInfo) {
  dispatcher.dispatch({
    actionType: "TASK_CANCELLED",
    callerInfo,
  });
}
