import { useEffect, useState } from "react";



import { Typography } from "@mui/material";



import { getInvoiceById, updateInvoice } from "../../api/Api";
import { properString } from "../../utils/StringUtils";
import { useMediaQuery } from "../../utils/useMediaQuery";
import MaterialModal from "../common/MaterialModal";
import MaterialForm from "../common/form/MaterialForm";
import { AdditionalItem, InvoiceData } from "./InvoiceFormUtils";



const headings = ["PRODUCT", "DESCRIPTION", "QTY", "RATE", "AMOUNT", ""];

type AdditionalItemsTableProps = {
  additionalData: AdditionalItem[] | [];
  invoiceId: number;
  invoiceStatus: string;
};

export default function AdditionalItemsTable({
  additionalData,
  invoiceId,
  invoiceStatus,
}: AdditionalItemsTableProps) {
  const [additionalRows, setAdditionalRows] = useState<AdditionalItem[]>([]);
  const isMobile = useMediaQuery("(max-width: 640px)");

  const formItemUpdate = () => {
    return [
      { name: "title", label: "Title" },
      { name: "description", label: "Description" },
      { name: "quantity", label: "Quantity" },
      { name: "rate", label: "Rate" },
    ];
  };

  const [editItemMobile, setEditItemMobile] = useState();

  useEffect(() => {
    console.log("LOG: editItemMobile", editItemMobile);
  }, [editItemMobile]);

  useEffect(() => {
    const rows = additionalData.map((item: AdditionalItem) => ({
      id: item.id,
      title: item.title,
      description: item.description,
      quantity: item.quantity,
      rate: item.rate,
      amount: item.amount,
    }));

    setAdditionalRows(rows);
  }, [additionalData]);

  const handleChange = (index: number, name: string, value: any) => {
    const tempData = additionalRows;
    const list = [...tempData];
    if (name === "rate" || name === "quantity") {
      if (value.match(/^\d*\.?\d*$/)) {
        list[index][name] = value;

        setAdditionalRows(list);
      }
    } else {
      list[index][name] = value;

      setAdditionalRows(list);
    }
  };

  const updateInvoiceItem = (row: AdditionalItem) => {
    const filteredItems = additionalRows.filter(
      (item: AdditionalItem) => item.id !== row.id && item.id !== 0
    );
    updateInvoice(invoiceId, [
      {
        op: "replace",
        path: "/additionalItems",
        value: [
          ...filteredItems,
          {
            id: row.id === 0 ? Date.now() : row.id,
            title: row.title,
            description: row.description,
            quantity: row.quantity,
            rate: row.rate,
            amount: Number(row.rate) * Number(row.quantity),
          },
        ],
      },
    ]).then(() => {
      getInvoiceById(invoiceId).then((data: InvoiceData) => {
        const rows = data.additionalItems.map((item: AdditionalItem) => ({
          id: item.id,
          title: item.title,
          description: item.description,
          quantity: item.quantity,
          rate: item.rate,
          amount: item.amount,
        }));

        setAdditionalRows(rows);
      });
    });
  };

  return (
    <>
      <div>
        {additionalRows.length > 0 && (
          <div className="w-full mt-5 shadow-md border border-gray-100 rounded">
            <div className="text-gray-600 px-3 font-semibold text-sm mt-1 py-2 border-b border-gray-300 w-full">
              Additional Items
            </div>
            <div className={"shadow rounded overflow-auto w-full"}>
              {!isMobile && tableHeader()}

              {additionalRows.map((row, row_index) => (
                <>
                  {isMobile
                    ? itemCard(row, row_index)
                    : tableItem(row, row_index)}
                </>
              ))}
            </div>
          </div>
        )}

        {invoiceStatus !== "PAID" && (
          <div className=" my-4">
            <button
              className="w-max text-green-600 font-medium border px-2 rounded border-green-600"
              onClick={() => {
                const newItems = [
                  ...additionalRows,
                  {
                    id: 0,
                    title: "",
                    description: "",
                    quantity: 0,
                    rate: 0,
                    amount: 0,
                  },
                ];
                setAdditionalRows(newItems);
                isMobile && setEditItemMobile(newItems.length - 1);
              }}
            >
              ADD ADDITIONAL ITEM
            </button>
          </div>
        )}
      </div>
      {editItemMobile !== undefined && (
        <MaterialModal
          open={editItemMobile !== undefined}
          label="new-user-modal"
          setOpen={(_) => {
            setEditItemMobile(undefined);
          }}
          describedby="create-new-user"
        >
          <div className="flex material-popup">
            <div>
              <Typography variant="h6">
                {additionalRows[editItemMobile].title}{" "}
              </Typography>
              <MaterialForm
                data={additionalRows[editItemMobile]}
                renderArray={formItemUpdate()}
                onChange={(update) => {
                  const { name, value } = update;
                  console.log("LOG: update", update, name, value);
                  setAdditionalRows((current) => {
                    return current.map((item, index) => {
                      if (index === editItemMobile) {
                        return { ...item, [name]: value };
                      }
                      return item;
                    });
                  });
                }}
              />
              <button
                onClick={(_) => {
                  updateInvoiceItem(additionalRows[editItemMobile]);
                  setEditItemMobile(undefined);
                }}
                color="primary"
                className="float-right p-4 material-create"
              >
                Update
              </button>
            </div>
          </div>
        </MaterialModal>
      )}
    </>
  );

  function itemCard(row: any, row_index: number) {
    return (
      <div
        className={
          "flex flex-col p-2 m-2 lb rounded cursor-pointer hover:bg-gray-300 bg-white kanban-cards border-gray-200 border-2"
        }
        onClick={() => {
          console.log("LOG: row_index", row_index);
          setEditItemMobile(row_index);
        }}
      >
        <div className="flex items-center">
          <div className="flex flex-row justify-between w-full mb-1">
            <div className="flex justify-center w-20 rounded bg-newOrange-200 text-newOrange-200 bg-opacity-20 text-xs font-normal py-0.5">
              {row.title}
            </div>
            <div className="self-end">
              <span
                className="inline-flex items-center rounded px-2 py-0.5 text-sm font-medium"
                style={{ color: "#4B4B4B" }}
              >
                ${parseFloat(row.amount).toFixed(2)}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-grow justify-between w-full">
          <p className="text-sm ml-0.5" style={{ color: "#0023FF" }}>
            {row.description}
          </p>

          <div className="w-16 ">
            <button
              className={`break-all w-max items-center px-3 py-0.5 rounded text-xs font-medium 
              ${
                invoiceStatus === "PAID"
                  ? "bg-newGray-700 text-newGray-800"
                  : "bg-red-500 text-red-100"
              }`}
              onClick={(e) => {
                const filteredItems = additionalRows.filter(
                  (item: AdditionalItem) => item.id !== row.id
                );
                updateInvoice(invoiceId, [
                  {
                    op: "replace",
                    path: "/additionalItems",
                    value: filteredItems,
                  },
                ]).then(() => {
                  getInvoiceById(invoiceId).then((data: InvoiceData) => {
                    const rows = data.additionalItems.map(
                      (item: AdditionalItem) => ({
                        id: item.id,
                        title: item.title,
                        description: item.description,
                        quantity: item.quantity,
                        rate: item.rate,
                        amount: item.amount,
                      })
                    );

                    setAdditionalRows(rows);
                  });
                });
                e.stopPropagation();
              }}
              disabled={invoiceStatus === "PAID"}
            >
              Delete
            </button>
          </div>
        </div>

        <div className="grid grid-cols-2 w-full justify-between gap-x-24">
          <div>
            {
              <span className="inline-flex items-center rounded px-1 py-0.5 text-xs font-medium estimateStatus">
                Qty:
                {row.quantity}
              </span>
            }
          </div>
          <div>
            {
              <span className="inline-flex items-center rounded px-1 py-0.5 text-xs font-medium estimateStatus">
                Rate: ${row.rate}
              </span>
            }
          </div>
        </div>
      </div>
    );
  }

  function tableItem(row: AdditionalItem, row_index: number) {
    return (
      <div className={"flex flex-row w-full items-center pl-1 "}>
        <span className={"p-2 text-gray-700 text-sm break-words w-12 mr-2 "}>
          <span className="">{row_index + 1}</span>
        </span>
        <div className={"w-full grid gap-2 items-center grid-cols-8 pr-2 "}>
          <span className="p-1 py-2 break-all text-gray-700 text-sm col-span-2 whitespace-pre-line ">
            <input
              className={"p-1 py-2 mr-3 break-all text-gray-700 text-sm  "}
              disabled={invoiceStatus === "PAID"}
              type="text"
              onChange={(e) => {
                handleChange(row_index, "title", e.target.value);
              }}
              value={row.title}
              onBlur={() => updateInvoiceItem(row)}
            />
          </span>
          <span className="p-1 py-2 break-all text-gray-700 text-sm col-span-2 whitespace-pre-line">
            <input
              className={
                "p-1 py-2 mr-3 break-all text-gray-700 text-sm estimate-quantity "
              }
              disabled={invoiceStatus === "PAID"}
              type="text"
              onChange={(e) => {
                handleChange(row_index, "description", e.target.value);
              }}
              value={row.description}
              onBlur={() => updateInvoiceItem(row)}
            />
          </span>
          {/* Qty Input */}
          <input
            className={
              "p-1 py-2 mr-3 break-all text-gray-700 text-sm text-right estimate-quantity "
            }
            disabled={invoiceStatus === "PAID"}
            type="text"
            onChange={(e) => {
              handleChange(row_index, "quantity", e.target.value);
            }}
            value={row.quantity}
            onBlur={() => updateInvoiceItem(row)}
          />
          {/* Rate Input */}
          <input
            className={
              "p-1 py-2 mr-3 break-all text-gray-700 text-sm text-right caret-transparent estimate-rate "
            }
            disabled={invoiceStatus === "PAID"}
            onChange={(e) => {
              handleChange(row_index, "rate", e.target.value.replace("$", ""));
            }}
            value={"$" + row.rate}
            onBlur={() => updateInvoiceItem(row)}
          />
          {/* Amount Span */}
          <span className="p-1 py-2 break-all text-gray-700 text-sm  text-right">
            {`$${row.amount}`}
          </span>
          <button
            className={`break-all w-max items-center px-3 py-0.5 rounded text-xs font-medium 
                  ${
                    invoiceStatus === "PAID"
                      ? "bg-newGray-700 text-newGray-800"
                      : "bg-red-500 text-red-100"
                  }`}
            onClick={() => {
              const filteredItems = additionalRows.filter(
                (item: AdditionalItem) => item.id !== row.id
              );
              updateInvoice(invoiceId, [
                {
                  op: "replace",
                  path: "/additionalItems",
                  value: filteredItems,
                },
              ]).then(() => {
                getInvoiceById(invoiceId).then((data: InvoiceData) => {
                  const rows = data.additionalItems.map(
                    (item: AdditionalItem) => ({
                      id: item.id,
                      title: item.title,
                      description: item.description,
                      quantity: item.quantity,
                      rate: item.rate,
                      amount: item.amount,
                    })
                  );

                  setAdditionalRows(rows);
                });
              });
            }}
            disabled={invoiceStatus === "PAID"}
          >
            Delete
          </button>
        </div>
      </div>
    );
  }
}

function tableHeader() {
  return (
    <div>
      <div className="flex flex-row w-full p-3 px-5 items-start">
        <span className="pr-5 py-2 font-semibold text-gray-500 text-sm w-12">
          #
        </span>
        <div className={"flex flex-grow w-full grid gap-2 grid-cols-8"}>
          {headings.map((val, index) => (
            <span
              className={
                "p-1 py-2 font-semibold text-gray-500 text-sm " +
                (index === 1 || index === 0 ? "col-span-2" : "") +
                " " +
                (index > 1 ? "text-right" : "")
              }
            >
              {val}
            </span>
          ))}
        </div>
      </div>

      <hr />
    </div>
  );
}