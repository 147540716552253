import React, { useState } from "react";

import PeopleOutlined from "@mui/icons-material/PeopleOutlined";
import { Typography, Button } from "@mui/material";

import { alertSuccess, alertError } from "../../actions/AlertActions";
import {
  createContact,
  createLocationContact,
  createSupplierContact,
} from "../../api/Api";
import { deepUpdate } from "../../utils/StateUtils";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import { formContact } from "../common/form/constants";

export default function CreateContact({
  baseData,
  onSubmitCB,
  successCB,
  renderFlexComponent,
  locationId,
  supplierId,
}) {
  const [data, setData] = useState(baseData ?? {});
  const onChange = (update) => {
    const { name, value } = update;
    setData((data) => deepUpdate(name, value, data));
  };
  const onSubmit = (event) => {
    event.preventDefault();
    if (onSubmitCB) {
      console.log("Callback");
      return onSubmitCB(event);
    } else if (locationId) {
      createLocationContact(data, locationId)
        .then((newContactId) => {
          data.contactId
            ? alertSuccess("Successfully Updated Contact")
            : alertSuccess("Successfully Created Contact");
          successCB && successCB();
        })
        .catch((e) => {
          const errorMessage =
            e.readableMessage ?? data.clientId
              ? "Error Updating Contact"
              : "Error Creating Contact";
          alertError(errorMessage);
        });
    } else {
      createSupplierContact(data, supplierId)
        .then((newContactId) => {
          data.contactId
            ? alertSuccess("Successfully Updated Contact")
            : alertSuccess("Successfully Created Contact");
          successCB && successCB();
        })
        .catch((e) => {
          const errorMessage =
            e.readableMessage ?? data.clientId
              ? "Error Updating Contact"
              : "Error Creating Contact";
          alertError(errorMessage);
        });
    }
  };
  return (
    <div className="p-4 max-w-sm">
      <div className="flex gap-2">
        <PeopleOutlined />
        <Typography variant="h5">Contact Details</Typography>
      </div>
      <MaterialForm
        className="px-4 py-2 w-full"
        data={data}
        renderArray={formContact(data)}
        onSubmit={onSubmit}
        onChange={onChange}
      />

      <div className="flex flex-row-reverse items-center justify-between">
        <Button
          color="primary"
          className="float-right p-4 supplier-contact-save"
          onClick={onSubmit}
        >
          Save
        </Button>
        {renderFlexComponent && renderFlexComponent()}
      </div>
    </div>
  );
}
