import React, { useState, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Typography } from "@mui/material";

import { alertError } from "../../actions/AlertActions";
import {
  subscribeTopic,
  unsubscribeTopic,
} from "../../actions/NotificationActions";
import { getAssociateMessages } from "../../api/Api";
import { getAssociate, getContactMessages } from "../../api/Api";
import dispatcher from "../../dispatcher";
import { makePageable } from "../../utils/PageUtils";
import MessagesView from "../clients/MessagesView";

const handleFetchMessages = (apiFetch, data, setMessages, next) => {
  apiFetch(data)
    .then((data) => {
      if (next) {
        setMessages((messages) => {
          return { ...data, content: [...messages.content, ...data.content] };
        });
      } else {
        setMessages(data);
      }
    })
    .catch((_) => alertError("Error Fetching Messages"));
};

const updateMessages = (associateId, setMessages) =>
  getAssociateMessages(associateId, { page: 0, size: 50 })
    .then(setMessages)
    .catch((_) => alertError("Couldn't Fetch Messages"));

export default function AssociateMessages({ associateId, defaultContactId }) {
  const [messages, setMessages] = useState({ content: [] });
  const [associateData, setAssociateData] = useState();
  const [contactData, setContactData] = useState();

  useEffect(() => {
    getAssociate({ associateId: associateId }).then((associate) => {
      setAssociateData(associate);

      const contactObj = associate.contacts.find((item) => {
        if (defaultContactId) {
          if (item.contactId === defaultContactId) {
            return item;
          }
        } else {
          if (item.contactId === associate.primaryContactId) {
            return item;
          }
        }
      });

      let value;
      if (contactObj) {
        value = contactObj;
      } else if (associate.contacts.length > 0) {
        value = associate.contacts[0];
      } else {
        value = {
          contactId: "",
          firstName: "",
          lastName: "",
          phone: "",
        };
      }
      setContactData(value);
    });

    console.log(
      "Subscribing to: /notifications/associate_message/" + associateId
    );
    subscribeTopic({
      topic: "/notifications/associate_message/" + associateId,
    });
    return () =>
      unsubscribeTopic({
        topic: "/notifications/associate_message/" + associateId,
      });
  }, [associateId]);

  useEffect(() => {
    dispatcher.register(handleAction);
    window.dispatcher = dispatcher;
  }, []);

  useEffect(() => {
    if (associateData && contactData) {
      fetchMessages();
    }
  }, [associateData, contactData]);

  const showAppFunc = () => {
    if (associateData.usesApp) {
      if (
        associateData?.type === "THIRD_PARTY_VENDOR" &&
        contactData?.contactId !== associateData?.primaryContactId
      ) {
        return false;
      }
      return true;
    } else return false;
  };

  const fetchMessages = (next = false) => {
    if (associateData) {
      const currentPage = messages.pageable?.pageNumber ?? -1;

      handleFetchMessages(
        getContactMessages,
        makePageable(
          { contactIds: [contactData?.contactId] },
          currentPage,
          next
        ),
        setMessages,
        next
      );
    } else {
      getAssociate({ associateId: associateId }).then((associate) => {
        const currentPage = messages.pageable?.pageNumber ?? -1;

        const contactObj = associate.contacts.find((item) => {
          if (item.contactId === associate.primaryContactId) {
            return item;
          }
        });

        let value;
        if (contactObj) {
          value = contactObj;
        } else if (associate.contacts.length > 0) {
          value = associate.contacts[0];
        } else {
          value = {
            contactId: "",
            firstName: "",
            lastName: "",
            phone: "",
          };
        }

        handleFetchMessages(
          getContactMessages,
          makePageable({ contactIds: [value?.contactId] }, currentPage, next),
          setMessages,
          next
        );
      });
    }
  };

  const handleAction = (action) => {
    if (
      action.actionType === "NOTIFICATION" &&
      action.topic.includes("/associate_message/")
    ) {
      if (!associateData) {
        getAssociate({ associateId: associateId }).then((associate) => {
          setAssociateData(associate);
          const contactObj = associate.contacts.find((item) => {
            if (item.contactId === associate.primaryContactId) {
              return item;
            }
          });

          let value;
          if (contactObj) {
            value = contactObj;
          } else if (associate.contacts.length > 0) {
            value = associate.contacts[0];
          } else {
            value = {
              contactId: "",
              firstName: "",
              lastName: "",
              phone: "",
            };
          }
          setContactData(value);
          fetchMessages();
        });
      } else {
        fetchMessages();
      }
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-col md:flex-row mx-auto">
        <div className="flex-1">
          <div className="flex flex-col md:flex-row  border border-grey rounded shadow-lg md:max-h-lscreen">
            {/* <!-- Left --> */}
            <div className="w-full md:w-3/12 border flex flex-col bg-gray-100 pb-6">
              <div className="flex gap-2 py-2 px-2">
                <Typography variant="h5">Contacts</Typography>
              </div>
              {/* <!-- Contacts --> */}

              {associateData?.contacts.map((contact) => (
                <div
                  key={""}
                  className={
                    "px-3 flex hover:bg-gray-200 cursor-pointer items-center " +
                    (contact.contactId === contactData?.contactId
                      ? "bg-gray-300"
                      : "bg-white")
                  }
                  onClick={(_) => {
                    setContactData(contact);
                  }}
                >
                  <div>
                    <AccountCircleIcon className="h-12 w-full rounded-full" />
                  </div>
                  <div className="ml-2 flex-1 border-b border-grey-lighter py-4">
                    <div className="flex items-bottom justify-between">
                      <button className="text-grey-darkest">
                        {contact?.firstName} {contact?.lastName}
                      </button>
                    </div>
                  </div>
                  {contact.contactId === associateData?.primaryContactId && (
                    <div className="px-4">
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-300 text-blue-800">
                        Primary
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="flex gap-2 py-2 px-2 md:hidden">
              <Typography variant="h5">Messages</Typography>
            </div>
            {associateData && (
              <MessagesView
                title={contactData?.firstName + " " + contactData?.lastName}
                subtext={contactData?.phone ?? ""}
                phone={contactData?.phone}
                messages={messages}
                associateMessaging={true}
                showApp={showAppFunc()}
                contactId={contactData?.contactId}
                updateMessages={(_) => fetchMessages()}
                fetchNextPage={(_) => fetchMessages(true)}
              />
            )}
          </div>
        </div>
      </div>
    </DndProvider>
  );
}
