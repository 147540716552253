// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DatePickerGen from "./DatePicker.gen";

var make = DatePickerGen.make;

export {
  make ,
  
}
/* make Not a pure module */
