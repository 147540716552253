import React, { useEffect, useState } from "react";

import { useRecoilState } from "recoil";

import { alertError } from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import {
  getBuildingClients,
  getPipelines,
  getTicketsCommercial,
  getTicketsForBuilding,
  getTicketsIndividual,
} from "../../api/Api";
import { renderAddress } from "../../utils/StringUtils";
import { SearchIcon } from "../common/AppIcons";
import HeadlessDropdown from "../common/HeadlessDropdown";
import HeadlessFilterSelect from "../common/HeadlessFilterSelect";
import MaterialTable from "../common/MaterialTable";

// import MaterialModal from "../common/MaterialModal";

// import NewCreateTicket from "../pipelines/NewCreateTicket";

// import PopOverClick from "../common/PopOverClick";

const EstimateStatus = {
  NOT_SENT: "Not Sent",
  ACCEPTED: "Accepted",

  UNSENT: "Unsent",
  SENT: "Sent",
  PARTIALLY_ACCEPTED: "Partially Accepted",
  REJECTED: "Rejected",
};

const fetchIndividualTickets = (params, callback) => {
  const { id, searchText, page, size, filterBuildingId, position } = params;
  getTicketsIndividual(id, searchText, page, size, filterBuildingId, position)
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      alertError("Couldn't fetch tickets for the Individual");
    });
};

const fetchCommercialTickets = (params, callback) => {
  const { id, searchText, page, size, filterBuildingId, position } = params;
  getTicketsCommercial(id, searchText, page, size, filterBuildingId, position)
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      alertError("Couldn't fetch tickets for the Commercial Client");
    });
};

// Props:
// id: number | buildingId | clientId | contactId
// type: "INDIVIDUAL" | "COMMERCIAL" | "BUILDING"
// buildings: Building[] | Buildings for Filtering
// refreshCB: () => void | Callback to refresh the list
export default function NewListTickets({
  id,
  unitNumbers = [],
  type = "COMMERCIAL",
  buildings = [],
  refreshCB,
}) {
  const [searchText, setSearchText] = useState("");
  const [pipelines, setPipelines] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [clients, setClients] = useState([]);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalElements, setTotalElements] = useState();

  const [filterBuildingId, setFilterBuildingId] = useState();
  const [filterClientId, setFilterClientId] = useState();
  const [position, setPosition] = useState();

  const [selectedUnitNumber, setSelectedUnitNumber] = useState();

  const [ifLatest, setLatest] = useLatest();

  const { openTicket } = useAppActions();

  const setTicketsCB = (data) => {
    setTotalElements(data.totalElements);
    setTickets(data.content);
  };

  const getData = () => {
    const unitNumberFilter =
      selectedUnitNumber === "Common" ? "BUILDING" : selectedUnitNumber;
    const now = setLatest(Date.now());
    if (type === "INDIVIDUAL") {
      fetchIndividualTickets(
        {
          id,
          searchText,
          page,
          size,
          filterBuildingId,
          position,
        },
        setTicketsCB
      );
    } else if (type === "COMMERCIAL") {
      fetchCommercialTickets(
        {
          id,
          searchText,
          page,
          size,
          filterBuildingId,
          position,
        },
        setTicketsCB
      );
    } else if (type === "BUILDING") {
      if (filterClientId) {
        const [type, filterId] = filterClientId.split(".");
        // filterKey = clientId | contactId
        const filterKey = `${type}Id`;
        getTicketsForBuilding(
          id,
          searchText,
          page,
          size,
          position,
          {
            [filterKey]: parseInt(filterId),
          },
          {
            unitNumber: unitNumberFilter,
          }
        ).then((data) => {
          ifLatest(now, () => setTicketsCB(data));
        });
      } else {
        getTicketsForBuilding(
          id,
          searchText,
          page,
          size,
          position,
          {},
          {
            unitNumber: unitNumberFilter,
          }
        ).then((data) => {
          ifLatest(now, () => setTicketsCB(data));
        });
      }

      getBuildingClients(id).then((data) => {
        ifLatest(now, () => setClients(data));
      });
    }
  };

  useEffect(() => {
    setTickets([]);
    getData();
  }, [
    selectedUnitNumber,
    type,
    id,
    filterClientId,
    filterBuildingId,
    searchText,
    size,
    page,
    position,
  ]);

  useEffect(() => {
    getPipelines().then((pipelines) => {
      setPipelines(pipelines);
    });
  }, []);

  useEffect(() => {
    if (unitNumbers.length > 0) {
      setSelectedUnitNumber(unitNumbers[0]);
    }
  }, [unitNumbers]);

  return (
    <div className="flex flex-col py-4">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="flex flex-row items-center px-2 rounded bg-newGray-100">
          <div>
            <SearchIcon />
          </div>
          <input
            id="search_input"
            className="focus:outline-none p-2 text-gray-700 bg-newGray-100 w-52"
            placeholder="Search in Tickets..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="grid grid-cols-2 md:flex md:flex-row gap-4 items-center mt-3 md:mt-0">
          {/* Unit Number TabFilter */}
          {unitNumbers.filter((unitNumber) => unitNumber).length > 0 &&
            ["Common", ...unitNumbers].map((unitNumber) => {
              return (
                <div
                  className={
                    "px-2 py-1 rounded text-sm cursor-pointer " +
                    (selectedUnitNumber === unitNumber
                      ? "bg-newBlue-500 text-newBlue-400"
                      : "bg-newGray-100 text-newGray-300")
                  }
                  onClick={() => {
                    setSelectedUnitNumber(unitNumber);
                  }}
                >
                  {unitNumber}
                </div>
              );
            })}
        </div>
        <div className="mt-3 md:mt-0">
          {buildings.length > 0 && (
            <div className="group inline-block relative">
              <HeadlessFilterSelect
                placeholder={"Filter"}
                onChange={(value) => {
                  setFilterBuildingId(value);
                }}
                options={buildings.map((building) => ({
                  title: building.shortName ?? "",
                  description: renderAddress(building.address),
                  value: building.buildingId,
                }))}
              />
            </div>
          )}

          {type === "BUILDING" && (
            <div className="group inline-block relative">
              <HeadlessDropdown
                options={
                  clients?.map((client) => {
                    return {
                      label: `${client.clientName}`,
                      value: client.clientId
                        ? `client.${client.clientId}`
                        : `contact.${client.contactId}`,
                    };
                  }) || []
                }
                onChange={(value) => {
                  setFilterClientId(value);
                }}
              >
                <button
                  className={
                    "border border-newGray-1400 text-gray-700 text-sm font-normal py-2 px-4 rounded inline-flex items-center "
                  }
                >
                  <div
                    className={"flex flex-row justify-between items-center "}
                  >
                    <div>
                      <span className="mr-1">
                        {clients?.find((client) => {
                          const [type, id] = filterClientId?.split(".") || [];
                          if (type === "client") {
                            return client.clientId === parseInt(id);
                          }
                          if (type === "contact") {
                            return client.contactId === parseInt(id);
                          }
                          return false;
                        })?.clientName || "Ticket Raised By"}
                      </span>
                    </div>
                    <div>
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </button>
              </HeadlessDropdown>
            </div>
          )}
        </div>
      </div>
      <div className="py-4">
        <MaterialTable
          data={tickets
            ?.filter((ticket) => {
              if (filterClientId) {
                const [type, id] = filterClientId.split(".");
                if (type === "client") {
                  return ticket.createdUnderClientId === parseInt(id);
                }
                if (type === "contact") {
                  return ticket.createdUnderContactId === parseInt(id);
                }
              }
              return true;
            })
            .map((row) => {
              return {
                id: row.ticketId,
                data: [
                  `T${row.ticketId}`,
                  row.title,
                  row.estimatestatus
                    ? EstimateStatus[row.estimatestatus]
                    : "No Estimate",
                  type === "BUILDING" ? row.clientName : renderAddress(row),
                  row.stageName,
                  {
                    render: `${new Date(row.createdAt).toLocaleString()}`,
                    sortBy: new Date(row.createdAt).getTime(),
                  },
                ],
              };
            })}
          head={[
            { id: "id", label: "Ticket ID" },
            { id: "title", label: "Title" },
            { id: "estimateStatus", label: "Estimate Status" },
            type === "BUILDING"
              ? { id: "clientName", label: "Client Name" }
              : { id: "address", label: "Address" },
            { id: "pipeline", label: "Pipeline Stage" },
            { id: "createdAt", label: "Created At" },
          ]}
          totalData={totalElements}
          pageRows={size}
          headRenderColor="text-newGray-300"
          defaultOrderBy={"createdAt"}
          defaultOrder="desc"
          onClickCB={(ticketId) => {
            const ticketData = tickets.find(
              (ticket) => ticket.ticketId === ticketId
            );
            openTicket(ticketData.ticketId);
          }}
          currentPage={page}
          paginationCB={(page) => {
            setPage(page);
          }}
          rowChangeCB={(row) => {
            setSize(row);
          }}
        />
      </div>
    </div>
  );
}

// TODO: Move this to hooks and implement in other relevant places
function useLatest() {
  const [latest, setLatest] = useState(0);
  const ifLatest = (now, callback) => {
    setLatest((latest) => {
      if (now === latest) {
        callback();
        return latest;
      }
      // if (now < latest) {
      //   console.log("Ignoring ", now, " as it is older than ", latest);
      // } else {
      //   console.log("Ignoring ", now, " as it is newer than ", latest);
      // }
      return latest;
    });
  };
  return [
    ifLatest,
    (now) => {
      /*console.log("Setting Latest to ", now);*/ setLatest(now);
      return now;
    },
  ];
}
