import { useEffect, useState } from "react";

import { alertSuccess } from "../../../../../actions/AlertActions";
import { getProspectById, updateProspect } from "../../../../../api/Api";
import { AddIconBlue } from "../../../../../components/common/AppIcons";
import MaterialForm from "../../../../../components/common/form/MaterialForm";

type MiscellaneousTabProps = {
  prospectId: number;
  setCurrentApplicationTab: React.Dispatch<React.SetStateAction<string>>;
  setDetailsTab: React.Dispatch<React.SetStateAction<string>>;
};

export function MiscellaneousTab({
  prospectId,
  setCurrentApplicationTab,
  setDetailsTab,
}: MiscellaneousTabProps) {
  const [vehicleDetails, setVehicleDetails] = useState([
    {
      make: "",
      model: "",
      licensePlate: "",
      state: "",
    },
  ]);
  const [petsDetails, setPetDetails] = useState([
    {
      type: "",
      breed: "",
      weight: "",
      name: "",
    },
  ]);

  useEffect(() => {
    getProspectById(prospectId).then((data) => {
      if (data.miscellaneous !== null) {
        if (data.miscellaneous.vehicles !== null)
          setVehicleDetails(data.miscellaneous.vehicles);
        if (data.miscellaneous.pets !== null)
          setPetDetails(data.miscellaneous.pets);
      }
    });
  }, [prospectId]);

  const onChangeVehicle = (
    update: { name: string; value: any },
    index: number
  ) => {
    const { name, value } = update;
    const test = [...vehicleDetails];
    test[index][name] = value;

    setVehicleDetails(test);
  };
  const onChangePets = (
    update: { name: string; value: any },
    index: number
  ) => {
    const { name, value } = update;
    const test = [...petsDetails];
    test[index][name] = value;

    setPetDetails(test);
  };

  const onSave = () => {
    updateProspect(prospectId, [
      {
        op: "replace",
        path: "/miscellaneous",
        value: {
          vehicles: vehicleDetails,
          pets: petsDetails,
        },
      },
    ]).then(() => {
      setDetailsTab("declaration");
      alertSuccess("Prospect updated Successfully");
    });
  };
  return (
    <div className="w-full md:p-4 ">
      <div className="flex flex-col items-start self-center ">
        <div className="text-xl font-semibold text-gray-900 my-4">
          Vehicle Information
        </div>
        <div className="w-full border-b-2 border-newGray-700">
          {vehicleDetails.map((item, index) => (
            <MaterialForm
              className={`w-full grid grid-flow-row-dense grid-rows-1 grid-cols-1 md:grid-cols-4 my-2 ${
                vehicleDetails.length === index + 1
                  ? ""
                  : "border-b-2 border-newGray-700"
              }`}
              data={item}
              onChange={(update: { name: string; value: any }) =>
                onChangeVehicle(update, index)
              }
              fieldClassName="px-2 my-2"
              renderArray={[
                {
                  name: "make",
                  variant: "OUTLINED",
                },
                {
                  name: "model",
                  variant: "OUTLINED",
                },

                {
                  name: "licensePlate",
                  variant: "OUTLINED",
                },
                {
                  name: "state",

                  variant: "OUTLINED",
                },
              ]}
            />
          ))}
          <button
            className="border w-max h-8 rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400 ml-2 my-4"
            onClick={() =>
              setVehicleDetails([
                ...vehicleDetails,
                {
                  make: "",
                  model: "",
                  licensePlate: "",
                  state: "",
                },
              ])
            }
          >
            <div className="flex flex-row items-center ">
              <div>
                <AddIconBlue className="h-4 w-4" />
              </div>
              <div className="px-2 flex">
                <div className="font-medium">Add Another Vehicle</div>
              </div>
            </div>
          </button>
        </div>
        <div className="w-full">
          <div className="text-xl font-semibold text-gray-900 my-4">
            Pet Information
          </div>
          {petsDetails.map((item, index) => (
            <MaterialForm
              className={`w-full grid grid-flow-row-dense grid-rows-1 grid-cols-1 md:grid-cols-4 mt-3 ${
                petsDetails.length === index + 1
                  ? ""
                  : "border-b-2 border-newGray-700"
              } `}
              data={item}
              onChange={(update: { name: string; value: any }) =>
                onChangePets(update, index)
              }
              fieldClassName="px-2 my-2"
              renderArray={[
                {
                  name: "type",
                  variant: "OUTLINED",
                },
                {
                  name: "breed",
                  variant: "OUTLINED",
                },
                {
                  name: "weight",
                  inputType: "NUMBER",
                  variant: "OUTLINED",
                },
                {
                  name: "name",
                  variant: "OUTLINED",
                },
              ]}
            />
          ))}
          <button
            className="border w-max h-8 rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400 ml-2 my-4"
            onClick={() =>
              setPetDetails([
                ...petsDetails,
                {
                  type: "",
                  breed: "",
                  weight: "",
                  name: "",
                },
              ])
            }
          >
            <div className="flex flex-row items-center ">
              <div>
                <AddIconBlue className="h-4 w-4" />
              </div>
              <div className="px-2 flex">
                <div className="font-medium">Add Another Pet</div>
              </div>
            </div>
          </button>
        </div>
        <div className="flex flex-row self-end mt-4 mr-4 ">
          <button
            className="text-base flex justify-center items-center px-4 py-2 rounded  font-medium cursor-pointer 
            bg-gray-300
            text-gray-800 duration-200 ease-in-out 
            transition"
            onClick={() => setCurrentApplicationTab("applicationListing")}
          >
            Cancel
          </button>
          <button
            className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
            bg-newBlue-400  w-40
            text-white border-newBlue-400
            border"
            onClick={onSave}
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
}
