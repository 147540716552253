import React, { useEffect } from "react";

import makeStyles from "@mui/styles/makeStyles";
import { navigate, usePath, useRoutes } from "raviger";

import CreateAssociate from "../components/associates/CreateAssociate";
import ListAssociates from "../components/associates/ListAssociates";
import NewBuildingList from "../components/buildings/NewBuildingList";
// import ClientHomeOwner from "../components/clients/OWNER/ClientHomeOWNER";
import Dashboard from "../components/dashboard/Dashboard";
import StripePaymentDetails from "../components/pipelines/StripePaymentDetails";
// import GanttView from "../components/scheduler/GanttView";
import NewGanttView from "../components/scheduler/NewGanttView";
import ServiceRequestScreen from "../components/service/ServiceRequestScreen";
import Users from "../components/settings/SettingsPage";
import CreateSupplier from "../components/suppliers/CreateSupplier";
import ListSupplier from "../components/suppliers/ListSupplier";
import ListVendors from "../components/vendors/ListVendors";
// import CRMHome from "../components/crm/CRMHome";
import CRMHomePage from "../pages/CRMHomePage";
import Invoice from "../pages/Invoice";
import Kanban from "../pages/KanbanScreen";
import TodoPage from "../pages/TodoPage";
import Associates from "../pages/associates/AssociatesHome";
import BuildingHomePage from "../pages/building/BuildingHomePage";
// import ListClients from "../components/clients/ListClients";
import ClientHomePage from "../pages/client/ClientHomePage";
import NewListClients from "../pages/client/ClientsPage";
import CreateClientPage from "../pages/client/CreateClientPage";
import NotificationsScreen from "../pages/notifications/NotificationsScreen";
import A2PComplianceForm from "../pages/phoneNumbers/A2PComplianceForm";
import BuyPhoneNumber from "../pages/phoneNumbers/BuyPhoneNumber";
import SuppliersHome from "../pages/suppliers/SuppliersHome";
import CreateVendor from "../pages/vendors/CreateVendor";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const usePageSync = () => {
  const currentPath = usePath();
  useEffect(() => {
    console.log("currentPath Changed", currentPath);
    localStorage.setItem("currentPath", currentPath);
  }, [currentPath]);

  useEffect(() => {
    // When currentPath in localStorage changes, navigate to that path
    window.addEventListener("storage", (e) => {
      console.log("Storage Event", e);
      if (currentPath !== localStorage.getItem("currentPath")) {
        console.log("Navigating to", localStorage.getItem("currentPath"));
        navigate(localStorage.getItem("currentPath"));
      }
    });
  }, []);
};

export default function AppRouter({ isHelpSquad }) {
  const classes = useStyles();
  let routes = {
    // "/clients": () => <ListClients />,
    "/clients": () => <NewListClients />,
    "/buildings": () => <NewBuildingList />,

    "/suppliers": () => <ListSupplier />,
    "/invoice": () => <Invoice />,
    "/stripe/payment-details/:paymentId": ({ paymentId }) => (
      <StripePaymentDetails paymentId={paymentId} />
    ),

    "/sales": () => <Kanban />,
    "/todo": () => <TodoPage />,
    "/sales/:ticketId": ({ ticketId }) => <Kanban ticketId={ticketId} />,
    "/sales/:ticketId/invoice/:invoiceId": ({ ticketId, invoiceId }) => (
      <Kanban ticketId={ticketId} showEstimate={true} invoiceId={invoiceId} />
    ),
    "/sales/:ticketId/estimate": ({ ticketId }) => (
      <Kanban ticketId={ticketId} showEstimate={true} />
    ),
    "/crm": () => <CRMHomePage />,

    "/commercial/:id/:tab": (pathParams) => (
      <ClientHomePage
        clientId={pathParams.id}
        tab={pathParams.tab}
        type={"COMMERCIAL"}
      />
    ),
    "/commercial/:id/:tab/:selectedId": (pathParams) => (
      <ClientHomePage
        clientId={pathParams.id}
        tab={pathParams.tab}
        selectedId={pathParams.selectedId}
        type={"COMMERCIAL"}
      />
    ),

    "/individual/:id/:tab/:selectedId": (pathParams) => (
      <ClientHomePage
        type={"INDIVIDUAL"}
        clientId={pathParams.id}
        tab={pathParams.tab}
        selectedId={pathParams.selectedId}
      />
    ),
    "/individual/:id/:tab": (pathParams) => (
      <ClientHomePage
        type={"INDIVIDUAL"}
        clientId={pathParams.id}
        tab={pathParams.tab}
        selectedId={pathParams.selectedId}
      />
    ),
    "/building/:id/:tab": (pathParams) => (
      <BuildingHomePage buildingId={pathParams.id} tab={pathParams.tab} />
    ),
    "/building/:id/:tab/:selectedId": (pathParams) => (
      <BuildingHomePage
        buildingId={pathParams.id}
        tab={pathParams.tab}
        selectedId={pathParams.selectedId}
      />
    ),
    "/building/:id/:tab/:selectedId/:unitTab/:leaseId/:leaseTab": (
      pathParams
    ) => (
      <BuildingHomePage
        buildingId={pathParams.id}
        tab={pathParams.tab}
        selectedId={pathParams.selectedId}
        unitTab={pathParams.unitTab}
        leaseId={pathParams.leaseId}
        leaseTab={pathParams.leaseTab}
      />
    ),
    "/suppliers/new": () => <CreateSupplier />,

    "/suppliers/:id/:tab": (pathParams) => (
      <SuppliersHome supplierId={pathParams.id} tab={pathParams.tab} />
    ),
    "/suppliers/:id/:tab/:selectedId": (pathParams) => (
      <SuppliersHome
        supplierId={pathParams.id}
        tab={pathParams.tab}
        selectedId={pathParams.selectedId}
      />
    ),
    "/settings": () => <Users />,
    "/settings/buy_new_number": () => <BuyPhoneNumber />,
    "/settings/:tab": (pathParams) => <Users tab={pathParams.tab} />,
    "/settings/:tab/:vendorId": (pathParams) => (
      <Users tab={pathParams.tab} vendorId={pathParams.vendorId} />
    ),
    "/scheduler": () => <NewGanttView />,
    "/scheduler/:date": (pathParams) => <NewGanttView from={pathParams.date} />,
    "/service_requests": () => <ServiceRequestScreen />,
    "/service_requests/:id": (pathParams) => (
      <ServiceRequestScreen srId={pathParams.id} />
    ),

    "/notifications": () => <NotificationsScreen />,

    "/vendors": () => <ListVendors />,

    "/vendor/new": () => <CreateVendor />,
    "/phone_number/compliance": () => <A2PComplianceForm />,

    "/associates": () => <ListAssociates />,

    "/associates/new": () => <CreateAssociate />,
    "/associates/:id/:page": (pathParams) => (
      <Associates associateId={pathParams.id} page={pathParams.page} />
    ),
    "/associates/:id/messages/:defaultContactId": (pathParams) => (
      <Associates
        associateId={pathParams.id}
        page={"messages"}
        defaultContactId={pathParams.defaultContactId}
      />
    ),
    "/clients/new": (pathParams) => <CreateClientPage />,
  };
  if (isHelpSquad) {
    routes = { "/": () => <Dashboard />, ...routes, "*": () => <Dashboard /> };
  } else {
    routes = { ...routes, "*": () => <CRMHomePage /> };
  }

  // usePageSync();

  const routeResult = useRoutes(routes);
  if (routeResult) return routeResult;
  else navigate("/");
  return <div />;
}
