import { useEffect, useState } from "react";

import { alertError } from "../../actions/AlertActions";
import { getMyVendor } from "../../api/Api";
import { AddIconBlue } from "../../components/common/AppIcons";
import MaterialModal from "../../components/common/MaterialModal";
import MaterialTable from "../../components/common/MaterialTable";
import { properString } from "../../utils/StringUtils";
import EditSkills from "./EditSkills";

export type Skill = {
  skill: string;
  subCategories: string[];
};

export default function ListSkills() {
  const [skillData, setSkillData] = useState<Skill[]>([]);

  const [edit, setEdit] = useState({
    display: false,
    skill: "",
    vendorId: 0,
    isEdit: false,
  });

  useEffect(() => {
    getMyVendor()
      .then((data) => {
        setSkillData(data.categories as Skill[]);
        setEdit({
          display: false,
          skill: "",
          vendorId: data.id,
          isEdit: false,
        });
      })
      .catch(() => alertError("Error while fetching vendor"));
  }, []);

  const getTableHead = () => {
    return [
      { id: "skill", label: "Category" },
      { id: "subCategories", label: "Sub Categories" },
    ];
  };

  const getSubCategories = (subCategories: string[]) => {
    const properCategories = subCategories.map((item) => properString(item));

    return properCategories.join(", ");
  };

  const getTableData = () => {
    let data = [];
    data = skillData?.map((item: Skill) => {
      return {
        id: item.skill,
        data: [properString(item.skill), getSubCategories(item.subCategories)],
      };
    });
    return data;
  };

  const getSelectedSkill = () => {
    return skillData.find((item) => item.skill === edit.skill);
  };
  return (
    <div className=" flex  flex-col justify-between items-center pt-6 px-4 h-fit">
      <button
        className=" self-end border rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400"
        onClick={() => {
          setEdit({
            display: true,
            skill: "",
            vendorId: edit.vendorId,
            isEdit: false,
          });
        }}
      >
        <div className="flex flex-row items-center w-36">
          <div>
            <AddIconBlue className="h-4 w-4" />
          </div>
          <div className="px-2 flex">
            <div className="font-medium">Add New Category</div>
          </div>
        </div>
      </button>
      <div className=" py-4 w-full h-min">
        <div className="border-t ">
          <MaterialTable
            data={getTableData()}
            head={getTableHead()}
            headRenderColor="text-newGray-300"
            defaultOrder="desc"
            onClickCB={(selection: number) => {
              setEdit({
                display: true,
                skill: selection.toString(),
                vendorId: edit.vendorId,
                isEdit: true,
              });
            }}
            loading={undefined}
            defaultOrderBy={undefined}
            multiSelect={undefined}
            setSelected={undefined}
            currentPage={undefined}
            totalData={undefined}
            searchText={undefined}
            paginationCB={undefined}
            rowChangeCB={undefined}
          />
        </div>
        <MaterialModal
          open={edit.display}
          setOpen={() => {
            setEdit({
              display: false,
              skill: "",
              vendorId: edit.vendorId,
              isEdit: false,
            });
            getMyVendor().then((data) => {
              setSkillData(data.categories as Skill[]);
            });
          }}
        >
          <EditSkills
            setEdit={setEdit}
            skills={skillData}
            vendorId={edit.vendorId}
            selectedSkill={
              getSelectedSkill() ?? { skill: "", subCategories: [] }
            }
            isEdit={edit.isEdit}
            onSuccess={() =>
              getMyVendor().then((data) => {
                setSkillData(data.categories as Skill[]);
              })
            }
          />
        </MaterialModal>
      </div>
    </div>
  );
}
