import React, { useEffect, useState } from "react";

import BusinessIcon from "@mui/icons-material/Business";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LaunchIcon from "@mui/icons-material/Launch";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { Button, Typography } from "@mui/material";
import { navigate } from "raviger";

import {
  alertError,
  alertInfo,
  alertSuccess,
} from "../../actions/AlertActions";
import {
  createSR,
  createSupplier,
  createTicket,
  getTerritory,
} from "../../api/Api";
import states from "../../assets/states";
import { deepUpdate } from "../../utils/StateUtils";
import { lowerCase, properString } from "../../utils/StringUtils";
// import { territories } from "../common/form/constants";
// import { useIndexedDB } from "react-indexed-db";
import { useIndexedDB } from "../../utils/indexed-db/indexed-hooks";
import { primaryContact } from "../clients/CreateBuilding";
import MaterialModal from "../common/MaterialModal";
import MaterialTable from "../common/MaterialTable";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import { supplierSubCategory } from "../common/form/constants";
import { getTerritoryFromZIP } from "../crm/chat/zip";

const formCommercial = (contacts, subAddressOptions) => {
  let basic = [
    {
      name: "commercialClient.billingAddress",
      inputType: "ADDRESS",
      variant: "STANDARD",
    },

    {
      name: "typeOfSupplies",
      inputType: "SELECT",
      variant: "MULTISELECT",
      defaultValue: [],
      options: supplierSubCategory,
    },
  ];
  if (contacts) {
    return [
      { name: "name" },
      {
        name: "primaryContactId",
        inputType: "SELECT",
        options: primaryContact(contacts),
        defaultValue: primaryContact(contacts)[0]?.value,
      },
      ...basic,
    ];
  } else {
    return [{ name: "name" }, ...basic];
  }
};

const formBuilding = (subAddressOptions, territories) => {
  return [
    {
      name: "building.address",
      inputType: "ADDRESS",
    },
    {
      name: "building.territory",
      inputType: "SELECT",
      options: territories,
    },
  ];
};

const formContact = [
  { name: "contact.firstName" },
  { name: "contact.lastName" },
  { name: "contact.phone", inputType: "PHONE" },
  { name: "contact.email", inputType: "EMAIL" },
];

export default function CreateSupplier({
  containerClass,
  supplierData,
  onSubmitCB,
  setShow,
  successCB,
  onSuccess,
  contacts,
}) {
  const { add, getAll, deleteRecord } = useIndexedDB("client");
  const [data, setData] = useState(supplierData ?? {});
  const [recoverDraft, setRecoverDraft] = useState(false);
  const [recovered, setRecovered] = useState([]);
  const [serviceRequest, setServiceRequest] = useState({});
  const [formCommercialAutofill, setFormRender] = useState([]);
  const [subAddressOptions, setSubAddressOptions] = useState({
    building: [],
    commercial: [],
  });

  const [territories, setTerritories] = useState([]);

  useEffect(() => {
    setFormRender(
      formCommercial(contacts, subAddressOptions)
      // : formCommercial("", subAddressOptions)
    );
  }, [data.type, contacts, subAddressOptions]);

  useEffect(() => {
    setData(supplierData ?? {});
  }, [supplierData]);

  useEffect(() => {
    onChange({ value: "CA", name: "building.address.state" });

    getTerritory().then((res) => {
      let temp = [];
      res.map((item) => {
        temp.push({ value: item.name, label: properString(item.name) });
      });
      setTerritories(temp);
    });
  }, []);

  const getDrafts = () => {
    getAll().then((draft) => {
      setRecovered(draft);
    });
  };

  useEffect(() => {
    getDrafts();
    return () => {
      setData((data) => {
        const toSave = JSON.stringify(data);
        data.supplierId === undefined &&
          toSave.length > 50 &&
          add({ form: toSave }).then(
            (event) => {
              alertInfo(
                "Saved Supplier Details " + JSON.stringify(data).length
              );
            },
            (error) => {
              alertError(
                error.readableMessage ?? "Couldn't Save Supplier Details"
              );
            }
          );
        return {};
      });
    };
  }, []);

  const onCreate = (supplierId, buildingId) => {
    navigate(`/suppliers/${supplierId}/summary/${buildingId}`);
  };

  const createServiceRequest = (supplierId, buildingId) => {
    alertInfo("Saving Service Request");
    serviceRequest.confirmed === "true"
      ? createSR({
          ...serviceRequest,
          bookingStatus: serviceRequest.bookingStatus ?? "CREATED",
          building: { buildingId },
        })
          .then(() => onCreate(supplierId, buildingId))
          .catch(() => alertError("Error Saving Service Request"))
      : createTicket({
          ...serviceRequest,
          supplierId: supplierId,
          pipelineId: 1,
        })
          .then((_) => onCreate(supplierId, buildingId))
          .catch((_) => alertError("Error Creating Ticket"));
  };

  const recoverClient = (index) => {
    // console.log(recovered[index]);
    setData({ ...JSON.parse(recovered[index].form), recovered: true });
    deleteRecord(recovered[index].id);
    setRecoverDraft(false);
  };

  const onChange = (update) => {
    if (update.name === "building.address.streetAddress1") {
      setSubAddressOptions((current) => {
        return {
          ...current,
          building: update.subOptions || [],
        };
      });
    }
    if (update.name === "commercialClient.billingAddress.streetAddress1") {
      setSubAddressOptions((current) => {
        return {
          ...current,
          commercial: update.subOptions || [],
        };
      });
    }

    let { name, value } = update;

    if (name === "contact.email") {
      value = lowerCase(value);
    }

    if (name === "building.address.zip") {
      let territory = getTerritoryFromZIP(value);
      setData((data) => deepUpdate("building.territory", territory, data));
    }

    setData((data) => deepUpdate(name, value, data));
  };

  const onChangeSR = (update) => {
    const { name, value } = update;
    if (name === "jobType" && value === "HANDYMAN" && data.subCategory !== "") {
      setServiceRequest((data) => deepUpdate("subCategory", "", data));
    }
    setServiceRequest((data) => deepUpdate(name, value, data));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (onSubmitCB) {
      console.log("Callback");
      return onSubmitCB(event);
    } else {
      createSupplier({
        ...data,
        name: data.name,

        buildings: [
          {
            ...data.building,
            contacts: [data.contact],
          },
        ],
      })
        .then((response) => {
          const { supplierId, buildingIds, contactId } = response;
          const buildingId =
            (buildingIds && buildingIds.length > 0 && buildingIds[0]) || "";
          if (data.supplierId) {
            alertSuccess("Successfully updated Supplier");
            successCB && successCB();
          } else {
            if (onSuccess)
              onSuccess({
                contactId: contactId,
                supplierId: supplierId,
              });
            setData({ ...data, supplierId: supplierId });
            alertSuccess("Successfully created Supplier");
            if (serviceRequest.jobType || serviceRequest.description)
              createServiceRequest(supplierId, buildingId);
            else onCreate(supplierId, buildingId);
          }
        })
        .catch((e) => {
          const errorMessage =
            e.readableMessage ??
            (data.supplierId
              ? "Error Updating Supplier"
              : "Error Creating Supplier");
          alertError(errorMessage);
        });
    }
  };

  const contactForm = (
    <div className="p-4 max-w-xs w-full">
      {/* {data.type === "COMMERCIAL" && ( */}
      <div className="flex gap-2">
        <BusinessIcon />
        <Typography variant="h5">Location Contacts</Typography>
      </div>
      {/* )} */}
      <MaterialForm
        data={data}
        renderArray={formContact}
        onSubmit={onSubmit}
        onChange={onChange}
      />
    </div>
  );

  return (
    <div
      className={containerClass ?? "max-w-6xl flex flex-col mx-auto bg-white"}
    >
      {data.supplierId === undefined &&
        recovered.length > 0 &&
        data.recovered !== true && (
          <div
            className="mx-auto p-1 mt-4 bg-gray-400 cursor-pointer text-sm rounded-lg text-white"
            onClick={(_) => setRecoverDraft(true)}
          >
            <LaunchIcon /> You have {recovered.length} unsaved clients. Click
            here to recover and save them.
          </div>
        )}
      <div className={"flex lg:flex-row flex-col"}>
        <div className="p-4 max-w-xs w-full">
          <div className="flex gap-2">
            <PeopleAltIcon />
            <Typography variant="h5">Supplier Details</Typography>
          </div>
          <MaterialForm
            data={data}
            renderArray={formCommercialAutofill}
            onSubmit={onSubmit}
            onChange={onChange}
          />
        </div>
        {!data.supplierId && (
          <div className="p-4 max-w-xs w-full">
            <div className="flex gap-2">
              <BusinessIcon />
              <Typography variant="h5">
                {data.type === "RESIDENTIAL" && "Residential "}Location
              </Typography>
            </div>
            <MaterialForm
              data={data}
              renderArray={formBuilding(subAddressOptions, territories)}
              onSubmit={onSubmit}
              onChange={onChange}
            />
          </div>
        )}
        {!data.supplierId && contactForm}
      </div>

      {supplierData !== data && (
        <div className="w-full">
          <Button
            color="primary"
            className="float-left lg:float-right p-4 create-supplier-button"
            onClick={onSubmit}
          >
            Save
          </Button>
          <Button
            className="float-left lg:float-right p-4"
            onClick={(_) => setData(supplierData ?? {})}
          >
            Cancel
          </Button>
        </div>
      )}
      {recoverDraft && (
        <MaterialModal
          open={recoverDraft}
          setOpen={(_) => setRecoverDraft(false)}
          label="new-user-modal"
          describedby="create-new-user"
        >
          <MaterialTable
            data={
              recovered?.map((draft, index) => {
                if (draft.form) {
                  const recoveredData = JSON.parse(draft.form);
                  return {
                    id: index,
                    data: [
                      `${index}`,
                      `${
                        recoveredData.clientName ??
                        `${recoveredData.contact?.firstName ?? "-"} ${
                          recoveredData.contact?.lastName ?? "-"
                        }`
                      }`,
                      <button
                        className="flex items-center hover:bg-blue-400 bg-dark text-white p-2 rounded-lg"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteRecord(draft.id).then(() => getDrafts());
                          setRecoverDraft(false);
                        }}
                      >
                        <DeleteForeverIcon /> Remove
                      </button>,
                    ],
                  };
                } else
                  return {
                    id: index,
                    data: [
                      `${index}`,
                      `Corrupt Data`,
                      <button
                        className="flex items-center hover:bg-blue-400 bg-dark text-white p-2 rounded-lg"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteRecord(draft.id).then(() => getDrafts());
                          setRecoverDraft(false);
                        }}
                      >
                        <DeleteForeverIcon /> Remove
                      </button>,
                    ],
                  };
              }) || []
            }
            head={[
              { id: "id", label: "ID" },
              { id: "clientName", label: "Client Name" },
              { id: "deleteIcon", label: "" },
            ]}
            defaultOrderBy={"id"}
            onClickCB={recoverClient}
            searchText={""}
            pageRows={5}
          />
        </MaterialModal>
      )}
    </div>
  );
}
