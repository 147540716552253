import { Checkbox, FormControlLabel, Switch } from "@mui/material";

import { deepFind } from "../../../../utils/StateUtils";

export default function Number({
  variant,
  data,
  name,
  label,
  onChange,
  optional,
  className,
  inputType,
}) {
  const value = deepFind(name, data) || "";

  return (
    <FormControlLabel
      className={className}
      control={
        inputType === "CHECKBOX" ? (
          <Checkbox
            checked={value}
            onChange={(e) => {
              onChange({ name, value: e.target.checked });
            }}
            name={name}
            color="primary"
          />
        ) : (
          <Switch
            checked={value}
            onChange={(e) => {
              onChange({ name, value: e.target.checked });
            }}
            name={name}
            color="primary"
          />
        )
      }
      label={label + (optional ? " (Optional)" : "")}
    />
  );
}
