import { useEffect, useState } from "react";



import { navigate } from "raviger";

import { alertError } from "../../actions/AlertActions";
import {
  adminFileUpload,
  createAdmin,
  createTerritory,
  createVendor,
} from "../../api/Api";
import {
  ContractorIcon,
  ImagePlaceholder,
  PmcIcon,
  Tick,
} from "../../components/common/AppIcons";
import { reverseString } from "../../utils/StringUtils";
import { timeZones } from "../../utils/constants";
import AddCategory from "./AddCategory";
import AddZipCodes from "./AddZipCodes";
import AdminSetup from "./AdminSetup";
import StepperComponent from "./StepperComponent";
import VendorDetails from "./VendorDetails";

export type VendorSkills = {
  skill: string;
  subCategories: string[];
};

export type Category = {
  skill: string;
  subCategory: string;
  subCategories: string[];
};

export enum SubscriberType {
  contractor = "CONTRACTOR",
  pmc = "PMC",
}

export type Territory = {
  territory: string;
  timeZone: string;
  zipCode: string;
  zipCodes: string[];
};

const uploadEncodedFile = (
  callback: (arg0: {
    fileName: any;
    contentType: any;
    encodedFile: any;
  }) => void,
  file: { name: any; type: any }
) => {
  return function (upload: { target: { result: any } }) {
    callback({
      fileName: file.name,
      contentType: file.type,
      encodedFile: upload.target.result,
    });
  };
};

const STAGE_SUBSCRIBER_DETAILS = 1;
const STAGE_ADD_CATEGORY = 2;
const STAGE_ADD_ZIPCODES = 3;
const STAGE_ADMIN_SETUP = 4;

// Refactoring Stages from boolean keys to an array of objects
type WizardStage = {
  name: "Subscriber Details" | "Add Category" | "Add Zipcodes" | "Admin Setup";
  index: number;
  // Whether the stage should be displayed or not
  display: boolean;
  // Whether the stage is active or has been completed
  active: boolean;
  // Whether the stage has been saved in the backend
  saved: boolean;
};

export type VendorStages = WizardStage[];

const initialStages: WizardStage[] = [
  {
    name: "Subscriber Details",
    index: STAGE_SUBSCRIBER_DETAILS,
    display: true,
    active: true,
    saved: false,
  },
  {
    name: "Add Category",
    index: STAGE_ADD_CATEGORY,
    display: true,
    active: false,
    saved: false,
  },
  {
    name: "Add Zipcodes",
    index: STAGE_ADD_ZIPCODES,
    display: true,
    active: false,
    saved: false,
  },
  {
    name: "Admin Setup",
    index: STAGE_ADMIN_SETUP,
    display: true,
    active: false,
    saved: false,
  },
];

const nextStage = (stages: WizardStage[], setStages: any) => {
  setStages((stages: WizardStage[]) => {
    const currentStage = stages
      .slice()
      .reverse()
      .find((stage) => stage.active) as WizardStage;
    const nextStage = stages.find(
      (stage) => stage.index > currentStage.index && stage.display
    );
    return stages.map((stage) => {
      if (stage.index === nextStage?.index) {
        return { ...stage, active: true };
      }
      return stage;
    });
  });
};

const prevStage = (stages: WizardStage[], setStages: any) => {
  setStages((stages: WizardStage[]) => {
    const currentStage = stages
      .slice()
      .reverse()
      .find((stage) => stage.active) as WizardStage;
    const prevEntry = stages
      .slice()
      .reverse()
      .find((stage) => {
        if (stage.index < currentStage.index && stage.display) return true;
        else {
          console.log(
            "prevStageLookup",
            stage.index,
            currentStage.index,
            stage.display
          );
          return false;
        }
      }) as WizardStage;
    const prevStage = stages.find(
      (stage) => stage.index === prevEntry.index
    ) as WizardStage;

    return stages.map((stage) => {
      if (stage.index === currentStage.index) {
        return { ...stage, active: false };
      }
      if (stage.index === prevStage.index) {
        return { ...stage, active: true };
      }
      return stage;
    });
  });
};

const disableContractorStages = (
  setStages: React.Dispatch<React.SetStateAction<WizardStage[]>>
) => {
  setStages((stages) =>
    stages.map((stage) => {
      if (
        stage.index === STAGE_ADD_CATEGORY ||
        stage.index === STAGE_ADD_ZIPCODES
      ) {
        return { ...stage, display: false };
      }
      return stage;
    })
  );
};

const enableContractorStages = (
  setStages: React.Dispatch<React.SetStateAction<WizardStage[]>>
) => {
  setStages((stages: WizardStage[]) =>
    stages.map((stage: WizardStage) => {
      if (
        stage.index === STAGE_ADD_CATEGORY ||
        stage.index === STAGE_ADD_ZIPCODES
      ) {
        return { ...stage, display: true };
      }
      return stage;
    })
  );
};

export default function CreateVendor() {
  const [stages, setStages] = useState(() => {
    console.log("Initializing Stages with ", initialStages);
    return initialStages.slice();
  });

  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("CONTRACTOR");

  const [vendorDetails, setVendorDetails] = useState({
    address: {
      city: "",
      geocodeLatitude: "",
      geocodeLongitude: "",
      melissaBaseMak: "",
      state: "",
      streetAddress1: "",
      zip: "",
    },
    name: "",
    primaryContactNumber: "",
    supportEmail: "",
    contractorLicenseNumber: "",
    contractorLicenseExpiry: "",
    territories: "",
    licenseDetails: "",
    invoiceTerms: "",
    estimateTerms: "",
    invoiceTermsAndConditions: "",
    estimateTermsAndConditions: "",
  });

  const [urls, SetUrls] = useState({
    logoUrl: "",
    iconUrl: "",
    logoKey: 0,
    iconKey: 0,
  });

  const [category, setCategory] = useState<Category[]>([
    { skill: "", subCategories: [], subCategory: "" },
  ]);

  const [territory, setTerritory] = useState<Territory[]>([
    { territory: "", timeZone: timeZones[0].value, zipCode: "", zipCodes: [] },
  ]);

  const [adminDetails, setAdminDetails] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    emailAddress: "",
    password: "",
  });

  const [vendorId, setVendorId] = useState(0);

  useEffect(() => {
    console.log("Initial Stages Changed", initialStages);
  }, [initialStages]);

  useEffect(() => {
    // Disable the Category and Zipcode stages for Contractor
    if (type === SubscriberType.contractor) {
      enableContractorStages(setStages);
    } else {
      disableContractorStages(setStages);
    }
  }, [type]);

  //   useEffect(() => {
  //     return () => {
  //       setStages(initialStages);
  //     };
  //   }, []);

  const uploadLogo = (data) =>
    adminFileUpload(data)
      .then((res) => {
        console.log("Success!", res.value);
        SetUrls({ ...urls, logoUrl: res.value });
      })
      .catch((failure) => {
        console.log("Failure", failure);
      });

  const uploadIcon = (data) =>
    adminFileUpload(data)
      .then((res) => {
        console.log("Success!", res.value);
        SetUrls({ ...urls, iconUrl: res.value });
      })
      .catch((failure) => {
        console.log("Failure", failure);
      });

  const handleSelectLogo = (event: { target: { files: any } }) => {
    let files = event.target.files;
    Array.from(files).forEach((file) => {
      console.log("MIME:" + file.type);
      let reader = new FileReader();
      reader.onload = uploadEncodedFile(uploadLogo, file);
      reader.readAsDataURL(file);
    });
  };

  const handleSelectIcon = (event: { target: { files: any } }) => {
    let files = event.target.files;
    Array.from(files).forEach((file) => {
      console.log("MIME:" + file.type);
      let reader = new FileReader();
      reader.onload = uploadEncodedFile(uploadIcon, file);
      reader.readAsDataURL(file);
    });
  };

  const handleAddAdmin = (vendorId: number) => {
    const adminRequest = {
      firstName: adminDetails.firstName,
      lastName: adminDetails.lastName,
      email: adminDetails.emailAddress,
      password: adminDetails.password,
      vendorId: vendorId,
      phoneNumber: adminDetails.phone,
    };
    createAdmin(adminRequest)
      .then(() => {
        setIsLoading(false);
        navigate("/settings");
      })
      .catch(() => {
        setIsLoading(false);
        alertError("Error occurred while Creating Admin");
      });
  };

  const handleAddTerritory = (vendorId: number) => {
    const territories = territory.map((item) => {
      if (
        item.territory !== "" &&
        item.timeZone !== "" &&
        item.zipCodes.length !== 0
      )
        return {
          name: reverseString(item.territory),
          zipcodes: item.zipCodes,
          timezone: item.timeZone,
        };
    });

    createTerritory({ territories: territories }, vendorId)
      .then(() => {
        setVendorId(vendorId);
        setStages((stages) =>
          stages.map((stage) => {
            if (stage.index === STAGE_ADD_ZIPCODES)
              return { ...stage, saved: true };
            return stage;
          })
        );
        handleAddAdmin(vendorId);
      })
      .catch(() => {
        setIsLoading(false);
        alertError("Error occurred while Adding Zipcodes");
      });
  };

  const handleAddVendor = () => {
    const categories = category.map((item) => {
      return { skill: item.skill, subCategories: item.subCategories };
    });
    const pmcRequest = {
      address: {
        state: vendorDetails.address.state,
        streetAddress1: vendorDetails.address.streetAddress1,
        city: vendorDetails.address.city,
        melissaBaseMak: vendorDetails.address.melissaBaseMak,
        zip: vendorDetails.address.zip,
      },
      categories: categories,
      iconUrl: urls.iconUrl,
      logoUrl: urls.logoUrl,
      name: vendorDetails.name,
      primaryContactNumber: vendorDetails.primaryContactNumber,
      supportEmail: `${vendorDetails.supportEmail}${process.env.REACT_APP_EMAIL_SUFFIX}`,
      licenseDetails: vendorDetails.licenseDetails,
      invoiceTerms: vendorDetails.invoiceTerms,
      estimateTerms: vendorDetails.estimateTerms,
      invoiceTermsAndConditions: vendorDetails.invoiceTermsAndConditions,
      estimateTermsAndConditions: vendorDetails.estimateTermsAndConditions,
      type: SubscriberType.pmc,
    };
    const contractorRequest = {
      address: {
        state: vendorDetails.address.state,
        streetAddress1: vendorDetails.address.streetAddress1,
        city: vendorDetails.address.city,
        melissaBaseMak: vendorDetails.address.melissaBaseMak,
        zip: vendorDetails.address.zip,
      },
      categories: categories,
      iconUrl: urls.iconUrl,
      logoUrl: urls.logoUrl,
      name: vendorDetails.name,
      primaryContactNumber: vendorDetails.primaryContactNumber,
      supportEmail: `${vendorDetails.supportEmail}${process.env.REACT_APP_EMAIL_SUFFIX}`,
      contractorLicenseExpiry: vendorDetails.contractorLicenseExpiry,
      contractorLicenseNumber: vendorDetails.contractorLicenseNumber,
      invoiceTerms: vendorDetails.invoiceTerms,
      estimateTerms: vendorDetails.estimateTerms,
      invoiceTermsAndConditions: vendorDetails.invoiceTermsAndConditions,
      estimateTermsAndConditions: vendorDetails.estimateTermsAndConditions,
      type: SubscriberType.contractor,
    };
    setIsLoading(true);
    createVendor(type === SubscriberType.pmc ? pmcRequest : contractorRequest)
      .then((res) => {
        console.log("Success!", res);
        setVendorId(vendorId);
        setStages((stages) =>
          stages.map((stage) => {
            if (
              stage.index === STAGE_SUBSCRIBER_DETAILS ||
              stage.index === STAGE_ADD_CATEGORY
            )
              return { ...stage, saved: true };
            return stage;
          })
        );
        if (type === SubscriberType.pmc) handleAddAdmin(res.id);
        else handleAddTerritory(res.id);
      })
      .catch(() => {
        setIsLoading(false);
        alertError("Error occurred in Vendor Details");
      });
  };

  const handleSubmit = () => {
    console.log("handleSubmit", stages);
    const submitStage = stages.find(
      (stage) => stage.active && !stage.saved
    ) as WizardStage;
    console.log("handleSubmit", submitStage);
    switch (submitStage.index) {
      case STAGE_SUBSCRIBER_DETAILS:
        handleAddVendor();
        break;
      case STAGE_ADD_ZIPCODES:
        handleAddTerritory(vendorId);
        break;
      case STAGE_ADMIN_SETUP:
        handleAddAdmin(vendorId);
        break;
      default:
        break;
    }
    // if (!vendorStages.addVendor) handleAddVendor();
    // else if (vendorStages.addVendor && !vendorStages.addTerritory)
    //   handleAddTerritory(vendorStages.vendorId);
    // else handleAddAdmin(vendorStages.vendorId);
  };
  // Reverse Sort the stages to display the last active stage
  const currentStage = stages
    .slice()
    .reverse()
    .find((stage) => stage.active) as WizardStage;

  const renderStage = (stage: WizardStage) => {
    switch (stage.index) {
      case 4:
        return (
          <div className="w-full md:w-auto">
            <AdminSetup
              adminDetails={adminDetails}
              setAdminDetails={setAdminDetails}
            />
            <div className="flex-auto flex flex-row-reverse mt-4 mx-20">
              <button
                className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
               bg-newBlue-400  w-80
               text-white border-newBlue-400
               border"
                onClick={handleSubmit}
              >
                {isLoading ? "Loading..." : "Submit"}
              </button>
              <button
                className="text-base flex justify-center items-center px-4 py-2 rounded font-medium cursor-pointer 
               bg-white
               text-newBlue-400 border-newBlue-400
               border duration-200 ease-in-out 
               transition"
                onClick={() => prevStage(stages, setStages)}
              >
                Go Back
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <AddZipCodes
            prevStage={() => prevStage(stages, setStages)}
            nextStage={() => nextStage(stages, setStages)}
            territory={territory}
            setTerritory={setTerritory}
          />
        );
      case 2:
        return (
          <AddCategory
            prevStage={() => prevStage(stages, setStages)}
            nextStage={() => nextStage(stages, setStages)}
            category={category}
            setCategory={setCategory}
          />
        );
      case 1:
        return (
          <div className="mt-20 p-4 md:mx-10">
            <div className="flex flex-col items-center w-full ">
              <div className="text-lg font-semibold mb-4">
                Select Subscriber Type
              </div>
              <div className="flex flex-col md:flex-row justify-start items-center mb-4 gap-6 ">
                <button
                  className="flex flex-col justify-center items-center relative p-4 bg-newGray-1400 w-60 h-32"
                  onClick={() => setType("CONTRACTOR")}
                >
                  <ContractorIcon className="h-12 w-12" />
                  <div className="text-sm text-newGray-800 font-normal">
                    Contractor
                  </div>
                  {type === SubscriberType.contractor && (
                    <div className="flex absolute top-2 right-2">
                      <div className="flex items-center justify-center bg-green-400 w-6 h-6  rounded-full">
                        <Tick />
                      </div>
                    </div>
                  )}
                </button>
                <button
                  className="flex flex-col justify-center relative  items-center py-4 bg-newGray-1400 w-60 h-32"
                  onClick={() => setType("PMC")}
                >
                  <PmcIcon className="h-12 w-12" />
                  <div className="text-sm text-newGray-800 font-normal">
                    PMC
                  </div>
                  <div className="text-sm text-newGray-800 font-normal">{`(Property Management Company)`}</div>
                  {type === SubscriberType.pmc && (
                    <div className="flex absolute top-2 right-2">
                      <div className="flex items-center justify-center bg-green-400 w-6 h-6  rounded-full">
                        <Tick />
                      </div>
                    </div>
                  )}
                </button>
              </div>
            </div>
            <div className="flex flex-col items-center border-b-2 border-newGray-700">
              <div className="text-lg font-semibold">Upload logo</div>
              <div className="flex flex-row my-4">
                {urls.logoUrl ? (
                  <div className="flex rounded-full h-12 w-12 md:h-24 md:w-24  justify-center items-center  mt-1 md:mt-0 overflow-hidden bg-newGray-700 ">
                    <img
                      className="object-scale-down"
                      src={urls.logoUrl}
                      alt=""
                      height="100"
                      width="100"
                    />
                  </div>
                ) : (
                  <div className="flex justify-center items-center  rounded-full transition duration-500 ease-in-out  h-12 w-12 md:h-24 md:w-24 mt-1 md:mt-0 bg-newGray-700">
                    <ImagePlaceholder />
                  </div>
                )}
                <div className="flex flex-col ml-2 md:ml-10 my-2 ">
                  <div className="flex flex-row">
                    <input
                      className="border text-sm font-medium text-newBlue-400 rounded border-newBlue-400 p-2 mr-2 md:mr-4 overflow-hidden"
                      id="upload_logo"
                      type="file"
                      key={urls.logoKey || ""}
                      placeholder="Upload Logo"
                      onChange={handleSelectLogo}
                    />

                    <button
                      className="border text-sm font-medium text-newGray-800 rounded border-newGray-800 p-2"
                      onClick={() => {
                        SetUrls({
                          ...urls,
                          logoUrl: "",
                          logoKey: urls.logoKey + 1,
                        });
                      }}
                    >
                      Delete
                    </button>
                  </div>
                  <div className="mt-2 text-sm font-normal text-newGray-800 ">
                    Recommended dimensions: 800x800, maximum file size: 5MB
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center mt-4 border-b-2 border-newGray-700">
              <div className="text-lg font-semibold">Upload Icon</div>
              <div className="flex flex-row my-4">
                {urls.iconUrl ? (
                  <div className="flex rounded-full h-12 w-12 md:h-24 md:w-24  justify-center items-center overflow-hidden mt-1 md:mt-0 bg-newGray-700 ">
                    <img
                      className="object-scale-down"
                      src={urls.iconUrl}
                      alt=""
                      height="100"
                      width="100"
                    />
                  </div>
                ) : (
                  <div className="flex justify-center items-center  rounded-full transition duration-500 ease-in-out  h-12 w-12 md:h-24 md:w-24  mt-1 md:mt-0 bg-newGray-700">
                    <ImagePlaceholder />
                  </div>
                )}
                <div className="flex flex-col ml-2 md:ml-10 my-2 ">
                  <div className="flex flex-row">
                    <input
                      className="border text-sm font-medium text-newBlue-400 rounded border-newBlue-400 p-2 mr-2 md:mr-4"
                      id="upload_Icon"
                      type="file"
                      key={urls.iconKey || ""}
                      placeholder="Upload Icon"
                      onChange={handleSelectIcon}
                    />
                    <button
                      className="border text-sm font-medium text-newGray-800 rounded border-newGray-800 p-2"
                      onClick={() =>
                        SetUrls({
                          ...urls,
                          iconUrl: "",
                          iconKey: urls.iconKey + 1,
                        })
                      }
                    >
                      Delete
                    </button>
                  </div>
                  <div className="mt-2 text-sm font-normal text-newGray-800 ">
                    Recommended dimensions: 200x200, maximum file size: 5MB
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center mt-4 border-b-2 border-newGray-700">
              <div className="text-lg font-semibold">Subscriber Details</div>
              <VendorDetails
                nextStage={() => nextStage(stages, setStages)}
                vendorDetails={vendorDetails}
                setVendorDetails={setVendorDetails}
                type={type}
              />
            </div>
          </div>
        );
      default:
        return (
          <div>
            Sorry, you cannot continue with this Subscriber Creation, Refresh
            the page and try again. If this issue persists, please contact
               support.
          </div>
        );
    }
  };

  return (
    <div className="w-full md:w-auto flex flex-col items-center md:m-4  pb-10 bg-white p-4 rounded-lg border-2">
      <div className="w-full md:w-3/4 md:pb-10">
        <StepperComponent stages={stages} />
        {renderStage(currentStage)}
      </div>
    </div>
  );
}