import { useEffect, useState } from "react";

import { alertSuccess } from "../../../../../actions/AlertActions";
import { useAppActions } from "../../../../../actions/AppActions";
import {
  // addTenantUnderLease,
  getAllTenants,
  getLeaseById,
  removeTenant,
} from "../../../../../api/Api";
import {
  DetailsIcon,
  MessageIcon,
} from "../../../../../components/clients/ClientAppIcons";
import { AddIconBlue } from "../../../../../components/common/AppIcons";
import HeadlessDropdown from "../../../../../components/common/HeadlessDropdown";
import MaterialModal from "../../../../../components/common/MaterialModal";
import MaterialForm from "../../../../../components/common/form/MaterialForm";
import { properString } from "../../../../../utils/StringUtils";
import NewTenantForm from "./NewTenantForm";

type TenantsTabProps = {
  leaseId: number;
};

type TenantData = {
  contactId: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  tenantClass: string;
};

export default function TenantsTab({ leaseId }: TenantsTabProps) {
  const [newTenant, setNewTenant] = useState(false);
  const [tenants, setTenants] = useState<TenantData[]>([]);
  const [tenantOptions, setTenantOptions] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState({ selectTenant: 0 });
  const [leaseTerms, setLeaseTerms] = useState("");

  const { withConfirmation } = useAppActions();

  useEffect(() => {
    if (leaseId !== null && leaseId !== 0)
      getLeaseById(leaseId).then((item) => {
        setLeaseTerms(item.leaseTerms);
        if (item.otherTenants === null && item.primaryTenant !== null)
          setTenants([item.primaryTenant]);
        if (item.otherTenants !== null)
          setTenants([item.primaryTenant, ...item.otherTenants]);
      });
  }, [leaseId]);

  // const onSave = () => {
  //   if (leaseId === null) {
  //     alertError("Please add lease");
  //     return;
  //   }
  //   addTenantUnderLease(leaseId, {
  //     contactId: selectedTenant.selectTenant,
  //   }).then(() => alertSuccess("Tenant added successfully"));
  // };
  return (
    <div className="w-full md:p-4">
      <div className="flex flex-col items-start self-center ">
        <div className="text-lg font-medium text-black">Lease Details</div>
        <div className="flex flex-row mt-4 my-1">
          <div className="text-base text-newGray-800 font-normal mr-1">
            {`Lease term:`}
          </div>
          <div className="text-base text-black font-semibold">{leaseTerms}</div>
        </div>
        <div className="flex flex-row my-1 mb-4">
          <div className="text-base text-newGray-800 font-normal mr-1">
            {`Lease Status:`}
          </div>
          <div className="text-base text-black font-semibold">---</div>
        </div>
        <div className="text-lg font-medium text-black mt-4">
          Add Tenant Details
        </div>
        <div className="w-full grid grid-flow-row-dense grid-rows-1 md:grid-cols-2 gap-4">
          {tenants.map((item) => {
            return (
              <div className="flex flex-col md:w-80 my-2 p-4 rounded bg-newGray-700">
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-row items-center">
                    <div className="text-base font-medium text-black">{`${item?.firstName} ${item?.lastName}`}</div>
                    <div className="bg-white rounded-md mx-2">
                      <MessageIcon className="h-7 w-7" />
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    <div className="flex justify-center w-max h-6 rounded bg-newOrange-200 text-newOrange-200 bg-opacity-20 text-xs font-normal p-0.5">
                      {item.tenantClass
                        ? properString(item?.tenantClass)
                        : "Primary Tenant"}
                    </div>
                    <HeadlessDropdown
                      value={"Options"}
                      options={["Delete"]}
                      onChange={(value) => {
                        if (value === "Delete") {
                          withConfirmation({
                            title: "Remove Contact",
                            description: `Are you sure you want to remove ${item?.firstName} ${item?.lastName}  
                          This action cannot be undone.`,
                            onConfirm: () =>
                              removeTenant(leaseId, item.contactId).then(() => {
                                alertSuccess("Tenant deleted Successfully");
                                getAllTenants(leaseId).then((data) => {
                                  setTenants(data);
                                  setTenantOptions((_) =>
                                    data.map((item: TenantData) => ({
                                      value: item.contactId,
                                      label: `${item?.firstName} ${item?.lastName}`,
                                    }))
                                  );
                                });
                              }),
                          });
                        }
                      }}
                    >
                      <div className="px-2  hover:bg-neGray-300 rounded">
                        <DetailsIcon />
                      </div>
                    </HeadlessDropdown>
                  </div>
                </div>
                {/* <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-col my-2 w-40">
                    <div className="text-base font-normal text-newGray-800">
                      Rent:
                    </div>
                    <div className="text-base font-semibold text-black">
                      $ ---
                    </div>
                  </div>
                  <div className="flex flex-col my-2 w-40">
                    <div className="text-base font-normal text-newGray-800">
                      Frequency
                    </div>
                    <div className="text-base font-semibold text-black">
                      ---
                    </div>
                  </div>
                </div> */}
                {/* <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-col my-2 w-40">
                    <div className="text-base font-normal text-newGray-800">
                      Lease Expiry
                    </div>
                    <div className="text-base font-semibold text-black">
                      ---
                    </div>
                  </div>
                  <div className="flex flex-col my-2 w-40">
                    <div className="text-base font-normal text-newGray-800">
                      Deposit
                    </div>
                    <div className="text-base font-semibold text-black">
                      ---
                    </div>
                  </div>
                </div> */}
              </div>
            );
          })}
        </div>
        <div className="w-full my-4">
          <MaterialForm
            className="w-80"
            data={selectedTenant}
            renderArray={[
              {
                name: "selectTenant",
                inputType: "SELECT",
                variant: "OUTLINED",
                options: tenantOptions,
              },
            ]}
            onChange={(update: { name: string; value: number }) => {
              const { value } = update;
              setSelectedTenant({ selectTenant: value });
            }}
          />
        </div>
        <button
          className="border w-40 h-8 rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400 my-4"
          onClick={() => setNewTenant(true)}
        >
          <div className="flex flex-row items-center ">
            <div>
              <AddIconBlue className="h-4 w-4" />
            </div>
            <div className="px-2 flex">
              <div className="font-medium">Add New tenant</div>
            </div>
          </div>
        </button>

        {/* <div className="flex flex-row self-end mt-4 mr-4 ">
          <button
            className="text-base flex justify-center items-center px-4 py-2 rounded  font-medium cursor-pointer 
          bg-gray-300
          text-gray-800 duration-200 ease-in-out 
          transition"
            onClick={() => onBackClick("unitListing")}
          >
            Cancel
          </button>
          <button
            className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
          bg-newBlue-400  w-40
          text-white border-newBlue-400
          border"
            onClick={onSave}
          >
            Save
          </button>
        </div> */}
      </div>
      <MaterialModal open={newTenant} setOpen={() => setNewTenant(false)}>
        <NewTenantForm
          leaseId={leaseId}
          onSave={() => {
            if (leaseId !== null && leaseId !== 0)
              getLeaseById(leaseId).then((item) => {
                setLeaseTerms(item.leaseTerms);

                if (item.otherTenants === null)
                  setTenants([item.primaryTenant]);
                if (item.otherTenants !== null)
                  setTenants([item.primaryTenant, ...item.otherTenants]);
              });
          }}
          onCloseTenant={() => {
            setNewTenant(false);
          }}
        />
      </MaterialModal>
    </div>
  );
}
