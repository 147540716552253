import moment from "moment";

import { conditionalArrayEntry } from "../../utils/ObjectUtills";
import { Skill } from "../vendors/ListSkills";
import { getSubCategories } from "./AssociateDetails";
import { AssociateDetailsData } from "./Service";

export const formAssociateAddress = () => {
  return [
    {
      name: "address",
      inputType: "ADDRESS",
      variant: "STANDARD",
    },
  ];
};

const getContacts = (data: AssociateDetailsData) => {
  let contact: { label: string; value: number | undefined }[] = [];

  data.contacts?.forEach((c) => {
    contact.push({
      label: `${c.firstName} ${c.lastName}`,
      value: c.contactId,
    });
  });

  return contact;
};
export const formAssociate = (
  data: AssociateDetailsData,
  territories: { label: string; value: string }[]
) => {
  return [
    {
      name: "type",
      inputType: "SELECT",
      options: [
        { label: "Squad Associate", value: "SQUAD_ASSOCIATE" },
        // { label: "Contractor", value: "CONTRACTOR" },
        { label: "Supervisor", value: "SUPERVISOR" },
        { label: "Third Party Vendor", value: "THIRD_PARTY_VENDOR" },
      ],
    },
    ...((data.type ?? "") === "THIRD_PARTY_VENDOR"
      ? [
          {
            name: "companyName",
          },
        ]
      : []),
    { name: "firstName" },
    { name: "lastName" },
    {
      name: "territories",
      inputType: "SELECT",
      variant: "MULTISELECT",
      defaultValue: [],
      options: territories,
    },
    { name: "homeCity" },
    { name: "alias" },
    {
      name: "email",
      inputType: "EMAIL",
    },
  ];
};

export const formAssociateFrom = (
  data: AssociateDetailsData,
  skillData: Skill[],
  skills: { label: string; value: string }[]
) => {
  return [
    {
      name: "phone",
      inputType: "PHONE",
    },

    ...(data && data.type === "THIRD_PARTY_VENDOR"
      ? [
          {
            name: "primaryContactId",
            inputType: "SELECT",
            options: getContacts(data),
          },
        ]
      : []),

    {
      name: "skill",
      inputType: "SELECT",
      options: skills,
    },
    ...conditionalArrayEntry(data.skill, {
      name: "subCategory",
      inputType: "SELECT",
      variant: "MULTISELECT",
      defaultValue: [],
      options: getSubCategories(skillData, data.skill ?? "") ?? [],
    }),

    ...((data.type ?? "") !== "THIRD_PARTY_VENDOR"
      ? [
          {
            name: "dateOfBirth",
            inputType: "DATE",
            defaultValue: "1969-12-31",
          },
        ]
      : []),
    {
      name: "notifyBy",
      inputType: "SELECT",
      defaultValue: "both",
      options: [
        { label: "Text and Email", value: "TEXT_AND_EMAIL" },
        { label: "Text", value: "TEXT" },
        { label: "Email", value: "EMAIL" },
      ],
    },
    ...((data.type ?? "") !== "THIRD_PARTY_VENDOR"
      ? [
          { name: "driversLicense" },
          {
            name: "driversLicenseExpiry",
            inputType: "DATE",
            variant: "DISABLEPAST",
            defaultValue: new Date(),
          },
        ]
      : []),
  ];
};

export const formAssociateSchedule = [
  {
    name: "workdayStart",
    defaultValue: "08:00",
    inputType: "TIME",
  },
  {
    name: "workdayEnd",
    defaultValue: "17:00",
    inputType: "TIME",
  },
  {
    name: "saturdayStart",
    inputType: "TIME",
  },
  {
    name: "saturdayEnd",
    inputType: "TIME",
  },
  {
    name: "sundayStart",
    inputType: "TIME",
  },
  {
    name: "sundayEnd",
    inputType: "TIME",
  },
];

export const metaData = (data: AssociateDetailsData) => {
  return [
    {
      name: "metadata.contractorLicense",
      inputType: "SELECT",
      defaultValue: "false",
      options: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
    },
    ...((data?.metadata?.contractorLicense ?? "") === "true"
      ? [
          {
            name: "metadata.contractorLicenseNumber",
          },
        ]
      : []),
    ...((data?.metadata?.contractorLicense ?? "") === "true"
      ? [
          {
            name: "metadata.contractorLicenseExpiry",
            inputType: "DATE",
            defaultValue: moment(),
          },
        ]
      : []),

    {
      name: "metadata.liabilityInsurance",
      inputType: "SELECT",
      defaultValue: "false",
      options: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
    },
    ...((data?.metadata?.liabilityInsurance ?? "") === "true"
      ? [
          {
            name: "metadata.liabilityInsuranceExpiry",
            inputType: "DATE",
            defaultValue: moment(),
          },
        ]
      : []),
    {
      name: "metadata.workersCompensationInsurance",
      inputType: "SELECT",
      defaultValue: "false",
      options: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
    },
    ...((data?.metadata?.workersCompensationInsurance ?? "") === "true"
      ? [
          {
            name: "metadata.workersCompensationInsuranceExpiry",
            inputType: "DATE",
            defaultValue: moment(),
          },
        ]
      : []),
  ];
};
