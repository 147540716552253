/* Untyped file generated from DatePicker.res by genType. */
/* eslint-disable */

import {default as makeNotChecked} from './DatePicker.jsx';

// In case of type error, check the type of 'make' in 'DatePicker.re' and './DatePicker.jsx'.
export const makeTypeChecked = makeNotChecked;

// Export 'make' early to allow circular import from the '.bs.js' file.
export const make = makeTypeChecked;
