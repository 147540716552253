// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SwitchGen from "./Switch.gen";

var make = SwitchGen.make;

export {
  make ,
  
}
/* make Not a pure module */
