import React, { useEffect, useState } from "react";

import { Button, Typography } from "@mui/material";
import moment from "moment";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import { createMaterials, listSupplierAddress } from "../../api/Api";
import { deepUpdate } from "../../utils/StateUtils";
import { AddIconBlue } from "../common/AppIcons";
import MaterialModal from "../common/MaterialModal";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import { materialMethods } from "../common/form/constants";
import MaterialTile from "../pipelines/MaterialTile";

export default function NewMaterialsServiceRequestScreen({
  serviceRequest,
  serviceRequestId,
  refreshCB,
  suppliers,
}) {
  const [editMaterials, setEditMaterials] = useState({
    display: false,
    edit: false,
    data: {
      deliveryDate: moment(),
      orderDate: moment(),
    },
  });
  const [supplierAddress, setSupplierAddress] = useState([]);

  const editCB = (data) => {
    setEditMaterials(data);
  };

  const formMaterialCreation = () => {
    return [
      {
        name: "method",
        inputType: "SELECT",
        options: materialMethods,
      },
      { name: "name", label: "Material Name" },
      { name: "description", inputType: "AREA", variant: "OUTLINED" },
      { name: "skuNumber", label: "SKU Number" },
      {
        name: "supplierId",
        inputType: "SELECT",
        variant: "SELECT_WITH_TAG",
        options: suppliers,
      },
      {
        name: "supplierBranchId",
        inputType: "SELECT",
        options: supplierAddress,
      },
      {
        name: "serviceRequestId",
        inputType: "HIDDEN",
        defaultValue: serviceRequestId,
      },
      ...(editMaterials.data.method !== "IN_STOCK"
        ? [
            // {
            //   name: "deliveryDate",
            //   inputType: "DATE",
            //   variant: "STEP30"
            // },
            { name: "orderNumber" },
            {
              name: "orderDate",
              inputType: "DATE",
              variant: "STEP30",
              // defaultValue: getDateStep30(new Date())
            },
            { name: "trackingUrl" },

            ...(editMaterials.edit
              ? [
                  {
                    name: "isDelivered",
                    inputType: "SELECT",

                    options: [
                      { label: "Yes", value: "true" },
                      { label: "No", value: "false" },
                    ],
                  },
                ]
              : []),
          ]
        : []),
    ];
  };

  useEffect(() => {
    if (editMaterials.data.supplierId)
      listSupplierAddress(editMaterials.data.supplierId).then((data) => {
        let options = [];
        data.map((supplierAdd) => {
          options.push({
            label: `${supplierAdd.streetAddress1} ${supplierAdd.streetAddress2}, ${supplierAdd.city}`,
            value: supplierAdd.buildingId,
          });
        });

        setSupplierAddress(options);
      });
  }, [editMaterials.data.supplierId]);

  return (
    <>
      <div
        className={
          serviceRequest?.materials?.length > 0
            ? "flex flex-row justify-between items-center py-6"
            : "hidden"
        }
      >
        <div className="font-semibold">Materials</div>
        <div
          className="text-newBlue-400 font-semibold"
          style={{
            fontSize: "14px",
          }}
        >
          <div
            // TODO: Hack for invoking the modal from the parent
            id="create-materials"
            className="hidden"
            onClick={(_) => {
              setEditMaterials((current) => {
                return {
                  ...current,
                  display: true,
                };
              });
            }}
          >
            <AddIconBlue className="h-4 w-4" />
            <span className="px-1">Create Materials</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        {serviceRequest?.materials?.map((item, index) => (
          <div className="px-2 py-2">
            <MaterialTile
              material={item}
              data={serviceRequest}
              editCB={editCB}
            />
          </div>
        ))}
      </div>

      {editMaterials.display && (
        <MaterialModal
          open={editMaterials.display ? true : false}
          setOpen={(_) => {
            setEditMaterials((current) => {
              return {
                data: {},
                display: false,
                edit: false,
              };
            });
          }}
          label="new-user-modal"
          describedby="create-new-user"
        >
          <div className="flex material-popup">
            <div>
              <Typography variant="h6">Material Details </Typography>
              <MaterialForm
                data={editMaterials.data}
                renderArray={formMaterialCreation()}
                onChange={(update) => {
                  const { name, value } = update;
                  setEditMaterials((current) => {
                    return {
                      ...current,
                      data: deepUpdate(name, value, editMaterials.data),
                    };
                  });

                  setEditMaterials((data) => deepUpdate(name, value, data));
                }}
              />
              <Button
                onClick={(_) => {
                  createMaterials(editMaterials.data)
                    .then((_) => {
                      setEditMaterials({
                        display: false,
                        data: {},
                        edit: false,
                      });

                      alertSuccess("Successfully Created Material");
                      refreshCB();
                    })
                    .catch((_) => alertError("Error Creating Material"));
                }}
                color="primary"
                className="float-right p-4 material-create"
              >
                {editMaterials.edit ? "Update" : "Create"}
              </Button>
            </div>
          </div>
        </MaterialModal>
      )}
    </>
  );
}
