export const resolveClient = (clientCandidate) => {
  if (clientCandidate.contactId) {
    return ["INDIVIDUAL", clientCandidate.contactId];
  } else if (clientCandidate.clientId) {
    return [clientCandidate.clientType, clientCandidate.clientId];
  } else {
    return null;
  }
};

export const findClient = (identifier, clients) => {
  const [clientType, clientId] = identifier.split("|");
  if (clientType === "contact") {
    const client = clients.find(
      (client) => client.contactId === Number(clientId)
    );
    return ["INDIVIDUAL", client.contactId];
  } else {
    const client = clients.find(
      (client) => client.clientId === Number(clientId)
    );
    return [client.clientType, client.clientId];
  }
};

export const getClientIdString = (clientId, contactId) => {
  if (clientId) {
    return `client|${clientId}`;
  } else if (contactId) {
    return `contact|${contactId}`;
  } else {
    return null;
  }
};

export const getClientOptions = (clients) => {
  return clients.map((client) => {
    return {
      value: getClientIdString(client.clientId, client.contactId),
      label: client.clientName,
    };
  });
};

export const patchTicket = (ticket, patchObj) => {
  console.log("patching ticket with", patchObj);
  if (patchObj.clientId) {
    const [clientType, clientId] = patchObj.clientId.split("|");
    return {
      ...ticket,
      createdUnderClientId: null,
      createdUnderContactId: null,
      [clientType === "contact"
        ? "createdUnderContactId"
        : "createdUnderClientId"]: Number(clientId),
    };
  }
  return {
    ...ticket,
    ...patchObj,
  };
};

export const filterUnique = (key) => (value, index, self) => {
  return self.findIndex((item) => item[key] === value[key]) === index;
};
