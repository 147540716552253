// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "rescript/lib/es6/list.js";
import * as $$String from "rescript/lib/es6/string.js";

function eventValue(someEvent) {
  return someEvent.value;
}

function eventName(someEvent) {
  return someEvent.name;
}

function optionValue(someOption) {
  return someOption.value;
}

function optionLabel(someOption) {
  return someOption.label;
}

function variant(someInput) {
  return someInput.variant;
}

function name(someInput) {
  return someInput.name;
}

function optional(someInput) {
  return someInput.optional;
}

function className(someInput) {
  return someInput.className;
}

function renderFields(someInput) {
  return someInput.renderFields;
}

function defaultValue(someInput) {
  return someInput.defaultValue;
}

function label(someInput) {
  var label$1 = someInput.label;
  if (label$1 !== undefined) {
    return label$1;
  }
  var tree = $$String.split_on_char(/* '.' */46, someInput.name);
  return $$String.capitalize_ascii(List.nth(tree, List.length(tree) - 1 | 0).replace(/([A-Z0-9])/g, " $1").replace(/(Id)$/g, ""));
}

function inputType(someInput) {
  return someInput.inputType;
}

function options(someInput) {
  return someInput.options;
}

function relatedCallback(someInput) {
  return someInput.relatedCallback;
}

function inputOptions(someInput) {
  return someInput.inputOptions;
}

function children(someInput) {
  return someInput.children;
}

export {
  eventValue ,
  eventName ,
  optionValue ,
  optionLabel ,
  variant ,
  name ,
  optional ,
  className ,
  renderFields ,
  defaultValue ,
  label ,
  inputType ,
  options ,
  relatedCallback ,
  inputOptions ,
  children ,
  
}
/* No side effect */
