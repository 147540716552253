import React, { useRef, useState } from "react";
import { useEffect } from "react";

import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ImageIcon from "@mui/icons-material/Image";

import { fileUpload } from "../../../../api/Api";
import { deepFind } from "../../../../utils/StateUtils";

const uploadEncodedFile = (callback, file) => {
  return function (upload) {
    callback({
      fileName: file.name,
      contentType: file.type,
      encodedFile: upload.target.result,
    });
  };
};

export default function FileUpload({ data, name, onChange, label }) {
  const [canRender, setCanRender] = useState(true);
  const value = deepFind(name, data) || "";
  const [isLoading, setLoading] = useState(false);
  const fileRef = useRef();
  useEffect(() => {
    setCanRender(true);
  }, [value]);
  const uploadCB = (data) =>
    fileUpload(data)
      .then((res) => {
        console.log("Success!", res.value);
        onChange({ name: name, value: res.value });
        setLoading(false);
      })
      .catch((failure) => {
        console.log("Failure", failure);
        onChange({ name: name, value: undefined });
        setLoading(false);
      });

  const handleSelectFile = (event) => {
    setLoading(true);
    var files = event.target.files;
    Array.from(files).forEach((file) => {
      console.log("MIME:" + file.type);
      var reader = new FileReader();
      reader.onload = uploadEncodedFile(uploadCB, file);
      reader.readAsDataURL(file);
    });
  };

  return (
    <div
      className="items-start flex gap-2 flex-col my-2 cursor-pointer"
      onClick={(_) => fileRef.current.click()}
    >
      <div>{label}</div>
      {value ? (
        canRender ? (
          <img
            className="object-cover rounded-lg"
            alt=""
            src={value}
            onError={(_) => setCanRender(false)}
          />
        ) : (
          <a
            href={value}
            className="h-full w-full"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            <BrokenImageIcon className="h-8 w-8 " />
            <span> Preview Unavailable </span>
          </a>
        )
      ) : isLoading ? (
        <div className="flex items-center justify-center gap-1">
          <HourglassEmptyIcon className="h-6 w-6 animate-spin" />
          <span> Loading </span>
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <ImageIcon className="h-8 w-8 " />
          <span> No Image Available </span>
        </div>
      )}
      {value && (
        <span
          className="flex gap-1"
          onClick={(e) => {
            e.stopPropagation();
            onChange({ name: name, value: undefined });
          }}
        >
          <CancelIcon className="text-red-600" />
          Clear
        </span>
      )}
      <input
        type="file"
        className="hidden"
        onChange={handleSelectFile}
        ref={fileRef}
      />
    </div>
  );
}
