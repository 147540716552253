import { EventEmitter } from "events";

import { alertInfo } from "../actions/AlertActions";
import {
  ACCESS_TOKEN,
  ADMIN_TOKEN,
  getCurrentUser,
  updateLastOnline,
} from "../api/Api";
import dispatcher from "../dispatcher";

class ActiveUserStore extends EventEmitter {
  constructor() {
    super();
    this.activeUser = {
      loading: true,
    };
    getCurrentUser()
      .then((response) => {
        this.activeUser = { ...response, loading: false };
        this.emit("change");
      })
      .catch((error) => {
        console.log("Error getting current User", error);
        this.activeUser = {
          loading: false,
        };
        this.emit("change");
      });
  }
  setNewUser(userCredentials) {
    this.activeUser = {
      ...userCredentials,
      loading: false,
    };
    this.emit("change");
  }

  loadCurrentUser() {
    getCurrentUser()
      .then((response) => {
        this.activeUser = { ...response, loading: false };
        this.emit("change");
      })
      .catch((error) => {
        console.log("Error getting current User", error);
        this.activeUser = {
          loading: false,
        };
        this.emit("change");
      });
  }
  intermittentUpdate() {
    updateLastOnline()
      .then((_) => {})
      .catch((_) => alertInfo("Connection to Server Lost"));
  }
  logoutUser() {
    localStorage.removeItem(ACCESS_TOKEN);
    if (localStorage.getItem(ADMIN_TOKEN)) {
      localStorage.setItem(ACCESS_TOKEN, localStorage.getItem(ADMIN_TOKEN));
      localStorage.removeItem(ADMIN_TOKEN);
    } else this.activeUser = { loading: false };
    this.emit("change");
  }
  getActiveUser() {
    return this.activeUser;
  }
  isLoggedIn() {
    return this.activeUser.roles && this.activeUser.roles.includes("ROLE_CSR");
  }
  isSuperAdvisor() {
    if (
      this.activeUser.roles &&
      this.activeUser.roles.includes("ROLE_SUPERVISOR")
    )
      return true;
    return false;
  }

  isAdmin() {
    if (this.activeUser.roles && this.activeUser.roles.includes("ROLE_ADMIN"))
      return true;
    return false;
  }
  isSuper() {
    if (
      this.activeUser.roles &&
      this.activeUser.roles.includes("ROLE_SUPERADMIN")
    )
      return true;
    return false;
  }
  isHelpSquad() {
    if (this.activeUser.vendor.name === "Help Squad") return true;
    return false;
  }
  switchUser(token) {
    localStorage.setItem(ADMIN_TOKEN, localStorage.getItem(ACCESS_TOKEN));
    localStorage.setItem(ACCESS_TOKEN, token.accessToken);
    window.location.reload();
  }
  handleActions(action) {
    switch (action.type) {
      case "updateActiveUser": {
        this.loadCurrentUser();
        break;
      }
      case "updateLastOnline": {
        this.intermittentUpdate();
        break;
      }
      case "logoutUser": {
        this.logoutUser();
        break;
      }
      default: {
        return;
      }
    }
  }
}

const activeUserStore = new ActiveUserStore();
dispatcher.register(activeUserStore.handleActions.bind(activeUserStore));
window.dispatcher = dispatcher;

export default activeUserStore;
