/* Untyped file generated from TimePicker.res by genType. */
/* eslint-disable */

import {default as makeNotChecked} from './TimePicker.jsx';

// In case of type error, check the type of 'make' in 'TimePicker.re' and './TimePicker.jsx'.
export const makeTypeChecked = makeNotChecked;

// Export 'make' early to allow circular import from the '.bs.js' file.
export const make = makeTypeChecked;
