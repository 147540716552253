import React, { useEffect, useRef, useState } from "react";

import { Button } from "@mui/material";
import useOnClickOutside from "use-onclickoutside";

import {
  incomingTask,
  taskCancelled,
  taskTimeout,
} from "../../actions/VoiceActions";
import { getWorkerToken, getTwilioStatus } from "../../api/Api";
import dispatcher from "../../dispatcher";
import activeUserStore from "../../store/ActiveUserStore";
import { properString } from "../../utils/StringUtils";
import { LoadingIcon2 } from "../common/AppIcons";
// import { alertInfo, alertSuccess } from "../../actions/AlertActions";
import MaterialModal from "../common/MaterialModal";

// import { TvRounded } from "@mui/icons-material";

const Worker = window.Twilio?.TaskRouter.Worker || null;

const removeReservation = (reservation, setReservations) => {
  setReservations((reservations) =>
    reservations.filter((r) => r.sid !== reservation.sid)
  );
};

const addReservation = (reservation, setReservations) => {
  setReservations((reservations) => [...(reservations ?? []), reservation]);
};

const Activity = {
  AVAILABLE: "Available",
  UNAVAILABLE: "Unavailable",
  WRAPUP: "WrapUp",
  OFFLINE: "Offline",
  MOBILE: "Mobile",
};

export default function TaskRouterUI({ isDesktopResolution }) {
  const [token, setToken] = useState();
  const [activities, setActivities] = useState([]);
  const [worker, setWorker] = useState();
  const [workerInstance, setWorkerInstance] = useState();
  const [reservations, setReservations] = useState([]);
  const [activeReservation, setActiveReservation] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [extend, setExtend] = useState(false);

  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    getTwilioStatus().then((data) => {
      setStatuses(data);
    });
  }, []);

  // for Activity Selector
  const [showActivityList, setShowActivityList] = useState(false);

  // TODO: Move the Ref & useOnClickOutside to a separate higher order component
  const activityListRef = useRef(null);

  useOnClickOutside(activityListRef, () => {
    setShowActivityList(false);
  });

  const fetchToken = async () => {
    const token = getWorkerToken()
      .then((token) => {
        setToken(token.value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const initializeWorker = () => {
    console.log("Initializing Twilio worker");
    const worker = new Worker(token);
    setWorkerInstance(worker);
    worker.on("ready", (worker) => {
      console.log("Twilio  worker ready");

      setWorker(worker);
    });
    worker.on("token.expired", function () {
      fetchToken();
    });
    worker.fetchReservations(
      function (error, reservations) {
        console.log("Reservations on Fetch:", reservations);
        setReservations(reservations?.data ?? []);
      },
      { ReservationStatus: "pending" }
    );
    worker.on("reservation.created", function (reservation) {
      console.log("Reservation on Create:", reservation);
      addReservation(reservation, setReservations);
      setActiveReservation(reservation);
      console.log("New Reservation Created");
      incomingTask(reservation.task.attributes);
    });
    worker.on("reservation.accepted", function (reservation) {
      console.log("Reservation on Accept Event:", reservation);
      console.log("Reservation Accepted");
      removeReservation(reservation, setReservations);
    });
    worker.on("reservation.rejected", function (reservation) {
      console.log("Reservation on Reject:", reservation);
      removeReservation(reservation, setReservations);
    });
    worker.on("reservation.timeout", function (reservation) {
      console.log("Reservation on Timeout:", reservation);
      removeReservation(reservation, setReservations);
      taskTimeout(reservation.task.attributes);
    });
    worker.on("reservation.canceled", function (reservation) {
      console.log("Reservation on Cancelled:", reservation);
      removeReservation(reservation, setReservations);
      taskCancelled(reservation.task.attributes);
    });

    worker.on("reservation.rescinded", function (reservation) {
      console.log("Reservation on Rescind:", reservation);
      removeReservation(reservation, setReservations);
    });

    worker.on("activity.update", function (worker) {
      // console.log("Worker now " + worker.activityName);
      setWorker(worker);
    });

    worker.on("attributes.update", function (worker) {
      console.log("Worker attributes changed");
      console.log("Worker Availability " + worker.available);
      setWorker(worker);
    });

    worker.activities.fetch(function (error, activityList) {
      setActivities(activityList);
    });
  };

  useEffect(() => {
    let timer;

    if (extend) {
      // setExtend(false);
      timer = setTimeout(() => {
        // console.log("hello");
        setShowModal(true);
      }, 120000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [extend]);

  const switchToAvailable = (event) => {
    event.preventDefault();
    updateWorker(Activity.AVAILABLE);
    setShowModal(false);
  };

  const extendSomeMoreTime = (event) => {
    setExtend(true);
    setShowModal(false);
  };

  const updateWorker = (activity) => {
    getTwilioStatus().then((data) => {
      const activityId = data[activity];
      setWorkerInstance((workerInstance) => {
        workerInstance.update(
          "ActivitySid",
          activityId,
          function (error, worker) {
            console.log("Worker now " + worker.activityName);
            if (worker.activityName === Activity.WRAPUP) {
              setExtend(true);
            } else {
              setExtend(false);
            }
          }
        );
        return workerInstance;
      });
    });
  };

  const wrappedUp = () => updateWorker(Activity.AVAILABLE);

  useEffect(() => {
    console.log("Worker Instance:", workerInstance);
  }, [workerInstance]);
  const handleAction = (action) => {
    switch (action.actionType) {
      case "CALL_END":
        console.log("Call End Event Caught");
        updateWorker(Activity.WRAPUP);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (token) {
      if (worker) {
        worker.updateToken(token);
      } else {
        Worker && initializeWorker();
      }
    }
  }, [token]);

  useEffect(() => {
    fetchToken();

    dispatcher.register(handleAction);
    window.dispatcher = dispatcher;
  }, []);

  const getBackGroundColor = (activityName) => {
    if (activityName === Activity.AVAILABLE) {
      return "#48bb78";
    } else if (activityName === Activity.UNAVAILABLE) {
      return "rgba(211, 47, 47, 1)";
    } else if (activityName === Activity.OFFLINE) {
      return "rgba(107, 108, 123, 1)";
    } else if (activityName === Activity.MOBILE) {
      return "rgba(123, 97, 255, 1)";
    }
  };

  return (
    <div className="flex gap-1 items-center ">
      {worker && worker.activityName === Activity.WRAPUP ? (
        <React.Fragment>
          <span className="text-white text-medium">Wrapping Up</span>
          <div className="px-2">
            <button
              className="px-2 py-2 rounded-full mx-auto bg-newGreen-300 items-center text-xs md:text-base flex gap-2 text-white"
              onClick={(e) => {
                e.stopPropagation();
                wrappedUp();
              }}
            >
              Go Online
            </button>
          </div>
        </React.Fragment>
      ) : (
        <>
          {worker && (
            <div className="group inline-block relative">
              <button
                onClick={(_e) => setShowActivityList(true)}
                className="bg-newGray-1000 md:bg-newGray-100 font-semibold py-2 px-4 inline-flex items-center rounded-full"
              >
                <div className="px-2">
                  <div
                    className="h-2 w-2 rounded-full "
                    style={{
                      backgroundColor: getBackGroundColor(worker?.activityName),
                    }}
                  ></div>
                </div>

                <span className="mr-1">
                  {worker?.activityName || "Authenticating"}
                </span>
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </button>
              <ul
                ref={activityListRef}
                className={
                  showActivityList
                    ? "rounded absolute bottom-16 md:bottom-auto md:right-0 text-gray-700 pt-1 group-hover:block"
                    : "hidden"
                }
              >
                {Object.keys(statuses)
                  .filter((activityName) => activityName !== Activity.WRAPUP)
                  .map((activity_name, _index) => (
                    <li
                      key={activity_name}
                      className=""
                      onClick={(_) => {
                        updateWorker(activity_name);
                        setShowActivityList(false);
                      }}
                    >
                      <div className="rounded bg-gray-100 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap">
                        <div className="flex flex-row items-center">
                          <div className="px-2">
                            <div
                              className="h-2 w-2 rounded-full "
                              style={{
                                backgroundColor: getBackGroundColor(
                                  properString(activity_name)
                                ),
                              }}
                            ></div>
                          </div>
                          <div>{properString(activity_name)}</div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          )}{" "}
          {!worker && <LoadingIcon2 />}
        </>
      )}
      {showModal && (
        <MaterialModal
          open={showModal}
          setOpen={(_) => setShowModal(false)}
          label="new-user-modal"
          describedby="create-new-user"
        >
          <div className="p-4 max-w-sm w-full">
            <div className="flex gap-2">
              <p>Do you want to extend the Wrap up or switch to Available ? </p>
            </div>
            <div className="float-right">
              <Button
                color="primary"
                className="float-right p-4"
                onClick={switchToAvailable}
              >
                Switch to Available
              </Button>
            </div>
            <div className="float-left">
              <Button
                color="primary"
                className="float-right p-4"
                onClick={extendSomeMoreTime}
              >
                Extend
              </Button>
            </div>
          </div>
        </MaterialModal>
      )}
    </div>
  );
}
