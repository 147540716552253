/* Untyped file generated from Number.res by genType. */
/* eslint-disable */

import {default as makeNotChecked} from './Number.jsx';

// In case of type error, check the type of 'make' in 'Number.re' and './Number.jsx'.
export const makeTypeChecked = makeNotChecked;

// Export 'make' early to allow circular import from the '.bs.js' file.
export const make = makeTypeChecked;
