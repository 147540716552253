/* Untyped file generated from Switch.res by genType. */
/* eslint-disable */

import {default as makeNotChecked} from './Switch.jsx';

// In case of type error, check the type of 'make' in 'Switch.re' and './Switch.jsx'.
export const makeTypeChecked = makeNotChecked;

// Export 'make' early to allow circular import from the '.bs.js' file.
export const make = makeTypeChecked;
