import React, { useState } from "react";
import { useEffect } from "react";

import CloseIcon from "@mui/icons-material/Close";

import { closeChat } from "../../../actions/ChatActions";
import { getChatToken } from "../../../api/Api";
import dispatcher from "../../../dispatcher";
import ChatUI from "./ChatUI";

const startChat = async (setTwilioChatToken) => {
  getChatToken().then(setTwilioChatToken).catch(console.log);
};

export default function ChatComponent() {
  const [showMessage, setShowMessage] = useState(false);
  const [messageReceived, setMessageReceived] = useState(true);
  const [messages, setMessages] = useState([]);
  const [twilioChatToken, setTwilioChatToken] = useState();

  const handleAction = (action) => {
    switch (action.actionType) {
      case "CHAT":
        setShowMessage(true);
        setMessageReceived(false);
        break;
    }
  };

  useEffect((_) => {
    startChat(setTwilioChatToken);

    dispatcher.register(handleAction);
    window.dispatcher = dispatcher;
  }, []);

  return (
    // TODO: Hidden Public Chat
    <div
      className="fixed bottom-16 right-1 w-full flex flex-row-reverse px-5 md:px-6 lg:px-12"
      style={{ zIndex: 140 }}
    >
      <div className="flex flex-row absolute bottom-0 items-start">
        {showMessage && (
          <div className="px-2 py-4">
            <div
              className={
                "bg-newBlue-1200 z-30 flex items-end hover:bg-blue-700 transform duration-500 cursor-pointer rounded-full p-4 text-white " +
                (messageReceived ? "animate-bounce" : "animate-none")
              }
              onClick={() => {
                setShowMessage(!showMessage);
                setMessageReceived(false);
                closeChat();
              }}
            >
              {showMessage ? (
                <CloseIcon className="h-6 w-6 fill-current" />
              ) : (
                ""
              )}
            </div>
          </div>
        )}
        <div className={showMessage ? "" : "hidden"}>
          <ChatUI
            messages={messages}
            twilioAuth={twilioChatToken}
            setMessageReceived={setMessageReceived}
          />
        </div>
      </div>
    </div>
  );
}
