import React, { useEffect, useState } from "react";

import BusinessIcon from "@mui/icons-material/Business";

import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import { Typography, Button } from "@mui/material";

import { deepUpdate } from "../../utils/StateUtils";

import { getSupervisors } from "../../api/Api";

export default function SelectSupervisor({ baseData, territory, onSubmitCB }) {
  const [data, setData] = useState(baseData ?? {});
  const [selectUserForm, setSelectUserForm] = useState([
    {
      name: "user",
      inputType: "SELECT",
      options: [],
    },
  ]);
  useEffect(() => {
    getSupervisors(territory).then((users) =>
      setSelectUserForm((form) =>
        form.map((input) =>
          input.name === "user"
            ? {
                ...input,
                options: users.map((user) => {
                  return {
                    label: `${user.firstName} ${user.lastName}`,
                    value: `${user.id}`,
                  };
                }),
              }
            : input
        )
      )
    );
  }, []);

  const onChange = (update) => {
    const { name, value } = update;
    setData((data) => deepUpdate(name, value, data));
  };
  return (
    <div className="p-4 max-w-sm w-full">
      <div className="flex gap-2">
        <BusinessIcon />
        <Typography variant="h5">Assign Supervisor</Typography>
      </div>
      <MaterialForm
        data={data}
        renderArray={selectUserForm}
        onSubmit={(_) => onSubmitCB(data.user)}
        onChange={onChange}
      />

      <div className="float-right">
        <Button
          color="primary"
          className="float-right p-4"
          onClick={(_) => onSubmitCB(data.user)}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
