import parsePhoneNumber from "libphonenumber-js";

import { alertInfo } from "../actions/AlertActions";

const upsertArrayObjects = (arr, index) => {
  const targetCount = index + 1 > arr.length ? index + 1 : arr.length;
  return [...Array(targetCount)].map((_, i) => {
    const currentVal = arr[i] || {};
    return currentVal;
  });
};

// const debugUpdateComparator = "serviceItems[0].materials[0].name";
// service.request[0].serviceType
export const deepUpdate = (name, value, depthState) => {
  // name = "service.request[0].serviceType"
  let debug = false;
  // if (debugUpdateComparator.includes(name)) {
  if (name.includes("contact")) {
    debug = true;
  }
  debug && console.log("Debugging enabled for: ", name, "in", depthState);
  if (name.includes(".")) {
    const splitByLevels = name.split(".");
    const container = splitByLevels[0];
    debug && console.log("Container", container);
    // container = "service"
    if (container.includes("[")) {
      debug && console.log("Container is an array");
      const containerName = container.split("[")[0];
      const containerIndex = container.split("[")[1].split("]")[0];
      const baseArr = depthState[containerName] || [];
      const containerArr = upsertArrayObjects(baseArr, Number(containerIndex));
      if (baseArr !== containerArr)
        console.log(
          "Upserted",
          containerArr.length - baseArr.length,
          "objects"
        );
      debug && console.log("Container Array", containerArr);
      return {
        ...(depthState || {}),
        [containerName]: containerArr.map((item, index) => {
          if (index === parseInt(containerIndex)) {
            return deepUpdate(splitByLevels.slice(1).join("."), value, item);
          }
          return item;
        }),
      };
    }
    debug && console.log("Container is an object");
    return {
      ...(depthState || {}),
      [container]: deepUpdate(
        splitByLevels.slice(1).join("."),
        value,
        depthState[container] || {}
      ),
    };
  } else {
    debug && console.log("Container is a single value");
    if (name.includes("[")) {
      debug && console.log("Container is an array");
      const containerName = name.split("[")[0];
      const containerIndex = name.split("[")[1].split("]")[0] || 0;
      const baseArr = depthState[containerName] || [];
      const containerArr = upsertArrayObjects(baseArr, containerIndex);
      if (baseArr !== containerArr)
        console.log(
          "Upserted",
          containerArr.length - baseArr.length,
          "objects"
        );
      debug && console.log("Container Array", containerArr);
      return {
        ...(depthState || {}),
        [containerName]: containerArr.map((item, index) => {
          if (index === parseInt(containerIndex)) {
            return value;
          }
          return item;
        }),
      };
    }
    debug && console.log("Returning final value", value);
    return {
      ...(depthState || {}),
      [name]: value,
    };
  }
};

export const recursiveDeepUpdate = (tuples, depthState) =>
  tuples.reduce((acc, tuple) => {
    const { name, value } = tuple;
    return deepUpdate(name, value, acc);
  }, depthState);

export const deepFind = (name, depthState) => {
  if (!depthState) return null;

  let debug = false;
  // if (debugComparator.includes(name)) debug = true;
  debug && console.log("Debugging enabled for: ", name, "in", depthState);

  if (name.includes(".")) {
    const splitByLevels = name.split(".");
    const container = splitByLevels[0];
    // container = "service"
    debug && console.log("Resolved Container: ", container);
    let stateCandidate;

    const containerName = container.split("[")[0];
    debug && console.log("Resolved Container Name: ", containerName);

    if (!Object.keys(depthState).includes(containerName)) {
      debug &&
        console.log("Container ", containerName, " not found in ", depthState);
      return null;
    }

    if (container.includes("[")) {
      debug && console.log("Container is an Array: ", container);
      const containerIndexStr = container.split("[")[1].split("]")[0];
      debug && console.log("Resolved Container Index: ", containerIndexStr);
      const containerIndex = Number(containerIndexStr);
      debug && console.log("Resolved Container Index: ", containerIndex);
      if (depthState.length <= containerIndex) {
        debug && console.log("Container Index Out of Bounds: ", containerIndex);
        return null;
      }
      stateCandidate = depthState[containerName][containerIndex];
      debug && console.log("Resolved Container State: ", stateCandidate);
    } else {
      debug && console.log("Container is an Object: ", container);
      stateCandidate = depthState[container];
      debug && console.log("Resolved Container State: ", stateCandidate);
    }
    // Hack to return null when null; Ensuring Controlled States
    const returnableState =
      stateCandidate === null ? null : stateCandidate || "";

    debug && console.log("Resolved Returnable State: ", returnableState);
    const resolvedReturnableState = deepFind(
      splitByLevels.slice(1).join("."),
      returnableState
    );
    debug &&
      console.log(
        "Returning resolved Returnable State: ",
        resolvedReturnableState
      );
    return resolvedReturnableState;
  }
  let stateCandidate;
  const containerName = name.split("[")[0];
  debug && console.log("Resolved Container Name: ", containerName);

  if (!Object.keys(depthState).includes(containerName)) {
    debug && console.log("Container Name Not Found: ", containerName);
    return null;
  }

  if (name.includes("[")) {
    const containerIndexStr = name.split("[")[1].split("]")[0];
    debug && console.log("Resolved Container Index: ", containerIndexStr);
    const containerIndex = Number(containerIndexStr);
    debug && console.log("Resolved Container Index: ", containerIndex);
    stateCandidate = depthState[containerName][containerIndex];
    debug && console.log("Resolved Container State: ", stateCandidate);
  } else {
    debug && console.log("Container is an Object: ", containerName);
    stateCandidate = depthState[name];
    debug && console.log("Resolved Container State: ", stateCandidate);
  }
  // Hack to return null when null; Ensuring Controlled States
  const returnableState = stateCandidate === null ? null : stateCandidate || "";
  debug && console.log("Resolved Returnable State: ", returnableState);
  return returnableState;
};

export const renderPhone = (number) => {
  if (number === null || number === undefined || number === "") return "";

  const phoneNumber = parsePhoneNumber(number);
  if (phoneNumber) {
    if (phoneNumber.isValid()) {
      if (phoneNumber.country === "US") return phoneNumber.formatNational();
      else return phoneNumber.formatInternational();
    } else {
      return number;
    }
  } else {
    return "";
  }
};

export const updateCurrencyInput = (e, currentValue) => {
  let returnable = `${currentValue ?? ""}`;
  const keyVal = e.key;
  if (keyVal === "Backspace") {
    returnable = returnable.slice(0, returnable.length - 1);
  } else if (!isNaN(keyVal)) {
    if (returnable.includes(".") && returnable.split(".")[1].length > 1)
      alertInfo("Cannot add more than 2 decimals");
    else returnable = returnable + keyVal;
  } else if (keyVal === "." && !returnable.includes(".")) {
    returnable = returnable + keyVal;
  }
  return returnable;
};

export const lowerCase = (str) => {
  if (str === null || str === undefined || str === "") return "";
  return str.toLowerCase();
};

export const deepFilter = (searchText) => (obj) => {
  // null and undefined are "empty" values
  if (!obj) return false;
  if (typeof obj === "string") {
    return obj.toLowerCase().includes(searchText.toLowerCase());
  } else if (typeof obj === "object") {
    return Object.values(obj).some((values) => {
      const currentFilter = deepFilter(searchText);
      return currentFilter(values);
    });
  }
};

export const convertToAscendingDescendingDict = (
  data,
  field,
  field2 = "serviceRequestIndex",
  order = "asc",
  type = "int"
) => {
  if (data) {
    let sortedData = [];
    type === "int"
      ? (sortedData = data.sort((a, b) => {
          if (a[field] - b[field] === 0) {
            return a[field2] - b[field2];
          } else {
            return a[field] - b[field];
          }
        }))
      : (sortedData = data.sort((a, b) => a[field].localeCompare(b[field])));

    if (order === "asc") {
      return sortedData;
    } else {
      return sortedData.reverse();
    }
  } else {
    return [];
  }
};
