import React, { useEffect, useState } from "react";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import ContactsIcon from "@mui/icons-material/Contacts";
import LocationIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import { Button, CircularProgress, Typography } from "@mui/material";
import { Link } from "raviger";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import {
  createSR,
  getAssociatesByTerritory,
  getBuilding,
  getClient,
  getSR,
  listSupplier,
} from "../../api/Api";
import { properStrings, renderAddress } from "../../utils/StringUtils";
import SRGallery from "../clients/SRGallery";
// import PreviewLink from "../common/PreviewLink";
import { ClientIcon } from "../common/AppIcons";
import Carousel from "../common/Carousel";
import MaterialModal from "../common/MaterialModal";
// import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
// import { getMedia } from "../clients/MessagesList";
import { NewMediaDragIn } from "../common/NewMediaDragIn";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";
// import { timeAfterNHours } from "../../utils/DateUtils";
import MaterialsServiceRequest from "./MaterialsServiceRequest";
import RenderAppointments from "./RenderAppointments";
import ScheduleForm from "./ScheduleForm";

export default function ServiceRequestDetail({
  serviceData,
  serviceRequestId,
  setForUpdate,
  setNewSchedule,
  refresh,
}) {
  const [associates, setAssociates] = useState([]);
  const [serviceRequest, setServiceRequest] = useState();
  const [appointments, setAppointments] = useState();
  const [changeBuilding, toggleChangeBuilding] = useState(false);
  const [clientBuildings, setClientBuildings] = useState();
  const [lightboxImages, setLightboxImages] = useState();
  const [imagePopUp, setImagePopUp] = useState({
    display: false,
    photoIndex: null,
  });
  const [editAppointment, setEditAppointment] = useState();
  const [editMedia, setEditMedia] = useState(false);

  const [mediaURl, setMediaURL] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [changeContacts, toggleChangeContacts] = useState(false);

  const [suppliers, setSuppliers] = useState([]);

  const [appointmentButton, setAppointmentButton] = useState(false);
  const [galleyModal, setGalleryModal] = useState(false);
  const [tempImages, setTempImages] = useState([]);

  const [selectedImage, setSelectedImage] = useState();

  useEffect(() => {
    if (serviceRequest?.mediaUrls) setTempImages(serviceRequest.mediaUrls);
    else {
      setTempImages([]);
    }
  }, [serviceRequest]);

  useEffect(() => {
    if (serviceRequest?.buildingId)
      getBuilding(serviceRequest.buildingId)
        .then((building) => {
          setContacts(building.contacts);
        })
        .catch((err) => {
          alertError("Couldn't Fetch Select Building");
        });
    else setContacts([]);
  }, [serviceRequest?.buildingId]);

  const onCancelCB = () => {
    console.log("cancelled called");
    setEditAppointment(undefined);
  };

  const onSuccessCB = () => {
    setForUpdate && setForUpdate((_) => null);
    setNewSchedule && setNewSchedule((_) => null);
    setEditAppointment(undefined);
    getServiceRequestData();
  };

  const refreshCB = () => {
    getServiceRequestData();
  };

  const addAttachmentToSR = (url) => {
    setServiceRequest((serviceRequest) => {
      return {
        ...serviceRequest,
        mediaUrls: [...(serviceRequest.mediaUrls ?? []), url],
      };
    });
  };

  const getServiceRequestData = () => {
    getSR(serviceRequestId)
      .then((data) => {
        const appointments = data.appointments;
        setMediaURL(data.mediaUrls);
        setLightboxImages(data.mediaUrls);

        setAppointments(appointments ?? []);
        // data["bookingStatus"] = "MATERIALS_REQUIRED";
        setServiceRequest(data);
        getAssociatesByTerritory(data.territory)
          .then((data) => {
            setAssociates(data);
          })
          .catch((error) => alertError("Couldn't get Associates"));

        listSupplier().then((data) => {
          let options = [];
          data.map((supplier) => {
            options.push({
              label: supplier.name,
              value: supplier.supplierId,
              tags: supplier.typeOfSupplies || [],
            });
          });
          setSuppliers(options);
        });

        if (data.bookingStatus === "MATERIALS_REQUIRED") {
          let changeStatus = 0;
          data.materials?.map((item) => {
            if (item.isDelivered) {
              changeStatus += 1;
            }
          });

          if (changeStatus === data.materials?.length) {
            setAppointmentButton(false);
          } else {
            setAppointmentButton(true);
          }
        } else {
          setAppointmentButton(false);
        }
      })
      .catch((_) => alertError("Couldn't fetch SR Details"));
  };

  useEffect(() => {
    if (changeBuilding)
      getClient(serviceRequest.clientId)
        .then((clientData) => setClientBuildings(clientData.buildings))
        .catch((_) => {
          toggleChangeBuilding(false);
          alertError("Error Loading Client Buildings");
        });
  }, [changeBuilding]);

  useEffect(() => {
    getServiceRequestData();
  }, [serviceRequestId, refresh]);

  return (
    <div className="flex flex-col max-w-full px-4">
      <DndProvider backend={HTML5Backend}>
        {serviceRequest ? (
          <>
            {/* {serviceRequest.mediaUrls && ( */}
            <>
              <Typography variant="h6">Media</Typography>
              <div className="flex gap-2">
                <>
                  {" "}
                  <NewMediaDragIn
                    urls={[
                      ...new Set(
                        (serviceRequest.mediaUrls ?? []).filter(
                          (d) =>
                            !(
                              serviceRequest?.blacklistedTicketMedia || []
                            ).includes(d)
                        )
                      ),
                    ]}
                    editMedia={true}
                    setServiceRequest={setServiceRequest}
                    OnDeleteCB={(filtered) => {
                      setServiceRequest((serviceRequest) => {
                        return {
                          ...serviceRequest,
                          mediaUrls: filtered,
                        };
                      });
                    }}
                  />
                  <Button
                    color="primary"
                    variant="text"
                    onClick={(_) => {
                      setGalleryModal(true);
                    }}
                  >
                    <div className="flex gap-1 sr-material-create">
                      <AddCircleOutlineOutlined />
                      <span>
                        {serviceRequest?.mediaUrls?.length > 0 ? "Edit" : "Add"}{" "}
                        Media
                      </span>
                    </div>
                  </Button>
                </>
              </div>
            </>
            {/* )} */}
            <Link
              href={`/clients/${serviceRequest.clientId}`}
              className="py-2 hover:text-blue-700"
            >
              <ClientIcon className="mx-1" />
              {serviceRequest.clientName}
            </Link>
            <div>
              {changeBuilding ? (
                clientBuildings && (
                  <MaterialForm
                    data={{}}
                    renderArray={[
                      {
                        name: "building",
                        label: "Building",
                        inputType: "SELECT",
                        options: clientBuildings.map((building) => {
                          return {
                            label: renderAddress(building.address),
                            value: building.buildingId,
                          };
                        }),
                      },
                    ]}
                    onSubmit={(_) => {}}
                    onChange={({ name, value }) => {
                      var data = Object.assign({}, serviceRequest);
                      delete data["appointments"];

                      createSR({ ...data, buildingId: value })
                        .then(() => {
                          getServiceRequestData();
                          alertSuccess("Successfully Updated Service Request");
                        })
                        .catch((_) =>
                          alertError("Error Updating Service Request")
                        );
                      toggleChangeBuilding((_) => false);
                      setClientBuildings((_) => undefined);
                    }}
                  />
                )
              ) : serviceRequest.buildingId ? (
                <Link
                  href={`/clients/${serviceRequest.clientId}/summary/${serviceRequest.buildingId}`}
                  className="py-1 hover:text-blue-700"
                >
                  <LocationIcon className="mx-1" />
                  {renderAddress(serviceRequest)}
                </Link>
              ) : (
                <span className="py-1 hover:text-blue-700">
                  <LocationIcon className="mx-1" />
                  Location not specified
                </span>
              )}

              {!changeBuilding && (
                <Button
                  onClick={(_) => {
                    toggleChangeBuilding((_) => true);
                  }}
                >
                  Change
                </Button>
              )}
            </div>
            {serviceRequest.contactId && (
              <span className="py-1 hover:text-blue-700">
                <PersonIcon className="mx-1" />
                {properStrings([
                  serviceRequest.contactFirstName,
                  serviceRequest.contactLastName,
                ])}
              </span>
            )}
            <div>
              {changeContacts ? (
                <MaterialForm
                  data={{}}
                  renderArray={[
                    {
                      name: "contactId",
                      label: "Contact",
                      inputType: "SELECT",
                      options: contacts.map((contact) => {
                        return {
                          label: properStrings([
                            contact.firstName,
                            contact.lastName,
                            contact.unitNumber,
                          ]),
                          value: contact.contactId,
                        };
                      }),
                    },
                  ]}
                  onSubmit={(_) => {}}
                  onChange={({ name, value }) => {
                    let data = Object.assign({}, serviceRequest);
                    delete data["appointments"];

                    createSR({
                      ...data,
                      contactId: value,
                    }).then(() => {
                      getServiceRequestData();
                    });

                    toggleChangeContacts((_) => false);
                  }}
                />
              ) : (
                <span className="py-1 hover:text-blue-700">
                  <ContactsIcon className="mx-1" />
                  {serviceRequest.alternateContactId
                    ? properStrings([
                        serviceRequest.alternateContactFirstName,
                        serviceRequest.alternateContactLastName,
                      ])
                    : "No Contacts Selected"}
                </span>
              )}

              {!changeContacts && (
                <Button
                  onClick={(_) => {
                    toggleChangeContacts((_) => true);
                  }}
                >
                  Change
                </Button>
              )}
            </div>

            <>
              <MaterialsServiceRequest
                serviceRequestId={serviceRequestId}
                serviceRequest={serviceRequest}
                refreshCB={refreshCB}
                suppliers={suppliers}
              />
            </>

            <div className="flex justify-between py-4 px-2">
              <Typography variant="h5">Appointments</Typography>
              <Button
                disabled={appointmentButton}
                color="primary"
                variant="text"
                onClick={(_) => {
                  setEditAppointment({
                    serviceRequestId: serviceRequest.serviceRequestId,
                    associateId: [],
                    startTime: serviceRequest.preferredDateTime,
                    timezone: serviceRequest.timezone,
                  });
                }}
              >
                <div className="flex gap-1 appointment-create-btn">
                  <AddCircleOutlineOutlined />
                  <span>Create</span>
                </div>
              </Button>
            </div>
            <div className="py-2">
              {editAppointment ? (
                <ScheduleForm
                  onCancelCB={onCancelCB}
                  onSuccessCB={onSuccessCB}
                  jobType={
                    serviceData ? serviceData.jobType : serviceRequest.jobType
                  }
                  materials={serviceRequest.materials.length}
                  duration={serviceRequest.duration}
                  baseData={editAppointment}
                  setEditAppointment={setEditAppointment}
                  associates={associates}
                />
              ) : appointments?.length > 0 ? (
                appointments.map((appointment, i) => (
                  <RenderAppointments
                    serviceRequest={serviceRequest}
                    appointment={appointment}
                    setLightboxImages={setLightboxImages}
                    addAttachmentToSR={addAttachmentToSR}
                    setEditAppointment={setEditAppointment}
                    setImagePopUp={setImagePopUp}
                    getServiceRequestData={getServiceRequestData}
                  />
                ))
              ) : (
                <p className="px-4">No Appointments Scheduled</p>
              )}
            </div>
          </>
        ) : (
          <CircularProgress className="mx-auto p-4" />
        )}

        {imagePopUp.display && (
          <MaterialModal
            className="p-0"
            open={imagePopUp.display}
            setOpen={() => {
              setImagePopUp({ display: false, photoIndex: null });
            }}
          >
            <Carousel
              lightboxImages={{
                images: [...lightboxImages],
                photoIndex: imagePopUp.photoIndex,
              }}
            />
          </MaterialModal>
        )}

        <MaterialModal
          open={galleyModal}
          setOpen={(_) => {
            setGalleryModal(false);
          }}
          label="new-user-modal"
          describedby="create-new-user"
        >
          <SRGallery
            // imgList={(serviceRequest?.mediaUrls || []).concat(
            //   serviceRequest?.ticketMedia
            // )}
            imgList={[
              ...new Set(
                (serviceRequest?.ticketMedia || []).filter(
                  (d) =>
                    !(serviceRequest?.blacklistedTicketMedia || []).includes(d)
                )
              ),
            ]}
            onCheckCB={(checked, item) => {
              let status = tempImages.includes(item);
              status
                ? setTempImages(tempImages.filter((i) => i !== item))
                : setTempImages([...tempImages, item]);
              // if (checked) {
              //   setData((data) => {
              //     return deepUpdate(
              //       "mediaUrls",
              //       [...(data.mediaUrls || []), item],
              //       data
              //     );
              //   });
              // } else {
              //   setData((data) => {
              //     return deepUpdate(
              //       "mediaUrls",
              //       data.mediaUrls?.filter((u) => u !== item),
              //       data
              //     );
              //   });
              // }
            }}
            onSelectCB={(item) => {
              setSelectedImage(item);
            }}
            onSubmitCB={() => {
              setGalleryModal(false);

              var data = Object.assign({}, serviceRequest);
              delete data["appointments"];

              createSR({ ...data, mediaUrls: tempImages })
                .then(() => {
                  getServiceRequestData();
                  alertSuccess("Successfully Updated Service Request");
                })
                .catch((_) => alertError("Error Updating Service Request"));
              toggleChangeBuilding((_) => false);
              setClientBuildings((_) => undefined);
            }}
            mediaUrls={tempImages}
            srSelectedImages={serviceRequest?.mediaUrls}
          />
        </MaterialModal>

        {selectedImage && (
          <MaterialModal
            className="p-0"
            open={selectedImage}
            setOpen={() => {
              setSelectedImage();
            }}
          >
            <Carousel
              lightboxImages={{ images: [selectedImage], photoIndex: 0 }}
            />
          </MaterialModal>
        )}

        {/* {imagePopUp && (
          <AutoRotatingCarousel
            open={true}
            onClose={() => setImagePopUp(false)}
            autoplay={false}
            mobile={false}
            style={{
              position: "absolute",
              backgroundColor: colors.blueGrey[50]
            }}
            classes={{ slide: "slide_parent" }}
            hideArrows={lightboxImages.length > 0 ? false : true}
          >
            {lightboxImages.map((imageSRC, index) => (
              <Slide
                media={getMedia(imageSRC)}
                classes={{ text: "text_parent" }}
                style={{
                  backgroundColor: colors.blueGrey[50],
                  height: "fit-content"
                }}
                mediaBackgroundStyle={{ backgroundColor: colors.blueGrey[50] }}
                title=""
                subtitle=""
              />
            ))}
          </AutoRotatingCarousel>
        )} */}
      </DndProvider>
    </div>
  );
}
