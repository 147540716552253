import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  getTicketsCommercial,
  getTicketsForBuilding,
  getTicketsIndividual,
} from "../../api/Api";
import { SearchIcon } from "../common/AppIcons";
import { MessagesContactIcon, MessagesTicketIcon } from "./ClientAppIcons";
import MessagesFilter from "./MessagesFilter";

export default function MessagesSideFilter({
  clientId,
  buildings,
  prefillContact,
  selectedContactCB,
  selectedContactId,
  selectedTicketCB,
  buildingId,
  tab,
  tabCB,
  type,
  clientClass = "INDIVIDUAL",
  onLoadCB,
}) {
  const [searchText, setSearchText] = useState("");

  const [tickets, setTickets] = useState([]);
  const [page, setPage] = useState(0);
  const individualClientId = type === "INDIVIDUAL" ? clientId : undefined;

  useEffect(() => {
    if (tab === "TICKETS") {
      if (type === "BUILDING") {
        getTicketsForBuilding(buildingId, "", page, 30).then((data) => {
          setTickets(data.content);
        });
      }
    }
  }, [buildingId]);

  useEffect(() => {
    if (tab === "TICKETS") {
      if (type === "COMMERCIAL") {
        getTicketsCommercial(clientId, "", page, 30).then((data) => {
          setTickets(data.content);
        });
      } else if (type === "INDIVIDUAL") {
        getTicketsIndividual(clientId, "", page, 30).then((data) => {
          setTickets(data.content);
        });
      } else if (type === "BUILDING") {
        getTicketsForBuilding(buildingId, "", page, 30).then((data) => {
          setTickets(data.content);
        });
      }
    }
  }, [clientId, tab, buildingId]);

  return (
    <div className="flex flex-col border border-newGray-1500 w-full md:w-96 rounded-lg p-4 md:max-h-lscreen md:overflow-y-auto">
      <div className="py-2 flex flex-row justify-between gap-1">
        <div
          className={
            "rounded px-4 py-2 flex flex-row items-center justify-center " +
            (tab === "TICKETS" ? "bg-newBlue-400" : "bg-newGray-1400")
          }
          onClick={() => {
            tabCB("TICKETS");
          }}
        >
          <div>
            <MessagesTicketIcon
              color={tab === "TICKETS" ? "#fff" : "rgba(107, 108, 123, 1)"}
            />
          </div>
          <div
            className={
              "text-sm px-1  " +
              (tab === "TICKETS" ? "text-white" : "text-newGray-800")
            }
          >
            Tickets
          </div>
        </div>
        <div
          className={
            "rounded px-4 py-2 flex flex-row items-center  justify-center " +
            (tab === "CONTACTS" ? "bg-newBlue-400" : "bg-newGray-1400")
          }
          onClick={() => {
            tabCB("CONTACTS");
          }}
        >
          <div>
            <MessagesContactIcon
              color={tab === "CONTACTS" ? "#fff" : "rgba(107, 108, 123, 1)"}
            />
          </div>
          <div
            className={
              "text-sm px-1 " +
              (tab === "CONTACTS" ? "text-white" : "text-newGray-800")
            }
          >
            Contacts
          </div>
        </div>
      </div>

      <div>
        <div className="flex flex-row items-center px-2 rounded bg-newGray-1400 ">
          <div
            onClick={() => {
              document.getElementById("search_input").focus();
            }}
          >
            <SearchIcon className="h-5 w-5 text-gray-500" />
          </div>
          <input
            id="search_input"
            className="focus:outline-none p-1 text-gray-700 bg-newGray-1400 text-sm"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>
      </div>

      {tab === "TICKETS" && (
        <div className="flex flex-col">
          <InfiniteScroll
            dataLength={tickets.length}
            next={() => {
              setPage(page + 1);
            }}
          >
            {tickets.map((ticket) => (
              <div
                className="hover:bg-newBlue-500 py-2 border-b flex flex-row items-center"
                onClick={() => {
                  selectedTicketCB(ticket);
                }}
              >
                <div>
                  <MessagesTicketIcon color={"#000"} />
                </div>
                <div className="px-1">{ticket.title}</div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      )}

      {tab === "CONTACTS" && (
        <MessagesFilter
          type={type}
          onLoadCB={onLoadCB}
          selectedContactId={selectedContactId}
          individualClientId={individualClientId}
          prefillContact={prefillContact}
          clientId={clientId}
          buildingId={buildingId}
          buildings={buildings}
          selectedContactCB={selectedContactCB}
          searchText={searchText}
          clientClass={clientClass}
        />
      )}
    </div>
  );
}
