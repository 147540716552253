/* Untyped file generated from Text.res by genType. */
/* eslint-disable */

import {default as makeNotChecked} from './Text.jsx';

// In case of type error, check the type of 'make' in 'Text.re' and './Text.jsx'.
export const makeTypeChecked = makeNotChecked;

// Export 'make' early to allow circular import from the '.bs.js' file.
export const make = makeTypeChecked;
