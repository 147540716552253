import { useEffect, useState } from "react";

import { Tab } from "@headlessui/react";
import { Link, navigate } from "raviger";

import { alertError } from "../../actions/AlertActions";
import { getSupplier } from "../../api/Api";
import { TicketBagIcon } from "../../components/clients/ClientAppIcons";
import { LeftArrowWithTailIcon } from "../../components/common/AppIcons";
import { SuspenseBoundary } from "../../components/common/core/SuspenseBoundary";
import MapComponent from "../../components/scheduler/MapComponent";
import Messages from "../../components/suppliers/Messages";
import { properString } from "../../utils/StringUtils";
import PageLayout from "../Common/PageLayout";
import { BillingAddress, SupplierDetailsData } from "./Service";
import SupplierDetails from "./SupplierDetails";
import SupplierLocations from "./SupplierLocations";

type HeaderLeftProps = {
  name: string;
  typeOfSupplies: string[];
};

type SuppliersHomeScreenProps = {
  supplierId: number;
  tab: string;
  selectedId: number;
};

type ContentProps = {
  tab: string;
  supplierId: number;
  supplierDetails?: SupplierDetailsData;
  selectedId: number;
};

function headerLeft({ name, typeOfSupplies }: HeaderLeftProps) {
  const suppliesList = typeOfSupplies.map((item) =>
    properString(item.toLowerCase())
  );

  return (
    <div className="mb-5">
      <Link
        href={`/suppliers`}
        className=" flex flex-row items-center hover:bg-gray-200 rounded-md flex-grow-0 flex-shrink"
      >
        <div>
          <LeftArrowWithTailIcon className="h-3 w-3 fill-newBlue-400" />
        </div>
        <div className="px-1 text-sm text-newBlue-400"> All Suppliers</div>
      </Link>
      <div className="text-gray-900 text-2xl mt-6 mb-5">{name}</div>
      <div className="flex flex-row items-center">
        <TicketBagIcon />
        <div className="text-base text-gray-800 mr-7 ml-2">
          {`Types of Supplies: ${suppliesList.join(", ")}`}
        </div>
      </div>
    </div>
  );
}

function HeaderRight(supplierDetails?: SupplierDetailsData) {
  const [building, setBuilding] = useState<BillingAddress>();

  useEffect(() => {
    setBuilding(supplierDetails?.commercialClient?.billingAddress);
  }, [supplierDetails?.commercialClient?.billingAddress]);

  return (
    <div className="h-48 w-112 pr-8">
      {building && (
        <MapComponent
          data={
            building.geocodeLatitude && building.geocodeLongitude
              ? [
                  {
                    label: "Location",
                    lat: building.geocodeLatitude,
                    lng: building.geocodeLongitude,
                  },
                ]
              : []
          }
          center={{
            lat: "34.0522",
            lng: "-118.2437",
          }}
        />
      )}
    </div>
  );
}

const SupplierHomeTabs = [
  { label: "Details", value: "details" },
  { label: "Supplier Locations", value: "locations" },
  { label: "Messages", value: "messages" },
];

function Content({
  tab,
  supplierId,
  supplierDetails,
  selectedId,
}: ContentProps) {
  const [serviceRequest, setServiceRequest] = useState({});
  const [tickets] = useState([]);
  return (
    // <SuspenseBoundary
    //   name="Client Home"
    //   waitFor=""
    //   fallback={undefined}
    //   fallbackClassName={undefined}
    // >
    <Tab.Group
      selectedIndex={SupplierHomeTabs.findIndex((item) => item.value === tab)}
      onChange={(index) => {
        console.log(SupplierHomeTabs[index].value);

        navigate(`/suppliers/${supplierId}/${SupplierHomeTabs[index].value}`);
      }}
    >
      <div className="flex flex-row w-full border-b">
        <div className="p-4">
          <div className=" border-gray-200">
            <Tab.List className="-mb-px flex space-x-8" aria-label="Tabs">
              {SupplierHomeTabs.map((item, _index) => {
                return (
                  <Tab key={item.value} className={"focus:outline-none"}>
                    {({ selected }) => (
                      <span
                        className={
                          "cursor-pointer border-transparent text-base hover:text-gray-700  whitespace-nowrap py-4 px-1 border-b-2 font-medium " +
                          (selected
                            ? "text-newBlue-400 border-b-4 border-newBlue-400"
                            : "text-newGray-800")
                        }
                      >
                        {item.label}
                      </span>
                    )}
                  </Tab>
                );
              })}
            </Tab.List>
          </div>
        </div>
      </div>
      {/* Ensuring that the components are not rendered when a client switch happens */}
      {/* Otherwise internal effects will trigger redirects */}

      <Tab.Panels>
        <Tab.Panel>
          <SuspenseBoundary
            name="Supplier Details"
            waitFor={supplierDetails}
            fallback={undefined}
            fallbackClassName={undefined}
          >
            <SupplierDetails supplierId={supplierId} />
          </SuspenseBoundary>
        </Tab.Panel>
        <Tab.Panel>
          <SuspenseBoundary
            name="Associate Appointments"
            waitFor={supplierDetails?.buildings && tab === "locations"}
            fallback={undefined}
            fallbackClassName={undefined}
          >
            <SupplierLocations supplierId={supplierId} />
          </SuspenseBoundary>
        </Tab.Panel>

        <Tab.Panel>
          <SuspenseBoundary
            name="Associate Messages"
            waitFor={tab === "messages"}
            fallback={undefined}
            fallbackClassName={undefined}
          >
            <Messages
              supplierId={supplierId}
              selectedContact={selectedId}
              setServiceRequest={setServiceRequest}
              serviceRequest={serviceRequest}
              tickets={tickets}
            />
          </SuspenseBoundary>
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
}
export default function SuppliersHomeScreen({
  selectedId,
  supplierId,
  tab,
}: SuppliersHomeScreenProps) {
  const [supplierDetails, setSupplierDetails] = useState<SupplierDetailsData>();

  useEffect(() => {
    getSupplier(supplierId)
      .then((data) => {
        setSupplierDetails(data as SupplierDetailsData);
      })
      .catch((_) => alertError("Error Loading Supplier"));
  }, [supplierId]);
  return (
    <PageLayout
      content={Content({
        tab,
        supplierId,
        supplierDetails,
        selectedId,
      })}
      headerLeft={headerLeft({
        name: supplierDetails?.name ?? "",
        typeOfSupplies: supplierDetails?.typeOfSupplies ?? [],
      })}
      headerRight={HeaderRight(supplierDetails)}
    ></PageLayout>
  );
}
