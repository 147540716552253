import React, { useEffect, useState } from "react";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { Button, Typography } from "@mui/material";
import { navigate } from "raviger";

import { unReadMessage } from "../../../actions/ChatActions";
import {
  subscribeTopic,
  unsubscribeTopic,
} from "../../../actions/NotificationActions";
import {
  assignContactToBuildings,
  getTerritory,
  listClientContacts,
  updateConversation,
} from "../../../api/Api";
// import audio from "../../../assets/not.wav";
import longAudio from "../../../assets/long_tone.wav";
import dispatcher from "../../../dispatcher";
import CreateContact from "../../clients/CreateContact";
import CreateClient from "../../clients/LegacyCreateClient";
// import CreateClientModal from "./CreateClientModal";
import ListClients from "../../clients/ListClients";
import ListProperties from "../../clients/ListProperties";
import MaterialModal from "../../common/MaterialModal";
import ConversationsApp from "./ConversationsApp";
import LoadingScreen from "./LoadingScreen";
import { getTerritoryFromZIP } from "./zip";

export default function ChatUI({
  messages,
  dispatchMessage,
  twilioAuth,
  setMessageReceived,
}) {
  const [newChats, setNewChats] = useState([]);
  const [clearFlag, setClearFlag] = useState(true);
  const [userName, setUserName] = useState("");
  const [conversation, setConversation] = useState();
  const [selectedConversationContactId, setSelectedConversationContactId] =
    useState(1);
  const [show, setShow] = useState(false);
  const [chirp, setChirp] = useState(new Audio(longAudio));
  const [mute, setMute] = useState(false);

  const [selectedConversationId, setSelectedConversationId] = useState(null);

  const [selected, setSelected] = useState({
    phone: "",
    email: "",
    clientId: "",
    display: false,
    link: "EXISTING",
  });

  const [territories, setTerritories] = useState([]);

  const [linkBuildingModal, setLinkBuildingModal] = useState({
    display: false,
    clientId: null,
    data: [],
  });
  const [clientContact, setClientContact] = useState({});

  const [selectedProperty, setSelectedProperty] = useState([]);
  const [selectedContactId, setSelectedContactId] = useState();

  useEffect(() => {
    getTerritory((res) => {
      setTerritories(res);
    });
  }, []);

  useEffect(() => {
    if (mute) chirp.pause();
    else {
      if (newChats.length > 0) {
        chirp.play();
      }
    }
  }, [mute]);

  useEffect(() => {
    if (newChats.length > 0 && !mute) {
      // chirp.addEventListener(
      //   "ended",
      //   function () {
      //     this.currentTime = 0;
      //     this.play();
      //   },
      //   false
      // );
      chirp.loop = true;
      chirp.play();

      // ChirpChirp.pause();
    } else {
      chirp.pause();
      chirp.currentTime = 0;
    }
  }, [newChats]);

  const handleAction = (action) => {
    if (action.actionType === "NOTIFICATION") {
      if (action.topic === "/notifications/public_chat/delete/") {
        var chats = newChats.filter((chat) => {
          return chat.conversationSID !== action.data.conversationSID;
        });
        setNewChats(chats);
      }
    }
    if (action.actionType === "NOTIFICATION") {
      if (action.topic === "/notifications/public_chat") {
        // var ChirpChirp = new Audio(audio);
        // ChirpChirp.play();
        setMessageReceived(true);
        setNewChats((current) => {
          console.log("NewChats", [...current, action.data]);
          return [...current, action.data];
        });
      }
    }

    action.actionType === "NOTIFICATION" &&
      action.topic.includes("/conversation_joined") &&
      setNewChats((current) => {
        console.log("NewChats", [...current, action.data]);
        return current.filter(
          (data) => data.conversationSID !== action.data.conversationSID
        );
      });

    if (newChats.length === 0) {
      // ChirpChirp.pause();
    }
  };

  useEffect(() => {
    dispatcher.register(handleAction);
    window.dispatcher = dispatcher;
  }, []);

  useEffect(() => {
    if (twilioAuth) {
      subscribeTopic({
        topic: "/notifications/public_chat",
      });
      subscribeTopic({
        topic: "/notifications/conversation_joined",
      });
      subscribeTopic({
        topic: "/notifications/public_chat/delete/",
      });
      return () => {
        unsubscribeTopic({
          topic: "/notifications/public_chat",
        });
        unsubscribeTopic({
          topic: "/notifications/conversation_joined",
        });
        unsubscribeTopic({
          topic: "/notifications/public_chat/delete/",
        });
      };
    }
  }, [twilioAuth]);
  return (
    <div className="flex justify-center w-full max-w-xs">
      <div className="w-full m-4 relative bg-white shadow-lg border border-gray-300 ">
        <div className="h-10 p-4 flex items-center w-full bg-newBlue-1200">
          {userName && (
            <ChevronLeftIcon
              className="w-6 h-6 rounded-full bg-red-200 flex items-center justify-center cursor-pointer"
              onClick={(_) => {
                setShow(false);
                setUserName("");
                setSelectedConversationContactId(1);
                setSelectedConversationId(null);
                setClearFlag((clearFlag) => !clearFlag);
              }}
            />
          )}
          <span className="px-2 text-white font-medium text-sm">
            Live Chat {userName}
          </span>
          <span
            className="rounded-full bg-newGray-1000 p-1"
            onClick={() => {
              setMute((current) => !current);
            }}
          >
            {mute ? (
              <VolumeOffIcon sx={{ color: "#fff", fontSize: 20 }} />
            ) : (
              <VolumeUpIcon sx={{ color: "#fff", fontSize: 20 }} />
            )}
          </span>
          {!selectedConversationContactId && (
            <button
              className="flex justify-end"
              onClick={(_) => {
                //new flow
                setShow((show) => !show);
                setSelected((current) => {
                  return {
                    ...current,
                    clientId: "",
                    link: "EXISTING",
                    display: true,
                  };
                });
              }}
            >
              Create Client
            </button>
          )}
        </div>

        {twilioAuth ? (
          <ConversationsApp
            token={twilioAuth.token}
            identity={twilioAuth.identity}
            newChats={newChats}
            clearFlag={clearFlag}
            setUserName={setUserName}
            setMessageReceived={setMessageReceived}
            setSelectedConversationContactId={setSelectedConversationContactId}
            setSelectedConversationId={setSelectedConversationId}
            selectedConversationId={selectedConversationId}
            setConversation={setConversation}
          />
        ) : (
          <LoadingScreen />
        )}
      </div>
      {/* {show && (
        
        // <CreateClientModal
        //   show={show}
        //   setShow={setShow}
        //   conversation={conversation}
        //   setUserName={setUserName}
        // />
      )} */}

      <MaterialModal
        open={selected.display ? true : false}
        setOpen={(_) =>
          setSelected((current) => {
            return { ...current, display: false };
          })
        }
        extended
      >
        <>
          {selected && selected.link === "NEW" && selected.display && (
            <CreateClient
              // displayInCol={true}
              clientData={{
                contact: {
                  phone: conversation
                    ? conversation.channelState.attributes.phoneOrEmail
                    : "",
                  firstName: conversation
                    ? conversation.channelState.attributes.first_name
                    : "",
                  lastName: conversation
                    ? conversation.channelState.attributes.last_name
                    : "",
                },
                commercialClient: {
                  zip: conversation
                    ? conversation.channelState.attributes.zipcode
                    : "",
                },
                building: {
                  territory: conversation
                    ? conversation.channelState.attributes.zipcode
                      ? getTerritoryFromZIP(
                          parseInt(conversation.channelState.attributes.zipcode)
                        )
                      : territories[0].value
                    : "",
                  address: {
                    zip: conversation.channelState.attributes.zipcode,
                  },
                },
              }}
              onSuccess={({ contactId, clientId }) => {
                setShow((current) => !current);
                console.log(
                  "before updating conversation",
                  contactId,
                  clientId
                );
                updateConversation({
                  conversationSID: conversation.sid,
                  clientId: clientId,
                  contactId: contactId,
                }).then((data) => {
                  setUserName(data.user_name);
                });
                unReadMessage(conversation.sid);

                setSelected((selected) => {
                  return { ...selected, clientId: "", display: false };
                });
              }}
            />
          )}
          {selected && selected.link === "EXISTING" && !selected.clientId && (
            <ListClients
              newClient={(_) =>
                setSelected((selected) => {
                  return { ...selected, link: "NEW", display: true };
                })
              }
              onSelect={(clientId, clientType) =>
                setSelected((selected) => {
                  return { ...selected, clientId: clientId, type: clientType };
                })
              }
            />
          )}

          {selected && selected.link === "EXISTING" && selected.clientId && (
            <CreateContact
              baseData={{
                client: { clientId: selected.clientId },
                phone: conversation.channelState.attributes.phoneOrEmail,
                firstName: conversation.channelState.attributes.first_name,
                lastName: conversation.channelState.attributes.last_name,
              }}
              successCB={(contactId) => {
                setSelectedContactId(contactId);

                updateConversation({
                  conversationSID: conversation.sid,
                  clientId: selected.clientId,
                  contactId: contactId,
                }).then((data) => {
                  setUserName(data.user_name);
                });
                unReadMessage(conversation.sid);
                setSelected((selected) => {
                  return { ...selected, clientId: "", display: false };
                });

                if (selected.type === "COMMERCIAL") {
                  listClientContacts(selected.clientId).then((contacts) => {
                    let contact = contacts.find((item) => {
                      if (item.contactId === contactId) return true;
                    });

                    setClientContact(contact);
                  });

                  setLinkBuildingModal({
                    clientId: selected.clientId,
                    display: true,
                    data: [],
                  });
                } else {
                  setLinkBuildingModal({
                    clientId: selected.clientId,
                    display: false,
                    data: [],
                  });

                  navigate(
                    `/clients/${selected.clientId}/messages/${contactId}`
                  );
                }
              }}
            />
          )}
        </>
      </MaterialModal>

      <MaterialModal
        open={linkBuildingModal.display}
        setOpen={(_) =>
          setLinkBuildingModal((_) => {
            return { clientId: null, display: false, data: [] };
          })
        }
        extended
      >
        <Typography variant="h6" className="mb-4">
          Would you like to link {clientContact?.firstName}{" "}
          {clientContact?.lastName} to a building?
        </Typography>
        <ListProperties
          clientId={linkBuildingModal.clientId}
          multiple={true}
          multiSelectCB={(properties) => {
            setSelectedProperty(properties);
          }}
        />
        <div className="py-4 flex-row">
          <Button
            onClick={(_) => {
              assignContactToBuildings(
                selectedContactId,
                selectedProperty
              ).then(() => {
                navigate(`/clients/${selected.clientId}/messages`);
              });
            }}
            color="primary"
            className="float-right p-4"
          >
            Link
          </Button>
          <Button
            onClick={(_) => {
              navigate(`/clients/${selected.clientId}/messages`);
            }}
            color="primary"
            className="float-right p-4"
          >
            Cancel
          </Button>
        </div>
      </MaterialModal>
    </div>
  );
}
