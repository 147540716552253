import React, { useState, useEffect } from "react";

import { alertError } from "../../actions/AlertActions";
import { getMessagesForExternal, getContactMessages } from "../../api/Api";
import { makePageable } from "../../utils/PageUtils";
import MessagesList from "../clients/MessagesList";
import ErrorWrap from "../common/ErrorWrap";

export default function ExternalMessaging({
  fromActual,
  contactIds,
  refreshFlag,
  setMessageData,
}) {
  const [messages, setMessages] = useState({ content: [] });

  const phoneNumberFromContent = (content) => {
    if (!content) return "";

    var re = /(?:[-+() ]*\d){10,13}/gm;

    var res = content.match(re);

    if (res) {
      res.map(function (s) {
        return s.trim();
      });

      if (res[0]) {
        return "+1 " + res[0];
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const validateEmail = (email) => {
    if (!email) return "";
    var re = email.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/);

    if (re) {
      return re[0];
    } else {
      return "";
    }
  };

  const onDataFetch = (data, next) => {
    if (next) {
      setMessages((messages) => {
        return {
          ...data,
          content: [...messages.content, ...data.content],
        };
      });
    } else {
      if (setMessageData) {
        setMessageData({
          phone: phoneNumberFromContent(data.content[0].content),
          email: validateEmail(data.content[0].content),
        });
      }

      setMessages(data);
    }
  };

  const fetchMessages = (next = false) => {
    let size = 15;
    const currentPage = messages.pageable?.pageNumber ?? -1;
    if (fromActual) {
      getMessagesForExternal(
        makePageable({ fromActual: fromActual }, currentPage, next, size)
      )
        .then((data) => {
          onDataFetch(data, next);
        })
        .catch((_) => alertError("Error Fetching Messages"));
    }
    if (contactIds) {
      getContactMessages(
        makePageable({ contactIds: contactIds }, currentPage, next, size)
      )
        .then((data) => {
          onDataFetch(data, next);
        })
        .catch((_) => alertError("Error Fetching Messages"));
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [fromActual, contactIds, refreshFlag]);

  return (
    <div>
      <ErrorWrap>
        <MessagesList
          messages={messages}
          pb={true}
          fetchNextPage={() => {
            fetchMessages(true);
          }}
        />
      </ErrorWrap>
    </div>
  );
}
