export const conditionalArrayEntry = (condition, value) => {
  if (Array.isArray(value)) {
    return condition ? value : [];
  } else {
    return condition ? [value] : [];
  }
};

export const conditionalObjectEntry = (condition, proxyObj) => {
  return condition ? proxyObj : {};
};
