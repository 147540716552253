/* Untyped file generated from FileUpload.res by genType. */
/* eslint-disable */

import {default as makeNotChecked} from './FileUpload.jsx';

// In case of type error, check the type of 'make' in 'FileUpload.re' and './FileUpload.jsx'.
export const makeTypeChecked = makeNotChecked;

// Export 'make' early to allow circular import from the '.bs.js' file.
export const make = makeTypeChecked;
