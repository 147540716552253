import { outlineFields } from "../../../utils/FormUtils";
import { make as MaterialForm } from "../../common/form/MaterialForm.bs";
import { useContactFields } from "../../common/form/constants";

export const positionField = {
  name: "position",
  optional: true,
  inputType: "SELECT",
  options: [
    // { label: "Manager", value: "MANAGER" },
    { label: "Owner", value: "OWNER" },
    { label: "Tenant", value: "TENANT" },
  ],
};

const contactFields = [
  { name: "profilePicture", inputType: "IMAGE" },
  { name: "firstName" },
  { name: "lastName" },
  {
    name: "phone",
    inputType: "PHONE",
  },
  { name: "email", inputType: "EMAIL" },
];

export default function ContactFormFields({
  data,
  onChange,
  clientClass = "INDIVIDUAL",
  clientData,
}) {
  const isIndividual = clientClass === "INDIVIDUAL";
  const isHOA = clientClass === "HOA";
  const isPMC = clientClass === "PROPERTY_MANAGEMENT";
  const skippable = !isIndividual;

  const contactFields = useContactFields(
    clientData?.billingAddress || {},
    isHOA
  );
  const relavantContactFields = contactFields.filter((field) => {
    let allowField = true;
    // Only for Individual, allow position field
    if (!isIndividual && field.name === "position") {
      allowField = false;
    }
    // For HOA & Individual, allow unitField
    if (!isHOA && field.name === "unit") {
      allowField = false;
    }
    return allowField;
  });

  return (
    <div className="flex flex-col gap-4 items-center bg-white h-full w-72 sm:w-full">
      <h1 className="text-left">
        {isPMC ? "Manager" : isHOA ? "Board Member" : "Contact"} Details
      </h1>
      {(
        <MaterialForm
          className="w-72 sm:w-96 flex flex-col gap-3"
          data={data}
          onChange={onChange}
          onSubmit={() => {}}
          renderArray={outlineFields(relavantContactFields)}
        />
      ) || "Loading..."}
      {skippable && (
        <div className="w-72 sm:w-96 flex gap-3 text-gray-500 text-sm">
          <span className="font-bold">PS:</span> Adding a Contact is optional,
          you can submit the form without adding contact details
        </div>
      )}
    </div>
  );
}
