import React, { useEffect, useState } from "react";

import PeopleOutlined from "@mui/icons-material/PeopleOutlined";
import { Typography, Button } from "@mui/material";

import { alertSuccess, alertError } from "../../actions/AlertActions";
import { associateContactUpdate } from "../../api/Api";
import { deepUpdate } from "../../utils/StateUtils";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import { formAssociateContact } from "../common/form/constants";

export default function CreateAssociateContact({
  baseData,
  associateId,
  onSubmitCB,
  successCB,
  renderFlexComponent,
}) {
  const [data, setData] = useState(baseData ?? {});
  const onChange = (update) => {
    const { name, value } = update;
    setData((data) => deepUpdate(name, value, data));
  };

  useEffect(() => {
    setData(baseData);
  }, [baseData]);
  const onSubmit = (event) => {
    event.preventDefault();

    const payload = {
      associateId: associateId,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
    };

    if (data.contactId) {
      payload["contactId"] = data.contactId;
    }

    associateContactUpdate(payload)
      .then((data) => {
        successCB();
        payload.contactId
          ? alertSuccess("Successfully Updated Contact")
          : alertSuccess("Successfully Created Contact");
      })
      .catch((e) => {
        const errorMessage =
          e.readableMessage ?? data.clientId
            ? "Error Updating Contact"
            : "Error Creating Contact";
        alertError(errorMessage);
      });
  };
  return (
    <div className="p-4 max-w-sm">
      <div className="flex gap-2">
        <PeopleOutlined />
        <Typography variant="h5">Contact Details</Typography>
      </div>
      <MaterialForm
        className="px-4 py-2 w-full"
        data={data}
        renderArray={formAssociateContact(data)}
        onSubmit={onSubmit}
        onChange={onChange}
      />

      <div className="flex flex-row-reverse items-center justify-between">
        <Button color="primary" className="float-right p-4" onClick={onSubmit}>
          Save
        </Button>
        {renderFlexComponent && renderFlexComponent()}
      </div>
    </div>
  );
}
