import React from "react";

import { Avatar } from "@mui/material";

import PopOverText from "../common/PopOverText";

import { jobTypes } from "../common/form/constants";

const PopOverContents = ({ clients, setForUpdate, backgroundFor }) => {
  return (
    <div
      style={{ minWidth: "300px" }}
      className="flex w-full p-3 max-w-sm flex-col"
    >
      {clients.map((ele, ele_index) => (
        // <PopOverText
        //   text={<PopOverContent client={ele} setForUpdate={setForUpdate} />}
        // >
        <div className="py-1">
          <div
            key={ele_index}
            className={
              "w-full h-full flex flex-col px-2 py-1 rounded cursor-pointer " +
              backgroundFor(ele.appointmentStatus)
            }
            style={{ minWidth: "100px" }}
            onClick={(_) =>
              setForUpdate({
                ...ele,
                startTime: ele.timelineStart,
                endTime: ele.timelineEnd,
                associate: ele.associateId,
              })
            }
          >
            <div className="flex flex-row items-center justify-between gap-1">
              <span className="font-semibold text-normal text-black my-auto">
                {ele.clientName}&nbsp;
              </span>
              <span className="text-sm text-newGray-300">
                {`${ele.ticketId}-` +
                  (!ele.isDraft
                    ? `SR${ele.serviceRequestIndex}`
                    : `I${ele.serviceRequestIndex}`)}
              </span>
            </div>
            <div className="flex flex-row items-center justify-between gap-1">
              <span className="text-newGray-300 text-normal">
                {jobTypes.find((j) => j.value === ele.jobType)?.label}
              </span>
              <span
                className="text-newGray-300"
                style={{ fontSize: "0.70rem" }}
              >
                {new Date(ele.timelineStart).toLocaleTimeString()} to{" "}
                {new Date(ele.timelineEnd).toLocaleTimeString()}
              </span>
            </div>
          </div>
        </div>
        // </PopOverText>
      ))}
    </div>
  );
};

export default function MultiScheduleItem({
  territory,
  setForUpdate,
  current,
  backgroundFor,
  slotDuration,
}) {
  return (
    <td
      className={
        "text-sm relative group border-newGray-500 cursor-pointer p-1 border-b" +
        (slotDuration % 2 === 0 ? "" : "")
      }
      colSpan={slotDuration}
    >
      <PopOverText
        text={
          <PopOverContents
            territory={territory}
            clients={current}
            setForUpdate={setForUpdate}
            backgroundFor={backgroundFor}
          />
        }
      >
        <div className="bg-newGray-400 p-1 flex flex-row items-center gap-2 rounded border border-newBlue-300">
          {current.map((ele, ele_index) => (
            <div
              key={ele_index}
              className={
                "flex flex-grow items-center px-2 py-1 rounded " +
                backgroundFor(ele.appointmentStatus)
              }
            >
              <div className="h-8 w-8 rounded-full bg-black text-white text-lg flex items-center justify-center">
                {ele.contactProfilePic ? (
                  <Avatar src={ele.contactProfilePic} className="h-6 w-6 mr-2">
                    {ele.clientName[0]}
                  </Avatar>
                ) : (
                  <span className="relative bottom-px">
                    {" "}
                    {ele.clientName[0]}
                  </span>
                )}
              </div>

              <span className="text-sm text-newGray-300 px-2">
                {`${ele.ticketId}-` +
                  (!ele.isDraft
                    ? `SR${ele.serviceRequestIndex}`
                    : `I${ele.serviceRequestIndex}`)}
              </span>
            </div>
          ))}
        </div>
      </PopOverText>
    </td>
  );
}
