import { outlineFields } from "../../../utils/FormUtils";
import { make as MaterialForm } from "../../common/form/MaterialForm.bs";
import { buildingTypes, territories } from "../../common/form/constants";
import { positionField } from "./ContactFormFields";

const _legacyTerritoryField = {
  name: "territory",
  inputType: "SELECT",
  options: territories,
};

const buildingFields = [
  { name: "coverImage", inputType: "IMAGE" },
  { name: "propertyShortName" },
  {
    name: "buildingType",
    inputType: "SELECT",
    options: buildingTypes,
  },
  { name: "accessCode" },
  // _legacyTerritoryField,
  {
    name: "address",
    inputType: "ADDRESS",
  },
];

export default function BuildingFormFields({
  data,
  onChange,
  clientClass,
  onSkip,
}) {
  const skippable =
    !(data?.buildingId || false) && clientClass === "PMC" && !data?.clientId;
  const formFields =
    clientClass &&
    clientClass !== "PMC" &&
    clientClass !== "HOA_OWNER" &&
    clientClass !== "COMMERCIAL"
      ? [...buildingFields, positionField]
      : buildingFields;
  return (
    <div className={"flex flex-col gap-4 items-center p-4 bg-white h-full"}>
      <h1 className="text-left text-lg w-72 sm:w-full">
        Building Details {skippable && "(Optional)"}
      </h1>

      {(
        <MaterialForm
          className={"flex flex-col gap-3 w-72 sm:w-80"}
          data={data}
          onChange={onChange}
          onSubmit={() => {}}
          renderArray={outlineFields(formFields)}
        />
      ) || "Loading..."}

      {skippable && (
        // Hint: Adding a building is optional for Management Companies, you can submit the form without adding building details
        <div className="flex items-center gap-2 w-72 sm:w-80">
          <div className="text-gray-500 text-sm">
            <span className="font-bold">PS:</span> Adding a building is optional
            for Management Companies, you can submit the form without adding
            building details
          </div>
          <button
            className="border rounded text-sm px-2 py-1 h-10 w-24 mr-10 bg-gray-200 text-gray-700 hover:bg-gray-300 hover:text-gray-800"
            onClick={onSkip}
          >
            Skip
          </button>
        </div>
      )}
    </div>
  );
}
