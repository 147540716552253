import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

// import Linkify from 'react-linkify';
// import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
// import { blueGrey } from "@mui/material/colors";
import { Typography } from "@mui/material";

import { alertError } from "../../actions/AlertActions";
// import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import { reassignComm } from "../../api/Api";
import { deepUpdate } from "../../utils/StateUtils";
import Carousel from "../common/Carousel";
import MaterialModal from "../common/MaterialModal";
import { getUrlExtension, processUrl } from "../common/PreviewLink";
import BasicConversationApp from "../crm/chat/BasicConversationApp";
import MessageBubble from "./MessageBubble";

export function getMedia(imageSRC) {
  var format = "image";
  const extension = getUrlExtension(imageSRC);
  const processedUrl = processUrl(imageSRC);
  switch (extension) {
    case "jpg":
    case "heic":
      format = "image";
      break;
    case "webm":
    case "mp4":
      format = "video";
      break;
    case "mov":
      format = "video";
      break;
    default:
      format = "image";
      break;
  }

  return format === "image" ? (
    <img src={processedUrl} />
  ) : (
    <video width="400" controls poster={processUrl(processedUrl, "jpg")}>
      <source src={processUrl(processedUrl, "webm")} type="video/webm" />
      <source src={processUrl(processedUrl, "mp4")} type="video/mp4" />
    </video>
  );
}

export default function MessagesList({
  messages,
  contacts,
  // Buildings to allow Building Tickets
  buildings,
  tickets,
  refreshCB,
  addAttachmentToSR,
  fetchNextPage,
  draggableMedia,
  title,
  associateMessaging = false,
  pb = false,
  ticketId,
  checkBoxForMedia,
  onClickCheckBoxCB,
}) {
  const endOfMessages = useRef();

  const [lightboxImages, setLightboxImages] = useState();
  const [select, setSelect] = useState({});
  const [chatModalDisplay, setChatModalDisplay] = useState(false);
  const [chatData, setChatData] = useState([]);
  const [messageContent, setMessageContent] = useState([]);

  useEffect(() => {
    setMessageContent(messages.content);
  }, [messages]);

  // const [content, setContent] = useState(messages.content);

  const onChange = (update) => {
    const { name, value } = update;
    setSelect((data) => deepUpdate(name, value, data));
  };

  const reassign = (commId, onSuccess) => {
    reassignComm({ communicationId: commId, ticketId: select.ticket })
      .then((_) => {
        refreshCB();
        onSuccess();
      })
      .catch((_) => alertError("Couldn't Reassign Message"));
  };

  return (
    <div
      id="scrollableDiv"
      className={
        "py-2 px-3  flex flex-col-reverse overflow-auto " +
        (pb ? "h-96" : "h-full max-h-screen")
      }
      style={{ backgroundColor: "white" }}
    >
      <InfiniteScroll
        dataLength={messages.content.length}
        next={fetchNextPage}
        style={{
          display: "flex",
          flexDirection: "column-reverse",
        }} //To put endMessage and loader to the top.
        inverse={true} //
        hasMore={messages.content.length < messages.totalElements}
        endMessage={
          <div className="flex justify-center mb-2">
            <div className="rounded-full py-2 px-4 bg-gray-300">
              <p className="text-xs uppercase">
                {messages.content.length === 0
                  ? "No Messages"
                  : "End of Communication"}
              </p>
            </div>
          </div>
        }
        loader={
          <div className="flex justify-center mb-2">
            <div className="rounded-full py-2 px-4 bg-gray-300">
              <p className="text-xs uppercase">Loading</p>
            </div>
          </div>
        }
        scrollableTarget="scrollableDiv"
      >
        {messageContent
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map((message, _index) => {
            return (
              <MessageBubble
                key={message.communicationId}
                message={message}
                associateMessaging={associateMessaging}
                outgoing={message.isOutgoing}
                contacts={contacts}
                // Buildings to allow Building Tickets
                buildings={buildings}
                tickets={tickets}
                ticketId={ticketId}
                refreshCB={refreshCB}
                draggableMedia={draggableMedia}
                addAttachmentToSR={addAttachmentToSR}
                setChatData={setChatData}
                setChatModalDisplay={setChatModalDisplay}
                setLightboxImages={setLightboxImages}
                checkBoxForMedia={checkBoxForMedia}
                onClickCheckBoxCB={onClickCheckBoxCB}
                lastChannel={
                  messages?.content[messages?.content?.length - 1]?.channel
                }
              />
            );
          })}
      </InfiniteScroll>

      {lightboxImages && (
        <MaterialModal
          className="p-0"
          open={lightboxImages}
          setOpen={() => {
            setLightboxImages();
          }}
        >
          <Carousel lightboxImages={lightboxImages} />
        </MaterialModal>
      )}

      {/* {lightboxImages && (
        <AutoRotatingCarousel
          open={true}
          onClose={() => setLightboxImages()}
          autoplay={false}
          mobile={false}
          style={{ position: "absolute", backgroundColor: blueGrey[50] }}
          classes={{ slide: "slide_parent" }}
        >
          {lightboxImages.images.map((imageSRC, index) => (
            <Slide
              media={getMedia(imageSRC)}
              classes={{ text: "text_parent" }}
              style={{
                backgroundColor: blueGrey[50],
                height: "fit-content"
              }}
              mediaBackgroundStyle={{ backgroundColor: blueGrey[50] }}
              title=""
              subtitle=""
            />
          ))}
        </AutoRotatingCarousel>
      )} */}

      {chatModalDisplay && chatData.chats && (
        <MaterialModal
          open={chatModalDisplay}
          setOpen={(_) => setChatModalDisplay(false)}
          label="new-user-modal"
          describedby="create-new-user"
        >
          <Typography variant="h6">{chatData.title}</Typography>
          <BasicConversationApp
            messages={chatData.chats}
          ></BasicConversationApp>
        </MaterialModal>
      )}
    </div>
  );
}
