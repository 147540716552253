import { useState } from "react";

import { Tab } from "@headlessui/react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPayments,
  ConnectPayouts,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";

import { stripeAccountSession, stripeLink } from "../../api/Api";
import { conditionalArrayEntry } from "../../utils/ObjectUtills";
import { StripeData, VendorDetailsData } from "./StripePayments";

type StripeTabsProps = {
  vendor?: VendorDetailsData;
  stripeData?: StripeData;
};

export default function StripeTabs({ vendor, stripeData }: StripeTabsProps) {
  const paymentsTab = [
    ...conditionalArrayEntry(stripeData?.isAccountChargesEnabled, {
      label: "Payments",
      value: "payment",
    }),
    ...conditionalArrayEntry(stripeData?.isAccountChargesEnabled, {
      label: "Payouts",
      value: "payout",
    }),
    { label: "Settings", value: "settings" },
  ];

  const [selectedTab, setSelectedTab] = useState("payment");

  const [stripeConnectInstance] = useState(() => {
    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? "",
      fetchClientSecret: () =>
        stripeAccountSession().then((data) => data.value),
      appearance: {
        overlays: "dialog",
        variables: {
          colorPrimary: "#625afa",
        },
      },
    });
  });

  return (
    <div className="w-full overflow-auto">
      <Tab.Group
        selectedIndex={paymentsTab.findIndex(
          (item) => item.value === selectedTab
        )}
        onChange={(index) => {
          setSelectedTab(paymentsTab[index].value);
        }}
      >
        <div className="flex flex-col  md:w-full  pb-10 border-b">
          <div className="">
            <div className="">
              <Tab.List
                className="flex space-x-8 pb-4 border-b border-gray-400  mb-4"
                aria-label="Tabs"
              >
                {paymentsTab.map((item) => (
                  <Tab key={item.value} className={"focus:outline-none "}>
                    {({ selected }) => (
                      <span
                        className={
                          "cursor-pointer border-transparent text-sm hover:text-gray-700  whitespace-nowrap py-4 px-1  border-b-2 font-medium " +
                          (selected
                            ? "text-newBlue-400 border-b-4 border-newBlue-400"
                            : "text-newGray-800")
                        }
                      >
                        {item.label}
                      </span>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>
          </div>
          <Tab.Panels>
            {stripeData?.isAccountChargesEnabled &&
              vendor?.stripeInfoId !== null &&
              vendor?.stripeInfoId !== undefined && (
                <div className="w-full">
                <ConnectComponentsProvider
                  connectInstance={stripeConnectInstance}
                >
                  <Tab.Panel>
                    <ConnectPayments />
                  </Tab.Panel>

                  <Tab.Panel>
                    <ConnectPayouts />
                  </Tab.Panel>
                </ConnectComponentsProvider>
                </div>
              )}
            <Tab.Panel>
              <div className="flex  justify-center  w-full">
                <div className="flex flex-col justify-between w-max border border-gray-400 bg-newGray-100 rounded  py-4 px-4">
                  <div className="flex flex-row">
                    <span className="text-sm font-normal ">
                      Details Submitted :
                    </span>
                    <span
                      className={`text-sm font-normal ml-8 border rounded px-2 ${
                        stripeData?.isAccountDetailsSubmitted
                          ? "text-green-500 border-green-500"
                          : "text-red-500 border-red-500"
                      }`}
                    >
                      {stripeData?.isAccountDetailsSubmitted ? "Yes" : "No"}
                    </span>
                  </div>
                  <div className="flex flex-row mt-4">
                    <span className="text-sm font-normal ">
                      Payments Enabled:
                    </span>
                    <span
                      className={`text-sm font-normal  ml-8 border rounded px-2 ${
                        stripeData?.isAccountChargesEnabled
                          ? "text-green-500 border-green-500"
                          : "text-red-500 border-red-500"
                      }`}
                    >
                      {stripeData?.isAccountChargesEnabled ? "Yes" : "No"}
                    </span>
                  </div>
                  <button
                    className="w-max text-sm font-normal self-center bg-newBlue-400 text-white rounded px-2 py-1 mt-4"
                    onClick={() => {
                      stripeLink().then((data) => {
                        console.log({ data });

                        window.location.replace(data.value);
                      });
                    }}
                  >
                    View/Submit Details
                  </button>
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
}
