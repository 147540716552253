import { Avatar } from "@mui/material";

import PopOverText from "../common/PopOverText";

interface TicketWithOwner {
  ownerId: string;
  ownerFirstName: string;
  ownerLastName: string;
}

interface UnassignedTicket {
  ownerId: null;
  ownerFirstName: null;
  ownerLastName: null;
}

type Ticket = TicketWithOwner | UnassignedTicket;

export default function TicketOwner({ ticket }: { ticket: Ticket }) {
  return (
    <PopOverText
      text={
        ticket.ownerId
          ? `Owner: ${ticket.ownerFirstName} ${ticket.ownerLastName}`
          : "Click to Assign Owner"
      }
    >
      <Avatar className="h-8 w-8" style={{ transform: "scale(0.8)" }}>
        {ticket.ownerId
          ? (ticket.ownerFirstName + ticket.ownerLastName).slice(0, 1)
          : "?"}
      </Avatar>
    </PopOverText>
  );
}
