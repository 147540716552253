import React, { useEffect, useState } from "react";

import { getLeaseById, getProspectById } from "../../../../api/Api";
import { ScreeningDetails } from "./screening/ScreeningDetails";
import { ScreeningListing } from "./screening/ScreeningListing";

type ScreeningTabProps = {
  unitId: number;
  leaseId?: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
};

export default function ApplicationTab({
  unitId,
  leaseId,
  setCurrentTab,
}: ScreeningTabProps) {
  const [currentScreeningScreen, setCurrentScreeningTab] =
    useState("screeningListing");

  const [selectedProspect, setSelectedProspect] = useState(0);
  const [leaseTerm, setLeaseTerm] = useState();
  const [applicantName, setApplicantName] = useState("");

  useEffect(() => {
    if (leaseId !== undefined && leaseId !== null && leaseId !== 0)
      getLeaseById(leaseId).then((data) => {
        setLeaseTerm(data.leaseTerms);
      });
  }, [leaseId]);

  useEffect(() => {
    if (selectedProspect !== 0)
      getProspectById(selectedProspect).then((data) => {
        setApplicantName(`${data.firstName} ${data.lastName}`);
      });
  }, [selectedProspect]);

  return (
    <div className="p-4">
      <div className="flex flex-col items-start self-center ">
        <div className="text-lg font-medium text-black">Lease Details</div>
        <div className="flex flex-row mt-4 my-1">
          <div className="text-base text-newGray-800 font-normal mr-1">
            {`Lease term:`}
          </div>
          <div className="text-base text-black font-semibold">
            {leaseTerm ? leaseTerm : "- - -"}
          </div>
        </div>

        <div className="flex flex-row my-1 mb-4">
          <div className="text-base text-newGray-800 font-normal mr-1">
            {`Application Status:`}
          </div>
          <div className="text-base text-black font-semibold">- - -</div>
        </div>
        {currentScreeningScreen !== "screeningListing" && (
          <div className="flex flex-row my-1 mb-4">
            <div className="text-base text-newGray-800 font-normal mr-1">
              {`Name:`}
            </div>
            <div className="text-base text-black font-semibold">
              {applicantName}
            </div>
          </div>
        )}

        {currentScreeningScreen === "screeningListing" && (
          <ScreeningListing
            unitId={unitId}
            leaseId={leaseId}
            setCurrentApplicationTab={setCurrentScreeningTab}
            setCurrentProspect={setSelectedProspect}
            setCurrentTab={setCurrentTab}
          />
        )}
        {currentScreeningScreen === "screeningDetails" && (
          <ScreeningDetails
            prospectId={selectedProspect}
            setCurrentApplicationTab={setCurrentScreeningTab}
          />
        )}
      </div>
    </div>
  );
}
