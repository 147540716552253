import { useEffect, useState } from "react";



import Button from "@mui/material/Button";
import moment from "moment";
import { navigate } from "raviger";



import { alertError, alertInfo, alertSuccess } from "../../actions/AlertActions";
import { addSR, getContactsForTicketId, getEstimate, getInvoiceById, getPayment, makePayment, payInvoice, sendInvoice, updateInvoice } from "../../api/Api";
import { renderPhone } from "../../utils/StateUtils";
import { properString } from "../../utils/StringUtils";
import RenderAddressComponent from "../clients/RenderAddressComponent";
import { DocumentIcon } from "../common/AppIcons";
import MaterialModal from "../common/MaterialModal";
import MaterialTable from "../common/MaterialTable";
import MaterialForm from "../common/form/MaterialForm";
import CustomFormatDatePicker from "../scheduler/CustomFormatDatePicker";
import AdditionalItemsTable from "./AdditionalItemsTable";
import { AdditionalItem, InvoiceData } from "./InvoiceFormUtils";
import InvoiceTable from "./InvoiceTable";
import PaymentsList from "./PaymentsList";
import StripeCheckout from "./StripeCheckout";


type InvoiceFormProps = {
  invoiceId: number;
};

type EstimateData = {
  id: number;
  estimateDate: string;
  ticketId: number;
  acceptedDate: string;
  acceptedName: string;
  acceptedChannel: string;
  estimateStatus: string;
  vendor: VendorData;
  invoiceId: number;
};

type VendorData = {
  id: number;
  name: string;
  logoUrl: string;
  primaryContactNumber: string;
  supportEmail: string;
  address: {
    streetAddress1: string;
    streetAddress2: string;
    city: string;
    state: string;
    zip: string;
  };
  invoiceTermsAndConditions: string;
};

type TicketContacts = {
  contactId: number;
  firstName: string;
  email: string;
  lastName: string;
  phone: string;
};

type PaymentData = {
  clientSecret: string;
  accountId: string;
};
export default function InvoiceForm({ invoiceId }: InvoiceFormProps) {
  const paymentChannelOptions = [
    { label: "Cash", value: "CASH" },
    { label: "Check", value: "CHECK" },
  ];
  const [invoiceData, setInvoiceData] = useState<InvoiceData>();
  const [showContactModal, setShowContactModal] = useState(false);

  const [paidBy, setPaidBy] = useState({
    invoiceDate: "",
    paymentChannel: "",
    checkNumber: "",
    paidBy: 0,
    paidOn: "",
  });

  const [selectedSr, setSelectedSr] = useState({
    srId: 0,
  });

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    if (sessionId !== null) {
      getPayment(sessionId).then((data) => {
        if (data.value === "COMPLETE") {
          getInvoiceById(invoiceId).then((data: InvoiceData) => {
            setInvoiceData(data);
            setPaidBy({
              invoiceDate: data.invoiceDate,
              paidBy: data.paidBy?.contactId,
              paidOn: data.paidOn,
              paymentChannel: data.paymentChannel,
              checkNumber: data.checkNumber,
            });
            if (data.status === "PAID") alertSuccess("Payment Successful");
            else alertInfo("Payment Under Process");
          });
        } else {
          navigate("stripe/checkout");
        }
      });
    }
  }, []);

  const [paidByOptions, setPaidByOptions] = useState<{}>([]);
  const [srOptions, setSrOptions] = useState<{}>([]);
  const [contactScreenSMS, setContactScreenSMS] = useState(false);
  const [srModal, setSrModal] = useState(false);

  const [estimateData, setEstimateData] = useState<EstimateData>();
  const [ticketContacts, setTicketContacts] = useState<TicketContacts[]>([]);

  const [selectedContacts, setSelectedContacts] = useState([]);
  const [additionalItem, setAdditionalItem] = useState<AdditionalItem[]>([]);

  const [pay, setPay] = useState(false);
  const [invoicePay, setInvoicePay] = useState(false);
  const [viewPayments, setViewPayments] = useState(false);
  const [checkoutPopup, setCheckoutPopup] = useState({
    open: false,
    accountId: "",
    clientSecret: "",
  });

  useEffect(() => {
    getInvoiceById(invoiceId).then((data: InvoiceData) => {
      setInvoiceData(data);
      setAdditionalItem(data.additionalItems ?? []);
      setPaidBy({
        invoiceDate: data.invoiceDate,
        paidBy: data.paidBy?.contactId,
        paidOn: data.paidOn,
        paymentChannel: data.paymentChannel,
        checkNumber: data.checkNumber,
      });
      getEstimate(data.estimateId).then((data) => {
        setEstimateData(data as EstimateData);
        const filteredSrs = data.lines.filter(
          (item) => item.invoiceId === null && item.acceptedDate !== null
        );
        setSrOptions(
          filteredSrs.map((item) => ({
            label: `${item.title} | ${item.description}`,
            value: item.id,
          }))
        );
      });
      getContactsForTicketId(data.ticket.ticketId).then((contacts) => {
        setTicketContacts(contacts);

        const contactList = contacts.map((item: TicketContacts) => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item.contactId,
        }));

        setPaidByOptions(contactList);
      });
    });

    const contacts = ticketContacts.map((item) => ({
      label: `${item.firstName} ${item.lastName}`,
      value: item.contactId,
    }));
    setPaidByOptions(contacts);
  }, [invoiceId]);

  return (
    <div className={"bg-white rounded md:mb-10 md:p-2"}>
      <div className="rounded-sm">
        <div className="w-full flex flex-col md:flex-row items-center md:items-start justify-between">
          <span className="text-gray-700 mt-2 font-semibold text-3xl flex flex-col md:flex-row items-center gap-1 whitespace-pre">
            <div className="flex flex-col items-center md:items-start">
              <div>
                <span>Invoice</span>&nbsp;
                <span className="relative top-px whitespace-pre">
                  #INV{invoiceData?.id}
                </span>
              </div>
              <span className="flex flex-col lg:flex-row items-center md:items-baseline">
                <>
                  <span
                    className={
                      `inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ` +
                      (invoiceData?.status === "PAID" ||
                      invoiceData?.status === "PARTIALLY_PAID"
                        ? "bg-green-500 text-green-100"
                        : "bg-red-500 text-red-100")
                    }
                  >
                    {properString(invoiceData?.status)}
                  </span>
                </>
                {invoiceData?.status !== "PAID" && (
                  <span
                    className="inline-flex items-center lg:mx-1 px-2.5 py-1 my-1 rounded-full text-xs font-medium bg-gray-400 text-white cursor-pointer mark-as-accepted-badge"
                    onClick={() => {
                      alertInfo("Please select the paid by contact");
                      setPay(true);
                      document
                        .getElementById("paid-by-section")
                        .scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    Click Here To Mark As Paid
                  </span>
                )}
              </span>
            </div>
          </span>
          <img
            src={estimateData?.vendor?.logoUrl}
            alt="Logo"
            className="w-40 h-40 md:w-48 md:h-48"
          />
          <div className="flex flex-col md:mt-5 text-gray-700 text-xs text-center md:text-right">
            {[
              `${estimateData?.vendor?.address.streetAddress1},
                    ${
                      estimateData?.vendor?.address.streetAddress2
                        ? estimateData?.vendor?.address.streetAddress2
                        : ""
                    }
                  `,
              `${estimateData?.vendor?.address.city},
                    ${estimateData?.vendor?.address.state},
                    ${estimateData?.vendor?.address.zip}
                  `,
              `${renderPhone(estimateData?.vendor?.primaryContactNumber)}`,
              `${estimateData?.vendor?.supportEmail}`,
            ].map((text, text_index) => (
              <span key={text_index}>{text}</span>
            ))}
          </div>
        </div>

        <div className="flex flex-col md:flex-row w-full justify-between gap-3">
          <div className="flex flex-col gap-4 items-center">
            <div className="md:ml-5 flex flex-col text-gray-700">
              <span className="font-semibold text-gray-900 py-1 mt-2 items-center text-center md:text-left">
                Invoiced To:
              </span>
              <div
                className={
                  "flex flex-col gap-px px-3 py-1 bg-gray-50 rounded text-sm text-center md:text-left"
                }
              >
                {invoiceData?.ticket?.createdUnderContactId !== null && (
                  <>
                    <span>
                      {`${
                        invoiceData?.ticket?.primaryContact?.firstName || ""
                      } ${invoiceData?.ticket?.primaryContact?.lastName || ""}`}
                    </span>
                  </>
                )}

                <span>{`${invoiceData?.ticket?.hoaName || ""}`}</span>
                <span>{invoiceData?.ticket?.streetAddress1}</span>
                {(invoiceData?.ticket?.unitNumber ??
                  invoiceData?.ticket?.streetAddress2) && (
                  <span>
                    {invoiceData?.ticket?.unitNumber ??
                      invoiceData?.ticket?.streetAddress2}
                  </span>
                )}
                <span>
                  {invoiceData?.ticket?.city}, {invoiceData?.ticket?.state}{" "}
                  {invoiceData?.ticket?.zip}
                </span>
                {invoiceData?.ticket?.createdUnderClientId !== null && (
                  <div className="flex flex-col mt-4">
                    <div className="flex flex-col md:flex-row items-center">
                      <span className="text-sm font-semibold">
                        Attention To:
                      </span>
                      <span className="px-2">
                        {`${
                          invoiceData?.ticket?.primaryContact?.firstName || ""
                        } ${
                          invoiceData?.ticket?.primaryContact?.lastName || ""
                        }`}
                      </span>
                    </div>
                    <span>{invoiceData?.ticket?.clientName}</span>
                    <span>
                      <RenderAddressComponent
                        address={invoiceData?.ticket?.billingAddress}
                      />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-8 sm:mt-16 mr-5 flex flex-col gap-4  text-center md:text-right">
            <div className="flex flex-col  justify-center md:justify-end">
              <span className="font-semibold">Invoice Date</span>
              <span
                className={
                  "text-gray-700 px-3 py-2 bg-gray-50 rounded mt-1 text-sm"
                }
              >
                <div>
                  <CustomFormatDatePicker
                    dateInit={
                      invoiceData?.invoiceDate
                        ? moment(invoiceData?.invoiceDate, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )
                        : ""
                    }
                    readOnly={invoiceData?.status === "PAID"}
                    onDateChange={(date: string) => {
                      setPaidBy({
                        ...paidBy,
                        paidBy: paidBy.paidBy,
                        paidOn: paidBy.paidOn,
                        invoiceDate: date,
                      });
                    }}
                  />
                </div>
              </span>
            </div>

            <div className="sm:mt-5 text-gray-700 flex flex-col">
              <span className="font-semibold text-lg">Amount</span>
              <span className="font-bold text-2xl text-green-600">
                $ {invoiceData?.total}
              </span>
            </div>
          </div>
        </div>

        <>
          <div className="w-full mt-5 shadow-md border border-gray-100 rounded">
            <div className="text-gray-600 px-3 font-semibold text-sm mt-1 py-2 border-b border-gray-300 w-full">
              Services & Materials
              <span className="float-right ">
                Total:-
                <span className="text-green-600">$ {invoiceData?.total}</span>
              </span>
            </div>

            <div className="flex flex-col md:flex-row items-center w-full py-1 px-0">
              <InvoiceTable
                invoiceData={invoiceData}
                setInvoiceData={setInvoiceData}
              />
            </div>
          </div>
        </>
        {invoiceData?.status !== "PAID" && (
          <div className=" my-4">
            <button
              className="w-max text-green-600 font-medium border px-2 rounded border-green-600"
              onClick={() => {
                getEstimate(invoiceData?.estimateId).then((estimate) => {
                  const filteredSrs = estimate.lines.filter(
                    (item) => item.invoiceId === null
                  );

                  if (filteredSrs.length === 0) {
                    alertInfo("All Accepted Service Requests are Invoiced");
                  } else {
                    setSrModal(true);
                  }
                });
              }}
            >
              ADD ITEMS FROM ESTIMATE
            </button>
          </div>
        )}
        <>
          <AdditionalItemsTable
            additionalData={additionalItem}
            invoiceId={invoiceId}
            invoiceStatus={invoiceData?.status ?? ""}
          />
        </>

        <div
          className={
            "flex flex-col md:flex-row gap-4 sm:gap-6 w-full justify-between mt-7"
          }
        >
          <p className="flex flex-col text-xs text-gray-600 mt-6 h-40 overflow-y-auto gap-2 whitespace-pre-wrap">
            {estimateData?.vendor?.invoiceTermsAndConditions}
          </p>
          <div className="w-full flex flex-col my-5 gap-1 items-center md:items-end text-gray-700">
            <div className="flex flex-row gap-8 font-semibold text-2xl">
              Invoice Total
              <span>${invoiceData?.total}</span>
            </div>

            <div className="mt-5 flex flex-col md:flex-row-reverse  items-center w-full p-2">
              <div className="flex flex-col gap-3 md:items-baseline items-center mx-2 sm:mr-10">
                {(pay ||
                  paidBy?.paymentChannel === "CASH" ||
                  paidBy?.paymentChannel === "CHECK") && (
                  <div className="flex flex-row-reverse items-center gap-7 w-full">
                    <MaterialForm
                      className={"w-40"}
                      data={paidBy}
                      editable={pay || invoicePay}
                      renderArray={[
                        {
                          name: "paymentChannel",
                          label: "Select",
                          inputType: "SELECT",

                          options: paymentChannelOptions,
                        },
                      ]}
                      onChange={(update: any) => {
                        const { value } = update;

                        setPaidBy({ ...paidBy, paymentChannel: value });
                      }}
                    />
                    <span className="whitespace-pre text-gray-600 font-semibold text-sm">
                      Paid Via
                    </span>
                  </div>
                )}
                {paidBy?.paymentChannel === "CHECK" && (
                  <div className="flex flex-row-reverse items-center gap-7 w-full">
                    <MaterialForm
                      className={"w-40"}
                      data={paidBy}
                      editable={pay || invoicePay}
                      renderArray={[
                        {
                          name: "checkNumber",
                          label: "Enter Check Number",
                          inputType: "TEXT",
                        },
                      ]}
                      onChange={(update: any) => {
                        const { value } = update;

                        setPaidBy({ ...paidBy, checkNumber: value });
                      }}
                    />
                    <span className="whitespace-pre text-gray-600 font-semibold text-sm">
                      Check
                      <br />
                      Number
                    </span>
                  </div>
                )}
                <div
                  id="paid-by-section"
                  className="flex flex-row-reverse items-center gap-7 w-full"
                >
                  <MaterialForm
                    className={"w-40"}
                    data={paidBy}
                    editable={pay || invoicePay}
                    renderArray={[
                      {
                        name: "paidBy",
                        label: "Select",
                        inputType: "SELECT",

                        options: paidByOptions,
                      },
                    ]}
                    onChange={(update: any) => {
                      const { value } = update;

                      setPaidBy({
                        ...paidBy,
                        paidBy: value,
                        paidOn: paidBy.paidOn,
                        invoiceDate: paidBy.invoiceDate,
                      });
                    }}
                  />
                  <span className="whitespace-pre text-gray-600 font-semibold text-sm">
                    Paid By
                  </span>
                </div>
                <div
                  className={"flex flex-row-reverse items-center gap-7 w-full"}
                >
                  <MaterialForm
                    className={"w-40"}
                    data={paidBy}
                    editable={pay || invoicePay}
                    renderArray={[
                      {
                        name: "paidOn",
                        label: "",
                        inputType: "DATE",
                        editable: pay,
                      },
                    ]}
                    onChange={(update: any) => {
                      const { value } = update;

                      setPaidBy({
                        ...paidBy,
                        paidBy: paidBy.paidBy,
                        paidOn: value,
                        invoiceDate: paidBy.invoiceDate,
                      });
                    }}
                  />

                  <span className="whitespace-pre text-gray-600 font-semibold text-sm">
                    Paid On
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {pay ? (
          <div>
            <Button
              onClick={(_) => {
                if (paidBy.paidBy !== 0 && paidBy.paidBy !== null) {
                  payInvoice(invoiceId, {
                    paidBy: paidBy.paidBy,
                    paidOn: paidBy.paidOn,
                    paymentChannel: paidBy.paymentChannel,
                    checkNumber: paidBy.checkNumber,
                  }).then(() => {
                    setPay(false);
                    getInvoiceById(invoiceData?.id).then(
                      (data: InvoiceData) => {
                        setInvoiceData(data);
                      }
                    );
                  });
                } else {
                  alertInfo("Please select paid by contact");
                }
              }}
              style={{
                color: "#38A169",
              }}
              className={"float-right save-and-send-pdf-estimate"}
            >
              Pay Invoice
            </Button>
          </div>
        ) : (
          <>
            {invoicePay ? (
              <div>
                <Button
                  onClick={(_) => {
                    setInvoicePay(false);
                  }}
                  style={{
                    color: "#38A169",
                  }}
                  className={"float-right save-and-send-pdf-estimate"}
                >
                  Cancel
                </Button>
                <Button
                  onClick={(_) => {
                    if (
                      paidBy.paidBy !== 0 &&
                      paidBy.paidBy !== null &&
                      paidBy.paidBy !== undefined
                    ) {
                      makePayment(invoiceId, paidBy.paidBy)
                        .then((data: PaymentData) => {
                          setCheckoutPopup({
                            open: true,
                            accountId: data.accountId,
                            clientSecret: data.clientSecret,
                          });
                        })
                        .catch((error) => {
                          alertError(error.message);
                        });
                    } else {
                      alertInfo("Please select paid by contact");
                    }
                  }}
                  style={{
                    color: "#38A169",
                  }}
                  className={"float-right save-and-send-pdf-estimate"}
                >
                  Make Payment
                </Button>
              </div>
            ) : (
              <div>
                <div className="">
                  <Button
                    onClick={(_) => {
                      updateInvoice(invoiceId, [
                        {
                          op: "replace",
                          path: "/invoiceDate",
                          value: paidBy.invoiceDate,
                        },
                      ]);
                    }}
                    style={{ color: "#38A169" }}
                    className="float-right"
                  >
                    Save
                  </Button>
                </div>
                {invoiceData?.status === "PAID" &&
                  (invoiceData?.paymentChannel === "SELF" ||
                    invoiceData?.paymentChannel === "ON_BEHALF") && (
                    <Button
                      onClick={(_) => {
                        setViewPayments(true);
                      }}
                      style={{
                        color: "#38A169",
                      }}
                      className={"float-right save-and-send-pdf-estimate"}
                    >
                      View Payments
                    </Button>
                  )}

                {invoiceData?.status !== "PAID" && (
                  <div>
                    <Button
                      onClick={(_) => {
                        alertInfo(
                          "Please select the Contact making the payment"
                        );
                        setInvoicePay(true);
                      }}
                      style={{
                        color: "#38A169",
                      }}
                      className={"float-right save-and-send-pdf-estimate"}
                    >
                      Make Payment
                    </Button>
                  </div>
                )}
                <div>
                  <Button
                    onClick={(_) => {
                      setShowContactModal(true);
                    }}
                    style={{
                      color: "#38A169",
                    }}
                    className={"float-right save-and-send-pdf-estimate"}
                  >
                    {invoiceData?.status === "SENT"
                      ? "Resend Invoice"
                      : "Send Invoice"}
                    <DocumentIcon className="h-3 w-3 ml-2" />
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <MaterialModal
        open={showContactModal}
        setOpen={(_) => setShowContactModal(false)}
        extended
      >
        <>
          <div className="flex flex-row justify justify-end items-center">
            <div className="items-center">
              <div className="flex flex-row items-center">
                <div className="flex items-center">
                  <input
                    id="notify-client"
                    aria-describedby="notify-client-helper-text"
                    name="notify-client"
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    onChange={(e) => {
                      setContactScreenSMS((current) => !current);
                    }}
                    checked={contactScreenSMS ? true : false}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="notify-client"
                    className="font-medium text-gray-700"
                  >
                    Send SMS
                  </label>
                </div>
              </div>
            </div>
            <div>
              <Button
                onClick={(_) => {
                  alertSuccess("Sending Email...");
                  sendInvoice(invoiceId, {
                    contactIds: selectedContacts,
                    channel: "EMAIL",
                    sendSMS: contactScreenSMS,
                  })
                    .then(() => {
                      setShowContactModal(false);

                      alertSuccess("Invoice sent successfully");
                    })
                    .catch((err: any) => {
                      if (err.readableMessage) {
                        alertError(err.readableMessage);
                      } else {
                        alertError("Sorry! Error Sending Invoice.");
                      }
                    });
                }}
                style={{ color: "#38A169" }}
                className="float-right p-4 send-estimate-button"
              >
                {invoiceData?.status === "SENT"
                  ? "Resend Invoice"
                  : "Send Invoice"}
              </Button>
            </div>
          </div>
          <MaterialTable
            data={ticketContacts?.map((contact) => {
              return {
                id: contact.contactId,
                data: [
                  contact.firstName,
                  contact.lastName,
                  contact.email,
                  renderPhone(contact.phone),
                ],
              };
            })}
            head={[
              { id: "firstName", label: "First Name" },
              { id: "lastName", label: "Last Name" },
              { id: "email", label: "Email" },
              { id: "phoneNumber", label: "Phone Number" },
            ]}
            onClickCB={undefined}
            defaultOrderBy={"firstName"}
            selected={selectedContacts}
            setSelected={setSelectedContacts}
            multiSelect={true}
            loading={undefined}
            currentPage={undefined}
            totalData={undefined}
            defaultOrder={undefined}
            searchText={undefined}
            paginationCB={undefined}
            rowChangeCB={undefined}
          />
        </>
      </MaterialModal>
      <MaterialModal
        open={viewPayments}
        setOpen={(_) => setViewPayments(false)}
        extended
      >
        <PaymentsList invoiceId={invoiceId} />
      </MaterialModal>
      <MaterialModal
        open={checkoutPopup.open}
        setOpen={(_) =>
          setCheckoutPopup({ open: false, clientSecret: "", accountId: "" })
        }
        extended
      >
        <StripeCheckout
          clientSecret={checkoutPopup.clientSecret}
          accountId={checkoutPopup.accountId}
        />
      </MaterialModal>
      <MaterialModal open={srModal} setOpen={(_) => setSrModal(false)}>
        <>
          <div className="flex flex-col p-4">
            <div className="text-lg font-normal">Add Item from Estimate</div>
            <MaterialForm
              className={"w-600"}
              data={selectedSr}
              renderArray={[
                {
                  name: "srId",
                  label: "Service Request",
                  inputType: "SELECT",
                  options: srOptions,
                },
              ]}
              onChange={(update: any) => {
                const { value } = update;

                setSelectedSr({
                  srId: value,
                });
              }}
            />
            <button
              className="w-max text-green-600 font-medium border px-2 rounded border-green-600 mt-10"
              onClick={() => {
                if (selectedSr.srId !== 0) {
                  addSR(invoiceId, selectedSr.srId).then(() => {
                    setSrModal(false);
                    alertSuccess("Service request added");
                    getInvoiceById(invoiceData?.id).then(
                      (data: InvoiceData) => {
                        setInvoiceData(data);
                      }
                    );
                  });
                } else {
                  alertInfo("Please select a SR");
                }
              }}
            >
              ADD SR
            </button>
          </div>
        </>
      </MaterialModal>
    </div>
  );
}