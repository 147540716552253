import React from "react";
import ChatIcon from "./ChatIcon";

function getStyle(msgStatus) {
  switch (msgStatus) {
    case true:
      return {
        itemStyle: "inline-block pl-3 my-1 align-top w-11/12",
        divStyle:
          "bg-blue-100 rounded text-gray-600 text-md m-0 py-1 pr-2 pl-3 w-9/12 break-all",
      };
    default:
      return {
        itemStyle: "overflow-hidden my-2 flex justify-end ",
        divStyle:
          "bg-blue-800 rounded text-md text-white m-0  py-1 pl-2 pr-3 w-9/12 ",
      };
  }
}

export default function BasicMessageBubble({ key, m }) {
  return (
    <li id={key} className={getStyle(m.incoming)["itemStyle"]}>
      <div className={getStyle(m.incoming)["divStyle"]}>
        <div>
          <span className="flex flex-row items-center mb-1 gap-1">
            <ChatIcon />
            <div className={"text-xs transform scale-90 "}>
              {" "}
              {m.incoming ? m["from"] : "You"}
            </div>
          </span>

          {m.cloudinaryUrls ? (
            <a
              href={m.cloudinaryUrls[0]}
              target="_blank"
              className="no-underline hover:underline"
            >
              {m.cloudinaryUrls[0]}
            </a>
          ) : (
            m.message
          )}
        </div>
        <span className=" block text-xs text-black-400 mt-2">
          {m.timestamp && new Date(m.timestamp).toLocaleTimeString()}
        </span>
      </div>
    </li>
  );
}
