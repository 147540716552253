import React, { Component } from "react";
// import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
// import { blueGrey } from "@mui/material/colors";
import { useState } from "react";

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Avatar } from "@mui/material";
import PropTypes from "prop-types";
import { navigate } from "raviger";

import { processUrl } from "../../common/PreviewLink";
import ChatIcon from "./ChatIcon";
import WhatsappIcon from "./WhatsappIcon";

class MessageBubble extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMedia: this.props.message.type === "media",
      mediaDownloadFailed: false,
      mediaUrl: null,
      lightboxImages: null,
    };

    this.update = this.update.bind(this);
  }

  update(nextState) {
    this.setState(nextState);
  }

  getMedia(imageSRC, mediaExtension) {
    var format = "image";
    const extension = mediaExtension.split("/")[1];
    const processedUrl = processUrl(imageSRC);
    switch (extension) {
      case "jpg":
      case "heic":
        format = "image";
        break;
      case "webm":
      case "mp4":
        format = "video";
        break;
      default:
        format = "image";
        break;
    }

    return format === "image" ? (
      <img src={processedUrl} />
    ) : (
      <video width="400" controls poster={processUrl(processedUrl, "jpg")}>
        <source src={processUrl(processedUrl, "webm")} type="video/webm" />
        <source src={processUrl(processedUrl, "mp4")} type="video/mp4" />
      </video>
    );
  }

  componentDidMount = async () => {
    this.setState({
      ...this.state,
      type: (await this.props.message.getParticipant()).type,
    });
    if (this.state.hasMedia) {
      this.props.message.media
        .getContentTemporaryUrl()
        .then((url) => {
          this.setState({ mediaUrl: url });
          this.props.open_image_popup({ [this.props.index]: url });
        })
        .catch((e) => this.setState({ mediaDownloadFailed: true }));
    }
    document
      .getElementById(this.props.message.sid)
      .scrollIntoView({ behavior: "smooth" });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    document
      .getElementById(this.props.message.sid)
      .scrollIntoView({ behavior: "smooth" });
  }

  resetLightBoxImages = () => {
    this.setState({ lightboxImages: null });
  };

  render = () => {
    const { itemStyle, divStyle } =
      this.props.direction === "incoming"
        ? {
            itemStyle: "inline-block my-1 align-top flex justify-start",
            divStyle:
              "bg-blue-100 rounded text-gray-600 text-md p-1 mr-3 ml-1 overflow-hidden",
          }
        : {
            itemStyle: "overflow-hidden my-2 flex justify-end ",
            divStyle:
              "bg-blue-800 rounded text-white p-1 ml-1 mr-3 overflow-hidden",
          };

    const m = this.props.message;
    const type = this.state.type;

    if (this.state.hasMedia) {
      console.log("Message is media message");
      // log media properties
      console.log("Media properties", m.media);
    }

    return (
      <li id={m.sid} className={itemStyle}>
        <div className={divStyle}>
          <div>
            <strong>
              <span className="flex flex-row items-center mb-1 gap-1">
                {type === "whatsapp" && <WhatsappIcon />}
                {type === "chat" && this.props.direction !== "incoming" && (
                  <ChatIcon />
                )}
                {type === "sms" && <span>Mobile Icon</span>}{" "}
                {this.props.direction === "incoming" && (
                  <Avatar
                    src={m.conversation.channelState.attributes.profilePic}
                    className="h-8 w-8"
                    onClick={(_) => {
                      if (m.conversation.channelState.attributes.clientId)
                        navigate(
                          `/clients/${m.conversation.channelState.attributes.clientId}`
                        );
                    }}
                  >
                    {(
                      m.conversation.channelState.attributes.user_name ?? " "
                    ).slice(0, 1)}
                  </Avatar>
                )}
                <div className={"text-xs transform scale-90 "}>
                  {this.props.direction === "incoming"
                    ? m.conversation.channelState.attributes.user_name
                    : "You"}
                </div>
              </span>
            </strong>
            {/* <br /> */}
            <div className="flex justify-start text-base flex-wrap">
              {this.state.hasMedia && (
                <Media
                  hasFailed={this.state.mediaDownloadFailed}
                  media={this.props.message.media}
                  url={this.state.mediaUrl}
                  previewImages={this.state.lightboxImages}
                  setState={this.update}
                  type={this.props.message.media.contentType}
                  open_image_popup={this.props.open_image_popup}
                  index={this.props.index}
                />
              )}
            </div>
            {m.body &&
              m.body.split("\n").map((m) => <p>{m != "" ? m : <br />}</p>)}
          </div>
          <span className=" block text-xs text-gray-500 mt-2">
            {m.state.timestamp.toLocaleTimeString()}
          </span>
        </div>
        {/* {this.state.lightboxImages && (
          <AutoRotatingCarousel
            open={true}
            onClose={() => this.resetLightBoxImages()}
            hideArrows={() =>
              this.state.lightboxImages.images.length > 0 ? false : true
            }
            autoplay={false}
            mobile={false}
            style={{ position: 'absolute', backgroundColor: blueGrey[50] }}
            classes={{ slide: 'slide_parent' }}
          >
            {this.state.lightboxImages.images.map((imageSRC, index) => (
              <Slide
                key={index}
                media={this.getMedia(imageSRC, this.state.lightboxImages.type)}
                classes={{ text: 'text_parent' }}
                style={{
                  backgroundColor: blueGrey[50],
                  height: 'fit-content'
                }}
                mediaBackgroundStyle={{ backgroundColor: blueGrey[50] }}
                title=""
                subtitle=""
              />
            ))}
          </AutoRotatingCarousel>
        )} */}
      </li>
    );
  };
}

function MediaUrl(media) {
  media.getContentTemporaryUrl().then((url) => {
    window.open(url, "_blank");
  });
}

function Media({
  hasFailed,
  url,
  setState,
  type,
  media,
  open_image_popup,
  index,
}) {
  const [hover_image, setHover_image] = useState(false);
  return (
    <div
      className={
        "rounded cursor-pointer m-1 flex justify-center items-center " +
        (url ? "" : "bg-gray-900")
      }
      onClick={() => {
        // setState({ lightboxImages: { images: [url] } });
      }}
    >
      {!url && !hasFailed && <a>Waiting...</a>}

      {hasFailed && (
        <div className="flex flex-col">
          <span>WarningIcon</span>
          <p>Failed to load media</p>
        </div>
      )}

      {!hasFailed && url && (
        <div className="m-1 relative rounded top-0 overflow-hidden flex justify-center items-center flex-shrink-0 ">
          <div
            className={hover_image ? "absolute" : "hidden"}
            style={{ zIndex: 123 }}
          >
            {/* <EyeIcon className="h-7 w-7" /> */}
          </div>
          {type === "application/pdf" ? (
            <div className="text-sm flex justify-center w-full">
              <p
                onClick={() => {
                  MediaUrl(media);
                }}
              >
                <FullscreenIcon />
                View Pdf
              </p>
            </div>
          ) : type.substring(0, 5) === "video" ? (
            <video
              controls
              className="w-64 object-contain transition duration-100 ease-in-out hover:opacity-90"
              onClick={() => {
                console.log("video click detected");
                setState({ lightboxImages: { images: [url], type: type } });
              }}
            >
              <source src={url} type={type} />
              {/* Your browser does not support the video tag. */}
            </video>
          ) : (
            <img
              alt=""
              src={url}
              onMouseEnter={() => {
                setHover_image(true);
              }}
              onMouseLeave={() => {
                setHover_image(false);
              }}
              onClick={() => {
                open_image_popup({
                  index: index,
                  popup: true,
                });
                setState({ lightboxImages: { images: [url], type: type } });
              }}
              className="w-64 object-contain transition duration-100 ease-in-out hover:opacity-90"
            />
          )}
        </div>
      )}
    </div>
  );
}

Media.propTypes = {
  hasFailed: PropTypes.bool.isRequired,
  url: PropTypes.string,
};

export default MessageBubble;
