import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import makeStyles from "@mui/styles/makeStyles";
import { navigate } from "raviger";

import { alertError } from "../../actions/AlertActions";
import {
  subscribeTopic,
  unsubscribeTopic,
} from "../../actions/NotificationActions";
import {
  getBuilding,
  getContactMessages,
  getMessagesForTickets,
  listSupplierContacts,
  getSupplier,
} from "../../api/Api";
import dispatcher from "../../dispatcher";
import { useFormServiceRequest } from "../../hooks/useFormServiceRequest";
import { makePageable } from "../../utils/PageUtils";
import { deepUpdate } from "../../utils/StateUtils";
import { renderAddress } from "../../utils/StringUtils";
import ListTickets from "../clients/ListTickets";
// import { NewMediaDragIn } from "../common/NewMediaDragIn";
import MaterialModal from "../common/MaterialModal";
import ListServiceRequests from "../service/ListServiceRequests";
// import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import CustomListItem from "./CustomListitem";
import MessagesView from "./MessagesView";

const handleFetchMessages = (apiFetch, data, setMessages, next) => {
  apiFetch(data)
    .then((data) => {
      if (next) {
        setMessages((messages) => {
          return { ...data, content: [...messages.content, ...data.content] };
        });
      } else {
        setMessages(data);
      }
    })
    .catch((_) => alertError("Error Fetching Messages"));
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function Messages({
  supplierId,
  selectedContact,
  setServiceRequest,
  serviceRequest,
  tickets,
}) {
  const classes = useStyles();
  const [tab, setTab] = useState("Contacts");
  const [contacts, setContacts] = useState([]);
  const [filter, setFilter] = useState([null, "", "MANAGER"]);
  const [selected, setSelected] = useState();
  const [messages, setMessages] = useState({ content: [] });
  const [openModal, setOpenModal] = useState({
    showModal: false,
    selectedTab: "TICKETS",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorTicketEl, setAnchorTicketEl] = React.useState(null);

  const [childServiceRequest, setChildServiceRequest] = useState();

  const [selectedBuilding, setSelectedBuilding] = useState();
  // const [selectedTicketBuilding, setSelectedTicketBuilding] = useState();

  const [selectedBuildingContacts, setSelectedBuildingContacts] = useState();

  const [supplierData, setSupplierData] = useState();
  useEffect(() => {
    getSupplier(supplierId)
      .then((data) => {
        setSupplierData(data);
      })
      .catch((_) => alertError("Error Loading Supplier"));
  }, [supplierId]);

  const buildings = supplierData?.buildings ?? [];

  const currentData = childServiceRequest
    ? { ...childServiceRequest, confirmed: "true" }
    : { ...serviceRequest, confirmed: "false" };

  const open = Boolean(anchorEl);
  const ticketOpen = Boolean(anchorTicketEl);

  const onChangeSR = (update) => {
    const { name, value } = update;

    if (name === "serviceRequests") {
      const childSR = serviceRequest.serviceRequests
        ? serviceRequest.serviceRequests[value]
        : undefined;
      setChildServiceRequest({
        ...childSR,
        index: value,
        buildingId: serviceRequest.buildingId,
      });
      return;
    }

    setServiceRequest((serviceRequest) =>
      deepUpdate(name, value, serviceRequest)
    );
  };

  const serviceRequestFormRenderArray = useFormServiceRequest({
    data: currentData,
    buildings: buildings || [],
    setData: childServiceRequest ? setChildServiceRequest : setServiceRequest,
  });

  const onDropCB = (file) => {
    if (childServiceRequest) {
      setChildServiceRequest((serviceRequest) => {
        return {
          ...serviceRequest,
          mediaUrls: [...(serviceRequest.mediaUrls ?? []), file.url],
        };
      });
    } else {
      setServiceRequest((serviceRequest) => {
        return {
          ...serviceRequest,
          mediaUrls: [...(serviceRequest.mediaUrls ?? []), file.url],
        };
      });
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTicketBuildingClick = (event) => {
    setAnchorTicketEl(event.currentTarget);
  };

  const handleClose = (buildingId, propertyName) => {
    if (propertyName)
      setSelectedBuilding({ id: buildingId, name: propertyName });
    buildingId
      ? getBuilding(buildingId).then((buildingData) => {
          setSelectedBuildingContacts(buildingData.contacts);
        })
      : setSelectedBuildingContacts(null);

    setAnchorEl(null);
  };

  const handleTicketsClose = (buildingId, buildingName) => {
    handleClose(buildingId, buildingName);
    buildingId
      ? setSelectedBuilding({ id: buildingId, name: buildingName })
      : setSelectedBuilding();
    setAnchorTicketEl(null);
  };

  const onChange = (evt) => {
    const name = evt.target.name;
    const checked = evt.target.checked;

    if (checked) setFilter((prevState) => [...prevState, name]);
    else setFilter((prevState) => prevState.filter((value) => value !== name));
    // We create a new object for filters
  };

  const handleChange = (event, newValue) => {
    setOpenModal({ ...openModal, selectedTab: newValue });
  };

  const fetchMessages = (next = false) => {
    const currentPage = messages.pageable?.pageNumber ?? -1;
    selected &&
      (tab === "Locations"
        ? handleFetchMessages(
            getMessagesForTickets,
            makePageable(
              {
                ticketIds: tickets
                  .filter((ticket) => selected.buildingId === ticket.buildingId)
                  .map((ticket) => ticket.ticketId),
              },
              currentPage,
              next
            ),
            setMessages,
            next
          )
        : handleFetchMessages(
            getContactMessages,
            makePageable(
              { contactIds: [selected.contactId] },
              currentPage,
              next
            ),
            setMessages,
            next
          ));
  };
  const addAttachmentToSR = (url) => {
    setServiceRequest((serviceRequest) => {
      return {
        ...serviceRequest,
        mediaUrls: [...(serviceRequest.mediaUrls ?? []), url],
      };
    });
  };

  const handleAction = (action) => {
    if (
      action.actionType === "NOTIFICATION" &&
      action.topic.includes("/contact_message/")
    ) {
      console.log("Reloading Messages");
      fetchMessages();
    }
  };

  useEffect(() => {
    dispatcher.register(handleAction);
    window.dispatcher = dispatcher;
  }, []);

  useEffect(() => {
    setContacts([]);
    // setTickets([]);
    setSelected();
    setMessages([]);
    listSupplierContacts(supplierId)
      .then(setContacts)
      .catch((_) => alertError("Error Loading Contacts"));
    // clientTickets(supplierId)
    //   .then(setTickets)
    //   .catch((_) => alertError("Error Loading Tickets"));
  }, [supplierId]);

  useEffect(() => {
    const contactIds = contacts.map((contact) => Number(contact.contactId));
    if (
      contacts.length > 0 &&
      selectedContact &&
      contactIds.includes(Number(selectedContact)) &&
      Number(selected?.contactId || null) !== Number(selectedContact)
    ) {
      console.log("Load from URL");
      const selectedContactObj = contacts.find(
        (contact) => Number(contact.contactId) === Number(selectedContact)
      );
      setSelected(selectedContactObj);
      setFilter((filter) => [...filter, selectedContactObj.position]);
      navigate(`/suppliers/${supplierId}/messages`);
    } else if (tab === "Tickets") tickets.length && setSelected(tickets[0]);
    else if (tab === "Locations") buildings.length && setSelected(buildings[0]);
    else if (!selectedContact && !selected && contacts.length > 0) {
      console.log("Load default");
      setSelected(
        contacts.find((contact) => filter.includes(contact.position)) ?? null
      );
    }
    if (
      contacts.length &&
      contacts.filter((contact) => filter.includes(contact.position)).length ===
        0
    ) {
      if (!filter.includes("OWNER"))
        setFilter((filter) => {
          return [...filter, "OWNER"];
        });
      else if (!filter.includes("TENANT"))
        setFilter((filter) => {
          return [...filter, "TENANT"];
        });
    }
  }, [selectedContact, contacts, buildings, tickets, tab, filter]);

  useEffect(() => {
    console.log("Messages", messages);
  }, [messages]);
  useEffect(() => {
    setMessages({ content: [] });
    selected && fetchMessages();

    if (selected && tab === "Contacts") {
      subscribeTopic({
        topic: "/notifications/contact_message/" + selected.contactId,
      });
      return () =>
        unsubscribeTopic({
          topic: "/notifications/contact_message/" + selected.contactId,
        });
    }
  }, [selected]);

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <div className="flex-1 flex-col md:flex-row mx-auto">
          <div className="flex-1">
            <div className="flex flex-col md:flex-row  border border-grey rounded shadow-lg bg-gray-200 md:max-h-lscreen">
              {/* <!-- Left --> */}
              <div className="w-full md:w-3/12 border flex flex-col bg-gray-100">
                <div className="mx-auto my-4 rounded-lg bg-gray-200 py-2">
                  {["Locations", "Contacts"].map((thisTab) => (
                    <span
                      key={thisTab}
                      className={
                        "px-4 py-2 cursor-pointer rounded-lg" +
                        (tab === thisTab ? " bg-gray-400" : " bg-gray-200")
                      }
                      onClick={(_) => setTab(thisTab)}
                    >
                      {thisTab}
                    </span>
                  ))}
                </div>
                {/* <!-- Contacts --> */}
                {tab === "Contacts" && (
                  <>
                    <Button
                      id="basic-button"
                      aria-controls="basic-menu"
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      {selectedBuilding ? selectedBuilding.name : "Location"}{" "}
                      <ArrowDropDownIcon />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={(_) => {
                        if (selectedBuilding) {
                          if (!selectedBuilding.id) {
                            handleClose("", "Location");
                          } else {
                            handleClose(
                              selectedBuilding.id,
                              selectedBuilding.name
                            );
                          }
                        } else {
                          handleClose("", "Location");
                        }
                      }}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={(_) => handleClose("", "Location")}>
                        Select
                      </MenuItem>
                      {buildings.map((building) => (
                        <MenuItem
                          key={building.buildingId}
                          onClick={(_) =>
                            handleClose(
                              building.buildingId,
                              renderAddress({
                                streetAddress1: building.address.streetAddress1,
                                streetAddress2: building.address.streetAddress2,
                                city: building.address.city,
                              })
                            )
                          }
                        >
                          {renderAddress({
                            streetAddress1: building.address.streetAddress1,
                            streetAddress2: building.address.streetAddress2,
                            city: building.address.city,
                          })}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}

                <div className="bg-grey-lighter flex-1 overflow-auto max-h-screen">
                  {(tab === "Tickets"
                    ? selectedBuilding
                      ? tickets.filter((ticket) => {
                          if (selectedBuilding.id)
                            return ticket.buildingId === selectedBuilding.id;
                          else return true;
                        })
                      : tickets
                    : tab === "Locations"
                    ? buildings
                    : selectedBuildingContacts
                    ? selectedBuildingContacts.filter((contact) =>
                        filter.includes(contact.position)
                      )
                    : contacts.filter((contact) =>
                        filter.includes(contact.position)
                      )
                  ).map((item, index) => (
                    <>
                      <CustomListItem
                        key={index}
                        item={item}
                        tab={tab}
                        setServiceRequest={setServiceRequest}
                        setSelected={setSelected}
                      ></CustomListItem>
                    </>
                  ))}
                </div>
              </div>
              {selected && (
                <MessagesView
                  title={
                    selected.ticketId
                      ? `#T${selected.ticketId}`
                      : selected.buildingId
                      ? renderAddress(selected.address)
                      : `${selected?.firstName ?? ""} ${
                          selected?.lastName ?? ""
                        }`
                  }
                  subtext={selected.phone ?? ""}
                  phone={selected.phone}
                  contacts={
                    selectedBuildingContacts
                      ? selectedBuildingContacts
                      : contacts.reduce((acc, current) => {
                          return { ...acc, [current.contactId]: current };
                        }, {}) ?? {}
                  }
                  messages={messages}
                  updateMessages={fetchMessages}
                  tickets={tickets}
                  addAttachmentToSR={addAttachmentToSR}
                  contactId={selected.contactId}
                  buildingId={selected.buildingId}
                  fetchNextPage={(_) => fetchMessages(true)}
                />
              )}
            </div>
          </div>
          <div className="p-2 w-3/12">
            <div className="block"></div>
          </div>
        </div>
      </DndProvider>

      <MaterialModal
        open={openModal.showModal}
        setOpen={setOpenModal}
        label="new-user-modal"
        describedby="create-new-user"
      >
        <div className="max-w-6xl mx-auto bg-white rounded-md p-4">
          <div className={classes.root}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={openModal.selectedTab}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs + " bg-white"}
            >
              {[
                { label: "Tickets", value: "TICKETS" },
                { label: "Service Requests", value: "REQUESTS" },
              ].map((tab, index) => (
                <Tab
                  label={tab.label}
                  key={index}
                  className="hover:bg-gray-200 px-4"
                  value={tab.value}
                  selected={openModal.selectedTab === tab.value}
                />
              ))}
            </Tabs>
            <div
              className={
                classes.root + " max-w-6xl mx-auto w-full gap-2 flex-col p-2"
              }
            >
              {openModal.selectedTab === "REQUESTS" ? (
                <ListServiceRequests
                  buildings={buildings}
                  supplierId={supplierId}
                  onClickCB={(data) => {
                    setOpenModal({ ...openModal, showModal: false });
                    setServiceRequest({
                      ...data,
                      confirmed: "true",
                      building: data.buildingId,
                    });
                  }}
                />
              ) : (
                <ListTickets
                  buildings={buildings}
                  supplierId={supplierId}
                  onClickCB={(data) => {
                    setOpenModal({ ...openModal, showModal: false });
                    setServiceRequest({
                      ...data,
                      confirmed: "false",
                      building: data.buildingId,
                    });
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </MaterialModal>
    </div>
  );
}
