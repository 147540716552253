import React, { useState } from "react";
import { useEffect } from "react";

import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  // Select,
  // MenuItem,
  InputBase,
  TextField,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { navigate } from "raviger";

import { alertError } from "../../actions/AlertActions";
import { getTerritory, listAssociates } from "../../api/Api";
import DropDown from "../../components/common/DropDown";
import { properString } from "../../utils/StringUtils";
import MaterialTable from "../common/MaterialTable";
import MaterialForm from "../common/form/MaterialForm";
import { extendedSkills } from "../common/form/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex ",
  },
  tabs: {
    borderRight: `1px solid \${theme.palette.divider}`,
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    // border: "1px solid #ced4da",
    borderTop: "none",
    borderBottom: "none",
    fontSize: 13,
    padding: "9px ",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const skillFormatted = (skill) => {
  let formatted = extendedSkills.find((item) => {
    if (item.value === skill) {
      return item;
    }
  });
  if (formatted) {
    return formatted.label;
  } else {
    return "";
  }
};

const types = {
  SQUAD_ASSOCIATE: "Squad Associate",
  // CONTRACTOR: "Contractor",
  SUPERVISOR: "Supervisor",
  THIRD_PARTY_VENDOR: "TPV",
};

export default function ListAssociates({ tab, newAssociate, onSelect }) {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [associates, setAssociates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    type: tab ? tab : "OTHERS",
    territory: "ALL",
  });
  const [territories, setTerritories] = useState([]);

  const init = () => {
    listAssociates(filter)
      .then((data) => {
        setAssociates(data);
        setLoading(false);
      })
      .catch((error) => {
        alertError("Error getting Associates");
        setLoading(false);
      });
  };

  useEffect(() => {
    init();
    getTerritory()
      .then((res) => {
        setTerritories(
          res.map((item) => {
            return { value: item.name, label: properString(item.name) };
          })
        );
      })
      .catch(() => {
        alertError("Error getting Territories");
      });
  }, []);

  useEffect(() => {
    init();
  }, [filter]);

  const handleChange = (event, newValue) => {
    setFilter((current) => {
      return { ...current, type: newValue };
    });
  };

  const getSearchBasedData = () => {
    if (associates) {
      return associates.filter((item) => {
        if (
          item.companyName?.toLowerCase().includes(searchText.toLowerCase())
        ) {
          return item;
        }
        if (item.firstName?.toLowerCase().includes(searchText.toLowerCase())) {
          return item;
        }
        if (item.lastName?.toLowerCase().includes(searchText.toLowerCase())) {
          return item;
        }
        if (filter.type === "TPV" || filter.type === "ALL") {
          if (
            `${item.firstName || ""} ${item.lastName || ""}`
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            return item;
          }
        }

        if (item.email?.includes(searchText)) {
          return item;
        }
        if (item.phone?.toLowerCase().includes(searchText.toLowerCase())) {
          return item;
        }
        if (item.skill?.toLowerCase().includes(searchText.toLowerCase())) {
          return item;
        }
        if (item.subCategory) {
          let array = item.subCategory + "";
          if (array?.toLowerCase().includes(searchText.toLowerCase())) {
            return item;
          }
        }

        if (item.contacts) {
          let filtered = item.contacts.filter((contact) => {
            if (
              contact.firstName
                ?.toLowerCase()
                .includes(searchText.toLowerCase())
            ) {
              return item;
            }
            if (
              contact.lastName?.toLowerCase().includes(searchText.toLowerCase())
            ) {
              return item;
            }
            if (contact.email?.includes(searchText)) {
              return item;
            }
            if (
              contact.phone?.toLowerCase().includes(searchText.toLowerCase())
            ) {
              return item;
            }
          });

          if (filtered.length > 0) {
            return item;
          }
        }
      });
    } else {
      return [];
    }
  };

  const gatherData = () => {
    let parseData = associates;
    if (searchText.length > 0) {
      parseData = getSearchBasedData();
    }
    let data = parseData?.map((associate) => {
      return {
        id: associate.associateId,
        data: [
          associate?.companyName
            ? associate?.companyName
            : `${associate.firstName} ${associate.lastName}`,
          skillFormatted(associate.skill),
          associate.appointmentCount,
        ],
      };
    });
    if (filter.type === "TPV") {
      data = parseData?.map((associate) => {
        return {
          id: associate.associateId,
          data: [
            associate?.companyName
              ? associate?.companyName
              : `${associate.firstName} ${associate.lastName}`,
            skillFormatted(associate.skill),

            associate.appointmentCount,
          ],
        };
      });
    } else if (filter.type === "ALL") {
      data = parseData?.map((associate) => {
        return {
          id: associate.associateId,
          data: [
            associate?.companyName
              ? associate?.companyName
              : `${associate.firstName} ${associate.lastName}`,

            skillFormatted(associate.skill),
            types[associate.type] || "-",

            associate.appointmentCount,
          ],
        };
      });
    }
    return data;
  };

  const gatherHead = () => {
    return [
      { id: "companyName", label: "Name" },
      { id: "skill", label: "Category" },
      ...(filter.type === "ALL" ? [{ id: "type", label: "Type" }] : []),

      { id: "appointmentCount", label: "Completed Jobs" },
    ];
  };
  const tabNames = [
    ...(!tab ? [{ label: "SQUAD ASSOCIATES", value: "OTHERS" }] : []),
    { label: "THIRD PARTY VENDORS", value: "TPV" },
    ...(!tab ? [{ label: "All", value: "ALL" }] : []),
  ];
  return (
    <div className="max-w-6xl mx-auto bg-white rounded-md p-4">
      <div className="flex flex-row items-center justify-between gap-3">
        <Typography className="p-2 text-gray-800" variant="h4">
          Field Service Operatives
        </Typography>

        <div className="flex flex-row items-center">
          <div>
            <MaterialForm
              className="w-28"
              noSelect={true}
              data={{ territory: filter.territory }}
              renderArray={[
                {
                  name: "territory",
                  inputType: "SELECT",
                  defaultValue: "ALL",
                  options: [{ label: "All", value: "ALL" }].concat(
                    ...territories
                  ),
                  label: "Territory",
                },
              ]}
              onChange={(update) => {
                const { name, value } = update;
                setFilter((current) => {
                  return { ...current, territory: value };
                });
              }}
            />

            {/* <Select
              variant="standard"
              id="territory"
              name="territory"
              defaultValue="ALL"
              input={<BootstrapInput />}
              onChange={(e) => {
                setFilter((current) => {
                  return { ...current, territory: e.target.value };
                });
              }}
            >
              <MenuItem value="ALL">All</MenuItem>
              {territories.map((options) => (
                <MenuItem value={options.value}>{options.label}</MenuItem>
              ))}
            </Select> */}
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row">
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={filter.type}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs + " bg-white hidden md:flex "}
        >
          {tabNames.map((tab) => (
            <Tab
              label={tab.label}
              key={tab.value}
              className="hover:bg-gray-200"
              value={tab.value}
            />
          ))}
        </Tabs>

        <div className="flex md:hidden justify-center mt-4 pb-4 border-b z-10">
          <DropDown
            className="text-m p-2"
            aria-label="Tabs"
            options={tabNames.map((item) => ({
              channel: item.label, // Display Name
              value: item.value, // Value
            }))}
            onChangeCB={(index) => {
              const selectedOption = tabNames.find(
                (item) => item.label === index
              );
              setFilter((current) => {
                return { ...current, type: selectedOption.value };
              });
            }}
            defaultValue={tabNames
              .map((item) => ({
                channel: item.label, // Display Name
                value: item.value, // Value
              }))
              .find((item) => item.value === filter.type)}
          />
        </div>

        <div
          className={
            classes.root +
            " max-w-6xl mx-auto w-full gap-2 flex-col p-4 rounded-md"
          }
        >
          <div className="flex items-center gap-2 cursor-pointer">
            <TextField
              id="search"
              className="flex-1"
              label="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              variant="outlined"
            />
            <div
              onClick={(_) => {
                if (newAssociate) {
                  newAssociate();
                } else {
                  navigate("/associates/new");
                }
              }}
            >
              <AddCircleOutlineOutlined /> New FSO
            </div>
          </div>
          <MaterialTable
            loading={loading}
            data={gatherData()}
            head={gatherHead()}
            defaultOrderBy={
              filter.type === "TPV" || filter.type === "ALL"
                ? "companyName"
                : "firstName"
            }
            onClickCB={(associate) => {
              if (onSelect) {
                onSelect(associate);
              } else {
                navigate("/associates/" + associate + "/details");
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
