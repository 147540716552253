import React from "react";

import { Link } from "raviger";

import { Tick, WrenchCrossIcon } from "../common/AppIcons";

interface PrimaryClient {
  clientName: string;
  clientId: number;
  contactId: number;
}

export default function RenderAddressComponent({
  primaryClient,
  address,
  unitNumber,
  className,
}: {
  primaryClient?: PrimaryClient;
  address: any;
  unitNumber?: string;
  className?: string;
}) {
  return (
    <div className={"flex flex-col items-start " + className}>
      {/* Render only when  */}
      
        <>
        {address && address.streetAddress1 && (<>
          <div className="whitespace-pre">
            {`${address?.propertyShortName ? address?.propertyShortName : ""}`}
          </div>
          <div className="whitespace-pre flex items-center">
            {`${address?.streetAddress1 ? address?.streetAddress1 : ""}, `}

            {address?.melissaBaseMak && (
              <div className="bg-green-500 text-white h-3.5 w-3.5 rounded-full  flex items-center justify-center">
                <Tick className="h-2 w-2" />
              </div>
            )}
          </div>
          {unitNumber && (
            <div className="whitespace-pre">{`${unitNumber},`}</div>
          )}

          <div className="whitespace-pre">
            {`${address?.city ? address?.city : ""}, ${
              address?.state ? address?.state : ""
            } ${address?.zip ? address.zip : ""}`}
          </div>
          </>)}
          
          {/* Managed by */}
          {primaryClient ? (
            (primaryClient.clientId || primaryClient.contactId) && (
              <div className="flex items-center mt-2 gap-2">
                <WrenchCrossIcon className="w-4 h-4" />
                <span>
                  Managed by
                  <Link
                    href={
                      primaryClient.clientId
                        ? `/commercial/${primaryClient.clientId}/summary`
                        : `/individual/${primaryClient.contactId}/buildings`
                    }
                    className="ml-1 text-blue-500 hover:underline"
                  >
                    {`${primaryClient.clientName}`}
                  </Link>
                </span>
              </div>
            )
          ) : (
            <></>
          )}
        </>
      
    </div>
  );
}
