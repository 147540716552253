import PopOverText from "./PopOverText";

export const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  let splitted = string.split("_");
  string = splitted.join(" ");
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};

export default function ProgressSteps({ steps, currentStep }) {
  const renderCompletedStep = (stepName, isFinal) => (
    <li className={isFinal ? "relative" : "relative pr-8 sm:pr-20"}>
      <PopOverText text={stepName}>
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="h-0.5 w-full bg-indigo-600"></div>
        </div>
        <span className="relative w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full hover:bg-indigo-900">
          <svg
            className="w-5 h-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
          <span className="sr-only">{stepName}</span>
        </span>
      </PopOverText>
    </li>
  );
  const renderCurrentStep = (stepName, fail, isFinal) => (
    <li className={isFinal ? "relative" : "relative pr-8 sm:pr-20"}>
      <PopOverText text={stepName}>
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="h-0.5 w-full bg-gray-200"></div>
        </div>
        <span
          className={
            "relative w-8 h-8 flex items-center justify-center bg-white border-2 rounded-full " +
            (fail ? "border-red-600" : "border-indigo-600")
          }
          aria-current="step"
        >
          <span
            className={
              "h-2.5 w-2.5 rounded-full " +
              (fail ? "bg-red-600" : "bg-indigo-600")
            }
            aria-hidden="true"
          ></span>
          <span className="sr-only">{stepName}</span>
        </span>
      </PopOverText>
    </li>
  );
  const renderUpcomingStep = (stepName, isFinal) => (
    <li className={isFinal ? "relative" : "relative pr-8 sm:pr-20"}>
      <PopOverText text={stepName}>
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="h-0.5 w-full bg-gray-200"></div>
        </div>
        <span className="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400">
          <span
            className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"
            aria-hidden="true"
          ></span>
          <span className="sr-only">{stepName}</span>
        </span>
      </PopOverText>
    </li>
  );
  return (
    <nav aria-label="Progress">
      <ol className="flex p-2 items-center justify-center">
        {
          steps.reduce(
            (acc, step, i, steps) => {
              const [successStep, failStep] = step.split("|");
              const completed =
                currentStep === successStep || currentStep === failStep
                  ? false
                  : acc.completion;
              const renderThis =
                successStep === currentStep
                  ? renderCurrentStep(
                      capitalizeFirstLetter(successStep),
                      true,
                      steps.length - 1 === i
                    )
                  : failStep === currentStep
                  ? renderCurrentStep(
                      capitalizeFirstLetter(failStep),
                      true,
                      steps.length - 1 === i
                    )
                  : acc.completion
                  ? renderCompletedStep(
                      capitalizeFirstLetter(successStep),
                      steps.length - 1 === i
                    )
                  : renderUpcomingStep(
                      capitalizeFirstLetter(successStep),
                      steps.length - 1 === i
                    );
              return {
                ...acc,
                completion: completed,
                renderArray: [...acc.renderArray, renderThis],
              };
            },
            { completion: true, renderArray: [] }
          ).renderArray
        }
      </ol>
    </nav>
  );
}
