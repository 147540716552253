// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GalleryGen from "./Gallery.gen";

var make = GalleryGen.make;

export {
  make ,
  
}
/* make Not a pure module */
